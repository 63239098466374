import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { sitesTypes } from '../../../utils/constants/tagColors';
import ContactsForm from './contactsForm';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [profesionals, setProfesionals] = useState([]);
  const [enumsStatus, setEnumsStatus] = useState([]);

  const getEnumsStatus = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders-supplies/enums_status'
      });
      setEnumsStatus(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const basicFields = [
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select>
          {(sites || []).map((item) => (
            <Option key={item._id} value={item._id}>
              <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['customer'],
      input: (
        <Select>
          {(customers || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.first_name || ''} ${item.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['profesional'],
      input: (
        <Select>
          {(profesionals || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['supplier']
    },
    {
      name: ['order_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      initialValue: moment(new Date()),
      input: <DatePicker format="DD-MM-YYYY" />
    },
    {
      name: ['removal_date'],
      input: <DatePicker format="DD-MM-YYYY" />
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <ContactsForm />
    }
  ];

  const getProfesionals = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/profesionals' });
      setProfesionals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getProfesionals();
      await getSites();
      await getCustomers();
      await getEnumsStatus();
    })();
  }, []);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
