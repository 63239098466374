import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import {
  noLabelLayout,
  formItemLayout
} from '../../../utils/constants/formLayout';
import { categoryProcutions } from '../../../utils/constants/tagColors';
import DartyPrice from '../dartyPrice';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const basicFields = [
    {
      name: ['ref'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['category'],
      input: (
        <Select loading={isFieldsLoading}>
          {enums.map((el) => {
            return (
              <Option key={el} value={el}>
                <Tag color={categoryProcutions[el.toUpperCase()]}>
                  {el.toUpperCase()}
                </Tag>
              </Option>
            );
          })}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['sold_price'],
      input: <InputNumber />,
      rules: [{ required: true }]
    }
  ];

  const advancedFields = [];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/productions/enums' });
      setEnums(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
