import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Card } from 'antd';

const Sections = ({ datas, products }) => {
  const { t } = useTranslation();
  const [sections, setSections] = useState();

  const { Title } = Typography;
  useEffect(() => {
    if (datas) {
      const sectionsArray = [];
      Object.keys(datas).forEach((key) => {
        if (key.startsWith('section_')) {
          sectionsArray.push(datas[key]);
        }
      });
      setSections(sectionsArray);
    }
  }, []);

  if (sections) {
    let indexMat = 0;
    return (
      <div>
        {sections.map((section) => {
          return (
            <div>
              {section.elements.map((mat) => {
                indexMat += 1;
                return (
                  <Row style={{ padding: '0 24px 24px 24px' }}>
                    <Col span={24}>
                      <Card
                        style={{
                          padding: 0,
                          background: 'rgba(255, 255, 255, 0)',
                          borderColor: 'black'
                        }}
                      >
                        <Row>
                          <Col>
                            <Title level={5}>
                              <span>
                                {t('sections.matter-title', {
                                  index: indexMat
                                })}
                              </span>
                              {t('sections.matter', {
                                matter: mat.matter,
                                color: mat.shaping?.color,
                                completion: mat.shaping?.completions,
                                thickness: mat.thikness
                              })}
                            </Title>
                            <div style={{ width: '103px' }}>
                              <hr
                                style={{
                                  backgroundColor: 'black',
                                  height: '3px',
                                  border: 'none',
                                  marginBottom: '18px'
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row justify="space-around">
                          <Col style={{ margin: '16px' }} span={7}>
                            <Card
                              style={{ width: '100%', height: '100%' }}
                              bordered={false}
                            >
                              <div>
                                <pre style={{ marginBottom: 0 }}>
                                  <div
                                    style={{
                                      whiteSpace: 'normal',
                                      fontStyle: 'italic',
                                      padding: '0px 0px 10px 0px'
                                    }}
                                  >
                                    {t(
                                      `sections.${
                                        mat.is_credence ? 'credence' : 'plan'
                                      }`,
                                      { thickness: mat.thikness }
                                    )}
                                  </div>
                                  <div>
                                    <ul>
                                      <li>{t('sections.sizes')}</li>
                                    </ul>
                                  </div>
                                  {mat.sizes.map((size) => {
                                    return (
                                      <div>
                                        <div
                                          style={{
                                            padding: '0px 0px 0px 40px'
                                          }}
                                        >
                                          {` ${size.height} x ${size.width}`}
                                        </div>
                                        {size.shapings &&
                                          size.shapings.length > 0 && (
                                            <div>
                                              {size.shapings.map((shaping) => {
                                                return (
                                                  <div>
                                                    {shaping.shaping_name &&
                                                      shaping.shaping_name
                                                        .label && (
                                                        <div
                                                          style={{
                                                            whiteSpace:
                                                              'normal',
                                                            padding:
                                                              '0px 0px 0px 40px'
                                                          }}
                                                        >
                                                          <ul>
                                                            <li>{`${shaping.shaping_name.label}`}</li>
                                                          </ul>
                                                        </div>
                                                      )}
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}

                                  <p
                                    style={{
                                      whiteSpace: 'normal',
                                      marginBottom: 0
                                    }}
                                  >
                                    {mat.matter_desc && (
                                      <ul>
                                        <li>
                                          {t('sections.description')}
                                          {mat.matter_desc}
                                        </li>
                                      </ul>
                                    )}
                                  </p>
                                </pre>
                              </div>
                            </Card>
                          </Col>
                          {products && (
                            <Col style={{ margin: '16px' }} span={7}>
                              <Card
                                style={{ width: '100%', height: '100%' }}
                                bordered={false}
                              >
                                <div>
                                  <pre style={{ marginBottom: 0 }}>
                                    <div
                                      style={{
                                        whiteSpace: 'normal',
                                        fontStyle: 'italic',
                                        padding: '0px 0px 10px 0px'
                                      }}
                                    >
                                      {`Entretien`}
                                    </div>

                                    {products.map((product) => {
                                      return (
                                        <div>
                                          <div>
                                            <ul>
                                              <li>{`${product.quantity} x ${product.product.label} - ${product.category}`}</li>
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </pre>
                                </div>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
  return <div> Loading sections ...</div>;
};

Sections.propTypes = {
  accessories: PropTypes.shape({}).isRequired,
  datas: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Sections;
