import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Card, Typography, Row } from 'antd';
import moment from 'moment';

export const Placed = ({ datas }) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  const renderRow = (label, value) => {
    if (value) {
      return (
        <Row>
          {`${label} : `}
          <Text strong>{`${value}`}</Text>
        </Row>
      );
    }
    return null;
  };

  if (datas) {
    const {
      measure,
      lay,
      displacement,
      lay_date,
      measurement_date,
      without_lay
    } = datas;

    return (
      <Col span={8} style={{ minHeight: '100%' }}>
        <Card
          style={{
            padding: 0,
            background: 'rgba(255, 255, 255, 0.8)',
            height: '100%'
          }}
        >
          <Col>
            <Title level={4}>{t('configurator.title')}</Title>
            <div style={{ width: '110px' }}>
              <hr
                style={{
                  backgroundColor: 'black',
                  height: '3px',
                  border: 'none',
                  marginBottom: '18px'
                }}
              />
            </div>
            {renderRow(t('configurator.measure'), measure?.label)}
            {renderRow(t('configurator.lay'), lay?.label)}
            {renderRow(t('configurator.displacement'), displacement?.label)}
            {renderRow(
              t('configurator.lay_date'),
              moment(lay_date).format('Do MMMM YYYY')
            )}
            {renderRow(
              t('configurator.measurement_date'),
              moment(measurement_date).format('Do MMMM YYYY')
            )}
            {renderRow(
              t('configurator.withoutLay'),
              t(`configurator.${without_lay}`)
            )}
          </Col>
        </Card>
      </Col>
    );
  }

  return <></>;
};

Placed.propTypes = {
  datas: PropTypes.shape({
    measure: PropTypes.shape({
      label: PropTypes.string
    }),
    lay: PropTypes.shape({ label: PropTypes.string }),
    displacement: PropTypes.shape({ label: PropTypes.string }),
    lay_date: PropTypes.string,
    measurement_date: PropTypes.string,
    without_lay: PropTypes.bool
  })
};

Placed.defaultProps = {
  datas: null
};
