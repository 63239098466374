import PropTypes, { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import {
  AimOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useScanContext from './ScanContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';

const { Title } = Typography;

const spin = (
  <Spin
    indicator={<LoadingOutlined spin style={{ fontSize: 16, color: 'grey' }} />}
  />
);

const creationRoutes = {
  Company: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/create`,
  Contact: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CONTACTS}/create`,
  Employee: `${routes.HUMANRESOURCE}${subRoutes.HUMANRESOURCE.EMPLOYEES}/create`
};

const ScanOutput = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const {
    output,
    fields,
    zoningField,
    setZoningField,
    loadingFields,
    setNextField
  } = useScanContext();
  const [inputs, setInputs] = useState([]);
  const [options, setOptions] = useState({});

  const handleZoneRecord = (key) => {
    if (zoningField === key) return setZoningField(null);
    if (
      !form.getFieldValue([zoningField]) &&
      !loadingFields.includes(zoningField)
    ) {
      setNextField(zoningField);
    }
    setZoningField(key);
  };

  const getOptions = async (collection) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/${collection}` });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInput = (type, inputElement, aim, inputSpin, collection) => {
    const formItem = (input) => (
      <Row key={`row_${inputElement[0]}`}>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div style={{ marginTop: '4px' }}>
            {inputElement[1].required && (
              <span style={{ color: 'red' }}>*</span>
            )}
            &nbsp;{t(`${type.toLowerCase()}.form.${inputElement[0]}`)}
            &nbsp;:&nbsp;
          </div>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[inputElement[0]]}
            key={inputElement[0]}
            rules={[{ required: inputElement[1].required }]}
          >
            {input}
          </Form.Item>
        </Col>
        <Col span={6}>
          {inputElement[1].ref &&
            (inputElement[1].type === 'ObjectID' ||
              inputElement[1].type === 'ObjectId') && (
              <Link to={creationRoutes[inputElement[1].ref]}>
                <Button type="add">
                  {`${t('buttons.create')} `}
                  <PlusOutlined />
                </Button>
              </Link>
            )}
        </Col>
      </Row>
    );

    if (!inputElement[1]) return;

    if (typeof inputElement[1].type === 'object') {
      const aimOut = (k) => (
        <AimOutlined
          style={{
            cursor: 'pointer',
            color: zoningField === k ? 'red' : 'inherit'
          }}
          onClick={() => handleZoneRecord(k)}
        />
      );

      switch (inputElement[0]) {
        case 'phone_number':
        case 'address':
          return Object.keys(inputElement[1].type).map((k) => {
            return getInput(
              type,
              [`${inputElement[0]}.${k}`, inputElement[1].type[k]],
              aimOut(k),
              inputSpin
            );
          });
        default:
          break;
      }
    }

    switch (element[1].type) {
      // default:
      case 'Number':
        return formItem(
          <Input
            type="number"
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && inputSpin
            }
          />
        );
      case 'String':
        if (element[1].enum) {
          return formItem(
            <Select
              options={element[1].enum.map((enumType) => {
                return {
                  label: t(`${type.toLowerCase()}.tags.${enumType}`),
                  value: enumType
                };
              })}
            />
          );
        }
        return formItem(
          <Input
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && inputSpin
            }
          />
        );
      case 'Date':
        return formItem(
          <Input
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && inputSpin
            }
          />
        );
      case 'ObjectID':
      case 'ObjectId':
        return (
          element[1].required &&
          formItem(
            <Select
              options={
                options[collection] &&
                options[collection].map((o) => {
                  if (!o.name && o.first_name) {
                    return {
                      label: `${o.first_name || ''} ${o.last_name}`,
                      value: o._id
                    };
                  }
                  return {
                    label: o.name,
                    value: o._id
                  };
                })
              }
            />
          )
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (fields && !options.fakeField) {
      const type = Object.keys(fields)[0];
      let tmpOptions = {};

      const mapOnFields = async () => {
        const promises = Object.entries(fields[type]).map(async (el) => {
          if (
            (el[1].type === 'ObjectId' || el[1].type === 'ObjectID') &&
            el[1].required
          ) {
            let collection = el[1].ref.endsWith('y')
              ? `${el[1].ref.slice(0, -1).toLowerCase()}ies`
              : `${el[1].ref.toLowerCase()}s`;
            if (el[1].ref === 'TicketType') {
              collection = 'ticketType';
            }
            if (el[1].ref === 'TicketStatus') {
              collection = 'ticketStatus';
            }

            const collecOptions = await getOptions(collection);
            tmpOptions = { ...tmpOptions, [collection]: collecOptions };
          }
        });
        await Promise.all(promises);
        setOptions(tmpOptions);
      };

      mapOnFields();
    }
  }, [fields, zoningField, loadingFields.length]);

  useEffect(() => {
    if (output) {
      form.setFieldsValue(output.values);
    }
  }, [output]);

  useEffect(() => {
    if (fields) {
      const tmpInputs = [];
      const type = Object.keys(fields)[0];

      Object.entries(fields[type]).map((el) => {
        if (el[1].scan !== false) {
          let collection = null;
          if (el[1].ref) {
            collection = el[1].ref.endsWith('y')
              ? `${el[1].ref.slice(0, -1).toLowerCase()}ies`
              : `${el[1].ref.toLowerCase()}s`;
          }
          const aim = (
            <AimOutlined
              style={{
                cursor: 'pointer',
                color: zoningField === el[0] ? 'red' : 'inherit'
              }}
              onClick={() => handleZoneRecord(el[0])}
            />
          );
          tmpInputs.push(
            getInput(
              `${
                type.endsWith('y')
                  ? `${type.slice(0, -1).toLowerCase()}ie`
                  : type
              }s`,
              element,
              aim,
              spin,
              collection
            )
          );
        }
        return el;
      });
      setInputs(tmpInputs);
    }
  }, [options, fields, zoningField, loadingFields.length]);

  return (
    <Form
      form={form}
      name="output"
      onFinish={onSubmit}
      onFinishFailed={onSubmit}
    >
      {inputs.length > 0 && (
        <>
          <Col span={12} offset={6}>
            <Title level={4}>{t('scan.document.output')}</Title>
          </Col>
          {inputs}
          <Col span={12} offset={6}>
            <Form.Item key="save">
              <Button type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Form.Item>
          </Col>
        </>
      )}
    </Form>
  );
};

ScanOutput.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ScanOutput;
