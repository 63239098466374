import { useTranslation } from 'react-i18next';

const useListContent = ({ label, site, customer, profesional }) => {
  const { t } = useTranslation();

  return [
    {
      label: t('building-sites.label'),
      content: label,
      span: 1
    },
    {
      label: t('building-sites.customer'),
      content: customer
        ? `${customer?.first_name} ${customer?.first_name}`
        : '-',
      span: 2
    },
    {
      label: t('building-sites.sites'),
      content: site ? site?.label : '-',
      span: 1
    },
    {
      label: t('building-sites.address'),
      content: customer
        ? `${customer?.address.street} ${customer?.address.postal_code} ${customer?.address.city} `
        : '-',
      span: 2
    },
    {
      label: t('building-sites.profesional'),
      content: profesional ? profesional?.name : '-',
      span: 1
    },
    {
      label: t('building-sites.addressCity'),
      content: customer
        ? `${customer?.address.postal_code} ${customer?.address.city} `
        : '-',
      span: 2
    }
  ];
};

export default useListContent;
