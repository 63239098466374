import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';
import PricingList from '../listPrice';
import PriceTimeline from './PriceTimeline';

const ShowMatter = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [matter, setMatter] = useState({});
  const [soldPrice, setSoldPrice] = useState([{}]);
  const deleteMatter = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/matters/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMatter = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/matters/${id}?populate=coef,supplier,timeline.by`
      });
      setMatter(data);
      const coef = data?.coef?.coef;
      const lost_cost = data?.lostCost || 0;
      const delivery_cost = data?.deliveryCost || 0;
      data.pricing.forEach(({ buy_price }) => {
        setSoldPrice((prevState) => [
          ...prevState,
          {
            buy_price,
            sold_price: (buy_price / (1 - lost_cost)) * coef + delivery_cost
          }
        ]);
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMatter();
    })();
  }, [getMatter]);

  return (
    <>
      <PageHeaderCustom
        title={matter.ref || ''}
        withSubRoutes
        extra={
          canEditResource(path) && (
            <>
              <Link
                to={{
                  pathname: `${routes.CATALOGUES}${subRoutes.CATALOGUES.MATTERS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteMatter}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <Row>
            <Col span={16}>
              <DescriptionList data={listContent(matter)} translate />
              <PricingList pricing={matter.pricing} soldPrice={soldPrice} />
            </Col>
            <Col span={8}>
              <PriceTimeline tasks={matter.timeline} t={t} />
            </Col>
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowMatter;
