import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const Context = createContext({});

export const ContextProvider = ({ children, collection }) => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { state } = useLocation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [template, setTemplate] = useState();
  const [refresh, setRefresh] = useState(false);

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=ACCEPTANCE_REPORT&template=true`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/acceptance-report/${id}/${template._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location = url;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (callback) callback();
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplate();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Context.Provider
      value={{
        collection,
        isLoading,
        setIsLoading,
        enums,
        template,
        downloadFile,
        state,
        refresh
      }}
    >
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired
};

export default () => useContext(Context);
