import React from 'react';
import styled from 'styled-components';
import { CheckSquareOutlined } from '@ant-design/icons';
import Repository from './DealCard';
import useBoxContext from './DealsContext';
import useAuthContext from '../../../contexts/AuthContext';

const Col = styled.div`
  height: 700px;
  position: relative;
  border-radius: 2px;
  min-width: 250px;
  margin: 5px;
  overflow-y: auto;
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 3px;
  background-color: black;
  box-shadow: 0px 4px 8px -6px rgba(0, 0, 0, 0.25);
  width: 97%;
  text-align: left;
  color: #e9edf0;
  height: 36px;

  #icon {
    padding: 0 20px;
  }
  #label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ArchivedColumn = () => {
  const { updateDeal, deals } = useBoxContext();
  const { user } = useAuthContext();

  return (
    <div style={{ background: 'rgba(0,0,0, 0.8)' }}>
      <Col>
        <Title style={{ backgroundColor: 'rgba(146, 146, 146, 1)' }}>
          <div id="icon">{<CheckSquareOutlined />}</div>
          <div id="label" style={{ lineHeight: 1 }}>
            Archives
          </div>
        </Title>
        <CardContainer>
          {deals
            .filter((deal) => deal.is_archived)
            .map((deal) => (
              <Repository deal={deal} key={deal._id} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

export default ArchivedColumn;
