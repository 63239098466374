import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card,Typography, Timeline, Anchor } from 'antd';
import moment from 'moment';
import CustomTimeline from '../../../components/TimeLine/CustomTimeline';

const { Title } = Typography;

const generateTimelineItem = (task) => {
  return (
    <Timeline.Item
      position="left"
      label={moment(task.date).format('DD-MM-YY kk[h]mm')}
    >
      <Anchor affix={false}>{task.status}</Anchor>
      <span>{`par ${task.by?.last_name} ${task.by?.first_name}`}</span>
    </Timeline.Item>
  );
};

const GenerateTimeline = ({ tasks }) => {
  const { t } = useTranslation();
  return (
    <Card span={4} style={{ marginBottom: 20, borderRadius: 5 }}>
      <Title level={3}>{t('matters.timeline.title')}</Title>
      {tasks && Array.isArray(tasks) && (
        <CustomTimeline
          tasks={tasks}
          mode="right"
          item={generateTimelineItem}
          size={3}
        />
      )}
    </Card>
  );
};

GenerateTimeline.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  isTaskDrawerVisible: PropTypes.bool,
  setIsTaskDrawerVisible: PropTypes.func
};

GenerateTimeline.defaultProps = {
  tasks: [],
  isTaskDrawerVisible: false,
  setIsTaskDrawerVisible: null
};

export default GenerateTimeline;
