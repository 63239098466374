import React, { useEffect, useState } from 'react';
import { Popconfirm, Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  DeleteOutlined,
  WarningOutlined,
  PlusOutlined,
  ExportOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useOrderContext from '../OrderContext';
import DownloadGeneratedFile from '../../Accounting/Invoices/DownloadGeneratedFile';
import { routes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';

const useButtons = (datas, onInvoice, onValidate) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { deleteResource, template, downloadFile } = useOrderContext();
  const { dispatchAPI } = useAuthContext();
  const [idInvoice, setIdInvoice] = useState(null);
  const getInvoice = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/invoices?orderNew=${datas?._id}`
    });
    if (data.length > 1) {
      data.forEach((invoice) => {
        if (invoice.totals.totalClient) {
          setIdInvoice(invoice._id);
        }
      });
    } else {
      setIdInvoice(data[0]._id);
    }
  };
  useEffect(() => {
    if (datas?.status === 'INVOICED') {
      getInvoice();
    }
  }, [datas]);
  const buttons = (
    <Space>
      {datas?.status === 'WAITING' && (
        <Popconfirm
          title={t(`orders.action.duplicate.quote-warning`)}
          okText={t(`orders.action.duplicate.ok`)}
          okButtonProps={{ type: 'add' }}
          cancelText={t(`orders.action.delete.cancel`)}
          onConfirm={() => {
            history.push(`/customers_accounting/quotes/create`, {
              order: datas
            });
          }}
          icon={<WarningOutlined />}
        >
          <Button type="primary" icon={<PlusOutlined />}>
            {t(`orders.action.create-quote`)}
          </Button>
        </Popconfirm>
      )}
      <Link
        to={{
          pathname: `/customers_accounting/quotes/show/${datas?.quote?._id}`
        }}
        style={{ float: 'right' }}
      >
        <Button type="primary" icon={<ExportOutlined />}>
          {t(`orders.action.associate-quote`)}
        </Button>
      </Link>
      {datas?.status === 'INVOICED' && (
        <Link
          to={{
            pathname: `/custom_accounting/invoices/show/${idInvoice}`
          }}
          style={{ float: 'right' }}
        >
          <Button type="primary" icon={<ExportOutlined />}>
            {t(`orders.action.associate-invoice`)}
          </Button>
        </Link>
      )}
      {datas?.status !== 'INVOICED' && (
        <>
          <Link
            to={{
              pathname: `${routes.CUSTOMERS_ACCOUNTING}/workSheets`,
              state: { datas }
            }}
            style={{ float: 'right' }}
          >
            <Button type="primary" icon={<PlusOutlined />}>
              {t(`orders.action.worksheet`)}
            </Button>
          </Link>
          <Link
            to={{
              pathname: `${routes.CUSTOMERS_ACCOUNTING}/laySheets`,
              state: { datas }
            }}
            style={{ float: 'right' }}
          >
            <Button type="primary" icon={<PlusOutlined />}>
              {t(`orders.action.laysheet`)}
            </Button>
          </Link>
        </>
      )}

      <DownloadGeneratedFile
        element={datas}
        template={template}
        downloadFile={downloadFile}
        advPay
      />
      {datas?.status === 'VALIDATE' && (
        <Button type="primary" onClick={() => onInvoice(datas)}>
          {t(`orders.action.invoiced`)}
        </Button>
      )}
      {datas?.status === 'WAITING' && (
        <Button type="primary" onClick={() => onValidate(datas._id)}>
          {t(`buttons.validate`)}
        </Button>
      )}
      {datas?.status === 'WAITING' && (
        <Popconfirm
          title={t(`orders.action.delete.title`)}
          okText={t(`orders.action.delete.ok`)}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(`orders.action.delete.cancel`)}
          onConfirm={() =>
            deleteResource(
              datas._id,
              history.push('/customers_accounting/orders_sales')
            )
          }
          icon={<WarningOutlined />}
        >
          <Button danger icon={<DeleteOutlined />}>
            {t('buttons.delete')}
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
  return buttons;
};

export default useButtons;
