/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Upload, Button, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';

const { Dragger } = Upload;
const types = [
  'QUOTE',
  'ORDER',
  'INVOICE',
  'WORKSHEET',
  'LAYSHEET',
  'ACCEPTANCE_REPORT',
  // 'REMOVALORDER',
  'MANUAL_REMOVAL_DELIVERY_ORDER'
];

const CreateTemplate = ({ typeSelector, callback }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState();

  const props = {
    name: 'file',
    multiple: false,
    disabled: !typeSelector ? disabled : disabled || !type,
    action: `${process.env.REACT_APP_API_URL}/files`,
    data: {
      type
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      setDisabled(true);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('files.create.title')} />
      <ContentCustom>
        <Row>
          <Col span={8} style={{ textAlign: 'right' }}>
            <div style={{ marginTop: '4px' }}>
              <span style={{ color: 'red' }}>*</span>
              &nbsp;
              {t(`files.form.type`)}
              &nbsp;:&nbsp;
            </div>
          </Col>
          <Col span={8} style={{ marginBottom: 10 }}>
            <Select
              options={types.map((el) => ({
                label: t(`templates.${el}`),
                value: el
              }))}
              style={{ width: '100%' }}
              onSelect={(value) => setType(value)}
            />
          </Col>
          <Col span={8} offset={8}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
              <p className="ant-upload-hint">{t('files.create.formats')}</p>
            </Dragger>
          </Col>
          <Col span={8} offset={8}>
            <Button
              type="primary"
              style={{
                color: '#fff',
                float: 'right',
                backgroundColor: 'rgb(71, 210, 103)',
                border: 'rgb(71, 210, 103)',
                marginTop: 40
              }}
              onClick={() => callback(history)}
            >
              Terminé
            </Button>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

CreateTemplate.propTypes = {
  typeSelector: PropTypes.bool,
  callback: PropTypes.func
};

CreateTemplate.defaultProps = {
  typeSelector: false,
  callback: (history) => history.push('/documents/templates')
};

export default CreateTemplate;
