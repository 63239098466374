import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateCommission = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="commissions"
      resource="commissions"
      withSubRoutes
    />
  );
};

CreateUpdateCommission.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCommission;
