import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CommissionTable = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('commission.user'),
      dataIndex: 'user'
    },
    {
      title: t('commission.date'),
      dataIndex: 'date'
    },
    {
      title: t('commission.commission'),
      dataIndex: 'comamount'
    },
    {
      title: t('commission.amountV'),
      dataIndex: 'amount'
    }
  ];

  const datas = [];

  if (data.payed) {
    data.payed.map((el) => {
      const result = {
        amount: `${el.amount}€`,
        date: moment(el.date).format('DD/MM/YYYY'),
        user: `${el.user.first_name} - ${el.user.last_name}`
      };
      datas.push(result);
      return el;
    });
  }

  return <Table columns={columns} dataSource={datas} />;
};

CommissionTable.propTypes = {
  data: PropTypes.shape({
    payed: PropTypes.array
  }).isRequired
};

export default CommissionTable;
