import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import {
  EyeOutlined,
  EditOutlined,
  LoadingOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Button, Divider, Tag } from 'antd';
import {
  enumsStatus,
  orderStatus,
  iconOrderStatus
} from '../../../utils/constants/tagColors';
import useOrderContext from '../OrderContext';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

const useColumns = () => {
  const { t } = useTranslation();
  const {
    enums,
    downloadFile,
    type,
    sites,
    workSheets,
    laySheets
  } = useOrderContext();
  const [isDownloading, setIsDownloading] = useState({});
  const allWorkSheets = workSheets;

  const renderStatus = (value) => {
    if (type === 'invoices') {
      return (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'PAYÉ' : 'EN ATTENTE DE PAIEMENT'}
        </Tag>
      );
    }
    return (
      <Tag icon={iconOrderStatus[value]} color={orderStatus[value]}>
        {enumsStatus[value]}
      </Tag>
    );
  };

  const filterStatus = () => {
    if (type === 'invoices') {
      return ['PAYÉ', 'EN ATTENTE DE PAIEMENT'].map((el, i) => ({
        text: el,
        value: i === 0
      }));
    }
    if (enums?.status) {
      return enums.status
        .filter((it) => it !== 'DELETED')
        .map((el) => ({
          text: enumsStatus[el],
          value: el
        }));
    }
  };
  const filterCommercialSite = () => {
    if (sites) {
      return sites.map((el) => ({
        text: el.label,
        value: el._id
      }));
    }
  };
  const filterProductionSite = () => {
    if (sites) {
      return sites.map((el) => ({
        text: el.label,
        value: el._id
      }));
    }
  };
  const getWorkSheetAssociateToOrder = (key) => {
    const datesWorkSheet = {};
    const filterWorSheet = allWorkSheets.filter(
      (workSheet) => workSheet?.order?._id === key
    );
    filterWorSheet.forEach((worksheet) => {
      if (worksheet.date !== undefined) {
        if (datesWorkSheet[key]) {
          datesWorkSheet[key].push(worksheet.date);
        } else {
          datesWorkSheet[key] = [worksheet.date];
        }
        datesWorkSheet[key].sort();
        datesWorkSheet[key].reverse();
      }
    });
    if (datesWorkSheet[key] !== undefined) {
      return moment(datesWorkSheet[key][0]).format('DD/MM/YYYY');
    }
    return 'N/R';
  };
  const getSheetStatut = (key) => {
    if (allWorkSheets) {
      const filterWorSheet = allWorkSheets.filter(
        (workSheet) => workSheet?.order?._id === key
      );
      if (filterWorSheet.length > 0) {
        if (laySheets) {
          const filterLaySheet = laySheets.filter(
            (laySheet) => filterWorSheet[0]._id === laySheet.associateWorkSheet
          );
          if (filterLaySheet.length > 0) {
            return <Tag style={{ color: '#cc9900' }}>Pose</Tag>;
          }
        }
        return <Tag style={{ color: '#009999' }}>Travail</Tag>;
      }
    }
    return '_';
  };
  const checkIfOrderIsReserved = (order) => {
    let thereIsReserve = 'N/R';
    if (order) {
      order.reserve.forEach((el, index) => {
        if (el === 'true') {
          thereIsReserve = 'Oui';
        }
        if (index === 0 && el === 'false') thereIsReserve = 'Non';
      });
      return thereIsReserve;
    }
  };
  const filterReserve = () => {
    return ['Oui', 'Non'].map((el, i) => ({
      text: el,
      value: el
    }));
  };
  return [
    {
      title: t('quotes.number'),
      dataIndex: type === 'invoices' ? 'invoice_number' : 'number',
      key: 'number',
      sorter: true,
      width: '10%'
    },
    {
      title: t('quotes.status'),
      dataIndex: type === 'invoices' ? 'payed' : 'status',
      key: 'status',
      render: (value) => renderStatus(value),
      sorter: true,
      filters: filterStatus(),
      width: '10%'
    },
    {
      title: t('quotes.commercial_site'),
      sorter: true,
      // filters: filterCommercialSite(),
      key: 'commercial_site',
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.commercial_site) {
          return `${infos?.commercial_site?.label}`;
        }
        return '_';
      },
      width: '10%'
    },
    {
      title: t('quotes.production_site'),
      sorter: true,
      // filters: filterProductionSite(),
      key: 'production_site',
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.production_site) {
          return `${infos?.production_site?.label}`;
        }
        return '_';
      },
      width: '10%'
    },
    {
      title: t('quotes.created_at'),
      dataIndex: type === 'invoices' ? 'invoice_date' : 'created_at',
      key: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true,
      width: '10%'
    },
    {
      title: t('quotes.Estimated_construction_date'),
      dataIndex: ['placed', 'lay_date'],
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true,
      width: '10%'
    },
    {
      title: t('quotes.sheet'),
      render: (data) => {
        if (data.key) {
          return getSheetStatut(data.key);
        }
        return '_';
      },
      sorter: true
    },
    {
      title: 'Réserve',
      key: 'reserve',
      render: (order) => {
        if (order.placed && order.placed.without_lay) return '_';
        return checkIfOrderIsReserved(order);
      },
      sorter: true,
      filters: filterReserve()
    },
    {
      title: t('quotes.customer'),
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.customer) {
          return `${infos?.customer?.first_name || ''} ${
            infos?.customer?.last_name
          }`;
        }
        return '_';
      },
      sorter: true,
      width: '10%'
    },
    {
      title: t('quotes.total'),
      dataIndex: 'totals',
      render: ({ totalClient }) =>
        `${numeral(totalClient).format('0,0.00')} € HT`,
      sorter: true,
      width: '10%'
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, number, status }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/customers_accounting/${type}/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>
            {status === 'PENDING' && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/customers_accounting/${type}/edit/${key}`,
                    state: { quoteId: key }
                  }}
                >
                  <EditOutlined style={iconStyle} />
                </Link>
              </>
            )}

            <Divider type="vertical" />
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={async () => {
                if (!isDownloading[key]) {
                  setIsDownloading({ ...isDownloading, [key]: true });
                  await downloadFile(key, `${number}.pdf`);
                }
                setIsDownloading({ ...isDownloading, [key]: false });
              }}
            >
              {isDownloading[key] ? (
                <LoadingOutlined style={iconStyle} />
              ) : (
                <DownloadOutlined style={iconStyle} />
              )}
            </Button>
          </span>
        );
      },
      width: '10%'
    }
  ];
};

export default useColumns;
