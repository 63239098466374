import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListPieces from './ListPieces';
import CreateUpdatePieces from './CreatePieces/CreateUpdatePieces';
import ShowPiece from './Show/ShowPiece';
import ListMovements from '../Movements';

const PiecesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/movements`} render={() => <ListMovements />} />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdatePieces purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdatePieces purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowPiece />} />
      <Route path={path} component={ListPieces} />
    </Switch>
  );
};

export default PiecesRouter;
