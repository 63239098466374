import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CreateUpdatePrescriber from '../../Prescribers/CreateUpdatePrescriber';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const AddPrescriberButton = ({ refresh }) => {
  const [isCreatePrescriberVisible, setIsCreatePrescriberVisible] = useState(
    false
  );
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        className="quotation-add-btn"
        onClick={() => setIsCreatePrescriberVisible(true)}
      >
        {t('configurator.new')}
      </Button>
      <Modal
        width="80vw"
        height="80vh"
        closable={false}
        footer={false}
        visible={isCreatePrescriberVisible}
        bodyStyle={{ textAlign: 'center' }}
      >
        <CreateUpdatePrescriber
          purpose="create"
          isModal
          customSubmit={async (body) => {
            try {
              await dispatchAPI('POST', {
                url: 'prescribers',
                body
              });
              setIsCreatePrescriberVisible(false);
              refresh();
            } catch (e) {
              if (e.response) message(e.response.status);
            }
          }}
          customCancel={() => setIsCreatePrescriberVisible(false)}
        />
      </Modal>
    </>
  );
};

AddPrescriberButton.propTypes = {
  refresh: PropTypes.func.isRequired
};

export default AddPrescriberButton;
