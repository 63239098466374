import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('sites.name'),
      dataIndex: 'label'
    },
    {
      title: t('sites.address'),
      dataIndex: 'address',
      render: (address) => address
    },
    {
      title: t('sites.type'),
      dataIndex: 'external',
      render: (value) =>
        value ? (
          <Tag color="black">{t('sites.columns.external')}</Tag>
        ) : (
          <Tag color="red">{t('sites.columns.internal')}</Tag>
        )
    },
    {
      title: t('sites.form.accounting_journal'),
      dataIndex: 'accounting_journal',
      render: (accounting_journal) => accounting_journal || '-'
    }
  ];
};

export default useColumns;
