import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useListContent = ({ order, profesional, amount }, forceRefresh) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [commissions, setCommissions] = useState([]);
  const [totalPayed, setTotals] = useState();
  const [enums, setEnums] = useState();
  const [orders, setOrders] = useState({});

  const getOrder = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/orders?number=${order && order}`
      });
      setOrders(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions/enums`
      });
      setEnums(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const editCoef = async (value) => {
    const body = {
      status: value
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/commissions/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCommission = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions/${id}?populate=profesional,building_site,payed.user`
      });
      setCommissions(data.payed.map((el) => el));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTotalPayed = () => {
    let totals = 0;
    if (commissions) {
      commissions.forEach((el) => {
        totals += el.amount;
      });
    }
    return setTotals(totals);
  };

  useEffect(() => {
    (async () => {
      await getCommission();
      await getOrder();
    })();
    getEnums();
  }, [forceRefresh]);

  useEffect(() => {
    getTotalPayed();
  }, [commissions]);

  return [
    {
      label: 'commission.commission',
      content: `${numeral(amount).format('0,0.00')}€`,
      span: 3
    },
    {
      label: 'commission.restDue',
      content: `${numeral(amount - totalPayed).format('0,0.00')}€`,
      span: 3
    },
    {
      label: 'commission.profesional',
      content: profesional ? profesional?.name : '-',
      span: 3
    },
    {
      label: 'commission.order',
      span: 3,
      content: (
        <Link to={`/customers_accounting/orders_sales/show/${order?._id}`}>
          {order?.number}
        </Link>
      )
    }
  ];
};

export default useListContent;
