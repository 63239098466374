import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { sitesTypes, ordersPurchase } from '../../utils/constants/tagColors';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders-buyings/enums'
      });
      setCategories(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
      await getCategories();
    })();
  }, []);

  return [
    {
      title: t('ordersPurchases.supplier'),
      dataIndex: 'supplier',
      render: (supplier) => supplier?.name
    },
    {
      title: t('ordersPurchases.category'),
      dataIndex: 'category',
      sorter: true,
      render: (renderedCategories) => ordersPurchase[renderedCategories],
      filters:
        categories &&
        categories?.map((c) => ({
          text: ordersPurchase[c],
          value: c
        }))
    },
    {
      title: t('ordersPurchases.site'),
      dataIndex: 'site',
      render: (site) =>
        site.map((item) => (
          <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
        )),
      sorter: true,
      filters:
        sites &&
        sites?.map((c) => ({
          text: c.label,
          value: c._id
        }))
    },
    {
      title: t('ordersPurchases.order_date'),
      dataIndex: 'order_date',
      render: (order_date) =>
        order_date ? moment(order_date).format('DD/MM/YYYY') : 'N/R'
    },
    {
      title: t('ordersPurchases.removal_date'),
      dataIndex: 'removal_date',
      render: (removal_date) =>
        removal_date ? moment(removal_date).format('DD/MM/YYYY') : 'N/R'
    }
  ];
};

export default useColumns;
