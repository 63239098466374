import { Button, Col, DatePicker, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import ChartQuotation from './ChartQuotation';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import PieQuotationTypeProject from './PieQuotationTypeProject';
import PieQuotationTypeCustomer from './PieQuotationTypeCustomer';
import PieQuotationByPro from './PieQuotationByPro';

const QuotationShortcut = ({ sites }) => {
  const { dispatchAPI } = useAuthContext();
  const [quotes, setQuotes] = useState([]);
  const [orders, setOrders] = useState([]);
  const { message } = useErrorMessage();
  const [totals, setTotals] = useState(0);
  const [taux, setTaux] = useState(0);
  const [average, setAverage] = useState(0);
  const [filter, setFilters] = useState({
    commercial_site: null,
    production_site: null,
    projectType: null,
    date_lowest: null,
    date_highest: null
  });
  const [projectType, setProjectType] = useState([]);
  const [quot, setData] = useState([]);

  const getQuotes = async (parameter) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes?${parameter}`
      });
      setData(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    if (quot.length) {
      getTotal(quot);
      setTaux(
        (quot.filter((el) => el.status === 'VALIDATE').length / quot.length) *
          100
      );
    } else {
      setTotals(0);
      setTaux(0);
    }
  }, [quot]);
  const checkTypeProject = (el, statut) => {
    return (
      el.status === statut &&
      (el?.section_1?.project_type === filter.projectType ||
        el?.section_2?.project_type === filter.projectType ||
        el?.section_3?.project_type === filter.projectType ||
        el?.section_4?.project_type === filter.projectType ||
        el?.section_5?.project_type === filter.projectType)
    );
  };
  const checkCommercialSite = (el, statut) => {
    return (
      el.status === statut &&
      el.infos.commercial_site._id === filter.commercial_site
    );
  };
  const checkProductionSite = (el, statut) => {
    return (
      el.status === statut &&
      el.infos.production_site._id === filter.production_site
    );
  };
  useEffect(() => {
    if (
      !(
        filter?.commercial_site ||
        filter?.production_site ||
        filter?.projectType ||
        filter?.date_lowest ||
        filter?.date_highest
      )
    ) {
      setData(quotes);
    } else {
      let newQuotPending = quot;
      let newQuotValidate = quot;
      if (filter.commercial_site) {
        newQuotPending = newQuotPending.filter((el) =>
          checkCommercialSite(el, 'PENDING')
        );
        newQuotValidate = newQuotValidate.filter((el) =>
          checkCommercialSite(el, 'VALIDATE')
        );
      }
      if (filter.production_site) {
        newQuotPending = newQuotPending.filter((el) =>
          checkProductionSite(el, 'PENDING')
        );
        newQuotValidate = newQuotValidate.filter((el) =>
          checkProductionSite(el, 'VALIDATE')
        );
      }
      if (filter.projectType) {
        newQuotPending = newQuotPending.filter((el) =>
          checkTypeProject(el, 'PENDING')
        );
        newQuotValidate = newQuotValidate.filter((el) =>
          checkTypeProject(el, 'VALIDATE')
        );
      }
      if (filter.date_lowest || filter.date_highest) {
        let filterParameter = '';
        Object.keys(filter).forEach((key) => {
          if (key === 'date_lowest' || key === 'date_highest') {
            if (filter[key]) {
              filterParameter += `${key}=${filter[key]}&`;
            }
          }
        });
        getQuotes(filterParameter);
      }
      setData([...newQuotValidate, ...newQuotPending]);
    }
  }, [filter]);
  // Fonction pour calculer le montant des devis en attente
  const getTotal = (quotePend) => {
    const quotesPending = quotePend.filter((el) => el.status === 'PENDING');
    let totalsOfquotesPending = 0;
    for (let index = 0; index < quotesPending.length; index += 1) {
      totalsOfquotesPending += quotesPending[index].totals.totalClient;
    }
    setTotals(totalsOfquotesPending);
  };
  const getAverageDifference = (ordersnew, quots) => {
    let averageDifference = 0;
    let totalDifference = 0;
    let totalOrders = 0;
    const listIdQuot = [];
    if (quots.length) {
      quots.map((el) => {
        listIdQuot.push(el._id);
      });
      ordersnew.forEach((order) => {
        if (order.quote && listIdQuot.includes(order.quote._id)) {
          totalOrders += 1;
          totalDifference += moment(order.created_at).diff(
            moment(order.quote.created_at),
            'days'
          );
        }
      });
      averageDifference = totalDifference / totalOrders;
      setAverage(averageDifference);
    } else setAverage(0);
  };
  const getQuotesOrders = async () => {
    try {
      const AllQuotes = await dispatchAPI('GET', {
        url: `/quotes?status!=DELETED`
      });
      const AllOrders = await dispatchAPI('GET', { url: `/ordersnew` });
      setQuotes(AllQuotes.data);
      setData(AllQuotes.data);
      setOrders(AllOrders.data);
      const AllprojectTypes = [];
      AllQuotes.data.forEach((quote) => {
        if (quote.section_1 && quote.section_1.project_type) {
          AllprojectTypes.push(quote.section_1.project_type);
        }
        if (quote.section_2 && quote.section_2.project_type) {
          AllprojectTypes.push(quote.section_2.project_type);
        }
        if (quote.section_3 && quote.section_3.project_type) {
          AllprojectTypes.push(quote.section_3.project_type);
        }
        if (quote.section_4 && quote.section_4.project_type) {
          AllprojectTypes.push(quote.section_4.project_type);
        }
        if (quote.section_5 && quote.section_5.project_type) {
          AllprojectTypes.push(quote.section_5.project_type);
        }
      });
      setProjectType(Array.from(new Set(AllprojectTypes)));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    getQuotesOrders();
  }, []);
  useEffect(() => {
    if (quot.length) getAverageDifference(orders, quot);
    else setAverage(0);
  }, [orders, quot]);

  return (
    <div
      justify="space-between"
      style={{
        background: 'rgb(249, 251, 252)',
        padding: 14,
        backgroundColor: '#edeef0',
        boxShadow: '0.5rem 0.5rem 1rem -1rem rgb(58,57,57)'
      }}
    >
      <Row gutter={[0, 8]}>
        <Col
          xs={6}
          xl={3}
          xxl={4}
          style={{
            fontSize: 16,
            textAlign: 'left',
            letterSpacing: 2
          }}
        >
          DEVIS
        </Col>
        <Col xs={24} md={18} xl={13} xxl={12}>
          Filtres :
          <Select
            allowClear
            style={{ marginLeft: 5, width: 150 }}
            onChange={(value) => {
              setFilters({ ...filter, commercial_site: value });
            }}
            placeholder="Site de demande"
            options={
              sites && sites.map(({ _id, label }) => ({ label, value: _id }))
            }
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) => {
              setFilters({ ...filter, production_site: value });
            }}
            placeholder="Site de fabrication"
            options={
              sites && sites.map(({ _id, label }) => ({ label, value: _id }))
            }
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) => setFilters({ ...filter, projectType: value })}
            placeholder="Type de projet"
            options={
              projectType && projectType.map((el) => ({ el, value: el }))
            }
          />
        </Col>
        <Col xs={24} xl={8}>
          Du
          <DatePicker
            style={{
              marginLeft: 4,
              marginRight: 4,
              minWidth: 120,
              width: '40%'
            }}
            onChange={(value) => {
              setFilters({ ...filter, date_lowest: value });
            }}
          />
          au
          <DatePicker
            style={{
              marginLeft: 4,
              marginRight: 4,
              minWidth: 120,
              width: '40%'
            }}
            onChange={(value) => setFilters({ ...filter, date_highest: value })}
          />
        </Col>
      </Row>
      <Row style={{ margin: '20px 0px 20px 0px' }} gutter={[16, 8]}>
        <Col
          style={{
            height: '100%',
            padding: '15px 0 20px 0'
          }}
          xs={24}
          md={12}
          xl={7}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '0 0 5px 0'
            }}
          >
            <Link to={{ pathname: '/customers_accounting/quotes' }}>
              <Button className="customShortcut">LISTE DES DEVIS</Button>
            </Link>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <Link to="/customers_accounting/quotes/create">
              <Button className="customShortcut">+ CRÉER UN DEVIS</Button>
            </Link>
          </div>
        </Col>
        <Col
          xs={24}
          sm={12}
          xl={5}
          style={{
            height: '100%',
            padding: '20px'
          }}
        >
          <div
            style={{
              textAlign: 'center'
            }}
          >
            MONTANT DES DEVIS EN ATTENTE :
          </div>
          <div
            style={{
              fontWeight: 900,
              fontSize: 25,
              letterSpacing: 2,
              textAlign: 'center'
            }}
          >
            {numeral(totals).format('0,0.00')} €
          </div>
        </Col>
        <Col
          style={{
            height: '100%',
            textAlign: 'center',
            padding: '20px'
          }}
          xs={24}
          sm={12}
          xl={5}
        >
          <div style={{ textAlign: 'center' }}>
            TAUX DE TRANSFORMATION DES DEVIS :
          </div>
          <div
            style={{
              fontWeight: 900,
              fontSize: 25,
              letterSpacing: 2
            }}
          >
            {numeral(taux).format('0.00')}%
          </div>
        </Col>
        <Col
          style={{
            height: '100%',
            padding: '20px'
          }}
          xs={24}
          sm={12}
          xl={5}
        >
          <div
            style={{
              textAlign: 'center'
            }}
          >
            TEMPS MOYEN DE TRANSFORMATION DES DEVIS :
          </div>
          <div
            style={{
              fontWeight: 900,
              fontSize: 25,
              letterSpacing: 2,
              textAlign: 'center'
            }}
          >
            {numeral(average).format('0.00')} J
          </div>
        </Col>
      </Row>
      <Row>
        <Row style={{ width: '100%' }}>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px' }}>STATUT DE DEVIS:</div>
            <div style={{ padding: ' 15px 100px' }}>
              <ChartQuotation md={6} sm={6} quotes={quot} />
            </div>
          </Col>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px' }}>TYPE DE PROJET:</div>
            <div>
              <PieQuotationTypeProject quotes={quot} />
            </div>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px' }}>TYPE DE CLIENT:</div>
            <div>
              <PieQuotationTypeCustomer quotes={quot} />
            </div>
          </Col>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px' }}>
              RÉPARTITION PAR TYPE DE PROFESSIONNEL:
            </div>
            <div style={{ padding: '0 0 5px 80px' }}>
              <PieQuotationByPro quotes={quot} />
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};
QuotationShortcut.propTypes = {
  sites: PropTypes.shape().isRequired
};
export default QuotationShortcut;
