import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

const style = {
  background: 'black',
  color: 'white',
  border: 'black',
  margin: '3px'
};

const OptionsRow = ({ setExtraQuery }) => {
  const [sites, setSites] = useState([]);
  const { dispatchAPI } = useAuthContext();

  const handleClick = (e) => {
    setExtraQuery(e);
  };
  const fetchSites = async () => {
    const { data } = await dispatchAPI('GET', { url: '/sites' });
    setSites(data);
  };
  useEffect(() => {
    fetchSites();
  }, []);

  const buttons = sites.map((site) => {
    return (
      <Button
        style={style}
        onClick={() => handleClick(`site=${site._id}`)}
        key={site.id}
      >
        {site.label}
      </Button>
    );
  });

  return (
    <>
      <span>Filtrer par : </span>
      {buttons}
      <Button style={style} onClick={() => handleClick('')}>
        ...TOUT
      </Button>
    </>
  );
};

OptionsRow.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};

export default OptionsRow;
