import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DownloadGeneratedFile = ({ element, template, downloadFile }) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      {template && (
        <Button
          type="primary"
          icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
          onClick={async () => {
            if (element?.validated) {
              if (!isDownloading) {
                setIsDownloading(true);
                await downloadFile(
                  element._id,
                  `${element.order.number}.pdf`,
                  () => setIsDownloading(false)
                );
              }
            } else {
              return Modal.info({
                title: 'info',
                content:
                  'Veuillez valider définitivement avant de téléchargement ce document.'
              });
            }
          }}
        >
          {t('buttons.download')}
        </Button>
      )}
    </>
  );
};

DownloadGeneratedFile.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.string,
    order: PropTypes.shape({ number: PropTypes.string }),
    validated: PropTypes.bool
  }).isRequired,
  template: PropTypes.shape({}).isRequired,
  downloadFile: PropTypes.func.isRequired
};

export default DownloadGeneratedFile;
