import React from 'react';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sitesTypes, ordersPurchase } from '../../../utils/constants/tagColors';

const useListContent = ({
  supplier,
  site,
  category,
  order,
  price,
  status,
  order_date,
  removal_date
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'ordersPurchases.site',
      content: site
        ? site.map((item) => (
            // eslint-disable-next-line react/jsx-indent
            <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
          ))
        : '-',
      span: 3
    },
    {
      label: 'ordersPurchases.supplier',
      content: supplier ? supplier?.name : '-',
      span: 3
    },
    {
      label: 'ordersPurchases.order_date',
      content: moment(order_date).format('DD/MM/YYYY'),
      span: 3
    },
    {
      label: 'ordersPurchases.removal_date',
      content: removal_date ? moment(removal_date).format('DD/MM/YYYY') : '-',
      span: 3
    },

    {
      label: 'ordersPurchases.category',
      content: category && ordersPurchase[category],
      span: 3
    },
    order
      ? {
          label: 'ordersPurchases.order',
          content: (
            <Link to={`/customers_accounting/orders_sales/show/${order?._id}`}>
              {`Commande N°${order?.number}`}
            </Link>
          ),
          span: 3
        }
      : {
          label: 'ordersPurchases.noOrder',
          content: '',
          span: 3
        },
    {
      label: 'ordersPurchases.price',
      content: price || '-',
      span: 3
    },
    {
      label: 'ordersPurchases.status',
      content: status ? t(`orders-buyings.form.status-values.${status}`) : '-',
      span: 3
    }
  ];
};

export default useListContent;
