import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import CommentariesTable from './commentariesTable';

const CommentarySettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchCommentaries = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/comments`
      });
      setComments(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/comments/enums`
      });
      setCategories(data.types);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addComment = async () => {
    const body = {
      label: 'Nouveau',
      type: 'MATTERS',
      comment: 'Mon commentaire ...'
    };
    try {
      await dispatchAPI('POST', { url: '/comments', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchCommentaries();
  };

  const deleteComment = async (comment) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/comments/${comment._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Comment_delete'));
    }
    fetchCommentaries();
  };

  const editComment = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/comments/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchCommentaries();
  };

  useEffect(() => {
    fetchCommentaries();
    fetchCategories();
  }, []);

  return (
    <>
      {comments.length > 0 && (
        <CommentariesTable
          comments={comments}
          categories={categories}
          editComment={editComment}
          deleteComment={deleteComment}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addComment}
      >
        {t('settings.commentary.addComment')}
      </Button>
    </>
  );
};

export default CommentarySettings;
