/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Row, Tag } from 'antd';
import { useDrag } from 'react-dnd';
import {
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useDealContext from './DealsContext';
import ModalCard from './ModalCard';
import StockTagForMatter from '../Stocks/StockTagForMatter';

const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  margin-top: 0px;
  margin-bottom: 10px;
  min-height: 170px;
  max-height: 260px;
  height: auto;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 8px -6px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  a,
  .anticon:not(.anticon-close) {
    color: #7c7c7c;
  }

  .anticon-edit {
    margin-left: 10px;
  }

  .anticon:not(.anticon-close) {
    transition: all 0.3s;
    &:hover {
      font-size: 24px;
    }
  }
`;

const EditInfo = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 18px;
`;

const DealCard = ({ deal }) => {
  const { path } = useRouteMatch();
  const { canEditResource } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [quotlines] = useState([]);
  const [mattersArray, setMattersArray] = useState([]);
  const { isLoading, setIsLoading } = useDealContext();

  const [inStockCount, setInStockCount] = useState(0);

  const incrementStockCount = () => {
    setInStockCount(inStockCount + 1);
  };

  const { _id } = deal;
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    canDrag: () => {
      if (canEditResource(path)) return true;
      return false;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && setIsLoading({ [deal._id]: true })
    })
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const getStockTag = () => {
    let nbElements = 0;
    const nbElementsEnStock = inStockCount;
    Object.keys(deal?.orders || []).forEach((key) => {
      if (key.startsWith('section_')) {
        if (deal?.orders?.[key]?.elements) {
          nbElements += deal.orders[key].elements.length;
        }
      }
    });

    if (nbElementsEnStock === nbElements) {
      return (
        <Tag
          style={{ fontSize: 13 }}
          color="green"
          icon={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
        >
          en stock
        </Tag>
      );
    }
    if (nbElementsEnStock > 0) {
      return (
        <Tag style={{ fontSize: 13 }} color="orange">
          {`${nbElementsEnStock}/${nbElements} en stock`}
        </Tag>
      );
    }

    return (
      <Tag
        style={{ fontSize: 13 }}
        color="red"
        icon={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
      >
        en rupture de stock
      </Tag>
    );
  };

  useEffect(() => {
    if (deal) {
      const _mattersArray = [];
      const { orders } = deal;
      Object.keys(orders || []).forEach((key) => {
        if (key.startsWith('section_')) {
          orders[key].elements.forEach((element) => {
            const sizeArray = [];
            (element.sizes || []).forEach(({ width, height }) => {
              sizeArray.push({ width, height });
            });
            const matterWithSize = {
              ...element,
              size: sizeArray
            };
            _mattersArray.push(matterWithSize);
          });
        }
      });
      setMattersArray(_mattersArray);
    }
    if (deal.quotation) {
      deal.quotation.quotlines.map((el) =>
        el.elements.map((e) => quotlines.push(e))
      );
    }
    form.setFieldsValue({
      estimated_date_measure: moment(deal.orders?.estimated_date_measure),
      estimated_date_lay: moment(deal.orders?.estimated_date_lay),
      manifacturing_site: deal.orders?.manifacturing_site
        ? [deal.orders.manifacturing_site]
        : [],
      lay_site: deal.orders?.lay_site
    });
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      {!isLoading[deal._id] && (
        <Card
          ref={drag}
          style={{ visibility: isDragging ? 'hidden' : 'visible' }}
        >
          <Tag
            style={{ borderRadius: '2px' }}
            color={
              deal.commercial_site?.request_color &&
              deal.commercial_site?.request_color?.hex
            }
          >
            <span>{`DEM.${deal.commercial_site?.label.toUpperCase()}`}</span>
          </Tag>
          {deal.production_sites.map((site) => (
            <Tag
              color={site.production_color && site.production_color.hex}
              style={{ borderRadius: '2px' }}
            >
              <span>{`FAB.${site?.label.toUpperCase()}`}</span>
            </Tag>
          ))}
          {deal?.color?.map((cardLabel) => (
            <Tag color={cardLabel.color} style={{ borderRadius: '2px' }}>
              <span>{cardLabel.label.toUpperCase()}</span>
            </Tag>
          ))}
          <div
            style={{ fontSize: 14, margin: '12px 0 8px 0', fontWeight: 500 }}
          >
            {`${deal?.orders?.infos?.customer?.first_name || ''} ${
              deal?.orders?.infos?.customer?.last_name
            }`}
          </div>
          {Object.keys(deal?.orders || []).map((key) => {
            if (key.startsWith('section_')) {
              if (deal?.orders[key].elements) {
                return deal?.orders[key].elements.map((el) => {
                  return (
                    <div>
                      {`${el?.matter} ${el?.color?.color} ${el?.thikness} cm`}
                      <StockTagForMatter
                        small
                        matter={el}
                        sizesNeeded={el?.sizes}
                        incrementStockCount={incrementStockCount}
                      />
                    </div>
                  );
                });
              }
            }
            return null;
          })}
          <div style={{ fontSize: 13, marginTop: 8 }}>
            <span style={{ marginRight: 6 }}>Date metré :</span>
            <b>
              {deal.worksheet?.measurement_date
                ? moment(deal.worksheet.measurement_date).format('DD/MM/YYYY')
                : 'Pas de Date du metré'}
            </b>
          </div>
          <div style={{ fontSize: 13 }}>
            <span style={{ marginRight: 6 }}>Date chantier :</span>
            <b>
              {deal.worksheet?.date
                ? moment(deal.worksheet.date).format('DD/MM/YYYY')
                : 'Pas de Date du metré'}
            </b>
          </div>
          <Row style={{ marginTop: 6 }} align="middle">
            {getStockTag()}
          </Row>
          <ModalCard
            deal={deal}
            isVisible={isModalVisible}
            setIsVisible={setIsModalVisible}
            mattersArray={mattersArray}
          />
          <EditInfo>
            {deal.archived ? (
              <EyeOutlined onClick={showModal} />
            ) : (
              <EditOutlined onClick={showModal} />
            )}
          </EditInfo>
        </Card>
      )}
    </div>
  );
};

DealCard.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.string,
    worksheet: PropTypes.shape({
      measurement_date: PropTypes.any,
      date: PropTypes.any
    }),
    archived: PropTypes.bool,
    buildingSite: PropTypes.shape({
      _id: PropTypes.any,
      label: PropTypes.any
    }),
    color: PropTypes.array,
    commercial_site: PropTypes.shape({
      commercial_color: PropTypes.shape({
        hex: PropTypes.any
      }),
      label: PropTypes.shape({
        toUpperCase: PropTypes.func
      }),
      request_color: PropTypes.shape({
        hex: PropTypes.any
      })
    }),
    estimated_date_lay: PropTypes.any,
    estimated_date_measure: PropTypes.any,
    label: PropTypes.shape({
      toUpperCase: PropTypes.func
    }),
    lay_site: PropTypes.shape({
      label: PropTypes.any
    }),
    lead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    manufacturing_site: PropTypes.shape({
      label: PropTypes.any
    }),
    name: PropTypes.string,
    orders: PropTypes.shape({
      _id: PropTypes.any,
      estimated_date_lay: PropTypes.any,
      estimated_date_measure: PropTypes.any,
      infos: PropTypes.shape({
        customer: PropTypes.shape({
          first_name: PropTypes.any,
          last_name: PropTypes.any
        })
      }),
      lay_site: PropTypes.any,
      manifacturing_site: PropTypes.any,
      number: PropTypes.any,
      section_1: PropTypes.shape({
        elements: PropTypes.any
      })
    }),
    priority: PropTypes.string,
    production_sites: PropTypes.shape({
      map: PropTypes.func
    }),
    quotation: PropTypes.shape({
      quotlines: PropTypes.shape({
        map: PropTypes.func
      })
    })
  }).isRequired
};

export default DealCard;
