import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, message, Popconfirm, Skeleton, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../utils/constants/routes';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ImportFiles from '../../components/importFiles';
import { enumsStatus } from '../../utils/constants/tagColors';

const ShowProfesional = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [profesional, setProfesional] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [quotes, setQuotes] = useState([]);

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/profesionals/${id}?populate=site,promotion,settlement_condition`
      });
      setProfesional(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  const getQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes?profesional=${id}`
      });
      setQuotes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await getQuotes();
    })();
  }, [fetchData, forceRefresh]);

  const deleteProfesional = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/profesionals/${id}` });
      history.push(`${routes.COMMERCIAL}${subRoutes.COMMERCIAL.PROFESIONALS}`);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const columns = [
    {
      title: t('quotations.list.column.title.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('quotes.commercial_site'),
      dataIndex: 'infos',
      render: (infos) => infos?.commercial_site?.label
    },
    {
      title: t('quotes.production_site'),
      dataIndex: 'infos',
      render: (infos) => infos?.production_site?.label
    },
    {
      title: t('quotations.list.column.title.status'),
      dataIndex: 'status',
      render: (status) => <Tag>{enumsStatus[status]}</Tag>
    },
    {
      title: t('quotes.created_at'),
      dataIndex: 'created_at',
      render: (created_at) =>
        created_at && moment(created_at).format('DD/MM/YYYY')
    },
    {
      title: t('configurator.totals_ttc'),
      dataIndex: 'totals',
      render: ({ totalsTTC }) => `${totalsTTC.toFixed(2)} €`
    }
  ];
  return (
    <>
      <PageHeaderCustom
        title={t(`companies.show.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.PROFESIONALS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteProfesional}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />

      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(profesional)} translate />
        </Skeleton>
        <ImportFiles
          id={id}
          files={profesional.files}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          resource="profesionals"
        />
        <Table
          dataSource={quotes}
          columns={columns}
          onRow={(key) => ({
            onDoubleClick: () =>
              history.push({
                pathname: `../../../customers_accounting/quotes/show/${key._id}`
              })
          })}
        />
      </ContentCustom>
    </>
  );
};

export default ShowProfesional;
