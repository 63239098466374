import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import {
  typeCatalogues,
  categoryProducts
} from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [targetsFilter, setTargetsFilter] = useState([]);

  const fetchEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'products/enums' });
      setTargetsFilter(
        data.targets.map((target) => ({
          text: (
            <Tag color={typeCatalogues[target]}>{target.toUpperCase()}</Tag>
          ),
          value: target
        }))
      );
      setCategoriesFilter(
        data.categories.map((cat) => ({
          text: <Tag color={categoryProducts[cat]}>{cat.toUpperCase()}</Tag>,
          value: cat
        }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, [fetchEnums]);

  return [
    {
      title: t('catalogues.column.ref'),
      key: 'ref',
      dataIndex: 'ref',
      sorter: true
    },
    {
      title: t('catalogues.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('catalogues.column.target'),
      key: 'target',
      dataIndex: 'target',
      render: (cat) =>
        cat && <Tag color={typeCatalogues[cat]}>{cat.toUpperCase()}</Tag>,
      sorter: true,
      filters: targetsFilter
    },
    {
      title: t('catalogues.column.category'),
      key: 'category',
      dataIndex: 'category',
      render: (cat) =>
        cat && <Tag color={categoryProducts[cat]}>{cat.toUpperCase()}</Tag>,
      sorter: true,
      filters: categoriesFilter
    },
    {
      title: t('catalogues.column.sold_price'),
      key: 'sold_price',
      dataIndex: 'sold_price',
      render: (price) =>
        typeof price === 'number'
          ? `${numeral(price).format('0,0.00')} € HT`
          : '...',
      sorter: true
    }
  ];
};

export default useColumns;
