const headers = [
  {
    label: 'Quotation number',
    key: 'number'
  },
  {
    label: 'emission date',
    key: 'emission_date'
  },
  {
    label: 'deadline date',
    key: 'deadline_date'
  },
  {
    label: 'Subject',
    key: 'subject'
  },
  {
    label: 'Comments',
    key: 'comment'
  },
  {
    label: 'Subtotal',
    key: 'subTotal'
  },
  {
    label: 'Total VAT',
    key: 'total_VAT'
  },
  {
    label: 'Total discount',
    key: 'total_discount'
  },
  {
    label: 'Final total',
    key: 'total'
  },
  {
    label: 'Customers',
    key: 'customer.name'
  },
  {
    label: 'Contact first name',
    key: 'contact.first_name'
  },
  {
    label: 'Contact last name',
    key: 'contact.last_name'
  },
  {
    label: 'Contact email',
    key: 'contact.email'
  }
];

export default headers;
