import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Space, Button, Card, Form, message, Col, Tag } from 'antd';
import moment from 'moment';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import MarbleBackground from '../../../assets/images/white-stone_Scott-Webb.jpg';
import Sections from './Sections';
import Infos from './Infos';
import DateInfos from './DateInfos';
import Buttons from './Buttons';

const Details = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(
    !(state.removalOrder && state.removalOrder.validated)
  );

  useEffect(() => {
    if (state) {
      form.setFieldsValue({
        order: state.removalOrder.order._id,
        worksheet: state.removalOrder.worksheet._id,
        date: moment(state.removalOrder.date),
        address_livraison: state.removalOrder.address_livraison
          ? state.removalOrder.address_livraison
          : `${state?.removalOrder.worksheet.customer.address.number ||
              ''} ${state?.removalOrder.worksheet.customer.address.street ||
              ''} ${
              state?.removalOrder.worksheet.customer.address.postal_code
            } ${state?.removalOrder.worksheet.customer.address.city}`,
        is_delivery: state.removalOrder.is_delivery
      });
    }
  }, [state]);

  const handleSave = async () => {
    const method = 'PATCH';
    const url = `/removal-order/${state.removalOrder._id}`;
    const body = form.getFieldsValue();
    try {
      await dispatchAPI(method, {
        url,
        body
      });
      history.push(
        `/customers_accounting/orders_sales/show/${state.removalOrder.order._id}`
      );
      setIsEditable(false);
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const handleValidate = async () => {
    const method = 'PATCH';
    const url = `/removal-order/${state.removalOrder._id}`;
    const body = form.getFieldsValue();
    try {
      if (body.date !== null) {
        await dispatchAPI(method, {
          url,
          body: { ...body, validated: true }
        });
        history.push(
          `/customers_accounting/orders_sales/show/${state.removalOrder.order._id}`
        );
      }
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <Space size="large">
            <div>
              {t(`removal_order.show.number`, {
                number: state?.removalOrder.order.number
              })}
              {'  '}
              {state.removalOrder.validated && <Tag color="green">VALIDÉ</Tag>}
            </div>
          </Space>
        }
        extra={
          <>
            <Buttons
              handleValidate={handleValidate}
              isEditable={isEditable}
              handleSave={handleSave}
              element={state.removalOrder}
            />
          </>
        }
      />
      <ContentCustom>
        <div
          style={{
            backgroundImage: `url(${MarbleBackground})`,
            backgroundSize: 'cover',
            padding: '2em'
          }}
        >
          <Card
            style={{ padding: 0, background: 'rgba(255, 255, 255, 0.8)' }}
            bordered={false}
          >
            <div
              style={{
                paddingLeft: '24px'
              }}
            >
              Veuillez vous assurer que les informations sont correctes avant de
              valider
            </div>
            <Row
              justify="space-between"
              align="stretch"
              style={{
                padding: '24px',
                flexWrap: 'nowrap',
                height: 'fit-content'
              }}
              gutter={24}
            >
              <Infos datas={state?.removalOrder.worksheet.customer} />
              <DateInfos
                form={form}
                datas={state?.removalOrder.worksheet.customer}
                isEditable={isEditable}
              />
            </Row>
            <Sections
              datas={state?.removalOrder.order}
              products={state?.removalOrder.order.products}
              accessories={state?.removalOrder.order.accessories}
            />
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Buttons
                  handleValidate={handleValidate}
                  isEditable={isEditable}
                  handleSave={handleSave}
                  element={state.removalOrder}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </ContentCustom>
    </>
  );
};

Details.propTypes = {
  datas: PropTypes.shape({
    _id: PropTypes.any,
    files: PropTypes.any,
    infos: PropTypes.any,
    invoice_number: PropTypes.any,
    number: PropTypes.any,
    placed: PropTypes.any,
    totals: PropTypes.any
  }).isRequired
};

export default Details;
