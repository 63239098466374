import PropTypes from 'prop-types';
import React from 'react';
import { Select, Table, Checkbox, Form, Input } from 'antd';

const ProcessForm = ({ operators, machines, process, sites, isEditable }) => {
  const columns = [
    {
      title: 'Usinages',
      dataIndex: 'label',
      width: '20%'
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity',
      width: '10%',
      render: (value, record) => (
        <Form.Item name={['process', record.label, 'quantity']}>
          <Input type="number" disabled={!isEditable} />
        </Form.Item>
      )
    },
    {
      title: 'Site',
      dataIndex: 'site',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['process', record.label, 'site']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={sites.map(({ label, _id }) => ({
              key: _id,
              value: label
            }))}
          />
        </Form.Item>
      )
    },
    {
      title: 'Machines',
      dataIndex: 'machine',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['process', record.label, 'machine']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={machines.map(({ label, _id }) => ({
              key: _id,
              value: label
            }))}
          />
        </Form.Item>
      )
    },

    {
      title: 'Opérateur',
      dataIndex: 'operator',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['process', record.label, 'operator']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={operators.map(({ name, _id }) => ({
              key: _id,
              value: name
            }))}
          />
        </Form.Item>
      )
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      width: '10%',
      render: (value, record) => (
        <Form.Item
          name={['process', record.label, 'status']}
          valuePropName="checked"
          label="Fait :"
        >
          <Checkbox disabled={!isEditable} />
        </Form.Item>
      )
    }
  ];

  return <Table dataSource={process} columns={columns} pagination={false} />;
};

ProcessForm.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  machines: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  operators: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  process: PropTypes.shape({
    _id: PropTypes.string,
    end_date: PropTypes.string,
    label: PropTypes.string,
    machine: PropTypes.string,
    operator: PropTypes.string,
    quantity: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ProcessForm;
