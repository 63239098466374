import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children }) => {
  return (
    <Content
      style={{
        padding: 24,
        marginTop: 2,
        background: 'var(--componentBackground)'
      }}
    >
      {children}
    </Content>
  );
};

ContentCustom.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
};

export default ContentCustom;
