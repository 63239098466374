import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import ProcessTable from './ProcessTable';

const ProcessSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [processs, setProcess] = useState([]);

  const fetchProcesss = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Processs`
      });
      setProcess(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addProcesss = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Processs', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchProcesss();
  };

  const deleteProcesss = async (process) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-Processs/${process._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchProcesss();
  };

  const editProcesss = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-Processs/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchProcesss();
  };

  useEffect(() => {
    fetchProcesss();
  }, []);

  return (
    <>
      {processs.length > 0 && (
        <ProcessTable
          processs={processs}
          editProcesss={editProcesss}
          deleteProcesss={deleteProcesss}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addProcesss}
      >
        {t('settings.Processs.addProcesss')}
      </Button>
    </>
  );
};

export default ProcessSettings;
