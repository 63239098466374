import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Layout, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import PaymentStripe from './PaymentStripe';
import PaymentGocardless from './PaymentGocardless';

const { Content } = Layout;

const btnStyle = {
  background: 'rgb(71, 210, 103)',
  color: '#fff',
  border: 'rgb(71, 210, 103)',
  borderRadius: 3,
  marginLeft: 10,
  float: 'right'
};

const Payment = () => {
  const { t } = useTranslation();
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/payment-system',
      breadcrumbName: t('payment.title')
    }
  ];
  // const cgType = "payment";
  const cgType = 'subscription';

  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          // eslint-disable-next-line react/prop-types
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        title={t('payment.title')}
        ghost={false}
      />

      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Row justify="center">
          <Col span={12}>
            <PaymentStripe amount={1000} btnStyle={btnStyle} />
          </Col>
          <Col span={12}>
            <PaymentGocardless
              amount={1000}
              type={cgType}
              reference="ABO01"
              btnStyle={btnStyle}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Payment;
