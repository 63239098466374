import React from 'react';
import PropTypes from 'prop-types';
import DescriptionList from '../../../../components/DescriptionList/DescriptionList';

const listClientContent = ({ status, name, address, phoneNumber, email }) => {
  return [
    {
      label: 'client.status',
      content: status
    },
    {
      label: 'client.name',
      content: name
    },
    {
      label: 'client.address',
      content: address
    },
    {
      label: 'client.phoneNumber',
      content: phoneNumber
    },
    {
      label: 'client.email',
      content: email
    }
  ];
};

const ClientInformationRow = ({ client }) => {
  return (
    <DescriptionList
      data={listClientContent(client)}
      backline
      translate
      column={3}
    />
  );
};

ClientInformationRow.propTypes = {
  client: PropTypes.shape({}).isRequired
};

export default ClientInformationRow;
