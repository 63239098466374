import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { sitesTypes, ordersPurchase } from '../../../utils/constants/tagColors';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders-buyings/enums'
      });
      setCategories(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
      await getCategories();
    })();
  }, []);
  const downloadFile = async (fileId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${fileId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return [
    {
      title: t('ordersPurchases.supplier'),
      dataIndex: 'supplier',
      render: (supplier) => supplier?.name
    },
    {
      title: t('ordersPurchases.category'),
      dataIndex: 'category',
      sorter: true,
      render: (renderedCategories) => ordersPurchase[renderedCategories],
      filters:
        categories &&
        categories?.map((c) => ({
          text: ordersPurchase[c],
          value: c
        }))
    },
    {
      title: t('ordersPurchases.site'),
      dataIndex: 'site',
      render: (site) =>
        site.map((item) => (
          <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
        )),
      sorter: true,
      filters:
        sites &&
        sites?.map((c) => ({
          text: c.label,
          value: c._id
        }))
    },
    {
      title: t('ordersPurchases.order_date'),
      dataIndex: 'order_date',
      render: (order_date) =>
        order_date ? moment(order_date).format('DD/MM/YYYY') : 'N/R'
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: (data, { key }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/custom_accounting/orders_purchases/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>

            <Divider type="vertical" />
            {data.files.length !== 0 && (
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={async () => {
                  if (!isDownloading[key]) {
                    setIsDownloading({ ...isDownloading, [key]: true });
                    await downloadFile(
                      data.files[0]._id,
                      data.files[0].filename
                    );
                  }
                  setIsDownloading({ ...isDownloading, [key]: false });
                }}
              >
                {isDownloading[key] ? (
                  <LoadingOutlined style={iconStyle} />
                ) : (
                  <DownloadOutlined style={iconStyle} />
                )}
              </Button>
            )}
          </span>
        );
      }
    }
  ];
};

export default useColumns;
