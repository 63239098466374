import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { OrderContextProvider } from './OrderContext';
import List from './List/index';
import Details from './Details/index';

const Router = () => {
  const { path } = useRouteMatch();
  const [type, setType] = useState(path?.split('/').slice(-1)[0]);

  useEffect(() => {
    setType(path?.split('/').slice(-1)[0]);
  }, [path]);

  return (
    <OrderContextProvider collection="ordersnew" type={type}>
      <Switch>
        <Route path={`${path}/show/:id`} component={Details} />
        <Route path={path} component={List} />
      </Switch>
    </OrderContextProvider>
  );
};

export default Router;
