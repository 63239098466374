import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import Datatable from '../../../components/DataTable/Datatable';
import useAuthContext from '../../../contexts/AuthContext';
import { ColumnsViews } from './Columns';
import { SearchAddBar } from '../../../utils/constants/customButton';

const Container = styled.div`
  padding: 24px;
  background-color: white;
`;

const Account = () => {
  const columns = ColumnsViews();
  const { dispatchAPI } = useAuthContext();
  const [payments, setPayments] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const { t } = useTranslation();

  const routes = [
    {
      path: './',
      breadcrumbName: t('home.title')
    },
    {
      breadcrumbName: 'Comptes'
    }
  ];

  const fetchData = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/accounts/` });
    setPayments(data.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <PageHeader
        className="site-page-header"
        title={t('accounts.title')}
        style={{ backgroundColor: 'white', marginBottom: 1 }}
        breadcrumb={{ routes }}
        onBack={() => window.history.back()}
      />
      <Container>
        <SearchAddBar addPath="/accounts/new" setSearchValue={setSearchValue} />
        <Datatable columns={columns} resourceName="accounts" />
      </Container>
    </>
  );
};

export default Account;
