import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExportOutlined
} from '@ant-design/icons';
import DownloadGeneratedFile from './DownloadGeneratedFile';

const WorksheetButtons = ({
  state,
  template,
  downloadFile,
  isEditable,
  handleValidate,
  handleDelete,
  handleOk
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="primary"
        icon={<ExportOutlined />}
        disabled
        style={{ margin: '0 4px' }}
      >
        {t('worksheets.button.trello')}
      </Button>
      {state.worksheet && (
        <DownloadGeneratedFile
          element={state.worksheet}
          template={template}
          downloadFile={downloadFile}
        />
      )}
      <Tooltip
        title={isEditable ? '' : t('worksheets.button.message-validation')}
      >
        <Button
          disabled={state?.worksheet?.validated}
          type="primary"
          icon={<CheckOutlined />}
          onClick={handleValidate}
          style={{ margin: '0 4px' }}
        >
          {t('worksheets.button.validate')}
        </Button>
      </Tooltip>
      <Tooltip
        title={isEditable ? '' : t('worksheets.button.message-validation')}
      >
        <Button
          type="primary"
          disabled={!isEditable}
          icon={<CloseOutlined />}
          onClick={handleDelete}
          style={{ margin: '0 4px' }}
        >
          {t('worksheets.button.delete')}
        </Button>
      </Tooltip>
      <Button type="primary" onClick={handleOk} style={{ margin: '0 4px' }}>
        {t('worksheets.button.save')}
      </Button>
    </>
  );
};

export default WorksheetButtons;

WorksheetButtons.propTypes = {
  state: PropTypes.shape({ worksheet: PropTypes.any }).isRequired,
  template: PropTypes.shape({}).isRequired,
  isEditable: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired
};
