import React from 'react';
import { Button, Dropdown, Menu, Space, Spin } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

export const DrowpdownDownloadButton = ({
  recordKey,
  number,
  isDownloading,
  setIsDownloading,
  downloadFile,
  buttonText,
  details,
  callback
}) => {
  const { t } = useTranslation();

  const handleDownload = async (fileType) => {
    if (!isDownloading[recordKey]) {
      setIsDownloading((prev) => ({ ...prev, [recordKey]: true }));
      await downloadFile(
        recordKey,
        `${number}.${fileType}`,
        callback,
        details,
        fileType
      );
      setIsDownloading((prev) => ({ ...prev, [recordKey]: false }));
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="pdf">
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() => handleDownload('pdf')}
        >
          <Space>
            {isDownloading[recordKey] ? (
              <LoadingOutlined style={iconStyle} />
            ) : (
              <DownloadOutlined style={iconStyle} />
            )}
            {t('buttons.download_pdf')}
          </Space>
        </Button>
      </Menu.Item>
      <Menu.Item key="docx">
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() => handleDownload('docx')}
        >
          <Space>
            {isDownloading[recordKey] ? (
              <LoadingOutlined style={iconStyle} />
            ) : (
              <DownloadOutlined style={iconStyle} />
            )}
            {t('buttons.download_docx')}
          </Space>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <Space>
          {isDownloading[recordKey] ? (
            <Spin />
          ) : (
            <DownloadOutlined style={iconStyle} />
          )}

          {buttonText}
        </Space>
      </Button>
    </Dropdown>
  );
};

DrowpdownDownloadButton.propTypes = {
  recordKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDownloading: PropTypes.shape({}).isRequired,
  setIsDownloading: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  details: PropTypes.bool,
  callback: PropTypes.func
};

DrowpdownDownloadButton.defaultProps = {
  buttonText: undefined,
  details: undefined,
  callback: null
};
