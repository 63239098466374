import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const Revenu = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [categories, setCategories] = useState([]);

  const columns = [
    {
      title: t('expenses.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: (date) => {
        return new Date(date).toLocaleDateString('fr-FR');
      }
    },
    {
      title: t('expenses.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('expenses.form.category'),
      key: 'category',
      dataIndex: 'category',
      sorter: true,
      render: (category) =>
        category && (
          <Tag color={category.color}>{category.label.toUpperCase()}</Tag>
        ),
      filters:
        categories && categories.map((c) => ({ text: c.label, value: c._id }))
    },
    {
      title: t('expenses.form.amount'),
      key: 'amount_ht',
      dataIndex: 'amount_ht',
      sorter: true,
      render: (value) => {
        return `${value} €`;
      }
    },
    {
      title: t('expenses.form.supplier'),
      key: 'id_supplier',
      dataIndex: 'id_supplier',
      sorter: true,
      render: (supplier) => supplier && `${supplier.name}`
    }
  ];

  const headers = [
    {
      label: 'Due_date',
      key: 'date'
    },
    {
      label: 'wording',
      key: 'wording'
    },
    {
      label: 'category',
      key: 'category.label'
    },
    {
      label: 'Amount_HT',
      key: 'amount_ht'
    },
    {
      label: 'TVA',
      key: 'tva'
    },
    {
      label: 'Customers first name',
      key: 'id_supplier.first_name' || ''
    },
    {
      label: 'Customers last name',
      key: 'id_supplier.last_name' || ''
    },
    {
      label: 'Invoice is reconciled',
      key: 'is_reconciled'
    },
    {
      label: 'Invoice amount HT',
      key: 'amount_ht'
    },
    {
      label: 'Invoice total reconciled',
      key: 'total_reconciled'
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/categories' });
      setCategories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <SimpleResourceLandingLayout
        resourceName="expenses"
        columns={columns}
        populate="id_supplier,category"
        headers={headers}
        resourceModelName="Expense"
      />
    </>
  );
};

export default Revenu;
