import React from 'react';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';
import DartyImg from '../../../assets/images/darty.png';
import AtelierImg from '../../../assets/images/atelierLogo.png';

const ListDartyMatters = () => {
  const switchResource = (
    <Space size="large">
      <Link
        to={{
          pathname: `/catalogues/dartymatters`
        }}
      >
        <img src={DartyImg} style={{ width: 40 }} alt="darty" />
      </Link>
      <Link
        to={{
          pathname: `/catalogues/matters`
        }}
      >
        <img
          src={AtelierImg}
          style={{ width: 40, opacity: 0.3 }}
          alt="atelier"
        />
      </Link>
    </Space>
  );

  const headers = [
    {
      label: 'Label',
      key: 'label' || ''
    },
    {
      label: 'Description',
      key: 'description'
    },
    {
      label: 'Quantity',
      key: 'quantity'
    },
    {
      label: 'Reference',
      key: 'ref'
    },
    {
      label: 'Buy price',
      key: 'buy_price' || ''
    },
    {
      label: 'Category',
      key: 'category.label'
    },
    {
      label: 'TVA',
      key: 'tva'
    },
    {
      label: 'LD product',
      key: 'ld_product'
    },
    {
      label: 'In CA',
      key: 'in_ca'
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="dartymatters"
      columns={[...columns()]}
      withSubRoutes
      headers={headers}
      resourceModelName="DartyMatter"
      extraHeader={switchResource}
    />
  );
};

export default ListDartyMatters;
