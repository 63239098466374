import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import InvoiceRouter from './Invoices/InvoiceRouter';
import OrdersSupplies from './OrdersSupplies/OrdersSuppliesRouter';
import OrdersPurchases from './OrdersPurchases/OrdersPurchasesRouter';

const AccountRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.CUSTOM_ACCOUNTING.INVOICES}`}
        component={InvoiceRouter}
      />
      <Route
        path={`${path}${subRoutes.CUSTOM_ACCOUNTING.ORDERS_SUPPLIES}`}
        component={OrdersSupplies}
      />
      <Route
        path={`${path}${subRoutes.CUSTOM_ACCOUNTING.ORDERS_PURCHASES}`}
        component={OrdersPurchases}
      />
    </Switch>
  );
};

export default AccountRouter;
