import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import DescriptionList from '../../../../components/DescriptionList/DescriptionList';
import {
  enumsStatus,
  quoteStatus
} from '../../../../utils/constants/tagColors';

const pathQuotation = `/customers_accounting/quotes`;

const listQuoteContent = ({
  id,
  number,
  infos,
  projectTypes,
  totals,
  created_at,
  status
}) => {
  return [
    {
      label: 'quotes.number',
      content: (
        <Link to={`${pathQuotation}/show/${id}`}>
          <Tag>
            N°
            {number}
          </Tag>
        </Link>
      )
    },
    {
      label: 'quotes.status',
      content: <Tag color={quoteStatus[status]}>{enumsStatus[status]}</Tag>
    },
    {
      label: 'quotes.commercial_site',
      content: infos.commercial_site.label
    },
    {
      label: 'quotes.production_site',
      content: infos.production_site.label
    },
    {
      label: 'quotes.project_types',
      content: projectTypes
    },
    {
      label: 'quotes.total',
      content: ` ${totals.totalWithoutEcoTax.toFixed(2)} € HT`
    },
    {
      label: 'quotes.created_at',
      content: moment(created_at).format('DD/MM/YYYY')
    }
  ];
};

const QuoteInformationRow = ({ quote }) => {
  return (
    <DescriptionList
      data={listQuoteContent(quote)}
      backline
      translate
      column={4}
    />
  );
};

QuoteInformationRow.propTypes = {
  quote: PropTypes.shape({}).isRequired
};

export default QuoteInformationRow;
