/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Col, Upload, Button, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';

const { Dragger } = Upload;

const Validation = ({ isVisible, setIsVisible, quote, validateQuote }) => {
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [fileHasBeenUploaded, setFileHasBeenUploaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();

  const props = {
    name: 'file',
    multiple: false,
    disabled: fileHasBeenUploaded,
    action: `${process.env.REACT_APP_API_URL}/files/upload`,
    data: {
      type: 'QUOTE'
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      setFileHasBeenUploaded(true);

      if (
        info.file.response &&
        info.file.response.description &&
        info.file.response.description.id
      ) {
        setFile(info.file.response.description.id);
      }

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  const steps = [
    <ContentCustom style={{ margin: '5px' }}>
      <div>{t('orders.action.validate.sub-title')}</div>

      <Col span={24}>
        <Dragger {...props} style={{ margin: '5px' }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
          <p className="ant-upload-hint">{t('files.create.formats')}</p>
        </Dragger>
      </Col>
    </ContentCustom>,
    <ContentCustom style={{ margin: '5px' }}>
      <div>
        {t(`orders.action.validate.${quote?.order ? 'warning-order' : 'new'}`, {
          number: quote?.order?.number
        })}
      </div>
    </ContentCustom>
  ];

  const postSignedQuote = async () => {
    const method = 'POST';
    const url = '/signed-quote';
    const body = {
      file,
      quote: quote._id
    };
    await dispatchAPI(method, {
      url,
      body
    });
  };

  const deleteFile = async () => {
    const method = 'DELETE';
    const url = `/files/${file}`;
    await dispatchAPI(method, { url });
  };

  const handleCancel = async () => {
    if (currentStep === 1) {
      return setCurrentStep(0);
    }
    if (fileHasBeenUploaded) {
      await deleteFile();
    }
    setIsVisible(false);
  };

  const handleValidate = async () => {
    if (currentStep === 0 && fileHasBeenUploaded) {
      await postSignedQuote();
      return setCurrentStep(1);
    }

    await validateQuote({ id: quote._id, order: quote?.order?._id || null });
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={isVisible}
        maskClosable={false}
        span={24}
        width="60vw"
        title={t(`orders.action.validate.title`, { number: quote.number })}
      >
        {steps[currentStep]}
        <Row justify="end">
          <Button
            type="primary"
            onClick={handleCancel}
            style={{ margin: '5px' }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            type="primary"
            onClick={handleValidate}
            disabled={currentStep === 0 && !fileHasBeenUploaded}
            style={{ margin: '5px' }}
          >
            {t(`buttons.${currentStep === 0 ? 'validate' : 'enclose'}`)}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

Validation.propTypes = {
  forceRefresh: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  quote: PropTypes.shape({
    _id: PropTypes.string,
    order: PropTypes.string,
    number: PropTypes.string
  }).isRequired,
  setIsVisible: PropTypes.func.isRequired,
  validateQuote: PropTypes.func.isRequired,
  withLay: PropTypes.bool.isRequired
};

export default Validation;
