import React from 'react';
import PropTypes from 'prop-types';
import { Col, Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  column,
  backline
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title} column={column}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={
                  backline ? '' : translate ? t(`${item.label}`) : item.label
                }
                span={item.span || 1}
                key={item}
              >
                <Col>
                  {!backline ? '' : translate ? t(`${item.label}`) : item.label}
                  <Typography style={{ fontWeight: 700 }}>
                    {item.content === 'undefined%' ? '-' : item.content || '0'}
                  </Typography>
                </Col>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  column: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  title: PropTypes.string,
  translate: PropTypes.bool,
  backline: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  column: 1,
  backline: false
};

export default DescriptionList;
