import React, { useState } from 'react';
import { Button } from 'antd';
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const DownloadGeneratedFile = ({ element, template, downloadFile }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      {template && (
        <Button
          type="primary"
          icon={isDownloading ? <LoadingOutlined /> : <FilePdfOutlined />}
          onClick={async () => {
            if (!isDownloading) {
              setIsDownloading(true);
              await downloadFile(element._id, `${element.number}.pdf`, () =>
                setIsDownloading(false)
              );
            }
          }}
        >
          Générer
        </Button>
      )}
    </>
  );
};

DownloadGeneratedFile.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.string,
    section_1: PropTypes.shape({})
  }).isRequired,
  template: PropTypes.shape({}).isRequired,
  downloadFile: PropTypes.func.isRequired
};


export default DownloadGeneratedFile;
