import { useEffect, useState } from 'react';

const useFields = () => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const basicFields = [
    {
      name: ['status']
    },
    {
      name: ['profesional']
    },
    {
      name: ['building-site']
    }
  ];

  useEffect(() => {
    (async () => {})();
  }, []);

  return {
    basicFields,
    isFieldsLoading
  };
};

export default useFields;
