/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Select, DatePicker } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const PieceExitModal = ({
  forceRefresh,
  setForceRefresh,
  supply,
  setPieces
}) => {
  const [numbers, setNumbers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersWithMatter, setOrdersWithMatter] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [select, setSelect] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const margin = { margin: '16px' };

  const { Option } = Select;

  useEffect(() => {
    if (supply?.sizes) {
      const newNumbers = (supply?.sizes || [])
        .filter((size) => !size?.exit_date)
        .map((size) => size.number);
      setNumbers(newNumbers);
    }
  }, [supply]);

  useEffect(() => {
    let newOrdersWithMatter = [];
    orders.forEach((order) => {
      Object.keys(order).forEach((key) => {
        if (key.startsWith('section_')) {
          if (order[key]?.elements.length > 0) {
            order[key].elements.forEach((element) => {
              if (
                element.color === supply?.matter?._id &&
                parseFloat(element.thikness) === supply.thickness
              ) {
                newOrdersWithMatter.push(order);
              }
            });
          }
        }
      });
    });

    newOrdersWithMatter = Array.from(new Set(newOrdersWithMatter));

    setOrdersWithMatter(newOrdersWithMatter);
  }, [orders, supply]);

  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/ordersnew` });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getOrders();
    })();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const sizes = supply.sizes;
    const newSizes = sizes.map((size) => {
      if (size.number === select) {
        const exit = size;
        exit.exit_date = form.getFieldValue().exit_date;
        return exit;
      }
      return size;
    });

    const body = { sizes: newSizes };

    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/pieces/${id}`,
        body
      });
      setPieces(data.sizes);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setForceRefresh(!forceRefresh);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  };

  const onFinish = async () => {
    await handleOk();
  };

  return (
    <div>
      <Button type="primary" onClick={showModal} style={margin}>
        {t('stocks.exit')}
      </Button>

      <Modal
        title={t('stocks.exitTitle')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('stocks.number')}
            name="number"
            rules={[{ required: true }]}
          >
            <Select value={select} onSelect={(newValue) => setSelect(newValue)}>
              {(numbers || []).map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('stocks.order')} name="order">
            <Select
              value={selectedOrder}
              onSelect={(newValue) => setSelectedOrder(newValue)}
              showSearch
              optionFilterProp="children"
            >
              {(ordersWithMatter || []).map((item) => (
                <Option key={item._id} value={item._id} label={item.number}>
                  {item.number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            initialValue={moment()}
            label={t('stocks.exit_date')}
            name="exit_date"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
          />

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {t('buttons.validate')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

PieceExitModal.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  setPieces: PropTypes.func.isRequired,
  supply: PropTypes.shape({
    matter: PropTypes.shape({
      _id: PropTypes.any
    }),
    sizes: PropTypes.array,
    thickness: PropTypes.any
  }).isRequired
};

export default PieceExitModal;
