import PropTypes from 'prop-types';
import React from 'react';
import { Typography, Row, Col, Input, Form, Divider } from 'antd';

const Infos = ({ datas }) => {
  const { Title } = Typography;

  if (datas) {
    return (
      <Col span={12}>
        <Title level={5}>Informations Client</Title>
        <div style={{ width: '103px' }}>
          <hr
            style={{
              backgroundColor: 'black',
              height: '3px',
              border: 'none',
              marginBottom: '18px'
            }}
          />
        </div>

        <Row>
          <Col span={24}>
            {datas.first_name && (
              <Form.Item
                label="Raison sociale :"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Input
                  disabled
                  value={`${datas.first_name || ''} ${datas.last_name}`}
                />
              </Form.Item>
            )}

            {datas.address && (
              <Form.Item
                label="Adresse :"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Input
                  disabled
                  value={`${datas.address.number || ''} ${
                    datas.address.street
                  } ${datas.address.postal_code} ${datas.address.city}`}
                />
              </Form.Item>
            )}

            {datas.phone_number_1 && (
              <Form.Item
                label="Téléphone :"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Input disabled value={`${datas.phone_number_1}`} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>
    );
  }

  return <>No infos available </>;
};

Infos.propTypes = {
  datas: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.any,
      number: PropTypes.any,
      postal_code: PropTypes.any,
      street: PropTypes.any
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number_1: PropTypes.string
  }).isRequired
};

export default Infos;
