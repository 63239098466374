import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import numeral from 'numeral';

const PricingList = ({ pricing, soldPrice }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('catalogues.column.size'),
      dataIndex: 'size',
      key: 'size'
    },
    {
      title: t('catalogues.column.buy_price'),
      dataIndex: 'buy_price',
      key: 'buy_price',
      render: (buy_price) => `${numeral(buy_price).format('0,0.00')} € HT`
    },
    {
      title: t('catalogues.column.sold_price'),
      dataIndex: 'buy_price',
      key: 'buy_price',
      render: (buy_price) =>
        `${numeral(
          soldPrice.find((el) => buy_price === el.buy_price).sold_price
        ).format('0,0.00')} € HT`
    }
  ];
  return (
    <div style={{ width: '50%' }}>
      <Table
        columns={columns}
        dataSource={pricing}
        pagination={false}
        size="small"
      />
    </div>
  );
};

PricingList.propTypes = {
  pricing: PropTypes.string,
  soldPrice: PropTypes.string
};

PricingList.defaultProps = {
  pricing: null,
  soldPrice: null
};

export default PricingList;
