import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Card, Input, Select, Tag, Radio, Row, Form } from 'antd';
import { TagFilled } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { tasksPriorities } from '../../../utils/constants/tagColors';
import AddPrescriberButton from './AddPrescriberButton';
import AddCustomerButton from './AddCustomerButton';
import AddProfessionnalButton from './AddProfessionnalButton';
import useFilesField from './filesField';

const { Option } = Select;
const { TextArea } = Input;

const useFields = ({ visible, form, loaded }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [kanbanCols, setKanbanCols] = useState([]);
  const [sites, setSites] = useState([]);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [professionals, setProfessionnals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [prescribers, setPrescribers] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quotesVisisble, setQuotesVisible] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [selectedPrescribers, setSelectedPrescribers] = useState([]);
  const [files, setFiles] = useState([]);
  const refresh = () => {
    setForceRefresh(!forceRefresh);
  };
  const onFileUploaded = (file) => {
    setFiles([...files, file]);
  };
  const onFileRemoved = (file) => {
    setFiles(files.filter((f) => f !== file));
  };
  // function that setFormValues to the array of selected quotes given in parameter
  const setFormQuotes = (sQuotes) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      quotes: sQuotes.map((q) => {
        return {
          quote: q
        };
      })
    });
    setSelectedQuotes(sQuotes);
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      files: files || [],
      customers: selectedCustomers,
      prescribers: selectedPrescribers,
      professionals: selectedProfessionals
    });
  }, [selectedProfessionals, selectedPrescribers, selectedCustomers, files]);

  useEffect(() => {
    const values = form.getFieldsValue();
    const idCustomers = [];
    const idPrescribers = [];
    const idProfessionals = [];
    if (values?.customers) {
      values.customers.map((c) => idCustomers.push(c._id));
    }
    if (values?.prescribers) {
      values.prescribers.map((p) => idPrescribers.push(p._id));
    }
    if (values?.professionals) {
      values.professionals.map((p) => idProfessionals.push(p._id));
    }
    setSelectedCustomers(idCustomers);
    setSelectedPrescribers(idPrescribers);
    setSelectedProfessionals(idProfessionals);
    setSelectedQuotes(values.quotes?.map((quote) => quote.quote._id) || []);
  }, [loaded]);

  const formItems = (
    <>
      <Form.Item hidden name="quotes" />
      <Form.Item hidden name="customers" />
      <Form.Item hidden name="professionals" />
      <Form.Item hidden name="prescribers" />

      <Form.Item name="selected_quotes" label={t(`deals.form.quotes`)}>
        <Row>
          <Select
            mode="multiple"
            loading={isFieldsLoading}
            optionFilterProp="label"
            onChange={(sQuotes) => setFormQuotes(sQuotes)}
            options={quotes.map((quote) => {
              return {
                key: quote._id,
                value: quote._id,
                label: quote.number
              };
            })}
            value={selectedQuotes}
          />
        </Row>
      </Form.Item>

      <Form.Item
        name="name"
        rules={[{ required: true }]}
        label={t(`deals.form.name`)}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="priority"
        rules={[{ required: true }]}
        label={t(`deals.form.priority`)}
      >
        <Select loading={isFieldsLoading}>
          {(enums.priorities || []).map((priority) => (
            <Option value={priority} key={priority}>
              {<TagFilled style={{ color: tasksPriorities[priority] }} />}
              &nbsp;
              <Tag color={tasksPriorities[priority]}>
                {t(`deals.tags.${priority}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="status"
        rules={[{ required: true }]}
        label={t(`deals.form.status`)}
      >
        <Select loading={isFieldsLoading}>
          {kanbanCols.map(({ _id, label }) => (
            <Option value={_id} key={_id}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="site"
        rules={[{ required: true }]}
        label={t(`deals.form.site`)}
      >
        <Select loading={isFieldsLoading}>
          {sites.map(({ _id, label }) => (
            <Option value={_id} key={_id}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="select_customers" label={t(`deals.form.customers`)}>
        <Row justify="space-between">
          <Select
            loading={isFieldsLoading}
            showSearch
            optionFilterProp="children"
            mode="multiple"
            style={{ width: '65%' }}
            onChange={(new_selection) => setSelectedCustomers(new_selection)}
            value={selectedCustomers}
          >
            {customers.map(({ _id, first_name, last_name }) => (
              <Option value={_id} key={_id}>
                {`${first_name || ''} ${last_name}`}
              </Option>
            ))}
          </Select>
          <AddCustomerButton refresh={refresh} />
        </Row>
      </Form.Item>

      <Form.Item
        name="select_professionals"
        label={t(`deals.form.professionals`)}
      >
        <Row justify="space-between">
          <Select
            loading={isFieldsLoading}
            showSearch
            optionFilterProp="children"
            mode="multiple"
            style={{ width: '65%' }}
            onChange={(new_selection) =>
              setSelectedProfessionals(new_selection)
            }
            value={selectedProfessionals}
          >
            {professionals.map(({ _id, name }) => (
              <Option value={_id} key={_id}>
                {name}
              </Option>
            ))}
          </Select>
          <AddProfessionnalButton refresh={refresh} />
        </Row>
      </Form.Item>

      <Form.Item name="select_prescribers" label={t(`deals.form.prescribers`)}>
        <Row justify="space-between">
          <Select
            loading={isFieldsLoading}
            showSearch
            optionFilterProp="children"
            mode="multiple"
            style={{ width: '65%' }}
            onChange={(new_selection) => setSelectedPrescribers(new_selection)}
            value={selectedPrescribers}
          >
            {prescribers.map(({ _id, name }) => (
              <Option value={_id} key={_id}>
                {name}
              </Option>
            ))}
          </Select>
          <AddPrescriberButton refresh={refresh} />
        </Row>
      </Form.Item>
      <Form.Item
        name="expected_date"
        label={t(`deals.form.date`)}
        rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="global_information"
        label={t(`deals.form.global_information`)}
      >
        <TextArea rows={8} />
      </Form.Item>
      <Form.Item name={['files']} label={t(`deals.form.global_information`)}>
        <Card>
          {t(`pieces.form.files_title`)}
          {useFilesField({ onFileUploaded, onFileRemoved })}
        </Card>
      </Form.Item>
    </>
  );

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/quotes' });
      setQuotes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProfessionnals = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/profesionals' });
      setProfessionnals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  // function to get precribers
  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });
      setPrescribers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getUsers();
    await getCompanies();
    await getKanbans();
    await getSites();
    await getCustomers();
    await getQuotes();
    await getProfessionnals();
    await getPrescribers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    if (visible)
      (async () => {
        await getSelectOptions();
      })();
  }, [getSelectOptions, visible, forceRefresh]);

  return { formItems, isFieldsLoading };
};

export default useFields;
