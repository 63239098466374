import { useTranslation } from 'react-i18next';

export const useSalesAccountingCodeColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('settings-sales-accounting-codes.form.title'),
      dataIndex: 'title',
      editable: true,
      width: '25%'
    },
    {
      title: t('settings-sales-accounting-codes.form.code'),
      dataIndex: ['code'],
      editable: true,
      width: '10%'
    }
  ];
};
