import React from 'react';
import { Select, Form, Input, Row, Col, Typography, Card } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const WorkSheetFormEnd = () => {
  const { Title } = Typography;
  return (
    <Col span={24} style={{ height: 'fit-content' }}>
      <Card style={{ background: 'rgba(255, 255, 255, 0.9)' }} bordered={false}>
        <div>
          <Title level={4}>Commentaires</Title>
          <div style={{ width: '130px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          <Row>
            <Col style={{ height: 'fit-content' }}>
              <Form.Item name={['commentaries']}>
                <TextArea
                  rows={4}
                  cols={200}
                  placeholder="Ecrire le commentaire..."
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default WorkSheetFormEnd;
