import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { companiesTypes } from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  title,
  type,
  first_name,
  last_name,
  position,
  email,
  phone_number_1,
  phone_number_2,
  my_company
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'contacts.form.gender',
      content: title,
      span: 3
    },
    {
      label: 'contacts.form.type',
      content: type && (
        <Tag color={companiesTypes[type.toUpperCase()]}>
          {t(`companies.tags.${type.toUpperCase()}`)}
        </Tag>
      ),
      span: 3
    },
    {
      label: 'contacts.form.full_name',
      content: `${first_name} ${last_name}`,
      span: 3
    },
    {
      label: 'contacts.form.position',
      content: position,
      span: 3
    },
    {
      label: 'contacts.form.email',
      content: email,
      span: 3
    },
    {
      label: 'contacts.form.phone_number_1',
      content: phone_number_1,
      span: 3
    },
    {
      label: 'contacts.form.phone_number_2',
      content: phone_number_2,
      span: 3
    },
    {
      label: 'contacts.form.company',
      content: my_company && (
        <Link
          to={{
            pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CONTACTS}/show/${my_company._id}`
            // search: `?type=${type}`
          }}
        >
          {my_company.name}
        </Link>
      ),
      span: 3
    }
  ];
};

export default useListContent;
