import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sites, setSites] = useState([]);
  const [orders, setOrders] = useState([]);

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrdersSupplies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/orders-supplies` });
      setOrders(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
      await getOrdersSupplies();
    })();
  }, []);

  return [
    {
      title: t('ordersPurchases.supplier'),
      dataIndex: 'supplier'
    },
    {
      title: t('ordersPurchases.site'),
      dataIndex: 'site',
      render: (site) => site?.label,
      sorter: true
      // filters: sites && sites?.map(c => ({
      //       text: c.label, value: c._id
      //     }))
    },

    {
      title: t('ordersPurchases.customer'),
      dataIndex: 'customer',
      render: (customer) =>
        customer ? `${customer?.first_name || ''} ${customer?.last_name}` : ''
    },
    {
      title: t('ordersPurchases.profesional'),
      dataIndex: 'profesional',
      render: (profesional) => (profesional ? profesional?.name : '')
    },
    {
      title: t('ordersPurchases.product'),
      dataIndex: 'supplies',
      render: (supplies) => supplies.length
    },
    {
      title: t('ordersPurchases.order_date'),
      dataIndex: 'order_date',
      render: (order_date) =>
        order_date ? moment(order_date).format('DD/MM/YYYY') : 'N/R'
    },
    {
      title: t('ordersPurchases.removal_date'),
      dataIndex: 'removal_date',
      render: (removal_date) =>
        removal_date ? moment(removal_date).format('DD/MM/YYYY') : 'N/R'
    }
  ];
};

export default useColumns;
