import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Card, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import StockTagForMatter from '../../Stocks/StockTagForMatter';

const { Title } = Typography;

const Sections = ({ datas }) => {
  const [sections, setSections] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (datas) {
      const sectionsArray = [];
      Object.keys(datas).forEach((key) => {
        if (key.startsWith('section_')) {
          sectionsArray.push(datas[key]);
        }
      });
      setSections(sectionsArray);
    }
  }, [datas]);

  if (sections) {
    return (
      <div>
        {sections.map((section) => {
          return (
            section.description && (
              <Row style={{ padding: '0 24px 24px 24px' }}>
                <Col span={24}>
                  <Card
                    style={{
                      padding: 0,
                      background: 'rgba(255, 255, 255, 0.8)'
                    }}
                    bordered={false}
                  >
                    <div>
                      <Row
                        style={{
                          width: '100%',
                          flexWrap: 'nowrap',
                          overflow: 'auto'
                        }}
                      >
                        <Col span={4}>
                          <Title level={4}>
                            {`${section.project_type} - ${section.description}`}
                          </Title>
                          <div style={{ width: '110px' }}>
                            <hr
                              style={{
                                backgroundColor: 'black',
                                height: '3px',
                                border: 'none',
                                marginBottom: '18px'
                              }}
                            />
                          </div>

                          <Link
                            to={{
                              pathname: `/stock/stocks`
                            }}
                          >
                            <Button type="primary" icon={<EyeOutlined />}>
                              {t('sections.stocks')}
                            </Button>
                          </Link>
                        </Col>
                        <Col
                          span={20}
                          style={{ display: 'flex', overflowX: 'auto' }}
                        >
                          {section.elements.map((mat) => {
                            return (
                              <Col style={{ margin: '16px' }}>
                                <Card
                                  style={{
                                    width: 'fit-content',
                                    height: '100%'
                                  }}
                                  bordered={false}
                                >
                                  <div>
                                    <pre style={{ marginBottom: 0 }}>
                                      <Col>
                                        <Row style={{ marginBottom: '16px' }}>
                                          <Col style={{ marginRight: '16px' }}>
                                            <div
                                              style={{
                                                whiteSpace: 'normal',
                                                fontSize: 16
                                              }}
                                            >
                                              {`${mat.shaping?.color} `}
                                            </div>
                                          </Col>
                                          <Col>
                                            {
                                              <StockTagForMatter
                                                matter={mat}
                                                sizesNeeded={mat.sizes}
                                              />
                                            }
                                          </Col>
                                        </Row>
                                        <Row>
                                          {t('sections.shaping')}
                                          <b>
                                            {` ${mat?.shaping?.completions ||
                                              ''}`}
                                          </b>
                                        </Row>
                                        <Row>
                                          {t(
                                            `sections.thickness-${
                                              mat.is_credence
                                                ? 'credence'
                                                : 'plan'
                                            }`
                                          )}
                                          <b>{` ${mat.thikness} cm`}</b>
                                        </Row>
                                        <Row>
                                          {t('sections.description')}
                                          <b>{`${mat.matter_desc}`}</b>
                                        </Row>

                                        <Row>{t('sections.sizes')}</Row>
                                        <ul>
                                          {mat.sizes.map((size, index) => {
                                            return (
                                              <li>
                                                <b>
                                                  <div>
                                                    {t('sections.piece', {
                                                      index: index + 1,
                                                      height: size.height,
                                                      width: size.width
                                                    })}
                                                  </div>
                                                  {size.shapings &&
                                                    size.shapings.length >
                                                      0 && (
                                                      <div>
                                                        {size.shapings.map(
                                                          (shaping) => {
                                                            return (
                                                              <div>
                                                                {shaping.shaping_name &&
                                                                  shaping
                                                                    .shaping_name
                                                                    .label && (
                                                                    <div
                                                                      style={{
                                                                        whiteSpace:
                                                                          'normal'
                                                                      }}
                                                                    >
                                                                      {`${shaping.shaping_name.label} x${shaping.nb}`}
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </b>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </Col>
                                    </pre>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            )
          );
        })}
      </div>
    );
  }
  return <div> Loading sections ...</div>;
};

Sections.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sections;
