import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListProductions from './ListProductions';
import CreateUpdateProduction from './CreateUpdateProduction';
import ShowProduction from './ShowProduction';

const ProductionRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateProduction purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateProduction purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowProduction} />
      <Route path={path} component={ListProductions} />
    </Switch>
  );
};

export default ProductionRouter;
