import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useStateWithLocalStorage } from '../utils';

export const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [, setRememberMe] = useStateWithLocalStorage('remember_me');
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN',
    permission: {
      grant: [],
      permission_label: ''
    }
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const loginAPI = async (email, password, remember) => {
    const result = await axiosInstance.post('/login', {
      email,
      password
    });
    setUser(result.data.user);
    setRememberMe(remember);
    setSession(result.data.token);
    return result;
  };

  const googleLoginAPI = async (code) => {
    const result = await axiosInstance.post('/auth2/google', { code });
    setUser(result.data.user);
    setRememberMe(true);
    setSession(result.data.token);
    return result;
  };

  const facebookLoginAPI = async (options) => {
    const result = await axiosInstance.post('/auth2/facebook', { options });
    setUser(result.data.user);
    setRememberMe(true);
    setSession(result.data.token);
    return result;
  };

  const twitterLoginAPI = async (options) => {
    const result = await axiosInstance.post('/auth2/twitter', options);
    setUser(result.data.user);
    setRememberMe(true);
    setSession(result.data.token);
    return result;
  };

  const linkedinLoginAPI = async (options) => {
    const result = await axiosInstance.post('/auth2/linkedin', options);
    setUser(result.data.user);
    setRememberMe(true);
    setSession(result.data.token);
    return result;
  };

  const registerAPI = async (values) => {
    return await axiosInstance.post('/register', values);
  };

  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const isTokenValid = () => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        message.warn(t('login.expiredSession'));
        setSession(null);
        return false;
      }
    } catch (e) {
      message.warn(t('login.tokenError'));
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  const verifyGrant = (toTest, values) => {
    let result = false;
    result = toTest.map((el) => values.find((i) => i === el));
    return !result.includes(undefined);
  };

  const removeAfter = (array, values) => {
    const outputArray = [];

    for (let i = 0; i < array.length; i += 1) {
      const currentItem = array[i];
      if (currentItem === '') {
        break;
      }
      if (values.includes(currentItem)) {
        outputArray.push(currentItem);
        break;
      } else {
        outputArray.push(currentItem);
      }
    }
    return outputArray;
  };

  const checkIsGranted = (requestPath) => {
    let isGranted = false;

    if (requestPath !== '') {
      let testRequestPath = requestPath.split('.');

      testRequestPath = removeAfter(testRequestPath, [
        'edit',
        'create',
        'show',
        'accounting-codes'
      ]);

      if (!user.permission || !user.permission.grant) return false;

      const grantedArray = [...user.permission.grant, 'login', ''].map((v) =>
        v.toLowerCase()
      );

      grantedArray.map((el) => {
        const grant = el.split('.');
        if (testRequestPath[0] === '' || grant[0] === '*') isGranted = true;

        if (verifyGrant(testRequestPath, grant)) isGranted = true;
        return grant;
      });
    } else {
      isGranted = true;
    }

    return isGranted;
  };

  const checkShouldDisplayMenuItem = (requestedItem) => {
    if (!user.permission || !user.permission.grant) {
      // handle user without permission field
      return true;
    }
    if (
      [...user.permission.grant, 'login', '', '.', 'home']
        .map((perm) => perm.split('.')[0].toLowerCase())
        .includes(requestedItem)
    ) {
      return true;
    }
    if (
      [...user.permission.grant, 'login', '', '.', 'home']
        .map((perm) => perm.split('.')[1]?.toLowerCase())
        .includes(requestedItem)
    ) {
      return true;
    }
  };

  isTokenValid();

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json',
    cancelToken
  ) => {
    try {
      isTokenValid();
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        cancelToken,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'GOOGLE_LOGIN':
        return googleLoginAPI(options.code);
      case 'FACEBOOK_LOGIN':
        return facebookLoginAPI(options);
      case 'TWITTER_LOGIN':
        return twitterLoginAPI(options);
      case 'LINKEDIN_LOGIN':
        return linkedinLoginAPI(options);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };

  const canEditResource = (path) => {
    let hasEdit = false;
    let filteredGrant = [];

    const grantedArray = [...user?.permission?.grant, 'login', ''].map((v) =>
      v.toLowerCase()
    );

    if (grantedArray[0] === '*') return true;

    const current = path
      .toLowerCase()
      .split('/')
      .filter((str) => str !== '');

    if (current.length > 1) {
      filteredGrant = grantedArray
        .map((el) => el.split('.'))
        .filter((el) => el[0] === current[0] && el[1] === current[1]);
    }

    if (current.length <= 1) {
      filteredGrant = grantedArray
        .map((el) => el.split('.'))
        .filter((el) => el[0] === current[0]);
    }

    hasEdit = filteredGrant.find(
      (arr) => arr[arr.length - 1] === 'edit' || arr[arr.length - 1] === '*'
    );

    if (hasEdit) return true;
    return hasEdit;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        dispatchAPI,
        checkIsGranted,
        checkShouldDisplayMenuItem,
        isLoading,
        canEditResource
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(AuthContext);
