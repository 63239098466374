import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Switch, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../utils/ErrorMessage';
import usePanelContext from './ConfigurationContext';
import ButtonPanel from './ButtonPanel';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  colorPicked,
  setColorPicked,
  isDisplayed,
  setIsDisplayed,
  selectType,
  iconValue,
  setIconValue,
  items,
  resourceName,
  ...restProps
}) => {
  let inputNode;
  const { t } = useTranslation();

  switch (inputType) {
    case 'display':
      inputNode = (
        <Switch
          defaultChecked={record.display || isDisplayed}
          onChange={() => setIsDisplayed(!isDisplayed)}
        />
      );
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true
            },
            {
              validator: (_, value) => {
                const [firstData] = value;

                const existingTitles = items.map((el) => `${el[dataIndex]}`);
                if (existingTitles.includes(firstData)) {
                  return Promise.reject(
                    new Error(t(`${resourceName}.error.${dataIndex}`))
                  );
                }

                return Promise.resolve();
              }
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ConfigurationTable = ({ items, resourceColumns, resourceName }) => {
  const { t } = useTranslation();
  const {
    editItem,
    deleteItem,
    cancel,
    editingKey,
    setEditingKey,
    isEditing
  } = usePanelContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [isDisplayed, setIsDisplayed] = useState(false);

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const setValues = () => {
    form.setFieldsValue({
      display: isDisplayed
    });
  };

  const save = async (record) => {
    setValues(record);
    try {
      const row = await form.validateFields();
      editItem(row, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
  };

  const columns = [
    ...resourceColumns,

    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      align: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        if (record.reserved !== true) {
          return (
            <ButtonPanel
              editingKey={editingKey}
              editable={editable}
              saveItem={() => save(record)}
              edit={() => edit(record)}
              cancel={cancel}
              deleteItem={() => deleteItem(record, resourceName)}
            />
          );
        }
        return false;
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    const createInputType = (dataIndex) => {
      switch (true) {
        case dataIndex[0] === 'icon':
          return 'icon';
        case dataIndex[0] === 'color':
          return 'color';
        case dataIndex[0] === 'display':
          return 'display';
        default:
          return 'text';
      }
    };

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: createInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        isDisplayed,
        setIsDisplayed,
        items,
        resourceName
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={items}
        columns={mergedColumns}
        style={{ overflowX: 'auto', overflowY: 'visible' }}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  items: PropTypes.shape([]).isRequired,
  resourceName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  setColorPicked: PropTypes.string.isRequired,
  colorPicked: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  setIsDisplayed: PropTypes.bool.isRequired,
  iconValue: PropTypes.string.isRequired,
  setIconValue: PropTypes.string.isRequired,
  selectType: PropTypes.string.isRequired
};

ConfigurationTable.propTypes = {
  items: PropTypes.string.isRequired,
  resourceColumns: PropTypes.shape([{}]).isRequired,
  resourceName: PropTypes.string.isRequired
};

export default ConfigurationTable;
