/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons';
import { red } from '@ant-design/colors';
import moment from 'moment';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import NewCompanyDrawer from '../../Commercial/Companies/NewCompanyDrawer';
import NewContactDrawer from '../../Commercial/Contacts/NewContactDrawer';

const { Title } = Typography;
const { TextArea } = Input;

const InvoiceForm = ({
  handleSubmit,
  resource,
  initialValues,
  fields1,
  fields2,
  options,
  contacts,
  articles,
  companies,
  settings,
  enums,
  articlesAvailable,
  addArticle,
  deleteArticle,
  orders,
  isLoading,
  getCompanies,
  getContacts
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [displayDiscount, setDiscountDisplay] = useState(
    initialValues.global_discount_value
      ? initialValues.global_discount_value > 0
      : false
  );
  const [displayOptions, setOptionsDisplay] = useState(false);
  const [displayLines, setLinesDisplay] = useState(true);
  const [articleSearch, setArticleSearch] = useState('');
  const [filteredContacts, setContacts] = useState([]);
  const [customer, setCustomer] = useState();
  const [displayedDiscounts, setDiscountsDisplay] = useState([]);
  const [lineSubtotals, setSubtotals] = useState([]);
  const [totalMode, setMode] = useState('HT');
  const [total, setTotal] = useState(0);
  const [visibilityCompanyDrawer, setVisibilityCompanyDrawer] = useState(false);
  const [visibilityContactDrawer, setVisibilityContactDrawer] = useState(false);

  const onInputChange = () => {
    const tmpArticles = Object.values(form.getFieldValue('articles'));
    const globalDiscount = form.getFieldValue('global_discount_value');
    const discountType = form.getFieldValue('global_discount_type');
    const tmpSubtotals = [...lineSubtotals];
    let calculatedGlobalDiscount = 0;
    let subTotal = 0;
    let total_VAT = 0;
    let total_discount = 0;

    tmpArticles.forEach(
      (
        { quantity, unit_price, VAT, discount, discount_type, subtotal },
        index
      ) => {
        let tmpDiscount = 0;

        if (!tmpSubtotals[index]) {
          tmpSubtotals.push(subtotal);
        }

        if (discount) {
          tmpDiscount =
            discount_type === 'CURRENCY'
              ? discount
              : (quantity * unit_price * discount) / 100;
          total_discount += tmpDiscount;
        }
        subTotal += quantity * unit_price - tmpDiscount;
        total_VAT += (quantity * unit_price - tmpDiscount) * (VAT / 100);
      }
    );

    if (globalDiscount && discountType) {
      calculatedGlobalDiscount =
        discountType === 'CURRENCY'
          ? globalDiscount
          : (subTotal * globalDiscount) / 100;
      total_discount += calculatedGlobalDiscount;
    }
    const calculatedTotal = subTotal - calculatedGlobalDiscount + total_VAT;

    form.setFieldsValue({
      subTotal: parseFloat(subTotal).toFixed(2),
      total_discount: parseFloat(total_discount).toFixed(2),
      total_VAT: parseFloat(total_VAT).toFixed(2),
      total: parseFloat(calculatedTotal).toFixed(2)
    });

    setSubtotals(tmpSubtotals);
    setTotal(
      totalMode === 'HT'
        ? subTotal - calculatedGlobalDiscount
        : subTotal - calculatedGlobalDiscount + total_VAT
    );
  };

  const onShowDiscount = (articleId, index) => {
    const tempDiscounts = [...displayedDiscounts];

    if (tempDiscounts.includes(articleId)) {
      const articleIndex = tempDiscounts.indexOf(articleId);
      tempDiscounts.splice(articleIndex, 1);
      form.setFieldsValue({
        articles: {
          [articleId]: {
            discount: 0
          }
        }
      });
    } else {
      tempDiscounts.push(articleId);
    }

    onInputChange();
    updateArticleSubtotal(articleId, index);
    setDiscountsDisplay(tempDiscounts);
  };

  const onValuesChange = (values) => {
    if (values.order) {
      orders.forEach((order) => {
        if (order._id === values.order) {
          form.setFieldsValue({
            order_number: order.number,
            supplier: order.supplier,
            customer: order.customer,
            contact: order.contact,
            contract: order.contract
          });

          order.quotations.articles.forEach((article) => {
            addArticle(article.article, article.quantity);
          });
        }
      });
    }
    if (values.customer) {
      const newCustomer = companies.find(
        (element) => element._id === values.customer
      );
      setContacts(contacts.filter((c) => c.my_company === values.customer));
      setCustomer(newCustomer);
      form.setFieldsValue({
        channel_number: newCustomer.channel_number,
        address_details: newCustomer.address_details,
        postal_code: newCustomer.postal_code,
        city: newCustomer.city
      });
    }
    if (values.emission_date) {
      const settingLabel =
        resource === 'quotations'
          ? 'default_quotation_deadline'
          : 'default_invoice_deadline';
      form.setFieldsValue({
        deadline_date: moment(values.emission_date).add(
          parseInt(
            settings.find((setting) => setting.label === settingLabel).value,
            10
          ),
          'days'
        )
      });
    }
  };

  const handleDeleteArticle = (key) => {
    deleteArticle(key);
    form.setFieldsValue({
      articles: { [key]: { name: '', quantity: 0, unit_price: 0 } }
    });
  };

  const updateArticleSubtotal = (articleId, index) => {
    const tmpSubtotal =
      form.getFieldValue(['articles', articleId, 'unit_price']) *
      form.getFieldValue(['articles', articleId, 'quantity']);
    let newDiscount = form.getFieldValue(['articles', articleId, 'discount']);
    const discountType = form.getFieldValue([
      'articles',
      articleId,
      'discount_type'
    ]);

    if (!newDiscount) {
      newDiscount = 0;
    }

    const newSubtotal =
      tmpSubtotal -
      (discountType === 'CURRENCY'
        ? newDiscount
        : (newDiscount *
            form.getFieldValue(['articles', articleId, 'subtotal'])) /
          100);

    form.setFieldsValue({
      articles: { [articleId]: { subtotal: newSubtotal.toFixed(2) } }
    });

    const tmpSubtotals = [...lineSubtotals];
    tmpSubtotals[index] = newSubtotal;
    setSubtotals(tmpSubtotals);
  };

  useEffect(() => {
    if (articles.length > 0) {
      articles.forEach(({ articleId, discount }) => {
        if (discount > 0) {
          setDiscountsDisplay([...displayedDiscounts, articleId]);
          // updateArticleSubtotal(articleId);
        }
      });
      onInputChange();
    }
  }, [articles]);

  useEffect(() => {
    if (displayedDiscounts.length > 0) {
      onInputChange();
    }
  }, [displayedDiscounts]);

  useEffect(() => {
    setContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    if (initialValues.customer) {
      setContacts(
        contacts.filter((c) => c.my_company === initialValues.customer)
      );
    }
  }, [initialValues]);

  const setNewCompanyDrawerVisibility = () => {
    setVisibilityCompanyDrawer(!visibilityCompanyDrawer);
  };

  const setNewContactDrawerVisibility = () => {
    setVisibilityContactDrawer(!visibilityContactDrawer);
  };

  return (
    <Spin spinning={isLoading}>
      <NewCompanyDrawer
        isVisible={visibilityCompanyDrawer}
        setVisibilityCompanyDrawer={setNewCompanyDrawerVisibility}
        getCompanies={getCompanies}
      />
      <NewContactDrawer
        isVisible={visibilityContactDrawer}
        setVisibilityContactDrawer={setNewContactDrawerVisibility}
        getContact={getContacts}
      />
      <Form
        {...formItemLayout}
        name="invoicesForm"
        onFinish={(values) =>
          handleSubmit({ ...values, order: initialValues.order })
        }
        initialValues={initialValues}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.heading`)}</Title>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div style={{ marginTop: 4 }}>
                  <span style={{ color: 'red' }}>*</span>&nbsp;
                  {t(`${resource}.form.customer`)}&nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item name={['customer']} required>
                  <Select
                    options={companies
                      .filter(
                        (c) =>
                          c.type.toLowerCase() === 'customers' || 'CUSTOMERS'
                      )
                      .map(({ name, _id }) => ({ label: name, value: _id }))}
                    loading={isLoading}
                    style={{ width: '300%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button
                  type="add"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setVisibilityCompanyDrawer(!visibilityCompanyDrawer);
                  }}
                >
                  {`${t('buttons.create')} `}
                  <PlusOutlined />
                </Button>
              </Col>
              {(customer || initialValues.customer) && (
                <>
                  <Col span={24}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      label={t(`${resource}.form.address`)}
                    >
                      <Input.Group compact>
                        <Form.Item name={['channel_number']} required>
                          <InputNumber />
                        </Form.Item>
                        <Form.Item name={['address_details']} required>
                          <Input style={{ width: '252.33%' }} />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24} offset={8} style={{ marginBottom: '20px' }}>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Input.Group compact>
                        <Form.Item name={['postal_code']} required>
                          <InputNumber />
                        </Form.Item>
                        <Form.Item name={['city']} required>
                          <Input style={{ width: '252.33%' }} />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={8} style={{ textAlign: 'right' }}>
                <div style={{ marginTop: 4 }}>
                  <span style={{ color: 'red' }}>*</span>&nbsp;
                  {t(`${resource}.form.contact`)}&nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item name={['contact']} required>
                  <Select
                    options={filteredContacts.map(
                      ({ first_name, last_name, _id }) => ({
                        label: `${first_name} ${last_name}`,
                        value: _id
                      })
                    )}
                    loading={isLoading}
                    style={{ width: '300%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button
                  type="add"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setVisibilityContactDrawer(!visibilityContactDrawer);
                  }}
                >
                  {`${t('buttons.create')} `}
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.body`)}</Title>
          </Col>
          <Col span={24}>
            {fields1.map((field) => generateFields(resource, field))}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.lines`)}</Title>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button type="link" onClick={() => setLinesDisplay(!displayLines)}>
              {displayLines
                ? t(`${resource}.form.title.hide`)
                : t(`${resource}.form.title.display`)}
              {displayLines ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
          <Col span={24}>
            {displayLines &&
              articles.map(
                (
                  {
                    name,
                    quantity,
                    unit_price,
                    subtotal,
                    VAT,
                    discount,
                    discount_type,
                    articleId,
                    comment
                  },
                  index
                ) => (
                  <Row
                    key={articleId}
                    style={{
                      border: '1px solid var(--borderColor)',
                      padding: '10px',
                      marginTop: 8
                    }}
                  >
                    <Form.Item
                      name={['articles', articleId, 'article']}
                      initialValue={articleId}
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>

                    <Col span={24} style={{ marginTop: '16px' }}>
                      <Button
                        style={{
                          position: 'absolute',
                          top: 6,
                          right: 14,
                          zIndex: 10
                        }}
                        type="link"
                        size="small"
                        icon={
                          <DeleteOutlined
                            style={{ color: red.primary, fontSize: 16 }}
                          />
                        }
                        onClick={() => handleDeleteArticle(articleId)}
                      />
                    </Col>

                    <Col span={8} offset={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.articles.name')}
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.articles.quantity')}
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.articles.unit_price')}
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.articles.VAT')}
                    </Col>
                    <Col span={3} style={{ paddingRight: '10px' }}>
                      <Button
                        type="link"
                        onClick={() => onShowDiscount(articleId, index)}
                      >
                        {t('invoices.form.articles.discount')}
                      </Button>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <strong>{t('invoices.form.articles.subtotal')}</strong>
                    </Col>
                    <Col span={8} offset={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['articles', articleId, 'name']}
                        initialValue={name}
                      >
                        <Input disabled style={{ width: '300%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['articles', articleId, 'quantity']}
                        initialValue={quantity || 1}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          onChange={() => {
                            onInputChange();
                            updateArticleSubtotal(articleId, index);
                          }}
                          style={{ width: '300%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['articles', articleId, 'unit_price']}
                        initialValue={unit_price || 0}
                      >
                        <InputNumber
                          formatter={(value) => value && `${value}€`}
                          parser={(value) => value.replace('€', '')}
                          onChange={() => {
                            onInputChange();
                            updateArticleSubtotal(articleId, index);
                          }}
                          style={{ width: '300%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['articles', articleId, 'VAT']}
                        initialValue={VAT || 20}
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={(enums.tva || []).map((indice) => ({
                            label: `${indice}%`,
                            value: indice
                          }))}
                          loading={isLoading}
                          onChange={onInputChange}
                          style={{ width: '300%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ paddingRight: '10px' }}>
                      {displayedDiscounts.includes(articleId) && (
                        <Input.Group compact>
                          <Form.Item
                            name={['articles', articleId, 'discount']}
                            initialValue={discount || 0}
                          >
                            <InputNumber
                              onChange={() => {
                                onInputChange();
                                updateArticleSubtotal(articleId, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={['articles', articleId, 'discount_type']}
                            initialValue={discount_type || 'CURRENCY'}
                          >
                            <Select
                              options={[
                                {
                                  label: '€ HT',
                                  value: 'CURRENCY'
                                },
                                {
                                  label: '%',
                                  value: 'PERCENT'
                                }
                              ]}
                              onChange={onInputChange}
                              style={{ width: '80px' }}
                            />
                          </Form.Item>
                        </Input.Group>
                      )}
                    </Col>
                    <Col span={5} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['articles', articleId, 'subtotal']}
                        initialValue={subtotal || 0}
                      >
                        <Title level={3}>
                          {`${
                            lineSubtotals[index]
                              ? lineSubtotals[index].toFixed(2)
                              : 0.0
                          }€`}
                        </Title>
                        <InputNumber
                          formatter={(value) => value && `${value}€`}
                          disabled
                          style={{ visibility: 'hidden' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={['articles', articleId, 'comment']}
                        initialValue={comment || ''}
                        label={t(`${resource}.form.articles.comment`)}
                      >
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              )}

            {displayLines && (
              <Row
                gutter={[0, 16]}
                style={{
                  background: 'var(--bodyBackground)',
                  border: '1px solid var(--borderColor)',
                  marginTop: 8
                }}
              >
                <Col span={24}>
                  <Form.Item
                    label={t(`${resource}.form.articles.select`)}
                    rules={[{ required: true }]}
                    style={{ marginBottom: '0' }}
                  >
                    <Select
                      showSearch
                      options={articlesAvailable.map(({ label, _id }) => ({
                        label,
                        value: _id
                      }))}
                      value={articleSearch}
                      loading={isLoading}
                      onSelect={(id) => {
                        addArticle(id);
                        setArticleSearch('');
                      }}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={setArticleSearch}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.discount`)}</Title>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              onClick={() => setDiscountDisplay(!displayDiscount)}
            >
              {displayDiscount
                ? t('invoices.form.title.hide')
                : t('invoices.form.title.display')}
              {displayDiscount ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
          <Col span={24}>
            <Row style={{ marginBottom: '16px' }}>
              {displayDiscount && (
                <Col span={24}>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.value`)}
                  >
                    <Input.Group compact>
                      <Form.Item
                        name={['global_discount_value']}
                        initialValue={0}
                      >
                        <InputNumber
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={['global_discount_type']}
                        initialValue="CURRENCY"
                      >
                        <Select
                          options={[
                            {
                              label: '€ HT',
                              value: 'CURRENCY'
                            },
                            {
                              label: '%',
                              value: 'PERCENT'
                            }
                          ]}
                          style={{ width: '80px' }}
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.comment`)}
                    name={['global_discount_comment']}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {resource === 'invoices' && (
          <Row style={{ marginTop: '20px' }}>
            <Col span={6} offset={8}>
              <Title level={3}>{t(`${resource}.form.title.options`)}</Title>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Button
                type="link"
                onClick={() => setOptionsDisplay(!displayOptions)}
              >
                {displayOptions
                  ? t('invoices.form.title.hide')
                  : t('invoices.form.title.display')}
                {displayOptions ? (
                  <UpOutlined style={{ marginLeft: 10 }} />
                ) : (
                  <DownOutlined style={{ marginLeft: 10 }} />
                )}
              </Button>
            </Col>
            <Col span={24}>
              {displayOptions &&
                options.map((field) => generateFields(resource, field))}
            </Col>
          </Row>
        )}

        <Row
          style={{
            background: 'var(--bodyBackground)',
            border: '1px solid var(--borderColor)',
            marginTop: '20px',
            marginBottom: '35px',
            padding: '10px'
          }}
        >
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.recap`)}</Title>
          </Col>
          <Col span={24}>
            {fields2.map((field) => generateFields(resource, field))}
          </Col>
        </Row>

        <Row>
          <Col
            span={2}
            offset={8}
            onClick={() => {
              setTotal(
                totalMode === 'HT'
                  ? form.getFieldValue('total')
                  : form.getFieldValue('subTotal')
              );
              setMode(totalMode === 'HT' ? 'TTC' : 'HT');
            }}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <strong>{t(`${resource}.form.total`)}</strong>
            <Title level={3} style={{ margin: 0 }}>
              {`${parseFloat(total).toFixed(2)}€`}
            </Title>
            <Button type="link">{totalMode}</Button>
          </Col>
          <Col span={4} offset={2}>
            <Button
              style={{ float: 'right' }}
              type="add"
              htmlType="submit"
              disabled={!(articles.length > 0)}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ float: 'right' }}
              type="link"
              danger
              onClick={() => history.goBack()}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

InvoiceForm.propTypes = {
  addArticle: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  articlesAvailable: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteArticle: PropTypes.func.isRequired,
  enums: PropTypes.shape({
    tva: PropTypes.array
  }).isRequired,
  fields1: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields2: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCompanies: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    customer: PropTypes.any,
    global_discount_value: PropTypes.number,
    order: PropTypes.any
  }),
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resource: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

InvoiceForm.defaultProps = {
  initialValues: null
};

export default InvoiceForm;
