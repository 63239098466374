import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import useFields from './fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import useDealContext from './DealsContext';

const EditDrawer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const {
    visible,
    setVisible,
    deal,
    setDeal,
    dataRefresh,
    setDataRefresh
  } = useDealContext();
  const { fields, isFieldsLoading } = useFields(visible);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setDeal(null);
    setDataRefresh(!dataRefresh);
  };

  const onFinishForm = async (body) => {
    try {
      await dispatchAPI(deal ? 'PATCH' : 'POST', {
        url: `/trellos-cards${deal ? `/${deal}` : ''}`,
        body
      });
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchDeal = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trellos-cards/${deal}`
      });
      form.setFieldsValue(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [deal, isFieldsLoading]);

  useEffect(() => {
    if (deal && !isFieldsLoading)
      (async () => {
        await fetchDeal();
      })();
  }, [fetchDeal]);

  return (
    <Drawer
      title={t(`deals.show.${deal ? 'edit' : 'add'}`)}
      width={320}
      onClose={onClose}
      visible={visible}
    >
      <Spin spinning={isLoading}>
        <Form
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={onFinishForm}
        >
          {fields.map((field) => generateFormItem('trellos-cards', field))}
          <Form.Item style={{ marginTop: 16 }}>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onClose}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditDrawer;
