import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import extraQuotationRouter from '../ExtraQuotations/QuotationsRouter';
import QuotesRouter from '../Quotes/router';
import WorkSheetRouter from '../WorkSheets/router';
import LaySheetRouter from '../LaySheets/router';
import OrdersRouter from '../Orders/router';

const CustomerAccountRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.CUSTOMERS_ACCOUNTING.QUOTES}`}
        component={QuotesRouter}
      />
      <Route
        path={`${path}${subRoutes.CUSTOMERS_ACCOUNTING.EXTRAQUOTATIONS}`}
        component={extraQuotationRouter}
      />
      <Route
        path={`${path}${subRoutes.CUSTOMERS_ACCOUNTING.ORDERS_SALES}`}
        component={OrdersRouter}
      />
      <Route
        path={`${path}${subRoutes.CUSTOMERS_ACCOUNTING.INVOICES}`}
        component={OrdersRouter}
      />
      <Route path={`${path}/workSheets`} component={WorkSheetRouter} />
      <Route path={`${path}/laySheets`} component={LaySheetRouter} />
    </Switch>
  );
};

export default CustomerAccountRouter;
