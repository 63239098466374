import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Select, Tag, Radio } from 'antd';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { profesionalsTypes } from '../../../utils/constants/tagColors';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const PieceEntryModal = ({
  forceRefresh,
  setForceRefresh,
  usedNumbers,
  setPieces,
  pieces
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRebut, setIsRebut] = useState(false);
  const [enums, setEnums] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [select, setSelect] = useState();
  const [selectedType, setSelectedType] = useState();
  const { t } = useTranslation();
  const [sites, setSites] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState();
  const [orders, setOrders] = useState([]);

  const { Option } = Select;

  const margin = { margin: '16px' };

  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/ordersnew'
      });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/pieces/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInitialPiece = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/pieces/${id}` });
      setPieces(data.sizes);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getSites();
      await getInitialPiece();
      await getOrders();
    })();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (sizes) => {
    const body = {
      new_size: sizes
    };
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/pieces/${id}`,
        body
      });

      setPieces(data.sizes);
    } catch (e) {
      if (e.response) message(e.response.status);
    }

    setForceRefresh(!forceRefresh);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const canValidate = () => {
    return !(
      selectedType !== 'Chute' &&
      usedNumbers &&
      usedNumbers.includes(parseInt(selectedNumber, 10))
    );
  };

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  };

  const onFinish = async (sizes) => {
    await handleOk(sizes);
  };

  const onFinishFailed = () => {};

  return (
    <>
      <Button type="primary" onClick={showModal} style={margin}>
        {t('stocks.enter')}
      </Button>

      <Modal
        title={t('stocks.enterTitle')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="person_in_charge"
            hidden
            initialValue={`${user.first_name} ${user.last_name}`}
          />
          <Form.Item
            label={t('stocks.type')}
            name="type"
            rules={[{ required: true }]}
          >
            <Select
              value={selectedType}
              onSelect={(newValue) => setSelectedType(newValue)}
            >
              {(enums.type || []).map((item) => (
                <Option key={item} value={item}>
                  <Tag color={profesionalsTypes[item]}>{item}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedType === 'Chute' &&
            pieces.filter((size) => size.exit_date).length === 0 && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {t('stocks.no_chute_possible')}
              </div>
            )}
          <Form.Item
            label={t('stocks.number')}
            name="number"
            rules={[{ required: true }]}
          >
            {selectedType === 'Chute' ? (
              <Select
                value={selectedNumber}
                onSelect={(newValue) => setSelectedNumber(newValue)}
              >
                {pieces
                  .filter((size) => size.exit_date)
                  .map((item) => (
                    <Option key={item.number} value={item.number}>
                      {item.number}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input
                type={Number}
                onChange={(value) => {
                  setSelectedNumber(value.target.value);
                }}
              />
            )}
          </Form.Item>
          {selectedType !== 'Chute' &&
            (usedNumbers || []).includes(parseInt(selectedNumber, 10)) && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {t('stocks.number_taken')}
              </div>
            )}

          <Form.Item
            label={t('stocks.shape')}
            name="shape"
            rules={[{ required: true }]}
          >
            <Select value={select} onSelect={(newValue) => setSelect(newValue)}>
              {(enums.shape || []).map((item) => (
                <Option key={item} value={item}>
                  <Tag color={profesionalsTypes[item]}>{item}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('stocks.length')}
            name="length"
            rules={[{ required: true }]}
          >
            <Input type={Number} />
          </Form.Item>
          <Form.Item
            label={t('stocks.width')}
            name="width"
            rules={[{ required: true }]}
          >
            <Input type={Number} />
          </Form.Item>
          {select === 'L' && (
            <>
              <Form.Item
                label={t('stocks.length_L_shape')}
                name="length_L_shape"
              >
                <Input type={Number} />
              </Form.Item>
              <Form.Item label={t('stocks.width_L_shape')} name="width_L_shape">
                <Input type={Number} />
              </Form.Item>
            </>
          )}
          <Form.Item label={t('stocks.is_rebut')} name="is_rebut">
            <Radio.Group
              value={isRebut}
              onChange={(newValue) => {
                setIsRebut(newValue.target.value);
              }}
            >
              <Radio value>{t('stocks.yes')}</Radio>
              <Radio value={false}>{t('stocks.no')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={t('stocks.order')}
            name="order"
            rules={[{ required: isRebut }]}
            hidden={!isRebut}
          >
            <Select showSearch optionFilterProp="children">
              {(orders || []).map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.number}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={t('stocks.site')} name="site">
            <Select>
              {(sites || []).map((item) => (
                <Option key={item._id} value={item._id}>
                  <Tag color={profesionalsTypes[item.label]}>{item.label}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" disabled={!canValidate()}>
              {t('buttons.validate')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

PieceEntryModal.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  usedNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPieces: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({}))
};

PieceEntryModal.defaultProps = {
  pieces: []
};

export default PieceEntryModal;
