import numeral from 'numeral';

const useListContent = ({
  label,
  brand,
  ref_internal,
  ref_supplier,
  category,
  quantity_min,
  quantity,
  buying_price,
  selling_price,
  description,
  catalogue,
  supplier
}) => {
  return [
    {
      label: 'suppliers.show.label',
      content: label,
      span: 1
    },
    {
      label: 'suppliers.show.brand',
      content: brand,
      span: 2
    },
    {
      label: 'suppliers.show.supplier',
      content: supplier,
      span: 1
    },
    {
      label: 'suppliers.show.ref_internal',
      content: ref_internal,
      span: 2
    },
    {
      label: 'suppliers.show.ref_supplier',
      content: ref_supplier,
      span: 1
    },
    {
      label: 'suppliers.show.catalogue',
      content: catalogue && catalogue?.label,
      span: 2
    },
    {
      label: 'suppliers.show.quantity_min',
      content: quantity_min,
      span: 1
    },
    {
      label: 'suppliers.show.quantity',
      content: quantity,
      span: 2
    },
    {
      label: 'suppliers.show.buying_price',
      content: `${numeral(buying_price).format('0,0.00')} €`,
      span: 1
    },
    {
      label: 'suppliers.show.selling_price',
      content: `${numeral(selling_price).format('0,0.00')} €`,
      span: 2
    },
    {
      label: 'suppliers.show.description',
      content: description,
      span: 1
    }
  ];
};

export default useListContent;
