import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Divider, Space, Tag, Popconfirm } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const LaySheet = ({ order }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState({});
  const [refresh, setrefresh] = useState(false);
  const { t } = useTranslation();

  const getDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/laysheets?order=${order._id}&populate=customer`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getDatas();
  }, [order, refresh]);

  const onDelete = async (key) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/laysheets/${key}`
      });
      setrefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: 'Addresse chantier',
      dataIndex: '',
      render: (el) => (
        <div>
          {el.customer &&
            `${el.customer.address?.number || ''} ${el.customer.address
              ?.street || ''} ${el.customer.address?.postal_code} ${
              el.customer.address?.city
            }  `}
          {el.isDefinitively && <Tag color="green">VALIDÉE</Tag>}
        </div>
      )
    },
    {
      title: 'FT associé',
      dataIndex: 'numberWorkSheet'
    },
    {
      title: 'Date de création',
      dataIndex: 'created_at',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: 'Date de pose',
      dataIndex: 'delivery_date',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: 'Action',
      render: (laysheet) => (
        <Space>
          <Link
            to={{
              pathname: `/customers_accounting/laySheets`,
              state: { laysheet, editing: true }
            }}
          >
            <EditOutlined style={{ color: 'black', fontSize: 18 }} />
          </Link>
          <Divider />

          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => onDelete(laysheet._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </Space>
      )
    }
  ];
  if (Object.keys(datas).length) {
    return <Table dataSource={datas} columns={columns} pagination={false} />;
  }
  return null;
};

LaySheet.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.any
  }).isRequired
};

export default LaySheet;
