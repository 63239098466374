import { Form, Col, Select, Tag, Input, InputNumber, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useMatterFields = ({ purpose, form }) => {
  const { Option } = Select;
  const [matters, setMatters] = useState([]);
  const [thickness, setThickness] = useState([]);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getMatters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/matters' });
      setMatters(data);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getMatters();
  }, []);

  const updateValue = () => {
    const {
      buying_price,
      devaluation,
      length,
      width,
      pieces_count
    } = form.getFieldsValue();
    let result = null;
    let newDevaluation = 1;

    if (devaluation) newDevaluation = 1 - devaluation / 100;
    if (buying_price) result = buying_price * newDevaluation;
    if (length) result *= length / 1000;
    if (width) result *= width / 1000;
    if (pieces_count) result *= pieces_count;

    if (result)
      form.setFieldsValue({
        value: Number(result.toFixed(2))
      });
  };

  return (
    <>
      <Col>
        <Form.Item
          name={['matter']}
          label={t('pieces.form.matter')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            loading={isFieldsLoading}
            optionFilterProp="children"
            onSelect={(el) => {
              const matter = matters.filter((mat) => mat._id === el)[0];
              const pricings = matter.pricing;
              setThickness(pricings.map((value) => value.size));
              form.setFieldsValue({ ref_internal: matter.ref });
            }}
            disabled={purpose === 'edit'}
          >
            {matters.map((el) => (
              <Option key={el._id} value={el._id}>
                {`${el.label} - ${el.color} - ${el.completions}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['ref_internal']}
          label={t('pieces.form.ref_internal')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={['thickness']}
          label={t('pieces.form.thickness')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Select disabled={purpose === 'edit'}>
            {(thickness || []).map((item) => (
              <Option key={item} value={item}>
                <Tag>{item}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['length']}
          label={t('pieces.form.length')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <InputNumber onChange={() => updateValue()} />
        </Form.Item>
        <Form.Item
          name={['width']}
          label={t('pieces.form.width')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <InputNumber onChange={() => updateValue()} />
        </Form.Item>
        <Form.Item
          name="is_bookmatch"
          label={t('stocks.is_bookmatch')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value>{t('stocks.yes')}</Radio>
            <Radio value={false}>{t('stocks.no')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </>
  );
};

useMatterFields.propTypes = {
  purpose: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired
};

export default useMatterFields;
