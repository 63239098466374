import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const DownloadLaysheet = ({ element, template, downloadFile }) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      {template && (
        <Button
          type="primary"
          icon={isDownloading ? <LoadingOutlined /> : <FilePdfOutlined />}
          onClick={async () => {
            if (!isDownloading) {
              setIsDownloading(true);
              await downloadFile(element._id, `${element.number}.pdf`, () =>
                setIsDownloading(false)
              );
            }
          }}
        >
          {t('buttons.generate')}
        </Button>
      )}
    </>
  );
};

DownloadLaysheet.propTypes = {
  element: PropTypes.shape({ _id: PropTypes.string, number: PropTypes.string })
    .isRequired,
  template: PropTypes.shape({}).isRequired,
  downloadFile: PropTypes.func.isRequired
};
