import React, { useEffect, useState } from 'react';
import { Popconfirm, Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  DeleteOutlined,
  CopyOutlined,
  WarningOutlined,
  EditOutlined,
  ExportOutlined,
  CloseOutlined,
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useContext from '../context';
import DownloadGeneratedFile from '../../Accounting/Invoices/DownloadGeneratedFile';
import useErrorMessage from '../../../utils/ErrorMessage';

const useButtons = (quote, showValidationForm, changeQuoteStatus) => {
  const { message } = useErrorMessage();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const {
    deleteResource,
    duplicateResource,
    template,
    downloadFile
  } = useContext();
  const [order, setOrder] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [signedQuote, setSignedQuote] = useState();

  const downloadSignedQuote = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading(false);
  };

  const fetchOrderForQuote = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew?quote=${quote._id}`
      });
      setOrder(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchSignedQuote = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/signed-quote?quote=${quote._id}&populate=file`
      });

      setSignedQuote(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (quote && quote.status === 'VALIDATE') {
      fetchOrderForQuote();
      fetchSignedQuote();
    }
    if (quote?.order && quote.status === 'DELETED') {
      setOrder({ _id: quote.order._id });
    }
  }, [quote]);

  return (
    <Space>
      {(quote?.status === 'VALIDATE' || quote?.status === 'DELETED') && order && (
        <Link
          to={{
            pathname: `/customers_accounting/orders_sales/show/${order?._id}`
          }}
          style={{ float: 'right' }}
        >
          <Button type="primary" icon={<ExportOutlined />}>
            {t(`quotations.action.order`)}
          </Button>
        </Link>
      )}
      {quote?.status === 'PENDING' && quote?.order && (
        <Link
          to={{
            pathname: `/customers_accounting/orders_sales/show/${quote?.order?._id}`
          }}
          style={{ float: 'right' }}
        >
          <Button type="primary" icon={<ExportOutlined />}>
            {t(`quotations.action.order`)}
          </Button>
        </Link>
      )}
      {quote?.status !== 'DRAFT' && (
        <>
          <DownloadGeneratedFile
            element={quote}
            template={template}
            downloadFile={downloadFile}
            details
            type="quote"
          />
          <DownloadGeneratedFile
            element={quote}
            template={template}
            downloadFile={downloadFile}
            details={false}
            type="quote"
          />
        </>
      )}
      {quote?.status === 'VALIDATE' && signedQuote && (
        <Button
          type="primary"
          onClick={() => {
            setIsDownloading(true);
            downloadSignedQuote(
              signedQuote?.file?._id,
              signedQuote?.file?.metadata?.originalName
            );
          }}
          icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
        >
          Télécharger le devis signé
        </Button>
      )}
      {(quote?.status === 'PENDING' || quote?.status === 'DRAFT') && (
        <Link
          to={{
            pathname: `/customers_accounting/quotes/edit/${quote?._id}`
          }}
          style={{ float: 'right' }}
        >
          <Button type="primary" icon={<EditOutlined />}>
            {t('buttons.edit')}
          </Button>
        </Link>
      )}
      {quote?.status !== 'DRAFT' && (
        <Popconfirm
          title={t(`quotations.action.duplicate.warning`)}
          okText={t(`quotations.action.duplicate.ok`)}
          okButtonProps={{ type: 'add' }}
          cancelText={t(`quotations.action.delete.cancel`)}
          onConfirm={() => duplicateResource(quote._id)}
          icon={<WarningOutlined />}
        >
          <Button type="primary" icon={<CopyOutlined />}>
            {t(`quotations.action.duplicate.title`)}
          </Button>
        </Popconfirm>
      )}
      {quote?.status === 'PENDING' && (
        <Button
          type="primary"
          onClick={() => changeQuoteStatus('WAITING_FOR_SIGNATURE')}
        >
          Passer en attente de signature
        </Button>
      )}
      {quote?.status === 'WAITING_FOR_SIGNATURE' && (
        <>
          <Button
            type="primary"
            onClick={() => showValidationForm(true)}
            icon={<CheckOutlined />}
          >
            Valider
          </Button>
          <Button
            type="primary"
            onClick={() => changeQuoteStatus('REJECTED')}
            icon={<CloseOutlined />}
          >
            Rejeter
          </Button>
          <Button
            type="primary"
            onClick={() => changeQuoteStatus('DROPPED')}
            icon={<CloseOutlined />}
          >
            Abandonner
          </Button>
        </>
      )}
      {!['VALIDATE', 'REJECTED'].includes(quote?.status) && (
        <Popconfirm
          title={t(`quotations.action.delete.title`)}
          okText={t(`quotations.action.delete.ok`)}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(`quotations.action.delete.cancel`)}
          onConfirm={() =>
            deleteResource(
              quote._id,
              history.push('/customers_accounting/quotes')
            )
          }
          icon={<WarningOutlined />}
        >
          <Button danger icon={<DeleteOutlined />}>
            {t('buttons.delete')}
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
};

export default useButtons;
