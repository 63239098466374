import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Form,
  Input,
  Radio,
  Modal,
  Select,
  message,
  Space
} from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import { PaymentColumns } from './Columns/PaymentColumns';

const Payments = ({ datas, forceRefresh }) => {
  const { t } = useTranslation();
  const columns = PaymentColumns(datas);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { dispatchAPI, user } = useAuthContext();
  const [toPayed, setToPayed] = useState(0);
  const [payed, setPayed] = useState(0);
  const [form] = Form.useForm();
  const [invoiceRadio, setInvoiceRadio] = useState(false);

  const getInvoice = async (order, value, payedValue) => {
    const { data } = await dispatchAPI('GET', {
      url: `/invoices?number=${order.invoice_number}`
    });
    if (data[0].status === 'PAYED') {
      setPayed(value);
      setToPayed(0);
    } else {
      setPayed(payedValue);
      setToPayed(value - payedValue);
    }
  };

  const checkInvoiceStatus = (order, value, payedValue) => {
    if (order?.invoice_number) {
      getInvoice(order, value, payedValue);
    } else {
      setPayed(payedValue);
      setToPayed(value - payedValue);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    forceRefresh();
    form.resetFields();
  };

  useEffect(() => {
    if (datas) {
      const total = Number(datas.totals.totalsTTC.toFixed(2));
      let payedCalc = 0;
      if (datas.payments && datas.payments.length) {
        const allPayments = datas.payments?.map((el) => ({
          ...el,
          amount:
            !el.advance_payment && !el.is_basic_invoice ? -el.amount : el.amount
        }));
        payedCalc = allPayments.reduce((accumulator, current) => {
          return accumulator + current.amount;
        }, 0);
      }
      payedCalc = Number(payedCalc.toFixed(2));
      checkInvoiceStatus(datas, total, payedCalc);
    }
  }, [datas]);

  const getAndDeletePlanning = async (order) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/plannings?number=${order.number}`
      });
      await dispatchAPI('DELETE', { url: `/plannings/${data[0]._id}` });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const updateInvoice = async (invoice, body, isPayed, { invoice_type }) => {
    let bodyData = body;
    try {
      await dispatchAPI('PATCH', {
        url: `/ordersnew/${invoice._id}`,
        body: bodyData
      });
      if (invoice.status === 'INVOICED') {
        const { data } = await dispatchAPI('GET', {
          url: `/invoices?number=${invoice.invoice_number}`
        });
        const allPayments = bodyData.payments.map((el) => ({
          ...el,
          amount:
            el.invoice_type === 'creditNote' ||
            (!el.invoice_type && !el.is_basic_invoice && !el.advance_payment)
              ? -Number(el.amount)
              : Number(el.amount)
        }));
        const newPayed = allPayments.reduce((accumulator, current) => {
          return accumulator + current.amount;
        }, 0);

        if (isPayed) {
          bodyData = { ...bodyData, status: 'PAYED', invoice_type };
          getAndDeletePlanning(invoice);
        }
        bodyData = { ...bodyData, advanced_payment: newPayed };
        await dispatchAPI('PATCH', {
          url: `/invoices/${data[0]._id}`,
          body: bodyData
        });
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const onFinish = (values) => {
    let payments = [];
    if (datas.payments && datas.payments.length) {
      payments = [...datas.payments, values];
    } else {
      payments.push(values);
    }
    let payedCalc = false;
    if (toPayed?.toFixed(2) === parseFloat(values.amount).toFixed(2))
      payedCalc = true;
    const body = {
      quote: { ...datas.quote },
      infos: { ...datas.infos },
      payed: payedCalc,
      payments: [...datas.payments, values],
      section_1: { ...datas.section_1 }
    };

    updateInvoice(datas, body, payedCalc, values);
    return handleOk();
  };

  const onChangeAmount = (e) => {
    const invoiceType = form.getFieldsValue().invoice_type;
    const inputValue = Number(e.target.value);

    setInvoiceRadio(inputValue > toPayed.toFixed(2));

    const canSolde = Number(e.target.value) === Number(toPayed.toFixed(2));
    if (!canSolde && invoiceType === 'basic')
      form.setFieldValue('invoice_type', 'advancePayment');
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalVisible(true);
        }}
        icon={<CreditCardOutlined />}
        style={{ marginBottom: 20 }}
      >
        {t('ordersnew.addPayment_button')}
      </Button>
      <Modal
        title={t('ordersnew.advancedPayment_modal.addPayment')}
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            invoice_type: toPayed !== 0 ? 'advancePayment' : 'creditNote'
          }}
        >
          <Form.Item
            label={t('quotations.show.amount')}
            name="amount"
            rules={[
              {
                required: true,
                message: 'Renseigner le montant'
              }
            ]}
          >
            <Input
              onChange={(e) => onChangeAmount(e)}
              step="0.01"
              min={0}
              type="number"
            />
          </Form.Item>
          <Form.Item hidden name={['user']} initialValue={user._id} />
          <Form.Item hidden name={['date']} initialValue={new Date()} />
          <Form.Item
            label={t('quotations.show.method')}
            name="method"
            rules={[
              {
                required: true,
                message: 'Renseigner la méthode de paiement'
              }
            ]}
          >
            <Select
              options={[
                'Chèque',
                'Monnaie',
                'Carte bancaire',
                'Virement'
              ].map((el) => ({ label: el, value: el }))}
            />
          </Form.Item>
          <Form.Item name="invoice_type">
            <Radio.Group>
              <Radio value="advancePayment">
                {t('quotations.show.advancePayment')}
              </Radio>
              <Radio value="creditNote">
                {t('quotations.show.creditNote')}
              </Radio>

              <Radio disabled={invoiceRadio} value="basic">
                {t('quotations.show.basic')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('ordersnew.addpayments_submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        columns={columns}
        dataSource={datas?.payments}
        pagination={false}
      />
      <Space size="large" direction="vertical" align="end">
        <div style={{ color: 'red' }}>
          {`Reste à payer TTC : ${numeral(toPayed).format('0,0.00')} €`}
        </div>
        <div style={{ color: 'green' }}>
          {`Déja payé TTC : ${numeral(payed).format('0,0.00')} €`}
        </div>
        <div style={{ color: 'var(--primaryColor)' }}>
          {`Total TTC : ${numeral(datas?.totals?.totalsTTC).format(
            '0,0.00'
          )} €`}
        </div>
      </Space>
    </>
  );
};

Payments.propTypes = {
  datas: PropTypes.shape({
    _id: PropTypes.any,
    quote: PropTypes.any,
    infos: PropTypes.any,
    advanced_payment: PropTypes.any,
    section_1: PropTypes.any,
    payments: PropTypes.shape({
      length: PropTypes.any,
      map: PropTypes.func,
      amount: PropTypes.number
    }),
    totals: PropTypes.shape({
      totalsTTC: PropTypes.number
    }),
    status: PropTypes.string
  }).isRequired,
  forceRefresh: PropTypes.func.isRequired
};

export default Payments;
