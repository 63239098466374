import moment from 'moment';

const checkIsAdvance = (datas, reverse) => {
  const { advance_payment, payments, totals, number, is_credit_note } = datas;

  const payment = payments.find((el) => number === el.advance_payment_number);

  let result = Number((totals?.totalsTTC).toFixed(2));
  if (!payment && advance_payment && advance_payment > 0)
    result -= advance_payment;

  if (!reverse && is_credit_note) return '';
  if (reverse && !is_credit_note) return '';

  return result;
};

export const headers = [
  {
    label: 'invoices.column.emission_date',
    render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY')
  },
  {
    label: 'invoices.column.accounting_journal',
    render: ({ infos }) => infos?.commercial_site?.accounting_journal || 'N/R'
  },
  {
    label: 'invoices.column.accounting-code',
    render: ({ infos }) =>
      infos?.profesional?.customer_code ||
      infos?.customer?.customer_code ||
      'N/R'
  },
  {
    label: 'invoices.column.number',
    render: ({ number }) => number || 'N/R'
  },
  {
    label: 'invoices.column.name',
    render: ({ infos }) => {
      if (infos && infos.profesional) {
        return `${infos.profesional?.name || ''}`;
      }
      if (infos && infos.customer) {
        return `${infos.customer?.last_name || ''}`;
      }
      return 'N/R';
    }
  },
  {
    label: 'invoices.column.total-debit',
    render: ({ totals, is_credit_note, payments, is_advance_payment }) => {
      if (is_credit_note) {
        return '';
      }
      if (is_advance_payment) {
        return Number(totals.totalsTTC.toFixed(2)) || 'N/R';
      }
      if (payments.length && !is_advance_payment) {
        let advancePaymentAmount = 0;

        payments.forEach((payment) => {
          if (payment.advance_payment) {
            advancePaymentAmount += payment.amount;
          }
        });

        const totalClient = totals.totalClient;
        const totalAdvHt = advancePaymentAmount * (100 / (100 + totals.tva));
        const baseTaxe = totalClient - totalAdvHt;
        const mtTva = baseTaxe * (totals.tva / 100);

        return Number(baseTaxe.toFixed(2)) + Number(mtTva.toFixed(2)) || 'N/R';
      }
      const totalTTC = totals.totalClient * (1 + totals.tva / 100);
      return Number(totalTTC.toFixed(2)) || 'N/R';
    }
  },
  {
    label: 'invoices.column.total-credit',
    render: (totals) => checkIsAdvance(totals, true)
  }
];
