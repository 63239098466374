import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tabs,
  Radio
} from 'antd';
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Sticky, StickyContainer } from 'react-sticky';
import styled from 'styled-components';
import StockTagForMatter from '../../../Stocks/StockTagForMatter';
import useAuthContext from '../../../../contexts/AuthContext';
import { CloseIcon } from '../../../../utils/constants/customIcon';
import SizesForm from './SizesForm';

const { Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

const Content = styled.div`
  margin-bottom: 40px;
  position: relative;
`;

const Sections = ({ datas, forceRefresh, matters, isEditable }) => {
  const [sections, setSections] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [numSection, setNumSection] = useState(null);
  const [sectionSelected, setSectionSelected] = useState(null);
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [filters, setFilters] = useState([]);
  const [name, setName] = useState('');
  const initialValue = {
    matter: '',
    matter_desc: '',
    color: '',
    thikness: '',
    shaping: '',
    unit_price: ''
  };

  const projectTypes = [
    'Agencement',
    'Cuisine',
    'Dallage',
    'Funéraire',
    'Salle de bain',
    'Autres'
  ];

  const handleOk = async () => {
    setVisible(false);
    // setTimeout(() => {
    //   setLoading(false);
    //   setVisible(false);
    // }, 60);
    setSectionSelected(null);
    const values = form.getFieldsValue();
    await dispatchAPI('PATCH', {
      url: `/invoices/${id}`,
      body: {
        [values.nameSection]: values[values.nameSection],
        number: datas.number
      }
    });
    forceRefresh();
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    setSectionSelected(null);
    form.resetFields();
  };
  useEffect(() => {
    if (datas) {
      const sectionsArray = [];
      Object.keys(datas).forEach((key) => {
        if (key.startsWith('section_')) {
          sectionsArray.push(datas[key]);
        }
      });
      setSections(sectionsArray);
    }
  }, [datas]);
  const onSelectMatter = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              color: undefined,
              shaping: undefined,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    const newFilters = filters;
    newFilters[index] = { label: value };

    setFilters(newFilters);
  };

  const onSelectColor = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              shaping: undefined,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    setFilters(
      filters.map((filter, findex) => {
        if (findex === index) {
          return { ...filter, color: value.label };
        }
        return filter;
      })
    );
  };

  const onSelectShaping = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    setFilters(
      filters.map((filter, findex) => {
        if (findex === index) {
          return { ...filter, shaping: value };
        }
        return filter;
      })
    );
  };

  // Save price once the thickness is selected
  const onSelectThickness = (value, index) => {
    const values = form.getFieldsValue();

    const matterPrice = matters
      ?.find(({ _id }) => _id === filters[index].shaping)
      ?.pricing?.find(({ size }) => size === value)?.buy_price;

    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              unit_price: matterPrice
            };
          }
        });
      }
    });
    form.setFieldsValue(values);
  };
  const editSection = (section, index) => {
    setNumSection(index);
    setSectionSelected(section);
    setName(`section_${index}`);
    const elemets = section.elements.map((el) => {
      return {
        ...el,
        color: el.color._id,
        shaping: el.shaping._id,
        sizes: el.sizes.map((size) => {
          return {
            ...size,
            shapings: size.shapings.map((shaping) => {
              return {
                ...shaping,
                shaping_name: shaping.shaping_name._id
              };
            })
          };
        })
      };
    });
    const values = {
      [`section_${index}`]: {
        description: section.description,
        project_type: section.project_type,
        elements: elemets
      },
      nameSection: `section_${index}`
    };
    form.setFieldsValue(values);
    Object.keys(values).forEach((key) => {
      if (key === `section_${index}`) {
        if (!values[key].elements) {
          return;
        }

        const newFilters = [];
        values[key].elements.forEach((el, position) => {
          const matterColorLabel = matters?.find(({ _id }) => _id === el?.color)
            ?.color;
          const currentFilter = {
            label: el.matter,
            color: matterColorLabel,
            shaping: el.shaping
          };
          newFilters[position] = currentFilter;
        });
        setFilters(newFilters);
      }
    });
    setVisible(!visible);
  };
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          className="site-custom-tab-bar"
          style={{ ...style }}
        />
      )}
    </Sticky>
  );
  if (sections) {
    return (
      <div>
        {sections.map((section, index) => {
          return (
            section.description && (
              <Row style={{ padding: '0 24px 24px 24px' }}>
                <Col span={24}>
                  <Card
                    style={{
                      padding: 0,
                      background: 'rgba(255, 255, 255, 0.8)'
                    }}
                    bordered={false}
                  >
                    <div>
                      <Row
                        style={{
                          width: '100%',
                          flexWrap: 'nowrap',
                          overflow: 'auto'
                        }}
                      >
                        <Col span={4}>
                          <Title level={4}>
                            {`${section.project_type} - ${section.description}`}
                          </Title>
                          <div style={{ width: '110px' }}>
                            <hr
                              style={{
                                backgroundColor: 'black',
                                height: '3px',
                                border: 'none',
                                marginBottom: '18px'
                              }}
                            />
                          </div>

                          <Link
                            to={{
                              pathname: `/stock/stocks`
                            }}
                          >
                            <Button type="primary" icon={<EyeOutlined />}>
                              CONSULTER LE STOCK
                            </Button>
                          </Link>
                        </Col>
                        <Col
                          span={20}
                          style={{ display: 'flex', overflowX: 'auto' }}
                        >
                          {section.elements.map((mat) => {
                            return (
                              <Col style={{ margin: '16px' }}>
                                <Card
                                  style={{
                                    width: 'fit-content',
                                    height: '100%'
                                  }}
                                  bordered={false}
                                >
                                  <div>
                                    <pre style={{ marginBottom: 0 }}>
                                      <Col>
                                        <Row style={{ marginBottom: '16px' }}>
                                          <Col style={{ marginRight: '16px' }}>
                                            <div
                                              style={{
                                                whiteSpace: 'normal',
                                                fontSize: 16
                                              }}
                                            >
                                              {`${mat.color?.color} `}
                                            </div>
                                          </Col>
                                          <Col>
                                            {
                                              <StockTagForMatter
                                                matter={mat}
                                                sizesNeeded={mat.sizes}
                                              />
                                            }
                                          </Col>
                                        </Row>
                                        <Row>
                                          {`Finition : `}
                                          <b>{`${mat.shaping.completions ||
                                            ''}`}</b>
                                        </Row>
                                        <Row>
                                          {`Epaisseur ${
                                            mat.is_credence
                                              ? 'crédence'
                                              : 'plan'
                                          } : `}
                                          <b>{`${mat.thikness} cm`}</b>
                                        </Row>
                                        <Row>
                                          {`Description : `}
                                          <b>{`${mat.matter_desc}`}</b>
                                        </Row>

                                        <Row>Dimensions : </Row>
                                        <ul>
                                          {mat.sizes.map((size, index) => {
                                            return (
                                              <li>
                                                <b>
                                                  <div>
                                                    {`Pièce ${index + 1} ${
                                                      size.height
                                                    } x ${size.width}`}
                                                  </div>
                                                  {size.shapings &&
                                                    size.shapings.length >
                                                      0 && (
                                                      <div>
                                                        {size.shapings.map(
                                                          (shaping) => {
                                                            return (
                                                              <div>
                                                                {shaping.shaping_name &&
                                                                  shaping
                                                                    .shaping_name
                                                                    .label && (
                                                                    <div
                                                                      style={{
                                                                        whiteSpace:
                                                                          'normal'
                                                                      }}
                                                                    >
                                                                      {`${shaping.shaping_name.label} x${shaping.nb}`}
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </b>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </Col>
                                    </pre>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Col>
                      </Row>
                      {/* <div > */}
                      {isEditable && (
                        <EditOutlined
                          style={{ fontSize: '20px', float: 'right' }}
                          onClick={() => {
                            editSection(section, index + 1);
                          }}
                        />
                      )}
                      <Modal
                        visible={visible}
                        title={`Modifier la section ${numSection}`}
                        onOk={handleOk}
                        width={1000}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleCancel}>
                            Return
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleOk}
                          >
                            Submit
                          </Button>
                        ]}
                      >
                        <Form form={form}>
                          <Form.Item hidden name="nameSection" />
                          <div className="quotation-form-fields-wrapper">
                            <Form.Item
                              label={t('configurator.project_type')}
                              name={[name, 'project_type']}
                              rules={[{ required: true }]}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 8 }}
                            >
                              <Select
                                allowClear
                                options={projectTypes.map((item) => ({
                                  label: item,
                                  value: item
                                }))}
                              />
                            </Form.Item>
                            <Form.Item
                              label={t('configurator.description')}
                              name={[name, 'description']}
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 8 }}
                              rules={[{ required: true }]}
                            >
                              <TextArea />
                            </Form.Item>
                          </div>
                          <Form.List name={[name, 'elements']}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((item, index) => (
                                  <Content className="quotation-form-fields-wrapper">
                                    <StickyContainer>
                                      <Tabs renderTabBar={renderTabBar}>
                                        <TabPane
                                          tab={t('configurator.matter')}
                                          key="1"
                                        >
                                          <Form.Item
                                            label={t(
                                              'configurator.is_credence'
                                            )}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'is_credence']}
                                            fieldKey={[
                                              item.fieldKey,
                                              'is_credence'
                                            ]}
                                            rules={[{ required: true }]}
                                            initialValue={false}
                                          >
                                            <Radio.Group>
                                              <Radio value={false}>Plan</Radio>
                                              <Radio value>Crédence</Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                          <Form.Item
                                            label={t('configurator.matter')}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'matter']}
                                            fieldKey={[item.fieldKey, 'matter']}
                                            rules={[{ required: true }]}
                                            initialValue={initialValue.matter}
                                          >
                                            <Select
                                              showSearch
                                              allowClear
                                              optionFilterProp="label"
                                              options={[
                                                ...new Set(
                                                  matters?.map(
                                                    ({ label }) => label
                                                  )
                                                )
                                              ].map((label) => ({
                                                label,
                                                value: label
                                              }))}
                                              onChange={(value) => {
                                                onSelectMatter(value, index);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            label={t('configurator.color')}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'color']}
                                            fieldKey={[item.fieldKey, 'color']}
                                            rules={[{ required: true }]}
                                            initialValue={initialValue.color}
                                          >
                                            <Select
                                              showSearch
                                              optionFilterProp="label"
                                              options={matters
                                                ?.filter(({ label }) =>
                                                  !filters[index]
                                                    ? false
                                                    : label ===
                                                      filters[index].label
                                                )
                                                .map(({ color, _id }) => ({
                                                  label: color,
                                                  value: _id
                                                }))}
                                              onChange={(_, values) => {
                                                onSelectColor(values, index);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            label={t('configurator.shaping')}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'shaping']}
                                            fieldKey={[
                                              item.fieldKey,
                                              'shaping'
                                            ]}
                                            rules={[{ required: true }]}
                                            initialValue={initialValue.shaping}
                                          >
                                            <Select
                                              showSearch
                                              optionFilterProp="label"
                                              options={matters
                                                ?.filter(({ color }) =>
                                                  !(
                                                    filters[index] &&
                                                    filters[index].color
                                                  )
                                                    ? false
                                                    : color ===
                                                      filters[index].color
                                                )
                                                .map(
                                                  ({ completions, _id }) => ({
                                                    label: completions,
                                                    value: _id
                                                  })
                                                )}
                                              onChange={(value) => {
                                                onSelectShaping(value, index);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            label={t('configurator.thikness')}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'thikness']}
                                            fieldKey={[
                                              item.fieldKey,
                                              'thikness'
                                            ]}
                                            rules={[{ required: true }]}
                                            initialValue={initialValue.thikness}
                                          >
                                            <Select
                                              showSearch
                                              optionFilterProp="label"
                                              options={
                                                !filters[index]
                                                  ? false
                                                  : matters
                                                      ?.find(
                                                        ({ _id }) =>
                                                          _id ===
                                                          filters[index].shaping
                                                      )
                                                      ?.pricing?.map(
                                                        ({ size }) => ({
                                                          label: size,
                                                          value: size
                                                        })
                                                      )
                                              }
                                              onChange={(value) => {
                                                onSelectThickness(value, index);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            hidden
                                            name={[item.name, 'unit_price']}
                                            fieldKey={[
                                              item.fieldKey,
                                              'unit_price'
                                            ]}
                                            initialValue={
                                              initialValue.unit_price
                                            }
                                            rules={[{ required: true }]}
                                          >
                                            <TextArea />
                                          </Form.Item>
                                          <Form.Item
                                            label={t(
                                              'configurator.matter_desc'
                                            )}
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 8 }}
                                            name={[item.name, 'matter_desc']}
                                            fieldKey={[
                                              item.fieldKey,
                                              'matter_desc'
                                            ]}
                                            rules={[{ required: true }]}
                                          >
                                            <TextArea />
                                          </Form.Item>
                                        </TabPane>
                                        <TabPane
                                          tab={t('configurator.sizes')}
                                          key="2"
                                        >
                                          <SizesForm
                                            name={[item.name, 'sizes']}
                                            sectionId={name}
                                            datas={datas}
                                          />
                                        </TabPane>
                                      </Tabs>
                                    </StickyContainer>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        remove(item.name);
                                        // removeSectionFromForm(item.name);

                                        const newFilters = filters.filter(
                                          (val, ind) => ind !== item.name
                                        );

                                        setFilters([...newFilters]);
                                      }}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Content>
                                ))}
                                <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                      // const values = form.getFieldsValue();
                                      // const index =
                                      //   values[name].elements.length - 1;
                                      // if (values[name].elements.length > 0) {
                                      //   values[name].elements[index] = {
                                      //     ...values[name].elements[index - 1]
                                      //   };
                                      //   delete values[name].elements[index]._id;
                                      //   form.setFieldsValue(values);
                                      //   Object.keys(values).forEach((key) => {
                                      //     if (key === name) {
                                      //       if (!values[key].elements) {
                                      //         return;
                                      //       }

                                      //       const newFilters = [];
                                      //       values[key].elements.forEach(
                                      //         (el, position) => {
                                      //           const matterColorLabel = matters?.find(
                                      //             ({ _id }) => _id === el?.color
                                      //           )?.color;

                                      //           const currentFilter = {
                                      //             label: el.matter,
                                      //             color: matterColorLabel,
                                      //             shaping: el.shaping
                                      //           };
                                      //           newFilters[
                                      //             position
                                      //           ] = currentFilter;
                                      //         }
                                      //       );
                                      //       setFilters(newFilters);
                                      //     }
                                      //   });
                                      // }
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                    className="quotation-add-btn"
                                  >
                                    {t('configurator.add_matter')}
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </Modal>
                      {/* </div> */}
                    </div>
                  </Card>
                </Col>
              </Row>
            )
          );
        })}
      </div>
    );
  }
  return <div> Loading sections ...</div>;
};

Sections.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.object).isRequired,
  forceRefresh: PropTypes.func.isRequired
};

export default Sections;
