import React, { useEffect, useState } from 'react';
import { Select, Form, InputNumber, Divider, Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CloseIcon } from '../../../utils/constants/customIcon';
import useConfiguratorContext from './ConfiguratorContext';
import AccessoryForm from './AccessoryForm';

const ProductsForm = () => {
  const { datas, currentStep, t, form } = useConfiguratorContext();
  const { products } = datas;

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // Initialize filters if there is already data (quotation modification)

    if (currentStep === 2) {
      const values = form.getFieldsValue();
      Object.keys(values).forEach((key) => {
        if (key === 'products') {
          const newFilters = [];
          if (values[key]) {
            values[key].forEach((el, position) => {
              const currentFilter = { category: el.category };
              newFilters[position] = currentFilter;
            });

            setFilters(newFilters);
          }
        }
      });
    }
  }, [currentStep]);

  const onSelectCategory = (value, index) => {
    const values = form.getFieldsValue();

    values.products[index] = {
      ...values.products[index],
      product: undefined,
      unit_price: undefined
    };

    form.setFieldsValue(values);

    const newFilters = filters;

    newFilters[index] = { category: value };

    setFilters(newFilters);
  };

  const onSelectProduct = (value, index) => {
    const values = form.getFieldsValue();

    const productPrice = products
      .filter((product) => product._id === value)
      .shift().sold_price;

    Object.keys(values).forEach((key) => {
      if (key === 'products') {
        values[key].forEach((el, position) => {
          if (index === position) {
            values[key][position] = {
              ...el,
              unit_price: productPrice
            };
          }
        });
      }
    });
    form.setFieldsValue(values);
  };

  return (
    <div>
      <div
        className="quotation-form-wrapper"
        style={{ display: currentStep === 2 ? '' : 'none' }}
      >
        <Typography.Title
          level={4}
          style={{ display: currentStep === 2 ? '' : 'none' }}
        >
          {t('configurator.products')}
        </Typography.Title>
        <Form.List
          name={['products']}
          style={{ display: currentStep === 2 ? '' : 'none' }}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, fieldKey }) => (
                <div
                  style={{
                    position: 'relative',
                    display: currentStep === 2 ? '' : 'none'
                  }}
                  className="quotation-form-fields-wrapper"
                >
                  <Form.Item
                    label={t('configurator.product_category')}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 8 }}
                    name={[name, 'category']}
                    fieldKey={[fieldKey, 'category']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        ...new Set(products?.map(({ category }) => category))
                      ].map((label) => ({ label, value: label }))}
                      onChange={(value) => {
                        onSelectCategory(value, name);
                      }}
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('configurator.product')}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 8 }}
                    name={[name, 'product']}
                    fieldKey={[fieldKey, 'product']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={products
                        ?.filter(({ category }) =>
                          !filters[name]
                            ? false
                            : category === filters[name].category
                        )
                        .map(({ _id, label }) => ({
                          label,
                          value: _id
                        }))}
                      showSearch
                      optionFilterProp="label"
                      onChange={(value) => onSelectProduct(value, name)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('configurator.quantity')}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 8 }}
                    name={[name, 'quantity']}
                    fieldKey={[fieldKey, 'quantity']}
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={[name, 'unit_price']}
                    fieldKey={[fieldKey, 'unit_price']}
                    rules={[{ required: true }]}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      remove(name);

                      const newFilters = filters.filter(
                        (val, ind) => ind !== name
                      );

                      setFilters([...newFilters]);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                  <Divider />
                </div>
              ))}
              <Form.Item
                wrapperCol={{ span: 8, offset: 8 }}
                style={{ display: currentStep === 2 ? '' : 'none' }}
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  className="quotation-add-btn"
                >
                  {t('configurator.add_product')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <AccessoryForm />
    </div>
  );
};

export default ProductsForm;
