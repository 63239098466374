import React, { useState } from 'react';
import {
  Switch,
  Form,
  Typography,
  Select,
  InputNumber,
  DatePicker
} from 'antd';
import useConfiguratorContext from './ConfiguratorContext';

const PlacedForm = () => {
  const { currentStep, datas, t } = useConfiguratorContext();
  const { labors } = datas;
  const [disabled, setDisabled] = useState(false);

  return (
    <div
      className="quotation-form-wrapper"
      style={{ display: currentStep === 3 ? '' : 'none' }}
    >
      <Typography.Title
        level={4}
        style={{ display: currentStep === 3 ? '' : 'none' }}
      >
        {t('configurator.placed')}
      </Typography.Title>
      <div className="quotation-form-fields-wrapper">
        <Form.Item
          label={t('configurator.measure')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'measure']}
        >
          <Select
            options={labors
              ?.filter(({ category }) => category === 'measure')
              .map(({ label, _id }) => ({ label, value: _id }))}
            allowClear
            showSearch
            optionFilterProp="label"
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.lay')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'lay']}
        >
          <Select
            options={labors
              ?.filter(({ category }) => category === 'lay')
              .map(({ label, _id }) => ({ label, value: _id }))}
            allowClear
            showSearch
            optionFilterProp="label"
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.displacement')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'displacement']}
        >
          <Select
            options={labors
              ?.filter(({ category }) => category === 'displacement')
              .map(({ label, _id }) => ({ label, value: _id }))}
            allowClear
            showSearch
            optionFilterProp="label"
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.lay_date')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'lay_date']}
        >
          <DatePicker disabled={disabled} />
        </Form.Item>
        <Form.Item
          label={t('configurator.measurement_date')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'measurement_date']}
        >
          <DatePicker disabled={disabled} />
        </Form.Item>
        <Form.Item
          label={t('configurator.deliveryCost')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['placed', 'deliveryCost']}
        >
          <InputNumber min={0} step="0.01" />
        </Form.Item>
        <Form.Item
          label={t('configurator.withoutLay')}
          name={['placed', 'without_lay']}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValue={false}
          valuePropName="checked"
        >
          <Switch onChange={(value) => setDisabled(value)} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PlacedForm;
