/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  InputNumber,
  Form,
  Input,
  Select,
  Tag,
  message as stop
} from 'antd';
import { TextArea } from 'antd/lib/input/TextArea';
import { CreditCardOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { profesionalsTypes } from '../../utils/constants/tagColors';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const CommissionAmount = ({ forceRefresh, setForceRefresh, data, setCom }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profesionals, setProfesionals] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [totalPayed, setTotals] = useState();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();

  const { Option } = Select;

  const getProfesionals = async () => {
    try {
      const { fetchedData } = await dispatchAPI('GET', { url: '/users' });
      setProfesionals(fetchedData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCommission = async () => {
    try {
      const { fetchedData } = await dispatchAPI('GET', {
        url: `/commissions/${id}?populate=profesional,building_site,payed.user`
      });
      setCommissions(fetchedData.payed.map((el) => el));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCommission();
      await getProfesionals();
    })();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setForceRefresh(!forceRefresh);
    setIsModalVisible(false);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  };

  const getTotalPayed = () => {
    let totals = 0;
    if (commissions) {
      commissions.forEach((el) => {
        totals += el.amount;
      });
    }
    return setTotals(totals);
  };
  const getTotalPayed2 = () => {
    let totals = 0;
    if (commissions) {
      commissions.forEach((el) => {
        totals += el.amount;
      });
    }
    return setCom(totals);
  };

  const onFinish = async (payed) => {
    if (
      data.amount - (totalPayed && totalPayed) - parseInt(payed.amount, 10) <
      0
    ) {
      return stop.error('Le montant saisie est supérieur au reste à charge !');
    }
    if (
      data.amount - (totalPayed && totalPayed) - parseInt(payed.amount, 10) ===
      0
    ) {
      const body = {
        status: 'PAYED',
        payed
      };
      try {
        await dispatchAPI('PATCH', {
          url: `/commissions/${id}`,
          body
        });
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      getCommission();
      handleOk();
    } else {
      const body = {
        payed
      };
      try {
        await dispatchAPI('PATCH', {
          url: `/commissions/${id}`,
          body
        });
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      getCommission();
      handleOk();
    }
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    getTotalPayed2();
    getTotalPayed();
  }, [commissions]);

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        icon={<CreditCardOutlined />}
        getContainer={() => (
          <>
            <TextArea />
            <InputNumber />
          </>
        )}
      >
        Payer
      </Button>

      <Modal
        title="Paiement de la commission"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Utisateur"
            name="user"
            rules={[
              {
                message: 'Please input your username!'
              }
            ]}
          >
            <Select>
              {(profesionals || []).map((item) => (
                <Option key={item._id} value={item._id}>
                  <Tag color={profesionalsTypes[item.first_name]}>
                    {item.first_name} {item.last_name}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Montant"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input type={Number} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

CommissionAmount.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.any,
    payed: PropTypes.array
  }).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setCom: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

export default CommissionAmount;
