/* eslint-disable prefer-template */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Select,
  Form,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Card,
  Typography,
  Input,
  Radio,
  message
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';

const { Option } = Select;

const HeaderForm = ({
  sites,
  commercialSites,
  productionSites,
  customer,
  operators,
  form,
  isValidDefinitevly,
  date_lay,
  measurement_date
}) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { dispatchAPI } = useAuthContext();

  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const url = '/laysheets/enums';
      const { data } = await dispatchAPI('GET', {
        url
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  useEffect(() => {}, [enums]);

  useEffect(() => {
    (async () => {
      await getEnums();
    })();

    form.setFieldsValue({
      ...form.getFieldsValue(),
      commercial_site: commercialSites,
      production_sites: productionSites,
      customer: customer._id,
      phone_number_2: customer.phone_number_2,
      lay_pv: form.getFieldsValue().lay_pv
        ? form.getFieldsValue().lay_pv[0]
        : [],
      delivery_date: moment(date_lay),
      measurement_date: moment(measurement_date)
    });
  }, [form]);

  return (
    <Col span={24} style={{ height: 'fit-content' }}>
      <Card style={{ background: 'rgba(255, 255, 255, 0.9)' }} bordered={false}>
        <div>
          <Title level={5}>
            {t('laysheet.form.titles.general_information')}
          </Title>
          <div style={{ width: '103px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          <Row>
            <Col xs={22} xxl={10} offset={1}>
              <Form.Item
                name={['commercial_site']}
                label={t('laysheet.form.values.commercial_site')}
                rules={[{ required: true }]}
              >
                <Select
                  disabled
                  options={sites.map(({ _id, label }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
              <Form.Item
                name={['production_sites']}
                label={t('laysheet.form.values.production_site')}
                rules={[{ required: true }]}
              >
                <Select
                  disabled
                  mode="multiple"
                  options={sites.map(({ _id, label }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
              <Form.Item
                name={['customer']}
                label={t('laysheet.form.values.customer')}
                rules={[{ required: true }]}
              >
                <Select
                  disabled
                  defaultValue={`${customer?.first_name || ''} ${
                    customer?.last_name
                  }`}
                >
                  <Option value={customer._id}>
                    {`${customer?.first_name || ''} ${customer?.last_name}`}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="delivery_date"
                label={t('laysheet.form.values.delivery_date')}
                rules={[{ required: true }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="measurement_date"
                label={t('laysheet.form.values.measurement_date')}
                rules={[{ required: true }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name={['address']}
                label={t('laysheet.form.values.work_site_address')}
                rules={[{ required: true }]}
              >
                <Input
                  disabled={isValidDefinitevly}
                  defaultValue={`${customer?.address?.number || ''} ${
                    customer?.address?.street
                  } ${customer?.address?.postal_code} ${
                    customer?.address?.city
                  }`}
                />
              </Form.Item>
              <Form.Item
                name={['phone']}
                label={t('laysheet.form.values.phone_number_2')}
              >
                <Input
                  disabled={isValidDefinitevly}
                  defaultValue={customer?.phone_number_2}
                />
              </Form.Item>
              <Row>
                <Col xs={24} lg={16}>
                  <Form.Item
                    name={['other_phone']}
                    label={t('laysheet.form.values.other_phone')}
                  >
                    <Input
                      disabled={isValidDefinitevly}
                      style={{ width: '80%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    name={['specify_name']}
                    label={t('laysheet.form.values.specify_name')}
                  >
                    <Input disabled={isValidDefinitevly} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name={['lay_team']}
                label={t('laysheet.form.values.lay_team')}
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isValidDefinitevly}
                  options={operators.map(({ name }) => ({
                    label: name,
                    value: name
                  }))}
                  mode="multiple"
                />
              </Form.Item>
              <Form.Item label="Situation chantier" name={['stage_site']}>
                <Select>
                  {enums?.stairs?.map((stair) => (
                    <Option value={stair} key={stair}>
                      {t(`laysheet.enums.stairs.${stair}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={['protections']} label="Protection chantier">
                <Checkbox.Group disabled={isValidDefinitevly}>
                  <Checkbox value="grounds">
                    {t('laysheet.form.values.grounds')}
                  </Checkbox>
                  <Checkbox value="element_pose">
                    {t('laysheet.form.values.element_pose')}
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col xs={22} xxl={10} offset={1}>
              <Form.Item
                name={['status']}
                label={t('laysheet.form.values.sheet_status')}
              >
                <Select disabled={isValidDefinitevly}>
                  {enums?.status?.map((statut) => (
                    <Option value={statut} key={statut}>
                      {t(`laysheet.enums.status.${statut}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row align="middle">
                <Col xs={24} sm={12} style={{ paddingBottom: 10 }}>
                  <span>{t('laysheet.form.values.point_manager')}</span>
                </Col>
                <Col xs={6} sm={3}>
                  <Form.Item
                    name={['point_manager']}
                    valuePropName="checked"
                    rules={[{ required: true }]}
                  >
                    <Checkbox disabled={isValidDefinitevly}>
                      {t('laysheet.form.values.yes')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={18} sm={9}>
                  <Form.Item name={['name_manager']} label="Nom">
                    <Input disabled={isValidDefinitevly} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12}>
                  {t('laysheet.form.values.verbal')}
                </Col>
                <Col xs={24} sm={12} style={{ padding: '0px 0px 0px 16px' }}>
                  <Form.Item name={['lay_pv']}>
                    <Radio.Group disabled={isValidDefinitevly}>
                      <Radio value="ValidatedWithout">
                        {t('laysheet.form.values.validated_without')}
                      </Radio>
                      <Radio value="ValidatedWith">
                        {t('laysheet.form.values.validated_with')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name={['coordinates_GPS']}
                label={t('laysheet.form.values.coordinates_GPS')}
              >
                <Input disabled={isValidDefinitevly} />
              </Form.Item>
              <Form.Item>
                <Input hidden />
              </Form.Item>
              <Row>
                <Col xs={24} lg={16}>
                  <Form.Item
                    name={['other_phone2']}
                    label={t('laysheet.form.values.other_phone')}
                  >
                    <Input
                      disabled={isValidDefinitevly}
                      style={{ width: '80%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    name={['specify_name2']}
                    label={t('laysheet.form.values.specify_name2')}
                  >
                    <Input disabled={isValidDefinitevly} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name={['need_interim']}
                label={t('laysheet.form.values.need_interim')}
                valuePropName="checked"
              >
                <Checkbox disabled={isValidDefinitevly} />
              </Form.Item>
              <Form.Item
                name={['dismantling']}
                label={t('laysheet.form.values.dismantling')}
                valuePropName="checked"
              >
                <Checkbox disabled={isValidDefinitevly} />
              </Form.Item>
              <Form.Item name={['pictures']} label="Photos chantier">
                <Checkbox.Group disabled={isValidDefinitevly}>
                  <Checkbox value="before_pose">
                    {t('laysheet.form.values.before_pose')}
                  </Checkbox>
                  <Checkbox value="after_pose">
                    {t('laysheet.form.values.after_pose')}
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

HeaderForm.propTypes = {
  commercialSites: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    _id: PropTypes.number,
    address: PropTypes.array,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number_1: PropTypes.string,
    phone_number_2: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isValidDefinitevly: PropTypes.bool.isRequired,
  operators: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  productionSites: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  sites: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  date_lay: PropTypes.shape().isRequired,
  measurement_date: PropTypes.shape().isRequired
};

export default HeaderForm;
