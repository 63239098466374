import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Divider, message, Popconfirm, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  searchValue,
  populate,
  style,
  extraQuery,
  forceRefresh,
  getURLParams,
  filter,
  isOrder
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (filterData = null, page = pagination, filters, sorters) => {
      setIsLoading(true);
      const { pageSize, current } = page;
      if (getURLParams) getURLParams(filters, sorters);

      const searchUrl = searchValue
        ? `filter={"$text": {"$search":"${searchValue}"}}&`
        : null;
      let sortingParameter = 'sort=-created_at&';

      if (sorters) {
        if (!sorters.order) {
          sortingParameter = 'sort=-created_at&';
        } else if (sorters.order === 'descend') {
          sortingParameter = `sort=-${sorters.columnKey}&`;
        } else {
          sortingParameter = `sort=${sorters.columnKey}&`;
        }
      }

      let filterParameter = '';
      Object.entries(filters || {}).forEach((el) => {
        if (el[1]) filterParameter += `${el[0]}=${[...el[1]]}&`;
      });
      Object.keys(filterData || {}).forEach((key) => {
        if (filterData[key]) {
          filterParameter += `${key}=${filterData[key]}&`;
        }
      });
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }${searchUrl || ''}limit=${pageSize}&skip=${(current - 1) * pageSize}`
        });
        const workSheets = await dispatchAPI('GET', {
          url: `/worksheets?populate=matter,customer,order`
        });
        const allWorkSheets = workSheets.data;
        setPagination({
          ...page,
          total: headers['x-total-count']
        });
        setResources(
          isOrder
            ? data.map(({ _id, ...d }) => {
                const datesWorkSheet = {};

                if (allWorkSheets) {
                  const filterWorSheet = allWorkSheets?.filter(
                    (workSheet) => workSheet?.order?._id === _id
                  );
                  filterWorSheet.forEach((worksheet) => {
                    if (worksheet.date !== undefined) {
                      if (datesWorkSheet[_id]) {
                        datesWorkSheet[_id].push(worksheet.date);
                      } else {
                        datesWorkSheet[_id] = [worksheet.date];
                      }
                      datesWorkSheet[_id].sort();
                      datesWorkSheet[_id].reverse();
                    }
                  });
                }
                if (datesWorkSheet[_id] !== undefined) {
                  return {
                    ...d,
                    key: _id,
                    estimated_construction_date: moment(
                      datesWorkSheet[_id][0]
                    ).format('DD/MM/YYYY')
                  };
                }

                return { ...d, key: _id, estimated_construction_date: 'N/R' };
              })
            : data.map(({ _id, ...d }) => ({ ...d, key: _id }))
        );
      } catch (e) {
        message.error(e.message);
      }
      setIsLoading(false);
    },
    [searchValue, forceRefresh, extraQuery]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const handlePageChange = async (page, filters, { columnKey, order } = {}) => {
    if (
      filter?.commercial_site ||
      filter?.production_site ||
      filter?.date_lowest ||
      filter?.date_highest
    ) {
      await fetchData(filter, page, filters, { columnKey, order });
    } else await fetchData(null, page, filters, { columnKey, order });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${path || pathname}/show/${key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${path || pathname}/edit/${key}`
            }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(key)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </div>
      )
    }
  ];
  useEffect(() => {
    if (
      filter?.commercial_site ||
      filter?.production_site ||
      filter?.date_lowest ||
      filter?.date_highest
    ) {
      (async () => {
        await fetchData(filter);
      })();
    } else {
      (async () => {
        await fetchData();
      })();
    }
  }, [filter]);
  return (
    <>
      <StyledTable
        as={Table}
        style={style}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onDoubleClick: () =>
            history.push({
              pathname: `${path || pathname}/show/${key}`
            })
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={pagination}
        columns={customActionColumn ? columns : [...columns, ...actionColumn]}
        scroll={{ x: 600 }}
      />
    </>
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  searchValue: PropTypes.string,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  getURLParams: PropTypes.func,
  isOrder: PropTypes.bool,
  filter: PropTypes.shape({
    commercial_site: PropTypes.shape({}),
    production_site: PropTypes.shape({}),
    date_lowest: PropTypes.shape({}),
    date_highest: PropTypes.shape({})
  })
};

Datatable.defaultProps = {
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  searchValue: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  getURLParams: null,
  isOrder: false,
  filter: null
};

export default Datatable;
