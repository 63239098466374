import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button, Col, Form, Row, Select } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useBoxContext from './PlanningContext';

const Circle1 = styled.div`
  height: 15px;
  width: 15px;
  background-color: #009999;
  border-radius: 50%;
  display: inline-block;
`;
const Circle2 = styled.div`
  height: 15px;
  width: 15px;
  background-color: #cc9900;
  border-radius: 50%;
  display: inline-block;
`;
const HeaderPage = ({
  showModal,
  dataSources,
  setDataSources,
  dataSourceOrder
}) => {
  const { sites } = useBoxContext();
  const { path } = useRouteMatch();
  const { canEditResource } = useAuthContext();
  const { Option } = Select;
  const [listCustomer, setListCustomer] = useState();
  const { t } = useTranslation();

  const onChangeProductionSite = (value) => {
    if (value) {
      setDataSources(
        dataSources.filter((planning) => {
          let found = false;
          planning.key.sites.forEach((site) => {
            site.forEach((el) => {
              if (el._id === value) found = true;
            });
          });
          return found;
        })
      );
    }
  };
  const onChangeOrder = (value) => {
    if (value) {
      setDataSources(
        dataSources.filter((planning) => planning.key.key === value)
      );
    }
  };
  const onChangeWorksite = (value) => {
    if (value) {
      setDataSources(dataSources.filter((el) => el.key.customer === value));
    }
  };
  const onClear = () => {
    setDataSources(dataSourceOrder);
  };
  useEffect(() => {
    const newListCustomer = {};

    dataSources.forEach((el) => {
      if (!newListCustomer[el.key.customer]) {
        newListCustomer[el.key.customer] =
          el.listeChantiers[0].order.infos.customer;
      }
    });
    setListCustomer(newListCustomer);
  }, [dataSources]);
  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={16}
      style={{ padding: '0px 15px 0px 6px' }}
    >
      <Col xs={10} xl={5}>
        <Form.Item name="production_site" label="Site">
          <Select
            showSearch
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            placeholder="Site de fabrication"
            optionFilterProp="children"
            onChange={onChangeProductionSite}
            onClear={onClear}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sites &&
              sites.map(({ _id, label }) => (
                <Option value={_id}>{label}</Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={14} xl={8}>
        <Form.Item name="filters" label="Filtres">
          <Select
            showSearch
            allowClear
            style={{ marginLeft: 5, width: 170 }}
            placeholder="Chantier"
            optionFilterProp="children"
            onChange={onChangeWorksite}
            onClear={onClear}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listCustomer &&
              Object.values(listCustomer).map((customer) => (
                <Option value={customer._id}>
                  {`${customer.last_name} ${customer.first_name || ''}`}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            allowClear
            style={{ marginLeft: 5, width: 170 }}
            placeholder="Commande"
            optionFilterProp="children"
            onChange={onChangeOrder}
            onClear={onClear}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataSources &&
              dataSources.map((p) => (
                <Option value={p.key.key}>{p.listeChantiers[0].number}</Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={16} xl={7}>
        <Form.Item label="Légende">
          <Circle1 />
          <span style={{ padding: '0px 24px 0px 10px' }}>ATELIER </span>
          <Circle2 /> <span style={{ padding: '0px 24px 0px 10px' }}>POSE</span>
        </Form.Item>
      </Col>
      <Col xs={8} xl={4} style={{ textAlign: 'right' }}>
        {canEditResource(path) && (
          <Button
            type="primary"
            style={{ marginBottom: 10 }}
            icon={<PlusOutlined />}
            onClick={showModal}
          >
            {t('planning.button.addConstruction')}
          </Button>
        )}
      </Col>
    </Row>
  );
};

HeaderPage.propTypes = {
  showModal: PropTypes.func.isRequired,
  dataSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDataSources: PropTypes.func.isRequired,
  dataSourceOrder: PropTypes.shape({
    map: PropTypes.func,
    filter: PropTypes.func
  }).isRequired
};

export default HeaderPage;
