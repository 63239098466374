import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListQuotations from './ListQuotations';
import ShowQuotations from './ShowQuotation';
import CreateUpdate from './CreateUpdate';

const QuotationsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/show/:id`}
        render={() => <ShowQuotations resource="quotations" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdate purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdate purpose="edit" />}
      />
      {/* <Route
        path={`${path}/create`}
        render={() => <QuotationGenerator purpose="create" />}
      /> */}
      <Route path={path} component={ListQuotations} />
    </Switch>
  );
};

export default QuotationsRouter;
