import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Tag, Space } from 'antd';
import { enumsStatus, colorTag } from '../../../../utils/constants/tagColors';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import Infos from './Infos';
import Sections from './Sections';
import Products from './Products';
import { Placed } from '../../../Quotes/Details/Placed';
import Totals from './Totals';
import useButtons from './Buttons';
import MarbleBackground from '../../../../assets/images/white-stone_Scott-Webb.jpg';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import Accessories from './Accessories';

const Details = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [refresh, setRefresh] = useState(false);
  const forceRefresh = () => setRefresh(!refresh);
  const [matters, setMatters] = useState([]);
  const [products, setProducts] = useState([]);
  const [labors, setLabors] = useState([]);

  const getMatters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/matters?populate=coef'
      });
      setMatters(data);
    } catch (e) {
      if (e.response) message.erroe(e.response.status);
    }
  };

  const getProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/products'
      });
      setProducts(data);
    } catch (e) {
      if (e.response) message.erroe(e.response.status);
    }
  };

  const getLabors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/labors'
      });
      setLabors(data);
    } catch (e) {
      if (e.response) message.erroe(e.response.status);
    }
  };

  useEffect(() => {
    getMatters();
    getProducts();
    getLabors();
  }, []);

  const extraButton = useButtons(datas);

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices/${id}`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getData();
  }, [id, refresh]);

  const renderStatus = () => {
    return (
      datas?.status && (
        <Tag color={colorTag[datas.status]}>{enumsStatus[datas.status]}</Tag>
      )
    );
  };

  const renderTypeInvoice = (data) => {
    switch (true) {
      case data.is_credit_note:
        return t('invoices.show.creditNote', { number: datas?.number });
      case data.is_advance_payment:
        return t('invoices.show.advNumber', { number: datas?.number });

      default:
        return t('invoices.show.number', { number: datas?.number });
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <Space size="large">
            {datas && renderTypeInvoice(datas)}
            {renderStatus()}
          </Space>
        }
        extra={extraButton}
      />
      <ContentCustom>
        <div
          style={{
            backgroundImage: `url(${MarbleBackground})`,
            backgroundSize: 'cover',
            minHeight: 'calc(100vh - 200px)'
          }}
        >
          <Row
            justify="space-between"
            align="stretch"
            style={{
              padding: '24px',
              flexWrap: 'nowrap',
              height: 'fit-content'
            }}
            gutter={24}
          >
            <Infos datas={datas?.infos} invoice={datas || []} />
            <Totals
              datas={datas?.totals}
              payments={datas?.payments}
              allDatas={datas}
            />
          </Row>
          <Sections
            datas={datas}
            forceRefresh={forceRefresh}
            matters={matters}
          />
          <Row
            justify="space-between"
            align="stretch"
            style={{ padding: '0 24px 24px 24px', height: 'fit-content' }}
            gutter={24}
          >
            <Products
              datas={datas}
              products={products}
              forceRefresh={forceRefresh}
            />
            <Accessories datas={datas} forceRefresh={forceRefresh} />
            <Placed datas={datas?.placed} />
          </Row>
        </div>
      </ContentCustom>
    </>
  );
};

export default Details;
