import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';
import DartyPriceList from '../dartyPriceList';

const ShowLabor = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [resource, setResource] = useState({});

  const deleteResource = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/labors/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/labors/${id}`
      });
      setResource(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getResource();
    })();
  }, [getResource]);

  return (
    <>
      <PageHeaderCustom
        title={resource.label || ''}
        withSubRoutes
        extra={
          canEditResource(path) && (
            <>
              <Link
                to={{
                  pathname: `${routes.CATALOGUES}/labors/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteResource}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(resource)} translate />
          <DartyPriceList pricing={resource.darty_price} />
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowLabor;
