import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListMatters from './ListMatters';
import CreateUpdateMatter from './CreateUpdateMatter';
import ShowMatter from './ShowMatter';

const MatterRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateMatter purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateMatter purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowMatter} />
      <Route path={path} component={ListMatters} />
    </Switch>
  );
};

export default MatterRouter;
