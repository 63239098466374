import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import SettlementConditionTable from './settlementConditionTable';

const SettlementConditionSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [settlementCondition, setSettlementCondition] = useState([]);

  const fetchSettlementCondition = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-settlement-conditions`
      });
      setSettlementCondition(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addSettlementCondition = async () => {
    const body = {
      label: 'Nouveau',
      text: '-'
    };
    try {
      await dispatchAPI('POST', {
        url: '/settings-settlement-conditions',
        body
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchSettlementCondition();
  };

  const deleteSettlementCondition = async (deletedSettlementCondition) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-settlement-conditions/${deletedSettlementCondition._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchSettlementCondition();
  };

  const editSettlementCondition = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-settlement-conditions/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchSettlementCondition();
  };

  useEffect(() => {
    fetchSettlementCondition();
  }, []);

  return (
    <>
      {settlementCondition.length > 0 && (
        <SettlementConditionTable
          settlementCondition={settlementCondition}
          editSettlementCondition={editSettlementCondition}
          deleteSettlementCondition={deleteSettlementCondition}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addSettlementCondition}
      >
        {t('settings.Settlement-condition.addSettlementCondition')}
      </Button>
    </>
  );
};

export default SettlementConditionSettings;
