import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Timeline, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const CustomTimeline = ({ tasks, mode, item, size }) => {
  const { t } = useTranslation();
  const [maxItemTimeline, setMaxItemTimeline] = useState(size);

  const setTimelineDisplay = () => {
    if (maxItemTimeline == 0) setMaxItemTimeline(size);
    else setMaxItemTimeline(0);
  };

  const getTimeLineDisplay = () =>
    !maxItemTimeline ? undefined : maxItemTimeline;

  return (
    <>
      <Timeline mode={mode}>
        {tasks.slice(0, getTimeLineDisplay()).map((task) => item(task, t))}
      </Timeline>
      {tasks.length > size && (
        <Row justify="center" gutter={[16, 16]}>
          <Button type="default" onClick={() => setTimelineDisplay()}>
            <span style={{ fontSize: '15px' }}>
              {t(`matters.timeline.${!maxItemTimeline ? 'less' : 'more'}`)}
            </span>
          </Button>
        </Row>
      )}
    </>
  );
};

CustomTimeline.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.string,
  item: PropTypes.func.isRequired,
  size: PropTypes.number
};

CustomTimeline.defaultProps = {
  tasks: [],
  mode: 'left',
  size: 5
};

export default CustomTimeline;
