import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [sites, setSites] = useState([]);
  const [customers, setCustomers] = useState();
  const [profesionals, setProfesionals] = useState();

  const basicFields = [
    {
      name: ['label'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    // {
    //   name: ['customer'],
    //   rules: [{ required: true, message: t('errors.message.missing_field') }],
    //   input: (
    //     <Select
    //       options={(customers || []).map((item) => ({
    //         label: `${item.last_name} ${item.first_name}`,
    //         value: item._id
    //       }))}
    //     />
    //   )
    // },
    {
      name: ['profesional'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={(profesionals || []).map((item) => ({
            label: `${item.name}`,
            value: item._id
          }))}
        />
      )
    },
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={(sites || []).map((item) => ({
            label: `${item.label}`,
            value: item._id
          }))}
        />
      )
    }
  ];

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProfesionals = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/profesionals' });
      setProfesionals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getProfesionals();
      await getSites();
      await getCustomers();
    })();
  }, []);

  return { basicFields };
};

export default useFields;
