import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('customers.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('customers.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },

    {
      title: t('customers.form.phone'),
      dataIndex: 'phone_number_2'
    },
    {
      title: t('customers.form.email'),
      dataIndex: 'email'
    },
    {
      title: t('customers.form.buildingSite'),
      dataIndex: 'building_site',
      render: (building_site) => {
        if (building_site) {
          let label = '';
          building_site.map((item, index) => {
            if (building_site.length - 1 === index) label += `${item.label} `;
            else label += `${item.label}, `;
          });
          return label;
        }
        return '';
      }
    },
    {
      title: t('customers.form.customer_code'),
      dataIndex: 'customer_code',
      render: (customer_code) => customer_code || '-'
    }
  ];
};

export default useColumns;
