import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListSupplies from './ListSupplies';
import CreateUpdateSupply from './CreateUpdateSupply';
import ShowSupply from './ShowSupply';

const SuppliesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateSupply purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateSupply purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowSupply />} />
      <Route path={path} component={ListSupplies} />
    </Switch>
  );
};

export default SuppliesRouter;
