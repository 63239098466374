import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { WorksheetsActions } from '../Buttons/WorksheetsActions';

export const WorksheetsColumns = (order, onDelete) => {
  const { t } = useTranslation();

  return [
    {
      title: t('worksheets.columns.number'),
      dataIndex: 'validated',
      render: (el, _, index) => (
        <>
          {`${order.number}-FT${index + 1}`}
          {el && <Tag color="green">{t('worksheets.status.validated')}</Tag>}
        </>
      )
    },
    {
      title: t('worksheets.columns.created_at'),
      dataIndex: 'created_at',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: t('worksheets.columns.date'),
      dataIndex: 'date',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: t('worksheets.columns.removal_date'),
      dataIndex: 'removal_orders',
      render: (removal_orders) =>
        (removal_orders || [])
          .map((el) => moment(el.date).format('DD-MM-YYYY'))
          .join(', ')
    },
    {
      title: t('worksheets.columns.delivery_date'),
      dataIndex: 'delivery_notes',
      render: (delivery_notes) =>
        (delivery_notes || [])
          .map((el) => moment(el.date).format('DD-MM-YYYY'))
          .join(', ')
    },
    {
      title: t('worksheets.columns.actions'),
      render: (worksheet, _, index) => (
        <WorksheetsActions
          worksheet={worksheet}
          order={order}
          index={index}
          onDelete={onDelete}
        />
      )
    }
  ];
};
