export const routes = {
  HOME: '/',
  USERS: '/users',
  CATALOGUES: '/catalogues',
  COMMERCIAL: '/commercial',
  CUSTOMERS_ACCOUNTING: '/customers_accounting',
  STOCK: '/stock',
  BUYING: '/buying',
  PRODUCTION: '/production',
  CUSTOM_ACCOUNTING: '/custom_accounting',
  SITES: '/sites',
  DOCUMENTS: '/documents',
  SETTINGS: '/settings'
};

export const subRoutes = {
  STOCK: {
    STOCKS: '/stocks',
    MOVEMENTS: '/movements',
    SUPPLIES: '/supplies'
  },
  USERS: {
    EXTRANET_USERS: '/extra_users',
    CRM_USERS: '/users'
  },
  CUSTOM_ACCOUNTING: {
    INVOICES: '/invoices',
    ORDERS_SUPPLIES: '/orders_supplies',
    ORDERS_PURCHASES: '/orders_purchases'
  },
  COMMERCIAL: {
    DEALS: '/deals',
    PROFESIONALS: '/profesionals',
    COMMISSION: '/commission',
    CUSTOMERS: '/customers',
    BUILDING_SITES: '/building_sites'
  },
  CATALOGUES: {
    MATTERS: '/matters',
    LABORS: '/labors',
    SHAPINGS: '/shapings',
    PRODUCTS: '/products'
  },
  CUSTOMERS_ACCOUNTING: {
    EXTRAQUOTATIONS: '/extraquotations',
    QUOTES: '/quotes',
    ORDERS_SALES: '/orders_sales'
  },
  BUYING: {
    SUPPLIERS: '/suppliers',
    ORDERS_PURCHASES: '/orders_purchases',
    ORDERS_SUPPLIES: '/orders_supplies'
  },
  HUMANRESOURCE: {
    EMPLOYEES: '/employees',
    ABSENCES: '/absences',
    'TIMES-AND-ATTENDANCES': '/times-and-attendances'
  },
  PRODUCTION: {
    PLANNING: '/planning',
    FOLLOWING_BUILDING_SITES: '/following_building_sites'
  },
  DOCUMENTS: {
    TEMPLATES: '/templates'
  }
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  PASSWORD_CREATION: '/post-pwd',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile'
};
