import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Select, Form, InputNumber } from 'antd';

const HeaderTable = ({ datas, setDatas, isEdit, options, form, required }) => {
  const { t } = useTranslation();

  const handleAllSelectChange = (value, current, setNewData) => {
    const { label, code } = value;
    const newData = current.map((item) => {
      return { ...item, salesType: label, code };
    });

    form.setFieldsValue({ all_codes: code });
    setNewData(newData);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col style={{ marginTop: 5 }}>{t('invoices.accounting-code.text')}</Col>
      <Col>
        <Form.Item
          name="salesTypes"
          label="Type de vente "
          rules={[
            {
              required,
              message: t('invoices.accounting-code.action.message-salesTypes')
            }
          ]}
        >
          <Select
            style={{ width: 200 }}
            onChange={(_, value) => {
              handleAllSelectChange(value, datas, setDatas);
            }}
            options={options}
            disabled={!isEdit}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="all_codes"
          label="Code Comptable "
          rules={[{ required }]}
        >
          <InputNumber disabled style={{ width: 200 }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default HeaderTable;

HeaderTable.propTypes = {
  datas: PropTypes.shape([]).isRequired,
  setDatas: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  options: PropTypes.shape([]).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired
};
