import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space, Row } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  FolderOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useLaysheetContext from '../LaysheetContext';
import { DownloadLaysheet } from './DownloadLaysheet';

export const LaysheetButtons = ({
  isValidDefinitevly,
  isValide,
  save,
  onValidate
}) => {
  const { t } = useTranslation();
  const {
    deleteLaySheet,
    worksheetInfo,
    orderInfo,
    isSuperAdmin,
    template,
    downloadFile,
    state
  } = useLaysheetContext();
  return (
    <Row justify="end">
      <Space>
        {isValide && (
          <>
            <Link
              to={{
                pathname: '/customers_accounting/workSheets',
                state: {
                  datas: orderInfo,
                  worksheet: worksheetInfo,
                  is_super_admin: isSuperAdmin
                }
              }}
              disabled={!worksheetInfo || !orderInfo}
            >
              <Button
                type="primary"
                icon={<FolderOutlined />}
                disabled={!worksheetInfo || !orderInfo}
              >
                {t('laysheet.form.buttons.worksheet')}
              </Button>
            </Link>
            <DownloadLaysheet
              element={state?.laysheet}
              template={template}
              downloadFile={downloadFile}
            />
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={onValidate}
              disabled={isValidDefinitevly}
            >
              {t('laysheet.form.buttons.validate')}
            </Button>
          </>
        )}
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => deleteLaySheet()}
          icon={<WarningOutlined />}
        >
          <Button
            type="primary"
            icon={<CloseOutlined />}
            disabled={isValidDefinitevly}
          >
            {t('laysheet.form.buttons.delete')}
          </Button>
        </Popconfirm>
        <Button type="primary" onClick={save} disabled={isValidDefinitevly}>
          {t('laysheet.form.buttons.save')}
        </Button>
      </Space>
    </Row>
  );
};

LaysheetButtons.propTypes = {
  isValide: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  isValidDefinitevly: PropTypes.bool.isRequired
};
