import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes, subRoutes } from '../utils/constants/routes';
import Login from './login';
import Home from './home/Home';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import Settings from './Settings/settings';
import Payment from './payment/Payment';
import UserRouter from './users/UserRouter';
import CommercialRouter from './Commercial/CommercialRouter';
import DocumentsRouter from './Documents/DocumentsRouter';
import AccountRouter from './Accounting/AccountRouter';
import CustomerAccountRouter from './Accounting/CustomerAccountRouter';
import SupplierAccountRouter from './Accounting/SupplierAccountRouter';
import CustomAccountRouter from './CustomAccounting/AccountRouter';
import CatalogueRouter from './Catalogue/CatalogueRouter';
import SitesRouter from './Sites/SitesRouter';
import DailyTableRouter from './DailyTable/router';
import Deals from './Trello/Deals';
import SAVRouter from './SAV/SAVRouter';
import RemovalOrderRouter from './RemovalOrder/router';
import AcceptanceReportRouter from './AcceptanceReport/router';
import PostPwdForm from './login/PostPwdForm';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  const splitPathGrant = location.pathname.split('/').filter((e) => e !== '');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  const grantFromPath =
    Symbol.iterator in Object(splitPathGrant)
      ? splitPathGrant.join('.').toLowerCase()
      : splitPathGrant.toLowerCase();

  const checkToRender = (props, resourceToCheck) => {
    if (!authContext.isValid) {
      return (
        <Redirect
          to={{
            pathname: outOfNavRoutes.LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if (!authContext.checkIsGranted(resourceToCheck)) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location }
          }}
        />
      );
    }

    return (
      <BasicLayout path={path}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </BasicLayout>
    );
  };

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) => checkToRender(props, grantFromPath)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          component={PostPwdForm}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />

        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path={routes.HOME} component={Home} />
        <PrivateRoute path={routes.USERS} component={UserRouter} />
        <PrivateRoute path={routes.COMMERCIAL} component={CommercialRouter} />
        <PrivateRoute path={`${routes.ACCOUNTING}`} component={AccountRouter} />
        <PrivateRoute
          path={`${routes.CUSTOMERS_ACCOUNTING}`}
          component={CustomerAccountRouter}
        />
        <PrivateRoute
          path={`${routes.BUYING}`}
          component={SupplierAccountRouter}
        />
        <PrivateRoute
          path={`${routes.STOCK}`}
          component={SupplierAccountRouter}
        />
        <PrivateRoute
          path={`${routes.CUSTOM_ACCOUNTING}`}
          component={CustomAccountRouter}
        />
        <PrivateRoute path={routes.CATALOGUES} component={CatalogueRouter} />
        <PrivateRoute
          path={`${routes.PRODUCTION}${subRoutes.PRODUCTION.FOLLOWING_BUILDING_SITES}`}
          component={Deals}
        />
        <PrivateRoute
          path={`${routes.PRODUCTION}${subRoutes.PRODUCTION.SAV}`}
          component={SAVRouter}
        />
        <PrivateRoute
          path={`${routes.PRODUCTION}`}
          component={DailyTableRouter}
        />
        <PrivateRoute path="/removal-order" component={RemovalOrderRouter} />
        <PrivateRoute
          path="/acceptance-report"
          component={AcceptanceReportRouter}
        />
        <PrivateRoute path={routes.SITES} component={SitesRouter} />
        {/* <PrivateRoute path={routes.TICKETS} component={TicketRouter} /> */}
        <PrivateRoute exact path="/payment-system/:id" component={Payment} />
        <PrivateRoute path="/payment-system/" component={Payment} />
        <PrivateRoute path={routes.DOCUMENTS} component={DocumentsRouter} />
        <PrivateRoute component={Exception} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
