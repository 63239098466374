import PropTypes from 'prop-types';
import React from 'react';
import { Select, Table, DatePicker, Checkbox, Form, Input } from 'antd';

const WorkSheetsFinition = ({
  operators,
  finitions,
  machines,
  sites,
  isEditable
}) => {
  const columns = [
    {
      title: 'Opérations Manuelles',
      dataIndex: 'label',
      width: '30%'
    },
    {
      title: 'Site',
      dataIndex: 'site',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['finitions', record.label, 'site']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={sites.map(({ label, _id }) => ({
              key: _id,
              value: label
            }))}
          />
        </Form.Item>
      )
    },
    {
      title: 'Machines',
      dataIndex: 'machine',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['finitions', record.label, 'machine']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={machines.map(({ label, _id }) => ({
              key: _id,
              value: label
            }))}
          />
        </Form.Item>
      )
    },
    {
      title: 'Opérateur',
      dataIndex: 'operator',
      width: '20%',
      render: (value, record) => (
        <Form.Item name={['finitions', record.label, 'operator']}>
          <Select
            disabled={!isEditable}
            allowClear
            style={{ width: '100%' }}
            options={operators.map(({ name, _id }) => ({
              key: _id,
              value: name
            }))}
          />
        </Form.Item>
      )
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      width: '10%',
      render: (value, record) => (
        <Form.Item
          name={['finitions', record.label, 'status']}
          valuePropName="checked"
          label="Fait :"
        >
          <Checkbox disabled={!isEditable} />
        </Form.Item>
      )
    }
  ];

  return <Table dataSource={finitions} columns={columns} pagination={false} />;
};

WorkSheetsFinition.propTypes = {
  finitions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ).isRequired,
  isEditable: PropTypes.bool.isRequired,
  machines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default WorkSheetsFinition;
