import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'antd';
import numeral from 'numeral';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Line = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 10px;
`;

const BlocPrices = ({ prices }) => {
  const { totalBrut, discount, amountTva, totalNet, ecoTaxe } = prices;
  const { t } = useTranslation();

  return (
    <>
      <Row style={{ marginTop: 50 }}>
        <Col span={6} offset={18}>
          <Line>
            {t('quotations.show.totalht')}
            <div style={{ float: 'right' }}>
              {`${numeral(totalBrut).format('0,0.00')}€`}
            </div>
          </Line>
        </Col>
      </Row>
      <Row>
        <Col span={6} offset={18}>
          <Line>
            {t('quotations.show.discount')}
            <div style={{ float: 'right' }}>
              {`${numeral(discount).format('0,0.00')}€`}
            </div>
          </Line>
        </Col>
      </Row>
      <Row>
        <Col span={6} offset={18}>
          <Line>
            {t('quotations.show.vat')}
            <div style={{ float: 'right' }}>
              {`${numeral(amountTva).format('0,0.00')}€`}
            </div>
          </Line>
        </Col>
      </Row>

      <Row>
        <Col span={6} offset={18}>
          <Line>
            {t('quotations.show.ecoTaxe')}
            <div style={{ float: 'right' }}>
              {`${numeral(ecoTaxe).format('0,0.00')}€`}
            </div>
          </Line>
        </Col>
      </Row>

      <Row>
        <Col span={6} offset={18}>
          <Line>
            {t('quotations.show.totalttc')}
            <div style={{ float: 'right' }}>
              {`${numeral(totalNet).format('0,0.00')}€`}
            </div>
          </Line>
        </Col>
      </Row>
    </>
  );
};

BlocPrices.propTypes = {
  prices: PropTypes.shape({
    amountTva: PropTypes.number,
    discount: PropTypes.number,
    ecoTaxe: PropTypes.number,
    totalBrut: PropTypes.number,
    totalNet: PropTypes.number
  }).isRequired
};

export default BlocPrices;
