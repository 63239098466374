import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, Typography, Row, Form, Input, Button, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import StockTagForMatter from '../Stocks/StockTagForMatter';

const MatterForm = ({ datas, form }) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [mattersArray, setMattersArray] = useState([]);
  const [inStockCount, setInStockCount] = useState(0);

  const incrementStockCount = () => {
    setInStockCount(inStockCount + 1);
  };

  useEffect(() => {
    const _mattersArray = [];
    if (datas && datas.datas) {
      Object.keys(datas.datas).forEach((key) => {
        if (key.startsWith('section_')) {
          datas.datas[key].elements.forEach((element) => {
            _mattersArray.push(element);
          });
        }
      });
      form.setFieldsValue({ matter: _mattersArray });
    }
    if (!_mattersArray.length && datas.worksheet) {
      if (datas.worksheet.matter) {
        _mattersArray.push({
          ...datas.worksheet.matter,
          thikness: datas.worksheet?.plan?.split(' ')[1]
        });
      }
      form.setFieldsValue({ matter: _mattersArray });
    }
    setMattersArray(_mattersArray);
  }, [datas]);

  return (
    <Col span={24} style={{ height: 'fit-content' }}>
      <Card style={{ background: 'rgba(255, 255, 255, 0.9)' }} bordered={false}>
        <div>
          <Title level={4}>Matières</Title>
          <div style={{ width: '130px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          {mattersArray.map((mat, _index) => {
            return (
              <Card
                style={{
                  height: '100%',
                  margin: '10px 10px 5px 41px'
                }}
                bordered={false}
              >
                <Row>
                  <Col span={4}>
                    <Row>
                      {t('worksheets.form.matter-number', {
                        number: _index + 1
                      })}
                    </Row>
                    <StockTagForMatter
                      matter={mat}
                      sizesNeeded={mat.sizes}
                      incrementStockCount={incrementStockCount}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t('worksheets.form.matter-number')}>
                      <Input defaultValue={mat.matter} disabled />
                    </Form.Item>
                    <Form.Item label={t('worksheets.form.matter-color')}>
                      <Input defaultValue={mat.shaping.color} disabled />
                    </Form.Item>
                    <Form.Item label={t('worksheets.form.matter-finition')}>
                      <Input defaultValue={mat.shaping.completions} disabled />
                    </Form.Item>
                    <Form.Item
                      name={['plans']}
                      label={t('worksheets.form.matter-thickness', {
                        type: mat.is_credence ? 'crédence' : 'plan'
                      })}
                    >
                      <Input defaultValue={mat.thikness} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Row>
            <Col
              style={{ height: 'fit-content', margin: '41px 25px 60px 56px' }}
            >
              {t('worksheets.form.matter-stock')}
            </Col>
            <Col
              style={{ height: 'fit-content', margin: '33px 46px 52px 0px' }}
            >
              <Link
                to={{
                  pathname: `/stock/stocks`
                }}
              >
                <Button type="primary" icon={<EyeOutlined />}>
                  {t('worksheets.button.matter-stock')}
                </Button>
              </Link>
            </Col>
            <Col
              style={{ height: 'fit-content', margin: '41px 10px 59px 0px' }}
            >
              <Tag color="orange">
                {t('worksheets.form.matter-disponibility', {
                  amount: inStockCount,
                  on: mattersArray.length
                })}
              </Tag>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};
MatterForm.propTypes = {
  datas: PropTypes.shape({
    datas: PropTypes.shape({
      infos: PropTypes.shape({
        customer: PropTypes.any
      })
    }),
    worksheet: PropTypes.shape({
      customer: PropTypes.any,
      matter: PropTypes.any,
      plan: PropTypes.shape({
        split: PropTypes.func
      })
    })
  }).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};
export default MatterForm;
