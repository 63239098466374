import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Row, Space, Tag } from 'antd';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import Infos from './Infos';
import Sections from './Sections';
import Products from './Products';
import { Placed } from './Placed';
import Totals from './Totals';
import TotalConditions from './TotalConditions';
import useButtons from './Buttons';
import useContext from '../context';
import MarbleBackground from '../../../assets/images/white-stone_Scott-Webb.jpg';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { enumsStatus, quoteStatus } from '../../../utils/constants/tagColors';
import Validation from './Validation';

const QuoteDetails = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const { getQuote1 } = useContext();
  const [quote, setQuote] = useState();
  const [refresh, setrefresh] = useState(false);
  const [validationVisisble, setValidationVisible] = useState(false);

  const forceRefresh = () => setrefresh(!refresh);

  const validateQuote = async (body) => {
    try {
      if (body?.order) {
        await dispatchAPI('PATCH', {
          url: `/ordersnew/new-quote/${body?.order}`,
          body
        });
      } else {
        await dispatchAPI('POST', {
          url: `/ordersnew`,
          body
        });
      }
      forceRefresh();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const changeStatus = async (status) => {
    try {
      // Create an order and set the quote status to VALIDATE
      await dispatchAPI('PATCH', {
        url: `/quotes/${id}`,
        body: { status }
      });
      forceRefresh();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const extraButtons = useButtons(quote, setValidationVisible, changeStatus);
  useEffect(() => {
    if (id) {
      (async () => {
        setQuote(await getQuote1(id));
      })();
    }
  }, [id, refresh]);

  if (quote) {
    const { products, accessories } = quote;
    return (
      <>
        <PageHeaderCustom
          title={
            <Space size="large">
              <div>
                {t(`quotes.show.number`, {
                  number: quote.number
                })}
              </div>
              <Tag color={quoteStatus[quote?.status]}>
                {enumsStatus[quote?.status]}
              </Tag>
            </Space>
          }
          extra={extraButtons}
        />
        <Validation
          isVisible={validationVisisble}
          setIsVisible={setValidationVisible}
          onCancel={() => setValidationVisible(false)}
          quote={quote}
          validateQuote={validateQuote}
        />
        <div
          style={{
            backgroundImage: `url(${MarbleBackground})`,
            backgroundSize: 'cover',
            minHeight: 'calc(100vh - 200px)'
          }}
        >
          <Row
            justify="space-between"
            align="stretch"
            style={{
              padding: '24px',
              flexWrap: 'nowrap',
              height: 'fit-content'
            }}
            gutter={24}
          >
            <Infos datas={quote.infos} />
            <Totals datas={quote.totals} />
          </Row>
          <Row
            justify="space-between"
            align="stretch"
            style={{
              padding: '24px',
              flexWrap: 'nowrap',
              height: 'fit-content'
            }}
          >
            <TotalConditions datas={quote?.totalConditions} />
          </Row>
          <Sections datas={quote} />

          <Row
            justify="space-between"
            align="stretch"
            style={{ padding: '0 24px 24px 24px', height: 'fit-content' }}
            gutter={24}
          >
            <Products datas={{ products, accessories }} />
            <Placed datas={quote.placed} />
          </Row>
        </div>
      </>
    );
  }

  return <Skeleton />;
};

export default QuoteDetails;
