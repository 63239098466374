import React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import FormAdd from './FormAdd';

const Container = styled.div`
  padding: 24px;
  background-color: white;
`;

const Ajouter = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/incomes',
      breadcrumbName: t('basiclayout.menu.accounting')
    },
    {
      path: '/incomes',
      breadcrumbName: t('incomes.breadcrumb.income')
    },
    {
      breadcrumbName: t('incomes.breadcrumb.new')
    }
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Ajouter un revenu"
        style={{ backgroundColor: 'white', marginBottom: 1 }}
        breadcrumb={{
          routes,
          // eslint-disable-next-line react/prop-types
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        onBack={history.goBack}
      />
      <Container>
        <FormAdd />
      </Container>
    </>
  );
};

export default Ajouter;
