/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Space,
  InputNumber,
  Row,
  message,
  Select
} from 'antd';
import { RingProgress } from '@ant-design/charts';
import { MinusCircleOutlined, PieChartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const ModalStat = ({
  setIsModalVisible,
  isModalVisible,
  saveStat,
  surface
}) => {
  const [form] = Form.useForm();
  const [lost, setLost] = useState();
  const [optionSelect, setOption] = useState();
  const [graph, setGraph] = useState(false);
  const { t } = useTranslation();
  const onOk = () => {
    const values = form.getFieldsValue(true);
    saveStat(values.lineStat);
    setIsModalVisible(false);
  };
  const handleChangeSelect = (e) => {
    setOption(e);
  };
  const surf = [];
  const selectOpt = [];
  if (surface) {
    surface.map((el) =>
      el.elements.map((item) =>
        surf.push({
          name: `${item.element.color} ${item.element.completions}`,
          surface: item.surface,
          id: item.element._id
        })
      )
    );
  }
  if (surface) {
    surface.map((el) =>
      el.elements
        .filter((e) => typeof e.surface === 'number')
        .map((item) =>
          selectOpt.push(`${item.element.color} ${item.element.completions}`)
        )
    );
  }
  const countList = surf
    .filter((el) => typeof el.surface === 'number')
    .reduce((p, c) => {
      const val = p;
      val[c.name] = (p[c.name] || 0) + c.surface;
      return p;
    }, {});

  const result = countList[optionSelect];
  const onCancel = () => {
    setGraph(false);
    setOption('');
    setIsModalVisible(false);
  };
  const onFinish = (values) => {
    const util = result;
    const surfaces = values.lineStat.map(
      ({ width, height }) => ((width / 1000) * height) / 1000
    );
    const use = surfaces.reduce((acc, value) => acc + value);
    const lostCalc = util / use;
    if (lostCalc > 1) {
      return message.error(t('quotations.stat.error'));
    }
    setLost(1 - lostCalc);
    setGraph(true);
  };

  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: lost,
    color: [
      lost < 0.3 ? '#389e0d' : lost > 0.6 ? '#f5222d' : '#fa541c',
      '#E8EDF3'
    ],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          lineHeight: '14px'
        },
        formatter: function formatter() {
          return 'Perte';
        }
      }
    }
  };
  const filteredOption = [...new Set(selectOpt)];
  const handleClick = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {selectOpt.length > 0 ? (
        <>
          <PieChartOutlined
            onClick={handleClick}
            style={{ fontSize: '3em', marginBottom: '0.8em' }}
          />
          <Modal
            footer={false}
            destroyOnClose
            title={t('quotations.stat.title')}
            visible={isModalVisible}
            onOk={onOk}
            onCancel={onCancel}
            okText="Enregistrer"
          >
            <Select style={{ width: '100%' }} onChange={handleChangeSelect}>
              {filteredOption.map((el) => (
                <Option value={el}>{el}</Option>
              ))}
            </Select>
            <Form
              name="stats"
              form={form}
              onFinish={onFinish}
              hidden={!optionSelect}
            >
              <Form.List name="lineStat">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                        size={20}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'width']}
                          fieldKey={[field.fieldKey, 'width']}
                          rules={[{ required: true, message: 'Missing width' }]}
                        >
                          <InputNumber
                            placeholder={t('quotations.stat.width')}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'height']}
                          fieldKey={[field.fieldKey, 'height']}
                          rules={[
                            { required: true, message: 'Missing height' }
                          ]}
                        >
                          <InputNumber
                            placeholder={t('quotations.stat.height')}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        {`${t('quotations.stat.piece')} ${fields.length + 1}`}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Row type="flex" justify="center">
                  <Button type="primary" htmlType="submit">
                    {t('quotations.stat.calcul')}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
            <Row type="flex" justify="center">
              {lost && graph && <RingProgress {...config} />}
            </Row>
          </Modal>
        </>
      ) : null}
    </>
  );
};

ModalStat.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  saveStat: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  surface: PropTypes.shape({
    map: PropTypes.func
  }).isRequired
};

export default ModalStat;
