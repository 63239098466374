import React from 'react';

const TrowelIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17.83 22"
        fill="currentColor"
        height="16px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Capa_1" data-name="Capa 1">
            <path d="M17.14.69a2.37,2.37,0,0,0-3.34,0l-3,3a2.34,2.34,0,0,0-.39,2.82L8.78,8.14a.64.64,0,0,0-.19.45v2.57c-.81.83-.37.39-1.65,1.67a.63.63,0,0,1-.46.19A.64.64,0,0,1,6,11.92l1.66-1.66L5.91,8.48a.64.64,0,0,0-1,.22L0,21.12A.65.65,0,0,0,.88,22L13.26,17.1A.65.65,0,0,0,13.48,16l-3.6-3.59V8.86l1.48-1.47A2.36,2.36,0,0,0,14.18,7l3-3a2.36,2.36,0,0,0,0-3.34Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const PlanIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.37 16"
        fill="currentColor"
        height="15px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Capa_1" data-name="Capa 1">
            <path d="M8,8.25v1.8h2.37V8.25l-1.19-1Z" />
            <path d="M.59,11.64a2.27,2.27,0,0,1,2.85-.4V.34A2.26,2.26,0,0,0,0,2.26v10Z" />
            <path d="M14.92,11.24a2.26,2.26,0,0,1,2.85.4l.6.59v-10A2.26,2.26,0,0,0,14.92.34Z" />
            <path d="M17,12.37a1.16,1.16,0,0,0-.87-.39,1.2,1.2,0,0,0-1.19,1.18.54.54,0,0,1-.53.54.53.53,0,0,1-.54-.53V2.3H4.52V13.15a.54.54,0,1,1-1.08,0A1.18,1.18,0,0,0,2.26,12a1.2,1.2,0,0,0-.88.39S1,12.81,1,12.8A1.87,1.87,0,0,0,2.26,16H16.18a1.87,1.87,0,0,0,1.23-3.2L17,12.37Zm-5.54-1.79a.54.54,0,0,1-.54.54H7.46a.54.54,0,0,1-.54-.54V8a.51.51,0,0,1,.2-.41L8.84,6.15a.56.56,0,0,1,.69,0l1.72,1.44a.53.53,0,0,1,.19.41v2.58Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const MeasureIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.94 15"
        fill="currentColor"
        height="15px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Capa_1" data-name="Capa 1">
            <circle cx="15.09" cy="6.81" r="2.79" />
            <path d="M15.09,0A6.82,6.82,0,0,0,8.27,6.86H7.54a.64.64,0,0,0,0,1.28h.73V11H6.17a.64.64,0,0,0-.64.64V12H.64a.64.64,0,0,0-.64.64v1.72a.65.65,0,0,0,1.29,0V13.29H5.53v1.07a.64.64,0,0,0,.64.64H21.3a.64.64,0,0,0,.64-.64V6.81A6.84,6.84,0,0,0,15.09,0Zm0,10.89a4.08,4.08,0,1,1,4.07-4.08A4.08,4.08,0,0,1,15.09,10.89Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const HammerIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        height="16px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Layer_1" data-name="Layer 1">
            <polygon points="12.89 4.5 13.92 3.46 12.54 2.08 11.5 3.11 8.39 0 4.24 4.15 11.85 11.76 16 7.61 12.89 4.5" />
            <path d="M6.31,7.61.43,13.49a1.47,1.47,0,1,0,2.08,2.08L8.39,9.69Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const TruckIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.68 15"
        fill="currentColor"
        height="15px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Capa_1" data-name="Capa 1">
            <path d="M19.54,11a2,2,0,1,0,2,2A2,2,0,0,0,19.54,11Zm0,2.61a.6.6,0,1,1,.59-.59A.59.59,0,0,1,19.54,13.58Z" />
            <path d="M19.35,2.61H15.87a.47.47,0,0,0-.47.47v9.24a.47.47,0,0,0,.47.47h.5a.47.47,0,0,0,.47-.4,2.62,2.62,0,0,1,2.7-2.31,2.62,2.62,0,0,1,2.7,2.31.47.47,0,0,0,.47.4h.5a.47.47,0,0,0,.47-.47V7.7a.45.45,0,0,0-.11-.31L19.71,2.77A.48.48,0,0,0,19.35,2.61Zm-3,3.78V4a.47.47,0,0,1,.48-.48H18.9a.52.52,0,0,1,.37.17l2,2.37a.47.47,0,0,1-.36.78H16.82A.47.47,0,0,1,16.34,6.39Z" />
            <path d="M.47,12.79H1.69a.47.47,0,0,0,.47-.4,2.61,2.61,0,0,1,2.7-2.31,2.6,2.6,0,0,1,2.69,2.31.47.47,0,0,0,.47.4h6a.47.47,0,0,0,.48-.47V.47A.47.47,0,0,0,14,0H.47A.47.47,0,0,0,0,.47V12.32A.47.47,0,0,0,.47,12.79Z" />
            <path d="M4.86,11a2,2,0,1,0,2,2A2,2,0,0,0,4.86,11Zm0,2.61A.6.6,0,1,1,5.45,13,.6.6,0,0,1,4.86,13.58Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const HelmetIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.56 15"
        fill="currentColor"
        height="15px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_1-2" data-name="Calque 1">
            <path d="M17.84,11.46H11.55V1.77A1.77,1.77,0,0,0,8,1.77v9.69H1.72A1.72,1.72,0,0,0,0,13.18v.1A1.72,1.72,0,0,0,1.72,15H17.84a1.72,1.72,0,0,0,1.72-1.72v-.1A1.72,1.72,0,0,0,17.84,11.46Z" />
            <path d="M6.86,2.05A8.28,8.28,0,0,0,4.73,3.3V7.79a.57.57,0,0,1-.57.58.58.58,0,0,1-.57-.58V4.45a8,8,0,0,0-1.8,5v.82H6.86Z" />
            <path d="M17.76,9.49a7.92,7.92,0,0,0-1.82-5.07V7.83a.57.57,0,1,1-1.14,0V3.28a7.94,7.94,0,0,0-2.11-1.23v8.26h5.07Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const BoxIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.26 17"
        fill="currentColor"
        height="16px"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Layer_1" data-name="Layer 1">
            <path d="M4,4.52l7.48-3.21L8.33,0a.58.58,0,0,0-.41,0L.48,3.1Z" />
            <path d="M12.82,1.89,5.34,5.09,8.13,6.24,15.77,3.1Z" />
            <path d="M7.59,7.18l-3-1.24V8.29a.54.54,0,1,1-1.07,0V5.49L0,4.06v9.46a.54.54,0,0,0,.33.5l7.26,3Z" />
            <path d="M8.67,7.18V17l7.25-3a.54.54,0,0,0,.34-.5V4.06Z" />
          </g>
        </g>
      </svg>
    </span>
  );
};

const ClockIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="15px"
        height="15px"
      >
        <path d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M31.5,27h-8c-0.8,0-1.5-0.7-1.5-1.5v-12c0-0.8,0.7-1.5,1.5-1.5 s1.5,0.7,1.5,1.5V24h6.5c0.8,0,1.5,0.7,1.5,1.5S32.3,27,31.5,27z" />
      </svg>
    </span>
  );
};

const CloseIcon = () => {
  return (
    <span
      role="img"
      className="anticon anticon-close"
      ariaLabel="close"
      tabIndex="-1"
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.47912 7.49996L14.2361 1.74301C14.3498 1.61017 14.4093 1.43928 14.4025 1.26451C14.3958 1.08973 14.3233 0.923939 14.1997 0.800263C14.076 0.676586 13.9102 0.604133 13.7354 0.597382C13.5606 0.590631 13.3897 0.65008 13.2569 0.763848L7.49995 6.52079L1.74301 0.756904C1.61224 0.626137 1.43489 0.552673 1.24995 0.552673C1.06502 0.552673 0.887665 0.626137 0.756899 0.756904C0.626132 0.88767 0.552668 1.06503 0.552668 1.24996C0.552668 1.43489 0.626132 1.61225 0.756899 1.74301L6.52079 7.49996L0.756899 13.2569C0.684203 13.3192 0.625161 13.3958 0.583478 13.4819C0.541795 13.5681 0.518372 13.6619 0.514678 13.7576C0.510984 13.8532 0.527098 13.9486 0.562011 14.0377C0.596924 14.1268 0.649881 14.2077 0.717558 14.2754C0.785235 14.3431 0.866171 14.396 0.955285 14.431C1.0444 14.4659 1.13977 14.482 1.23541 14.4783C1.33105 14.4746 1.42489 14.4512 1.51104 14.4095C1.5972 14.3678 1.67381 14.3088 1.73607 14.2361L7.49995 8.47912L13.2569 14.2361C13.3897 14.3498 13.5606 14.4093 13.7354 14.4025C13.9102 14.3958 14.076 14.3233 14.1997 14.1997C14.3233 14.076 14.3958 13.9102 14.4025 13.7354C14.4093 13.5606 14.3498 13.3898 14.2361 13.2569L8.47912 7.49996Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export {
  TrowelIcon,
  PlanIcon,
  MeasureIcon,
  HammerIcon,
  TruckIcon,
  HelmetIcon,
  BoxIcon,
  ClockIcon,
  CloseIcon
};
