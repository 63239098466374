import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Popconfirm, Radio } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const useColumnsMaterial = (
  isValidDefinitevly,
  form,
  materialLaySheet,
  setMaterialLaySheet
) => {
  const { t } = useTranslation();

  useEffect(() => {}, [materialLaySheet]);
  const deleteMaterial = (record, index) => {
    const data = materialLaySheet.filter((i) => i._id !== record._id);

    const formValues = form.getFieldsValue();

    formValues.utils = formValues.utils.filter(
      (value, filterIndex) => filterIndex !== index
    );

    form.setFieldsValue({ ...formValues });

    setMaterialLaySheet([...data]);
  };

  const columnsMat = [
    {
      title: t('laysheet.columns.materials.label'),
      dataIndex: 'label',
      key: 'utils'
    },
    {
      dataIndex: '_id',
      key: '_id',
      render: (_, record, index) => {
        return (
          <Form.Item
            hidden
            name={['utils', index, '_id']}
            initialValue={record._id}
            rules={[{ required: true }]}
          />
        );
      }
    },
    {
      dataIndex: 'label',
      key: 'label',
      render: (_, record, index) => {
        return (
          <Form.Item
            hidden
            name={['utils', index, 'label']}
            initialValue={record.label}
            rules={[{ required: true }]}
          />
        );
      }
    },
    {
      title: t('laysheet.columns.materials.expect'),
      dataIndex: 'expect',
      key: 'expect',
      render: (value, record, index) => (
        <Form.Item name={['utils', index, 'expect']}>
          <Radio.Group disabled={isValidDefinitevly}>
            <Radio value={t('laysheet.columns.materials.yes')}>
              {t('laysheet.columns.materials.yes')}
            </Radio>
            <Radio value={t('laysheet.columns.materials.no')}>
              {t('laysheet.columns.materials.no')}
            </Radio>
          </Radio.Group>
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.materials.operation'),
      dataIndex: 'operation',
      render: (_, record, index) => {
        return (
          <>
            <Popconfirm
              title={t('laysheet.columns.materials.delete')}
              onConfirm={() => {
                deleteMaterial(record, index);
              }}
            >
              <DeleteOutlined
                disabled={isValidDefinitevly}
                style={{ color: 'red', fontSize: 18 }}
              />
            </Popconfirm>
          </>
        );
      }
    }
  ];
  return columnsMat;
};

export default useColumnsMaterial;
