import React from 'react';
import { Form, InputNumber, Divider, Button, Typography, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CloseIcon } from '../../../utils/constants/customIcon';
import useConfiguratorContext from './ConfiguratorContext';

const AccessoryForm = () => {
  const {
    datas,
    filters,
    setFilters,
    currentStep,
    t,
    initFilters,
    setInitFilters
  } = useConfiguratorContext();
  const { products } = datas;

  return (
    <div
      className="quotation-form-wrapper"
      style={{ display: currentStep === 2 ? '' : 'none' }}
    >
      <Typography.Title
        level={4}
        style={{ display: currentStep === 2 ? '' : 'none' }}
      >
        Autres
      </Typography.Title>
      <Form.List
        name={['accessories']}
        style={{ display: currentStep === 2 ? '' : 'none' }}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, fieldKey }) => (
              <div
                style={{
                  position: 'relative',
                  display: currentStep === 2 ? '' : 'none'
                }}
                className="quotation-form-fields-wrapper"
              >
                <Form.Item
                  label="Accessoire"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 8 }}
                  name={[name, 'name']}
                  fieldKey={[fieldKey, 'name']}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Accessoire" />
                </Form.Item>
                <Form.Item
                  label="Prix"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 8 }}
                  name={[name, 'price']}
                  fieldKey={[fieldKey, 'price']}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} step={0.01} />
                </Form.Item>

                <Form.Item
                  label="Quantité"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 8 }}
                  name={[name, 'quantity']}
                  fieldKey={[fieldKey, 'quantity']}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={1} />
                </Form.Item>

                <Button type="link" onClick={() => remove(name)}>
                  <CloseIcon />
                </Button>
                <Divider />
              </div>
            ))}
            <Form.Item
              wrapperCol={{ span: 8, offset: 8 }}
              style={{ display: currentStep === 2 ? '' : 'none' }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                className="quotation-add-btn"
              >
                Nouveau Accessoire
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default AccessoryForm;
