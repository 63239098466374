import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Switch,
  Row,
  Col,
  Form,
  InputNumber,
  Typography,
  Space,
  Select,
  Tooltip
} from 'antd';
import useConfiguratorContext from './ConfiguratorContext';

const TotalsForm = () => {
  const { currentStep, t, datas } = useConfiguratorContext();
  const { tvas } = datas;

  const renderTtcLabel = (
    <>
      {t('configurator.totals_ttc')}
      <Tooltip title={t('configurator.tva_products')}>
        <InfoCircleOutlined style={{ marginLeft: 10 }} />
      </Tooltip>
    </>
  );

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ display: currentStep === 4 ? '' : 'none' }}
      >
        {t('configurator.totals')}
      </Typography.Title>
      <div className="quotation-form-fields-wrapper">
        <Row>
          <Col span={10} offset={2}>
            <Form.Item
              label={t('configurator.total_matters')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <Space>
                <Form.Item name={['totals', 'totalMatters']}>
                  <InputNumber precision={2} />
                </Form.Item>
                <Form.Item name={['totals', 'discountMatters']}>
                  <InputNumber
                    placeholder="Remise %"
                    formatter={(value) => (value ? `${value} %` : '')}
                    style={{ minWidth: '100px' }}
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item
              label={t('configurator.total_shapings')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalShapings']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.total_products')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalProducts']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.total_lay')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalLay']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.total_labor')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalLabor']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.total_lay_labor')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalLayLabor']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label="Total Livraison (€/HT)"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'deliveryCost']}
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Total Accessoires (€/HT)"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalsAccessories']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.without_ecotax')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalWithoutEcoTax']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.totals_ecotax')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totals', 'totalEcoTax']}
            >
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              label={t('configurator.com_pro')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <Space>
                <Form.Item name={['totals', 'comPro']}>
                  <InputNumber precision={2} />
                </Form.Item>
                <Form.Item
                  name={['totals', 'comWithLay']}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={t('configurator.com_lay')}
                    unCheckedChildren={t('configurator.com_lay')}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item
              label={t('configurator.total_pro')}
              name={['totals', 'totalPro']}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <InputNumber precision={2} />
            </Form.Item>

            <Form.Item
              label={t('configurator.total_customer')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <Space>
                <Form.Item
                  name={['totals', 'totalClient']}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 8 }}
                >
                  <InputNumber precision={2} />
                </Form.Item>

                <Form.Item name={['totals', 'discountCustomer']}>
                  <InputNumber
                    placeholder="Remise %"
                    formatter={(value) => (value ? `${value} %` : '')}
                    style={{ minWidth: '100px' }}
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item
              label={renderTtcLabel}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <Space>
                <Form.Item name={['totals', 'totalsTTC']}>
                  <InputNumber precision={2} />
                </Form.Item>
                <Form.Item
                  name={['totals', 'tva']}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 8 }}
                >
                  <Select
                    options={tvas?.map(({ label, _id }) => ({
                      label,
                      value: _id
                    }))}
                    placeholder={t('configurator.tva')}
                    style={{ width: 'auto' }}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalsForm;
