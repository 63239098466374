const useListContent = ({
  name,
  site,
  siren_number,
  ape_number,
  address,
  email,
  phone_number,
  commission,
  reglement,
  customer_code,
  settlement_condition
}) => {
  return [
    {
      label: 'profesionals.show.name',
      content: name,
      span: 1
    },
    {
      label: 'profesionals.show.sites',
      content: site ? site?.label : '-',
      span: 2
    },
    {
      label: 'profesionals.show.channel_number',
      content: address ? address.number : '-',
      span: 1
    },
    {
      label: 'profesionals.show.street_number',
      content: address ? address.street : '-',
      span: 1
    },
    {
      label: 'profesionals.show.postal_code',
      content: address ? address.postal_code : '-',
      span: 1
    },
    {
      label: 'profesionals.show.city',
      content: address ? address.city : '-',
      span: 2
    },
    {
      label: 'profesionals.show.email',
      content: email,
      span: 1
    },
    {
      label: 'profesionals.show.phone_number',
      content: phone_number,
      span: 2
    },
    {
      label: 'profesionals.show.siren_number',
      content: siren_number,
      span: 1
    },
    {
      label: 'profesionals.show.ape_number',
      content: ape_number,
      span: 2
    },
    {
      label: 'profesionals.show.reglement',
      content: `${reglement || '-'}`,
      span: 1
    },
    {
      label: 'profesionals.show.commission',
      content: commission,
      span: 2
    },
    {
      label: 'profesionals.show.customer_code',
      content: `${customer_code !== '' ? customer_code : '-'}`,
      span: 2
    },
    {
      label: 'profesionals.show.settlement_condition',
      content: settlement_condition?.label || '-'
    }
  ];
};

export default useListContent;
