const getNbrBatchs = (sizes, withScrap = true) => {
  if (withScrap)
    return sizes?.filter((obj) => !obj.exit_date).map((size) => size.number);
  return [
    ...new Set(
      sizes?.filter((obj) => !obj.exit_date).map((size) => size.number)
    )
  ];
};

const useListContent = (data = {}) => {
  const supplier = data?.supplier ?? {};
  const ref_supplier = data?.ref_supplier ?? '';
  const batch_number = data?.batch_number ?? '';
  const ref_internal = data?.ref_internal ?? '';
  const value = data?.value ?? 0;
  const buying_price = data?.buying_price ?? 0;
  const sizes = data?.sizes ?? [];
  const devaluation = data?.devaluation ?? 0;
  const customer = data?.customer ?? {};

  return [
    {
      key: 'supplier',
      label: 'stocks.show.supplier',
      content: supplier && supplier?.name,
      span: 1
    },
    {
      key: 'ref_supplier',
      label: 'stocks.show.ref_supplier',
      content: ref_supplier,
      span: 1
    },
    {
      key: 'batch_number',
      label: 'stocks.show.batch_number',
      content: batch_number,
      span: 1
    },
    {
      key: 'pieces_numbers',
      label: 'stocks.show.pieces_numbers',
      content:
        sizes &&
        getNbrBatchs(sizes, false)
          .sort((a, b) => a - b)
          .join(','),
      span: 1
    },
    {
      key: 'pieces_count',
      label: 'stocks.show.pieces_count',
      content: sizes && getNbrBatchs(sizes).length,
      span: 1
    },
    {
      key: 'buying_price',
      label: 'stocks.show.buying_price',
      content: buying_price,
      span: 1
    },
    {
      key: 'devaluation',
      label: 'stocks.show.devaluation',
      content: devaluation,
      span: 1
    },
    {
      key: 'value',
      label: 'stocks.show.value',
      content: value,
      span: 1
    },
    {
      key: 'ref_internal',
      label: 'stocks.show.ref_internal',
      content: ref_internal,
      span: 1
    },
    {
      key: 'affected_to',
      label: 'stocks.show.affected_to',
      content: customer && `${customer.first_name || ''} ${customer.last_name}`,
      span: 1
    }
  ];
};

export default useListContent;
