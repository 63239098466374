import { Button, Col, DatePicker, Row, Select } from 'antd';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ChartOrder from './ChartOrder';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import PieOrderTypeProject from './PieOrderTypeProject';
import PieOrderTypeCustomer from './PieOrderTypeCustomer';
import PieOrderByMatter from './PieOrderByMatter';
import PieOrderByPro from './PieOrderByPro';

const OrdersShortcut = ({ sites }) => {
  const [filterOrder, setFiltersOrder] = useState({
    commercial_site: null,
    production_site: null,
    projectType: null,
    matterType: null,
    date_lowest: null,
    date_highest: null
  });
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [projectType, setProjectType] = useState([]);
  const [matterType, setMatterType] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [waiting, setWaiting] = useState({});
  const [validate, setValidate] = useState({});
  const [invoiced, setInvoiced] = useState({});
  const [totals, setTotals] = useState(0);
  const [average, setAverage] = useState(0);

  const getOrders = async (parameter) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew?${parameter}`
      });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    if (orders.length) {
      setWaiting(orders.filter((el) => el.status === 'WAITING'));
    } else setWaiting({});
  }, [orders]);
  const checkMatter = (el, statut) => {
    return (
      el.status === statut &&
      (el?.section_1?.elements
        .map((matter) => matter.matter === filterOrder.matterType)
        .includes(true) ||
        el?.section_2?.elements
          .map((matter) => matter.matter === filterOrder.matterType)
          .includes(true) ||
        el?.section_3?.elements
          .map((matter) => matter.matter === filterOrder.matterType)
          .includes(true) ||
        el?.section_4?.elements
          .map((matter) => matter.matter === filterOrder.matterType)
          .includes(true) ||
        el?.section_5?.elements
          .map((matter) => matter.matter === filterOrder.matterType)
          .includes(true))
    );
  };
  const checkTypeProject = (el, statut) => {
    return (
      el.status === statut &&
      (el?.section_1?.project_type === filterOrder.projectType ||
        el?.section_2?.project_type === filterOrder.projectType ||
        el?.section_3?.project_type === filterOrder.projectType ||
        el?.section_4?.project_type === filterOrder.projectType ||
        el?.section_5?.project_type === filterOrder.projectType)
    );
  };
  const checkCommercialSite = (el, statut) => {
    return (
      el.status === statut &&
      el.infos.commercial_site._id === filterOrder.commercial_site
    );
  };
  const checkProductionSite = (el, statut) => {
    return (
      el.status === statut &&
      el.infos.production_site._id === filterOrder.production_site
    );
  };
  useEffect(() => {
    if (
      !(
        filterOrder?.commercial_site ||
        filterOrder?.production_site ||
        filterOrder?.projectType ||
        filterOrder?.matterType ||
        filterOrder?.date_lowest ||
        filterOrder?.date_highest
      )
    ) {
      setOrders(allOrders);
    } else {
      let newOrderWaiting = orders;
      let newOrderValidate = orders;
      let newOrderInvoiced = orders;
      if (filterOrder.commercial_site) {
        newOrderWaiting = newOrderWaiting.filter((el) =>
          checkCommercialSite(el, 'WAITING')
        );
        newOrderValidate = newOrderValidate.filter((el) =>
          checkCommercialSite(el, 'VALIDATE')
        );
        newOrderInvoiced = newOrderInvoiced.filter((el) =>
          checkCommercialSite(el, 'INVOICED')
        );
      }

      if (filterOrder.production_site) {
        newOrderWaiting = newOrderWaiting.filter((el) =>
          checkProductionSite(el, 'WAITING')
        );
        newOrderValidate = newOrderValidate.filter((el) =>
          checkProductionSite(el, 'VALIDATE')
        );
        newOrderInvoiced = newOrderInvoiced.filter((el) =>
          checkProductionSite(el, 'INVOICED')
        );
      }
      if (filterOrder.projectType) {
        newOrderWaiting = newOrderWaiting.filter((el) =>
          checkTypeProject(el, 'WAITING')
        );
        newOrderValidate = newOrderValidate.filter((el) =>
          checkTypeProject(el, 'VALIDATE')
        );
        newOrderInvoiced = newOrderInvoiced.filter((el) =>
          checkTypeProject(el, 'INVOICED')
        );
      }

      if (filterOrder.matterType) {
        newOrderWaiting = newOrderWaiting.filter((el) =>
          checkMatter(el, 'WAITING')
        );
        newOrderValidate = newOrderValidate.filter((el) =>
          checkMatter(el, 'VALIDATE')
        );
        newOrderInvoiced = newOrderInvoiced.filter((el) =>
          checkMatter(el, 'INVOICED')
        );
      }

      if (filterOrder.date_lowest || filterOrder.date_highest) {
        let filterParameter = '';
        Object.keys(filterOrder).forEach((key) => {
          if (filterOrder[key]) {
            filterParameter += `${key}=${filterOrder[key]}&`;
          }
        });
        getOrders(filterParameter);
      }
      setWaiting(newOrderWaiting);
      setValidate(newOrderValidate);
      setInvoiced(newOrderInvoiced);
      setOrders([...newOrderWaiting, ...newOrderValidate, ...newOrderInvoiced]);
    }
  }, [filterOrder]);

  const getAllOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/ordersnew` });
      setOrders(data);
      setAllOrders(data);
      const projectTypes = [];
      const matterTypes = [];
      data.forEach((quote) => {
        if (quote.section_1 && quote.section_1.project_type) {
          projectTypes.push(quote.section_1.project_type);
        }
        if (quote.section_2 && quote.section_2.project_type) {
          projectTypes.push(quote.section_2.project_type);
        }
        if (quote.section_3 && quote.section_3.project_type) {
          projectTypes.push(quote.section_3.project_type);
        }
        if (quote.section_4 && quote.section_4.project_type) {
          projectTypes.push(quote.section_4.project_type);
        }
        if (quote.section_5 && quote.section_5.project_type) {
          projectTypes.push(quote.section_5.project_type);
        }
        if (quote.section_1 && quote.section_1.elements) {
          quote.section_1.elements.map((el) => matterTypes.push(el.matter));
        }
        if (quote.section_2 && quote.section_2.elements) {
          quote.section_2.elements.map((el) => matterTypes.push(el.matter));
        }
        if (quote.section_3 && quote.section_3.elements) {
          quote.section_3.elements.map((el) => matterTypes.push(el.matter));
        }
        if (quote.section_4 && quote.section_4.elements) {
          quote.section_4.elements.map((el) => matterTypes.push(el.matter));
        }
        if (quote.section_5 && quote.section_5.elements) {
          quote.section_5.elements.map((el) => matterTypes.push(el.matter));
        }
      });
      setMatterType(Array.from(new Set(matterTypes)));
      setProjectType(Array.from(new Set(projectTypes)));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    getAllOrders();
  }, []);

  const getTotal = (ordersWaiting) => {
    let totalsOfquotesPending = 0;
    for (let index = 0; index < ordersWaiting.length; index += 1) {
      totalsOfquotesPending += ordersWaiting[index].totals.totalClient;
    }
    setTotals(totalsOfquotesPending);
  };

  const getAverageDifference = (ordersnew) => {
    let averageDifference = 0;
    let totalDifference = 0;
    let totalOrders = 0;
    ordersnew.forEach((order) => {
      if (order.invoice_date) {
        totalOrders += 1;
        totalDifference += moment(order.invoice_date).diff(
          moment(order.created_at),
          'days'
        );
      }
    });
    averageDifference = totalDifference / totalOrders;
    setAverage(averageDifference / 7);
  };

  useEffect(() => {
    getAverageDifference(orders);
    getTotal(waiting);
  }, [waiting, orders]);

  return (
    <div
      justify="space-between"
      style={{
        background: 'rgb(249, 251, 252)',
        padding: 14,
        backgroundColor: '#edeef0',
        boxShadow: '0.5rem 0.5rem 1rem -1rem rgb(58,57,57)'
      }}
    >
      <Row gutter={[0, 8]}>
        <Col
          xs={24}
          xxl={3}
          style={{
            fontSize: 16,
            textAlign: 'left',
            letterSpacing: 2
          }}
        >
          COMMANDES
        </Col>
        <Col xs={24} xxl={13}>
          Filtres :
          <Select
            allowClear
            style={{ marginLeft: 5, width: 150 }}
            onChange={(value) => {
              setFiltersOrder({ ...filterOrder, commercial_site: value });
            }}
            placeholder="Site de demande"
            options={
              sites && sites.map(({ _id, label }) => ({ label, value: _id }))
            }
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) =>
              setFiltersOrder({ ...filterOrder, production_site: value })
            }
            placeholder="Site de fabrication"
            options={
              sites && sites.map(({ _id, label }) => ({ label, value: _id }))
            }
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) => {
              setFiltersOrder({ ...filterOrder, projectType: value });
            }}
            placeholder="Type de projet"
            options={
              projectType && projectType.map((el) => ({ el, value: el }))
            }
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) => {
              setFiltersOrder({ ...filterOrder, matterType: value });
            }}
            placeholder="Type de matière"
            options={matterType && matterType.map((el) => ({ el, value: el }))}
          />
        </Col>
        <Col xs={24} xxl={8}>
          Du
          <DatePicker
            style={{
              marginLeft: 4,
              marginRight: 4,
              minWidth: 120,
              width: '40%'
            }}
            onChange={(value) => {
              setFiltersOrder({ ...filterOrder, date_lowest: value });
            }}
          />
          au
          <DatePicker
            style={{
              marginLeft: 4,
              marginRight: 4,
              minWidth: 120,
              width: '40%'
            }}
            onChange={(value) =>
              setFiltersOrder({ ...filterOrder, date_highest: value })
            }
          />
        </Col>
      </Row>
      <Row style={{ margin: '20px 0px 20px 0px' }}>
        <Col
          style={{
            height: '100%',
            padding: '15px 0 20px 0'
          }}
          xs={24}
          md={12}
          xl={7}
        >
          <div style={{ width: '100%', height: '100%', padding: '0 0 5px 0' }}>
            <Link to={{ pathname: '/customers_accounting/orders_sales' }}>
              <Button className="customShortcut">LISTE DES COMMANDES</Button>
            </Link>
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <Link to={{ pathname: '/custom_accounting/invoices' }}>
              <Button className="customShortcut">LISTE DES FACTURES</Button>
            </Link>
          </div>
        </Col>
        <Col
          style={{
            height: '100%',
            padding: '20px'
          }}
          xs={24}
          sm={12}
          xl={8}
        >
          <div style={{ textAlign: 'center' }}>
            MONTANT DES COMMANDES EN COURS :
          </div>
          <div
            style={{
              fontWeight: 900,
              fontSize: 25,
              letterSpacing: 2,
              textAlign: 'center'
            }}
          >
            {numeral(totals).format('0,0.00')} €
          </div>
        </Col>
        <Col
          style={{
            height: '100%',
            textAlign: 'center',
            padding: '20px'
          }}
          xs={24}
          sm={12}
          xl={8}
        >
          <div style={{ textAlign: 'center' }}>PERFORMANCE DE TRAITEMENT :</div>
          <div
            style={{
              fontWeight: 900,
              fontSize: 25,
              letterSpacing: 2
            }}
          >
            {numeral(average).format('0.00')} SEMAINES
          </div>
        </Col>
      </Row>
      <Row>
        <Row style={{ width: '100%' }}>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px' }}>
              STATUT DE LA COMMANDE:
            </div>
            <div style={{ padding: ' 15px 100px' }}>
              <ChartOrder md={6} sm={6} orders={orders} />
            </div>
          </Col>
          <Col xs={24} xl={12} style={{ padding: '20px 50px 20px 0px' }}>
            <div style={{ padding: '0 0 5px 80px', height: 10 }}>
              TYPE DE PROJET:
            </div>
            <div>
              <PieOrderTypeProject orders={orders} />
            </div>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col
            xs={24}
            xl={12}
            xxl={8}
            style={{ padding: '20px 50px 20px 0px' }}
          >
            <div style={{ padding: '0 0 5px 80px', height: 20 }}>
              TYPE DE CLIENT:
            </div>
            <div>
              <PieOrderTypeCustomer orders={orders} />
            </div>
          </Col>
          <Col
            xs={24}
            xl={12}
            xxl={8}
            style={{ padding: '20px 50px 20px 0px' }}
          >
            <div style={{ padding: '0 0 5px 80px', height: 20 }}>
              RÉPARTITION PAR TYPE DE PROFESSIONNEL:
            </div>
            <div>
              <PieOrderByPro orders={orders} />
            </div>
          </Col>
          <Col
            xs={24}
            xl={12}
            xxl={8}
            style={{ padding: '20px 50px 20px 0px' }}
          >
            <div style={{ padding: '0 0 5px 80px', height: 20 }}>
              RÉPARTITION PAR FAMILLE DE MATIÈRE:
            </div>
            <div>
              <PieOrderByMatter orders={orders} />
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

OrdersShortcut.propTypes = {
  sites: PropTypes.shape().isRequired
};
export default OrdersShortcut;
