import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';

const HeaderButton = ({ id, isDisplay, setIsEdit, isEdit }) => {
  const { t } = useTranslation();

  return (
    isDisplay && (
      <Space>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => {
            setIsEdit(true);
          }}
          disabled={isEdit}
        >
          {t('invoices.accounting-code.action.edit')}
        </Button>
        <Link
          to={{
            pathname: `/custom_accounting/invoices/show/${id}`
          }}
          style={{ float: 'right' }}
        >
          <Button type="primary" icon={<ExportOutlined />}>
            {t('invoices.accounting-code.action.show')}
          </Button>
        </Link>
      </Space>
    )
  );
};

export default HeaderButton;

HeaderButton.propTypes = {
  id: PropTypes.string.isRequired,
  isDisplay: PropTypes.bool.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired
};
