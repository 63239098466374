import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  const formatPrice = (value) => `${value.toFixed(2)} €`;

  const containsWord = (str, word) => {
    let result = '';
    result = str.toLowerCase().indexOf(word.toLowerCase()) !== -1;
    if (result) return t(`invoices.accounting-code.${str}`);
    return str;
  };

  const except = [
    'title-type.adv',
    'title-type.basic-invoice',
    'title-type.credit-note',
    'title-type.advance-payment'
  ];

  return [
    {
      title: t('invoices.accounting-code.column.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.length - b.type.length,
      width: '15%',
      render: (title) => containsWord(title, 'title-type')
    },
    {
      title: t('invoices.accounting-code.column.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity.length - b.quantity.length,
      width: '15%'
    },
    {
      title: t('invoices.accounting-code.column.label'),
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.label.length - b.label.length,
      render: (label) => containsWord(label, 'message-type')
    },
    {
      title: t('invoices.accounting-code.column.totalHT'),
      dataIndex: 'totalHT',
      key: 'totalHT',
      align: 'right',
      width: '10%',
      sorter: (a, b) => a.totalHT - b.totalHT,
      render: (_, { totalHT, type, is_basic_invoice, advance_payment }) => {
        switch (true) {
          case totalHT && !is_basic_invoice && !advance_payment:
            return formatPrice(Math.abs(totalHT));
          case totalHT && except.includes(type):
            return `${formatPrice(totalHT)}`;
          case totalHT && type === 'title-type.discount':
            return `-${formatPrice(totalHT)}`;
          case totalHT && !except.includes(type):
            return formatPrice(totalHT);

          default:
            return '-';
        }
      }
    },
    {
      title: t('invoices.accounting-code.column.mtTva'),
      dataIndex: 'mtTva',
      key: 'mtTva',
      align: 'right',
      width: '10%',
      sorter: (a, b) => a.mtTva - b.mtTva,
      render: (_, { mtTva, type }) => {
        switch (true) {
          case mtTva && except.includes(type):
            return `${formatPrice(mtTva)}`;
          case mtTva && type === 'title-type.discount':
            return '-';
          case mtTva && !except.includes(type):
            return formatPrice(mtTva);
          default:
            return '-';
        }
      }
    }
  ];
};

export default useColumns;
