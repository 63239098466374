import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useListContent = ({
  supplier,
  site,
  supplies,
  customer,
  profesional,
  price,
  status,
  order_date,
  removal_date
}) => {
  const { t } = useTranslation();
  return [
    {
      label: 'ordersSupplies.site',
      content: site ? site?.label : '-',
      span: 3
    },
    {
      label: 'ordersSupplies.customer',
      content:
        customer && `${customer?.first_name || ''} ${customer?.last_name}`,
      span: 3
    },
    {
      label: 'ordersSupplies.profesional',
      content: profesional && profesional?.name,
      span: 3
    },
    {
      label: 'ordersSupplies.supplier',
      content: supplier,
      span: 3
    },
    {
      label: 'ordersSupplies.order_date',
      content: moment(order_date).format('DD/MM/YYYY'),
      span: 3
    },
    {
      label: 'ordersSupplies.removal_date',
      content: removal_date ? moment(removal_date).format('DD/MM/YYYY') : '-',
      span: 3
    },
    {
      label: 'ordersSupplies.price',
      content: price || '-',
      span: 3
    },
    {
      label: 'ordersSupplies.status',
      content: status ? t(`orders-buyings.form.status-values.${status}`) : '-',
      span: 3
    }
  ];
};

export default useListContent;
