export const headers = [
  {
    label: 'stocks.matter',
    key: 'matter'
  },
  {
    label: 'stocks.supplier',
    key: 'supplier'
  },
  {
    label: 'catalogues.column.label',
    key: 'label'
  },
  {
    label: 'stocks.batch_number',
    key: 'batch_number'
  },
  {
    label: 'stocks.finition',
    key: 'finition'
  },
  {
    label: 'stocks.dimensions',
    key: 'dimensions'
  },
  {
    label: 'stocks.thickness',
    key: 'thickness'
  }
];
