import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  typeCatalogues,
  categoryProducts
} from '../../../utils/constants/tagColors';

const useListContent = ({
  ref,
  label,
  category,
  target,
  points,
  supplier,
  nonExistentSupplier,
  sold_price
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('catalogues.column.ref'),
      content: ref,
      span: 3
    },
    {
      label: t('catalogues.column.label'),
      content: label,
      span: 3
    },
    {
      label: t('catalogues.column.target'),
      content: target && (
        <Tag color={typeCatalogues[target]}>{target.toUpperCase()}</Tag>
      ),
      span: 3
    },
    {
      label: t('catalogues.column.category'),
      content: category && (
        <Tag color={categoryProducts[category]}>{category.toUpperCase()}</Tag>
      ),
      span: 3
    },
    {
      label: t('catalogues.column.points'),
      content: points,
      span: 3
    },
    {
      label: t('products.form.supplier'),
      content: nonExistentSupplier
        ? nonExistentSupplier || ''
        : supplier?.name || '',
      span: 3
    },
    {
      label: t('catalogues.column.sold_price'),
      content: sold_price && `${parseFloat(sold_price).toFixed(2)} €`,
      span: 3
    }
  ];
};

export default useListContent;

// render: total => `${parseFloat(total).toFixed(2)}€`
