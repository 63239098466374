/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Input, Switch } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import { CompactPicker } from 'react-color';

const useFields = () => {
  const [address, setAddress] = useState('');
  const [requestColorPicker, setRequestColorPicker] = useState('#000');
  const [productionColorPicker, setProductionColorPicker] = useState('#000');

  const requestColorChromePicker = (
    <CompactPicker
      color={requestColorPicker}
      onChangeComplete={(color) => {
        setRequestColorPicker(color.hex);
      }}
    />
  );

  const productionColorChromePicker = (
    <CompactPicker
      color={productionColorPicker}
      onChangeComplete={(color) => {
        setProductionColorPicker(color.hex);
      }}
    />
  );

  const basicFields = [
    {
      label: 'label',
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      label: 'title_doc',
      name: ['title']
    },
    {
      label: 'address',
      name: ['address'],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Rechercher ...',
                  className: 'location-search-input'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      rules: [{ required: true }]
    },
    {
      label: 'fax',
      name: ['fax']
    },
    {
      label: 'web_site',
      name: ['web_site']
    },
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      label: 'siret',
      name: ['siret']
    },
    {
      label: 'TvaInt',
      name: ['TvaInt']
    },
    {
      label: 'RCS',
      name: ['RCS']
    },
    {
      label: 'APE',
      name: ['APE']
    },
    {
      label: 'request_color',
      name: ['request_color'],
      valuePropName: 'color',
      required: true,
      input: requestColorChromePicker
    },
    {
      label: 'production_color',
      name: ['production_color'],
      valuePropName: 'color',
      required: true,
      input: productionColorChromePicker
    },
    {
      label: 'accounting_journal',
      name: ['accounting_journal']
    },
    {
      label: 'external',
      name: ['external'],
      input: <Switch />
    }
  ];

  return { basicFields };
};

export default useFields;
