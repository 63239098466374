import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Steps,
  Descriptions,
  DatePicker,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import {
  DeleteOutlined,
  WarningOutlined,
  CheckOutlined,
  UndoOutlined,
  CopyOutlined
} from '@ant-design/icons/lib';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import DownloadGeneratedFile from '../Invoices/DownloadGeneratedFile';
import Customer from '../Invoices/Components/Customer';
import Profesionals from '../Invoices/Components/Profesionals';
import DescQuotForm from '../Quotations/descQuotForm';
import ImportFiles from '../../../components/importFiles';
import BlocPrices from '../Quotations/prices';
import InvoicesModal from './InvoicesModal';

const { Step } = Steps;

const ExtraContainer = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 10px;
`;

const ShowOrder = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const history = useHistory();
  // const {
  //   template,
  //   updateDocument,
  //   deleteDocument,
  //   downloadFile
  // } = useAccountingContext();
  const [isLoading, setLoading] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [total, setTotal] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [toPayed, setToPayed] = useState(0);
  const dateFormat = 'YYYY/MM/DD';
  const [template, setTemplate] = useState();

  const [dateMetre, setDateMetre] = useState();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=ORDER`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (
    fileId,
    name,
    callback,
    _,
    outputType = 'pdf'
  ) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/order/${fileId}/${template._id}?outputType=${outputType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  const onDuplicate = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/duplicate/${displayedResource?.quotation._id}`
      });
      setLoading(false);
      await dispatchAPI('DELETE', { url: `/orders/${id}` });
      history.push(`/customers_accounting/quotations/show/${data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (displayedResource.quotation) {
      setDateMetre(displayedResource.quotation.metre);
    }
  }, [displayedResource]);

  const handleMetre = async (e) => {
    const body = {
      metre: e
    };

    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${displayedResource?.quotation._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,profesional,`
      });
      const quotation = await dispatchAPI('GET', {
        url: `/quotations/${data.quotation}?populate=customer,site,profesional,coef,quotlines.elements.element,`
      });
      setResource({ ...data, quotation: quotation.data });
      setTotal(data.total);
      setToPayed(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unvalidateQuotation = async (quoteId) => {
    const body = {
      status: 'PENDING'
    };
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${quoteId}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onDelete = async () => {
    const body = {
      deleted: true
    };
    try {
      await dispatchAPI('PATCH', { url: `/orders/${id}`, body });
      await unvalidateQuotation(displayedResource.quotation._id);
      history.push('/customers_accounting/orders_sales');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createInvoice = async (orderId) => {
    const body = {
      order: orderId
    };
    try {
      await dispatchAPI('POST', { url: '/invoices', body });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onValidate = async () => {
    const body = {
      status: 'VALIDATE'
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${displayedResource._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleCommission = async () => {
    if (displayedResource.profesional?.commission) {
      const body = {
        profesional: displayedResource.profesional._id,
        order: displayedResource._id,
        amount: parseInt(
          (displayedResource.prices.totalNet *
            displayedResource.profesional.commission) /
            100,
          10
        )
      };
      try {
        await dispatchAPI('POST', {
          url: `/commissions`,
          body
        });
        setForceRefresh(!forceRefresh);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
  };

  const onInvoiced = async () => {
    const body = {
      status: 'INVOICED'
    };
    handleCommission();
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${displayedResource._id}`,
        body
      });
      await createInvoice(displayedResource._id);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onInvalidate = async () => {
    const body = {
      status: 'WAITING'
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${displayedResource._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCurrentStep = (currResource) => {
    let step;
    switch (currResource.status) {
      case 'WAITING':
        step = 0;
        break;
      case 'VALIDATE':
        step = 1;
        break;
      case 'INVOICED':
        step = 2;
        break;
      default:
        break;
    }
    return step;
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    await getTemplate();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id, forceRefresh]);
  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: displayedResource.number
        })}
        extra={
          toPayed && toPayed.files.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DownloadGeneratedFile
                element={displayedResource}
                template={template}
                downloadFile={downloadFile}
              />

              {!isLoading && displayedResource.status === 'INVOICED' && (
                <InvoicesModal
                  setForceRefresh={setForceRefresh}
                  forceRefresh={forceRefresh}
                  toPayed={toPayed}
                />
              )}
              {!isLoading && displayedResource.status === 'WAITING' && (
                <>
                  <Popconfirm
                    title={t(`${resource}.action.duplicate.warning`)}
                    okText={t(`${resource}.action.duplicate.ok`)}
                    okButtonProps={{ type: 'add' }}
                    cancelText={t(`${resource}.action.delete.cancel`)}
                    onConfirm={onDuplicate}
                    icon={<WarningOutlined />}
                  >
                    <Button
                      type="add"
                      add
                      icon={isLoading ? <Spin /> : <CopyOutlined />}
                      style={{ float: 'right', margin: '1em' }}
                    >
                      {t(`${resource}.action.duplicate.title`)}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={t(`${resource}.action.delete.title`)}
                    okText={t(`${resource}.action.delete.ok`)}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t(`${resource}.action.delete.cancel`)}
                    onConfirm={onDelete}
                    icon={<WarningOutlined />}
                  >
                    <Button
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      style={{ float: 'right', margin: '1em' }}
                    >
                      {t('buttons.delete')}
                    </Button>
                  </Popconfirm>
                  <Button
                    type="add"
                    icon={<CheckOutlined />}
                    style={{ float: 'right', margin: '1em' }}
                    onClick={onValidate}
                  >
                    {t('buttons.validate')}
                  </Button>
                </>
              )}
              {!isLoading && displayedResource.status === 'VALIDATE' && (
                <>
                  <Popconfirm
                    title={t(`${resource}.action.delete.title`)}
                    okText={t(`${resource}.action.delete.ok`)}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t(`${resource}.action.delete.cancel`)}
                    onConfirm={onDelete}
                    icon={<WarningOutlined />}
                  >
                    <Button
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      style={{ float: 'right', margin: '1em' }}
                    >
                      {t('buttons.delete')}
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    icon={<UndoOutlined />}
                    onClick={onInvalidate}
                  >
                    {t('buttons.unvalidate')}
                  </Button>
                  <Button
                    type="add"
                    style={{ float: 'right', margin: '1em' }}
                    icon={<CheckOutlined />}
                    onClick={onInvoiced}
                  >
                    {t('buttons.invoiced')}
                  </Button>
                </>
              )}
            </div>
          ) : null
        }
      />

      <ContentCustom>
        <Row>
          <Col span={18} offset={3}>
            <Steps current={getCurrentStep(displayedResource)}>
              <Step
                title={
                  <ContentCustom>
                    <div
                      style={{
                        backgroundColor: 'var(--contrastBackground)',
                        padding: ' 10px',
                        width: '100%'
                      }}
                    >
                      <p>Date prévisionnelle du métré </p>
                      {dateMetre && (
                        <DatePicker
                          format={dateFormat}
                          onChange={handleMetre}
                          defaultValue={moment(dateMetre)}
                        />
                      )}
                    </div>
                  </ContentCustom>
                }
              />
              <Step title={t('orders.form.enums.VALIDATE')} />
              <Step title={t('orders.form.enums.INVOICED')} />
            </Steps>
          </Col>
        </Row>

        <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
          <Col span={6} style={{ paddingBottom: '10px' }}>
            {displayedResource.profesional && (
              <Profesionals
                display={displayedResource.profesional}
                isLoading={isLoading}
              />
            )}
          </Col>
          <Col span={6} offset={12} style={{ paddingBottom: '10px' }}>
            <Customer
              customer={displayedResource.customer}
              isLoading={isLoading}
            />
          </Col>
        </Row>
        {displayedResource.quotation?.quotlines.map((line) => (
          <DescQuotForm line={line} />
        ))}
        {displayedResource.quotation?.extra_fields?.length > 0 ? (
          <ExtraContainer>
            {displayedResource.quotation?.extra_fields?.map((extra) => (
              <Descriptions title={t('quotation.extra.title')} size="small">
                <Descriptions.Item label={t('quotation.extra.label')}>
                  {`${extra.label}`}
                </Descriptions.Item>
                <Descriptions.Item label={t('quotation.extra.comment')}>
                  {`${extra.comment}`}
                </Descriptions.Item>
                <Descriptions.Item label={t('quotation.extra.price')}>
                  {`${extra.price}`}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </ExtraContainer>
        ) : null}

        {/* <Row>
          <Col span={24} />
        </Row> */}
        {/* <Row style={{ marginTop: 50 }}>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.totalht')}
              <div style={{ float: 'right' }}>
                {`${numeral(total).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.vat')}
              <div style={{ float: 'right' }}>
                {`${numeral(total * 0.2).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.discount')}
              <div style={{ float: 'right' }}>
                {`${numeral(resource.discount || 0).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.totalttc')}
              <div style={{ float: 'right' }}>
                {`${numeral(total * 1.2).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row> */}
        {displayedResource?.prices && (
          <BlocPrices prices={displayedResource.prices} />
        )}
      </ContentCustom>
      <ImportFiles
        id={id}
        files={displayedResource.files}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        resource="orders"
      />
    </>
  );
};

ShowOrder.propTypes = {
  resource: PropTypes.shape({
    status: PropTypes.any
  }).isRequired
};

export default ShowOrder;
