import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import MatterRouter from './Matters/MatterRouter';
import DartyMatterrouter from './DartyMatters/DartyMatterRouter';
import LaborRouter from './Labors/LaborRouter';
import ProductionRouter from './Productions/ProductionRouter';
import ShapingRouter from './Shapings/ShapingRouter';
import ProductRouter from './Products/ProductsRouter';

const CatalogueRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.CATALOGUES.MATTERS}`}
        component={MatterRouter}
      />
      <Route path={`${path}/dartymatters`} component={DartyMatterrouter} />
      <Route
        path={`${path}${subRoutes.CATALOGUES.LABORS}`}
        component={LaborRouter}
      />
      <Route
        path={`${path}${subRoutes.CATALOGUES.PRODUCTIONS}`}
        component={ProductionRouter}
      />
      <Route
        path={`${path}${subRoutes.CATALOGUES.SHAPINGS}`}
        component={ShapingRouter}
      />
      <Route
        path={`${path}${subRoutes.CATALOGUES.PRODUCTS}`}
        component={ProductRouter}
      />
    </Switch>
  );
};

export default CatalogueRouter;
