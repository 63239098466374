import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import { routes, subRoutes } from '../../utils/constants/routes';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import useErrorMessage from '../../utils/ErrorMessage';

const ShowSupply = () => {
  const { id } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [supply, setSupply] = useState({});
  const { dispatchAPI, canEditResource } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await dispatchAPI('GET', {
        url: `/supplies/${id}?populate=catalogue`
      });
      setSupply(data.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const deleteSupply = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/supplies/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('supplies.show')}
        withSubRoutes
        extra={
          canEditResource(path) && (
            <>
              <Link
                to={{
                  pathname: `${routes.STOCK}${subRoutes.STOCK.SUPPLIES}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteSupply}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(supply)} translate />
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowSupply;
