import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import MachinesTable from './machineTable';

const MachineSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [machines, setMachine] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const fetchProcesss = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Processs`
      });

      setProcessList(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchMachines = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Machines?populate=type`
      });
      setMachine(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addMachines = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Machines', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchMachines();
  };

  const deleteMachines = async (Machine) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-Machines/${Machine._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchMachines();
  };

  const editMachines = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-Machines/${_id}`,
        body: row
      });
      setRefresh(!refresh);
    } catch (e) {
      message.error(e.message);
    }
    fetchMachines();
  };

  useEffect(() => {
    fetchMachines();
    fetchProcesss();
  }, [refresh]);

  return (
    <>
      {machines.length > 0 && (
        <MachinesTable
          machines={machines}
          editMachines={editMachines}
          deleteMachines={deleteMachines}
          processList={processList}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addMachines}
      >
        {t('settings.machines.addMachines')}
      </Button>
    </>
  );
};

export default MachineSettings;
