/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Tag,
  Typography
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import { companiesTypes } from '../../../utils/constants/tagColors';

const { Title } = Typography;
const { Option } = Select;

const NewContactDrawer = ({
  isVisible,
  setVisibilityContactDrawer,
  getContact
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [companies, setCompanies] = useState();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const onFinish = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: '/contacts',
        body: values
      });
      message.success(t('contacts.message.success.new'));
      await getContact();
      setVisibilityContactDrawer();
    } catch (e) {
      message.error(t('companies.message.error.'));
    }
  };

  const onFinishFailed = () => {
    message.error(t('contacts.message.error'));
  };

  const cancelButton = () => {
    setVisibilityContactDrawer();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityContactDrawer();
      }}
      width="30%"
    >
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`contacts.form.title.create`)}</Title>
      </Col>
      <Form
        {...formItemLayout}
        name="test"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%' }}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
          label={t('contacts.form.type')}
          name="type"
        >
          <Select loading={isFieldsLoading}>
            {(enums.types || []).map((type) => (
              <Option key={type} value={type}>
                <Tag color={companiesTypes[type]}>
                  {t(`companies.tags.${type}`)}
                </Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('contacts.form.gender')} name="gender">
          <Select loading={isFieldsLoading}>
            {(enums.genders || []).map((title) => (
              <Option key={title} value={title}>
                {t(`contacts.form.${title}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('contacts.form.last_name')}
          name="last_name"
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.first_name')}
          name="first_name"
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
          style={{ width: '100%' }}
          label={t('contacts.form.company')}
          name="my_company"
        >
          {typeof companies !== 'undefined' && (
            <Select loading={isFieldsLoading}>
              {companies.map((company) => (
                <Option key={company._id} value={company._id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('contacts.form.position')} name="position">
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.phone_number_1')}
          name="phone_number_1"
          rules={[
            {
              required: true,
              message: t('errors.message.missing_field')
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('contacts.form.phone_number_2')}
          name="phone_number_2"
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            background: 'rgb(71, 210, 103)',
            color: '#fff',
            border: 'rgb(71, 210, 103)',
            borderRadius: 3,
            marginLeft: 10,
            float: 'right'
          }}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          style={{
            background: '#fff',
            color: 'red',
            borderColor: 'red',
            borderRadius: 3,
            float: 'right'
          }}
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

NewContactDrawer.propTypes = {
  getContact: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setVisibilityContactDrawer: PropTypes.func.isRequired
};

export default NewContactDrawer;
