import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const TemplatesContext = createContext({});

export const TemplatesContextProvider = ({ children }) => {
  return (
    <TemplatesContext.Provider value={{}}>{children}</TemplatesContext.Provider>
  );
};

TemplatesContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(TemplatesContext);
