const useListContent = ({
  label,
  address,
  fax,
  email,
  siret,
  TvaInt,
  RCS,
  APE,
  web_site,
  phone_number,
  accounting_journal
}) => {
  return [
    {
      label: 'sites.name',
      content: label
    },
    {
      label: 'sites.form.phone_number',
      content: phone_number
    },
    {
      label: 'sites.form.email',
      content: email
    },
    {
      label: 'sites.form.web_site',
      content: web_site
    },
    {
      label: 'sites.form.fax',
      content: fax
    },
    {
      label: 'sites.form.siret',
      content: siret
    },
    {
      label: 'sites.form.TvaInt',
      content: TvaInt
    },
    {
      label: 'sites.form.APE',
      content: APE
    },
    {
      label: 'sites.form.RCS',
      content: RCS
    },
    {
      label: 'customers.show.channel_number',
      content: address ? address?.number : '-'
    },
    {
      label: 'customers.show.street',
      content: address ? address?.street : '-'
    },
    {
      label: 'customers.show.postal_code',
      content: address ? address?.postal_code : '-'
    },
    {
      label: 'customers.show.city',
      content: address ? address?.city : '-'
    },
    {
      label: 'customers.show.additional_information',
      content: address ? address?.additional : '-'
    },
    {
      label: 'customers.show.additional_information',
      content: address ? address?.additional : '-'
    },
    {
      label: 'sites.form.accounting_journal',
      content: accounting_journal || '-'
    }
  ];
};

export default useListContent;
