import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Layout, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo1 from '../../assets/images/atelierdumarbre.png';
import Logo from '../../assets/images/atelierLogo.png';
import useAuthContext from '../../contexts/AuthContext';

const Login = () => {
  const { dispatchAPI } = useAuthContext();
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const [googleUrl, setGoogleUrl] = useState('');
  const [code, setCode] = useState('');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };
  const { location } = history;

  const postGoogleCode = async (gCode) => {
    await dispatchAPI('GOOGLE_LOGIN', { gCode });
    message.success(t('login.success'));
  };

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach((part) => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (code !== '') {
      (async () => {
        await postGoogleCode(decodeURIComponent(code));
      })();
    }
  }, [code]);

  const getGoogleUrl = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/auth2/google` });
      setGoogleUrl(data.url);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  useEffect(() => {
    (async () => {
      await getGoogleUrl();
    })();
  }, [googleUrl]);

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <img
              style={{ width: '100%', height: '100vh' }}
              alt="logo"
              src={Logo1}
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              {width < 768 && height > 550 && (
                <img alt="logo" style={{ width: '80%' }} src={Logo} />
              )}
              {width < 768 && height <= 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )}
              <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
                {width > 768 && (
                  <img
                    style={{
                      width: 160,
                      marginBottom: 50
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )}
                <Title style={{ textAlign: 'center' }}>
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
                <Divider />
                {/* { currentForm === 'login' && (
                  <div>
                    <div onClick={() => window.location.replace(googleUrl)} style={{width: 250, margin: 'auto', marginBottom: 5, textAlign: 'center', cursor: 'pointer'}}>
                      <img style={{marginRight: 5}} width={20} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1004px-Google_%22G%22_Logo.svg.png" alt=""/>
                      &nbsp;
                      <span>{t(`login.title.google`)}</span>
                    </div>
                    <FacebookLogin
                      appId="1901517216655058"
                      fields="name,email,picture"
                      callback={handleFacebookConnection}
                      render={renderProps  => (
                        <div onClick={renderProps.onClick} style={{width: 250, margin: 'auto', marginBottom: 5, textAlign: 'center', cursor: 'pointer'}}>
                          <img style={{marginRight: 5}} width={20} src="https://www.bing.com/th?id=AMMS_8ddf76a14a2e3ad3ba62b46d49a75a74&w=110&h=110&c=7&rs=1&qlt=95&cdv=1&pid=16.1" alt=""/>
                          &nbsp;
                          <span>{t(`login.title.facebook`)}</span>
                        </div>
                      )}
                    />
                    <TwitterLogin
                      authCallback={handleTwitterConnection}
                      consumerKey={"OQfDULgeBLTnrhQEqJsgVxknm"}
                      consumerSecret={"YdGHXHIM208MwRreqbHpyCBsEucXe7wgQAWijaFnYv2HsYwG2q"}
                      children={(
                        <div style={{width: 250, margin: 'auto', marginBottom: 5, textAlign: 'center', cursor: 'pointer'}}>
                          <img style={{marginRight: 5}} width={20} src="https://www.bing.com/th?id=AMMS_c4fa0d273f63fef684d0e182a47bc002&w=110&h=110&c=7&rs=1&qlt=95&cdv=1&pid=16.1" alt=""/>
                          &nbsp;
                          <span>{t(`login.title.twitter`)}</span>
                        </div>
                      )}
                    />
                    <LinkedIn
                      clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                      callback={handleLinkedinConnection}
                      className="linkedin-connector"
                      scope={["r_liteprofile","r_emailaddress"]}
                      text={(
                        <div style={{width: 250, margin: 'auto', textAlign: 'center', cursor: 'pointer'}}>
                          <img style={{marginRight: 5}} width={20} src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/600px-LinkedIn_logo_initials.png" alt=""/>
                          &nbsp;
                          <span>{t(`login.title.linkedin`)}</span>
                        </div>
                      )}
                    />
                    </div>
                )} */}
                <Row>
                  {width < 768 && height > 550 && (
                    <img
                      style={{
                        width: 120,
                        margin: '0 auto',
                        display: 'block'
                      }}
                      alt="logo"
                      src={Logo}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;
