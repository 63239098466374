import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Input,
  Form,
  DatePicker,
  Radio
} from 'antd';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

const DateInfos = ({ form, datas, isEditable }) => {
  const { Title } = Typography;
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setRefresh(!refresh);
  }, [form]);

  if (datas) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Form {...formItemLayout} form={form} style={{ width: '100%' }}>
        <Col span={24}>
          <Title level={5}>Date de l&lsquo;enlèvement / de la livraison</Title>
          <div style={{ width: '103px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          <Row>
            <Col span={24}>
              {
                <Form.Item
                  name="date"
                  rules={[{ required: true, message: 'Choisir une date.' }]}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                  style={{ marginLeft: '60px' }}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    disabled={!isEditable}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  name="is_delivery"
                  label="Ce bon est pour"
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 16 }} // TODO : mettre une default value + mettre un formulaire pour recup les infos
                >
                  <Radio.Group
                    onChange={() => setRefresh(!refresh)}
                    disabled={!isEditable}
                  >
                    <Radio value={false}>un enlèvement</Radio>
                    <Radio value>une livraison</Radio>
                  </Radio.Group>
                </Form.Item>
              }
              {form.getFieldsValue().is_delivery && (
                <Form.Item
                  label="Adresse de livraison"
                  name="address_livraison"
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 16 }} // TODO : mettre une default value + mettre un formulaire pour recup les infos
                >
                  <Input
                    disabled={!isEditable}
                    value={`${datas.address.number || ''} ${
                      datas.address.street
                    } ${datas.address.postal_code} ${datas.address.city}`}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
      </Form>
    );
  }

  return <>No infos available </>;
};

DateInfos.propTypes = {
  datas: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.any,
      number: PropTypes.any,
      postal_code: PropTypes.any,
      street: PropTypes.any
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number_1: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    is_delivery: PropTypes.bool
  }).isRequired,
  isEditable: PropTypes.bool.isRequired
};

export default DateInfos;
