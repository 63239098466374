/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  processList,
  ...restProps
}) => {
  let inputNode;

  switch (inputType) {
    case 'text':
      inputNode = <Input />;
      break;
    case 'select':
      inputNode = (
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          options={processList.map(({ label, _id }) => ({
            label,
            value: _id
          }))}
        />
      );

      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  children: PropTypes.element.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  inputType: PropTypes.string.isRequired,
  processList: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  record: PropTypes.shape({ status: PropTypes.string }).isRequired,
  title: PropTypes.string.isRequired
};

const MachinesTable = ({
  machines,
  editMachines,
  deleteMachines,
  processList
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      type: record.type.map(({ _id }) => _id)
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    const row = await form.validateFields();
    editMachines(record._id, row);
    setEditingKey('');
  };

  const columns = [
    {
      title: t('settings.machines.label'),
      dataIndex: 'label',
      width: '40%',
      editable: true
    },
    {
      title: t('settings.machines.type'),
      dataIndex: 'type',
      render: (types, record) => (
        <div>
          {' '}
          {types.map(({ label }) => (
            <p>{label}</p>
          ))}
        </div>
      ),
      width: '40%',
      editable: true
    },

    {
      title: t('settings.machines.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)}>
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {t('buttons.edit')}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteMachines(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {t('buttons.delete')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        processList,
        inputType: col.dataIndex === 'type' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={machines}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

MachinesTable.propTypes = {
  deleteMachines: PropTypes.func.isRequired,
  editMachines: PropTypes.func.isRequired,
  machines: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, type: PropTypes.any })
  ).isRequired,
  processList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MachinesTable;
