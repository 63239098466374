import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Checkbox, Form, DatePicker } from 'antd';

const useColumns = (isValidDefinitevly) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('laysheet.columns.accessories.label'),
      dataIndex: 'label',
      width: '20%'
    },
    {
      title: t('laysheet.columns.accessories.onconstruction'),
      dataIndex: 'onconstruction',
      key: 'onconstruction',
      width: '12%',
      render: (_, record, index) => (
        <Form.Item name={['accessories', index, 'onconstruction']}>
          <Input disabled={isValidDefinitevly} style={{ width: '70%' }} />
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.accessories.deposit'),
      dataIndex: 'deposit',
      key: 'deposit',
      width: '12%',
      render: (_, record, index) => (
        <Form.Item name={['accessories', index, 'deposit']}>
          <Input disabled={isValidDefinitevly} style={{ width: '70%' }} />
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.accessories.delivering'),
      dataIndex: 'delivering',
      key: 'delivering',
      width: '12%',
      render: (_, record, index) => (
        <Form.Item name={['accessories', index, 'delivering']}>
          <Input disabled={isValidDefinitevly} style={{ width: '70%' }} />
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.accessories.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '12%',
      render: (_, record, index) => (
        <Form.Item name={['accessories', index, 'quantity']}>
          <Input
            disabled={isValidDefinitevly}
            type="number"
            style={{ width: '70%' }}
          />
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.accessories.date_receipt'),
      dataIndex: 'date_receipt',
      key: 'date_receipt',
      width: '20%',
      render: (_, record, index) => (
        <Form.Item
          style={{ width: '100%' }}
          name={['accessories', index, 'date_receipt']}
        >
          <DatePicker disabled={isValidDefinitevly} format="DD-MM-YYYY" />
        </Form.Item>
      )
    },
    {
      title: t('laysheet.columns.accessories.status'),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (_, record, index) => (
        <Form.Item
          label="Reçu"
          name={['accessories', index, 'status']}
          valuePropName="checked"
        >
          <Checkbox disabled={isValidDefinitevly} />
        </Form.Item>
      )
    },
    {
      dataIndex: 'label',
      key: 'label',
      width: '0%',
      render: (_, record, index) => (
        <Form.Item
          style={{ width: '0%' }}
          initialValue={record.label}
          hidden
          name={['accessories', index, 'label']}
        />
      )
    },
    {
      dataIndex: '_id',
      key: '_id',
      width: '0%',
      render: (_, record, index) => (
        <Form.Item
          style={{ width: '0%' }}
          initialValue={record._id}
          hidden
          name={['accessories', index, '_id']}
        />
      )
    }
  ];
  return columns;
};

export default useColumns;
