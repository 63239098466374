import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListUsers from './ListUsers';
import ShowUser from './ShowUser';
import { subRoutes } from '../../utils/constants/routes';
import CreateUpdateUser from './CreateUpdateUser';

const ExtranetUsers = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateUser purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateUser purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowUser} />
      <Route path={`${path}`} component={ListUsers} />
    </Switch>
  );
};

export default ExtranetUsers;
