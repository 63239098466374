import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import FormPrice from '../formPrice';
import { mattersLabels } from '../../../utils/constants/tagColors';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [coef, setCoef] = useState([]);
  const [choosenCoef, setChossenCoef] = useState(0);

  const handleCoef = (e) => {
    setChossenCoef(e);
  };

  const basicFields = [
    {
      name: ['cle1'],
      input: <InputNumber />,
      rules: [{ required: true }]
    },
    {
      name: ['cle2'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['ref'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {enums.map((el) => (
            <Option key={el} value={el}>
              <Tag color={mattersLabels[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['color'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['group'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['completions'],
      input: <Input />
    },
    {
      name: ['coef'],
      input: (
        <Select onChange={handleCoef} value={choosenCoef}>
          {coef &&
            coef.map((el) => (
              <Option key={el._id} value={el._id}>
                {el.coef}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['pricing'],
      input: <FormPrice />
    }
  ];

  const advancedFields = [];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/dartymatters/enums' });
      setEnums(data.labels);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  const getCoefs = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/settings-coefs' });
      setCoef(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCoefs();
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
