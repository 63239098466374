import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { message, Row } from 'antd';

import useLaysheetContext from './LaysheetContext';

import HeaderForm from './HeaderForm';
import { CommentaryCard } from './LaysheetCard/CommentaryCard';
import { MaterialsCard } from './LaysheetCard/MaterialsCard';
import { ConsumableCard } from './LaysheetCard/ConsumableCard';
import { AccessoryCard } from './LaysheetCard/AccessoryCard';
import { LaysheetButtons } from './Buttons/LaysheetButtons';

export const LaysheetForm = ({
  isValide,
  setIsValide,
  form,
  isValidDefinitevly,
  setIsValidDefinitevly,
  handleValideDefini
}) => {
  const {
    accessorie,
    setIsdisabled,
    measurementDate,
    setMeasurementDate,
    date,
    setDate,
    commercialSite,
    setCommercialSite,
    productionSites,
    setProductionSites,
    customer,
    setCustomer,
    operators,
    sites,
    state
  } = useLaysheetContext();
  const history = useHistory();

  const [materialLaySheet, setMaterialLaySheet] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!state?.datas && !state?.editing) {
      message.error('Données manquantes');
      history.push('/customers_accounting/orders_sales');
    }

    if (state?.laysheet) {
      setCommercialSite(state.laysheet.commercial_site);
      setProductionSites(state.laysheet.production_sites);
      setCustomer(state.laysheet.customer);
      setDate(state.laysheet.delivery_date);
      setMeasurementDate(state.laysheet.measurement_date);
      setMaterialLaySheet(state.laysheet.utils);
      setDataSource(state.laysheet.consumables);
      form.setFieldsValue({
        ...state.laysheet,
        customer: state.laysheet.customer._id,
        commercial_site: state.laysheet.commercial_site,
        production_sites: state.laysheet.production_sites,
        delivery_date: moment(state.laysheet.delivery_date),
        measurement_date: moment(state.laysheet.measurement_date),
        accessories: state.laysheet.accessories
          ? state.laysheet.accessories.map((el) => ({
              ...el,
              date_receipt: moment(el.date_receipt)
            }))
          : accessorie,
        associateWorkSheet: {
          value: state.laysheet.associateWorkSheet,
          key: state.laysheet.associateWorkSheet,
          label: state.laysheet.numberWorkSheet
        }
      });

      if (state.laysheet?.isDefinitively) setIsValidDefinitevly(true);
      else setIsValidDefinitevly(false);

      setIsValide(true);
      setIsdisabled(true);
    }
  }, [state]);

  return isValide ? (
    <>
      <Row
        justify="space-between"
        align="stretch"
        style={{
          padding: '24px',
          flexWrap: 'nowrap',
          height: 'fit-content'
        }}
        gutter={24}
      >
        <HeaderForm
          sites={sites}
          commercialSites={commercialSite}
          productionSites={productionSites}
          customer={customer}
          operators={operators}
          form={form}
          isValidDefinitevly={isValidDefinitevly}
          state={state.laysheet ? state.laysheet : null}
          date_lay={date}
          measurement_date={measurementDate}
        />
      </Row>
      <AccessoryCard isValidDefinitevly={isValidDefinitevly} />
      <MaterialsCard
        form={form}
        isValidDefinitevly={isValidDefinitevly}
        materialLaySheet={materialLaySheet}
        setMaterialLaySheet={setMaterialLaySheet}
      />
      <ConsumableCard
        form={form}
        isValidDefinitevly={isValidDefinitevly}
        dataSource={dataSource}
        setDataSource={setDataSource}
      />
      <div
        style={{
          padding: '24px'
        }}
      >
        <CommentaryCard isValidDefinitevly={isValidDefinitevly} />
        <LaysheetButtons
          isValidDefinitevly={isValidDefinitevly}
          isValide={isValide}
          save={() => handleValideDefini(false)}
          onValidate={() => handleValideDefini(true)}
        />
      </div>
    </>
  ) : null;
};

LaysheetForm.propTypes = {
  isValide: PropTypes.bool.isRequired,
  setIsValide: PropTypes.func.isRequired,
  form: PropTypes.shape({ setFieldsValue: PropTypes.func }).isRequired,
  isValidDefinitevly: PropTypes.bool.isRequired,
  setIsValidDefinitevly: PropTypes.func.isRequired,
  handleValideDefini: PropTypes.func.isRequired
};
