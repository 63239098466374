import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import PiecesRouter from '../Stocks/PiecesRouter';
import SupplierRouter from '../Suppliers/SupplierRouter';
import OrdersPurchases from '../OrdersPurchases/OrdersPurchasesRouter';
import OrdersSupplies from '../OrdersSupplies/OrdersSuppliesRouter';
import SuppliesRouter from '../Supplies/SuppliesRouter';
import MovementRouter from '../Movements/MovementRouter';

const SuppliersRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.BUYING.SUPPLIERS}`}
        render={() => <SupplierRouter />}
      />
      <Route
        path={`${path}${subRoutes.BUYING.ORDERS_PURCHASES}`}
        component={OrdersPurchases}
      />
      {/* <Route
        path={`${path}${subRoutes.SUPPLIERS_ACCOUNTING.STOCKS}`}
        component={PiecesRouter}
      /> */}
      <Route
        path={`${path}${subRoutes.STOCK.STOCKS}`}
        component={PiecesRouter}
      />
      <Route
        path={`${path}${subRoutes.STOCK.MOVEMENTS}`}
        component={MovementRouter}
      />
      <Route
        path={`${path}${subRoutes.STOCK.SUPPLIES}`}
        component={SuppliesRouter}
      />
      {/* <Route
        path={`${path}${subRoutes.SUPPLIERS_ACCOUNTING.SUPPLIES}`}
        component={SuppliesRouter}
      /> */}
      <Route
        path={`${path}${subRoutes.BUYING.ORDERS_SUPPLIES}`}
        component={OrdersSupplies}
      />
    </Switch>
  );
};

export default SuppliersRouter;
