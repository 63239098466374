import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  message
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import useHandleResize from '../../utils/HandleResize';
import useAuthContext from '../../contexts/AuthContext';
import Logo1 from '../../assets/images/atelierdumarbre.png';
import Logo from '../../assets/images/atelierLogo.png';
import usePwdPattern from '../../utils/pwdPattern';
import { outOfNavRoutes } from '../../utils/constants/routes';

const { Title } = Typography;

/**
 * @component
 * Component for creating a password via email.
 * @param {object} props - Component props.
 * @param {Function} props.switchForm - Callback to switch form.
 * @returns {JSX.Element} The JSX element representing the ForgotPwdForm component.
 */
const PostPwdForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { urlToken } = useParams();
  const { width, height } = useHandleResize();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const postPwd = async (body) => {
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `users/post-pwd/${urlToken}`,
        body
      });
      history.push(outOfNavRoutes.LOGIN);
      message.success(t('login.passwordChanged'));
    } catch (e) {
      message.error(t('login.wrongToken'));
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    await postPwd(values);
  };

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <img
              style={{ width: '100%', height: '100vh' }}
              alt="logo"
              src={Logo1}
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              {width < 768 && height > 550 && (
                <img alt="logo" style={{ width: '80%' }} src={Logo} />
              )}
              {width < 768 && height <= 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )}
              <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
                {width > 768 && (
                  <img
                    style={{
                      width: 160,
                      marginBottom: 50
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )}

                <Title style={{ textAlign: 'center' }}>
                  {t(`login.title.post-pwd`)}
                </Title>
                <Row>
                  {width < 768 && height > 550 && (
                    <img
                      style={{
                        width: 120,
                        margin: '0 auto',
                        display: 'block'
                      }}
                      alt="logo"
                      src={Logo}
                    />
                  )}
                </Row>
                <Form onFinish={handleSubmit}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: t('login.pwdMissing') },
                      ...usePwdPattern()
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined
                          style={{ color: 'var(--textColorSecondary)' }}
                        />
                      }
                      placeholder={t('login.password')}
                    />
                  </Form.Item>
                  <Form.Item
                    dependencies={['password']}
                    hasFeedback
                    name="confirm"
                    rules={[
                      {
                        required: true,
                        message: t('login.pwdMissing')
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t('login.pwdNotMatching'));
                        }
                      })
                    ]}
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                      }
                      placeholder={t('login.confirmPassword')}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      loading={isLoading}
                    >
                      {t('login.createPwd')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="link"
                      block
                      style={{ width: '100%' }}
                      onClick={() => history.push(`${outOfNavRoutes.LOGIN}`)}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default PostPwdForm;
