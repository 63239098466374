import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const AccountingContext = createContext({});

export const AccountingContextProvider = ({ children, collection }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [range, setRange] = useState([]);
  const [, setIsLoading] = useState(false);
  const [template, setTemplate] = useState();
  const [organization, setOrganization] = useState();

  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/organizations' });
      setOrganization(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteDocument = async (id, callback) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${collection}/${id}`,
        body: { deleted: true }
      });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateDocument = async (id, body, callback) => {
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=INVOICE`
      });
      setTemplate(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (
    id,
    name,
    callback,
    advPay,
    advNumber,
    invoiceType,
    outputType = 'pdf'
  ) => {
    try {
      let templateName = 'Exemple Facture.docx';
      if (invoiceType && invoiceType !== 'default')
        templateName = 'Facture acompteAdv.docx';
      const goodTemplate = template.find(
        (el) => el.metadata.originalName === templateName
      );
      const response = await dispatchAPI('GET', {
        url: `/files/generate/${collection.slice(0, -1)}/${id}/${
          goodTemplate._id
        }?withdetails=${advPay}&advNumber=${advNumber}&outputType=${outputType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (callback) callback();
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getOrganizations();
    await getTemplate();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <AccountingContext.Provider
      value={{
        collection,
        organization,
        template,
        range,
        setRange,
        updateDocument,
        deleteDocument,
        downloadFile
      }}
    >
      {children}
    </AccountingContext.Provider>
  );
};

AccountingContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired
};

export default () => useContext(AccountingContext);
