import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Customers from './Customers';
import ShowCustomer from './ShowCustomer';
import CreateUpdateCustomer from './CreateUpdateCustomer';

const CustomerRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateCustomer purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowCustomer} />
      <Route path={path} component={Customers} />
    </Switch>
  );
};

export default CustomerRouter;
