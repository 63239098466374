const useMatterListContent = ({
  label,
  color,
  completions,
  thickness,
  width,
  length,
  isBookmatch
}) => {
  return [
    {
      key: 'label',
      label: 'stocks.show.matter.label',
      content: `${label} - ${color}`,
      span: 1
    },
    {
      key: 'finish',
      label: 'stocks.show.matter.finish',
      content: completions,
      span: 1
    },
    {
      key: 'thickness',
      label: 'stocks.show.matter.thickness',
      content: thickness,
      span: 1
    },
    {
      key: 'length',
      label: 'stocks.show.matter.length',
      content: length,
      span: 1
    },
    {
      key: 'width',
      label: 'stocks.show.matter.width',
      content: width,
      span: 1
    },
    {
      key: 'is_bookmatch',
      label: 'stocks.is_bookmatch',
      content: isBookmatch,
      span: 1
    }
  ];
};

export default useMatterListContent;
