import React, { useState } from 'react';
import { Popconfirm, Button, Space, Dropdown, Menu, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  DeleteOutlined,
  WarningOutlined,
  ExportOutlined,
  EditOutlined,
  LoadingOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAccountingContext from '../../AccountingContext';

const useButtons = (datas) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState({});
  const { deleteDocument, downloadFile } = useAccountingContext();

  const advPayment =
    datas?.payments?.find((el) => el.advance_payment_number === datas.number) ||
    null;

  let invoiceType = 'default';

  switch (true) {
    case advPayment?.is_basic_invoice:
      invoiceType = 'default';
      break;
    case datas?.is_advance_payment:
      invoiceType = 'adv-invoice';
      break;
    case datas?.is_credit_note:
      invoiceType = 'credit_note';
      break;

    default:
      break;
  }

  const handleDownload = async (
    fileType,
    isAdvancePayment,
    advPaymentNumber
  ) => {
    if (!isDownloading[datas._id]) {
      setIsDownloading((prev) => ({ ...prev, [datas._id]: true }));
      await downloadFile(
        datas._id,
        `${datas.number}.${fileType}`,
        null,
        isAdvancePayment,
        advPaymentNumber,
        invoiceType,
        fileType
      );
      setIsDownloading((prev) => ({ ...prev, [datas._id]: false }));
    }
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key="pdf">
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() =>
            handleDownload(
              'pdf',
              !!advPayment,
              advPayment?.advance_payment_number
            )
          }
        >
          <Space>
            {isDownloading[datas._id] ? (
              <LoadingOutlined />
            ) : (
              <FilePdfOutlined />
            )}
            {t('buttons.download_pdf')}
          </Space>
        </Button>
      </Menu.Item>
      <Menu.Item key="docx">
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() =>
            handleDownload(
              'docx',
              !!advPayment,
              advPayment?.advance_payment_number
            )
          }
        >
          <Space>
            {isDownloading[datas._id] ? (
              <LoadingOutlined />
            ) : (
              <FileWordOutlined />
            )}
            {t('buttons.download_docx')}
          </Space>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const buttons = (
    <Space>
      <Link
        to={{
          pathname: `/customers_accounting/orders_sales/show/${datas?.orderNew?._id}`
        }}
        style={{ float: 'right' }}
      >
        <Button type="primary" icon={<ExportOutlined />}>
          {t('invoices.action.order')}
        </Button>
      </Link>

      <Dropdown overlay={downloadMenu} trigger={['click']}>
        <Button>
          <Space>
            {isDownloading[datas._id] ? <Spin /> : <DownloadOutlined />}
            {t('invoices.column.type.number')}
          </Space>
        </Button>
      </Dropdown>

      {datas?.status === 'WAITING FOR PAYMENT' && (
        <Popconfirm
          title={t(`orders.action.delete.title`)}
          okText={t(`orders.action.delete.ok`)}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(`orders.action.delete.cancel`)}
          onConfirm={() =>
            deleteDocument(
              datas._id,
              history.push('/custom_accounting/invoices')
            )
          }
          icon={<WarningOutlined />}
        >
          <Button danger icon={<DeleteOutlined />}>
            {t('buttons.delete')}
          </Button>
        </Popconfirm>
      )}
      <Link
        to={{
          pathname: `/custom_accounting/invoices/accounting-codes/${datas._id}`,
          state: datas
        }}
        style={{ float: 'right' }}
      >
        <Button type="primary" icon={<EditOutlined />}>
          {t('invoices.action.accounting_code')}
        </Button>
      </Link>
    </Space>
  );
  return buttons;
};

export default useButtons;
