import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Divider } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useContext from '../AccountingContext';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sites, setSites] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrdersSupplies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/orders-supplies` });
      setOrders(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
      await getOrdersSupplies();
    })();
  }, []);
  const downloadFile = async (fileId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${fileId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return [
    {
      title: t('ordersPurchases.supplier'),
      dataIndex: 'supplier'
    },
    {
      title: t('ordersPurchases.site'),
      dataIndex: 'site',
      render: (site) => site?.label,
      sorter: true
      // filters: sites && sites?.map(c => ({
      //       text: c.label, value: c._id
      //     }))
    },

    {
      title: t('ordersPurchases.customer'),
      dataIndex: 'customer',
      render: (customer) =>
        customer ? `${customer?.first_name || ''} ${customer?.last_name}` : '',
      sorter: true
    },
    {
      title: t('ordersPurchases.profesional'),
      dataIndex: 'profesional',
      render: (profesional) => (profesional ? profesional?.name : ''),
      sorter: true
    },
    {
      title: t('ordersPurchases.product'),
      dataIndex: 'supplies',
      render: (supplies) => supplies.length,
      sorter: true
    },
    {
      title: t('ordersPurchases.order_date'),
      dataIndex: 'order_date',
      render: (order_date) =>
        order_date ? moment(order_date).format('DD/MM/YYYY') : 'N/R',
      sorter: true
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: (data, { key }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/custom_accounting/orders_supplies/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>

            <Divider type="vertical" />
            {data.files.length !== 0 && (
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={async () => {
                  if (!isDownloading[key]) {
                    setIsDownloading({ ...isDownloading, [key]: true });
                    await downloadFile(
                      data.files[0]._id,
                      data.files[0].filename
                    );
                  }
                  setIsDownloading({ ...isDownloading, [key]: false });
                }}
              >
                {isDownloading[key] ? (
                  <LoadingOutlined style={iconStyle} />
                ) : (
                  <DownloadOutlined style={iconStyle} />
                )}
              </Button>
            )}
          </span>
        );
      }
    }
  ];
};

export default useColumns;
