import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import Deals from './Deals/Deals';
import ShowDeal from './Deals/Show/ShowDeal';
import ProfesionalRouter from '../Profesionals/ProfesionalRouter';
import BuildingSitesRouter from '../BuildingSites/BuildingSitesRouter';
import CustomersRouter from '../Customers/CustomerRouter';
import CommissionRouter from '../Commissions/CommissionRouter';

const CommercialRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.COMMERCIAL.PROFESIONALS}`}
        component={ProfesionalRouter}
      />
      <Route
        path={`${path}${subRoutes.COMMERCIAL.COMMISSION}`}
        component={CommissionRouter}
      />
      <Route
        path={`${path}${subRoutes.COMMERCIAL.CUSTOMERS}`}
        component={CustomersRouter}
      />
      <Route
        path={`${path}${subRoutes.COMMERCIAL.BUILDING_SITES}`}
        component={BuildingSitesRouter}
      />
      <Route
        path={`${path}${subRoutes.COMMERCIAL.DEALS}/show/:id`}
        component={ShowDeal}
      />
      <Route path={`${path}${subRoutes.COMMERCIAL.DEALS}`} component={Deals} />
    </Switch>
  );
};

export default CommercialRouter;
