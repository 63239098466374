import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  EyeOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import columns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const iconSize = 18;
const ListQuotations = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { pathname } = location;
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = async (key) => {
    try {
      await dispatchAPI('DELETE', { url: `extraquotations/${key}` });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, status, number, ...rest }) => {
        return (
          <span>
            <Link
              to={{
                pathname: `${pathname}/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => onDelete(key)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  // const generateNewQuotation = (
  //   <Link to={`${pathname}/create`}>
  //     <Button type="primary">
  //       <PlusCircleOutlined /> NOUVELLE DEMANDE DE DEVIS
  //     </Button>
  //   </Link>
  // );
  return (
    <SimpleResourceLandingLayout
      withCreateButton={false}
      resourceName="extraquotations"
      columns={[...columns(t), ...actionColumn]}
      customActionColumn
      forceRefresh={forceRefresh}
      // extraHeader={generateNewQuotation}
    />
  );
};

export default ListQuotations;
