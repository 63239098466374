import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import CoefTable from './coefTable';

const CoefSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [coefs, setCoefs] = useState([]);

  const fetchCoefs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-coefs`
      });
      setCoefs(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addCoef = async () => {
    const body = {
      label: 'Nouveau',
      coef: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-coefs', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchCoefs();
  };

  const deleteCoef = async (coef) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-coefs/${coef._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.promotion_delete'));
    }
    fetchCoefs();
  };

  const editCoef = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-coefs/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchCoefs();
  };

  useEffect(() => {
    fetchCoefs();
  }, []);

  return (
    <>
      {coefs.length > 0 && (
        <CoefTable coef={coefs} editCoef={editCoef} deleteCoef={deleteCoef} />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addCoef}
      >
        {t('settings.coef.addCoef')}
      </Button>
    </>
  );
};

export default CoefSettings;
