/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Tabs, Input, Button, Select, Radio, Popconfirm } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { PlusOutlined } from '@ant-design/icons';
import { CloseIcon } from '../../../utils/constants/customIcon';
import SizesForm from './SizesForm';
import useConfiguratorContext from './ConfiguratorContext';

const { TextArea } = Input;
const { TabPane } = Tabs;
const Content = styled.div`
  margin-bottom: 40px;
  position: relative;
`;

const projectTypes = [
  'Agencement',
  'Cuisine',
  'Dallage',
  'Funéraire',
  'Salle de bain',
  'Autres'
];

const SectionForm = ({ name, choice }) => {
  const {
    datas,
    t,
    form,
    currentStep,
    activeKey,
    setActiveKey
  } = useConfiguratorContext();
  const { matters } = datas;
  const [catalogue, setCatalogue] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (currentStep === 1) {
      const values = form.getFieldsValue();
      Object.keys(values).forEach((key) => {
        if (key === name) {
          if (!values[key].elements) {
            return;
          }

          const newFilters = [];
          values[key].elements.forEach((el, position) => {
            const matterColorLabel = matters?.find(
              ({ _id }) => _id === el?.color
            )?.color;

            const currentFilter = {
              label: el?.matter,
              color: matterColorLabel,
              shaping: el?.shaping
            };
            newFilters[position] = currentFilter;
          });
          setFilters(newFilters);
        }
      });
    }
  }, [currentStep, name, datas]);

  useEffect(() => {
    const copy = [];
    if (choice === 'DY') {
      (matters || []).forEach((el) => {
        if (el.ref.slice(-2) === 'DY') {
          copy.push(el);
        }
      });
    } else {
      (matters || []).forEach((el) => {
        if (el.ref.slice(-2) !== 'DY') {
          copy.push(el);
        }
      });
    }
    setCatalogue(copy);
  }, [choice, datas]);

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          className="site-custom-tab-bar"
          style={{ ...style }}
        />
      )}
    </Sticky>
  );

  const onSelectMatter = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              color: undefined,
              shaping: undefined,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    const newFilters = filters;
    newFilters[index] = { label: value };

    setFilters(newFilters);
  };

  const onSelectColor = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              shaping: undefined,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    setFilters(
      filters.map((filter, findex) => {
        if (findex === index) {
          return { ...filter, color: value.label };
        }
        return filter;
      })
    );
  };

  const onSelectShaping = (value, index) => {
    const values = form.getFieldsValue();
    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              thikness: undefined,
              unit_price: undefined
            };
          }
        });
      }
    });
    form.setFieldsValue(values);

    setFilters(
      filters.map((filter, findex) => {
        if (findex === index) {
          return { ...filter, shaping: value };
        }
        return filter;
      })
    );
  };
  const onSelectThickness = (value, index) => {
    const values = form.getFieldsValue();

    const matterPrice = matters
      ?.find(({ _id }) => _id === filters[index].shaping)
      ?.pricing?.find(({ size }) => size === value)?.buy_price;

    Object.keys(values).forEach((key) => {
      if (key === name) {
        values[key].elements.forEach((el, position) => {
          if (index === position) {
            values[key].elements[position] = {
              ...el,
              unit_price: matterPrice
            };
          }
        });
      }
    });
    form.setFieldsValue(values);
  };

  return (
    <>
      <div className="quotation-form-fields-wrapper">
        <Form.Item
          label={t('configurator.project_type')}
          name={[name, 'project_type']}
          rules={[{ required: true }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
        >
          <Select
            allowClear
            options={projectTypes.map((item) => ({
              label: item,
              value: item
            }))}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.description')}
          name={[name, 'description']}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
      </div>
      <br />
      <Form.List name={[name, 'elements']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((item, index) => {
              return (
                <Content className="quotation-form-fields-wrapper">
                  <StickyContainer>
                    <Tabs
                      renderTabBar={renderTabBar}
                      activeKey={activeKey}
                      onChange={(key) => setActiveKey(key)}
                    >
                      <TabPane tab={t('configurator.matter')} key="1">
                        <Form.Item
                          label={t('configurator.is_credence')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'is_credence']}
                          fieldKey={[item.fieldKey, 'is_credence']}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <Radio value={false}>Plan</Radio>
                            <Radio value>Crédence</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label={t('configurator.matter')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'matter']}
                          fieldKey={[item.fieldKey, 'matter']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            options={[
                              ...new Set(catalogue?.map(({ label }) => label))
                            ].map((label) => ({
                              label,
                              value: label
                            }))}
                            onChange={(value) => {
                              onSelectMatter(value, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('configurator.color')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'color']}
                          fieldKey={[item.fieldKey, 'color']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="label"
                            options={catalogue
                              ?.filter(({ label }) =>
                                !(filters[index] && filters[index].label)
                                  ? false
                                  : label === filters[index].label
                              )
                              ?.filter(
                                (el, pos, array) =>
                                  array.findIndex(
                                    (element) => element.color === el.color
                                  ) === pos
                              )
                              .map(({ color, _id }) => ({
                                label: color,
                                value: _id
                              }))}
                            onChange={(_, values) => {
                              onSelectColor(values, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('configurator.shaping')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'shaping']}
                          fieldKey={[item.fieldKey, 'shaping']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="label"
                            options={catalogue
                              ?.filter(({ color }) =>
                                !(filters[index] && filters[index].color)
                                  ? false
                                  : color === filters[index].color
                              )
                              .map(({ completions, _id }) => ({
                                label: completions || 'Aucune',
                                value: _id
                              }))}
                            onChange={(value) => {
                              onSelectShaping(value, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('configurator.thikness')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'thikness']}
                          fieldKey={[item.fieldKey, 'thikness']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="label"
                            options={
                              !filters[index]
                                ? false
                                : catalogue
                                    ?.find(
                                      ({ _id }) =>
                                        _id === filters[index].shaping
                                    )
                                    ?.pricing?.map(({ size }) => ({
                                      label: size,
                                      value: size
                                    }))
                            }
                            onChange={(value) => {
                              onSelectThickness(value, index);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          hidden
                          name={[item.name, 'unit_price']}
                          fieldKey={[item.fieldKey, 'unit_price']}
                          rules={[{ required: true }]}
                        >
                          <TextArea />
                        </Form.Item>
                        <Form.Item
                          label={t('configurator.matter_desc')}
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          name={[item.name, 'matter_desc']}
                          fieldKey={[item.fieldKey, 'matter_desc']}
                          rules={[{ required: true }]}
                        >
                          <TextArea />
                        </Form.Item>
                      </TabPane>
                      <TabPane tab={t('configurator.sizes')} key="2">
                        <SizesForm
                          name={[item?.name, 'sizes']}
                          sectionId={name}
                          value={choice}
                        />
                      </TabPane>
                    </Tabs>
                  </StickyContainer>
                  <Button
                    type="link"
                    onClick={() => {
                      remove(item.name);

                      const newFilters = filters.filter(
                        (val, ind) => ind !== item.name
                      );

                      setFilters([...newFilters]);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Content>
              );
            })}
            <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
              {fields.length > 0 ? (
                <Popconfirm
                  title="Voulez-vous dupliquer l&#39;élément précédent ?"
                  okText="Dupliquer"
                  okButtonProps={{ type: 'primary' }}
                  cancelText="Ne pas dupliquer"
                  onCancel={() => add()}
                  onConfirm={() => {
                    add();
                    const values = form.getFieldsValue();
                    const index = values[name].elements.length - 1;
                    if (values[name].elements.length > 0) {
                      values[name].elements[index] = {
                        ...values[name].elements[index - 1]
                      };
                      delete values[name].elements[index]._id;
                      form.setFieldsValue(values);
                      Object.keys(values).forEach((key) => {
                        if (key === name) {
                          if (!values[key].elements) {
                            return;
                          }

                          const newFilters = [];
                          values[key].elements.forEach((el, position) => {
                            const matterColorLabel = matters?.find(
                              ({ _id }) => _id === el?.color
                            )?.color;

                            const currentFilter = {
                              label: el.matter,
                              color: matterColorLabel,
                              shaping: el.shaping
                            };
                            newFilters[position] = currentFilter;
                          });
                          setFilters(newFilters);
                        }
                      });
                    }
                  }}
                >
                  <Button
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                    className="quotation-add-btn"
                  >
                    {t('configurator.add_matter')}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  className="quotation-add-btn"
                >
                  {t('configurator.add_matter')}
                </Button>
              )}
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

SectionForm.propTypes = {
  name: PropTypes.string.isRequired,
  choice: PropTypes.string
};

SectionForm.defaultProps = {
  choice: ''
};

export default SectionForm;
