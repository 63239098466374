import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Table,
  Divider,
  Space,
  Tag,
  Button,
  Typography,
  Modal,
  Form,
  Select,
  DatePicker
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  LoadingOutlined,
  DownloadOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const RemovalOrders = ({ order }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [datas, setDatas] = useState({});
  const [signedOrders, setSignedOrders] = useState([]);
  const [refresh] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [worksheets, setWorksheets] = useState([]);
  const [template, setTemplate] = useState({});
  const [matters, setMatters] = useState([]);

  const { Title } = Typography;

  const getDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/removal-order?order=${order._id}&populate=order,worksheet,worksheet.customer,order.products.product,order.section_1.elements.color`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const workSheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?order=${order._id}&populate=order`
      });
      setWorksheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSignedRemovalOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/signed-removal-order?order=${order._id}&populate=file`
      });
      setSignedOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/files?type=MANUAL_REMOVAL_DELIVERY_ORDER'
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getDatas();
    getSignedRemovalOrders();
    workSheets();
    getTemplate();
  }, [order, refresh]);

  const downloadFile = async (id, removalOrderId) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/manual-removal-delivery-order/${id}/${template._id}?type=removal&typeId=${removalOrderId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location = url;
      setIsDownloading({
        ...isDownloading,
        [removalOrderId]: false
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onFinish = async (values) => {
    try {
      const workSheetIndex = worksheets.findIndex(
        (el) => el._id === values.worksheet
      );

      await dispatchAPI('POST', {
        url: '/removal-order',
        body: {
          ...values,
          number: `${order.number}-BE${datas.length + 1}`,
          order: order._id,
          numberWorkSheet: `${order.number}-FT${workSheetIndex + 1}`,
          is_delivery: false
        }
      });
      getDatas();
      setIsModalVisible(false);
      form.resetFields();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t('removal-orders.form.file_name'),
      key: 'number',
      dataIndex: 'number'
    },
    {
      title: t('removal-orders.form.associated_worksheet'),
      dataIndex: 'numberWorkSheet'
    },
    {
      title: t('removal-orders.form.creation_date'),
      dataIndex: 'created_at',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: t('removal-orders.form.removal_date'),
      dataIndex: 'date',
      render: (el) =>
        el
          ? moment(el).format('DD-MM-YYYY')
          : t('removal-orders.form.not_planned')
    },
    {
      title: 'Action',
      render: (removalOrder) => (
        <Space>
          <Button disabled={isDownloading[removalOrder._id]}>
            {isDownloading[removalOrder._id] ? (
              <LoadingOutlined />
            ) : (
              <DownloadOutlined
                onClick={() => {
                  setIsDownloading({
                    ...isDownloading,
                    [removalOrder._id]: true
                  });
                  downloadFile(removalOrder.worksheet._id, removalOrder._id);
                }}
              />
            )}
          </Button>
        </Space>
      )
    }
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsModalVisible(true);
        }}
        // icon={<CreditCardOutlined />}
        style={{ marginBottom: 20 }}
      >
        {t('orders.show.buttons.add_removal_order')}
      </Button>
      {Object.keys(datas).length ? (
        <div>
          <Title level={4}>Bons d&apos;enlèvement</Title>
          <Table dataSource={datas} columns={columns} pagination={false} />
        </div>
      ) : null}
      <Modal
        title={t('removal-orders.modals.titles.add_removal_order')}
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label={t('orders.show.worksheet')}
            name="worksheet"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner la fiche de travail associée'
              }
            ]}
          >
            <Select onChange={(_, options) => setMatters(options.matters)}>
              {(worksheets || []).map((worksheet, index) => (
                <Option
                  key={worksheet._id}
                  value={worksheet._id}
                  matters={worksheet.matter}
                >
                  {`${order?.number}-FT${index + 1}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('orders.show.matters')} name="matters">
            <Select mode="multiple">
              {(matters || []).map((matter, index) => (
                <Option key={matter._id} value={matter._id}>
                  {`Matière ${index + 1} : ${matter?.shaping?.color}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('orders.show.date')} name="date">
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('orders.show.buttons.create_removal_order')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

RemovalOrders.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.any
  }).isRequired
};

export default RemovalOrders;
