import React from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import WorkSheets from './index';
import { WorkSheetsContextProvider } from './WorkSheetContext';
import useAuthContext from '../../contexts/AuthContext';

const WorkSheetRouter = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { canEditResource } = useAuthContext();

  return (
    <Switch>
      <WorkSheetsContextProvider>
        <Route
          path={`${path}`}
          component={canEditResource(path) ? WorkSheets : history.goBack()}
        />
      </WorkSheetsContextProvider>
    </Switch>
  );
};

export default WorkSheetRouter;
