import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import TvaTable from './tvaTable';

const TvaSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [tva, setTva] = useState([]);

  const fetchTva = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-tva`
      });
      setTva(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addTva = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-tva', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchTva();
  };

  const deleteTva = async (deletedTva) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-tva/${deletedTva._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchTva();
  };

  const editTva = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-tva/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchTva();
  };

  useEffect(() => {
    fetchTva();
  }, []);

  return (
    <>
      {tva.length > 0 && (
        <TvaTable tva={tva} editTva={editTva} deleteTva={deleteTva} />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addTva}
      >
        {t('settings.tva.addTva')}
      </Button>
    </>
  );
};

export default TvaSettings;
