import React from 'react';
import { Tag, Button, Popconfirm, Menu, Dropdown } from 'antd';
import { FlagTwoTone, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Options from './Options';
import filters from './filters';

const invoicesMenu = (invoices) => (
  <Menu>
    {invoices.map((invoice) => (
      <Menu.Item>
        <Link to={`/invoices/show/${invoice._id}`}>{invoice.number}</Link>
      </Menu.Item>
    ))}
  </Menu>
);

const expensesMenu = (expenses) => (
  <Menu>
    {expenses.map((expense) => (
      <Menu.Item>
        <Link to={`/expenses/show/${expense._id}`}>{expense.wording}</Link>
      </Menu.Item>
    ))}
  </Menu>
);

const renderStatusCell = (t, status) => {
  let cell = <FlagTwoTone />;
  if (status) {
    cell = (
      <>
        <CheckOutlined style={{ color: 'green' }} />
        &nbsp;
        {status.length === 1 && status[0] && status[0].number && (
          <Link to={`/invoices/show/${status[0]._id}`}>{status[0].number}</Link>
        )}
        {status.length === 1 && status[0] && (
          <Link to={`/expenses/show/${status[0]._id}`}>
            {status[0].wording}
          </Link>
        )}
        {status.length > 1 && status[0] && status[0].number && (
          <Dropdown overlay={() => invoicesMenu(status)}>
            <span>
              {t('reconciliations.datatable.invoices-count', {
                count: status.length
              })}
            </span>
          </Dropdown>
        )}
        {status.length > 1 && status[0] && status[0].amount_ht && (
          <Dropdown overlay={() => expensesMenu(status)}>
            <span>
              {t('reconciliations.datatable.expenses-count', {
                count: status.length
              })}
            </span>
          </Dropdown>
        )}
      </>
    );
  }
  return cell;
};

const renderDirectReconciliationCell = (
  t,
  dataIndex,
  key,
  invoices,
  expenses,
  postReconciliation
) => {
  const value = key.value ? key.value : key.original_value;
  if (value && !key.status) {
    if (value > 0) {
      const matchingInvoice = invoices.find((i) => i.total === value);

      if (
        matchingInvoice &&
        (!matchingInvoice.total_paid ||
          (matchingInvoice.total_paid &&
            matchingInvoice.total_paid !== matchingInvoice.total))
      ) {
        return (
          <>
            <Link to={`/invoices/show/${matchingInvoice._id}`}>
              {matchingInvoice.number}
            </Link>
            &nbsp;
            <Popconfirm
              title={t('reconciliations.confirm.title.invoice', {
                invoice: matchingInvoice.number
              })}
              onConfirm={() => {
                postReconciliation(key, matchingInvoice._id);
              }}
              okText={t('reconciliations.confirm.confirm')}
              cancelText={t('reconciliations.confirm.cancel')}
              okButtonProps={{ type: 'add' }}
            >
              <Button type="primary">
                {t('reconciliations.confirm.confirm')}
              </Button>
            </Popconfirm>
          </>
        );
      }
      return t('reconciliations.no-match');
    }
    const matchingExpense = expenses.find((i) => i.amount_ht === value);
    const matchingCredit = invoices.find(
      (i) => Math.abs(i.total) === Math.abs(value) && i.type === 'CREDIT'
    );

    if (matchingExpense && !matchingExpense.is_reconciled) {
      return (
        <>
          <Link to={`/expenses/show/${matchingExpense._id}`}>
            {matchingExpense.wording}
          </Link>
          &nbsp;
          <Popconfirm
            title={t('reconciliations.confirm.title.expense', {
              expense: matchingExpense.wording
            })}
            onConfirm={() => {
              postReconciliation(key, matchingExpense._id, 'expenses');
            }}
            okText={t('reconciliations.confirm.confirm')}
            cancelText={t('reconciliations.confirm.cancel')}
            okButtonProps={{ type: 'add' }}
          >
            <Button type="primary">
              {t('reconciliations.confirm.confirm')}
            </Button>
          </Popconfirm>
        </>
      );
    }
    if (
      matchingCredit &&
      (!matchingCredit.total_paid ||
        (matchingCredit.total_paid &&
          matchingCredit.total_paid !== matchingCredit.total))
    ) {
      return (
        <>
          <Link to={`/invoices/show/${matchingCredit._id}`}>
            {matchingCredit.number}
          </Link>
          &nbsp;
          <Popconfirm
            title={t('reconciliations.confirm.title.invoice', {
              invoice: matchingCredit.number
            })}
            onConfirm={() => {
              postReconciliation(key, matchingCredit._id, 'invoices');
            }}
            okText={t('reconciliations.confirm.confirm')}
            cancelText={t('reconciliations.confirm.cancel')}
            okButtonProps={{ type: 'add' }}
          >
            <Button type="primary">
              {t('reconciliations.confirm.confirm')}
            </Button>
          </Popconfirm>
        </>
      );
    }
    return (
      <Link
        to={`/expenses/create?transaction=${key.id}&amount_ht=${Math.abs(
          value
        )}&date=${key.date}`}
      >
        <Button>
          {t('reconciliations.actions.create')}&nbsp;
          <PlusOutlined />
        </Button>
      </Link>
    );
  }
};

const Columns = (t, accounts, invoices, expenses, postReconciliation) => [
  {
    title: t('reconciliations.datatable.status'),
    key: 'status',
    dataIndex: 'status',
    sorter: (a, b) => (a.status && !b.status ? 1 : -1),
    render: (status) => renderStatusCell(t, status)
  },
  {
    title: t('reconciliations.datatable.date'),
    key: 'date',
    dataIndex: 'date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: (date) => new Date(date).toLocaleDateString('fr-FR')
  },
  {
    title: t('reconciliations.datatable.account'),
    key: 'account',
    dataIndex: 'id_account',
    render: (id_account) => {
      const account = accounts.find((a) => a.id === id_account);
      return <Tag color={`#${account.connector.color}`}>{account.name}</Tag>;
    }
  },
  {
    title: t('reconciliations.datatable.type'),
    key: 'type',
    dataIndex: 'type',
    filters: filters(t),
    onFilter: (value, key) => key.type.indexOf(value) === 0,
    render: (type) => <Tag>{t(`reconciliations.types.${type}`)}</Tag>
  },
  {
    title: t('reconciliations.datatable.wording'),
    key: 'original_wording',
    dataIndex: 'original_wording'
  },
  {
    title: t('reconciliations.datatable.value'),
    key: 'value',
    dataIndex: 'value',
    sorter: (a, b) => {
      const valueA = a.value ? a.value : a.original_value;
      const valueB = b.value ? b.value : b.original_value;
      return valueA - valueB;
    },
    render: (value, key) => {
      return value ? (
        <span
          style={{
            color: parseFloat(value) > 0 ? 'green' : 'red'
          }}
        >
          {`${value}€`}
        </span>
      ) : (
        <span style={{ color: parseFloat(value) > 0 ? 'green' : 'red' }}>
          {key.original_value ? `${key.original_value}€` : ''}
        </span>
      );
    }
  },
  {
    title: t('reconciliations.datatable.match'),
    key: 'direct-reconciliation',
    render: (dataIndex, key) =>
      renderDirectReconciliationCell(
        t,
        dataIndex,
        key,
        invoices,
        expenses,
        postReconciliation
      )
  },
  {
    key: 'options',
    render: (dataIndex, key) =>
      (key.value || key.original_value) &&
      !key.status &&
      Options(key, t, invoices, expenses, postReconciliation)
  }
];

export default Columns;
