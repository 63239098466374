const headers = [
  {
    label: 'Numéro',
    key: 'number'
  },
  {
    label: 'Statut',
    key: 'status'
  },
  {
    label: 'Site de demande',
    key: 'infos.commercial_site.label'
  },
  {
    label: 'Site de fabrication',
    key: 'infos.production_site.label'
  },
  {
    label: 'Date de création',
    key: 'created_at'
  },
  {
    label: 'Date prév. de chantier',
    key: 'estimated_construction_date'
  },
  {
    label: 'Client',
    key: 'infos.customer.last_name'
  },
  {
    label: 'Montant (€ HT)',
    key: 'totals.totalClient'
  }
];

export default headers;
