import PropTypes from 'prop-types';
import React from 'react';
import { Button, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const style = {
  background: 'black',
  color: 'white',
  border: 'black',
  margin: '3px'
};

const OptionsRow = ({ setExtraQuery }) => {
  const handleClick = (e) => {
    setExtraQuery(e);
  };

  return (
    <>
      <Button
        style={style}
        onClick={() => handleClick('site=6040a54cbeaca41868d02fdf')}
      >
        TERRASSON
      </Button>
      <Button
        style={style}
        onClick={() => handleClick('site=6040d45c142fa8042c21889f')}
      >
        PESSAC
      </Button>
      <Button style={style} onClick={() => handleClick('')}>
        ...TOUT
      </Button>
    </>
  );
};

OptionsRow.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};

export default OptionsRow;
