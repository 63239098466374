import React, { useEffect, useState } from 'react';
import { Input, InputNumber, Select, Switch } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContactsForm from './contactsForm';

const { Option } = Select;

const useFields = (form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [city, setCity] = useState([]);
  const [customersCodes, setCustomersCodes] = useState([]);
  const [settlementConditions, setSettlementConditions] = useState([]);
  const [enums, setEnums] = useState({});
  const [isLocalAddress, setIsLocalAddress] = useState(true);

  useEffect(() => {
    const addressBoolean = form.getFieldValue(['local_address']);
    if (![undefined, null, 'undefined', 'null'].includes(addressBoolean)) {
      setIsLocalAddress(addressBoolean);
    }
  }, [form]);

  const getCities = async (code) => {
    try {
      const { data } = await axios.get(
        `https://geo.api.gouv.fr/departements/${code.slice(0, 2)}/communes`
      );
      let cities = data.filter((item) =>
        item.codesPostaux.map((el) => el === code).includes(true)
      );
      if (data.length !== 0 && cities.length === 0) {
        cities = data.filter((item) =>
          item.codesPostaux.map((el) => el === code).includes(false)
        );
      }

      const nameCities = cities.map((item) => item.nom);
      setCity(nameCities);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const basicFields = [
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={(sites || []).map((item) => ({
            label: `${item.label}`,
            value: item._id
          }))}
          showSearch
          optionFilterProp="label"
        />
      )
    },
    {
      name: ['name'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['type'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={(enums.type || []).map((item) => ({
            label: `${item}`,
            value: item
          }))}
          showSearch
          optionFilterProp="label"
        />
      )
    },
    {
      label: 'channel_number',
      name: ['address', 'number']
    },
    {
      label: 'street',
      name: ['address', 'street'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'local_address',
      name: ['local_address'],
      valuePropName: 'checked',
      input: <Switch onChange={(e) => setIsLocalAddress(e)} />
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      input: (
        <Input
          type="number"
          onChange={(val) => {
            if (val.target.value.length === 5) {
              getCities(val.target.value);
            } else {
              setCity([]);
            }
          }}
        />
      ),
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'city',
      name: ['address', 'city'],
      input: isLocalAddress ? (
        <Select allowClear showSearch>
          {(city || []).map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      ) : (
        <Input />
      ),
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['siren_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['ape_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'email',
      name: ['email']
    },
    {
      name: ['phone_number']
    },
    {
      label: 'reglement',
      name: ['reglement']
    },
    {
      name: ['commission'],
      input: <InputNumber />
    },
    {
      name: ['customer_code'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          allowClear
          showSearch
          options={customersCodes}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      )
    },
    {
      name: ['settlement_condition'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          allowClear
          showSearch
          options={settlementConditions}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      )
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <ContactsForm />
    }
  ];

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/profesionals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerCodes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-customers-codes'
      });
      const options = data
        .filter((item) => item.nomenclature || item.title)
        .map((item) => {
          return {
            label: `${item.nomenclature} - ${item.title}`,
            key: item._id,
            value: item.title
          };
        });
      setCustomersCodes(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettlementConditions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-settlement-conditions'
      });
      const options = data.map((item) => {
        return {
          label: item.label,
          key: item._id,
          value: item._id
        };
      });
      setSettlementConditions(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
      await getEnums();
      await getCustomerCodes();
      await getSettlementConditions();
      setIsFieldsLoading(false);
    })();
  }, []);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
