import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import StripeModale from './PaymentStripeModale';

const PaymentStripe = ({ btnStyle, amount }) => {
  const { t } = useTranslation();
  const [modalOpen, setmodalOpen] = useState(false);

  return (
    <>
      <Row justify="center">
        <Button
          type="primary"
          style={btnStyle}
          onClick={() => setmodalOpen(true)}
        >
          {t('payment.buttons.stripe')} <CheckOutlined />
        </Button>
      </Row>
      <StripeModale
        amount={amount}
        visible={modalOpen}
        setmodalOpen={setmodalOpen}
      />
    </>
  );
};

PaymentStripe.propTypes = {
  amount: PropTypes.number.isRequired,
  btnStyle: PropTypes.string.isRequired
};

export default PaymentStripe;
