const filters = (t) => [
  {
    value: 'transfer',
    text: t(`reconciliations.types.transfer`)
  },
  {
    value: 'order',
    text: t(`reconciliations.types.order`)
  },
  {
    value: 'check',
    text: t(`reconciliations.types.check`)
  },
  {
    value: 'deposit',
    text: t(`reconciliations.types.deposit`)
  },
  {
    value: 'payback',
    text: t(`reconciliations.types.payback`)
  },
  {
    value: 'withdrawal',
    text: t(`reconciliations.types.withdrawal`)
  },
  {
    value: 'loan_payment',
    text: t(`reconciliations.types.loan_payment`)
  },
  {
    value: 'bank',
    text: t(`reconciliations.types.bank`)
  },
  {
    value: 'card',
    text: t(`reconciliations.types.card`)
  },
  {
    value: 'deferred_card',
    text: t(`reconciliations.types.deferred_card`)
  },
  {
    value: 'summary_card',
    text: t(`reconciliations.types.summary_card`)
  }
];

export default filters;
