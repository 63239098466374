import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';

const AccountingCodesContext = createContext({});

export const AccountingCodesContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [accountingCodes, setAccountingCodes] = useState([]);
  const [settingCoef, setSettingCoef] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const fetchSettingCoef = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-coefs'
      });
      setSettingCoef(data);
      setRefresh(!refresh);
      setLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchAccountingCodes = async () => {
    let options = [];
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-sales-accounting-codes'
      });

      options = data.map((op) => {
        const newOp = {
          label: op.title,
          value: op._id,
          code: op.code,
          key: op._id
        };
        return newOp;
      });

      setAccountingCodes(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateInvoice = async (id, body) => {
    setLoading(true);
    const finalBody = {
      is_accounting_code: true,
      accounting_code: body
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/invoices/${id}`,
        body: finalBody
      });
      setRefresh(!refresh);
      setLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <AccountingCodesContext.Provider
      value={{
        fetchSettingCoef,
        fetchAccountingCodes,
        accountingCodes,
        settingCoef,
        loading,
        updateInvoice,
        isEdit,
        setIsEdit
      }}
    >
      {children}
    </AccountingCodesContext.Provider>
  );
};

AccountingCodesContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(AccountingCodesContext);
