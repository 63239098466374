import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import { mattersLabels } from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [labelsFilter, setLabelsFilter] = useState(null);

  const fetchLabels = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'matters/enums' });
      setLabelsFilter(
        data.labels.map((label) => ({
          text: (
            <Tag color={mattersLabels[label.toUpperCase()]}>
              {label.toUpperCase()}
            </Tag>
          ),
          value: label
        }))
      );
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchLabels();
    })();
  }, [fetchLabels]);

  return [
    {
      title: t('catalogues.column.ref'),
      key: 'ref',
      dataIndex: 'ref',
      sorter: true
    },
    {
      title: t('catalogues.column.category'),
      key: 'label',
      dataIndex: 'label',
      sorter: true,
      render: (label) =>
        label && (
          <Tag color={mattersLabels[label.toUpperCase()]}>
            {label.toUpperCase()}
          </Tag>
        ),
      filters: labelsFilter
    },
    {
      title: t('catalogues.column.label'),
      key: 'color',
      dataIndex: 'color',
      sorter: true
    },
    {
      title: t('catalogues.column.completions'),
      key: 'completions',
      dataIndex: 'completions',
      sorter: true
    },
    {
      title: t('catalogues.column.prix0_6'),
      key: 'pricing',
      dataIndex: 'pricing',
      render: (pricing) => {
        let indexEl = null;
        pricing.forEach((element, index) => {
          if (element?.size === '0.6') indexEl = index;
        });
        if (indexEl !== null)
          return `${numeral(pricing[indexEl]?.buy_price).format(
            '0,0.00'
          )} € HT`;
        return '--';
      },
      sorter: true
    },
    {
      title: t('catalogues.column.prix0_8'),
      key: 'pricing',
      dataIndex: 'pricing',
      render: (pricing) => {
        let indexEl = null;
        pricing.forEach((element, index) => {
          if (element?.size === '0.8') indexEl = index;
        });
        if (indexEl !== null)
          return `${numeral(pricing[indexEl]?.buy_price).format(
            '0,0.00'
          )} € HT`;
        return '--';
      },
      sorter: true
    },
    {
      title: t('catalogues.column.prix1_2'),
      key: 'pricing',
      dataIndex: 'pricing',
      render: (pricing) => {
        let indexEl = null;
        pricing.forEach((element, index) => {
          if (element?.size === '1.2') indexEl = index;
        });
        if (indexEl !== null)
          return `${numeral(pricing[indexEl]?.buy_price).format(
            '0,0.00'
          )} € HT`;
        return '--';
      },
      sorter: true
    },
    {
      title: t('catalogues.column.prix2'),
      key: 'pricing',
      dataIndex: 'pricing',
      render: (pricing) => {
        let indexEl = null;
        pricing.forEach((element, index) => {
          if (element?.size === '2') indexEl = index;
        });
        if (indexEl !== null)
          return `${numeral(pricing[indexEl]?.buy_price).format(
            '0,0.00'
          )} € HT`;
        return '--';
      },
      sorter: true
    },
    {
      title: t('catalogues.column.prix3'),
      key: 'pricing',
      dataIndex: 'pricing',
      render: (pricing) => {
        let indexEl = null;
        pricing.forEach((element, index) => {
          if (element?.size === '3') indexEl = index;
        });
        if (indexEl !== null)
          return `${numeral(pricing[indexEl]?.buy_price).format(
            '0,0.00'
          )} € HT`;
        return '--';
      },
      sorter: true
    }
  ];
};

export default useColumns;
