import React, { useEffect, useState } from 'react';
import { Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { ExportDatas } from './AccountingCode/Buttons/ExportDatas';
import { headers } from './headers';
import formatter from './AccountingCode/utils';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { RangePicker } = DatePicker;

const ListInvoices = () => {
  const { dispatchAPI } = useAuthContext();
  const { sumByCode, totalColumns } = formatter;
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [extraQuery, setExtraQuery] = useState(null);
  const [enums, setEnums] = useState();
  const [range, setRange] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const [exportDatas, setExportDatas] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/invoices/enums` });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?is_accounting_code=true&${extraQuery || ''}`
      });
      setInvoices(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const finalFormat = (datas, parent) => {
    const { created_at, number, infos, is_credit_note } = parent;
    const result = [];
    datas.forEach(({ code, totalHT, mtTva, type }) => {
      let credit = totalHT;
      let debit = '';
      if (type === 'title-type.tva') credit = mtTva;
      if (type === 'title-type.discount') {
        debit = totalHT;
        credit = '';
      }

      if (is_credit_note) {
        debit = credit;
        credit = '';
      }

      result.push([
        moment(created_at).format('DD/MM/YYYY'),
        infos?.commercial_site?.accounting_journal || 'N/R',
        code,
        number,
        infos.profesional ? infos.profesional.name : infos.customer.last_name,
        debit,
        credit
      ]);
      return null;
    });

    return result;
  };

  const childFormat = (child, parent, except) => {
    const advAndDiscount = ['title-type.discount'];
    const filteredAdvChild = child.filter(({ type }) =>
      advAndDiscount.includes(type)
    );
    const filteredChild = child.filter(
      ({ type }) => !advAndDiscount.includes(type)
    );
    let sumCodes = filteredChild.filter(({ type }) => !except.includes(type));
    let notSumCodes = filteredChild.filter(({ type }) => except.includes(type));

    const mtAdvAndDiscountHT = totalColumns(filteredAdvChild, 'totalHT');

    sumCodes = sumByCode(sumCodes);

    if (mtAdvAndDiscountHT > 0) {
      const newValue = sumCodes[0].totalHT - mtAdvAndDiscountHT;
      sumCodes[0].totalHT = newValue;
    }

    sumCodes = finalFormat(sumCodes, parent);
    notSumCodes = finalFormat(notSumCodes, parent);
    return [...sumCodes, ...notSumCodes];
  };

  const formatDatasCSV = (datas, keys, children, childrenFormatter, except) => {
    const csvData = [];
    let childrens;

    const header = keys.map((key) => t(`${key.label}`));
    csvData.push(header);

    datas.forEach((data) => {
      let rowData = keys.map((key) => {
        const value = data;
        return key.render ? key.render(value) : value;
      });

      if (children) {
        childrens = childrenFormatter(data[children], data, except);
        rowData = [rowData, ...childrens];
        csvData.push(...rowData);
      }
    });

    setExportDatas(csvData);
    return csvData;
  };

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `created_at>=${moment(range[0])
          .startOf('day')
          .toISOString()}&created_at<=${moment(range[1])
          .endOf('day')
          .toISOString()}`
      );
    } else setExtraQuery(null);
  }, [range]);

  useEffect(() => {
    (async () => {
      await getEnums();
      await getInvoices();
    })();
  }, [extraQuery]);

  useEffect(() => {
    formatDatasCSV(invoices, headers, 'accounting_code', childFormat, []);
  }, [invoices]);

  return (
    <SimpleResourceLandingLayout
      resourceName="invoices"
      columns={columns(enums)}
      extraQuery={extraQuery}
      populate="infos.customer infos.profesional.site,order,profesional,user"
      customActionColumn
      withCreateButton={false}
      withUploadButton={false}
      withSearchPlaceholder={false}
      extraHeader={<ExportDatas exportDatas={exportDatas} />}
    >
      <Col>
        <RangePicker value={range} onChange={(dates) => setRange(dates)} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListInvoices;
