/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  InputNumber,
  Form,
  Input,
  Select,
  message as stop
} from 'antd';
import { TextArea } from 'antd/lib/input/TextArea';
import { CreditCardOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const InvoicesModal = ({ setForceRefresh, forceRefresh, toPayed }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [enums, setEnums] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setForceRefresh(!forceRefresh);
    setIsModalVisible(false);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  };

  const onFinish = async (payed) => {
    if (toPayed < 0) {
      return stop.error('Le montant saisie est supérieur au reste à charge !');
    }
    const status = toPayed === payed.amount ? 'PAYED' : 'PENDING';
    const body = {
      payed,
      status2: status
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${id}`,
        body
      });
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?order=${id}`
      });
      const invoiceId = data[0]._id;
      const body2 = {
        payed: body.payed,
        status
      };
      await dispatchAPI('PATCH', {
        url: `/invoices/${invoiceId}`,
        body: body2
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    handleOk();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      {toPayed && toPayed?.payed.length === 0 ? (
        <Button
          type="primary"
          onClick={showModal}
          icon={<CreditCardOutlined />}
          getContainer={() => (
            <>
              <TextArea />
              <InputNumber />
            </>
          )}
        >
          Acompte
        </Button>
      ) : (
        <div
          style={{
            padding: '5px',
            verticalAlign: 'middle',
            backgroundColor: '#F5F5F5'
          }}
        >
          <h2 style={{ color: '#616161' }}>
            Acompte versé {toPayed && toPayed?.payed[0]?.amount}€
          </h2>
        </div>
      )}

      <Modal
        title="Procéder a un payment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('quotations.show.amount')}
            name="amount"
            rules={[
              {
                message: 'Please input your username!'
              }
            ]}
          >
            <Input type={Number} />
          </Form.Item>

          <Form.Item
            label={t('quotations.show.method')}
            name="method"
            rules={[
              {
                message: 'Please input your username!'
              }
            ]}
          >
            <Select>
              {(enums.methods || []).map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('User')}
            name="user"
            hidden
            initialValue={user._id}
          />

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Validé
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

InvoicesModal.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  toPayed: PropTypes.shape({
    payed: PropTypes.shape({
      length: PropTypes.number
    })
  }).isRequired
};

export default InvoicesModal;
