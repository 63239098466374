import PropTypes from 'prop-types';
import { Modal, Row, Checkbox } from 'antd';
import React, { useState } from 'react';

// Modal that allows to select a few quote using checkboxes on which you want to apply a given function in parameter

const ChangeQuotesStatusModal = ({
  visible,
  onCancel,
  onOk,
  quotes,
  status,
  t
}) => {
  const [checkedQuotes, setCheckedQuotes] = useState([]);

  const handleOk = () => {
    onOk(checkedQuotes, status);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      title={`${t('deals.changeQuotesStatusModal.title')} ${t(
        `deals.changeQuotesStatusModal.${status}`
      )}`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('deals.changeQuotesStatusModal.okText')}
      cancelText={t('deals.changeQuotesStatusModal.cancelText')}
    >
      <div>
        {quotes.map((quote) => (
          <div key={quote.quote._id}>
            <Row>
              <Checkbox
                checked={checkedQuotes.includes(quote.quote._id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckedQuotes([...checkedQuotes, quote.quote._id]);
                  } else {
                    setCheckedQuotes(
                      checkedQuotes.filter((id) => id !== quote.quote._id)
                    );
                  }
                }}
              >
                {quote.quote.number}
              </Checkbox>
            </Row>
          </div>
        ))}
      </div>
    </Modal>
  );
};

ChangeQuotesStatusModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default ChangeQuotesStatusModal;
