const useListContent = ({
  first_name,
  last_name,
  email,
  phone_number_1,
  phone_number_2,
  address,
  details,
  gender,
  site,
  customer_code,
  settlement_condition
}) => {
  return [
    {
      label: 'customers.show.name',
      content: `${gender || ''} ${last_name} ${first_name || ''}`
    },
    {
      label: 'customers.show.email',
      content: email
    },
    {
      label: 'profesionals.show.channel_number',
      content: address ? address.number : '-',
      span: 1
    },
    {
      label: 'profesionals.show.street_number',
      content: address ? address.street : '-',
      span: 1
    },
    {
      label: 'customers.show.postal_code',
      content: address ? address.postal_code : '-'
    },
    {
      label: 'customers.show.city',
      content: address ? address.city : '-'
    },
    {
      label: 'customers.show.additional_information',
      content: address ? address.additional : '-'
    },
    {
      label: 'customers.show.phone_number_1',
      content: phone_number_1 || '-'
    },
    {
      label: 'customers.show.phone_number_2',
      content: phone_number_2 || '-'
    },
    {
      label: 'customers.show.details',
      content: details || '-'
    },
    {
      label: 'customers.show.site',
      content: site ? site?.label : '-'
    },
    {
      label: 'customers.show.customer_code',
      content: customer_code || '-'
    },
    {
      label: 'customers.show.settlement_condition',
      content: settlement_condition?.label || '-'
    }
  ];
};

export default useListContent;
