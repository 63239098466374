import React, { useEffect, useState } from 'react';
import { Button, message, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { BellOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import TrelloCardLabelsTable from './trelloCardLabelsTable';

const { Option } = Select;
const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  },
  {
    title: 'Icône',
    dataIndex: 'icon',
    key: 'icon',
    render: (icon) => (
      <Select InitialValue={icon}>
        <Option value="bell">
          <BellOutlined />
        </Option>
      </Select>
    )
  }
];

const TrelloCardLabelsSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [CardLabel, setCardLabel] = useState(initialColumns);
  const [newKanbanCol, setNewKanbanCol] = useState({
    label: 'Nouvelle colonne',
    color: '#31b3fb'
  });

  const fetchCardLabel = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/cards-labels`
      });
      setCardLabel(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addCardLabel = async () => {
    try {
      await dispatchAPI('POST', { url: '/cards-labels', body: newKanbanCol });
    } catch (e) {
      message.error(e.message);
    }
    fetchCardLabel();
  };

  const deleteCardLabel = async (cardLabel) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/cards-labels/${cardLabel._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.CardLabel'));
    }
    fetchCardLabel();
  };

  const editCardLabel = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/cards-labels/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchCardLabel();
  };

  useEffect(() => {
    fetchCardLabel();
  }, []);
  return (
    <>
      {CardLabel.length > 0 && (
        <TrelloCardLabelsTable
          cardLabel={CardLabel}
          editCardLabel={editCardLabel}
          deleteCardLabel={deleteCardLabel}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addCardLabel}
      >
        {t('settings.trello.addCardLabel')}
      </Button>
    </>
  );
};

export default TrelloCardLabelsSettings;
