import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table, Collapse, Col, Row } from 'antd';
import useColumns from '../Columns/columns';
import useLaysheetContext from '../LaysheetContext';

const { Panel } = Collapse;

export const AccessoryCard = ({ isValidDefinitevly }) => {
  const { t } = useTranslation();
  const { accessorie } = useLaysheetContext();
  const columns = useColumns(isValidDefinitevly);

  return (
    <Row
      justify="space-between"
      align="stretch"
      style={{
        padding: '0px 24px 24px 24px',
        flexWrap: 'nowrap',
        height: 'fit-content'
      }}
      gutter={24}
    >
      <Col span={24}>
        <Collapse>
          <Panel
            header={t('laysheet.form.titles.accessories')}
            key="accessories"
          >
            <Table
              dataSource={accessorie}
              columns={columns}
              pagination={false}
              scroll={{ x: 1300 }}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

AccessoryCard.propTypes = {
  isValidDefinitevly: PropTypes.bool.isRequired
};
