import moment from 'moment';
import numeral from 'numeral';
import { enumsStatus } from '../../../utils/constants/tagColors';

const columns = (t, enums) => [
  {
    title: t('orders.list.column.title.number'),
    dataIndex: 'number',
    key: 'number',
    sorter: true
  },

  {
    title: t('orders.list.column.title.status'),
    dataIndex: 'status',
    key: 'status',
    render: (renderedEnums) => enumsStatus[renderedEnums],
    sorter: true
  },
  {
    title: t('orders.list.column.title.customer'),
    dataIndex: 'infos',
    key: 'customer',
    render: (infos) =>
      infos && `${infos.customer.last_name} ${infos.customer.first_name || ''}`,
    sorter: true
  },
  {
    title: t('orders.list.column.title.quotation'),
    dataIndex: 'quote',
    key: 'quote',
    render: (quote) => quote.number,
    sorter: true
  },
  {
    title: t('orders.list.column.title.emission_date'),
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    render: (date) => date && moment(date).format('DD/MM/YYYY')
  },
  {
    title: t('quotes.total'),
    dataIndex: 'totals',
    render: ({ totalClient }) =>
      `${numeral(totalClient).format('0,0.00')} € HT`,
    sorter: true
  }
  // {
  //   title: t('orders.list.column.title.total'),
  //   dataIndex: 'prices',
  //   sorter: true,
  //   render: ({ totalNet }) => `${numeral(totalNet).format('0,0.00')} € TTC`
  // }
];

export default columns;
