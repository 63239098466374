import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { DownloadButtonAdv } from '../Buttons/DownloadButtonAdv';
import { DownloadGeneratedFileAdv } from '../Buttons/DownloadGeneratedFileAdv';

export const PaymentColumns = (datas) => {
  const { t } = useTranslation();

  return (
    datas && [
      {
        title: t('quotations.show.date'),
        dataIndex: 'date',
        span: 3,
        render: (date) => moment(date).format('DD/MM/YYYY')
      },
      {
        title: t('quotations.show.amountPayed'),
        dataIndex: 'amount',
        span: 3,
        render: (_, amount) => {
          if (!amount.advance_payment && !amount.is_basic_invoice) {
            return `-${numeral(amount.amount).format('0,0.00')} €`;
          }
          return `${numeral(amount.amount).format('0,0.00')} €`;
        }
      },
      {
        title: t('ordersnew.saved_by'),
        dataIndex: 'user',
        span: 3,
        render: (value) => value.first_name
      },
      {
        title: t('ordersnew.is_advanced_payment'),
        dataIndex: 'advance_payment',
        span: 3,
        render: (value) => (value ? 'Oui' : 'Non')
      },
      {
        title: t('quotations.show.method'),
        dataIndex: 'method',
        span: 3
      },
      {
        title: t('ordersnew.advancedPayment_invoice'),
        dataIndex: 'advance_payment_number',
        span: 3,
        render: (_, value) => (
          <>
            {value && !value.is_basic_invoice && (
              <DownloadButtonAdv value={value} />
            )}
            {value && value.is_basic_invoice && (
              <DownloadGeneratedFileAdv values={datas} />
            )}
          </>
        )
      }
    ]
  );
};
