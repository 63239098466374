import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tag, Spin } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const StockTagForMatter = ({
  matter,
  sizesNeeded,
  incrementStockCount,
  small,
  order
}) => {
  const [pieces, setPieces] = useState([]);
  const [allSizesForMatter, setAllSizesForMatter] = useState();
  const [stockPerSize, setStockPerSize] = useState();
  const { dispatchAPI } = useAuthContext();
  const [isMatterInStock, setIsMatterInStock] = useState(true);
  const [stockCalculated, setStockCalculated] = useState(false);
  const { message } = useErrorMessage();

  const getPiecesForMatter = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pieces?${
          matter.shaping._id
            ? `matter._id=${matter.shaping._id || matter?.color?._id}`
            : `matter._id=${matter?.color?._id}`
        }&thickness=${parseFloat(matter.thikness)}`
      });
      setPieces(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAllSizesInStock = () => {
    const newAllSizesForMatter = [];

    pieces.forEach((piece) =>
      piece.sizes.forEach((size) => {
        if (!size.exit_date || (order && size.order === order._id)) {
          newAllSizesForMatter.push(size);
        }
      })
    );

    setAllSizesForMatter(newAllSizesForMatter);
  };

  const calculateStockPerSize = () => {
    const stock = {};
    const usedSizes = [];
    (sizesNeeded || []).forEach((size) => {
      stock[size._id] = false;

      const length = size.width > size.height ? size.width : size.height;
      const width = size.width > size.height ? size.height : size.width;

      const candidates = []; // Stores the sizes in stock that could be used for the size needed
      allSizesForMatter.forEach((sizeInStock) => {
        if (sizeInStock.length >= length && sizeInStock.width >= width) {
          if (!usedSizes.includes(sizeInStock)) {
            candidates.push(sizeInStock);
          }
        }

        if (candidates.length > 0) {
          // Use the smallest candidate and free the other sizes in stock
          let minArea = candidates[0].length * candidates[0].height;
          let bestCandidate = candidates[0];
          candidates.forEach((candidate) => {
            if (candidate.length * candidate.width < minArea) {
              minArea = candidate.length * candidate.width;
              bestCandidate = candidate;
            }
          });

          usedSizes.push(bestCandidate);
          stock[size._id] = true;
        }
      });
    });
    setStockPerSize(stock);

    if (!isMatterInStock && !Object.values(stock).includes(false)) {
      if (incrementStockCount) {
        incrementStockCount();
      }
    }

    setIsMatterInStock(!Object.values(stock).includes(false));
  };

  useEffect(() => {
    getPiecesForMatter();
  }, [matter]);

  useEffect(() => {
    getAllSizesInStock();
  }, [pieces]);

  useEffect(() => {
    if (allSizesForMatter) {
      calculateStockPerSize();
    }
  }, [allSizesForMatter]);

  useEffect(() => {
    if (isMatterInStock !== undefined && isMatterInStock !== null) {
      setStockCalculated(true);
    }
  }, [isMatterInStock]);

  if (!stockCalculated) {
    return <Spin />;
  }
  return small ? (
    <>
      {isMatterInStock ? (
        <CheckCircleOutlined style={{ color: '#52c41a' }} />
      ) : (
        <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
      )}
    </>
  ) : (
    <>
      {allSizesForMatter && (
        <Tag
          color={isMatterInStock ? '#52c41a' : '#f5222d'}
          icon={
            isMatterInStock ? (
              <CheckCircleOutlined />
            ) : (
              <ExclamationCircleOutlined />
            )
          }
        >
          {isMatterInStock ? 'en stock' : 'en rupture de stock'}
        </Tag>
      )}
    </>
  );
};

StockTagForMatter.propTypes = {
  incrementStockCount: PropTypes.func,
  matter: PropTypes.shape({
    color: PropTypes.shape({
      _id: PropTypes.any
    }),
    shaping: PropTypes.shape({
      _id: PropTypes.any
    }),
    thikness: PropTypes.any
  }).isRequired,
  sizesNeeded: PropTypes.shape({
    forEach: PropTypes.func
  }).isRequired,
  small: PropTypes.bool,
  order: PropTypes.shape({
    _id: PropTypes.any
  })
};

StockTagForMatter.defaultProps = {
  incrementStockCount: null,
  small: null,
  order: null
};

export default StockTagForMatter;
