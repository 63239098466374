import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space, Switch } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import InvoiceForm from './InvoiceForm';
import useFields from './fields';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import DocumentScan from '../../Documents/Scan/DocumentScan';
import { ScanContextProvider } from '../../Documents/Scan/ScanContext';

const CreateUpdateInvoice = ({ purpose }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const { fields1, fields2, options } = useFields(purpose);
  const [articlesList, setArticlesList] = useState([]);
  const [articlesAvailable, setArticles] = useState([]);
  const [orders, setOrders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [settings, setSettings] = useState([]);
  const [enums, setEnums] = useState([]);
  const [mode, setMode] = useState('FORM');

  const updateInvoice = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/invoices/${id}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateCustomer = async (companyId, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/companies/${companyId}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createInvoice = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/invoices', body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getArticles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/articles' });
      setArticles(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts' });
      setContacts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders?populate=quotations'
      });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/articles/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInvoice = useCallback(async () => {
    setIsLoading(true);
    await getArticles();
    await getOrders();
    await getCompanies();
    await getContacts();
    await getSettings();
    await getEnums();
    if (purpose === 'edit' && id) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/invoices/${id}?populate=customer`
        });
        const { articles, ...rest } = data;
        setInvoice({
          ...rest,
          emission_date: data.emission_date && moment(data.emission_date),
          deadline_date: data.deadline_date && moment(data.deadline_date),
          payment_date: data.payment_date && moment(data.payment_date),
          reception_date: data.reception_date && moment(data.reception_date),
          global_discount_type: data.global_discount
            ? data.global_discount.type
            : 'CURRENCY',
          global_discount_value: data.global_discount
            ? data.global_discount.value
            : 0,
          global_discount_comment: data.global_discount
            ? data.global_discount.comment
            : '',
          customer: data.customer ? data.customer._id : null,
          channel_number: data.customer ? data.customer.channel_number : null,
          address_details: data.customer ? data.customer.address_details : null,
          postal_code: data.customer ? data.customer.postal_code : null,
          city: data.customer ? data.customer.city : null
        });
        setArticlesList(
          articles.map(
            ({
              article,
              name,
              quantity,
              unit_price,
              discount,
              comment,
              VAT
            }) => {
              const discountValue = discount ? discount.value : 0;
              const discountType = discount ? discount.type : 'CURRENCY';
              let lineDiscount = 0;

              if (discountValue > 0) {
                lineDiscount =
                  discountType === 'CURRENCY'
                    ? discountValue
                    : (unit_price * quantity * discountValue) / 100;
              }

              return {
                name,
                quantity,
                unit_price,
                articleId: article,
                discount_type: discountType,
                discount: discountValue,
                subtotal: unit_price * quantity - lineDiscount,
                comment,
                VAT
              };
            }
          )
        );
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    getInvoice();
  }, [getInvoice, purpose, id]);

  useEffect(() => {
    if (
      history.location.search &&
      history.location.search.split('?')[1].split('=')[0] === 'customer' &&
      purpose !== 'edit'
    ) {
      const search = history.location.search.split('?')[1];
      const params = search.split('&');
      const customer = companies.find((c) => c._id === params[0].split('=')[1]);
      const contact =
        params.length > 1
          ? contacts.find((c) => c._id === params[1].split('=')[1])
          : null;
      const order = params.length > 2 && params[2].split('=')[1];

      setInvoice({
        customer: customer ? customer._id : null,
        channel_number: customer ? customer.channel_number : null,
        address_details: customer ? customer.address_details : null,
        postal_code: customer ? customer.postal_code : null,
        city: customer ? customer.city : null,
        contact: contact ? contact._id : null,
        order
      });
    }
  }, [companies, contacts]);

  const handleSubmit = async (values) => {
    const formattedArticles = [];
    let global_discount = null;
    let total_paid = null;

    Object.values(values.articles).map((element) => {
      formattedArticles.push({
        ...element,
        discount: element.discount
          ? {
              type: element.discount_type,
              value: element.discount
            }
          : null
      });
      return element;
    });

    if (values.global_discount_value) {
      global_discount = {
        type: values.global_discount_type,
        value: values.global_discount_value,
        comment: values.global_discount_comment
      };
    }

    if (values.payment_date) {
      total_paid = values.total;
    }

    const formattedValues = {
      ...values,
      global_discount,
      total_paid,
      articles: formattedArticles
    };

    await updateCustomer(values.customer, {
      channel_number: values.channel_number,
      address_details: values.address_details,
      postal_code: values.postal_code,
      city: values.city
    });

    if (purpose === 'edit') await updateInvoice(formattedValues);
    if (purpose === 'create') await createInvoice(formattedValues);
  };

  const addArticle = (articleId, quantity = 1) => {
    let article = {};
    const tmp = articlesList;
    const isInList = tmp.find((a) => a.articleId === articleId);

    if (!isInList) {
      articlesAvailable.map((element) => {
        if (element._id === articleId) {
          article = element;
        }
        return element;
      });
      tmp.push({
        articleId,
        name: article.label,
        unit_price: article.sold_price,
        subtotal: article.sold_price * quantity,
        VAT: article.tva,
        quantity
      });
      setArticlesList([...tmp]);
    }
  };

  const deleteArticle = (key) => {
    setArticlesList(articlesList.filter(({ articleId }) => articleId !== key));
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`invoices.form.title.${purpose}`)}
        extra={
          <Switch
            checkedChildren="Scan OCR"
            unCheckedChildren="Formulaire"
            checked={mode === 'SCAN'}
            onChange={() => setMode(mode === 'SCAN' ? 'FORM' : 'SCAN')}
          />
        }
      />
      <ContentCustom>
        {isLoading ? (
          <Space direction="vertical" style={{ width: '100%' }} align="center">
            {[...fields1, ...fields2, ...articlesList].map((f) => (
              <Skeleton.Input key={`${f.name}`} style={{ width: 300 }} active />
            ))}
          </Space>
        ) : (
          <>
            {mode === 'FORM' && (
              <InvoiceForm
                resource="invoices"
                isLoading={isLoading}
                initialValues={invoice}
                handleSubmit={handleSubmit}
                companies={companies}
                settings={settings}
                enums={enums}
                contacts={contacts}
                fields1={fields1}
                fields2={fields2}
                options={options}
                articles={articlesList}
                articlesAvailable={articlesAvailable}
                addArticle={addArticle}
                deleteArticle={deleteArticle}
                orders={orders}
              />
            )}
            {mode === 'SCAN' && (
              <ScanContextProvider>
                <DocumentScan resource="invoices" />
              </ScanContextProvider>
            )}
          </>
        )}
      </ContentCustom>
    </>
  );
};

CreateUpdateInvoice.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateInvoice;
