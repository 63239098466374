import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  Select,
  Form,
  Typography,
  Checkbox,
  Button,
  Input,
  Radio
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useConfiguratorContext from './ConfiguratorContext';
import CreateUpdateProfesional from '../../Profesionals/CreateUpdateProfesional';
import CreateUpdateCustomer from '../../Customers/CreateUpdateCustomer';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const GlobalForm = ({ isPro, setValue, isProVisible, setIsProVisible }) => {
  const {
    datas,
    currentStep,
    t,
    refreshData,
    setRefreshData,
    loading,
    form
  } = useConfiguratorContext();
  const { profesionals, customers, sites } = datas;
  const { state } = useLocation();
  const [isCreateProVisible, setIsCreateProVisible] = useState(false);
  const [isCreateCustomerVisisble, setIsCreateCustomerVisible] = useState(
    false
  );
  useEffect(() => {
    setIsProVisible(isPro);
  }, [isPro]);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div
      className="quotation-form-wrapper"
      style={{ display: currentStep === 0 ? '' : 'none' }}
    >
      <Modal
        width="80vw"
        height="80vh"
        closable={false}
        footer={false}
        visible={isCreateProVisible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <CreateUpdateProfesional
          purpose="create"
          isModal
          customSubmit={async (body) => {
            try {
              await dispatchAPI('POST', {
                url: 'profesionals',
                body
              });
              setIsCreateProVisible(false);
              setRefreshData(!refreshData);
            } catch (e) {
              if (e.response) message(e.response.status);
            }
          }}
          customCancel={() => setIsCreateProVisible(false)}
        />
      </Modal>
      <Modal
        width="80vw"
        height="80vh"
        closable={false}
        footer={false}
        visible={isCreateCustomerVisisble}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <CreateUpdateCustomer
          purpose="create"
          isModal
          customSubmit={async (body) => {
            try {
              await dispatchAPI('POST', {
                url: 'customers',
                body
              });
              setIsCreateCustomerVisible(false);
              setRefreshData(!refreshData);
            } catch (e) {
              if (e.response) message(e.response.status);
            }
          }}
          customCancel={() => setIsCreateCustomerVisible(false)}
        />
      </Modal>
      <Typography.Title
        level={4}
        style={{ display: currentStep === 0 ? '' : 'none' }}
      >
        {t('configurator.informations')}
      </Typography.Title>
      <div className="quotation-form-fields-wrapper">
        <Form.Item
          label={t('configurator.commercial_site')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          name={['infos', 'commercial_site']}
          rules={[{ required: true }]}
        >
          <Select
            options={(sites || []).map(({ label, _id }) => ({
              label,
              value: _id
            }))}
            loading={loading}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.production_site')}
          name={['infos', 'production_site']}
          rules={[{ required: true }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
        >
          <Select
            options={(sites || []).map(({ label, _id }) => ({
              label,
              value: _id
            }))}
            loading={loading}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label={t('configurator.catalogue')}
          name={['infos', 'catalogue']}
          rules={[{ required: true }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
        >
          <Radio.Group onChange={onChange}>
            <Radio value="ADM">Catalogue ADM</Radio>
            <Radio value="DY">Catalogue Darty</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          label={t('configurator.customer')}
        >
          <Input.Group compact>
            <Form.Item
              noStyle
              name={['infos', 'customer']}
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '66.66%' }}
                options={(customers || []).map(
                  ({ first_name, last_name, _id }) => ({
                    label: `${first_name || ''} ${last_name}`,
                    value: _id
                  })
                )}
                loading={loading}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
            <Button
              style={{ marginLeft: '10px' }}
              type="dashed"
              icon={<PlusOutlined />}
              className="quotation-add-btn"
              onClick={() => setIsCreateCustomerVisible(true)}
            >
              {t('configurator.new')}
            </Button>
          </Input.Group>
        </Form.Item>

        <Form.Item
          label={t('configurator.profesional')}
          name={['infos', 'isPro']}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          valuePropName="checked"
        >
          <Checkbox
            onChange={(e) => {
              // if (e.target.checked)
              setIsProVisible(e.target.checked);
            }}
          />
        </Form.Item>
        {isProVisible && (
          <>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              label={t('configurator.profesional')}
            >
              <Input.Group>
                <Form.Item
                  noStyle
                  name={['infos', 'profesional']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue(['invoicedToPro']),
                      message: t('message.Please_select_a_professional')
                    })
                  ]}
                >
                  <Select
                    style={{ width: '66.66%' }}
                    options={(profesionals || []).map(({ name, _id }) => ({
                      label: name,
                      value: _id
                    }))}
                    loading={loading}
                    onSelect={(value) => {
                      const values = form.getFieldsValue();
                      const pro = profesionals.find(
                        (item) => item._id === value
                      );
                      values.totals.comPro = pro.commission;
                      form.setFieldsValue(values);
                    }}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
                <Button
                  style={{ marginLeft: '10px' }}
                  type="dashed"
                  icon={<PlusOutlined />}
                  className="quotation-add-btn"
                  onClick={() => setIsCreateProVisible(true)}
                >
                  {t('configurator.new')}
                </Button>
              </Input.Group>
            </Form.Item>
            <Form.Item
              label={t('configurator.invoicedToPro')}
              name={['invoicedToPro']}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </>
        )}
        <Form.Item
          name={['building_site']}
          hidden
          initialValue={state?.building_site}
        />
      </div>
    </div>
  );
};

GlobalForm.propTypes = {
  isPro: PropTypes.bool.isRequired,
  setValue: PropTypes.func
};

GlobalForm.defaultProps = {
  setValue: null
};

export default GlobalForm;
