/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

export const AddActivityForm = ({
  addActivity,
  setFormActivity,
  setListActivity,
  employeesList
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: 100 }}>
      <Form {...layout} onFinish={addActivity}>
        <Form.Item name="type" label="Catégorie" rules={[{ required: true }]}>
          <Select>
            <Option value="note">Note</Option>
            <Option value="call">Appel</Option>
            <Option value="meeting">Meeting</Option>
          </Select>
        </Form.Item>
        <Form.Item name="title" label="Titre" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="desc" label="Description" rules={[{ required: true }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="author" label="Auteur">
          <Select allowClear>
            {employeesList.map((item) => (
              <Option value={item._id} key={item._id}>
                {`${item.last_name} ${item.first_name || ''}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dateTime" label="Date" rules={[{ required: true }]}>
          <DatePicker showTime format="DD/MM/YYYY HH:mm" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            danger
            style={{ marginRight: 20 }}
            onClick={() => {
              setListActivity(true);
              setFormActivity(false);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.add')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

AddActivityForm.propTypes = {
  addActivity: PropTypes.func.isRequired,
  employeesList: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  setFormActivity: PropTypes.func.isRequired,
  setListActivity: PropTypes.func.isRequired
};
