import PropTypes from 'prop-types';
import React from 'react';
import { Row, Tag, Col } from 'antd';

const DealCardTags = ({ deal }) => {
  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 8 }}>
        <Col>
          {deal.site && (
            <Tag
              style={{ borderRadius: '2px' }}
              color={deal.site.request_color && deal.site.request_color.hex}
            >
              <span>{`${deal.site.label.toUpperCase()}`}</span>
            </Tag>
          )}
        </Col>
        <Col>
          {deal.quotes &&
            deal.quotes.map(
              (quote) =>
                quote.quote && (
                  <Row gutter={[0, 4]}>
                    <Col>
                      <Tag color="blue">{`N°${quote.quote.number}`}</Tag>
                    </Col>
                  </Row>
                )
            )}
        </Col>
      </Row>
    </>
  );
};

DealCardTags.propTypes = {
  deal: PropTypes.shape({
    quotes: PropTypes.array,
    site: PropTypes.shape({
      label: PropTypes.shape({
        toUpperCase: PropTypes.func
      }),
      request_color: PropTypes.shape({
        hex: PropTypes.any
      })
    })
  }).isRequired
};

export default DealCardTags;
