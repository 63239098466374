import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Tag, Space } from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import WorksheetButtons from './WorksheetButtons';
import useWorkSheetContext from './WorkSheetContext';
import WorksheetForm from './WorksheetForm';

const WorkSheets = () => {
  const {
    form,
    state,
    template,
    downloadFile,
    updateLayDate,
    createWorkSheet
  } = useWorkSheetContext();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [isEditable] = useState(
    !(state.worksheet && state.worksheet.validated)
  );

  const saveWorkSheet = async () => {
    const url = `/worksheets/${state.worksheet._id}`;
    const body = {
      ...form.getFieldsValue(),
      date: form.getFieldValue().lay_date
        ? form.getFieldValue().lay_date
        : moment(),
      measurement_date: form.getFieldValue().measurement_date
        ? form.getFieldValue().measurement_date
        : moment(),
      matter: form.getFieldValue().matter
    };
    const method = 'PATCH';

    try {
      await dispatchAPI(method, {
        url,
        body
      });
      updateLayDate();
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const handleOk = async () => {
    let key;
    if (!state.worksheet) {
      await createWorkSheet();
      key = state.datas._id;
    } else {
      await saveWorkSheet();
      key = state.worksheet.order;
    }

    history.push(`/customers_accounting/orders_sales/show/${key}`);
  };

  const handleValidate = async () => {
    const method = 'POST';
    let url;
    let body;

    if (!state.worksheet) {
      form.setFieldsValue({ validated: true });
      await createWorkSheet();
      return;
    }

    // Save & set the validate field
    try {
      await dispatchAPI('PATCH', {
        url: `/worksheets/${state.worksheet._id}`,
        body: {
          ...form.getFieldsValue(),
          validated: true,
          date: form.getFieldValue().lay_date
            ? form.getFieldValue().lay_date
            : moment(),
          measurement_date: form.getFieldValue().measurement_date
            ? form.getFieldValue().measurement_date
            : moment()
        }
      });
      updateLayDate();
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }

    if (state.datas?.placed.without_lay) {
      // Without lay : Removal / Delivery Order
      url = '/removal-order';
      body = {
        worksheet: state.worksheet?._id,
        order: state.datas?._id,
        is_delivery: false,
        date: form.getFieldValue().date,
        numberWorkSheet: state.numberWorkSheet
      };
    } else {
      // With lay : Proces Verbal
      url = '/acceptance-report';
      body = {
        worksheet: state.worksheet?._id,
        order: state.datas?._id,
        date: form.getFieldValue().date,
        numberWorkSheet: state.numberWorkSheet
      };
    }

    try {
      await dispatchAPI(method, {
        url,
        body
      });
      history.push(
        `/customers_accounting/orders_sales/show/${state.worksheet.order}`
      );
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const handleDelete = async () => {
    let key;

    if (!state.worksheet) {
      // CREATE mode
      key = state.datas._id;
      history.push(`/customers_accounting/orders_sales/show/${key}`);
    }

    key = state.worksheet.order;

    const method = 'DELETE';
    const url = `/worksheets/${state.worksheet._id}`;

    try {
      await dispatchAPI(method, {
        url
      });
      history.push(`/customers_accounting/orders_sales/show/${key}`);
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  useEffect(() => {
    if (state?.worksheet) {
      // EDIT mode
      state.worksheet.number = state.numberWorkSheet;
      // pass existing worksheet in state from order worksheets tab list
      const existingProcess = state?.worksheet.process;
      if (existingProcess) {
        Object.entries(existingProcess).forEach(([key, value]) => {
          existingProcess[key].end_date = moment(value.end_date);
        });
      }
      const wFinitions = state?.worksheet.finitions;
      if (wFinitions) {
        Object.entries(wFinitions).forEach(([key, value]) => {
          wFinitions[key].end_date = moment(value.end_date);
        });
      }

      form.setFieldsValue({
        ...form.getFieldValue(),
        ...state.worksheet,
        date: moment(state.worksheet.date),
        measurement_date: moment(state.worksheet?.measurement_date),
        existingProcess,
        wFinitions
      });
    }
    if (state?.datas) {
      // CREATE mode
      // pass order in state from order extrabutton
      form.setFieldsValue({
        customer: state.datas.infos.customer._id,
        commercial_site: state.datas.infos.commercial_site._id,
        production_sites: state.datas.infos.production_site._id
      });
    }
  }, [state]);

  const renderStatus = () => {
    if (state.worksheet && state.worksheet.validated) {
      return <Tag color="green">VALIDÉE</Tag>;
    }
    return '';
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <Space size="large">
            <div>
              {`Commande n°${state.datas.number} - Fiche de Travail ${
                state.numberWorkSheet ? state.numberWorkSheet.split('T')[1] : ''
              }`}
            </div>
            {renderStatus()}
          </Space>
        }
        extra={
          <WorksheetButtons
            state={state}
            template={template}
            downloadFile={downloadFile}
            isEditable={isEditable}
            handleValidate={handleValidate}
            handleDelete={handleDelete}
            handleOk={handleOk}
          />
        }
      />
      <WorksheetForm
        handleValidate={handleValidate}
        handleDelete={handleDelete}
        handleOk={handleOk}
      />
    </>
  );
};

export default WorkSheets;
