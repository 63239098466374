import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Card, Row, Button } from 'antd';
import {
  LoadingOutlined,
  DownloadOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const FileCard = ({ file, onDeleteFile }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const downloadFile = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const uploadedFile = document.createElement('a');
      uploadedFile.href = url;
      uploadedFile.download = file.metadata.originalName;
      uploadedFile.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading(false);
  };

  const deleteFile = async () => {
    try {
      await onDeleteFile(file);
      await dispatchAPI('DELETE', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Card
      style={{
        background: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid black',
        marginRight: '8px'
      }}
      bodyStyle={{ padding: '8px 8px 8px 8px' }}
      bordered
    >
      <Row justify="middle" align="space-between">
        <Col>{file.metadata && file.metadata.originalName}</Col>
        <Col>
          <Row>
            <Button
              type="link"
              onClick={async () => {
                if (!isDownloading) {
                  setIsDownloading(true);
                  await downloadFile();
                }
              }}
            >
              {isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>
            <Button
              type="link"
              onClick={async () => {
                await deleteFile();
              }}
            >
              {<DeleteOutlined style={{ color: 'red' }} />}
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

FileCard.propTypes = {
  file: PropTypes.shape({
    _id: PropTypes.any,
    filename: PropTypes.string,
    metadata: PropTypes.shape({
      originalName: PropTypes.string
    })
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

export default FileCard;
