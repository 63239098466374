/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@ant-design/charts';

const ChartQuotation = ({ quotes }) => {
  const [pending, setPending] = useState(0);
  const [validate, setValidate] = useState(0);
  useEffect(() => {
    if (quotes.length) {
      setPending(quotes.filter((el) => el.status === 'PENDING').length);
      setValidate(quotes.filter((el) => el.status === 'VALIDATE').length);
    } else {
      setPending(0);
      setValidate(0);
    }
  }, [quotes]);
  const data = [
    {
      type: 'EN ATTENTE',
      sales: pending
    },
    {
      type: 'VALIDÉ',
      sales: validate
    }
  ];
  const config = {
    width: 280,
    height: 280,
    data,
    xField: 'sales',
    yField: 'type',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'VALIDÉ') {
        return '#1C5253';
      }
      return '#ABB4B9';
    },
    label: {
      position: 'middle',
      style: {
        opacity: 0.6
      }
    },
    meta: {
      sales: { alias: 'Nombres' }
    }
  };
  return <Bar {...config} />;
};

ChartQuotation.propTypes = {
  quotes: PropTypes.shape().isRequired
};

export default ChartQuotation;
