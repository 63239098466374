import moment from 'moment';
import numeral from 'numeral';
import { enumsStatus } from '../../../utils/constants/tagColors';

const useColumns = (t) => {
  return [
    {
      title: t('quotations.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('quotations.list.column.title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (renderedStatus) => enumsStatus[renderedStatus],
      sorter: true
      // filters:
      //   status &&
      //   status.map(el => ({
      //     text: enumsStatus[el],
      //     value: el
      //   }))
    },
    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('quotations.list.column.title.customer'),
      dataIndex: 'customer',
      render: (customer) =>
        `${customer?.first_name || ''} ${customer?.last_name}`,
      sorter: true
    },
    {
      title: t('quotations.list.column.title.total'),
      dataIndex: 'prices',
      render: ({ totalNet }) => `${numeral(totalNet).format('0,0.00')} € TTC`,
      sorter: true
    }
  ];
};

export default useColumns;
