import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Col,
  Row,
  message,
  Collapse,
  Table,
  Button,
  Select,
  Modal
} from 'antd';
import { useTranslation } from 'react-i18next';
import useColumnsMaterial from '../Columns/columnsMaterial';
import useAuthContext from '../../../contexts/AuthContext';
import useLaysheetContext from '../LaysheetContext';

const { Panel } = Collapse;

export const MaterialsCard = ({
  isValidDefinitevly,
  form,
  materialLaySheet,
  setMaterialLaySheet
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { fetchMaterials, material } = useLaysheetContext();
  const [isModalMaterialVisible, setIsModalMaterialVisible] = useState(false);

  const columnsMat = useColumnsMaterial(
    isValidDefinitevly,
    form,
    materialLaySheet,
    setMaterialLaySheet
  );

  const [add, setAdd] = useState(false);

  const handleCancel = () => {
    setIsModalMaterialVisible(false);
    setAdd(false);
  };

  const addMaterials = async () => {
    const body = {
      label: form.getFieldValue().newMaterialAdd
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Materials', body });
    } catch (e) {
      message.error(e.message);
    }
    setIsModalMaterialVisible(false);
    fetchMaterials();
  };

  const addToListMaterial = () => {
    const idMat = form.getFieldValue().newMaterialSelect;

    if (add) {
      addMaterials();
    } else if (idMat !== undefined) {
      material.forEach(({ _id, label, expect }) => {
        if (_id === idMat) {
          setMaterialLaySheet([
            ...materialLaySheet,
            {
              _id,
              label,
              expect
            }
          ]);
        }
      });
    }
    setIsModalMaterialVisible(false);
  };

  const showModalMaterial = () => {
    setIsModalMaterialVisible(true);
    form.setFieldsValue({
      ...form.getFieldValue(),
      newMaterialAdd: undefined,
      newMaterialSelect: undefined
    });
  };

  const showInput = () => {
    if (!add) setAdd(true);
    else setAdd(false);
    form.setFieldsValue({
      ...form.getFieldValue(),
      newMaterialAdd: undefined,
      newMaterialSelect: undefined
    });
  };

  useEffect(() => {
    const labelMat = form.getFieldValue().newMaterialAdd;
    if (add) {
      material.forEach(({ _id, label, expect }) => {
        if (label === labelMat) {
          setMaterialLaySheet([
            ...materialLaySheet,
            {
              _id,
              label,
              expect
            }
          ]);
        }
      });
      setAdd(false);
    }
  }, [material]);

  return (
    <Row
      justify="space-between"
      align="stretch"
      style={{
        padding: '0px 24px 24px 24px',
        flexWrap: 'nowrap',
        height: 'fit-content'
      }}
      gutter={24}
    >
      <Col span={24}>
        <Collapse>
          <Panel header={t('laysheet.form.titles.utils')} key="utils">
            <Table
              dataSource={materialLaySheet}
              columns={columnsMat}
              pagination={false}
              scroll={{ x: 800 }}
            />
            <Button
              disabled={isValidDefinitevly}
              type="dashed"
              style={{ width: '100%', height: 50 }}
              onClick={showModalMaterial}
            >
              {t('settings.Materials.addMaterial')}
            </Button>
            <Modal
              title={t('laysheet.form.buttons.add.utils')}
              visible={isModalMaterialVisible}
              onOk={addToListMaterial}
              onCancel={handleCancel}
            >
              {t('laysheet.form.buttons.materials')}
              <Form.Item name={['newMaterialSelect']}>
                <Select
                  options={material.map(({ _id, label }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
              {!add ? (
                <Button
                  type="primary"
                  style={{ marginBottom: '10px' }}
                  onClick={showInput}
                >
                  {t('laysheet.form.buttons.add.utils')}
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{ marginBottom: '10px' }}
                  onClick={showInput}
                >
                  {t('buttons.cancel')}
                </Button>
              )}
              <Form.Item name={['newMaterialAdd']}>
                {add && (
                  <Input placeholder={t('laysheet.form.placeholders.utils')} />
                )}
              </Form.Item>
            </Modal>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

MaterialsCard.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isValidDefinitevly: PropTypes.bool.isRequired,
  materialLaySheet: PropTypes.arrayOf({}).isRequired,
  setMaterialLaySheet: PropTypes.func.isRequired
};
