const patchInvoice = async (dispatchAPI, message, id, body) => {
  try {
    await dispatchAPI('PATCH', { url: `/invoices/${id}`, body });
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};

const patchExpense = async (dispatchAPI, message, id, body) => {
  try {
    await dispatchAPI('PATCH', { url: `/expenses/${id}`, body });
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};

export const getExpenses = async (dispatchAPI, message) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url:
        '/expenses?populate=id_supplier&is_reconciled=null&is_reconciled=false'
    });
    return data;
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};

export const getInvoices = async (dispatchAPI, message) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/invoices?populate=customer&payment_date=null'
    });
    return data;
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};

export const getTransactions = async (
  dispatchAPI,
  message,
  userId,
  visibleAccounts
) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/bank/transactions/${userId}?accounts=${visibleAccounts.join(',')}`
    });
    return data;
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};

export const updateExpenses = async (
  dispatchAPI,
  message,
  transaction,
  elements,
  expenses
) => {
  const value = transaction.value
    ? transaction.value
    : transaction.original_value;
  const expense = expenses.find((i) => i._id === elements[0]);
  const newPayment = {
    date: transaction.date,
    amount_paid: value,
    transaction_id: transaction.id
  };

  let is_reconciled = null;
  if (value === expense.amount_ht) {
    is_reconciled = true;
  }

  await patchExpense(dispatchAPI, message, elements[0], {
    $push: { payments: newPayment },
    $inc: { total_reconciled: newPayment.amount_paid },
    is_reconciled
  });
};

export const updateInvoices = async (
  dispatchAPI,
  message,
  transaction,
  elements,
  invoices
) => {
  const value = transaction.value
    ? transaction.value
    : transaction.original_value;
  const invoice = invoices.find((i) => i._id === elements[0]);
  const newPayment = {
    date: transaction.date,
    amount_paid: value,
    transaction_id: transaction.id
  };

  let payment_date = null;
  if (value === invoice.total) {
    payment_date = transaction.date;
  }

  await patchInvoice(dispatchAPI, message, elements[0], {
    $push: { payments: newPayment },
    $inc: { total_paid: newPayment.amount_paid },
    payment_date
  });
};

export const getCode = async (dispatchAPI, message, userId) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/bank/auth/code/${userId}`
    });
    return data.code;
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
