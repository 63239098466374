import PropTypes from 'prop-types';
import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

export const SelectDealStatus = ({
  editStatus,
  dealStatusIndex,
  kanbanCols
}) => {
  const sortedArray = kanbanCols.sort((a, b) => {
    return b.index - a.index;
  });

  const onSelectChange = (value) => {
    editStatus(value);
  };

  return (
    <div>
      <Steps current={dealStatusIndex} progressDot onChange={onSelectChange}>
        {sortedArray &&
          sortedArray.map((item) => <Step title={item.label} key={item._id} />)}
      </Steps>
    </div>
  );
};

SelectDealStatus.propTypes = {
  dealStatusIndex: PropTypes.string.isRequired,
  editStatus: PropTypes.func.isRequired,
  kanbanCols: PropTypes.arrayOf(PropTypes.object).isRequired
};
