import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Col } from 'antd';
import moment from 'moment';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import OptionsRow from './OptionsRow';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import { headers } from './headers';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { DrowpdownDownloadButton } from '../../../components/DropdownDonwloadButton/DropdownDownloadButtonQuotation';

const iconSize = 18;

const ListInvoices = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { template, range, downloadFile } = useAccountingContext();
  const location = useLocation();
  const { pathname } = location;
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [enums, setEnums] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `emission_date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&emission_date<=${moment(range[1])
          .endOf('day')
          .toISOString()}`
      );
    } else setExtraQuery(null);
  }, [range]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/invoices/enums` });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, [forceRefresh]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, number }) => (
        <>
          <Link to={{ pathname: `${pathname}/show/${key}` }}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {template && (
            <DrowpdownDownloadButton
              recordKey={key}
              number={number}
              isDownloading={isDownloading}
              setIsDownloading={setIsDownloading}
              downloadFile={downloadFile}
            />
          )}
        </>
      )
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="invoices"
      columns={[...columns(t, enums), ...actionColumn]}
      extraQuery={extraQuery}
      populate="customer,order,profesional,user"
      customActionColumn
      withCreateButton={false}
      forceRefresh={forceRefresh}
      headers={headers}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListInvoices;
