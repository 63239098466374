import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import PatchForm from './PatchForm';

const Container = styled.div`
  padding: 24px;
  background-color: white;
`;

const Modifier = ({
  match: {
    params: { id }
  }
}) => {
  const history = useHistory();
  const revenuId = id;
  const { dispatchAPI } = useAuthContext();
  const [revenu, setRevenu] = useState({});
  const { t } = useTranslation();

  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/incomes',
      breadcrumbName: t('basiclayout.menu.accounting')
    },
    {
      path: '/incomes',
      breadcrumbName: t('incomes.breadcrumb.income')
    },
    {
      breadcrumbName: t('incomes.breadcrumb.edit')
    }
  ];

  const fetchData = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/incomes/${revenuId}` });
    setRevenu(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {typeof revenu.data !== 'undefined' && (
        <PageHeader
          className="site-page-header"
          title={`${t('incomes.breadcrumb.edit')} ${revenu.data.wording}`}
          style={{ backgroundColor: 'white', marginBottom: 1 }}
          breadcrumb={{
            routes,
            // eslint-disable-next-line react/prop-types
            itemRender: ({ path, breadcrumbName }) => (
              <Link to={path}>{breadcrumbName}</Link>
            )
          }}
          onBack={history.goBack}
        />
      )}
      <Container>
        <PatchForm revenuId={revenuId} />
      </Container>
    </>
  );
};

Modifier.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Modifier;
