import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import React, { useState, useEffect, createContext, useContext } from 'react';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

export const LaysheetContext = createContext({});

export const LaysheetContextProvider = ({ children }) => {
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { state } = useLocation();
  const isSuperAdmin = user?.role?.split(':').pop() === 'SUPER-ADMIN' || false;

  const [template, setTemplate] = useState();

  const [laySheets, setLaySheets] = useState({});
  const [worksheetInfo, setWorksheetInfo] = useState();
  const [orderInfo, setOrderInfo] = useState();
  const [accessorie, setAccessories] = useState([]);
  const [material, setMaterials] = useState([]);
  const [operators, setOperators] = useState([]);
  const [sites, setSites] = useState([]);
  const [datas, setDatas] = useState([]);
  const [workSheetSelected, setWorkSheetSelected] = useState(null);
  const [isdisabled, setIsdisabled] = useState(false);
  const [commercialSite, setCommercialSite] = useState();
  const [productionSites, setProductionSites] = useState([]);
  const [customer, setCustomer] = useState({});
  const [measurementDate, setMeasurementDate] = useState();
  const [date, setDate] = useState();

  const getWorksheet = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets/${id}`
      });
      setWorksheetInfo(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLaySheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/laysheets?order=${state.datas._id}&populate=customer`
      });
      setLaySheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrder = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew/${id}`
      });
      setOrderInfo(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchAccessories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Accessories`
      });
      const datasAcc = data.map((elem) => ({
        _id: elem._id,
        label: elem.label,
        onconstruction: '',
        deposit: '',
        delivering: '',
        quantity: '',
        date_receipt: elem.date_receipt
          ? moment(elem.date_receipt).format('DD-MM-YYYY')
          : null,
        status: ''
      }));
      setAccessories(datasAcc);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchMaterials = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Materials`
      });
      const datasMat = data.map(({ _id, label }) => ({
        _id,
        label,
        expect: ''
      }));
      setMaterials(datasMat);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchOperators = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-operators`
      });
      setOperators(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sites` });
      setSites(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?order=${state.datas._id}&populate=matter,customer,order, order.infos.commercial_site,  order.infos.production_site,   order.infos.customer`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteLaySheet = () => {
    if (state?.laysheet) {
      dispatchAPI('DELETE', {
        url: `/laysheets/${state.laysheet._id}`
      });
      history.goBack();
    } else {
      history.goBack();
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=LAYSHEET`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/laysheet/${id}/${template._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });
      const nameFile =
        name === 'undefined.pdf' ? `${state.datas.number}-FP.pdf` : name;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = nameFile;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  useEffect(() => {
    getTemplate();
    getDatas();
    fetchSites();
    getLaySheets();
  }, []);

  useEffect(() => {
    fetchAccessories();
    fetchMaterials();
    fetchOperators();
  }, [workSheetSelected]);

  return (
    <LaysheetContext.Provider
      value={{
        getWorksheet,
        getLaySheets,
        fetchOperators,
        getOrder,
        fetchAccessories,
        fetchMaterials,
        fetchSites,
        getDatas,
        deleteLaySheet,
        downloadFile,
        laySheets,
        setLaySheets,
        worksheetInfo,
        accessorie,
        setAccessories,
        measurementDate,
        setMeasurementDate,
        isdisabled,
        template,
        setTemplate,
        date,
        setDate,
        setIsdisabled,
        material,
        setMaterials,
        workSheetSelected,
        setWorkSheetSelected,
        commercialSite,
        setCommercialSite,
        productionSites,
        setProductionSites,
        customer,
        setCustomer,
        operators,
        datas,
        setDatas,
        setOperators,
        sites,
        setSites,
        setWorksheetInfo,
        orderInfo,
        setOrderInfo,
        isSuperAdmin,
        state
      }}
    >
      {children}
    </LaysheetContext.Provider>
  );
};

LaysheetContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(LaysheetContext);
