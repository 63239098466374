import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import OperatorsTable from './operatorsTable';

const OperatorSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [operators, setOperator] = useState([]);

  const fetchOperators = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-operators`
      });
      setOperator(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addOperators = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-operators', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchOperators();
  };

  const deleteOperators = async (operator) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-operators/${operator._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchOperators();
  };

  const editOperators = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-operators/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchOperators();
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <>
      {operators.length > 0 && (
        <OperatorsTable
          operators={operators}
          editOperators={editOperators}
          deleteOperators={deleteOperators}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addOperators}
      >
        {t('settings.operators.addOperators')}
      </Button>
    </>
  );
};

export default OperatorSettings;
