import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListUsers from './ListUsers';
import CreateUpdateUser from './CreateUpdateUser';
import ShowUser from './ShowUser';
import { subRoutes } from '../../utils/constants/routes';
import ExtranetUsers from '../usersExtranet/ExtranetUsers';

const UserRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.USERS.CRM_USERS}/create`}
        render={() => <CreateUpdateUser purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes.USERS.CRM_USERS}/edit/:id`}
        render={() => <CreateUpdateUser purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes.USERS.CRM_USERS}/show/:id`}
        component={ShowUser}
      />
      <Route
        path={`${path}${subRoutes.USERS.CRM_USERS}`}
        component={ListUsers}
      />
      <Route
        path={`${path}${subRoutes.USERS.EXTRANET_USERS}`}
        component={ExtranetUsers}
      />
    </Switch>
  );
};

export default UserRouter;
