import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import KanbanBoard from './KanbanBoard';
import ListDeals from './ListDeals';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { DealsContextProvider } from './DealsContext';
import MarbleBackground from '../../assets/images/white-stone_Scott-Webb.jpg';

const Deals = () => {
  const { t } = useTranslation();
  const [kanbanView] = useState(true);
  const [seeArchived, setSeeArchived] = useState(false);

  return (
    <>
      <PageHeaderCustom
        title={t('trello.title')}
        withSubRoutes
        extra={
          <Button
            type="primary"
            icon={seeArchived ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setSeeArchived(!seeArchived)}
          >
            {seeArchived ? 'MASQUER ARCHIVES' : 'AFFICHER ARCHIVES'}
          </Button>
        }
      />
      <ContentCustom
        style={{
          backgroundImage: `url(${MarbleBackground})`,
          backgroundSize: 'cover'
        }}
      >
        <DealsContextProvider>
          {kanbanView ? <KanbanBoard archived={seeArchived} /> : <ListDeals />}
        </DealsContextProvider>
      </ContentCustom>
    </>
  );
};

export default Deals;
