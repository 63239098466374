import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

const AccountingTag = ({ invoice }) => {
  const { t } = useTranslation();
  const { is_accounting_code } = invoice;

  return (
    <Tag
      color={is_accounting_code ? '#0dc815' : '#ff0003'}
      style={{ borderColor: 'transparent' }}
    >
      {t(`invoices.column.is_accounting_code.${is_accounting_code}`)}
    </Tag>
  );
};

export default AccountingTag;

AccountingTag.propTypes = {
  invoice: PropTypes.shape({
    is_accounting_code: PropTypes.bool,
    is_advance_payment: PropTypes.bool,
    is_credit_note: PropTypes.bool
  }).isRequired
};
