import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Descriptions, PageHeader, Tag, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useTranslation } from 'react-i18next';
import { DetailsButton } from '../../../utils/constants/customButton';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { routes, subRoutes } from '../../../utils/constants/routes';

const Container = styled.div`
  padding: 24px;
  background-color: white;
  margin-bottom: 0.05%;
`;

const { Text } = Typography;

const Details = () => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [expense, setExpense] = useState();
  const [suppliers, setSuppliers] = useState();
  const [categories, setCategories] = useState();

  const expenseId = history.location.pathname.split('/')[
    history.location.pathname.split('/').length - 1
  ];

  const renderSuppliers = (value) => {
    let display = null;
    if (suppliers) {
      suppliers.map((e) => {
        if (e._id === value) {
          display = e.name;
        }
        return e;
      });
    }
    return display;
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/categories' });
      setCategories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/companies` });
      const filteredSuppliers = await data.filter((supplier) => {
        return supplier.type === 'SUPPLIERS';
      });
      setSuppliers(filteredSuppliers);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getExpense = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/expenses/${id}` });
      setExpense(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getExpense(expenseId);
    await getCategories();
    await getSuppliers();
    setIsLoading(false);
  }, []);

  const deleteRessource = (id) => {
    dispatchAPI('DELETE', { url: `/expenses/${id}` });
    history.goBack();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {expense && (
        <PageHeader
          className="site-page-header"
          title={`${t('expenses.details.title')} ${expense.wording}`}
          style={{ backgroundColor: 'white', marginBottom: 1 }}
          breadcrumb={{
            routes,
            // eslint-disable-next-line react/prop-types
            itemRender: ({ path, breadcrumbName }) => (
              <Link to={path}>{breadcrumbName}</Link>
            )
          }}
          onBack={history.goBack}
          extra={[
            <DetailsButton
              path={`${routes.ACCOUNTING}${subRoutes.ACCOUNTING.EXPENSES}/edit/`}
              id={expenseId}
              deleteRessource={deleteRessource}
            />
          ]}
        />
      )}
      <Container>
        {expense && (
          <Descriptions style={{ paddingLeft: 25 }}>
            <Descriptions.Item
              label={t('expenses.form.wording')}
              style={{ display: 'block' }}
            >
              <Text strong> {expense.wording} </Text>
            </Descriptions.Item>
            <DescriptionsItem
              label={t('expenses.form.supplier')}
              style={{ display: 'block' }}
            >
              <Text strong>{renderSuppliers(expense.id_supplier)}</Text>
            </DescriptionsItem>
            <Descriptions.Item
              label={t('expenses.form.date')}
              style={{ display: 'block' }}
            >
              <Text strong>
                {new Date(expense.date).toLocaleDateString('fr-FR')}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={t('expenses.form.amount')}
              style={{ display: 'block' }}
            >
              <Text strong>{`${expense.amount_ht} €`}</Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={t('expenses.form.desc')}
              style={{ display: 'block' }}
            >
              <Text strong>{expense.desc}</Text>
            </Descriptions.Item>
            <DescriptionsItem
              label={t('expenses.details.category')}
              style={{ display: 'block' }}
            >
              <Text>
                {categories && expense.category && (
                  <Tag
                    color={
                      categories.find((c) => c._id === expense.category).color
                    }
                  >
                    {categories.find((c) => c._id === expense.category).label}
                  </Tag>
                )}
              </Text>
            </DescriptionsItem>
            <DescriptionsItem
              label={t('expenses.form.tva')}
              style={{ display: 'block' }}
            >
              <Text strong>{expense.tva}</Text>
            </DescriptionsItem>
            <DescriptionsItem
              label={t('expenses.details.reconcilation')}
              style={{ display: 'block' }}
            >
              <Text strong>{expense.id_payment}</Text>
            </DescriptionsItem>
          </Descriptions>
        )}
      </Container>
    </>
  );
};

export default Details;
