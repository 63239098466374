import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Space,
  Button,
  Modal,
  Form,
  Row,
  Select,
  InputNumber,
  Radio
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';
import DartyImg from '../../../assets/images/darty.png';
import AtelierImg from '../../../assets/images/atelierLogo.png';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const ListMatters = () => {
  const [extraQuery, setExtraQuery] = useState('ADM');
  const [opacityADM, setOpacityADM] = useState(1);
  const [opacityDY, setOpacityDY] = useState(0.3);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const onOk = async () => {
    try {
      const body = form.getFieldsValue();
      const ref = body.matter;
      await dispatchAPI('PATCH', { url: `/matters`, body });
      await dispatchAPI('POST', {
        url: `/matters/timeline/${ref}`,
        body: {
          status:
            body.direction === 'DEA'
              ? `Baisse de prix de ${body.price}%`
              : `Augmentation de prix de ${body.price}%`,
          date: moment(),
          by: user._id
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatchAPI('GET', {
          url: `/matters`
        });
        setData(response.data);
      } catch (e) {
        if (e) message(e);
      }
    })();
  }, []);

  const extraButtons = (
    <>
      <Form form={form}>
        <Modal
          title="Changer le prix"
          visible={isVisible}
          onOk={onOk}
          onCancel={() => setIsVisible(false)}
        >
          <Row align="middle">
            <Form.Item
              name="matter"
              label="Matière"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '100%', minWidth: '200px' }}
                options={[
                  ...new Set(data?.map(({ label }) => label))
                ].map((label) => ({ label, value: label }))}
              />
            </Form.Item>
          </Row>
          <Row align="middle">
            <Form.Item
              name="direction"
              label="Prix"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="INC">Augmentation du prix</Radio>
                <Radio value="DEA">Baisse du prix</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row align="middle">
            <Form.Item
              name="price"
              label="Changement du prix"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => (value ? `${value} %` : '')}
                style={{ width: '100%', minWidth: '100px' }}
                min={0}
                max={100}
              />
            </Form.Item>
          </Row>
        </Modal>
      </Form>
      <Button
        style={{
          background: 'black',
          border: 'black',
          color: 'white',
          marginRight: 10
        }}
        onClick={() => setIsVisible(true)}
      >
        {`${t('buttons.price')}`}
        <EditOutlined />
      </Button>
    </>
  );

  const handleOnClick = (param) => {
    if (param === 'DY') {
      setExtraQuery(`ref=DY`);
      setOpacityADM(0.3);
      setOpacityDY(1);
    } else {
      setExtraQuery(`ref=ADM`);
      setOpacityADM(1);
      setOpacityDY(0.3);
    }
  };

  const switchResource = (
    <Space size="large" align="center">
      <Button className="filterButton" onClick={() => handleOnClick('DY')}>
        <img
          src={DartyImg}
          style={{ height: 40, opacity: opacityDY }}
          alt="darty"
        />
      </Button>
      <Button className="filterButton" onClick={() => handleOnClick('ADM')}>
        <img
          src={AtelierImg}
          style={{ height: 40, opacity: opacityADM }}
          alt="atelier"
        />
      </Button>
    </Space>
  );

  const headers = [
    {
      label: 'Label',
      key: 'label' || ''
    },
    {
      label: 'Description',
      key: 'completion'
    },
    {
      label: 'Color',
      key: 'color'
    },
    {
      label: 'Reference',
      key: 'ref'
    },
    {
      label: 'Coef',
      key: 'coef.coef'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="matters"
      columns={[...columns()]}
      withSubRoutes
      withImportButton
      headers={headers}
      resourceModelName="Matter"
      extraHeader={switchResource}
      populate="coef"
      extraQuery={extraQuery}
      extraButtons={extraButtons}
    />
  );
};

export default ListMatters;
