/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/charts';

const PieOrderByMatter = ({ orders }) => {
  const [datas, setDatas] = useState([]);
  const statistics = [];
  const AllprojectTypes = [];
  useEffect(() => {
    if (orders.length) {
      orders.forEach((quote) => {
        if (quote.section_1 && quote.section_1.elements) {
          quote.section_1.elements.map((el) => AllprojectTypes.push(el.matter));
        }
        if (quote.section_2 && quote.section_2.elements) {
          quote.section_2.elements.map((el) => AllprojectTypes.push(el.matter));
        }
        if (quote.section_3 && quote.section_3.elements) {
          quote.section_3.elements.map((el) => AllprojectTypes.push(el.matter));
        }
        if (quote.section_4 && quote.section_4.elements) {
          quote.section_4.elements.map((el) => AllprojectTypes.push(el.matter));
        }
        if (quote.section_5 && quote.section_5.elements) {
          quote.section_5.elements.map((el) => AllprojectTypes.push(el.matter));
        }
      });
      AllprojectTypes.sort();
      AllprojectTypes.map((el) => {
        if (statistics[el]) {
          statistics[el] += 1;
        } else {
          statistics[el] = 1;
        }
      });
      let total = 0;
      Object.keys(statistics).map((key) => {
        total += statistics[key];
      });

      if (total) {
        const arrayData = Object.keys(statistics).map((key) => {
          return {
            type: key,
            value: parseInt((statistics[key] / total) * 100, 0)
          };
        });
        const elements = [];
        arrayData.map((el) => {
          elements.push({ type: el.type, value: el.value });
        });
        setDatas([...elements]);
      }
    } else {
      setDatas([]);
    }
  }, [orders]);

  const config = {
    width: 280,
    height: 280,
    appendPadding: 10,
    data: datas,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.75,
    label: {
      type: 'outer',
      offset: '-50%',
      content: '{value} %',
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [{ type: 'element-selected' }],
    statistic: {
      title: false,
      content: false
    }
  };
  return <Pie {...config} />;
};

PieOrderByMatter.propTypes = {
  orders: PropTypes.shape().isRequired
};

export default PieOrderByMatter;
