import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const PaymentTable = ({ payments }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('quotations.show.date'),
      dataIndex: 'date',
      span: 3,
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('quotations.show.amountPayed'),
      dataIndex: 'amount',
      span: 3,
      render: (amount) => `${numeral(amount).format('0,0.00')} € HT`
    },
    {
      title: t('quotations.show.user'),
      dataIndex: 'user',
      span: 3
    },
    {
      title: t('quotations.show.method'),
      dataIndex: 'method',
      span: 3
    }
  ];

  return <Table columns={columns} dataSource={payments} pagination={false} />;
};

PaymentTable.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PaymentTable;
