import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Divider
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CloseIcon } from '../../../../utils/constants/customIcon';
import useAuthContext from '../../../../contexts/AuthContext';

const Products = ({ datas, products, forceRefresh, isEditable }) => {
  const { dispatchAPI } = useAuthContext();
  const { Title } = Typography;
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [form] = Form.useForm();
  const handleOk = async () => {
    setVisible(false);
    // form.resetFields();

    const values = form.getFieldsValue();
    await dispatchAPI('PATCH', {
      url: `/invoices/${id}`,
      body: {
        products: values.products,
        number: datas.number
      }
    });
    forceRefresh();
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const onSelectCategory = (value, index) => {
    const values = form.getFieldsValue();

    values.products[index] = {
      ...values.products[index],
      product: undefined,
      unit_price: undefined
    };

    form.setFieldsValue(values);

    const newFilters = filters;

    newFilters[index] = { category: value };

    setFilters(newFilters);
  };

  const onSelectProduct = (value, index) => {
    const values = form.getFieldsValue();

    const productPrice = products
      .filter((product) => product._id === value)
      .shift().sold_price;

    Object.keys(values).forEach((key) => {
      if (key === 'products') {
        values[key].forEach((el, position) => {
          if (index === position) {
            values[key][position] = {
              ...el,
              unit_price: productPrice
            };
          }
        });
      }
    });
    form.setFieldsValue(values);
  };
  const onEditeProducts = () => {
    setVisible(true);
    let values = datas;
    values = {
      ...values,
      products: values?.products.map((el) => {
        return {
          ...el,
          product: el?.product?._id
        };
      })
    };

    form.setFieldsValue(values);
    Object.keys(values).forEach((key) => {
      if (key === 'products') {
        const newFilters = [];
        if (values[key]) {
          values[key].forEach((el, position) => {
            const currentFilter = { category: el?.category };
            newFilters[position] = currentFilter;
          });

          setFilters(newFilters);
        }
      }
    });
  };
  if (datas) {
    return (
      <>
        <Col span={8} style={{ height: 'fit-content' }}>
          <Card
            style={{ background: 'rgba(255, 255, 255, 0.8)' }}
            bordered={false}
            height="100%"
          >
            <Row>
              <Col>
                <Title level={4}>
                  Produits
                  {isEditable && (
                    <EditOutlined
                      style={{ fontSize: '20px', float: 'right' }}
                      onClick={() => {
                        onEditeProducts();
                      }}
                    />
                  )}
                  <Modal
                    visible={visible}
                    title="Modifier les produits"
                    onOk={handleOk}
                    width={1000}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        Annuler
                      </Button>,
                      <Button key="submit" type="primary" onClick={handleOk}>
                        Enregistrer
                      </Button>
                    ]}
                  >
                    <Form form={form}>
                      <Form.List name={['products']}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ name, fieldKey }) => (
                              <div
                                style={{
                                  position: 'relative'
                                }}
                                className="quotation-form-fields-wrapper"
                              >
                                <Form.Item
                                  label={t('configurator.product_category')}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 8 }}
                                  name={[name, 'category']}
                                  fieldKey={[fieldKey, 'category']}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    options={[
                                      ...new Set(
                                        products?.map(
                                          ({ category }) => category
                                        )
                                      )
                                    ].map((label) => ({ label, value: label }))}
                                    onChange={(value) => {
                                      onSelectCategory(value, name);
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('configurator.product')}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 8 }}
                                  name={[name, 'product']}
                                  fieldKey={[fieldKey, 'product']}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    options={products
                                      ?.filter(({ category }) =>
                                        !filters[name]
                                          ? false
                                          : category === filters[name].category
                                      )
                                      .map(({ _id, label }) => {
                                        return {
                                          label,
                                          value: _id
                                        };
                                      })}
                                    onChange={(value) =>
                                      onSelectProduct(value, name)
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('configurator.quantity')}
                                  labelCol={{ span: 8 }}
                                  wrapperCol={{ span: 8 }}
                                  name={[name, 'quantity']}
                                  fieldKey={[fieldKey, 'quantity']}
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber min={1} />
                                </Form.Item>
                                <Form.Item
                                  hidden
                                  name={[name, 'unit_price']}
                                  fieldKey={[fieldKey, 'unit_price']}
                                  rules={[{ required: true }]}
                                />
                                <Button
                                  type="link"
                                  onClick={() => {
                                    remove(name);

                                    const newFilters = filters.filter(
                                      (val, ind) => ind !== name
                                    );

                                    setFilters([...newFilters]);
                                  }}
                                >
                                  <CloseIcon />
                                </Button>
                                <Divider />
                              </div>
                            ))}
                            <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                className="quotation-add-btn"
                              >
                                {t('configurator.add_product')}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Form>
                  </Modal>
                </Title>

                <div style={{ width: '110px' }}>
                  <hr
                    style={{
                      backgroundColor: 'black',
                      height: '3px',
                      border: 'none',
                      marginBottom: '18px'
                    }}
                  />
                </div>
                <Row style={{ minHeight: '100px' }}>
                  {datas.products &&
                    datas.products.map((product) => {
                      return (
                        <Col style={{ padding: '6px 6px 6px 0' }}>
                          <Card style={{ width: '90%' }} bordered={false}>
                            <Row>
                              {`Catégorie :  `}
                              <b>{`${product.category}`}</b>
                            </Row>
                            <Row>
                              {`Produit : `}
                              <b>{`${product.product?.label}`}</b>
                            </Row>
                            <Row>
                              {`Quantité : `}
                              <b>{`${product.quantity}`}</b>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </>
    );
  }

  return <div>Loading</div>;
};

Products.propTypes = {
  datas: PropTypes.shape({
    accessories: PropTypes.array,
    products: PropTypes.array
  }).isRequired,
  products: PropTypes.shape({
    map: PropTypes.func,
    filter: PropTypes.func,
    _id: PropTypes.string,
    label: PropTypes.string,
    category: PropTypes.string,
    unit_price: PropTypes.number
  }).isRequired,
  forceRefresh: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

export default Products;
