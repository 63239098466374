import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateUser = ({ purpose }) => {
  const { fields, isFieldsLoading, base64 } = useFields();
  const { user, setUser } = useAuthContext();
  const { dispatchAPI } = useAuthContext();
  const [permissions, setPermissions] = useState([]);

  const getPermissions = useCallback(async () => {
    const { data } = await dispatchAPI('GET', { url: `/permissions` });
    setPermissions(data);
  }, []);

  useEffect(() => {
    (async () => {
      await getPermissions();
    })();
  }, [getPermissions]);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        const permission = permissions.find(
          ({ _id }) => _id === data.permission
        );
        if (data._id === user._id) setUser({ ...user, ...data, permission });
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {})
        };
      }
    },
    onCreateResource: {
      setBody: (data) => {
        const name = `${data.last_name
          .charAt(0)
          .toUpperCase()}${data.last_name.slice(1)}`;
        const date = moment(data.date_of_birth).format('YYYY');
        const password = `${name}${date}!`;
        return {
          ...data,
          password
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
