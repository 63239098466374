import React, { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';

const Columns = () => {
  const { dispatchAPI } = useAuthContext();
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const renderSuppliers = (value) => {
    let display = null;
    suppliers.map((e) => {
      if (e._id === value) {
        display = e.name;
      }
      return e;
    });
    return display;
  };
  const fetchCategories = async () => {
    const { data } = await dispatchAPI('GET', { url: '/categories' });
    setCategories(data);
  };

  const fetchData = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/contacts` });
    setSuppliers(data.data);
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchData();
  }, [fetchData]);
  return [
    {
      title: t('expenses.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: (date) => {
        return new Date(date).toLocaleDateString('fr-FR');
      }
    },
    {
      title: t('expenses.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('expenses.form.category'),
      key: 'category',
      dataIndex: 'category',
      sorter: true,
      render: (cat) => {
        try {
          const category = categories.filter((c) => c._id === cat)[0];
          return (
            <Tag color={category.color}>{category.label.toUpperCase()}</Tag>
          );
        } catch (e) {
          return null;
        }
      }
    },
    {
      title: t('expenses.form.amount'),
      key: 'amount_ht',
      dataIndex: 'amount_ht',
      sorter: true,
      render: (value) => {
        return `${value.toFixed(2)} €`;
      }
    },
    {
      title: t('expenses.form.supplier'),
      key: 'id_supplier',
      dataIndex: 'id_supplier',
      sorter: true,
      render: (id) => {
        return renderSuppliers(id);
      }
    }
  ];
};

export default Columns;
