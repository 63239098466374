import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Space,
  Col,
  Row,
  Card,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import { routes, subRoutes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import PieceTable from '../PieceTable';
import PieceEntryModal from './PieceEntryModal';
import PieceExitModal from './PieceExitModal';
import listMatterContent from './listMatterContent';
import listSiteContent from './listSiteContent';
import FileCard from './FileCard';
import Barcode from '../Barcode';

const ShowSupply = () => {
  const { id } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [supply, setSupply] = useState({});
  const { dispatchAPI, canEditResource } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [pieces, setPieces] = useState([]);
  const { Title } = Typography;

  const titleDivider = (
    <div style={{ width: '110px' }}>
      <hr
        style={{
          backgroundColor: 'black',
          height: '3px',
          border: 'none',
          marginBottom: '18px'
        }}
      />
    </div>
  );

  const refresh = () => {
    setForceRefresh(!forceRefresh);
  };

  const padding = { padding: '8px' };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await dispatchAPI('GET', {
        url: `/pieces/${id}?populate=supplier,buy_site,catalogue,localisation,rack,matter,customer,sizes.order,files`
      });
      setSupply(data.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [forceRefresh]);

  const savePiece = async (body) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/pieces/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const removeFile = async (file) => {
    const files = (supply?.files || []).filter((el) => el._id !== file._id);

    const body = { files };

    await savePiece(body);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteSupply = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/pieces/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const extraButton = () => {
    return (
      <Space>
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ danger: true }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={deleteSupply}
          icon={<WarningOutlined />}
        >
          <Button icon={<DeleteOutlined />} type="primary" danger>
            {`${t('buttons.delete').toUpperCase()} `}
          </Button>
        </Popconfirm>
        <Link
          to={{
            pathname: `${routes.STOCK}${subRoutes.STOCK.STOCKS}/edit/${id}`
          }}
        >
          <Button icon={<EditOutlined />} type="primary">
            {`${t('buttons.edit').toUpperCase()} `}
          </Button>
        </Link>
      </Space>
    );
  };

  return (
    <>
      <PageHeaderCustom
        title={t('stocks.show.title')}
        withSubRoutes
        extra={canEditResource(path) && extraButton()}
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <Row>
            <Col xs={24} xl={16} style={padding}>
              <Card
                style={{
                  backgroundColor: 'rgba(245,245,245,1)',
                  minHeight: '100%'
                }}
                bordered={false}
              >
                {t('stocks.show.lot_title')}
                {titleDivider}
                <DescriptionList
                  data={listContent(supply)}
                  backline
                  translate
                  column={4}
                />
              </Card>
            </Col>
            <Col xs={24} xl={8} style={padding}>
              <Card
                style={{ backgroundColor: 'rgba(245,245,245,1)' }}
                bordered={false}
              >
                {t('stocks.show.matter_title')}
                {titleDivider}
                <DescriptionList
                  backline
                  data={listMatterContent(
                    supply?.matter
                      ? {
                          ...supply.matter,
                          thickness: supply.thickness,
                          length: supply.length,
                          width: supply.width,
                          isBookmatch: supply.is_bookmatch
                            ? t('stocks.yes')
                            : t('stocks.no')
                        }
                      : {}
                  )}
                  translate
                  column={2}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12} xl={8} style={padding}>
              <Card
                style={{ backgroundColor: 'rgba(245,245,245,1)' }}
                bordered={false}
              >
                {t('stocks.show.site_title')}
                {titleDivider}
                <DescriptionList
                  data={listSiteContent(supply)}
                  backline
                  translate
                  column={4}
                />
              </Card>
            </Col>
            <Col xs={24} md={12} xl={8} style={padding}>
              <Card
                style={{
                  backgroundColor: 'rgba(245,245,245,1)',
                  minHeight: '100%'
                }}
                bordered={false}
              >
                {t('stocks.show.files_title')}
                {titleDivider}
                {(supply?.files || [])?.map((file) => (
                  <FileCard file={file} onDeleteFile={removeFile} />
                ))}
              </Card>
            </Col>
            <Col xs={24} md={12} xl={8} style={padding}>
              <Card
                style={{
                  backgroundColor: 'rgba(245,245,245,1)',
                  minHeight: '100%'
                }}
                bordered={false}
              >
                {t('stocks.show.comment_title')}
                {titleDivider}
                {supply?.comment || ''}
              </Card>
            </Col>
          </Row>
          <Row>
            <Barcode data={supply?.ref_internal} />
          </Row>
        </Skeleton>

        <Row justify="space-between" style={padding} align="middle">
          <Title level={4}>{t('stocks.show.move_title')}</Title>
          {canEditResource(path) && (
            <Row>
              <PieceEntryModal
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                usedNumbers={
                  supply?.sizes && (supply.sizes || []).map((s) => s.number)
                }
                setPieces={setPieces}
                pieces={pieces}
              />
              <PieceExitModal
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                supply={supply}
                setPieces={setPieces}
              />
            </Row>
          )}
        </Row>
        <PieceTable data={supply} refresh={refresh} />
      </ContentCustom>
    </>
  );
};

export default ShowSupply;
