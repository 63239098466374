import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Details from './Details/index';
import { ContextProvider } from './context';

const AcceptanceReportRouter = () => {
  const { path } = useRouteMatch();

  return (
    <ContextProvider>
      <Switch>
        <Route path={`${path}/show/:id`} component={Details} />
      </Switch>
    </ContextProvider>
  );
};

export default AcceptanceReportRouter;
