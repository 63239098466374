import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import useAuthContext from '../../../contexts/AuthContext';
import { mattersLabels } from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [labelsFilter, setLabelsFilter] = useState([]);

  const fetchLabels = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'dartymatters/enums' });
      setLabelsFilter(
        data.labels.map((label) => ({ text: label, value: label }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchLabels();
    })();
  }, [fetchLabels]);

  return [
    {
      title: t('catalogues.column.cle1'),
      key: 'cle1',
      dataIndex: 'cle1',
      sorter: true
    },
    {
      title: t('catalogues.column.cle2'),
      key: 'cle2',
      dataIndex: 'cle2',
      sorter: true
    },
    {
      title: t('catalogues.column.ref'),
      key: 'ref',
      dataIndex: 'ref',
      sorter: true
    },
    {
      title: t('catalogues.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true,
      render: (lab) =>
        lab && <Tag color={mattersLabels[lab]}>{lab.toUpperCase()}</Tag>,
      filters: labelsFilter
    },
    {
      title: t('catalogues.column.color'),
      key: 'color',
      dataIndex: 'color',
      sorter: true
    },
    {
      title: t('catalogues.column.group'),
      key: 'group',
      dataIndex: 'group',
      sorter: true
    },
    {
      title: t('catalogues.column.completions'),
      key: 'completions',
      dataIndex: 'completions',
      sorter: true
    }
  ];
};

export default useColumns;
