import React from 'react';
import {
  BellFilled,
  HourglassFilled,
  QuestionOutlined,
  CheckOutlined,
  LoginOutlined,
  CloseOutlined,
  DiffFilled,
  UnorderedListOutlined,
  HeatMapOutlined,
  WechatFilled,
  AlertFilled,
  BulbFilled,
  FormatPainterFilled,
  FlagFilled,
  BankFilled,
  ToolFilled,
  PhoneFilled,
  CreditCardFilled
} from '@ant-design/icons';
import {
  TrowelIcon,
  PlanIcon,
  MeasureIcon,
  HammerIcon,
  TruckIcon,
  HelmetIcon,
  BoxIcon,
  ClockIcon
} from '../../utils/constants/customIcon';

export const kanbanIcon = [
  {
    label: 'bell',
    value: <BellFilled />
  },
  {
    label: 'check',
    value: <CheckOutlined />
  },
  {
    label: 'plus',
    value: <HourglassFilled />
  },
  {
    label: 'question',
    value: <QuestionOutlined />
  },
  {
    label: 'login',
    value: <LoginOutlined />
  },
  {
    label: 'close',
    value: <CloseOutlined />
  },
  {
    label: 'diff',
    value: <DiffFilled />
  },
  {
    label: 'list',
    value: <UnorderedListOutlined />
  },
  {
    label: 'heatmap',
    value: <HeatMapOutlined />
  },
  {
    label: 'wechat',
    value: <WechatFilled />
  },
  {
    label: 'alert',
    value: <AlertFilled />
  },
  {
    label: 'bulb',
    value: <BulbFilled />
  },
  {
    label: 'painter',
    value: <FormatPainterFilled />
  },
  {
    label: 'flag',
    value: <FlagFilled />
  },
  {
    label: 'bank',
    value: <BankFilled />
  },
  {
    label: 'tool',
    value: <ToolFilled />
  },
  {
    label: 'phone',
    value: <PhoneFilled />
  },
  {
    label: 'creditcard',
    value: <CreditCardFilled />
  },
  {
    label: 'trowel',
    value: <TrowelIcon />
  },
  {
    label: 'plan',
    value: <PlanIcon />
  },
  {
    label: 'measure',
    value: <MeasureIcon />
  },
  {
    label: 'hammer',
    value: <HammerIcon />
  },
  {
    label: 'truck',
    value: <TruckIcon />
  },
  {
    label: 'helmet',
    value: <HelmetIcon />
  },
  {
    label: 'box',
    value: <BoxIcon />
  },
  {
    label: 'clock',
    value: <ClockIcon />
  }
];

export const kanbanColor = [
  '#fffff',
  '#e6f7ff',
  '#bae7ff',
  '#91d5ff',
  '#69c0ff',
  '#40a9ff',
  '#1890ff',
  '#096dd9',
  '#0050b3',
  '#003a8c',
  '#002766'
];
