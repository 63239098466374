import React from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateArticle = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="sites"
      resource="sites"
      loadingFields={isFieldsLoading}
    />
  );
};

CreateUpdateArticle.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateArticle;
