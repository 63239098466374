import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form, Col, Button, Row, Card, Typography } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import useLaysheetContext from '../LaysheetContext';

const { Title } = Typography;
const { Option } = Select;

export const SelectionCard = ({
  form,
  isValidDefinitevly,
  isValide,
  setIsValide
}) => {
  const {
    laySheets,
    isdisabled,
    setIsdisabled,
    setWorkSheetSelected,
    setCommercialSite,
    setDate,
    setMeasurementDate,
    setProductionSites,
    setCustomer,
    datas,
    state
  } = useLaysheetContext();
  const { t } = useTranslation();

  const [isExist, setIsExist] = useState(false);

  const handleValid = () => {
    const idAssociate = form.getFieldValue()?.associateWorkSheet?.value;
    setIsExist(false);
    if (idAssociate) {
      const laySheet = laySheets.filter(
        (laysheet) => laysheet.associateWorkSheet === idAssociate
      );
      if (laySheet.length === 0) {
        setWorkSheetSelected(idAssociate);
        setIsValide(true);
        setIsdisabled(true);

        datas.forEach((value) => {
          if (value._id === idAssociate) {
            setCommercialSite(value.commercial_site);
            setCustomer(value.customer);
            setProductionSites(value.production_sites);
            setDate(value.date);
            setMeasurementDate(value.measurement_date);
          }
        });
      } else setIsExist(true);
    }
  };

  const handleUpdate = () => {
    setIsValide(false);
    setIsdisabled(false);
  };

  return (
    <Col span={24} offset={0} style={{ padding: '24px 24px 0px 24px' }}>
      <Card style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
        <Title level={5}>
          {t('laysheet.form.titles.associated_worksheet')}
        </Title>
        <div style={{ width: '103px' }}>
          <hr
            style={{
              backgroundColor: 'black',
              height: '3px',
              border: 'none',
              marginBottom: '18px'
            }}
          />
        </div>
        <Row style={{ padding: '20px 24px 21px 82px' }}>
          {t('laysheet.form.messages.worksheet')}
        </Row>
        <Form.Item
          name={['associateWorkSheet']}
          style={{ padding: '0px 0px 0px 82px' }}
          rules={[
            {
              required: true,
              message: t('laysheet.form.messages.worksheet-error')
            }
          ]}
        >
          <Select
            labelInValue
            disabled={isdisabled}
            onChange={() => setIsExist(false)}
            style={{ width: '60%', padding: '0px' }}
          >
            {datas &&
              datas.map(({ _id, order }, index) => (
                <Option value={_id}>{`${order.number}-FT${index + 1}`}</Option>
              ))}
          </Select>
        </Form.Item>
        {isExist && (
          <div
            style={{
              padding: '5px 24px 21px 82px',
              color: 'red',
              fontSize: 15
            }}
          >
            {t('laysheet.form.messages.already-taken')}
          </div>
        )}
        <Row style={{ padding: '0px 24px 24px 200px' }}>
          {!isValide ? (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={handleValid}
              disabled={isExist}
            >
              {t('buttons.validate')}
            </Button>
          ) : (
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={handleUpdate}
              disabled={isValidDefinitevly || state.laysheet}
            >
              {t('buttons.edit')}
            </Button>
          )}
        </Row>
      </Card>
    </Col>
  );
};

SelectionCard.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isValidDefinitevly: PropTypes.bool.isRequired,
  isValide: PropTypes.bool.isRequired,
  setIsValide: PropTypes.func.isRequired
};
