import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Divider, Col } from 'antd';
import useOrderContext from './OrderContext';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import OptionsRow from './OptionsRow';

const iconSize = 18;

const ListOrders = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { range } = useOrderContext();
  const { pathname } = location;
  const [extraQuery, setExtraQuery] = useState(null);
  const [isDownloading, setIsDownloading] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [enums, setEnums] = useState([]);

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?label=template_orders'
      });
      setTemplate(data[0].value);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteOrder = async (id, quotation) => {
    try {
      await dispatchAPI('DELETE', { url: `/orders/${id}` });
      await unvalidateQuotation(quotation._id);
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unvalidateQuotation = async (id) => {
    const body = {
      status: 'PENDING'
    };
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/ordersnew/enums` });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/ordersnew/${id}/${template}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location = url;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  useEffect(() => {
    if (range && range.length)
      setExtraQuery(`emission_date>=${range[0]}&emission_date<=${range[1]}`);
    else setExtraQuery(null);
  }, [range]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplateSetting();
    await getEnums();
    setIsLoading(false);
  }, [forceRefresh]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, number, quotation }) => {
        return (
          <span>
            <Link
              to={{
                pathname: `${pathname}/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            {template && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={async () => {
                    if (!isDownloading[key]) {
                      setIsDownloading({ ...isDownloading, [key]: true });
                      await downloadFile(key, `${number}.pdf`);
                    }
                  }}
                >
                  {isDownloading[key] ? (
                    <LoadingOutlined />
                  ) : (
                    <DownloadOutlined />
                  )}
                </Button>
              </>
            )}
            {/* <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteOrder(key, quotation)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm> */}
          </span>
        );
      }
    }
  ];

  const headers = [
    {
      label: 'Number',
      key: 'number'
    },
    {
      label: 'Emission date',
      key: 'emission_date'
    },
    {
      label: 'Reception date',
      key: 'reception_date'
    },
    {
      label: 'Subject',
      key: 'subject'
    },
    {
      key: 'comment',
      label: 'Comment'
    },
    {
      key: 'customer.name',
      label: 'Customer name'
    },
    {
      label: 'Contact first name',
      key: 'contact.first_name'
    },
    {
      label: 'Contact last name',
      key: 'contact.last_name'
    },
    {
      label: 'Quotation number',
      key: 'quotation.number'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="ordersnew"
      columns={[...columns(t, enums), ...actionColumn]}
      extraQuery={extraQuery}
      customActionColumn
      withUploadButton={false}
      withCreateButton={false}
      headers={headers}
      forceRefresh={forceRefresh}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListOrders;
