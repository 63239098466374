import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Tag } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { profesionalsTypes } from '../../utils/constants/tagColors';

const { Option } = Select;

const ContactsForm = () => {
  const { t } = useTranslation();
  const [matter, setMatter] = useState([]);
  const [thickness, setThickness] = useState([2, 3]);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getMatter = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/matters' });
      setMatter(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getMatter();
    })();
  }, []);

  return (
    <Form.List name="supplies">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <>
              <div style={{ fontSize: 14 }}>
                {`${t('supplies.form.matter')} ${index + 1} :`}
              </div>
              <Form.Item
                label={t('supplies.form.matter')}
                name={[field.name, 'matter']}
                validateTrigger={['onChange', 'onBlur']}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={t('supplies.form.matter')}
                  filterOption={(inputValue, option) =>
                    option.children.props.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {(matter || []).map((item) => (
                    <Option key={item._id} value={item._id}>
                      <Tag color={profesionalsTypes[item.label]}>
                        {item.ref}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t('supplies.form.finition')}
                name={[field.name, 'finition']}
                validateTrigger={['onChange', 'onBlur']}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  showSearch
                  placeholder={t('supplies.form.finition')}
                  filterOption={(input, option) =>
                    option.value.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {(matter || []).map((item) => (
                    <Option key={item._id} value={item.completions}>
                      {item.completions}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t('supplies.form.thickness')}
                name={[field.name, 'thickness']}
                validateTrigger={['onChange', 'onBlur']}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select placeholder={t('supplies.form.thickness')}>
                  {(thickness || []).map((item) => (
                    <Option key={item} value={item}>
                      {item} cm
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t('supplies.form.quantity')}
                name={[field.name, 'quantity']}
                validateTrigger={['onChange', 'onBlur']}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Input type="number" placeholder="Quantité" />
              </Form.Item>

              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
            >
              {t('ordersPurchases.addMatter')}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
export default ContactsForm;
