import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';
import OptionsRow from './OptionsRow';

const ListArticles = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState('pro=true');
  const [filters, setFilters] = useState({});

  const setFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  useEffect(() => {
    let query;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        if (query) {
          query += `&${key}=${filters[key]}&pro=true`;
        } else {
          query = `${key}=${filters[key]}&pro=true`;
        }
      }
    });
    setExtraQuery(query);
  }, [filters]);

  return (
    <SimpleResourceLandingLayout
      resourceName="quotes"
      columns={columns}
      withSubRoutes
      withCreateButton={false}
      extraQuery={extraQuery}
      withUploadButton={false}
      resourceModelName="Quote"
      customActionColumn
      pagination
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} setFilter={setFilter} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListArticles;
