import { message } from 'antd';

const getRedirectionFlow = async (dispatchAPI, user, t) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless',
    body: { user: user._id }
  });
  if (response.data) return response.data.redirect_url;

  message.error(t('payment.gc.errors.getdirectionflow'));
};

const completeRedirectionFlow = async (
  dispatchAPI,
  user,
  t,
  redirect_flow_id,
  setUser
) => {
  const response = await dispatchAPI('PATCH', {
    url: '/payment-system/gocardless',
    body: { user, redirect_flow_id }
  });
  if (!response.data.errors) {
    const newUser = response.data.user;

    if (newUser !== undefined) {
      setUser(newUser);

      await dispatchAPI('PATCH', {
        url: `users/${newUser._id}`,
        body: { newUser }
      });
      return newUser;
    }
    message.error(t('payment.gc.errors.userUndefined'));

    // return (newUser);
  } else {
    message.error(t('payment.gc.errors.confirmdirectionflow'));
  }
};

const launchPayment = async (dispatchAPI, user, amount, t, ref) => {
  const response = await dispatchAPI('POST', {
    url: '/payment-system/gocardless/payment',
    body: { user, amount, reference: ref }
  });
  if (response.data) {
    message.success(t('payment.gc.success.paymentSuccess'));
    return response.data;
  }

  message.error(t('payment.gc.errors.paymentFailed'));
  return null;
};

const launchSubscription = async (dispatchAPI, user, amount, t, ref) => {
  const res = await dispatchAPI('POST', {
    url: '/payment-system/gocardless/subscription',
    body: { user, amount, reference: ref }
  });
  if (res.data) {
    message.success(t('payment.gc.success.subscriptionSuccess'));
    return res.data;
  }

  message.error(t('payment.gc.errors.subscriptionFailed'));
  return null;
};

const pay = (type, dispatchAPI, user, amount, t, ref) => {
  if (type === 'payment') launchPayment(dispatchAPI, user, amount, t, ref);
  else if (type === 'subscription')
    launchSubscription(dispatchAPI, user, amount, t, ref);
  else message.error(t('payment.gc.errors.gcTypeMissing'));
};

export { getRedirectionFlow, completeRedirectionFlow, pay };
