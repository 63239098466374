import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Spin } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from './fieldsContact';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const ContactsDrawer = (id) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const [visible, setVisible] = useState(false);
  const [contact, setContact] = useState(null);
  const { fields, isFieldsLoading } = useFields(visible);
  const history = useHistory();

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setContact(null);
  };

  const onFinishForm = async (values) => {
    try {
      const body = {
        ...values,
        deal: id.id
      };
      await dispatchAPI('POST', { url: `/contacts`, body });
      onClose();
      history.go(0);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <>
      <Button
        type="primary"
        style={{ float: 'right' }}
        onClick={() => setVisible(!visible)}
      >
        <PlusCircleOutlined />
      </Button>
      <Drawer
        title={t(`contacts.show.add`)}
        width={320}
        onClose={onClose}
        visible={visible}
      >
        <Spin spinning={isLoading}>
          <Form
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            form={form}
            onFinish={onFinishForm}
          >
            {fields.map((field) => generateFormItem('contacts', field))}
            <Form.Item style={{ marginTop: 16 }}>
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={onClose}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default ContactsDrawer;
