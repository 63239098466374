import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Input, Row, Select, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import KanbanColumn from './KanbanColumn';
import useBoxContext from './DealsContext';
import MarbleBackground from '../../assets/images/white-stone_Scott-Webb.jpg';
import ArchivedColumn from './ArchiveColumn';

const { Search } = Input;
const formatDate = 'DD/MM/YYYY';

const KanbanBoard = ({ archived }) => {
  const { t } = useTranslation();
  const {
    fetchKanbanColumns,
    kanbanCols,
    setFilters,
    sites,
    setSearchValue
  } = useBoxContext();
  const [mesurementRange, setMesurementRange] = useState([]);
  const [layDatesRange, setLayDatesRange] = useState([]);
  const [commercialSite, setCommercialSite] = useState();
  const [productionSites, setProductionSite] = useState();

  const sortedKanbanCols = kanbanCols.sort((a, b) => {
    return b.index - a.index;
  });

  useEffect(() => {
    (async () => {
      await fetchKanbanColumns();
    })();
  }, []);

  const ExtraQuery = [
    mesurementRange[0] && `&estimated_date_measure>=${mesurementRange[0]}`,
    mesurementRange[1] && `&estimated_date_measure<=${mesurementRange[1]}`,
    layDatesRange[0] && `&estimated_date_lay>=${layDatesRange[0]}`,
    layDatesRange[1] && `&estimated_date_lay<=${layDatesRange[1]}`,
    `&commercial_site=${commercialSite ||
      `${sites.map((el) => el._id).join(',')}`}`,
    `&production_sites=${productionSites || ''}`
  ]
    .filter(Boolean)
    .join('');

  useEffect(() => {
    if (
      sites &&
      (mesurementRange || layDatesRange || productionSites || commercialSite)
    )
      setFilters(ExtraQuery);
    else setFilters('');
  }, [mesurementRange, layDatesRange, productionSites, commercialSite, sites]);

  const onChangeDates = (dates, callBack, index) => {
    const selectedDate = moment(dates);

    if (dates && index === 1)
      callBack((prev) => [
        selectedDate.startOf('day'),
        prev[index] && moment(prev[index]).endOf('day')
      ]);
    else if (dates && index === 0)
      callBack((prev) => [
        prev[index] && moment(prev[index]).startOf('day'),
        selectedDate.endOf('day')
      ]);
    else callBack([]);
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 16]}>
        <Col>
          <Row style={{ marginBottom: 10, width: 450 }}>
            <Search
              placeholder={t('trellos-cards.filter.search')}
              onSearch={(value) => {
                setSearchValue(value);
              }}
            />
          </Row>
          <Row align="middle">
            <Select
              mode="multiple"
              allowClear
              style={{ margin: '3px 6px 3px 0', width: 200 }}
              onChange={(value) => setCommercialSite(value)}
              placeholder={t('trellos-cards.filter.commercial_site')}
              showArrow
              options={
                sites && sites.map(({ _id, label }) => ({ label, value: _id }))
              }
            />
            <Select
              mode="multiple"
              allowClear
              style={{ margin: '3px 6px 3px 0', width: 200 }}
              onChange={(value) => setProductionSite(value)}
              placeholder={t('trellos-cards.filter.production_sites')}
              showArrow
              options={
                sites && sites.map(({ _id, label }) => ({ label, value: _id }))
              }
            />
            <Col style={{ padding: '0 20px 0 16px' }}>
              <Row align="middle">
                <Space>
                  {t('trellos-cards.filter.measurement')}
                  <DatePicker
                    format={formatDate}
                    value={mesurementRange[0]}
                    onChange={(dates) =>
                      onChangeDates(dates, setMesurementRange, 1)
                    }
                  />
                  {t('trellos-cards.filter.to')}
                  <DatePicker
                    format={formatDate}
                    value={mesurementRange[1]}
                    onChange={(dates) =>
                      onChangeDates(dates, setMesurementRange, 0)
                    }
                  />
                </Space>
              </Row>
            </Col>
            <Col>
              <Row align="middle">
                <Space>
                  {t('trellos-cards.filter.lay')}
                  <DatePicker
                    format={formatDate}
                    value={layDatesRange[0]}
                    onChange={(dates) =>
                      onChangeDates(dates, setLayDatesRange, 1)
                    }
                  />
                  {t('trellos-cards.filter.to')}
                  <DatePicker
                    format={formatDate}
                    value={layDatesRange[1]}
                    onChange={(dates) =>
                      onChangeDates(dates, setLayDatesRange, 0)
                    }
                  />
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              backgroundImage: `url(${MarbleBackground})`,
              backgroundSize: 'cover'
            }}
          >
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll'
              }}
            >
              {sortedKanbanCols
                .filter((col) => col.show)
                .map((col) => (
                  <KanbanColumn column={col} key={col.label} />
                ))}
            </div>

            <div>{archived && <ArchivedColumn />}</div>
          </div>
        }
      </Row>
    </>
  );
};

KanbanBoard.propTypes = {
  archived: PropTypes.bool.isRequired
};

export default KanbanBoard;
