import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListQuotations from './ListQuotations';
import CreateUpdate from './CreateUpdate';
import ShowQuotation from './ShowQuotation';
import { AccountingContextProvider } from '../AccountingContext';

const QuotationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AccountingContextProvider collection="quotations" type="quotation">
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdate purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdate purpose="edit" />}
        />
        <Route
          path={`${path}/show/:id`}
          render={() => <ShowQuotation resource="quotations" />}
        />
        <Route path={path} component={ListQuotations} />
      </Switch>
    </AccountingContextProvider>
  );
};

export default QuotationRouter;
