import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListLabors = () => {
  const headers = [
    {
      label: 'Label',
      key: 'label' || ''
    },
    {
      label: 'Description',
      key: 'description'
    },
    {
      label: 'Quantity',
      key: 'quantity'
    },
    {
      label: 'Reference',
      key: 'ref'
    },
    {
      label: 'Solde price',
      key: 'sold_price' || ''
    },
    {
      label: 'Buy price',
      key: 'buy_price' || ''
    },
    {
      label: 'Category',
      key: 'category.label'
    },
    {
      label: 'TVA',
      key: 'tva'
    },
    {
      label: 'LD product',
      key: 'ld_product'
    },
    {
      label: 'In CA',
      key: 'in_ca'
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="labors"
      columns={[...columns()]}
      withSubRoutes
      headers={headers}
      resourceModelName="Labor"
    />
  );
};

export default ListLabors;
