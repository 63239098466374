import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { sitesTypes } from '../../utils/constants/tagColors';
import ContactsForm from './contactForm';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [sites, setSites] = useState([]);
  const [building_site, setBuildingSites] = useState([]);
  const basicFields = [
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select>
          {(sites || []).map((item) => (
            <Option key={item._id} value={item._id}>
              <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['type'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select>
          {(enums.type || []).map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'channel_number',
      name: ['address', 'number'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'street',
      name: ['address', 'street'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      input: <Input type="number" />,
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'city',
      name: ['address', 'city'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['siren_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['ape_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'email',
      name: ['email']
    },
    {
      name: ['phone_number']
    }
  ];
  const advancedFields = [
    {
      noLabel: true,
      input: <ContactsForm />
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBuildingSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/building-sites' });
      setBuildingSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
      await getEnums();
      await getSites();
      await getBuildingSites();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
