import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, PageHeader } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../ContentCustom/ContentCustom';
import PageHeaderCustom from '../PageHeader/PageHeader';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import DocumentScan from '../../routes/Documents/Scan/DocumentScan';
import { ScanContextProvider } from '../../routes/Documents/Scan/ScanContext';

const modes = {
  FORM: 'FORM',
  SCAN: 'SCAN'
};

const CreateUpdateContainer = ({
  purpose,
  fields,
  loadingFields,
  resource,
  baseUrl,
  config,
  formExtra,
  tradKey,
  withSubRoutes,
  submitLabel,
  customSubmit,
  customCancel,
  isParentLoading,
  isModal,
  customForm
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(modes.FORM);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { onGetResource, onCreateResource, onUpdateResource } = config;

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${id}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? await onUpdateResource.setBody(body)
            : body
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `${baseUrl}`,
        body:
          onCreateResource && onCreateResource.setBody
            ? onCreateResource.setBody(body)
            : body
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `${baseUrl}/${id}` });
      let newData = data;
      if (data.removal_date) {
        newData = { ...newData, removal_date: moment(data.removal_date) };
      }
      newData = { ...newData, order_date: moment(data.order_date) };
      (customForm || form).setFieldsValue(
        onGetResource && onGetResource.setFields
          ? onGetResource.setFields(data)
          : newData
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [purpose, id, loadingFields]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      if (!loadingFields)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  const handleSubmit = async (values) => {
    if (customSubmit) {
      customSubmit(values);
      (customForm || form).resetFields();
      return;
    }
    if (purpose === 'edit') await updateResource(values);
    if (purpose === 'create') await createResource(values);
  };

  const header = isModal ? (
    <PageHeader
      title={t(`${resource}.form.title.${purpose}`)}
      onBack={() => {
        if (customCancel) customCancel();
        else history.goBack();
        (customForm || form).resetFields();
      }}
      ghost={false}
    />
  ) : (
    <PageHeaderCustom
      title={t(`${resource}.form.title.${purpose}`)}
      withSubRoutes={withSubRoutes}
    />
  );

  return (
    <>
      {header}

      <ContentCustom>
        <Spin spinning={isLoading || isParentLoading}>
          {mode === modes.SCAN && (
            <ScanContextProvider>
              <DocumentScan
                resource={resource === 'customers' ? 'companies' : resource}
              />
            </ScanContextProvider>
          )}
          {mode === modes.FORM && (
            <Form
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...formItemLayout}
              onFinish={handleSubmit}
              form={customForm || form}
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => {
                      if (customCancel) customCancel();
                      else history.goBack();
                      (customForm || form).resetFields();
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button type="add" htmlType="submit">
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Spin>
      </ContentCustom>
    </>
  );
};

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  withSubRoutes: PropTypes.bool,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  customCancel: PropTypes.func,
  customForm: PropTypes.shape({}),
  isParentLoading: PropTypes.bool,
  isModal: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  withSubRoutes: false,
  submitLabel: null,
  customSubmit: null,
  customCancel: null,
  customForm: null,
  isParentLoading: false,
  isModal: false
};

export default CreateUpdateContainer;
