import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import OrderTable from './OrderTable';

const { TabPane } = Tabs;

const TablesTab = ({ profesional }) => {
  const [tabSelected, setTabSelected] = useState(1);

  return (
    <Tabs defaultActiveKey="1" onTabClick={setTabSelected}>
      <TabPane tab="Liste des matériaux commandés" key="1">
        <OrderTable data={profesional} />
      </TabPane>
    </Tabs>
  );
};

TablesTab.propTypes = {
  profesional: PropTypes.shape({}).isRequired
};

export default TablesTab;
