import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import Datatable from '../../../components/DataTable/Datatable';

const { Content } = Layout;
const iconSize = 18;

const OrdersTable = ({ populate, extraQuery, path }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('orders.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('orders.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('orders.list.column.title.total'),
      dataIndex: 'prices',
      render: (prices) => `${parseFloat(prices.totalNet).toFixed(2)}€`
    },

    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => {
        return (
          <span>
            <Link
              to={{
                pathname: `${path}/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `${path}/edit/${key}`
              }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          </span>
        );
      }
    }
  ];

  return (
    <>
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Datatable
          resourceName="orders"
          columns={columns}
          populate={populate}
          extraQuery={extraQuery}
          customActionColumn
          withCreateButton={false}
          path={path}
        />
      </Content>
    </>
  );
};

OrdersTable.propTypes = {
  extraQuery: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  populate: PropTypes.string.isRequired
};

export default OrdersTable;
