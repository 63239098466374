import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Form,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Card,
  Typography,
  Input
} from 'antd';

const { Option } = Select;

const WorkSheetForm = ({ datas, sites, isEditable, form }) => {
  const { t } = useTranslation();
  const { Title } = Typography;

  const handleCheckPointManager = (e) => {
    form.setFieldsValue({ point_manager: e.target.checked });
  };

  const handleCheckQualityManager = (e) => {
    form.setFieldsValue({ quality_manager: e.target.checked });
  };

  const handleCheckDisponibility = (e) => {
    form.setFieldsValue({ disponibility: e.target.checked });
  };

  return (
    <Col span={24} style={{ height: 'fit-content' }}>
      <Card style={{ background: 'rgba(255, 255, 255, 0.9)' }} bordered={false}>
        <div>
          <Title level={4}>{t('worksheets.title.info')}</Title>
          <div style={{ width: '130px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          <Row>
            <Col xs={22} xxl={10} offset={1}>
              <Form.Item
                name={['commercial_site']}
                label={t('worksheets.form.commercial_site')}
              >
                <Select
                  disabled
                  options={[...new Set(sites)].map(({ label, _id }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
              <Form.Item
                name={['production_sites']}
                label={t('worksheets.form.production_sites')}
              >
                <Select
                  disabled
                  options={[...new Set(sites)].map(({ label, _id }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
              <Form.Item
                name={['customer']}
                label={t('worksheets.form.customer')}
              >
                <Select
                  disabled
                  options={[datas.datas.infos.customer].map(
                    ({ _id, first_name, last_name }) => ({
                      label: `${first_name || ''} ${last_name}`,
                      value: _id
                    })
                  )}
                />
              </Form.Item>

              <Form.Item
                name={['lay_date']}
                label={t('worksheets.form.lay_date')}
              >
                <DatePicker
                  disabled={datas.datas.placed.without_lay}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
              <Form.Item
                name={['measurement_date']}
                label={t('worksheets.form.measurement_date')}
              >
                <DatePicker
                  disabled={datas.datas.placed.without_lay}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
              <Form.Item
                name={['placed']}
                label={t('worksheets.form.placed')}
                rules={[{ required: false }]}
                disabled={!isEditable}
              >
                <Select style={{ width: '100%' }} disabled={!isEditable}>
                  <Option value="pose">{t('worksheets.form.pose')}</Option>
                  <Option value="nopose">{t('worksheets.form.nopose')}</Option>
                  <Option value="delivery">
                    {t('worksheets.form.delivery')}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={['disponibility']}
                label={t('worksheets.form.disponibility')}
                valuePropName="checked"
              >
                <Checkbox
                  defaultChecked={datas?.worksheet?.disponibility || null}
                  onClick={handleCheckDisponibility}
                />
                {t('worksheets.form.yes')}
              </Form.Item>
              <Form.Item
                label={t('worksheets.form.finition_site')}
                name={['finition_site']}
              >
                <Select
                  options={sites.map(({ label, _id }) => ({
                    label,
                    value: _id
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={22} xxl={11} offset={1}>
              <Row>
                <Col span={8}>
                  <Row style={{ padding: '10px 0px 5px 0px' }}>
                    {t('worksheets.form.status')}
                  </Row>
                  <Row style={{ padding: '10px 0px 5px 0px' }}>
                    {t('worksheets.form.point_manager')}
                  </Row>
                  <Row style={{ padding: '10px 0px 0px 0px' }}>
                    {t('worksheets.form.quality_manager')}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <div style={{ padding: '0px 0px 0px 0px' }}>
                        <Form.Item name={['status']}>
                          <Select
                            style={{ width: '150%' }}
                            disabled={!isEditable}
                          >
                            <Option value="waiting">
                              {t('worksheets.form.waiting')}
                            </Option>
                            <Option value="ongoing">
                              {t('worksheets.form.ongoing')}
                            </Option>
                            <Option value="finished">
                              {t('worksheets.form.finished')}
                            </Option>
                            <Option value="late">
                              {t('worksheets.form.late')}
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        name={['point_manager']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked={
                            datas?.worksheet?.point_manager || null
                          }
                          disabled={!isEditable}
                          onClick={handleCheckPointManager}
                        />
                        {t('worksheets.form.yes')}
                      </Form.Item>

                      <Form.Item
                        name={['quality_manager']}
                        valuePropName="checked"
                      >
                        <Checkbox
                          defaultChecked={
                            datas?.worksheet?.quality_manager || null
                          }
                          disabled={!isEditable}
                          onClick={handleCheckQualityManager}
                        />
                        {t('worksheets.form.yes')}
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name={['name_chef']}
                        label={t('worksheets.form.name')}
                        disabled={!isEditable}
                      >
                        <Input disabled={!isEditable} />
                      </Form.Item>
                      <Form.Item
                        name={['name_respo']}
                        label={t('worksheets.form.name')}
                        disabled={!isEditable}
                      >
                        <Input disabled={!isEditable} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

WorkSheetForm.propTypes = {
  datas: PropTypes.shape({
    datas: PropTypes.shape({
      infos: PropTypes.shape({
        customer: PropTypes.any,
        production_site: PropTypes.shape({
          label: PropTypes.string
        }),
        commercial_site: PropTypes.shape({
          label: PropTypes.string
        })
      }),
      placed: PropTypes.shape({
        lay_date: PropTypes.any,
        without_lay: PropTypes.any
      })
    }),
    worksheet: PropTypes.shape({
      customer: PropTypes.any,
      matter: PropTypes.any,
      plan: PropTypes.shape({
        split: PropTypes.func
      }),
      point_manager: PropTypes.any,
      quality_manager: PropTypes.any,
      disponibility: PropTypes.bool
    })
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

export default WorkSheetForm;
