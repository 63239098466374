import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useListContent = ({
  cle1,
  cle2,
  group,
  ref,
  label,
  completions,
  color,
  coef
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [coefs, setCoefs] = useState();

  const fetchCoefs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-coefs`
      });
      setCoefs(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const editCoef = async (value) => {
    const body = {
      coef: value
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/dartymatters/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    fetchCoefs();
  }, []);

  return [
    {
      label: t('catalogues.column.cle1'),
      content: cle1,
      span: 3
    },
    {
      label: t('catalogues.column.cle2'),
      content: cle2,
      span: 3
    },
    {
      label: t('catalogues.column.ref'),
      content: ref,
      span: 3
    },
    {
      label: t('catalogues.column.label'),
      content: label,
      span: 3
    },
    {
      label: t('catalogues.column.group'),
      content: group,
      span: 3
    },
    {
      label: t('catalogues.column.color'),
      content: color,
      span: 3
    },
    {
      label: t('catalogues.column.completions'),
      content: completions,
      span: 3
    },
    {
      label: t('catalogues.column.coef'),
      span: 3,
      content: (
        <Select
          defaultValue={coef && coef?.coef}
          style={{ width: 100 }}
          options={
            coefs &&
            coefs.map((el) => ({
              label: el.label,
              value: el._id
            }))
          }
          onSelect={editCoef}
        />
      )
    }
  ];
};

export default useListContent;
