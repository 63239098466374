import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const Context = createContext({});

export const ContextProvider = ({ children, collection }) => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { state } = useLocation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [template, setTemplate] = useState();
  const [refresh, setRefresh] = useState(false);
  const [sites, setSites] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuote = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/${id}`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuote1 = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/populated/${id}`
      });

      const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});

      return filteredData;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const duplicateResource = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes/duplicate/${id}`
      });

      history.push(`/customers_accounting/quotes/show/${data}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteResource = async (id) => {
    const body = {
      status: 'DELETED'
    };
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body, id) => {
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      history.push(`/customers_accounting/quotes/show/${id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/${collection}`,
        body
      });
      history.push(`/customers_accounting/quotes/show/${data}`);
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=QUOTE&template=true`
      });
      setTemplate(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (
    id,
    name,
    callback,
    withDetails,
    outputType = 'pdf'
  ) => {
    try {
      const goodTemplate = withDetails
        ? template.find(
            (el) =>
              el.metadata.originalName === 'fix_quotation_values_template.docx'
          )
        : template.find(
            (el) => el.metadata.originalName === 'undetailedQuote.docx'
          );

      const response = await dispatchAPI('GET', {
        url: `/files/generate/quote/${id}/${goodTemplate._id}?withdetails=true&outputType=${outputType}`,
        responseType: 'blob'
      });

      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (callback) callback();
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    await getTemplate();
    await getSites();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const resultComPro = (data) => {
    const {
      totalMatters,
      totalShapings,
      totalsAccessories,
      totalPro,
      comPro
    } = data;
    let result = 0;
    if (data && data.comWithLay) {
      result = totalPro * (comPro / 100);
    } else {
      const fixComPro = totalMatters + totalShapings + totalsAccessories;
      result = fixComPro - fixComPro / ((100 + comPro) / 100);
    }
    return result;
  };

  return (
    <Context.Provider
      value={{
        collection,
        isLoading,
        setIsLoading,
        enums,
        updateResource,
        deleteResource,
        template,
        downloadFile,
        duplicateResource,
        state,
        refresh,
        createResource,
        getQuote,
        getQuote1,
        sites,
        resultComPro
      }}
    >
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired
};

export default () => useContext(Context);
