import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { CheckSquareOutlined } from '@ant-design/icons';
import Repository from './DealCard';
import useBoxContext from './DealsContext';
import { kanbanIcon } from '../Settings/utilsKanban';
import useAuthContext from '../../contexts/AuthContext';

const Col = styled.div`
  position: relative;
  border-radius: 2px;
  min-width: 250px;
  height: 62vh;
  padding: 7px;
  overflow: hidden;
`;

const Title = styled.div`
  background: #929292;
  position: absolute;
  display: flex;
  justify-content: left;
  top: 7px;
  width: calc(100% - 14px);
  text-align: left;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  #icon {
    color: #fff;
  }
  #label {
    color: #fff;
    margin-left: 12px;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 57px;
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 57px);
  overflow-y: auto;
`;

const ArchivedColumn = () => {
  const { updateDeal, deals } = useBoxContext();
  const { user } = useAuthContext();

  return (
    <div style={{ background: 'rgba(0,0,0, 0.8)' }}>
      <Col>
        <Title style={{ backgroundColor: 'rgba(146, 146, 146, 1)' }}>
          <div id="icon">{<CheckSquareOutlined />}</div>
          <div id="label" style={{ lineHeight: 1 }}>
            Archives
          </div>
        </Title>
        <CardContainer>
          {deals
            .filter((deal) => deal.archived)
            .map((deal) => (
              <Repository deal={deal} key={deal._id} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

export default ArchivedColumn;
