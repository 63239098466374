import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

const OrderTable = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('ordersPurchases.product'),
      dataIndex: 'product'
    },
    {
      title: t('ordersPurchases.category'),
      dataIndex: 'category'
    },
    {
      title: t('ordersPurchases.point'),
      dataIndex: 'point'
    },
    {
      title: t('ordersPurchases.quantity'),
      dataIndex: 'quantity'
    },
    {
      title: t('ordersPurchases.price'),
      dataIndex: 'price',
      render: (prices) => `${numeral(prices).format('0,0.00')}`
    }
  ];
  const datas = [];
  if (data.supplies) {
    data.supplies.map((el, i) => {
      const result = {
        product: el?.product.label,
        category: el.product.category,
        point: el.product.points,
        price: el.product.sold_price,
        quantity: el.quantity
      };
      datas.push(result);
      return el;
    });
  }

  return <Table columns={columns} dataSource={datas} />;
};

OrderTable.propTypes = {
  data: PropTypes.shape({
    supplies: PropTypes.array
  }).isRequired
};

export default OrderTable;
