import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import AccessoriesTable from './accessoriesTable';

const AccessorySettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [accessories, setAccessory] = useState([]);

  const fetchAccessories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Accessories`
      });
      setAccessory(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addAccessories = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Accessories', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchAccessories();
  };

  const deleteAccessories = async (process) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-Accessories/${process._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchAccessories();
  };

  const editAccessories = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-Accessories/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchAccessories();
  };

  useEffect(() => {
    fetchAccessories();
  }, []);

  return (
    <>
      {accessories.length > 0 && (
        <AccessoriesTable
          accessorie={accessories}
          editAccessorie={editAccessories}
          deleteAccessorie={deleteAccessories}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addAccessories}
      >
        {t('settings.Accessories.addAccessorie')}
      </Button>
    </>
  );
};

export default AccessorySettings;
