import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowArticle from './ShowArticle';
import ListArticles from './ListArticles';
import CreateUpdateCommission from './CreateUpdateCommission';

const ProductionRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateCommission purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateCommission purpose="edit" />}
      />

      <Route path={`${path}/show/:id`} component={ShowArticle} />

      <Route path={`${path}`} component={ListArticles} />
    </Switch>
  );
};

export default ProductionRouter;
