import React, { useState } from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListCustomers = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);

  const headers = [
    {
      label: 'Genre',
      key: 'gender'
    },
    {
      label: 'Nom',
      key: 'last_name'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      populate="site,building_site"
      resourceName="customers"
      resourceModelName="customers"
      withSubRoutes
      extraQuery={extraQuery}
      headers={headers}
    >
      {/* <Col><OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} /></Col> */}
    </SimpleResourceLandingLayout>
  );
};

export default ListCustomers;
