import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';

const style = {
  background: 'black',
  color: 'white',
  border: 'black',
  margin: '3px'
};

const OptionsRow = ({ setExtraQuery }) => {
  const handleClick = (e) => {
    setExtraQuery(e);
  };

  return (
    <>
      <span>Filtrer par : </span>
      <Button style={style} onClick={() => handleClick('category=NATURAL')}>
        NATUREL
      </Button>
      <Button style={style} onClick={() => handleClick('category=SYNTHETIC')}>
        SYNTHETIQUE
      </Button>
      <Button style={style} onClick={() => handleClick('')}>
        ...TOUT
      </Button>
    </>
  );
};

OptionsRow.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};

export default OptionsRow;
