import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import DailyTable from './index';
import SAVRouter from '../SAV/SAVRouter';
import { PlanningContextProvider } from './PlanningContext';

const DailyTableRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PlanningContextProvider>
        <Route
          path={`${path}${subRoutes.PRODUCTION.PLANNING}`}
          component={DailyTable}
        />
      </PlanningContextProvider>
      <Route path={`${subRoutes.PRODUCTION.SAV}`} component={SAVRouter} />
    </Switch>
  );
};

export default DailyTableRouter;
