import { Col } from 'antd';
import React, { useState } from 'react';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';
import OptionsRow from './OptionsRow';

const ListBuildingSites = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);

  const headers = [];

  return (
    <SimpleResourceLandingLayout
      resourceName="building-sites"
      columns={columns}
      withSubRoutes
      populate="site,customer,profesional"
      headers={headers}
      resourceModelName="BuildingSite"
      extraQuery={extraQuery}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListBuildingSites;
