const useListContent = ({
  name,
  site,
  siren_number,
  ape_number,
  address,
  email,
  phone_number,
  commission,
  promotion
}) => {
  return [
    {
      label: 'profesionals.show.name',
      content: name,
      span: 2
    },
    {
      label: 'profesionals.show.sites',
      content: site ? site?.label : '-',
      span: 2
    },
    {
      label: 'profesionals.show.postal_code',
      content: address ? address.postal_code : '-',
      span: 2
    },
    {
      label: 'profesionals.show.city',
      content: address ? address.city : '-',
      span: 2
    },
    {
      label: 'profesionals.show.email',
      content: email,
      span: 2
    },
    {
      label: 'profesionals.show.phone_number',
      content: phone_number,
      span: 2
    },
    {
      label: 'profesionals.show.siren_number',
      content: siren_number,
      span: 2
    },
    {
      label: 'profesionals.show.ape_number',
      content: ape_number
    }
  ];
};

export default useListContent;
