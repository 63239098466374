import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Input, InputNumber, Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContactsForm from './contactsForm';

const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const [enumsGender, setEnumsGender] = useState([]);

  const fields = [
    {
      name: ['fileNumber'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['type'],
      rules: [{ required: false }],
      input: (
        <Select
          options={enums.map((item) => ({
            label: item,
            value: item
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['gender'],
      rules: [{ required: false }],
      input: (
        <Select
          options={enumsGender.map((item) => ({
            label: item,
            value: item
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['societyName'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name']
    },
    {
      name: ['last_name']
    },
    {
      name: ['adress'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['mobile_phone'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      name: ['comment'],
      input: <TextArea />
    },
    {
      name: ['matters'],
      input: (
        <>
          <Divider />
          <ContactsForm />
        </>
      )
    }
  ];

  const getEnumsTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/extraquotations/enums'
      });
      setEnums(data.types);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getEnumsGender = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/extraquotations/enums'
      });
      setEnumsGender(data.genders);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnumsTypes();
    await getEnumsGender();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields
  };
};

export default useFields;
