import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { companiesTypes } from '../../../utils/constants/tagColors';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [companies, setCompanies] = useState();

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, []);

  return [
    {
      title: t('contacts.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('contacts.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('contacts.form.type'),
      dataIndex: 'type',
      render: (type) =>
        type && (
          <Tag color={companiesTypes[type.toUpperCase()]}>
            {t(`companies.tags.${type.toUpperCase()}`)}
          </Tag>
        ),
      filters: [
        { text: t('companies.customer'), value: 'CUSTOMERS' },
        { text: t('companies.supplier'), value: 'SUPPLIERS' }
      ],
      sorter: true
    },
    {
      title: t('contacts.form.company'),
      dataIndex: 'my_company',
      render: (company) => company && company.name,
      sorter: true,
      filters:
        companies &&
        companies.map((c) => ({
          text: c.name,
          value: c._id
        }))
    },
    {
      title: t('contacts.form.email'),
      dataIndex: 'email',
      sorter: true
    }
  ];
};

export default useColumns;
