/* eslint-disable no-unused-expressions */
import { Button, Divider } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const useColumns = (
  start,
  setIsModalVisible,
  form,
  setSelected,
  setShow,
  setIsEdit,
  refresh
) => {
  const dates = [];
  const getDaysBetweenDates = (startDate, endDate) => {
    const now = startDate;
    const datesBetween = [];

    while (now.isSameOrBefore(endDate)) {
      datesBetween.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return datesBetween;
  };
  const verifyDate = (day, data, type) => {
    data.listeChantiers
      .filter((el) => el.type_sheet === type)
      .forEach((el) => {
        if (
          moment(el.date_start).format('YYYY-MM-DD') <=
            moment(day).format('YYYY-MM-DD') &&
          moment(el.date_end).format('YYYY-MM-DD') >=
            moment(day).format('YYYY-MM-DD')
        ) {
          form.setFieldsValue({
            idChantier: el._id,
            number: el.number,
            order: el.order,
            type_sheet: el.type_sheet,
            date_start_end: [moment(el.date_start), moment(el.date_end)]
          });
          if (el.type_sheet === 'workSheet') {
            setSelected(true);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              workSheet: el.workSheet._id
            });
            setShow(el.workSheet.date);
          } else {
            setSelected(false);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              laySheet: el.laySheet._id
            });
            setShow(el.laySheet.delivery_date);
          }
          setIsEdit(true);
          setIsModalVisible(true);
        }
      });
  };
  const displayButton = (list, data, day, type, btnColor, typeBtn) => {
    if (type && type?.includes(day.format('YYYY-MM-DD')))
      return (
        <div>
          <Button
            onClick={() => {
              verifyDate(day, data, typeBtn);
            }}
            style={{
              height: '50%',
              width: '100%',
              backgroundColor: btnColor,
              border: 'none',
              color: btnColor,
              borderRadius: '0px 0px'
            }}
          >
            .
          </Button>
        </div>
      );
    return [];
  };
  const [list, setList] = useState([]);
  const listDate = {};
  const getPlanning = () => {
    for (let index = 0; index < 42; index += 1) {
      const day = moment(start).add(index, 'days');
      if (
        day.format('dddd') === 'Sunday' ||
        day.format('dddd') === 'dimanche'
      ) {
        dates.push({
          title: (
            <Divider
              style={{
                backgroundColor: 'white',
                height: '25px',
                width: '2px',
                margin: 'auto'
              }}
              type="vertical"
            />
          ),
          date: day,
          dataIndex: day.format('YYYY-MM-DD'),
          width: '1%',
          align: 'center'
        });
      }
      ![0, 6].includes(day.day()) &&
        dates.push({
          title: day.format('D'),
          date: day,
          dataIndex: day.format('YYYY-MM-DD'),
          width: '2%',
          align: 'center',
          render: (_, data) => {
            const listeChantiers = data.listeChantiers;
            listeChantiers.forEach((chantier) => {
              if (
                moment(chantier.date_start).format('YYYY-MM-DD') ===
                  day.format('YYYY-MM-DD') ||
                moment(chantier.date_end).format('YYYY-MM-DD') ===
                  day.format('YYYY-MM-DD')
              ) {
                const datesToAdd = getDaysBetweenDates(
                  moment(chantier.date_start),
                  moment(chantier.date_end)
                );
                if (!listDate[data.key.key]) {
                  listDate[data.key.key] = {};
                }
                if (listDate[data.key.key][chantier.type_sheet]) {
                  listDate[data.key.key][chantier.type_sheet] = [...datesToAdd];
                } else {
                  listDate[data.key.key][chantier.type_sheet] = datesToAdd;
                }
              }
            });
            return {
              props: {
                style: {
                  padding: 0,
                  margin: 0,
                  height: 60
                }
              },
              children: !listDate
                ? []
                : [
                    displayButton(
                      listDate,
                      data,
                      day,
                      listDate[data.key.key]?.workSheet,
                      '#009999',
                      'workSheet'
                    ),
                    displayButton(
                      listDate,
                      data,
                      day,
                      listDate[data.key.key]?.laySheet,
                      '#cc9900',
                      'laySheet'
                    )
                  ]
            };
          }
        });
    }
    const newList = [];
    dates.forEach((day) => {
      if (
        !newList
          .map((el) => el.title)
          .includes(
            day.date
              .format('MMMM')
              .charAt(0)
              .toUpperCase() + day.date.format('MMMM').slice(1)
          )
      ) {
        newList.push({
          title:
            day.date
              .format('MMMM')
              .charAt(0)
              .toUpperCase() + day.date.format('MMMM').slice(1),
          children: [day]
        });
      } else {
        const month = newList.findIndex(
          (el) =>
            el.title ===
            day.date
              .format('MMMM')
              .charAt(0)
              .toUpperCase() +
              day.date.format('MMMM').slice(1)
        );
        newList[month].children.push(day);
      }
    });

    setList(newList);
  };
  useEffect(() => {
    getPlanning();
  }, [refresh, start]);
  const columns = [
    {
      title: 'Chantier',
      dataIndex: 'orderHeader',
      width: '15%',
      align: 'center'
    },
    ...list
  ];
  return {
    columns
  };
};

export default useColumns;
