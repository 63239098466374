import moment from 'moment';
import numeral from 'numeral';

const formatStatus = (status) => {
  if (status === 'WAITING') {
    return 'En attente';
  }
  if (status === 'VALIDATE') {
    return 'Validé';
  }
  if (status === 'FACTURÉ') {
    return 'FACTURÉ';
  }
  return status;
};

const formatDatesWorkSheet = (allWorkSheets, _id) => {
  const datesWorkSheet = {};
  const filteredWorkSheets = allWorkSheets?.filter(
    (workSheet) => workSheet?.order?._id === _id
  );
  filteredWorkSheets.forEach((worksheet) => {
    if (worksheet.date !== undefined) {
      if (datesWorkSheet[_id]) {
        datesWorkSheet[_id].push(worksheet.date);
      } else {
        datesWorkSheet[_id] = [worksheet.date];
      }
      datesWorkSheet[_id].sort().reverse();
    }
  });
  return datesWorkSheet;
};

export const formatData = (data, allWorkSheets) => {
  return data.map(({ _id, ...d }) => {
    const datesWorkSheet = formatDatesWorkSheet(allWorkSheets, _id);
    const estimatedDate = datesWorkSheet[_id]?.[0]
      ? moment(datesWorkSheet[_id][0]).format('DD/MM/YYYY')
      : 'N/R';

    return {
      ...d,
      status: formatStatus(d.status),
      created_at: moment(d.created_at).format('DD/MM/YYYY'),
      estimated_construction_date: estimatedDate,
      totals: {
        ...d.totals,
        totalClient: numeral(d.totals.totalClient).format('0,0.00'),
        totalsTTC: `${numeral(d.totals.totalsTTC).format('0,0.00')} € TTC`
      }
    };
  });
};
