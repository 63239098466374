import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, message, Form, Row, Button } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import HeaderPage from './HeaderPage';
import { DailyModal } from './DailyModal';
import { PlanningButtons } from './Buttons/PlanningButtons';
import usePlanningContext from './PlanningContext';

const DailyTable = () => {
  const draggleRef = React.createRef();
  const { t } = useTranslation();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const {
    refresh,
    setRefresh,
    isLoading,
    setIsLoading,
    dataSourceOrder,
    dataSources,
    setDataSources,
    workSheets,
    getWorkSheets,
    laySheets,
    getLaySheets,
    allOptions,
    deletePlanning
  } = usePlanningContext();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState(true);
  const [start, setStart] = useState();
  const [show, setShow] = useState(null);
  const [isRequire, setIsRequire] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  });

  const { columns } = useColumns(
    start,
    setIsModalVisible,
    form,
    setSelected,
    setShow,
    setIsEdit,
    refresh
  );

  const resetAll = () => {
    setIsModalVisible(false);
    setShow(null);
    setIsEdit(false);
    setIsRequire(false);
    form.resetFields();
  };

  const handleCancel = () => resetAll();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  const changeSheet = (e) => {
    if (e.target.value === 'workSheet') {
      setSelected(true);
      setShow(null);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        number: null,
        workSheet: null
      });
    } else {
      setSelected(false);
      setShow(null);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        number: null,
        laySheet: null
      });
    }
  };
  const handleChange = (value) => {
    if (value) {
      workSheets.forEach(({ _id, order, date }) => {
        if (_id === value) {
          if (date) {
            setShow(date);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              date_start_end: [moment(date), moment(date)]
            });
          } else {
            setShow('N/R');
          }
          form.setFieldsValue({
            ...form.getFieldsValue(),
            number: order.number,
            order: order._id
          });
        }
      });
      laySheets.forEach(({ _id, order, delivery_date }) => {
        if (_id === value) {
          if (delivery_date) {
            setShow(delivery_date);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              date_start_end: [moment(delivery_date), moment(delivery_date)]
            });
          } else {
            setShow('N/R');
          }
          form.setFieldsValue({
            ...form.getFieldsValue(),
            number: order.number,
            order: order._id
          });
        }
      });
    } else {
      setShow(null);
    }
  };
  useEffect(() => {
    if (selected) getWorkSheets();
    else getLaySheets();
  }, [selected]);

  const showModal = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      type_sheet: 'workSheet'
    });
    setSelected(true);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (
      form.getFieldsValue().type_sheet &&
      form.getFieldsValue().date_start_end &&
      (form.getFieldsValue().workSheet || form.getFieldsValue().laySheet)
    ) {
      const body = {
        ...form.getFieldsValue(),
        date_start: form.getFieldsValue().date_start_end[0],
        date_end: form.getFieldsValue().date_start_end[1]
      };

      const method = body.idChantier ? 'PATCH' : 'POST';
      const url = body.idChantier
        ? `/plannings/${body.idChantier}`
        : '/plannings';
      try {
        setIsLoading(true);
        await dispatchAPI(method, { url, body });
        message.success(
          body.idChantier
            ? t('planning.message.success.edit')
            : t('planning.message.success.new')
        );
      } catch (e) {
        if (e.response) message.error(e.response.message);
      }
      resetAll();
      setRefresh(!refresh);
    } else setIsRequire(true);
  };

  const deleteWorksite = () => {
    try {
      const idWorkSite = form.getFieldsValue().idChantier;
      deletePlanning(idWorkSite);
      message.success(t('planning.message.success.delete'));
      resetAll();
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('planning.show.title')} withSubRoutes />
      <ContentCustom>
        <HeaderPage
          showModal={showModal}
          dataSources={dataSources}
          dataSourceOrder={dataSourceOrder}
          setDataSources={setDataSources}
        />
        <PlanningButtons setStart={setStart} start={start} />
        <Form form={form}>
          <DailyModal
            disabled={disabled}
            isEdit={isEdit}
            isModalVisible={isModalVisible}
            setDisabled={setDisabled}
            canEditResource={canEditResource}
            path={path}
            handleOk={handleOk}
            handleCancel={handleCancel}
            bounds={bounds}
            draggleRef={draggleRef}
            onStart={onStart}
            deleteChantier={deleteWorksite}
            isRequire={isRequire}
            changeSheet={changeSheet}
            selected={selected}
            handleChange={handleChange}
            allOptions={allOptions}
            show={show}
          />
          <Row style={{ padding: 8 }}>
            <Table
              className="planning-table"
              columns={columns}
              dataSource={dataSources}
              loading={isLoading}
              pagination={false}
              tableLayout="fixed"
              sticky
              scroll={{ x: 800 }}
              style={{ padding: '0px 5px 0px 0px' }}
              size="small"
            />
          </Row>
          {canEditResource(path) && (
            <Row justify="end">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={showModal}
              >
                {t('planning.button.addConstruction')}
              </Button>
            </Row>
          )}
        </Form>
      </ContentCustom>
    </>
  );
};

export default DailyTable;
