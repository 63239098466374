/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/charts';

const PieOrderByPro = ({ orders }) => {
  const [datas, setDatas] = useState([]);
  const statistics = [];
  const Allprofessionals = [];
  useEffect(() => {
    if (orders.length) {
      orders.forEach((order) => {
        if (order.infos?.profesional) {
          Allprofessionals.push(
            order.infos.profesional.type === 'Autres'
              ? 'Autres'
              : `${order.infos.profesional.type}s`
          );
        }
      });
      Allprofessionals.sort();
      Allprofessionals.map((el) => {
        if (statistics[el]) {
          statistics[el] += 1;
        } else {
          statistics[el] = 1;
        }
      });
      let total = 0;
      Object.keys(statistics).map((key) => {
        total += statistics[key];
      });
      if (total) {
        const arrayData = Object.keys(statistics).map((key) => {
          return {
            type: key,
            value: parseInt((statistics[key] / total) * 100, 0)
          };
        });
        const elements = [];
        arrayData.map((el) => {
          elements.push({ type: el.type, value: el.value });
        });
        setDatas([...elements]);
      }
    } else {
      setDatas([]);
    }
  }, [orders]);

  const config = {
    width: 280,
    height: 280,
    appendPadding: 10,
    data: datas,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.75,
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      content: '{value}%',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [{ type: 'element-selected' }],
    statistic: {
      title: false,
      content: false
    }
  };
  return <Pie {...config} />;
};

PieOrderByPro.propTypes = {
  orders: PropTypes.shape().isRequired
};

export default PieOrderByPro;
