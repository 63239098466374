import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import { categoryProcutions } from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const fetchCategories = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'productions/enums' });
      setCategoriesFilter(
        data.categories.map((label) => ({
          text: (
            <Tag color={categoryProcutions[label]}>{label.toUpperCase()}</Tag>
          ),
          value: label
        }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCategories();
    })();
  }, [fetchCategories]);

  return [
    {
      title: t('catalogues.column.ref'),
      key: 'ref',
      dataIndex: 'ref',
      sorter: true
    },
    {
      title: t('catalogues.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('catalogues.column.category'),
      key: 'category',
      dataIndex: 'category',
      render: (cat) =>
        cat && (
          <Tag color={categoryProcutions[cat.toUpperCase()]}>
            {cat.toUpperCase()}
          </Tag>
        ),
      sorter: true,
      filters: categoriesFilter
    },
    {
      title: t('catalogues.column.sold_price'),
      key: 'sold_price',
      dataIndex: 'sold_price',
      render: (price) =>
        typeof price === 'number'
          ? `${numeral(price).format('0,0.00')} € HT`
          : '...',
      sorter: true
    }
  ];
};

export default useColumns;
