import React, { useEffect, useState } from 'react';
import { Input, Select, Tag } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [labels, setLabels] = useState([]);
  const [buildingSites, setBuildingSites] = useState([]);
  const [orders, setOrders] = useState([]);
  const [sites, setSites] = useState([]);

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLabels = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/cards-labels' });
      setLabels(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/ordersnew' });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getBuildingSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/building-sites' });
      setBuildingSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getStatus = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/trellos' });
      setStatus(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getStatus();
      await getLabels();
      await getOrders();
      await getBuildingSites();
      await getSites();
    })();
  }, []);

  const fields = [
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['note'],
      input: <Input.TextArea />,
      rules: [{ required: true }]
    },
    {
      name: ['color'],
      rules: [{ required: true }],
      input: (
        <Select allowClear>
          {(labels || []).map((item) => (
            <Option key={item._id} value={item._id}>
              <Tag color={item.color}>{item.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select allowClear>
          {(status || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['orders'],
      rules: [{ required: true }],
      input: (
        <Select allowClear>
          {(orders || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.number}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { fields, isFieldsLoading };
};

export default useFields;
