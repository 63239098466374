import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';
import OptionsRow from './OptionsRow';

const ListProducts = () => {
  const [extraQuery, setExtraQuery] = useState(null);
  const { t } = useTranslation();
  const headers = [
    {
      label: t('catalogues.column.ref'),
      key: 'ref'
    },
    {
      label: t('catalogues.column.label'),
      key: 'label'
    },
    {
      label: t('catalogues.column.target'),
      key: 'target'
    },
    {
      label: t('catalogues.column.category'),
      key: 'category'
    },
    {
      label: t('catalogues.column.sold_price'),
      key: 'sold_price'
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="products"
      columns={[...columns()]}
      withSubRoutes
      withImportButton
      headers={headers}
      resourceModelName="Product"
      extraQuery={extraQuery}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListProducts;
