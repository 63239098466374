import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Dropdown, Menu, Space } from 'antd';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAccountingContext from '../AccountingContext';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

export const generateAdvPaymentsMenu = (
  t,
  isDownloading,
  data,
  advPayment,
  handleDownload
) => (
  <Menu>
    <Menu.Item key="pdf">
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() =>
          handleDownload('pdf', true, advPayment.advance_payment_number)
        }
      >
        <Space>
          {isDownloading[data.key] ? (
            <LoadingOutlined style={iconStyle} />
          ) : (
            <DownloadOutlined style={iconStyle} />
          )}
          {t('buttons.download_pdf')}
        </Space>
      </Button>
    </Menu.Item>
    <Menu.Item key="docx">
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() =>
          handleDownload('docx', true, advPayment.advance_payment_number)
        }
      >
        <Space>
          {isDownloading[data.key] ? (
            <LoadingOutlined style={iconStyle} />
          ) : (
            <DownloadOutlined style={iconStyle} />
          )}
          {t('buttons.download_docx')}
        </Space>
      </Button>
    </Menu.Item>
  </Menu>
);

export const generateBasicMenu = (t, isDownloading, data, handleDownload) => (
  <Menu>
    <Menu.Item key="pdf">
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => handleDownload('pdf', false, null)}
      >
        <Space>
          {isDownloading[data.key] ? (
            <LoadingOutlined style={iconStyle} />
          ) : (
            <DownloadOutlined style={iconStyle} />
          )}
          {t('buttons.download_pdf')}
        </Space>
      </Button>
    </Menu.Item>
    <Menu.Item key="docx">
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => handleDownload('docx', false, null)}
      >
        <Space>
          {isDownloading[data.key] ? (
            <LoadingOutlined style={iconStyle} />
          ) : (
            <DownloadOutlined style={iconStyle} />
          )}
          {t('buttons.download_docx')}
        </Space>
      </Button>
    </Menu.Item>
  </Menu>
);

const ListInvoicesGenerateFile = ({ data }) => {
  const { t } = useTranslation();
  const { downloadFile } = useAccountingContext();
  const [isDownloading, setIsDownloading] = useState({});

  const advPayment = data?.payments.find(
    (el) => el.advance_payment_number === data.number
  );

  let invoiceType = 'default';

  switch (true) {
    case data?.is_advance_payment:
      invoiceType = 'adv-invoice';
      break;
    case data?.is_credit_note:
      invoiceType = 'credit_note';
      break;
    default:
      break;
  }

  const handleDownload = async (
    fileType,
    isAdvancePayment,
    advPaymentNumber
  ) => {
    if (!isDownloading[data.key]) {
      setIsDownloading((prev) => ({ ...prev, [data.key]: true }));
      await downloadFile(
        data.key,
        `${data.number}.${fileType}`,
        null,
        isAdvancePayment,
        advPaymentNumber,
        invoiceType,
        fileType
      );
      setIsDownloading((prev) => ({ ...prev, [data.key]: false }));
    }
  };

  const advPaymentsMenu = generateAdvPaymentsMenu(
    t,
    isDownloading,
    data,
    advPayment,
    handleDownload
  );
  const basicMenu = generateBasicMenu(t, isDownloading, data, handleDownload);

  return (
    <span style={{ float: 'right' }}>
      <Link
        to={{
          pathname: `/custom_accounting/invoices/show/${data.key}`,
          state: { quoteId: data.key }
        }}
      >
        <EyeOutlined style={iconStyle} />
      </Link>
      <Divider type="vertical" />
      <Dropdown overlay={advPayment ? advPaymentsMenu : basicMenu}>
        <Button>
          <Space>
            <DownloadOutlined style={iconStyle} />
          </Space>
        </Button>
      </Dropdown>
    </span>
  );
};

ListInvoicesGenerateFile.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    number: PropTypes.string,
    payments: PropTypes.arrayOf(
      PropTypes.shape({
        advance_payment_number: PropTypes.string
      })
    ),
    is_advance_payment: PropTypes.bool,
    is_credit_note: PropTypes.bool
  }).isRequired
};

export default ListInvoicesGenerateFile;
