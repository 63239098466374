import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const OrderContext = createContext({});

export const OrderContextProvider = ({ children, collection, type }) => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { state } = useLocation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [template, setTemplate] = useState();
  const [refresh, setRefresh] = useState(false);
  const [sites, setSites] = useState([]);
  const [workSheets, setWorkSheets] = useState([]);
  const [laySheets, setLaySheets] = useState([]);
  const [templateAdv, setTemplateAdv] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuote = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/${id}`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuote1 = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${collection}/populated/${id}`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const duplicateResource = async (id, callback) => {
    try {
      await dispatchAPI('GET', {
        url: `/${collection}/duplicate/${id}`
      });
      if (callback) callback();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteResource = async (id) => {
    const body = {
      status: 'DELETED',
      deleted: true
    };
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body, id) => {
    try {
      await dispatchAPI('PATCH', { url: `/${collection}/${id}`, body });
      history.push(`/customers_accounting/quotes/show/${id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/${collection}`,
        body
      });
      history.push(`/customers_accounting/quotes/show/${data}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getTemplateAdv = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=INVOICE`
      });
      setTemplateAdv(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const downloadFileAdv = async (id, name, callback, advPay, advNumber) => {
    try {
      const goodTemplate = advPay
        ? templateAdv.find(
            (el) => el.metadata.originalName === 'Facture acompteAdv.docx'
          )
        : templateAdv.find(
            (el) => el.metadata.originalName === 'Exemple Facture.docx'
          );
      const response = await dispatchAPI('GET', {
        url: `/files/generate/invoice/${id}/${goodTemplate._id}?advNumber=${advNumber}&withdetails=${advPay}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location = url;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (callback) callback();
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=ORDER`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, callback, _, outputType = 'pdf') => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/ordersnew/${id}/${template._id}?outputType=${outputType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (callback) callback();
  };
  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getWorksheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?populate=matter,customer,order`
      });
      setWorkSheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getLaysheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/laysheets`
      });
      setLaySheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    await getTemplate();
    await getTemplateAdv();
    await getSites();
    await getWorksheets();
    await getLaysheets();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <OrderContext.Provider
      value={{
        collection,
        isLoading,
        setIsLoading,
        enums,
        updateResource,
        deleteResource,
        templateAdv,
        template,
        downloadFile,
        downloadFileAdv,
        duplicateResource,
        state,
        refresh,
        createResource,
        getQuote,
        getQuote1,
        type,
        sites,
        workSheets,
        laySheets
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  collection: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default () => useContext(OrderContext);
