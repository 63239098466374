import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tabs, Table } from 'antd';
import useColumns from './otherColumns';

const { TabPane } = Tabs;

const TablesTab = ({ datas }) => {
  const [type, setType] = useState('');
  const { quotesCols, ordersCols } = useColumns(type);

  const callback = (key) => {
    if (key === '2') setType('orders_sales');
    if (key === '3') setType('invoices');
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Devis" key="1">
        <Table columns={quotesCols} dataSource={datas?.quotes} />
      </TabPane>
      <TabPane tab="Commandes" key="2">
        <Table
          columns={ordersCols}
          dataSource={(datas?.orders || []).filter(
            ({ status }) => status !== 'INVOICED'
          )}
        />
      </TabPane>
      <TabPane tab="Factures" key="3">
        <Table
          columns={ordersCols}
          dataSource={(datas?.orders || []).filter(
            ({ status }) => status === 'INVOICED'
          )}
        />
      </TabPane>
    </Tabs>
  );
};

TablesTab.propTypes = {
  datas: PropTypes.shape({
    orders: PropTypes.array,
    quotes: PropTypes.array
  }).isRequired
};

export default TablesTab;
