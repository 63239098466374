import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Card, Row, Col, Input, Button } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import MarbleBackground from '../../../assets/images/stocks-background.jpg';
import useLotFields from './lotFields';
import useMatterFields from './matterFields';
import useSiteFields from './siteFields';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import useFilesField from './filesField';

const { TextArea } = Input;

const titleDivider = (
  <div style={{ width: '110px' }}>
    <hr
      style={{
        backgroundColor: 'black',
        height: '3px',
        border: 'none',
        marginBottom: '18px'
      }}
    />
  </div>
);

const cardStyle = { margin: '16px', background: 'rgba(255, 255, 255, 0.85)' };

const CreateUpdatePieces = ({ purpose }) => {
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [supply, setSupply] = useState();
  const [loading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showClientSelect, setShowClientSelect] = useState(false);

  const savePiece = async () => {
    let method;
    let url;

    const values = form.getFieldsValue();
    const numbers = values.pieces_numbers;
    const newSizes = [];
    const allFiles = [...(values.files || []), ...files];

    const length = values.length > values.width ? values.length : values.width;
    const width = values.length < values.width ? values.length : values.width;

    if (purpose === 'create') {
      method = 'POST';
      url = '/pieces';
      numbers.forEach((num) => {
        newSizes.push({
          number: num,
          width,
          length,
          person_in_charge: `${user.first_name} ${user.last_name}`
        });
      });
    }

    let body = {
      ...values,
      sizes: newSizes,
      files: allFiles,
      width,
      length
    };
    if (purpose === 'edit') {
      method = 'PATCH';
      url = `/pieces/${id}`;
      body = {
        ...values,
        width,
        length,
        sizes: supply.sizes
      };
    }

    setIsLoading(true);
    try {
      await dispatchAPI(method, {
        url,
        body
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const removeFile = async (file) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${file._id}`
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onCancel = () => {
    files.forEach((file) => removeFile(file));
    history.goBack();
  };

  const onSave = async () => {
    try {
      await form.validateFields();
      await savePiece();
    } catch (error) {
      message('missing_fields');
    }
  };

  const buttons = (
    <Row>
      <Button style={{ margin: '8px' }} type="primary" onClick={onCancel}>
        ANNULER
      </Button>
      <Button
        style={{ margin: '8px' }}
        type="primary"
        icon={<CheckOutlined />}
        onClick={onSave}
      >
        ENREGISTRER
      </Button>
    </Row>
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await dispatchAPI('GET', {
        url: `/pieces/${id}`
      });
      setSupply(data.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (purpose === 'edit') {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (supply) {
      const length = supply.sizes[0]?.length;
      const width = supply.sizes[0]?.width;
      if (supply.customer) {
        setShowClientSelect(true);
      }
      form.setFieldsValue({
        ...supply,
        files: supply.files || [],
        length,
        width
      });
      setFiles(supply.files);
    } else {
      form.setFieldsValue({ files: [] });
    }
  }, [supply]);

  const onFileUploaded = (file) => {
    setFiles([...files, file]);
  };

  const onFileRemoved = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`pieces.form.title.${purpose}`)}
        extra={buttons}
      />
      <div
        style={{
          backgroundImage: `url(${MarbleBackground})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          padding: 8
        }}
      >
        <Form form={form}>
          <Card loading={loading} style={cardStyle}>
            {t(`pieces.form.lot_title`)}
            {titleDivider}
            {useLotFields({
              form,
              purpose,
              showClientSelect,
              setShowClientSelect
            })}
          </Card>
          <Card style={cardStyle}>
            {t(`pieces.form.matter_title`)}
            {titleDivider}
            {useMatterFields({ purpose, form })}
          </Card>
          <Card style={cardStyle}>
            {t(`pieces.form.site_title`)}
            {titleDivider}
            {useSiteFields()}
          </Card>
          <Row>
            <Col span={12}>
              <Card style={cardStyle}>
                {t(`pieces.form.files_title`)}
                {titleDivider}
                {useFilesField({ onFileUploaded, onFileRemoved })}
              </Card>
            </Col>
            <Col span={12}>
              <Card style={cardStyle}>
                {t(`pieces.form.comment_title`)}
                {titleDivider}
                <Form.Item name={['comment']}>
                  <TextArea placeholder={t('pieces.form.comment')} />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

CreateUpdatePieces.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePieces;
