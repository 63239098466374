/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Card, Upload, Form } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useFilesField = ({ onFileUploaded, onFileRemoved }) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const { Dragger } = Upload;

  const props = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/files/upload`,
    data: {
      type: 'STOCK_PIECE_FILE'
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;

      if (
        info.file.response &&
        info.file.response.description &&
        info.file.response.description.id
      ) {
        onFileUploaded(info.file.response.description.id, info.file.name);
        setVisible(false);
      }

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      hidden: true
    }
  };

  const fileDragger = (
    <Col>
      <Form.Item name={['files']} initialValue={[]} hidden />
      <Dragger {...props} style={{ margin: '5px' }} onRemove={onFileRemoved}>
        <p className="ant-upload-drag-icon">
          <PlusCircleOutlined />
        </p>
        <p className="ant-upload-text">{t('files.create.action')}</p>
      </Dragger>
    </Col>
  );

  return (
    <Col style={{ height: 'fit-content' }}>
      <Card bordered={false}>{fileDragger}</Card>
    </Col>
  );
};

useFilesField.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired
};

export default useFilesField;
