import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Timeline } from 'antd';
import moment from 'moment';

const HistoryColumn = ({ history }) => {
  const { t } = useTranslation();

  const getTrelloHistoryLabel = (trelloHistory) => {
    switch (trelloHistory.change_type) {
      case 'FILE_ADD':
        return t('trellos-cards.history.file_added', {
          filename: trelloHistory.new_value,
          person: `${trelloHistory.editor.first_name} ${trelloHistory.editor.last_name}`
        });
      case 'FILE_REMOVE':
        return t('trellos-cards.history.file_deleted', {
          filename: trelloHistory.new_value,
          person: `${trelloHistory.editor.first_name} ${trelloHistory.editor.last_name}`
        });
      case 'DATE_CHANGE':
        return t('trellos-cards.history.date_changed', {
          person: `${trelloHistory.editor.first_name} ${trelloHistory.editor.last_name}`
        });
      case 'STATUS_CHANGE':
        return t('trellos-cards.history.status_changed', {
          new_status: trelloHistory.new_value,
          person: `${trelloHistory.editor.first_name} ${trelloHistory.editor.last_name}`
        });
      case 'TRELLO_CREATION':
        return t('trellos-cards.history.creation');
      default:
        break;
    }
  };

  return (
    <Col>
      <Timeline>
        {[...history].reverse().map((trelloHistory) => (
          <Timeline.Item
            color="black"
            dot={
              <div
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: 'black',
                  borderRadius: '50%',
                  display: 'inline-block'
                }}
              />
            }
          >
            <Col style={{ width: '70%' }}>
              {getTrelloHistoryLabel(trelloHistory)}
            </Col>
            <Col width="30%" align="right">
              {`${moment(trelloHistory.date)
                .format('DD/MM/YYYY[\n]HH[h]mm')
                .split('\n')}`}
            </Col>
          </Timeline.Item>
        ))}
      </Timeline>
    </Col>
  );
};

HistoryColumn.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default HistoryColumn;
