import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Popconfirm, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EyeOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

import OptionsRow from './OptionsRow';

const iconSize = 18;
const ListArticles = () => {
  const columns = useColumns();
  const location = useLocation();
  const { pathname } = location;
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [extraQuery, setExtraQuery] = useState(null);
  const { t } = useTranslation();

  const deleteSAV = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/sav/${id}` });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    window.location.reload();
  };

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, payment_date, number }) => {
        return (
          <>
            <Link to={{ pathname: `${pathname}/show/${key}` }}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteSAV(key)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize, marginLeft: '1em' }}
                type="delete"
              />
            </Popconfirm>

            {/* <Link to={{ pathname: `${pathname}/show/${key}` }}>
              <DeleteOutlined
                onClick={() => deleteSAV(key)}
                style={{ fontSize: iconSize, marginLeft: '1em' , color: 'red'}}
              />
            </Link> */}
          </>
        );
      }
    }
  ];

  const headers = [];

  return (
    <SimpleResourceLandingLayout
      resourceName="sav"
      columns={[...columns, ...actionColumn]}
      withSubRoutes
      customActionColumn
      populate="site,buildingSite,order"
      headers={headers}
      resourceModelName="SAV"
      extraQuery={extraQuery}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListArticles;
