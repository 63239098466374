import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import { invoiceTypes } from '../../../utils/constants/tagColors';
import useErrorMessage from '../../../utils/ErrorMessage';

const { TextArea } = Input;

const useFields = (purpose) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [settings, setSettings] = useState([]);
  const [projects, setProjects] = useState([]);

  const fields1 = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          options={(enums.types || []).map((type) => ({
            label: (
              <Tag color={invoiceTypes[type]}>{t(`invoices.tags.${type}`)}</Tag>
            ),
            value: type
          }))}
          loading={isLoading}
        />
      )
    }
  ];
  if (purpose === 'edit') {
    fields1.push({
      name: ['number'],
      input: <Input disabled />,
      initialValue: `P${moment().format('YYYYMMDD')}00001`
    });
  }
  fields1.push(
    { name: ['subject'], rules: [{ required: true }] },
    { name: ['comment'], input: <TextArea /> },
    {
      name: ['emission_date'],
      rules: [{ required: true }],
      input: <DatePicker />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      rules: [{ required: true }],
      input: <DatePicker />,
      initialValue:
        settings.find(
          (setting) => setting.label === 'default_invoice_deadline'
        ) &&
        moment().add(
          parseInt(
            settings.find(
              (setting) => setting.label === 'default_invoice_deadline'
            ).value,
            10
          ),
          'days'
        )
    }
  );
  if (purpose === 'edit') {
    fields1.push({
      name: ['payment_date'],
      input: <DatePicker />
    });
  }

  const options = [
    {
      name: ['reception_date'],
      input: <DatePicker />
    },
    {
      name: ['project'],
      input: (
        <Select
          options={projects.map(({ wording, _id }) => ({
            label: wording,
            value: _id
          }))}
          loading={isLoading}
        />
      )
    }
  ];

  const fields2 = [
    {
      name: ['total_discount'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      )
    },
    {
      name: ['subTotal'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      )
    },
    {
      name: ['total_VAT'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      )
    },
    {
      name: ['total'],
      input: (
        <InputNumber
          style={{ visibility: 'hidden' }}
          disabled
          formatter={(value) => value && `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      ),
      noLabel: true
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects' });
      setProjects(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    await getProjects();
    await getSettings();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields1,
    fields2,
    options
  };
};

export default useFields;
