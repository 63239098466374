import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Radio, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

export const useConsumableColumns = (isValidDefinitevly, setDataSource) => {
  const { t } = useTranslation();

  const handleChange = (record, name, value) => {
    setDataSource((prevState) => {
      const updatedState = [...prevState];
      const elementToUpdateIndex = updatedState.findIndex(
        (el) => el._id === record._id
      );
      updatedState[elementToUpdateIndex][name] = value;
      return updatedState;
    });
  };

  const handleDelete = (record) => {
    setDataSource((prevState) => {
      const updatedState = [...prevState];
      const elementToDeleteIndex = updatedState.findIndex(
        (el) => el._id === record._id
      );

      if (elementToDeleteIndex !== -1) {
        updatedState.splice(elementToDeleteIndex, 1);
      }

      return updatedState;
    });
  };

  return [
    {
      title: t('laysheet.columns.consumable.consumable'),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: t('laysheet.columns.consumable.tint'),
      dataIndex: 'tint',
      key: 'tint',
      render: (_, record) => (
        <Select
          defaultValue={record?.tint}
          onChange={(value) => handleChange(record, 'tint', value)}
        >
          <Option value="acryliq">
            {t('laysheet.columns.consumable.acryliq')}
          </Option>
          <Option value="black_mat">
            {t('laysheet.columns.consumable.black_mat')}
          </Option>
          <Option value="silestone">
            {t('laysheet.columns.consumable.silestone')}
          </Option>
          <Option value="dekton">
            {t('laysheet.columns.consumable.dekton')}
          </Option>
        </Select>
      )
    },
    {
      title: t('laysheet.columns.consumable.expect'),
      dataIndex: 'expect',
      key: 'expect',
      render: (_, record) => {
        return (
          <Radio.Group
            onChange={(e) => handleChange(record, 'expect', e.target.value)}
            defaultValue={record?.expect}
          >
            <Radio value>{t('laysheet.columns.consumable.yes')}</Radio>
            <Radio value={false}>{t('laysheet.columns.consumable.no')}</Radio>
          </Radio.Group>
        );
      }
    },
    {
      title: t('laysheet.columns.consumable.operation'),
      dataIndex: 'operation',
      render: (_, record) => {
        return (
          <>
            <Popconfirm
              title={t('laysheet.columns.consumable.delete')}
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined
                disabled={isValidDefinitevly}
                style={{ color: 'red', fontSize: 18 }}
              />
            </Popconfirm>
          </>
        );
      }
    }
  ];
};
