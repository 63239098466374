import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import { categoryLabors } from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const fetchCategoriess = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'labors/enums' });
      setCategoriesFilter(
        data.categories.map((category) => ({
          text: (
            <Tag color={categoryLabors[category]}>
              {t(`labors.enums.${category}`)}
            </Tag>
          ),
          value: category
        }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCategoriess();
    })();
  }, [fetchCategoriess]);

  return [
    {
      title: t('catalogues.column.ref'),
      key: 'ref',
      dataIndex: 'ref',
      sorter: true
    },
    {
      title: t('catalogues.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('catalogues.column.category'),
      key: 'category',
      dataIndex: 'category',
      render: (cat) =>
        cat ? (
          <Tag color={categoryLabors[cat]}>{t(`labors.enums.${cat}`)}</Tag>
        ) : (
          '-'
        ),
      sorter: true,
      filters: categoriesFilter
    },
    {
      title: t('catalogues.column.sold_price'),
      key: 'sold_price',
      dataIndex: 'sold_price',
      render: (price) =>
        typeof price === 'number'
          ? `${numeral(price).format('0,0.00')} € HT`
          : '...',
      sorter: true
    }
  ];
};

export default useColumns;
