import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useFields from './fields';
import QuotationForm from './FormsComponents/quotationForm';
import DocumentScan from '../../Documents/Scan/DocumentScan';
import { ScanContextProvider } from '../../Documents/Scan/ScanContext';

const CreateUpdate = ({ purpose }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const [quotation, setQuotation] = useState({});
  const [mode, setMode] = useState('FORM');
  const [customers, setCustomers] = useState([]);
  const [profesionals, setProfesionals] = useState([]);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [comments, setComments] = useState({});
  const [tvaCategories, setTvaCategories] = useState([]);
  const { createFields, editFields } = useFields(id);
  const [initialElements, setInitialElements] = useState([]);
  const [coefs, setCoefs] = useState([]);
  const location = useLocation();

  const fetchTvaCategories = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/settings-tva` });
      setTvaCategories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const updateQuotation = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createQuotation = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/quotations', body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchCustomers = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/customers` });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchSites = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/sites` });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchProfesionals = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/profesionals` });
      setProfesionals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchQuotation = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/${id}?populate=quotlines.elements.element`
      });
      const initials = [];
      data.quotlines.forEach(({ elements }) => {
        elements.forEach((el) => {
          initials.push(el.element);
        });
      });
      setInitialElements(initials);
      const datas = await dispatchAPI('GET', {
        url: `/quotations/${id}`
      });
      datas.data.quotlines.forEach(({ elements }) => {
        elements.forEach((el) => {
          const currElement = el;
          if (el.thikness) {
            const thik = initials
              .map(({ pricing }) => pricing)
              .flat()
              .filter((it) => it !== undefined)
              .find(({ _id }) => _id === el.thikness);
            currElement.thikness = thik.size;
          }
        });
      });
      setQuotation(datas.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchCategories = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/enums`
      });
      setCategories(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchComments = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/comments`
      });
      setComments(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  const fetchCoefs = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-coefs`
      });
      setCoefs(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchTvaCategories();
      await fetchCustomers();
      await fetchSites();
      await fetchCoefs();
      await fetchProfesionals();
      await fetchCategories();
      await fetchComments();
      if (id !== undefined) {
        await fetchQuotation();
      }
    })();
  }, [
    fetchCustomers,
    fetchSites,
    fetchProfesionals,
    fetchQuotation,
    fetchCategories,
    fetchComments,
    fetchCoefs,
    fetchTvaCategories
  ]);

  const handleSubmit = async (values, submittedId, total) => {
    const currentValues = values;

    const cat = tvaCategories.find(({ _id }) => _id === values.tva);
    const tx = 1 + cat?.amount / 100 || 1;
    currentValues.tvaTx = tx;
    const ecoTaxe = values.ecoTaxe;
    const discount = values.discount?.amount || 0;
    const totalBrut = total + discount;
    const tvatx = tx - 1;
    const amountTva = total * tvatx;
    const totalNet = total + amountTva + ecoTaxe;
    currentValues.prices = {
      totalBrut,
      discount,
      amountTva,
      totalNet,
      ecoTaxe
    };

    values.quotlines.forEach(({ elements }) => {
      elements.forEach((el) => {
        const currentElement = el;
        if (el.thikness === '2') {
          const elem = initialElements.find(({ _id }) => _id === el.element);
          const pricing = elem.pricing.find(({ size }) => size === '2');
          currentElement.thikness = pricing._id;
        } else if (el.thikness === '3') {
          const elem = initialElements.find(({ _id }) => _id === el.element);
          const pricing = elem.pricing.find(({ size }) => size === '3');
          currentElement.thikness = pricing._id;
        }
      });
    });

    if (purpose === 'edit') await updateQuotation(values, submittedId);
    if (purpose === 'create') await createQuotation(values);
  };

  return (
    <>
      <PageHeaderCustom title={t(`quotations.form.title.${purpose}`)} />
      <ContentCustom>
        {isLoading ? (
          <Space direction="vertical" style={{ width: '100%' }} align="center">
            {[...createFields, ...editFields].map((f) => (
              <Skeleton.Input key={`${f.name}`} style={{ width: 300 }} active />
            ))}
          </Space>
        ) : (
          <>
            {mode === 'FORM' && (
              <QuotationForm
                resource="quotations"
                isLoading={isLoading}
                quotation={quotation}
                handleSubmit={handleSubmit}
                fields={purpose === 'create' ? createFields : editFields}
                customers={customers}
                id={id}
                profesionals={profesionals}
                sites={sites}
                categories={categories}
                comments={comments}
                location={location}
                tvaCategories={tvaCategories}
                dispatchAPI={dispatchAPI}
                initialElements={initialElements}
                coefs={coefs}
              />
            )}
            {mode === 'SCAN' && (
              <ScanContextProvider>
                <DocumentScan resource="quotations" />
              </ScanContextProvider>
            )}
          </>
        )}
      </ContentCustom>
    </>
  );
};

CreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdate;
