import React, { useState } from 'react';
import {
  MoreOutlined,
  StopOutlined,
  TagsTwoTone,
  TagTwoTone
} from '@ant-design/icons';
import { Dropdown, Menu, Modal, Button } from 'antd';
import PartialReconciliation from './Modals/PartialReconciliation';
import MultipleReconciliation from './Modals/MultipleReconciliation';

const fontSize = 20;

const modals = {
  NONE: 'none',
  PARTIAL_INVOICE: 'invoices.partial',
  MULTIPLE_INVOICES: 'invoices.multiple',
  PARTIAL_EXPENSE: 'expenses.partial',
  MULTIPLE_EXPENSES: 'expenses.multiple'
};

const Options = (transaction, t, invoices, expenses, postReconciliation) => {
  const [modal, setModal] = useState(modals.NONE);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const value = parseFloat(
    transaction.value ? transaction.value : transaction.original_value
  );

  const menu = (type) => (
    <Menu>
      <Menu.Item onClick={() => postReconciliation(transaction)}>
        <StopOutlined />
        &nbsp;
        {t('reconciliations.actions.default')}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setModal(
            type === 'invoices'
              ? modals.PARTIAL_INVOICE
              : modals.PARTIAL_EXPENSE
          )
        }
      >
        <TagTwoTone />
        &nbsp;
        {t(
          `reconciliations.actions.${
            type === 'invoices'
              ? modals.PARTIAL_INVOICE
              : modals.PARTIAL_EXPENSE
          }`
        )}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setModal(
            type === 'invoices'
              ? modals.MULTIPLE_INVOICES
              : modals.MULTIPLE_EXPENSES
          )
        }
      >
        <TagsTwoTone />
        &nbsp;
        {t(
          `reconciliations.actions.${
            type === 'invoices'
              ? modals.MULTIPLE_INVOICES
              : modals.MULTIPLE_EXPENSES
          }`
        )}
      </Menu.Item>
    </Menu>
  );

  const getModal = () => {
    if (modal === modals.MULTIPLE_INVOICES) {
      return (
        <MultipleReconciliation
          elements={invoices.filter((invoice) => invoice.total <= value)}
          transaction={transaction}
          onSelectionChange={(state) => setDisabled(state)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      );
    }
    if (modal === modals.MULTIPLE_EXPENSES) {
      return (
        <MultipleReconciliation
          type="expenses"
          elements={expenses.filter((invoice) => invoice.amount_ht >= value)}
          transaction={transaction}
          onSelectionChange={(state) => setDisabled(state)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      );
    }
    if (modal === modals.PARTIAL_INVOICE) {
      return (
        <PartialReconciliation
          elements={invoices.filter((invoice) => invoice.total >= value)}
          onSelectionChange={(state) => setDisabled(state)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      );
    }
    if (modal === modals.PARTIAL_EXPENSE) {
      return (
        <PartialReconciliation
          type="expenses"
          elements={expenses.filter((expense) => expense.amount_ht <= value)}
          onSelectionChange={(state) => setDisabled(state)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      );
    }
  };

  const handleSubmit = () => {
    postReconciliation(transaction, selectedRowKeys, modal);
    handleReset();
  };

  const handleReset = () => {
    setModal(modals.NONE);
    setDisabled(true);
    setSelectedRowKeys([]);
  };

  return (
    <>
      {value && (
        <Dropdown
          overlay={() => menu(value > 0 ? 'invoices' : 'expenses')}
          placement="bottomRight"
          trigger="click"
        >
          <MoreOutlined style={{ fontSize }} />
        </Dropdown>
      )}
      <Modal
        title={t(`reconciliations.actions.${modal}`)}
        visible={modal !== modals.NONE}
        width={1000}
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            {t('reconciliations.confirm.cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {t('reconciliations.confirm.confirm')}
          </Button>
        ]}
      >
        {getModal()}
      </Modal>
    </>
  );
};

export default Options;
