import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Radio,
  Select,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Popconfirm
} from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

export const DailyModal = ({
  disabled,
  isEdit,
  isModalVisible,
  setDisabled,
  canEditResource,
  path,
  handleOk,
  handleCancel,
  bounds,
  draggleRef,
  onStart,
  deleteChantier,
  isRequire,
  changeSheet,
  selected,
  handleChange,
  allOptions,
  show
}) => {
  const { t } = useTranslation();
  const isWeekEnd = (dat) => {
    const day = dat.day();
    return day === 0 || day === 6;
  };

  return (
    <Modal
      title={
        <Row
          style={{
            width: '100%',
            cursor: 'move'
          }}
          onMouseOver={() => {
            if (disabled) setDisabled(false);
          }}
          onMouseOut={() => setDisabled(true)}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {t(`planning.modal.title.${isEdit ? 'edit' : 'create'}`)}
        </Row>
      }
      visible={isModalVisible && canEditResource(path)}
      okText={t('planning.button.validate')}
      cancelText={t('planning.button.cancel')}
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      footer={[
        isEdit && (
          <Popconfirm
            title={t('settings.messages.confirm-deletion')}
            onConfirm={deleteChantier}
          >
            <Button danger style={{ float: 'left' }}>
              {t('planning.button.delete')}
            </Button>
          </Popconfirm>
        ),
        <Button key="back" onClick={handleCancel}>
          {t('planning.button.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {t('planning.button.validate')}
        </Button>
      ]}
    >
      {isRequire && (
        <Row style={{ color: 'red' }}>
          {t('planning.modal.error-validation')}
        </Row>
      )}
      <Form.Item hidden name="order" />
      <Form.Item hidden name="idChantier" />
      <Form.Item
        label={t('planning.modal.type')}
        name={['type_sheet']}
        style={{ width: '60%', padding: '0px 0px 10px 0px' }}
      >
        <Radio.Group onChange={changeSheet} disabled={isEdit}>
          <Radio value="workSheet">{t('planning.modal.worksheet')}</Radio>
          <Radio value="laySheet">{t('planning.modal.laysheet')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Row style={{ width: '100%' }}>
        <Col span={10}>
          <Form.Item
            name={selected ? 'workSheet' : 'laySheet'}
            label={t('planning.modal.choice', {
              type: t(`planning.modal.${selected ? 'worksheet' : 'laysheet'}`)
            })}
            style={{ width: '80%' }}
            rules={[
              {
                required: true,
                message: t('planning.modal.message.rule-sheet')
              }
            ]}
          >
            <Select
              disabled={isEdit}
              allowClear
              placeholder={t(
                `planning.modal.${selected ? 'worksheet' : 'laysheet'}`
              )}
              showSearch
              optionFilterProp="children"
              style={{ width: 300 }}
              onChange={handleChange}
            >
              {allOptions &&
                allOptions.map(({ label, value }) => (
                  <Option value={value}>{label}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="number"
            label={t('planning.modal.number')}
            style={{ width: '50%', padding: '0px 0px 10px 0px' }}
          >
            <Input disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {show && show !== 'N/R' ? (
            <Form.Item label="Date de pose" style={{ width: '60%' }}>
              <DatePicker
                disabled
                value={moment(show)}
                format="DD-MM-YYYY"
                style={{ width: '100%' }}
              />
            </Form.Item>
          ) : (
            <span />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            style={{ width: '60%' }}
            name="date_start_end"
            label={t('planning.modal.date_start_end')}
            rules={[
              {
                required: true,
                message: t('planning.modal.message.date_start_end')
              }
            ]}
          >
            <RangePicker
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              disabledDate={isWeekEnd}
              disabled={[true, false]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

DailyModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  canEditResource: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  bounds: PropTypes.shape({}).isRequired,
  draggleRef: PropTypes.shape({}).isRequired,
  onStart: PropTypes.func.isRequired,
  deleteChantier: PropTypes.func.isRequired,
  isRequire: PropTypes.bool.isRequired,
  changeSheet: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  allOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  show: PropTypes.string.isRequired
};
