import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { headers } from './headers';
import { ExportStocks } from './Buttons/ExportStocks';

const OptionsRow = ({ setFilter, searchFilter, dataSource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [matterOptions, setMatterOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);

  const getOptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pieces?populate=matter'
      });

      const newMatterOptions = data.map((piece) => piece.matter.label);
      const newSupplierOptions = data.map((piece) => piece.ref_supplier);

      setMatterOptions(Array.from(new Set(newMatterOptions)));
      setSupplierOptions(Array.from(new Set(newSupplierOptions)));
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <>
      <Row align="space-between">
        <Row>
          <Search
            placeholder={t('placeholder.search')}
            style={{ width: 400 }}
            onSearch={searchFilter}
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 200 }}
            placeholder={t('placeholder.matter')}
            onChange={(value) => setFilter('matter.label', value)}
            options={matterOptions.map((matter) => ({
              key: matter,
              value: matter
            }))}
            isLoading={isLoading}
          />
          <Select
            allowClear
            style={{ marginLeft: 5, width: 200 }}
            placeholder={t('placeholder.supplier-ref')}
            onChange={(value) => setFilter('ref_supplier', value)}
            options={supplierOptions.map((supplier) => ({
              key: supplier,
              value: supplier
            }))}
            isLoading={isLoading}
          />
        </Row>
        <Row>
          <ExportStocks
            exportDatas={dataSource}
            resourceName="pieces"
            columns={headers}
          />
        </Row>
      </Row>
    </>
  );
};

OptionsRow.propTypes = {
  setFilter: PropTypes.func.isRequired,
  searchFilter: PropTypes.func.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default OptionsRow;
