import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

const ContactsTable = ({ contacts }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('profesionals.form.first_name'),
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: t('profesionals.form.last_name'),
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: t('profesionals.form.role'),
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: t('profesionals.form.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('profesionals.form.phone_number'),
      dataIndex: 'phone_number',
      key: 'phone_number'
    }
  ];
  return (
    <div style={{ width: '50%' }}>
      {contacts !== undefined && contacts.length > 0 && (
        <Table
          columns={columns}
          dataSource={contacts}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      gender: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      position: PropTypes.string,
      email: PropTypes.string.isRequired,
      phone_number_1: PropTypes.string.isRequired,
      phone_number_2: PropTypes.string,
      deal: PropTypes.object
    })
  ).isRequired
};

export default ContactsTable;
