import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  DownloadOutlined,
  HighlightOutlined,
  LoadingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Button } from 'antd';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import Columns from './Columns';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const iconSize = 18;

const DocumentTemplates = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { location } = history;
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});
  const [isSigning, setIsSigning] = useState({});
  const url = `https://account-d.docusign.com/oauth/auth?response_type=token&scope=signature&client_id=${process.env.REACT_APP_DOCUSIGN_CLIENT_ID}&redirect_uri=http://localhost:3000/documents/templates`;

  const deleteFile = async (id) => {
    try {
      if (
        usedTemplates.length > 0 &&
        usedTemplates.find((temp) => temp.value === id)
      ) {
        return message('used_file');
      }

      await dispatchAPI('DELETE', { url: `/files/${id}` });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, contentType) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: contentType
      });

      const createdUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = createdUrl;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const updateUser = async (body) => {
    try {
      const newUser = { ...user, ...body };
      setUser(newUser);
      await dispatchAPI('PATCH', { url: `/users/${user._id}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?unitType=TEMPLATE'
      });
      setUsedTemplates(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSignURL = async (fileId) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sign/${fileId}` });

      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsSigning({ ...isSigning, [fileId]: false });
  };

  const handleSignFile = async (fileId) => {
    const { redirectUrl } = await getSignURL(fileId);

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      message(500);
    }
  };

  if (location.hash) {
    const token = location.hash.split('=')[1].split('&')[0];
    updateUser({ docusign_token: token });
    history.push(path);
  }

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplateSetting();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, contentType, metadata }) => {
        return (
          <span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={async () => {
                if (!isDownloading[key]) {
                  setIsDownloading({ ...isDownloading, [key]: true });
                  // eslint-disable-next-line react/prop-types
                  await downloadFile(key, metadata.originalName, contentType);
                }
              }}
            >
              {isDownloading[key] ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>
            {contentType === 'application/pdf' && user.docusign_token && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={async () => {
                    if (!isSigning[key]) {
                      setIsSigning({ ...isSigning, [key]: true });
                      await handleSignFile(key);
                    }
                  }}
                >
                  {isSigning[key] ? (
                    <LoadingOutlined />
                  ) : (
                    <HighlightOutlined style={{ fontSize: iconSize }} />
                  )}
                </Button>
              </>
            )}
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
              onConfirm={async () => {
                setForceRefresh(false);
                await deleteFile(key);
              }}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="files"
      columns={[...Columns(t), ...actionColumn]}
      customActionColumn
      withUploadButton={false}
      forceRefresh={forceRefresh}
      extraQuery="template=true&type=INVOICE,ORDER,QUOTE,WORKSHEET,LAYSHEET,ACCEPTANCE_REPORT,REMOVALORDER,MANUAL_REMOVAL_DELIVERY_ORDER"
      extraHeader={
        <>
          {/* {!user.docusign_token && (
            <Button
              type="primary"
              onClick={() => {
                window.location.replace(url);
              }}
            >
              {t('files.docusign.synchronize')}
              <PlusOutlined />
            </Button>
          )} */}
          {/* {user.docusign_token && (
            <Button type="link">
              {t('files.docusign.title')}
              <CheckOutlined />
            </Button>
          )} */}
        </>
      }
      // extraButtons={(
      //   <Link to={`${pathname}/sign`}>
      //     <Button type="primary" style={{marginRight: 10}}>
      //       {`${t('buttons.sign')}`}
      //       &nbsp;
      //       <HighlightOutlined />
      //     </Button>
      //   </Link>
      // )}
    />
  );
};

export default DocumentTemplates;
