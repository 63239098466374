import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Tag } from 'antd';
import moment from 'moment';
import PriorityTag from '../PriorityTag';

const InformationRow = ({ deal }) => {
  return (
    <>
      <Row justify="space-between" style={{ paddingBottom: '24px' }}>
        <Col>
          <p style={{ marginBottom: 2 }}>Responsable :</p>
          <p style={{ fontWeight: 'bold' }}>
            {deal.lead &&
              `${deal.lead.last_name} ${deal.lead.first_name || ''}`}
          </p>
        </Col>
        <Col>
          <p style={{ marginBottom: 2 }}>Priorité :</p>
          <PriorityTag priority={deal.priority} includeLabel />
        </Col>
        <Col>
          <p style={{ marginBottom: 2 }}>Site de rattachement :</p>
          {deal.site && (
            <Tag
              style={{ borderRadius: '2px' }}
              color={deal.site.request_color && deal.site.request_color.hex}
            >
              <span>{`${deal.site.label.toUpperCase()}`}</span>
            </Tag>
          )}
        </Col>
        <Col>
          <p style={{ marginBottom: 2 }}>Date souhaitée :</p>
          <p style={{ fontWeight: 'bold' }}>
            {deal.expected_date
              ? moment(deal.expected_date).format('DD/MM/YYYY')
              : 'N/R'}
          </p>
        </Col>
        <Col>
          <p style={{ marginBottom: 2 }}>Informations complémentaires :</p>
          <p style={{ fontWeight: 'bold' }}>{deal.global_information}</p>
        </Col>
      </Row>
    </>
  );
};

InformationRow.propTypes = {
  deal: PropTypes.shape({
    expected_date: PropTypes.func,
    global_information: PropTypes.any,
    lead: PropTypes.shape({
      first_name: PropTypes.any,
      last_name: PropTypes.any
    }),
    priority: PropTypes.string,
    site: PropTypes.shape({
      label: PropTypes.shape({
        toUpperCase: PropTypes.func
      }),
      request_color: PropTypes.shape({
        hex: PropTypes.any
      })
    })
  }).isRequired
};

export default InformationRow;
