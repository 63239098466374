import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Space, Button, Card, Form, message, Tooltip, Tag } from 'antd';
import moment from 'moment';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import MarbleBackground from '../../../assets/images/white-stone_Scott-Webb.jpg';
import Sections from './Sections';
import Infos from './Infos';
import DateInfos from './DateInfos';
import useContext from '../context';
import DownloadGeneratedFile from './DownloadGeneratedFile';

const Details = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { template, downloadFile } = useContext();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(
    !(state.acceptanceReport && state.acceptanceReport.validated)
  );

  useEffect(() => {
    if (state) {
      form.setFieldsValue({
        order: state.acceptanceReport.order._id,
        worksheet: state.acceptanceReport.worksheet._id,
        date: moment(state.acceptanceReport.date),
        is_delivery: state.acceptanceReport.is_delivery
      });
    }
  }, [state]);

  const handleSave = async () => {
    const method = 'PATCH';
    const url = `/acceptance-report/${state.acceptanceReport._id}`;
    const body = form.getFieldsValue();
    if (body.date) {
      try {
        await dispatchAPI(method, {
          url,
          body
        });
        history.push(
          `/customers_accounting/orders_sales/show/${state.acceptanceReport.order._id}`
        );
        setIsEditable(false);
      } catch (e) {
        if (e.response) message.error(e.response.message);
      }
    }
  };

  const handleValidate = async () => {
    const method = 'PATCH';
    const url = `/acceptance-report/${state.acceptanceReport._id}`;
    const body = form.getFieldsValue();
    if (body.date) {
      try {
        await dispatchAPI(method, {
          url,
          body: { ...body, validated: true }
        });
        history.push(
          `/customers_accounting/orders_sales/show/${state.acceptanceReport.order._id}`
        );
      } catch (e) {
        if (e.response) message.error(e.response.message);
      }
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <Space size="large">
            <div>
              {t(`acceptance_report.show.number`, {
                number: state?.acceptanceReport.order.number
              })}{' '}
              {state.acceptanceReport.validated && (
                <Tag color="green">VALIDÉ</Tag>
              )}
            </div>
          </Space>
        }
        extra={
          <>
            <DownloadGeneratedFile
              element={state.acceptanceReport}
              template={template}
              downloadFile={downloadFile}
            />
            <Tooltip
              title={isEditable ? '' : 'Le procès verbal a déjà été validé!'}
            >
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={handleValidate}
                disabled={!isEditable}
              >
                Valider définitivement
              </Button>
            </Tooltip>
            <Tooltip
              title={isEditable ? '' : 'Le procès verbal a déjà été validé!'}
            >
              <Button
                type="primary"
                onClick={handleSave}
                disabled={!isEditable}
              >
                Enregistrer
              </Button>
            </Tooltip>
          </>
        }
      />
      <ContentCustom>
        <div
          style={{
            backgroundImage: `url(${MarbleBackground})`,
            backgroundSize: 'cover',
            padding: '2em'
          }}
        >
          <Card
            style={{ padding: 0, background: 'rgba(255, 255, 255, 0.8)' }}
            bordered={false}
          >
            <div
              style={{
                paddingLeft: '24px'
              }}
            >
              Veuillez vous assurer que les informations sont correctes avant de
              valider
            </div>
            <Row
              justify="space-between"
              align="stretch"
              style={{
                padding: '24px',
                flexWrap: 'nowrap',
                height: 'fit-content'
              }}
              gutter={24}
            >
              <Infos datas={state?.acceptanceReport.worksheet.customer} />
              <DateInfos
                form={form}
                datas={state?.acceptanceReport.worksheet.customer}
                isEditable={isEditable}
              />
            </Row>
            <Sections
              datas={state?.acceptanceReport.order}
              products={state?.acceptanceReport.order.products}
              accessories={state?.acceptanceReport.order.accessories}
            />
            <Row
              justify="end"
              style={{
                textAlign: 'left',
                padding: '0 24px',
                flexWrap: 'nowrap',
                height: 'fit-content'
              }}
            >
              <DownloadGeneratedFile
                element={state.acceptanceReport}
                template={template}
                downloadFile={downloadFile}
              />
              <Tooltip
                title={isEditable ? '' : 'Le procès verbal a déjà été validé'}
              >
                <Button
                  type="primary"
                  style={{ margin: '0 5px 0 10px' }}
                  icon={<CheckOutlined />}
                  onClick={handleValidate}
                  disabled={!isEditable}
                >
                  Valider définitivement
                </Button>
              </Tooltip>
              <Tooltip
                title={isEditable ? '' : 'Le procès verbal a déjà été validé'}
              >
                <Button
                  type="primary"
                  style={{ margin: '0 5px' }}
                  onClick={handleSave}
                  disabled={!isEditable}
                >
                  Enregistrer
                </Button>
              </Tooltip>
            </Row>
          </Card>
        </div>
      </ContentCustom>
    </>
  );
};

Details.propTypes = {
  datas: PropTypes.shape({
    _id: PropTypes.any,
    files: PropTypes.any,
    infos: PropTypes.any,
    invoice_number: PropTypes.any,
    number: PropTypes.any,
    placed: PropTypes.any,
    totals: PropTypes.any
  }).isRequired
};

export default Details;
