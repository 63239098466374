import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import DownloadGeneratedFile from '../../../CustomAccounting/Invoices/DownloadGeneratedFile';
import useOrderContext from '../../OrderContext';

export const DownloadButtonAdv = ({ value }) => {
  const { templateAdv, downloadFileAdv } = useOrderContext();
  const { dispatchAPI } = useAuthContext();
  const [datas, setDatas] = useState();

  const getInvoice = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?number=${value?.advance_payment_number}`
      });
      setDatas(data[0]);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      {value && datas && (
        <DownloadGeneratedFile
          element={datas}
          template={templateAdv}
          downloadFile={downloadFileAdv}
          advPay
          advNumber={value}
        />
      )}
    </>
  );
};

DownloadButtonAdv.propTypes = {
  value: PropTypes.shape({
    advance_payment_number: PropTypes.string
  }).isRequired
};
