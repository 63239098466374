import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useContext from '../context';
import useColumns from './columns';
import headers from './headers';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const List = () => {
  const { collection, refresh, sites } = useContext();
  const { canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const columns = useColumns(canEditResource(path));

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName={collection}
      headers={headers}
      withUploadButton={false}
      customActionColumn
      forceRefresh={refresh}
      sites={sites}
      extraQuery="status!=DELETED"
      isQuote="true"
    />
  );
};

export default List;
