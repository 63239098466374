import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Tag, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { sitesTypes } from '../../utils/constants/tagColors';

const { Option } = Select;

const useFields = (form) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [sites, setSites] = useState([]);
  const [building_site, setBuildingSites] = useState([]);
  const [customersCodes, setCustomersCodes] = useState([]);
  const [settlementConditions, setSettlementConditions] = useState([]);
  const [city, setCity] = useState([]);
  const [isLocalAddress, setIsLocalAddress] = useState(true);

  useEffect(() => {
    const addressBoolean = form.getFieldValue(['local_address']);
    if (![undefined, null, 'undefined', 'null'].includes(addressBoolean)) {
      setIsLocalAddress(addressBoolean);
    }
  }, [form]);

  const getCities = async (code) => {
    try {
      const { data } = await axios.get(
        `https://geo.api.gouv.fr/departements/${code.slice(0, 2)}/communes`
      );
      let cities = data.filter((item) =>
        item.codesPostaux.map((el) => el === code).includes(true)
      );
      if (data.length !== 0 && cities.length === 0) {
        cities = data.filter((item) =>
          item.codesPostaux.map((el) => el === code).includes(false)
        );
      }

      const nameCities = cities.map((item) => item.nom);
      setCity(nameCities);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const basicFields = [
    {
      name: ['gender'],
      input: (
        <Select allowClear>
          {(enums.genders || []).map((item) => (
            <Option key={item._id} value={item}>
              <Tag color={sitesTypes[item]}>{item}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name']
    },
    {
      label: 'channel_number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'street',
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'local_address',
      name: ['local_address'],
      valuePropName: 'checked',
      input: <Switch onChange={(e) => setIsLocalAddress(e)} />
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      input: (
        <Input
          type="number"
          onChange={(val) => {
            if (val.target.value.length === 5) {
              getCities(val.target.value);
            } else {
              setCity([]);
            }
          }}
        />
      ),
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    ...(isLocalAddress
      ? [
          {
            label: 'text',
            input: (
              <div
                style={{
                  color: 'gray'
                }}
              >
                75001
              </div>
            )
          }
        ]
      : []),
    {
      label: 'city',
      name: ['address', 'city'],
      input: isLocalAddress ? (
        <Select allowClear showSearch>
          {(city || []).map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      ) : (
        <Input />
      ),
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'phone_number_1',
      name: ['phone_number_1']
    },
    {
      label: 'phone_number_2',
      name: ['phone_number_2']
    },
    {
      label: 'email',
      name: ['email']
    },
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={(sites || []).map((item) => ({
            label: `${item.label}`,
            value: item._id
          }))}
          showSearch
          optionFilterProp="label"
        />
      )
    },
    {
      label: 'building_site',
      name: ['building_site'],
      input: (
        <Select mode="multiple">
          {(building_site || []).map((item) => (
            <Option key={item._id} value={item._id}>
              <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['customer_code'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          allowClear
          showSearch
          options={customersCodes}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      )
    },
    {
      name: ['settlement_condition'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          allowClear
          showSearch
          options={settlementConditions}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      )
    },
    {
      label: 'details',
      name: ['details'],
      input: <TextArea />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBuildingSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/building-sites' });
      setBuildingSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomerCodes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-customers-codes'
      });
      const options = data
        .filter((item) => item.nomenclature || item.title)
        .map((item) => {
          return {
            label: `${item.nomenclature} - ${item.title}`,
            key: item._id,
            value: item.title
          };
        });
      setCustomersCodes(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettlementConditions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings-settlement-conditions'
      });
      const options = data.map((item) => {
        return {
          label: item.label,
          key: item._id,
          value: item._id
        };
      });
      setSettlementConditions(options);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
      await getEnums();
      await getSites();
      await getBuildingSites();
      await getCustomerCodes();
      await getSettlementConditions();
    })();
  }, [getSelectOptions]);

  return {
    basicFields,
    isFieldsLoading
  };
};

export default useFields;
