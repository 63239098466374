import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/users` });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);
  const fetchSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);
  const fetchStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotations/enums'
      });
      setStatus(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUsers();
      await fetchSettings();
      await fetchStatus();
    })();
  }, [fetchUsers, fetchSettings, fetchStatus]);

  const createFields = [
    {
      name: ['status'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={
            status &&
            status.slice(0, 3).map((item) => ({
              label: t(`quotations.form.enums.${item}`),
              value: item
            }))
          }
          loading={isLoading}
        />
      )
    },
    {
      name: ['emission_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: <DatePicker />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: <DatePicker />,
      initialValue:
        settings.find(
          (setting) => setting.label === 'default_quotation_deadline'
        ) &&
        moment().add(
          parseInt(
            settings.find(
              (setting) => setting.label === 'default_quotation_deadline'
            ).value,
            10
          ),
          'days'
        )
    },
    { name: ['comment'], input: <TextArea /> }
  ];

  const editFields = [
    {
      name: ['status'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select
          options={
            status &&
            status.slice(1).map((item) => ({
              label: t(`quotations.form.enums.${item}`),
              value: item
            }))
          }
          loading={isLoading}
        />
      )
    },
    {
      name: ['edit_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: <DatePicker />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: <DatePicker />,
      initialValue:
        settings.find(
          (setting) => setting.label === 'default_quotation_deadline'
        ) &&
        moment().add(
          parseInt(
            settings.find(
              (setting) => setting.label === 'default_quotation_deadline'
            ).value,
            10
          ),
          'days'
        )
    },
    { name: ['comment'], input: <TextArea /> }
  ];

  return {
    createFields,
    editFields
  };
};

export default useFields;
