import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Space, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const FooterButton = ({ cancel }) => {
  const { t } = useTranslation();
  return (
    <Row justify="end">
      <Space>
        <Button
          type="link"
          onClick={() => {
            cancel();
          }}
        >
          {t('invoices.accounting-code.action.cancel')}
        </Button>
        <Button type="primary" icon={<CheckOutlined />} htmlType="submit">
          {t('invoices.accounting-code.action.save')}
        </Button>
      </Space>
    </Row>
  );
};

export default FooterButton;

FooterButton.propTypes = {
  cancel: PropTypes.func.isRequired
};
