import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Input, Form, Col, Typography, Row } from 'antd';

const { TextArea } = Input;
const { Title } = Typography;

export const CommentaryCard = ({ isValidDefinitevly }) => {
  const { t } = useTranslation();
  return (
    <Row
      style={{
        padding: '24px',
        background: 'rgba(255, 255, 255, 0.9)'
      }}
    >
      <Col span={24}>
        <Title level={5}>{t('laysheet.form.titles.commentaries')}</Title>
        <div style={{ width: '103px' }}>
          <hr
            style={{
              backgroundColor: 'black',
              height: '3px',
              border: 'none',
              marginBottom: '18px'
            }}
          />
        </div>
        <Form.Item name={['commentaries']}>
          <TextArea
            disabled={isValidDefinitevly}
            placeholder={t('laysheet.form.placeholders.commentaries')}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

CommentaryCard.propTypes = {
  isValidDefinitevly: PropTypes.bool.isRequired
};
