import React, { useEffect, useRef, useState } from 'react';
import useScanContext from './ScanContext';

const canvasWidth = 600;
const canvasHeight = 850;
const SCALE = 2;

const ScanCanvas = () => {
  const canvasRef = useRef(null);
  const {
    setZone,
    setZones,
    zones,
    fields,
    zoningField,
    nextField,
    setZoningField,
    setLoadingFields,
    loadingFields,
    setNextField,
    loadedZones
  } = useScanContext();
  const [context, setContext] = useState();
  const [dragStartLocation, setDragStartLocation] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [snapshot, setSnapshot] = useState();
  const [drawed, setDrawed] = useState(false);

  const getCanvasCoordinates = (event) => {
    const bounding = canvasRef.current.getBoundingClientRect();
    return {
      x: event.clientX - bounding.left,
      y: event.clientY - bounding.top
    };
  };

  const takeSnapshot = () => {
    setSnapshot(context.getImageData(0, 0, canvasWidth, canvasHeight));
  };

  const restoreSnapshot = () => {
    context.putImageData(snapshot, 0, 0);
  };

  const handleDragStart = (event) => {
    setDragging(true);
    setDragStartLocation(getCanvasCoordinates(event));

    if (zoningField) {
      const currentZone = zones.find((z) => z.key === zoningField);

      if (currentZone) {
        context.clearRect(
          currentZone.left / SCALE,
          currentZone.top / SCALE,
          currentZone.width / SCALE,
          currentZone.height / SCALE
        );
      }
    }
    takeSnapshot();
  };

  const handleDrag = (event) => {
    if (dragging === true) {
      restoreSnapshot();
      const position = getCanvasCoordinates(event);
      draw(position);
    }
  };

  const handleDragStop = (event) => {
    setDragging(false);
    restoreSnapshot();
    const position = getCanvasCoordinates(event);
    draw(position);

    if (zoningField) {
      const type = Object.keys(fields)[0];
      const newZone = {
        key: zoningField,
        type: fields[type][zoningField].type,
        left: dragStartLocation.x * SCALE,
        top: dragStartLocation.y * SCALE,
        width: Math.abs((position.x - dragStartLocation.x) * SCALE),
        height: Math.abs((position.y - dragStartLocation.y) * SCALE)
      };
      const tmpZones = [...zones];
      const zoneExists = tmpZones.find((z) => z.key === newZone.key);
      if (zoneExists) {
        zoneExists.left = newZone.left;
        zoneExists.top = newZone.top;
        zoneExists.width = newZone.width;
        zoneExists.height = newZone.height;
        setZones(tmpZones);
      } else {
        setZones([...zones, newZone]);
      }

      setZone(newZone);
      setLoadingFields([...loadingFields, zoningField]);

      // Set next field to associate to a zone
      let nextFieldIndex = Object.keys(fields[type]).indexOf(zoningField) + 1;
      if (nextField)
        nextFieldIndex = Object.keys(fields[type]).indexOf(nextField);
      if (Object.keys(fields[type])[nextFieldIndex]) {
        setZoningField(Object.keys(fields[type])[nextFieldIndex]);
      } else {
        setZoningField(null);
      }
      setNextField(null);
    }
  };

  const draw = (position) => {
    if (zoningField) {
      context.beginPath();
      context.rect(
        dragStartLocation.x,
        dragStartLocation.y,
        position.x - dragStartLocation.x,
        position.y - dragStartLocation.y
      );
      context.fill();
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      const canvasObj = canvasRef.current;
      const ctx = canvasObj.getContext('2d');
      ctx.fillStyle = 'rgba(24,144,255,0.4)';
      setContext(ctx);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (loadedZones.length > 0 && !drawed) {
      loadedZones.forEach((zone) => {
        context.rect(
          zone.left / SCALE,
          zone.top / SCALE,
          zone.width / SCALE,
          zone.height / SCALE
        );
      });
      context.fill();
      setDrawed(true);
    }
  }, [loadedZones]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: 0,
        border: '1px solid black',
        cursor: 'crosshair'
      }}
      width={canvasWidth}
      height={canvasHeight}
      ref={canvasRef}
      onMouseMove={handleDrag}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragStop}
    />
  );
};

export default ScanCanvas;
