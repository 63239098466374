import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListLabors from './ListLabors';
import CreateUpdateLabor from './CreateUpdateLabor';
import ShowLabor from './ShowLabor';

const LaborRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateLabor purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateLabor purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowLabor} />
      <Route path={path} component={ListLabors} />
    </Switch>
  );
};

export default LaborRouter;
