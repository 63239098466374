import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import ContactsTable from './contactsTable';
import OrderTable from '../Accounting/Orders/OrdersTable';

const { TabPane } = Tabs;

const TablesTab = ({ contacts, extraQuery }) => {
  const [tabSelected, setTabSelected] = useState(1);
  // const operation = tabSelected == 1 ?
  //  <Link  to={{
  //     pathname: '/customers_accounting/quotations/create',
  //     search: "?sort=name",
  //     state: {
  //         site: '',
  //         customer: '',
  //         profesional: data &&  data
  //     }
  //   }}>
  // <Button type="add" style={{float: 'right', margin : 10}}>Ajouter un nouveau devis</Button>
  // </Link> : '-';

  const callback = (key) => {
    setTabSelected(key);
  };
  const populate = 'site,profesional,customer,invoice,order';
  const pathQuotation = `/customers_accounting/quotations`;
  const pathOrder = '/customers_accounting/orders_sales';
  const pathInvoice = '/customers_accounting/invoices';

  return (
    <Tabs defaultActiveKey="2" onTabClick={callback}>
      <TabPane tab="Contacts" key="2">
        <ContactsTable contacts={contacts} />
      </TabPane>
    </Tabs>
  );
};

TablesTab.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone_number1: PropTypes.string
    }).isRequired
  ).isRequired,
  extraQuery: PropTypes.string
};

TablesTab.defaultProps = {
  extraQuery: null
};

export default TablesTab;
