/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, Col, Upload, Radio, Input, Button, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';

const { Dragger } = Upload;
const { TextArea } = Input;

const Validation = ({
  isVisible,
  setIsVisible,
  // order,
  forceRefresh
  // withLay
}) => {
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [fileHasBeenUploaded, setFileHasBeenUploaded] = useState(false);
  const [withReserve, setWithReserve] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState();
  const [reserveDescription, setReserveDescription] = useState('');
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [withLay, setWithLay] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew/${id}`
      });
      setOrder(data);
      setWithLay(data.placed && !data.placed.without_lay);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    getData();
  }, [id, refresh]);
  const props = {
    name: 'file',
    multiple: false,
    disabled: fileHasBeenUploaded,
    action: `${process.env.REACT_APP_API_URL}/files/upload`,
    data: {
      type: withLay ? 'ACCEPTANCE_REPORT' : 'REMOVAL_ORDER'
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      setFileHasBeenUploaded(true);

      if (
        info.file.response &&
        info.file.response.description &&
        info.file.response.description.id
      ) {
        setFile(info.file.response.description.id);
      }

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  const steps = [
    <ContentCustom style={{ margin: '5px' }}>
      <div>
        {withLay
          ? 'Veuillez déposer le procès verbal de réception du chantier rempli et signé par le client'
          : "Veuillez déposer le bon d'enlèvement / de livraison rempli et signé par le client"}
      </div>

      <Col span={24}>
        <Dragger {...props} style={{ margin: '5px' }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
          <p className="ant-upload-hint">{t('files.create.formats')}</p>
        </Dragger>
      </Col>
      {withLay && (
        <div>
          <div>La réception a été prononcée :</div>
          <Radio.Group
            onChange={(value) => setWithReserve(value.target.value)}
            defaultValue={false}
            style={{ margin: '5px' }}
          >
            <Radio value={false}>sans réserve</Radio>
            <Radio value>avec réserve</Radio>
          </Radio.Group>
          {withReserve && (
            <TextArea
              style={{ margin: '5px' }}
              rows={4}
              placeholder="Décrire les réserves ici ..."
              onChange={(value) => setReserveDescription(value.target.value)}
            />
          )}
        </div>
      )}
    </ContentCustom>,
    <ContentCustom style={{ margin: '5px' }}>
      {withLay ? (
        <div>
          {withReserve
            ? 'La réception a été prononcée avec réserve, vous ne pouvez donc pas clôturer la commande.'
            : 'La réception a été prononcée sans réserve, vous pouvez donc clôturer définitivement la commande.'}
        </div>
      ) : (
        <div>
          {' '}
          Vous avez déposé le bon de d&apos;enlèvement/de livraison signé, vous
          pouvez donc clôturer la commande.
        </div>
      )}
    </ContentCustom>
  ];

  const postSignedAcceptanceReport = async () => {
    const method = 'POST';
    const url = withLay ? '/signed-acceptance-report' : '/signed-removal-order';
    let body = {
      file,
      with_reserve: withReserve,
      order: order._id
    };
    if (withReserve) {
      body = { ...body, reserve: reserveDescription };
    }
    await dispatchAPI(method, {
      url,
      body
    });
  };

  const deleteFile = async () => {
    const method = 'DELETE';
    const url = `/files/${file}`;
    await dispatchAPI(method, { url });
  };

  const handleCancel = async () => {
    if (currentStep === 1 && !withReserve) {
      return setCurrentStep(0);
    }
    if (fileHasBeenUploaded) {
      await deleteFile();
    }
    setIsVisible(false);
  };

  const handleValidate = async () => {
    if (currentStep === 0 && fileHasBeenUploaded) {
      await postSignedAcceptanceReport();
      return setCurrentStep(1);
    }

    if (!withReserve) {
      try {
        const reserve = [];
        if (order?.placed && order?.placed.without_lay) reserve.push('removal');
        else {
          if (order.reserve) order.reserve.map((el) => reserve.push(el));
          reserve.push(`${withReserve}`);
        }
        await dispatchAPI('PATCH', {
          url: `/ordersnew/${order._id}`,
          body: { status: 'VALIDATE', reserve }
        });
        setIsVisible(false);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      setRefresh(!refresh);
      forceRefresh();
    } else {
      const reserve = [];
      if (order?.reserve && order?.reserve.length) {
        order.reserve.map((el) => reserve.push(el));
      }
      reserve.push(`${withReserve}`);
      await dispatchAPI('PATCH', {
        url: `/ordersnew/${order._id}`,
        body: { reserve }
      });
      setWithReserve(false);
      setReserveDescription('');
      setFileHasBeenUploaded(false);
      setCurrentStep(0);
      setRefresh(!refresh);
      forceRefresh();
    }
  };

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={isVisible}
        maskClosable={false}
        span={24}
        width="60vw"
        title={
          withLay
            ? `Validation de la commande ${order.number} - Procès verbal requis`
            : `Validation de la commande ${order.number} - Bon d'enlèvement/de livraison requis`
        }
      >
        {steps[currentStep]}
        <Row justify="end">
          <Button
            type="primary"
            onClick={handleCancel}
            style={{ margin: '5px' }}
          >
            Annuler
          </Button>
          <Button
            type="primary"
            onClick={handleValidate}
            disabled={currentStep === 0 && !fileHasBeenUploaded}
            style={{ margin: '5px' }}
          >
            {currentStep === 0
              ? 'Valider'
              : withReserve
              ? 'Déposer un nouveau PV de réception'
              : 'Clôturer'}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

Validation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  order: PropTypes.shape({
    number: PropTypes.string,
    placed: PropTypes.bool,
    _id: PropTypes.string
  }).isRequired,
  forceRefresh: PropTypes.func.isRequired,
  withLay: PropTypes.bool.isRequired
};

export default Validation;
