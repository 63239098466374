import React, { useState } from 'react';
import { Button } from 'antd';
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DownloadGeneratedFile = ({
  element,
  template,
  downloadFile,
  advPay,
  advNumber
}) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const advPayment = element?.payments.find(
    (el) => el.advance_payment_number === advNumber.advance_payment_number
  );

  let invoiceType = 'default';

  switch (true) {
    case advPayment.advance_payment:
      invoiceType = 'adv-invoice';
      break;
    case !advPayment.is_basic_invoice && !advPayment.advance_payment:
      invoiceType = 'credit_note';
      break;

    default:
      break;
  }

  return (
    <>
      {template && (
        <Button
          type="primary"
          style={{ margin: 0 }}
          icon={isDownloading ? <LoadingOutlined /> : <FilePdfOutlined />}
          onClick={async () => {
            if (!isDownloading) {
              setIsDownloading(true);
              await downloadFile(
                element._id,
                `${element.number}.pdf`,
                () => setIsDownloading(false),
                advPay,
                advNumber?.advance_payment_number,
                invoiceType
              );
            }
          }}
        >
          {t('invoices.column.type.number')}
        </Button>
      )}
    </>
  );
};

DownloadGeneratedFile.propTypes = {
  element: PropTypes.shape([
    {
      _id: PropTypes.string,
      number: PropTypes.string
    }
  ]).isRequired,
  template: PropTypes.string.isRequired,
  downloadFile: PropTypes.func.isRequired,
  advPay: PropTypes.bool,
  advNumber: PropTypes.string
};

DownloadGeneratedFile.defaultProps = {
  advPay: false,
  advNumber: ''
};

export default DownloadGeneratedFile;
