export const formatter = (data) => {
  const formattedData = data.map((item) => {
    const { matter, supplier, sizes } = item;

    const formattedItem = {
      ...item,
      matter: matter?.label || '',
      supplier: supplier?.name || '',
      label: matter?.color || '',
      finition: matter?.completions || '',
      dimensions: `${sizes[0]?.length || ''} x ${sizes[0]?.width || ''}`
    };
    return formattedItem;
  });

  return formattedData;
};
