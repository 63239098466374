import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const ShapingsForm = ({ name, elementId, sectionId, datas }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [shapings, setShapings] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [filters, setFilters] = useState([]);
  const [isDecimal, setIsDecimal] = useState({});
  const [unitPrice, setUnitPrice] = useState();
  const getProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/shapings'
      });
      setShapings(data);
    } catch (e) {
      if (e.response) message.erroe(e.response.status);
    }
  };
  useEffect(() => {
    getProducts();
    if (datas) {
      const values = datas;

      const newFilters = [];
      if (values[sectionId]?.elements[elementId]?.sizes[name[0]]?.shapings) {
        values[sectionId].elements[elementId].sizes[name[0]].shapings.forEach(
          (shaping, shapingIndex) => {
            newFilters[shapingIndex] = { category: shaping.shaping };
          }
        );
      }
      setFilters(newFilters);
      form.setFieldsValue(datas);
    }
  }, [datas]);

  const onSelectShapingType = (value, index) => {
    const values = datas;
    // values[sectionId].elements[elementId].sizes[name[0]].shapings[
    //   index
    // ].shaping_name = null;
    // values[sectionId].elements[elementId].sizes[name[0]].shapings[
    //   index
    // ].nb = null;
    form.setFieldsValue({ ...values });
    const newFilters = [...filters];
    newFilters[index] = { category: value };
    setFilters(newFilters);
  };

  const onSelectShaping = (value, index) => {
    const values = datas;
    const shapingPrice = shapings
      .filter((shaping) => shaping._id === value)
      .shift();
    setUnitPrice(shapingPrice.sold_price);
    // values[sectionId].elements[elementId].sizes[name[0]].shapings[index] = {
    //   ...values[sectionId].elements[elementId].sizes[name[0]].shapings[index],
    //   unit_price: shapingPrice.sold_price
    // };
    // if (elementId > values[sectionId].elements.length) {
    //   values[sectionId].elements[elementId].sizes[name[0]].shapings[index] = {
    //     ...values[sectionId].elements[elementId].sizes[name[0]].shapings[index],
    //     unit_price: shapingPrice.sold_price
    //   };
    // }
    if (shapingPrice?.unit?.toLowerCase() === 'unité') {
      setIsDecimal({ ...isDecimal, [index]: false });
    } else {
      setIsDecimal({ ...isDecimal, [index]: true });
    }
    form.setFieldsValue(values);
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((el) => (
            <div className="quotation-shaping-field-wrapper">
              <Form.Item
                label={t('configurator.shaping_type')}
                name={[el.name, 'shaping']}
                fieldKey={[el.fieldKey, 'shaping']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  options={[
                    ...new Set(shapings.map(({ category }) => category))
                  ].map((label) => ({ label, value: label }))}
                  onChange={(value) => {
                    onSelectShapingType(value, el.name);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('configurator.shaping_name')}
                name={[el.name, 'shaping_name']}
                fieldKey={[el.fieldKey, 'shaping_name']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  options={shapings
                    .filter(({ category }) => {
                      return !filters[el.name]
                        ? false
                        : category === filters[el.name].category;
                    })
                    .map(({ label, _id }) => ({ label, value: _id }))}
                  onChange={(value) => onSelectShaping(value, el.name)}
                />
              </Form.Item>
              <Form.Item
                hidden
                name={[el.name, 'unit_price']}
                fieldKey={[el.fieldKey, 'unit_price']}
                rules={[{ required: true }]}
                initialValue={unitPrice}
              />
              <Form.Item
                label={t('configurator.quantity')}
                name={[el.name, 'nb']}
                fieldKey={[el.fieldKey, 'nb']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <InputNumber
                  min={0}
                  step={isDecimal[el.name] ? 0.01 : 1}
                  formatter={(value) => {
                    if (value) {
                      const values = form.getFieldsValue();
                      const id =
                        values[sectionId]?.elements[elementId]?.sizes[name[0]]
                          .shapings[el.name]?.shaping_name;
                      const isInteger = shapings
                        .filter((shaping) => shaping._id === id)
                        .map((val) => val)
                        .shift();
                      if (isInteger?.unit?.toLowerCase() === 'unité') {
                        isDecimal[el.key] = false;
                        setIsDecimal(isDecimal);
                        return Math.trunc(value);
                      }
                      isDecimal[el.key] = true;
                      setIsDecimal(isDecimal);

                      return value;
                    }
                    return value;
                  }}
                  parser={(value) =>
                    isDecimal[el.name] ? value : Math.trunc(value)
                  }
                  onChange={(value) => {
                    if (isDecimal[el.name]) {
                      return value;
                    }
                    return Math.trunc(value);
                  }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                <DeleteOutlined
                  onClick={() => {
                    remove(el.name);
                    const values = form.getFieldsValue();
                    const newFilters = [];
                    if (
                      values[sectionId]?.elements[elementId]?.sizes[name[0]]
                        .shapings
                    ) {
                      values[sectionId].elements[elementId].sizes[
                        name[0]
                      ].shapings.forEach((shaping, shapingIndex) => {
                        if (shaping) {
                          newFilters[shapingIndex] = {
                            category: shaping.shaping
                          };
                        }
                      });
                    }
                    setFilters(newFilters);
                  }}
                />
              </Form.Item>
            </div>
          ))}
          <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
            <Button
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              style={{ marginBottom: 20 }}
              className="add-shaping-btn"
            >
              façonnage
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

ShapingsForm.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  sectionId: PropTypes.number.isRequired,
  elementId: PropTypes.number.isRequired
};

export default ShapingsForm;
