import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { noLabelLayout } from '../../../utils/constants/formLayout';

const CreateUpdateArticle = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const advancedOptions = (
    <>
      <Row style={{ marginTop: 30, marginBottom: 30 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Col {...noLabelLayout.wrapperCol}>
          <Button type="link" onClick={() => setVisible(!visible)}>
            {t('articles.form.options')}
            {visible ? (
              <UpOutlined style={{ marginLeft: 10 }} />
            ) : (
              <DownOutlined style={{ marginLeft: 10 }} />
            )}
          </Button>
        </Col>
      </Row>
      {visible &&
        advancedFields.map((field) => generateFormItem('articles', field))}
    </>
  );

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="dartymatters"
      resource="dartymatters"
      loadingFields={isFieldsLoading}
      withSubRoutes
    />
  );
};

CreateUpdateArticle.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateArticle;
