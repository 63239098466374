import PropTypes from 'prop-types';
import React from 'react';
import { Typography, Col, Card, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import useContext from '../context';

const Totals = ({ datas }) => {
  const { t } = useTranslation();
  const { resultComPro } = useContext();
  const { Title } = Typography;

  const format = (value) => numeral(value).format('0,0[.]00 $');
  const formatPercentage = (value) => `${value} %`;
  if (datas) {
    return (
      <Col span={17} style={{ height: 'fit-content' }}>
        <Card
          style={{
            padding: 0,
            background: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          <div>
            <Title level={4}>{t('totals.title')}</Title>
            <div style={{ width: '110px' }}>
              <hr
                style={{
                  backgroundColor: 'black',
                  height: '3px',
                  border: 'none',
                  marginBottom: '18px'
                }}
              />
            </div>
            <Row>
              <Col span={8}>
                <div>
                  {`${t('totals.withoutEcotax')} : `}
                  <strong>{format(datas?.totalWithoutEcoTax)}</strong>
                </div>
                <div>
                  {`${t('totals.ecotax')} : `}
                  <strong>{format(datas?.totalEcoTax)}</strong>
                </div>
                <div>
                  {`${t('totals.comPro')} : `}
                  <strong>{formatPercentage(datas?.comPro || 0)}</strong>
                </div>
                <div>
                  {`${t('totals.amountComPro')} : `}
                  <strong>{format(resultComPro(datas))}</strong>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  {`${t('totals.totalLay')} : `}
                  <strong>{format(datas?.totalLayLabor)}</strong>
                </div>
                <div>
                  {`${t('totals.totalsAccessories')} : `}
                  <strong>{format(datas?.totalsAccessories)}</strong>
                </div>
                <div>
                  {`${t('totals.totalProducts')} : `}
                  <strong>{format(datas?.totalProducts)}</strong>
                </div>
                <div>
                  {`${t('totals.deliveryCost')} : `}
                  <strong>{format(datas?.deliveryCost)}</strong>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  {`${t('totals.totalPro')} : `}
                  <strong>{format(datas?.totalPro)}</strong>
                </div>
                <div>
                  {`${t('totals.total')} : `}
                  <strong>{format(datas?.totalClient)}</strong>
                </div>
                <div>
                  {`${t('totals.totalTTC')} : `}
                  <strong>{format(datas?.totalsTTC)}</strong>
                </div>
                <div>
                  {`${t('totals.tva')} : `}
                  <strong>{formatPercentage(datas?.tva || 0)}</strong>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    );
  }

  return <div> Totals loading ...</div>;
};

Totals.propTypes = {
  datas: PropTypes.shape({
    comPro: PropTypes.number,
    deliveryCost: PropTypes.number,
    totalEcoTax: PropTypes.number,
    totalLayLabor: PropTypes.number,
    totalMatters: PropTypes.number,
    totalPro: PropTypes.number,
    totalProducts: PropTypes.number,
    totalWithoutEcoTax: PropTypes.number,
    totalsAccessories: PropTypes.number,
    totalsTTC: PropTypes.number,
    tva: PropTypes.number,
    totalClient: PropTypes.number
  }).isRequired
};

export default Totals;
