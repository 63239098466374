import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Modal,
  Input,
  Button,
  InputNumber,
  Divider
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import { CloseIcon } from '../../../../utils/constants/customIcon';

const Accessories = ({ datas, forceRefresh, isEditable }) => {
  const { dispatchAPI } = useAuthContext();
  const { Title } = Typography;
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onEditeAccessoire = () => {
    setVisible(true);
    let values = datas;
    form.setFieldsValue(datas);
  };
  const handleOk = async () => {
    setVisible(false);
    // // form.resetFields();

    const values = form.getFieldsValue();
    await dispatchAPI('PATCH', {
      url: `/invoices/${id}`,
      body: {
        accessories: values.accessories,
        number: datas.number
      }
    });
    forceRefresh();
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  if (datas) {
    return (
      <Col span={8} style={{ minHeight: '100%' }}>
        <Card
          style={{ background: 'rgba(255, 255, 255, 0.8)', height: '100%' }}
          bordered={false}
        >
          <Row>
            <Col>
              <Title level={4}>
                Accessoires
                {isEditable && (
                  <EditOutlined
                    style={{ fontSize: '20px', float: 'right' }}
                    onClick={() => {
                      onEditeAccessoire();
                    }}
                  />
                )}
                <Modal
                  visible={visible}
                  title="Modifier les produits"
                  onOk={handleOk}
                  width={1000}
                  onCancel={handleCancel}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Annuler
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      Enregistrer
                    </Button>
                  ]}
                >
                  <Form form={form}>
                    <Form.List name={['accessories']}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ name, fieldKey }) => (
                            <div
                              style={{
                                position: 'relative'
                              }}
                              className="quotation-form-fields-wrapper"
                            >
                              <Form.Item
                                label="Accessoire"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 8 }}
                                name={[name, 'name']}
                                fieldKey={[fieldKey, 'name']}
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Accessoire" />
                              </Form.Item>
                              <Form.Item
                                label="Prix"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 8 }}
                                name={[name, 'price']}
                                fieldKey={[fieldKey, 'price']}
                                rules={[{ required: true }]}
                              >
                                <InputNumber min={0} step={0.01} />
                              </Form.Item>

                              <Form.Item
                                label="Quantité"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 8 }}
                                name={[name, 'quantity']}
                                fieldKey={[fieldKey, 'quantity']}
                                rules={[{ required: true }]}
                              >
                                <InputNumber min={1} />
                              </Form.Item>

                              <Button type="link" onClick={() => remove(name)}>
                                <CloseIcon />
                              </Button>
                              <Divider />
                            </div>
                          ))}
                          <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              className="quotation-add-btn"
                            >
                              Nouveau Accessoire
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form>
                </Modal>
              </Title>
              <div style={{ width: '110px' }}>
                <hr
                  style={{
                    backgroundColor: 'black',
                    height: '3px',
                    border: 'none',
                    marginBottom: '18px'
                  }}
                />
              </div>
              <Row style={{ minHeight: '100px' }}>
                {datas.accessories &&
                  datas.accessories.map((accessory, index) => {
                    return (
                      <Col style={{ padding: '6px 6px 6px 0' }}>
                        <Card style={{ width: '90%' }} bordered={false}>
                          <Row>
                            Nom :&nbsp;
                            <b>{`${accessory.name}`}</b>
                          </Row>
                          <Row>
                            Prix :&nbsp;
                            <b>{`${accessory.price}`}</b>
                          </Row>
                          <Row>
                            Quantité :&nbsp;
                            <b>{`${accessory.quantity}`}</b>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }

  return <div>Loading</div>;
};

Accessories.propTypes = {
  datas: PropTypes.shape({
    accessories: PropTypes.shape({
      map: PropTypes.func
    }),
    products: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired,
  forceRefresh: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

export default Accessories;
