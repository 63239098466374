import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Divider, message, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../utils/constants/routes';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import TablesTab from './TablesTab';

const ShowSupplier = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [profesional, setProfesional] = useState({});
  const { dispatchAPI, canEditResource } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/suppliers/${id}?populate=site`
      });

      setProfesional(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const deleteProfesional = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/profesionals/${id}` });
      history.push(
        `${routes.SUPPLIERS_ACCOUNTING}${subRoutes.SUPPLIERS_ACCOUNTING.SUPPLIERS}`
      );
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`suppliers.details`)}
        withSubRoutes
        extra={
          canEditResource(path) && (
            <>
              <Link
                to={{
                  pathname: `${routes.BUYING}${subRoutes.BUYING.SUPPLIERS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteProfesional}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )
        }
      />

      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(profesional)} translate />
        </Skeleton>
        <Divider orientation="left" style={{ marginTop: 48 }}>
          {t('companies.show.contactListTitle')}
        </Divider>
        <TablesTab
          contacts={profesional.contacts}
          extraQuery={`supplier=${profesional._id}`}
        />
      </ContentCustom>
    </>
  );
};

export default ShowSupplier;
