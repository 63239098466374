/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, Spin, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const OrderForm = ({
  handleSubmit,
  resource,
  initialValues,
  fields,
  isLoading
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();

  return (
    <Spin spinning={isLoading}>
      <Form
        {...formItemLayout}
        name="ordersForm"
        onFinish={(values) => handleSubmit(values)}
        initialValues={initialValues}
        form={form}
      >
        {fields.map((field) => generateFields(resource, field))}

        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => history.goBack()}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
};

OrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired
};

OrderForm.defaultProps = {
  initialValues: null
};

export default OrderForm;
