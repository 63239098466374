import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tag, Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const PieceTable = (refresh) => {
  const { t } = useTranslation();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const { message } = useErrorMessage();

  const deleteResource = async (piece, key) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/pieces/${piece._id}`,
        body: {
          sizes: piece.sizes.filter((size) => size._id !== key._id)
        }
      });
      refresh();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const actionColumns = (piece, key) => {
    return (
      <>
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `stocks/show/${piece._id}`
            }}
          >
            <EyeOutlined />
          </Link>
          {canEditResource(path) && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(piece, key)}
              icon={<WarningOutlined />}
            >
              <Divider type="vertical" />
              <DeleteOutlined style={{ color: 'red' }} type="delete" />
            </Popconfirm>
          )}
        </div>
      </>
    );
  };

  return [
    {
      title: t('stocks.matter'),
      dataIndex: 'matter',
      render: (matter) => (
        <Tag>
          {matter.label}
          <br />
          {matter.color}
        </Tag>
      )
    },
    {
      title: t('stocks.ref_supplier'),
      dataIndex: 'ref_supplier'
    },
    {
      title: t('stocks.number'),
      dataIndex: 'number',
      sorter: {
        compare: (a, b) => {
          return a.number - b.number;
        }
      }
    },

    {
      title: t('stocks.type'),
      dataIndex: 'type'
    },
    {
      title: t('stocks.entry_date'),
      dataIndex: 'entry_date',
      render: (date) => moment(date).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => {
          return new Date(a.entry_date) - new Date(b.entry_date);
        }
      }
    },
    {
      title: t('stocks.exit_date'),
      dataIndex: 'exit_date',
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : '')
    },
    {
      title: t('stocks.person_in_charge'),
      dataIndex: 'person_in_charge'
    },
    {
      title: t('stocks.order'),
      dataIndex: 'order',
      render: (order) => order?.number
    },
    {
      title: t('stocks.is_rebut'),
      dataIndex: 'is_rebut',
      render: (isRebut) => (isRebut ? 'Oui' : 'Non')
    },
    {
      title: t('stocks.shape'),
      dataIndex: 'shape'
    },
    {
      title: t('stocks.dimensions'),
      dataIndex: 'size',
      render: (size) => `${size.length}x${size.width}`,
      sorter: {
        compare: (a, b) => a.length * a.width - b.length * b.width
      }
    },
    {
      key: 'action',
      dataIndex: 'piece',
      render: (piece, key) => actionColumns(piece, key)
    }
  ];
};

export default PieceTable;
