import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Form,
  InputNumber,
  Input,
  Typography,
  Space,
  Tooltip
} from 'antd';
import useConfiguratorContext from './ConfiguratorContext';

const TotalConditionsForm = () => {
  const { currentStep, t, datas } = useConfiguratorContext();
  const { tvas } = datas;

  const renderTtcLabel = (
    <>
      {t('configurator.totals_ttc')}
      <Tooltip title={t('configurator.tva_products')}>
        <InfoCircleOutlined style={{ marginLeft: 10 }} />
      </Tooltip>
    </>
  );

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ display: currentStep === 4 ? '' : 'none' }}
      >
        {t('configurator.total_conditions')}
      </Typography.Title>
      <div className="quotation-form-fields-wrapper">
        <Row>
          <Col span={10} offset={2}>
            <Form.Item
              label="type de facturation"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
              name={['totalConditions', 'billingType']}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('configurator.com_rate')}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
            >
              <Space>
                <Form.Item name={['totalConditions', 'comRate']}>
                  <InputNumber defaultValue="0.00" precision={2} />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalConditionsForm;
