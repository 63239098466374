/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  InputNumber,
  Form,
  Input,
  Select,
  message as stop
} from 'antd';
import { TextArea } from 'antd/lib/input/TextArea';
import { CreditCardOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const InvoicesModal = ({ setForceRefresh, forceRefresh, toPayed }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [enums, setEnums] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setForceRefresh(!forceRefresh);
    setIsModalVisible(false);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
  };

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  };

  const onFinish = async (payed) => {
    if (toPayed - payed.amount < 0) {
      return stop.error('Le montant saisie est supérieur au reste à charge !');
    }

    const status = toPayed === payed.amount ? 'PAYED' : 'PENDING';
    const body = {
      payed,
      status
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/invoices/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    handleOk();
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        icon={<CreditCardOutlined />}
        getContainer={() => (
          <>
            <TextArea />
            <InputNumber />
          </>
        )}
      >
        Payment
      </Button>

      <Modal
        title="Procéder a un payment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('quotations.show.amount')}
            name="amount"
            rules={[
              {
                message: 'Please input your username!'
              }
            ]}
          >
            <Input type={Number} />
          </Form.Item>

          <Form.Item
            label={t('quotations.show.method')}
            name="method"
            rules={[
              {
                message: 'Please input your username!'
              }
            ]}
          >
            <Select>
              {(enums.methods || []).map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Payer par" name="user">
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Validé
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

InvoicesModal.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  toPayed: PropTypes.number.isRequired
};

export default InvoicesModal;
