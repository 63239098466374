import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useDealContext from './DealsContext';

const EditDrawer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const {
    visible,
    setVisible,
    deal,
    setDeal,
    dataRefresh,
    setDataRefresh
  } = useDealContext();
  const { fields, formItems, isFieldsLoading } = useFields({
    visible,
    form,
    loaded
  });

  const onClose = () => {
    setVisible(false);
    form.resetFields();
    setDeal(null);
    setLoaded(false);
    setDataRefresh(!dataRefresh);
  };

  const onFinishForm = async (values) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const allFiles = [...(values.files || []), ...files];
    const body = {
      ...values,
      lead: user._id,
      files: allFiles
    };
    try {
      await dispatchAPI(deal ? 'PATCH' : 'POST', {
        url: `/deals${deal ? `/${deal}` : ''}`,
        body
      });
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchDeal = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${deal}`
      });
      form.setFieldsValue({
        ...data,
        expected_date: moment(data.expected_date),
        status: data.status._id,
        site: data.site._id
      });
      setFiles(data.files);
      setLoaded(true);
    } catch (e) {
      form.setFieldsValue({ files: [] });
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [deal]);

  useEffect(() => {
    if (deal && !isFieldsLoading) {
      (async () => {
        await fetchDeal();
      })();
    }
  }, [fetchDeal, isFieldsLoading]);

  return (
    <Drawer
      title={t(`deals.show.${deal ? 'edit' : 'add'}`)}
      width={480}
      onClose={onClose}
      visible={visible}
    >
      <Spin spinning={isLoading}>
        <Form
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={onFinishForm}
        >
          {formItems}
          <Form.Item style={{ marginTop: 16 }}>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onClose}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditDrawer;
