import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateBuildingSites = ({ purpose }) => {
  const { basicFields, advancedFields, isFieldsLoading } = useFields();
  const location = useLocation();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const crm = location?.state?.crm;
        return {
          ...data,
          crm
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      populate="site,customers"
      baseUrl="building-sites"
      resource="building-sites"
      loadingFields={isFieldsLoading}
      withSubRoutes
      config={config}
    />
  );
};

CreateUpdateBuildingSites.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateBuildingSites;
