import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListDartyMatters from './ListDartyMatters';
import CreateUpdateDartyMatter from './CreateUpdateDartyMatter';
import ShowDartyMatter from './ShowDartyMatter';

const DartyMatterRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateDartyMatter purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateDartyMatter purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowDartyMatter} />
      <Route path={path} component={ListDartyMatters} />
    </Switch>
  );
};

export default DartyMatterRouter;
