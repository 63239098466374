import { GoldOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import BarcodeScan from './BarcodeScan';
import marbleBlack from '../../assets/images/marbleBlack.jpg';
import ColumnChartTop from './ColumnChartTop';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ColumnChartRebut from './ColumnChartRebut';
import useHandleResize from '../../utils/HandleResize';

const StockShortcut = ({ sites }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [dataStocks, setDataStocks] = useState([]);
  const [filter, setFilters] = useState();
  const [total, setTotal] = useState(0);
  const { width } = useHandleResize();

  const getPieces = async (parameter) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/pieces?populate=matter,sizes.order${parameter || ''}`
      });
      setDataStocks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (dataStocks.length) {
      const totals = dataStocks.reduce(
        (acc, obj) => Number(acc) + Number(obj?.value || 0),
        0
      );
      setTotal(totals);
    } else setTotal(0);
  }, [dataStocks]);

  useEffect(() => {
    let filterParameter = '';
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key]) {
          filterParameter += `&${key}=${filter[key]}`;
        }
      });
      getPieces(filterParameter);
    } else {
      getPieces();
    }
  }, [filter]);

  return (
    <div
      justify="space-between"
      style={{
        background: 'rgb(249, 251, 252)',
        padding: 14,
        backgroundColor: '#edeef0',
        boxShadow: '0.5rem 0.5rem 1rem -1rem rgb(58,57,57)'
      }}
    >
      <Row gutter={[0, 8]}>
        <Col
          xs={6}
          xl={3}
          xxl={4}
          style={{
            fontSize: 16,
            textAlign: 'left',
            letterSpacing: 2
          }}
        >
          {t('dashboard.stocks.title')}
        </Col>
        <Col xs={24} md={18} xl={13} xxl={12}>
          {t('dashboard.stocks.filters.title')}
          <Select
            allowClear
            onClear={() => setFilters(null)}
            style={{ marginLeft: 5, width: 150 }}
            onChange={(value) => {
              setFilters({ ...filter, buy_site: value });
            }}
            placeholder={t('dashboard.stocks.placeholder.buy_site')}
            options={
              sites && sites.map(({ _id, label }) => ({ label, value: _id }))
            }
          />
          <Select
            allowClear
            onClear={() => setFilters(null)}
            style={{ marginLeft: 5, width: 160 }}
            onChange={(value) => setFilters({ ...filter, projectType: value })}
            placeholder={t('dashboard.stocks.placeholder.projectType')}
          />
        </Col>
        <Col xs={24} xl={8}>
          {t('dashboard.stocks.dates.from')}
          <DatePicker
            style={{ marginLeft: 4, marginRight: 4, width: 120 }}
            onChange={(value) => {
              setFilters({ ...filter, date_lowest: value });
            }}
          />
          {t('dashboard.stocks.dates.to')}
          <DatePicker
            style={{ marginLeft: 4, marginRight: 4, width: 120 }}
            onChange={(value) => setFilters({ ...filter, date_highest: value })}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col style={{ padding: '20px 0' }} xs={24} xl={8}>
          <Row>
            <Col
              xs={24}
              sm={12}
              style={{
                padding: '5px'
              }}
            >
              <BarcodeScan state="exit" />
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                padding: '5px'
              }}
            >
              <BarcodeScan state="entry" />
            </Col>
          </Row>
          <Row>
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '5px'
              }}
            >
              <Link
                style={{
                  textAlign: 'center',
                  width: width < 577 ? '100%' : '50%'
                }}
                to="/stock/stocks"
              >
                <Button
                  style={{
                    height: '10em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundImage: `url(${marbleBlack})`
                  }}
                  className="customShortcut"
                >
                  <GoldOutlined style={{ fontSize: '30px' }} />
                  <span
                    style={{
                      whiteSpace: 'pre-line',
                      width: '70%',
                      textAlign: 'center',
                      marginLeft: 0
                    }}
                  >
                    {t('dashboard.stocks.all_stocks')}
                  </span>
                </Button>
              </Link>
            </div>
          </Row>
          <Row>
            <Row
              style={{
                width: '100%'
              }}
            >
              <div
                style={{
                  padding: '20px 0',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                {t('dashboard.stocks.totals')}
              </div>
            </Row>
            <Row
              style={{
                width: '100%'
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: 30,
                  fontWeight: 900,
                  fontSize: 25,
                  letterSpacing: 2,
                  textAlign: 'center'
                }}
              >
                {numeral(total).format('0,0.00')} €
              </div>
            </Row>
          </Row>
        </Col>
        <Col xs={24} xl={8} style={{ padding: '20px' }}>
          <div style={{ margin: '0 0 20px 0px', height: 20 }}>
            {t('dashboard.stocks.top_10')}
          </div>
          <div style={{ width: '100%' }}>
            <ColumnChartTop dataStocks={dataStocks} />
          </div>
        </Col>
        <Col xs={24} xl={8} style={{ padding: '20px' }}>
          <div style={{ margin: '0 0 20px 0px', height: 20 }}>
            {t('dashboard.stocks.rest_matter')}
          </div>
          <div>
            <ColumnChartRebut dataStocks={dataStocks} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
StockShortcut.propTypes = {
  sites: PropTypes.shape().isRequired
};
export default StockShortcut;
