import React from 'react';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ContactsForm = () => {
  const { t } = useTranslation();

  return (
    <Form.List name="contacts">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <>
              <Form.Item
                name={[field.name, 'first_name']}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={t('profesionals.form.first_name')} />
              </Form.Item>
              <Form.Item
                name={[field.name, 'last_name']}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={t('profesionals.form.last_name')} />
              </Form.Item>
              <Form.Item
                name={[field.name, 'role']}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={t('profesionals.form.role')} />
              </Form.Item>
              <Form.Item
                name={[field.name, 'email']}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={t('profesionals.form.email')} />
              </Form.Item>
              <Form.Item
                name={[field.name, 'phone_number']}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input placeholder={t('profesionals.form.phone_number')} />
              </Form.Item>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
            >
              {t('profesionals.addcontact')}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
export default ContactsForm;
