import PropTypes from 'prop-types';
import React from 'react';
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { Checkbox, Col, Row, Tag, Timeline } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MarbleBackground from '../../../../assets/images/marble-background-trello.jpg';

const Line = styled.div`
  position: relative;
  background-color: #f0f0f0;
  margin-right: 40px;
  padding: 15px;
  .type {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
`;

const DisplayActivity = ({ elem, delActivity }) => {
  const dateTime = elem.dateTime ? new Date(elem.dateTime) : null;
  const { t } = useTranslation();

  const getIcon = () => {
    switch (elem.type) {
      case 'call':
        return <PhoneOutlined />;
      case 'meeting':
        return <CalendarOutlined />;
      case 'note':
        return <EditOutlined />;
      default:
        return <UserOutlined />;
    }
  };
  const getTag = () => {
    if (
      moment(elem.dateTime).format('DD-MM-YYYY') ===
      moment().format('DD-MM-YYYY')
    )
      return <Tag color="orange">Aujourd&apos;hui</Tag>;
    if (
      moment(elem.dateTime).format('DD-MM-YYYY') > moment().format('DD-MM-YYYY')
    )
      return <Tag color="green">A venir</Tag>;
    return <Tag color="red">En retard</Tag>;
  };
  return (
    <div>
      <Timeline.Item>
        {elem.dateTime && (
          <div className="date">{moment(dateTime).format('LLLL')}</div>
        )}
        <Line>
          {/* <Checkbox
            style={{
              position: 'absolute',
              top: 8,
              right: 30,
              cursor: 'pointer'
            }}
          /> */}
          <DeleteOutlined
            onClick={() => delActivity(elem)}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer'
            }}
          />
          <div className="type">
            <Row align="middle">
              {getIcon()}
              &nbsp;
              <span style={{ marginLeft: 5 }}>{elem.title}</span>
              &nbsp;
              {/* {getTag()} */}
            </Row>
          </div>
          <Row>
            <Col span={18}>
              <div className="desc">{elem.desc}</div>
            </Col>
            <Col span={6}>
              <div
                className="desc"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                <UserOutlined style={{ marginRight: 8 }} />
                <span>
                  {elem.author ? (
                    <div
                      style={{
                        fontSize: 12
                      }}
                    >
                      {elem?.author.first_name || ''} {elem?.author.last_name}
                    </div>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </Line>
      </Timeline.Item>
    </div>
  );
};

DisplayActivity.propTypes = {
  delActivity: PropTypes.func.isRequired,
  elem: PropTypes.shape({
    author: PropTypes.shape({
      first_name: PropTypes.any,
      last_name: PropTypes.any
    }),
    dateTime: PropTypes.any,
    desc: PropTypes.any,
    title: PropTypes.any,
    type: PropTypes.any
  }).isRequired
};

const ListDealActivity = ({ deal, selectedTab, delActivity }) => {
  const listActivitiesTimeline = () => {
    const sortedArray = deal?.activities.sort((a, b) => {
      return new Date(b.dateTime) - new Date(a.dateTime);
    });

    return sortedArray.map((elem) => (
      <DisplayActivity delActivity={delActivity} elem={elem} key={elem._id} />
    ));
  };

  if (deal.activities) {
    return (
      <div
        style={{
          backgroundImage: `url(${MarbleBackground})`,
          backgroundSize: 'cover',
          minHeight: '100%'
        }}
      >
        <div
          style={{
            paddingLeft: '14px',
            paddingTop: '30px'
          }}
        >
          {selectedTab === 'note' &&
            deal?.activities
              .filter((activity) => activity.type === 'note')
              .map((elem) => (
                <DisplayActivity
                  delActivity={delActivity}
                  elem={elem}
                  key={elem._id}
                />
              ))}
          {selectedTab === 'calls' &&
            deal?.activities
              .filter((activity) => activity.type === 'call')
              .map((elem) => (
                <DisplayActivity
                  delActivity={delActivity}
                  elem={elem}
                  key={elem._id}
                />
              ))}
          {selectedTab === 'meeting' &&
            deal?.activities
              .filter((activity) => activity.type === 'meeting')
              .map((elem) => (
                <DisplayActivity
                  delActivity={delActivity}
                  elem={elem}
                  key={elem._id}
                />
              ))}
          {selectedTab === 'activity' && (
            <Timeline>{listActivitiesTimeline()}</Timeline>
          )}
        </div>
      </div>
    );
  }
  return null;
};

ListDealActivity.propTypes = {
  deal: PropTypes.shape({
    activities: PropTypes.shape({
      filter: PropTypes.func,
      sort: PropTypes.func
    })
  }).isRequired,
  delActivity: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired
};

export default ListDealActivity;
