import React, { useEffect, useState } from 'react';
import {
  BellOutlined,
  CheckOutlined,
  HourglassOutlined,
  QuestionOutlined,
  RetweetOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { Button, Input, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import TrelloTable from './trelloTable';

const { Option } = Select;

const initialColumns = [
  {
    title: 'KANBAN',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  },
  {
    title: 'Icône',
    dataIndex: 'icon',
    key: 'icon',
    render: (icon) => (
      <Select InitialValue={icon}>
        <Option value="bell">
          <BellOutlined />
        </Option>
        <Option value="hourglass">
          <HourglassOutlined />
        </Option>
        <Option value="Check">
          <CheckOutlined />
        </Option>
        <Option value="question">
          <QuestionOutlined />
        </Option>
        <Option value="review">
          <RetweetOutlined />
        </Option>
        <Option value="rollback">
          <RollbackOutlined />
        </Option>
      </Select>
    )
  }
];

const TrelloSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [kanbanCols, setKanbanCols] = useState(initialColumns);
  const [newKanbanCol, setNewKanbanCol] = useState({
    label: 'Nouvelle colonne',
    icon: null,
    show: true,
    color: '#31b3fb'
  });
  const fetchDatas = async () => {
    try {
      const data = await dispatchAPI('GET', { url: `/building-sites` });
      setDatas(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const getKanbanCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/trellos' });
      setKanbanCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addKanbanCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/trellos', body: newKanbanCol });
      getKanbanCols();
    } catch (e) {
      message.error(e.message);
    }
  };
  const deleteKanbanCol = async (kanbanCol) => {
    if (datas.filter((deal) => deal.status === kanbanCol.label).length > 0) {
      return message.error(t('settings.kanbantable.error-delete-column'));
    }
    try {
      await dispatchAPI('DELETE', {
        url: `/trellos/${kanbanCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getKanbanCols();
  };
  const editKanbanCol = async (_id, row) => {
    const col = kanbanCols.find((c) => c._id === _id);
    const usecol = datas.filter((deal) => deal.status === col.label);
    if (usecol.length !== 0 && row.label !== col.label) {
      return message.error(t('settings.kanbantable.error-delete-column'));
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/trellos/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getKanbanCols();
  };
  useEffect(() => {
    getKanbanCols();
    fetchDatas();
  }, []);

  return (
    <>
      <TrelloTable
        kanbanCols={kanbanCols}
        editKanbanCol={editKanbanCol}
        deleteKanbanCol={deleteKanbanCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addKanbanCol}
      >
        {t('settings.kanbantable.addkanbancol')}
      </Button>
    </>
  );
};

export default TrelloSettings;
