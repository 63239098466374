import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import OptionsRow from './OptionsRow';

const ListProfesionals = ({ type }) => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);
  const { t } = useTranslation();
  const headers = [
    {
      label: t('ordersPurchases.supplier'),
      key: 'supplier.name'
    },
    {
      label: t('ordersPurchases.category'),
      key: 'category'
    },
    {
      label: t('ordersPurchases.site'),
      key: 'site.label'
    },
    {
      label: t('ordersPurchases.created_at'),
      key: 'created_at'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="orders-buyings"
      resourceModelName="OrderBuying"
      withSubRoutes
      extraQuery={extraQuery}
      populate="site,supplier,supplies.matter"
      headers={headers}
      withCreateButton={false}
      customActionColumn
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

ListProfesionals.propTypes = {
  type: PropTypes.string.isRequired
};

export default ListProfesionals;
