import React from 'react';
import useConfiguratorContext from './ConfiguratorContext';
import EcoTaxForm from './EcotaxForm';
import TotalsForm from './TotalsForm';
import TotalConditions from './TotalConditions';

const RecapsForm = () => {
  const { currentStep } = useConfiguratorContext();

  return (
    <div
      style={{ display: currentStep === 4 ? '' : 'none' }}
      className="quotation-form-wrapper"
    >
      <EcoTaxForm />
      <br />
      <TotalConditions />
      <br />
      <TotalsForm />
    </div>
  );
};

export default RecapsForm;
