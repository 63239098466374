import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListShapings from './ListShapings';
import CreateUpdateShaping from './CreateUpdateShaping';
import ShowShaping from './ShowShaping';

const ShapingRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateShaping purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateShaping purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowShaping} />
      <Route path={path} component={ListShapings} />
    </Switch>
  );
};

export default ShapingRouter;
