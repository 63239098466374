import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../utils/ErrorMessage';
import CommissionAmount from './CommissionAmount';
import CommissionTable from './CommissionTable';

const { Step } = Steps;

const ShowArticle = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [com, setCom] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);

  const deleteArticle = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/commissions/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getArticle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions/${id}?populate=profesional,payed.user,order`
      });
      setProduct(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id, forceRefresh]);

  useEffect(() => {
    (async () => {
      await getArticle();
    })();
  }, [getArticle]);

  return (
    <>
      <PageHeaderCustom
        title={product.label || ''}
        extra={
          <>
            <CommissionAmount
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              data={product}
              setCom={setCom}
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteArticle}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList
            data={listContent(product, forceRefresh)}
            translate
          />
        </Skeleton>
      </ContentCustom>

      <br />

      <ContentCustom>
        <Row>
          <Col span={6} offset={8}>
            <Steps current={com === product.amount ? 1 : 0}>
              <Step title={t('invoices.enums.PENDING')} />
              <Step title={t('invoices.enums.PAYED')} />
            </Steps>
          </Col>
        </Row>
        <br />
        <CommissionTable data={product} />
      </ContentCustom>
    </>
  );
};

export default ShowArticle;
