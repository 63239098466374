/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Button, Modal, DatePicker } from 'antd';

const MetreModal = ({
  handleOkMetre,
  handleCancelMetre,
  isModalVisible,
  onFinish
}) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
  };

  const onFinishFailed = (errorInfo) => {};
  const dateFormat = 'YYYY/MM/DD';

  return (
    <>
      <Modal
        title="Date prévisionnelle du métré"
        visible={isModalVisible}
        onOk={handleOkMetre}
        onCancel={handleCancelMetre}
        footer={false}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Le"
            name="metre"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <DatePicker format={dateFormat} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

MetreModal.propTypes = {
  handleCancelMetre: PropTypes.func.isRequired,
  handleOkMetre: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired
};

export default MetreModal;
