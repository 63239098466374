import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [suppliers, setSuppliers] = useState();
  const [products, setProducts] = useState();

  const getProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/products' });
      setProducts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSuppliers();
      await getProducts();
    })();
  }, []);

  return [
    {
      title: t('suppliers.show.supplier'),
      dataIndex: 'supplier'
    },
    {
      title: t('suppliers.show.label'),
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('suppliers.show.brand'),
      dataIndex: 'brand',
      sorter: true
    },
    {
      title: t('suppliers.show.ref_internal'),
      dataIndex: 'ref_internal'
    },
    {
      title: t('suppliers.show.category'),
      dataIndex: 'catalogue',
      sorter: true,
      render: (catalogue) => catalogue?.label,
      filters:
        products &&
        products?.map((c) => ({
          text: c.label,
          value: c._id
        }))
    },
    {
      title: t('suppliers.show.quantity'),
      dataIndex: 'quantity',
      sorter: true
    },
    {
      title: t('suppliers.show.quantity_min'),
      dataIndex: 'quantity_min',
      sorter: true
    },
    {
      title: t('suppliers.show.buying_price'),
      dataIndex: 'buying_price',
      render: (price) => `${numeral(price).format('0,0.00')} €`,
      sorter: true
    },
    {
      title: t('suppliers.show.selling_price'),
      dataIndex: 'selling_price',
      render: (price) => `${numeral(price).format('0,0.00')} €`,
      sorter: true
    }
  ];
};

export default useColumns;
