import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Form, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useWorkSheetContext from './WorkSheetContext';
import FinitionsForm from './FinitionsForm';
import HeaderForm from './HeaderForm';
import FooterForm from './FooterForm';
import ProcessForm from './ProcessForm';
import MarbleBackground from '../../assets/images/white-stone_Scott-Webb.jpg';
import MatterForm from './Matter';
import WorksheetButtons from './WorksheetButtons';

const { Panel } = Collapse;

const WorksheetForm = ({ handleValidate, handleDelete, handleOk }) => {
  const {
    form,
    state,
    process,
    template,
    finitions,
    sites,
    matterfinits,
    machines,
    operators,
    downloadFile
  } = useWorkSheetContext();
  const { t } = useTranslation();
  const [isEditable] = useState(
    !(state.worksheet && state.worksheet.validated)
  );

  return (
    <div
      style={{
        backgroundImage: `url(${MarbleBackground})`,
        backgroundSize: 'cover',
        minHeight: 'calc(100vh - 200px)'
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        initialValues={{
          ...state?.worksheet,
          lay_date: state?.datas?.placed?.lay_date
            ? moment(state.datas.placed.lay_date)
            : null,
          measurement_date: state?.datas?.placed?.measurement_date
            ? moment(state.datas.placed.measurement_date)
            : null,
          matter: []
        }}
      >
        <Row
          justify="space-between"
          align="stretch"
          style={{
            padding: '24px',
            flexWrap: 'nowrap',
            height: 'fit-content'
          }}
          gutter={24}
        >
          <HeaderForm
            form={form}
            datas={state}
            sites={sites}
            isEditable={isEditable}
          />
        </Row>
        <Row
          justify="space-between"
          align="stretch"
          style={{
            padding: '0px 24px 0px 24px',
            flexWrap: 'nowrap',
            height: 'fit-content'
          }}
          gutter={24}
        >
          <MatterForm
            datas={state}
            form={form}
            matterfinits={matterfinits}
            isEditable={isEditable}
          />
        </Row>
        <Row
          justify="space-between"
          align="stretch"
          style={{
            padding: '24px',
            flexWrap: 'nowrap',
            height: 'fit-content'
          }}
          gutter={24}
        >
          <Col span={24}>
            <Collapse accordion style={{ marginBottom: 24 }}>
              <Panel header="Usinages" key="process">
                <ProcessForm
                  machines={machines}
                  operators={operators}
                  process={process}
                  sites={sites}
                  isEditable={isEditable}
                />
              </Panel>
            </Collapse>
            <Collapse accordion>
              <Panel header={t('worksheets.finitions')} key="finitions">
                <FinitionsForm
                  operators={operators}
                  machines={machines}
                  finitions={finitions}
                  sites={sites}
                  isEditable={isEditable}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="stretch"
          style={{
            padding: '0px 24px 24px 24px',
            flexWrap: 'nowrap',
            height: 'fit-content'
          }}
        >
          <FooterForm quote={state} />
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <Row
            style={{
              padding: '0px 20px 24px 20px',
              flexWrap: 'nowrap',
              height: 'fit-content'
            }}
            justify="end"
            align="stretch"
          >
            <WorksheetButtons
              state={state}
              template={template}
              downloadFile={downloadFile}
              isEditable={isEditable}
              handleValidate={handleValidate}
              handleDelete={handleDelete}
              handleOk={handleOk}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WorksheetForm;

WorksheetForm.propTypes = {
  handleValidate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired
};
