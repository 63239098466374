import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Divider, Space, Button, Typography, Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  LoadingOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Title } = Typography;

const AcceptanceReports = ({ order }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState({});
  const [signedReports, setSignedReports] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});

  const getDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/acceptance-report?order=${order._id}&populate=order,worksheet,worksheet.customer,order.products.product,order.section_1.elements.color`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSignedAcceptanceReports = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/signed-acceptance-report?order=${order._id}&populate=file`
      });
      setSignedReports(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  useEffect(() => {
    getDatas();
    getSignedAcceptanceReports();
  }, [order, refresh]);

  const columns = [
    {
      title: 'Numéro',
      dataIndex: 'validated',
      render: (el, _, index) => (
        <div>
          {`${order.number}-PV${index + 1}`} &nbsp;
          {el && <Tag color="green">EDITÉ</Tag>}
        </div>
      )
    },
    {
      title: 'FT associé',
      dataIndex: 'numberWorkSheet'
    },
    {
      title: 'Date de création',
      dataIndex: 'created_at',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: 'Date de la pose',
      dataIndex: 'date',
      render: (el) => (el ? moment(el).format('DD-MM-YYYY') : 'Non planifié(e)')
    },
    {
      title: 'Action',
      render: (acceptanceReport) => (
        <Space>
          <Link
            to={{
              pathname: `/acceptance-report/show/${acceptanceReport._id}`, // TODO : verifier le path
              state: { acceptanceReport }
            }}
          >
            <EditOutlined style={{ color: 'black', fontSize: 18 }} />
          </Link>
        </Space>
      )
    }
  ];

  const signedReportsColumns = [
    {
      title: 'Date de mise en ligne',
      dataIndex: 'created_at',
      render: (el) => moment(el).format('DD-MM-YYYY')
    },
    {
      title: 'Réserves',
      dataIndex: 'with_reserve',
      render: (el) => (el ? 'Oui' : 'Non')
    },
    {
      title: 'Description réserves',
      dataIndex: 'reserve',
      render: (el) => el
    },
    {
      title: 'Action',
      render: (element) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={async () => {
            if (!isDownloading[element._id]) {
              setIsDownloading({
                ...isDownloading,
                [element._id]: true
              });
              // eslint-disable-next-line react/prop-types
              await downloadFile(
                element.file._id,
                element.file.metadata.originalName
              );
            }
          }}
        >
          {isDownloading[element._id] ? (
            <LoadingOutlined />
          ) : (
            <DownloadOutlined />
          )}
        </Button>
      )
    }
  ];

  if (Object.keys(datas).length) {
    return (
      <div>
        <Title level={4}>Procès verbaux de réception</Title>
        <Table dataSource={datas} columns={columns} pagination={false} />
        {Object.keys(signedReports).length !== 0 && (
          <div>
            <Divider />
            <Title level={4}>Procès verbaux de réception signés</Title>
            <Table
              dataSource={signedReports}
              columns={signedReportsColumns}
              pagination={false}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

AcceptanceReports.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.any,
    number: PropTypes.string
  }).isRequired
};

export default AcceptanceReports;
