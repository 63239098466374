import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const style = {
  background: 'black',
  color: 'white',
  border: 'black',
  margin: '3px'
};

const OptionsRow = ({ setFilter }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [matterOptions, setMatterOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [blocOptions, setBlocOptions] = useState([]);
  const [finitionsOptions, setFinitionsOptions] = useState([]);
  const [thicknessOptions, setThicknessOptions] = useState([]);

  const getOptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pieces?populate=matter'
      });

      const newMatterOptions = data.map((piece) => piece.matter.label);
      const newSupplierOptions = data.map((piece) => piece.ref_supplier);
      const newBlocOptions = data.map((piece) => piece.batch_number);
      const newFinitionsOptions = data.map((piece) => piece.matter.completions);
      const newThicknessOptions = data.map((piece) => piece.thickness);

      setMatterOptions(Array.from(new Set(newMatterOptions)));
      setSupplierOptions(Array.from(new Set(newSupplierOptions)));
      setBlocOptions(Array.from(new Set(newBlocOptions)));
      setFinitionsOptions(Array.from(new Set(newFinitionsOptions)));
      setThicknessOptions(Array.from(new Set(newThicknessOptions)));
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <>
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 200 }}
        placeholder="Matière"
        onChange={(value) => setFilter('matter.label', value)}
        options={matterOptions.map((matter) => ({
          key: matter,
          value: matter
        }))}
        isLoading={isLoading}
        showSearch
      />
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 200 }}
        placeholder="Réf. fournisseur"
        onChange={(value) => setFilter('ref_supplier', value)}
        options={supplierOptions.map((supplier) => ({
          key: supplier,
          value: supplier
        }))}
        isLoading={isLoading}
        showSearch
      />
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 150 }}
        placeholder="Numéro de bloc"
        onChange={(value) => setFilter('batch_number', value)}
        options={blocOptions.map((bloc) => ({ key: bloc, value: bloc }))}
        isLoading={isLoading}
        showSearch
      />
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 200 }}
        placeholder="Finition"
        onChange={(value) => setFilter('matter.completions', value)}
        options={finitionsOptions.map((finition) => ({
          key: finition,
          value: finition
        }))}
        isLoading={isLoading}
        showSearch
      />
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 150, marginRight: 10 }}
        placeholder="Epaisseur"
        onChange={(value) => setFilter('thickness', value)}
        options={thicknessOptions.map((thickness) => ({
          key: thickness,
          value: thickness
        }))}
        isLoading={isLoading}
        showSearch
      />
    </>
  );
};

OptionsRow.propTypes = {
  setFilter: PropTypes.func.isRequired
};

export default OptionsRow;
