import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { Link } from 'react-router-dom';
import QuotationShortcut from './QuotationShortcut';
import OrdersShortcut from './OrdersShortcut';
import StockShortcut from './StockShortcut';
import useAuthContext from '../../contexts/AuthContext';

const Home = () => {
  const { dispatchAPI } = useAuthContext();
  const [sites, setSites] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [stock, setStock] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allData, setAllData] = useState([]);
  const [options, setOptions] = useState([]);

  const welcome = () => {
    const today = new Date();
    const time = today.getHours();
    if (time >= 6 && time <= 9) {
      message.success("Bienvenue sur L'Atelier du Marbre");
    }
  };
  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotes?status!=DELETED'
      });
      setQuotes(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/ordersnew' });
      setOrders(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices' });
      setInvoices(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getPieces = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/pieces?populate=matter'
      });
      setStock(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getProfessionals = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/profesionals'
      });
      setProfessionals(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customers'
      });
      setCustomers(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  useEffect(() => {
    welcome();
    getSites();
    getQuotes();
    getOrders();
    getInvoices();
    getPieces();
    getProfessionals();
    getCustomers();
  }, []);

  useEffect(() => {
    if (quotes) {
      setAllData([...quotes, ...orders, ...invoices]);
    }
    if (orders) {
      setAllData([...quotes, ...orders, ...invoices]);
    }
    if (invoices) {
      setAllData([...quotes, ...orders, ...invoices]);
    }
    if (stock) {
      setAllData([...quotes, ...orders, ...invoices, ...stock]);
    }
    if (professionals) {
      setAllData([
        ...quotes,
        ...orders,
        ...invoices,
        ...stock,
        ...professionals
      ]);
    }
    if (customers) {
      setAllData([
        ...quotes,
        ...orders,
        ...invoices,
        ...stock,
        ...professionals,
        ...customers
      ]);
    }
  }, [quotes, orders, invoices, stock, professionals, customers]);
  const getAllData = (value) => {
    if (value) {
      setOptions(
        allData.filter((element) => {
          if (element?.number && !element.last_name) {
            return element.number.toUpperCase().startsWith(value.toUpperCase());
          }
          if (element?.matter?.label) {
            return element.matter.label
              .toUpperCase()
              .startsWith(value.toUpperCase());
          }
          if (element?.name) {
            return element.name.toUpperCase().startsWith(value.toUpperCase());
          }
          if (element.last_name) {
            return element.last_name
              .toUpperCase()
              .startsWith(value.toUpperCase());
          }
        })
      );
    } else setOptions([]);
  };
  const getLinkTo = (route, id, prefix = '', label) => {
    return {
      label: (
        <Link to={`${route}/show/${id}`}>
          {prefix}
          {label}
        </Link>
      )
    };
  };
  return (
    <div
      style={{
        background: 'white',
        height: '100%',
        width: '100%'
      }}
      // // Add this to the div to make it auto focused at first render
      //  ref={inputRef}
      //   onKeyDown={handleKey}
      //   tabIndex="0"
    >
      {/* <PageHeaderCustom title={t('home.title')} /> */}
      <div
        style={{
          padding: '24px 24px 0px 24px',
          background: 'white'
        }}
      >
        <Select
          style={{ width: '30%' }}
          placeholder="Entrer un mot clé"
          allowClear
          filterOption={false}
          showSearch
          showArrow={false}
          notFoundContent={null}
          onSearch={getAllData}
          options={
            options &&
            options.map((data) => {
              if (data.number && !data.last_name) {
                switch (data.number[0]) {
                  case 'D':
                    return getLinkTo(
                      'customers_accounting/quotes',
                      data._id,
                      'Devis : ',
                      data.number
                    );
                  case 'C':
                    return getLinkTo(
                      'customers_accounting/orders_sales',
                      data._id,
                      'Commande : ',
                      data.number
                    );
                  case 'F':
                    return getLinkTo(
                      'custom_accounting/invoices',
                      data._id,
                      'Facture : ',
                      data.number
                    );
                  default:
                    break;
                }
              }
              if (data.matter) {
                return getLinkTo('stock/stocks', data._id, data.matter.label);
              }
              if (data.name) {
                return getLinkTo(
                  '/commercial/profesionals',
                  data._id,
                  'Professionnel : ',
                  data.name
                );
              }
              if (data.last_name) {
                return getLinkTo(
                  '/commercial/customers',
                  data._id,
                  'Client : ',
                  `${data.last_name} ${data.first_name || ''}`
                );
              }
            })
          }
        />
      </div>
      <div
        style={{
          padding: '24px 24px 24px 24px',
          width: '100%',
          background: 'white'
        }}
      >
        <QuotationShortcut sites={sites} />
        <div style={{ height: 16 }} />
        <OrdersShortcut sites={sites} />
        <div style={{ height: 16 }} />
        <StockShortcut sites={sites} />
        <div style={{ height: 16 }} />
      </div>
    </div>
  );
};

export default Home;
