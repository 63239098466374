import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LaySheet from './index';
import { LaysheetContextProvider } from './LaysheetContext';

const LaySheetRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <LaysheetContextProvider>
        <Route path={`${path}`} component={LaySheet} />
      </LaysheetContextProvider>
    </Switch>
  );
};

export default LaySheetRouter;
