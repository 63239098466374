import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Row, Typography } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import CheckoutForm from './CheckoutForm';
import './stripe.css';

const promise = loadStripe('pk_test_yH3fJCX0cb6ZWDX2IskdCr65');
const { Text } = Typography;

const ModaleCard = ({ visible, amount, setmodalOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={null}
      footer={null}
      mask={false}
      closable={false}
      visible={visible}
    >
      <Row justify="center">
        <Text className="price-title">
          {t('payment.stripe.title')} {amount} €
        </Text>
      </Row>
      <Elements stripe={promise}>
        <CheckoutForm amount={amount} setmodalOpen={setmodalOpen} />
      </Elements>
    </Modal>
  );
};

ModaleCard.propTypes = {
  amount: PropTypes.number.isRequired,
  setmodalOpen: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default ModaleCard;
