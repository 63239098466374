import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Tag } from 'antd';
import { enumsStatus, quoteStatus } from '../../../utils/constants/tagColors';
import useContext from '../context';
import { DrowpdownDownloadButton } from '../../../components/DropdownDonwloadButton/DropdownDownloadButtonQuotation';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

const useColumns = (canEdit) => {
  const { t } = useTranslation();
  const { enums, deleteResource, downloadFile } = useContext();
  const [isDownloading, setIsDownloading] = useState({});

  const basicColumns = [
    {
      title: t('quotes.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('quotes.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={quoteStatus[status]}>{enumsStatus[status]}</Tag>
      ),
      sorter: true,
      filters: enums?.status
        ?.filter((it) => it !== 'DELETED')
        ?.map((el) => ({
          text: <Tag color={quoteStatus[el]}>{enumsStatus[el]}</Tag>,
          value: el
        }))
    },
    {
      title: t('quotes.commercial_site'),
      sorter: true,
      key: 'commercial_site',
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.commercial_site) {
          return `${infos?.commercial_site?.label}`;
        }
        return '_';
      },
      width: '10%'
    },
    {
      title: t('quotes.production_site'),
      sorter: true,
      key: 'production_site',
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.production_site) {
          return `${infos?.production_site?.label}`;
        }
        return '_';
      },
      width: '10%'
    },
    {
      title: t('quotes.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('quotes.customer'),
      dataIndex: 'infos',
      render: (infos) => {
        if (infos && infos.customer) {
          return `${infos?.customer?.first_name || ''} ${
            infos?.customer?.last_name
          }`;
        }
        return '_';
      },
      sorter: true
    },
    {
      title: t('quotes.total'),
      dataIndex: 'totals',
      render: ({ totalClient }) =>
        `${numeral(totalClient).format('0,0.00')} € HT`,
      sorter: true
    }
  ];

  const editActionResource = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, number, status }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/customers_accounting/quotes/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>
            {(status === 'PENDING' || status === 'DRAFT') && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/customers_accounting/quotes/edit/${key}`,
                    state: { quoteId: key }
                  }}
                >
                  <EditOutlined style={iconStyle} />
                </Link>
              </>
            )}

            <Divider type="vertical" />
            <DrowpdownDownloadButton
              recordKey={key}
              number={number}
              isDownloading={isDownloading}
              setIsDownloading={setIsDownloading}
              downloadFile={downloadFile}
            />
            {(status === 'PENDING' || status === 'DRAFT') && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteResource(key)}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: 18 }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
          </span>
        );
      }
    }
  ];

  if (canEdit) return [...basicColumns, ...editActionResource];
  return basicColumns;
};

export default useColumns;
