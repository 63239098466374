/* eslint-disable operator-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/charts';
import numeral from 'numeral';

const ColumnChartRebut = ({ dataStocks }) => {
  const [datas, setDatas] = useState([]);

  const calculateArea = () => {
    const aireOfMatter = {};
    if (dataStocks?.length > 0) {
      dataStocks.forEach((piece) => {
        aireOfMatter[piece.matter.color] = 0;
      });
      dataStocks.forEach((piece) => {
        piece.sizes.forEach((size) => {
          if (size.is_rebut) {
            aireOfMatter[piece.matter.color] =
              aireOfMatter[piece.matter.color] +
              (size.length / 1000) * (size.width / 1000);
          }
        });
      });
      if (Object.keys(aireOfMatter).length > 0) {
        let arrayOfData = Object.keys(aireOfMatter).map((key) => {
          return {
            type: key,
            value: parseFloat(aireOfMatter[key].toFixed(4), 10)
          };
        });
        arrayOfData = arrayOfData.filter((el) => {
          return el.value !== 0;
        });
        setDatas(arrayOfData);
      }
    } else setDatas([]);
  };

  useEffect(() => {
    calculateArea();
  }, [dataStocks]);
  const config = {
    maxColumnWidth: 20,
    width: 200,
    height: 350,
    data: datas,
    xField: 'type',
    yField: 'value',
    seriesField: 'type',
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      maxRow: 4,
      itemWidth: 100
    },
    xAxis: {
      label: false
    }
  };
  return <Column {...config} />;
};

ColumnChartRebut.propTypes = {
  dataStocks: PropTypes.shape().isRequired
};

export default ColumnChartRebut;
