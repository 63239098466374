import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Table,
  Divider,
  PageHeader,
  Skeleton,
  Card
} from 'antd';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import Columns from './Columns';
import useReconciliationContext from './BankReconciliationContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import Account from './Account';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import {
  getCode,
  getExpenses,
  getInvoices,
  getTransactions,
  updateExpenses,
  updateInvoices
} from './services';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const CLIENT_ID = process.env.REACT_APP_BUDGET_INSIGHT_CLIENT_ID;
const WEBVIEW_URI = process.env.REACT_APP_BUDGET_INSIGHT_WEBVIEW_URI;
const CALLBACK_ORIGIN = window.location.origin;

const BankReconciliation = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { path } = useRouteMatch();
  const history = useHistory();
  const {
    user,
    visibleAccounts,
    setVisibleAccounts
  } = useReconciliationContext();
  const [accounts, setAccounts] = useState();
  const [transactions, setTransactions] = useState();
  const [invoices, setInvoices] = useState();
  const [expenses, setExpenses] = useState();
  const [isLoading, setLoading] = useState(false);

  const { location } = history;

  const authenticate = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/bank/auth', body });
      await getAccounts(user._id);
      history.push(path);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const postReconciliation = async (
    transaction,
    invoicesToLink,
    type = 'invoices'
  ) => {
    try {
      const elements = Array.isArray(invoicesToLink)
        ? invoicesToLink
        : [invoicesToLink];

      switch (type) {
        default:
        case 'invoices':
        case 'expenses':
          await dispatchAPI('POST', {
            url: '/reconciliations/simple',
            body: { transaction, [type]: elements }
          });
          break;
        case 'invoices.multiple':
        case 'expenses.multiple':
          await dispatchAPI('POST', {
            url: '/reconciliations/multiple',
            body: { transaction, [type.split('.')[0]]: elements }
          });
          break;
        case 'invoices.partial':
          await dispatchAPI('POST', {
            url: '/reconciliations/partial',
            body: { transaction, [type.split('.')[0]]: elements }
          });
          await updateInvoices(
            dispatchAPI,
            message,
            transaction,
            elements,
            invoices
          );
          break;
        case 'expenses.partial':
          await dispatchAPI('POST', {
            url: '/reconciliations/partial',
            body: { transaction, [type.split('.')[0]]: elements }
          });
          await updateExpenses(
            dispatchAPI,
            message,
            transaction,
            elements,
            expenses
          );
          break;
      }

      const fetchedTransactions = await getTransactions(
        dispatchAPI,
        message,
        user._id,
        visibleAccounts
      );
      setTransactions(fetchedTransactions);
      const fetchedInvoices = await getInvoices(dispatchAPI, message);
      setInvoices(fetchedInvoices);
      const fetchedExpenses = await getExpenses(dispatchAPI, message);
      setExpenses(fetchedExpenses);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAccounts = async (userId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bank/accounts/${userId}`
      });
      setAccounts(data.accounts);
      const tmpVisibleAccounts = [];
      data.accounts.forEach((account) => {
        tmpVisibleAccounts.push(account.id);
      });
      setVisibleAccounts(tmpVisibleAccounts);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    await getAccounts(user._id);
    const fetchedInvoices = await getInvoices(dispatchAPI, message);
    setInvoices(fetchedInvoices);
    const fetchedExpenses = await getExpenses(dispatchAPI, message);
    setExpenses(fetchedExpenses);
    setLoading(false);
  }, []);

  const fetchTransactions = useCallback(async (visibleAccounts2) => {
    const fetchedTransactions = await getTransactions(
      dispatchAPI,
      message,
      user._id,
      visibleAccounts2
    );
    setTransactions(fetchedTransactions);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (visibleAccounts.length > 0) {
      fetchTransactions(visibleAccounts);
    }
  }, [visibleAccounts.length]);

  useEffect(() => {
    if (location.search) {
      const parsed = location.search.split('?')[1];
      const params = parsed.split('&');
      const parsedParams = {};

      params.forEach((param) => {
        const parts = param.split('=');
        parsedParams[parts[0]] = parts[1];
      });

      if (parsedParams.code) {
        authenticate({
          code: decodeURIComponent(parsedParams.code),
          user_id: user._id,
          connection_id: parsedParams.connection_id
        });
      }
    }
  }, [location]);

  const handleVisibilityChange = (accountId) => {
    const tmpVisibleAccounts = [...visibleAccounts];

    if (tmpVisibleAccounts.includes(accountId)) {
      tmpVisibleAccounts.splice(tmpVisibleAccounts.indexOf(accountId), 1);
    } else {
      tmpVisibleAccounts.push(accountId);
    }

    setVisibleAccounts(tmpVisibleAccounts);
  };

  const handleManageAccounts = async () => {
    const code = await getCode(dispatchAPI, message, user._id);
    window.location.replace(
      `${WEBVIEW_URI}2.0/auth/webview/manage?client_id=${CLIENT_ID}&code=${code}&redirect_uri=${CALLBACK_ORIGIN}${path}`
    );
  };

  const handleAddAccounts = () => {
    window.location.replace(
      `${WEBVIEW_URI}2.0/auth/webview/connect?client_id=${CLIENT_ID}&redirect_uri=${CALLBACK_ORIGIN}${path}`
    );
  };

  return (
    <>
      <PageHeaderCustom
        title={t('reconciliations.title')}
        extra={
          !isLoading && accounts ? (
            accounts.length > 0 ? (
              <Button type="primary" onClick={handleManageAccounts}>
                {t('reconciliations.account.manage')}
              </Button>
            ) : (
              <Button type="add" onClick={handleAddAccounts}>
                {t('reconciliations.account.add')}
                &nbsp;
                <PlusOutlined />
              </Button>
            )
          ) : (
            <Skeleton.Button
              active
              size="default"
              style={{ width: 150 }}
              shape="default"
            />
          )
        }
      />

      <ContentCustom>
        {isLoading && (
          <Row>
            <Col span={6} style={{ padding: 5 }}>
              <Card>
                <Skeleton
                  active
                  loading={isLoading}
                  title={{ width: '100%' }}
                  paragraph={{ width: ['100%', '100%', '100%'] }}
                />
              </Card>
            </Col>
          </Row>
        )}

        {accounts && !isLoading && (
          <Row>
            {accounts.map((account) => (
              <Col key={`col_${account.id}`} span={6} style={{ padding: 5 }}>
                <Account
                  account={account}
                  visible={visibleAccounts.includes(account.id)}
                  onVisibilityChange={handleVisibilityChange}
                />
              </Col>
            ))}
          </Row>
        )}
        {accounts && accounts.length === 0 && (
          <p>{t('reconciliations.no-account')}</p>
        )}
      </ContentCustom>
      {transactions && invoices && !isLoading && (
        <>
          <Divider />
          <PageHeader title={t('reconciliations.transactions')} ghost={false} />
          <ContentCustom>
            <StyledTable
              as={Table}
              rowClassName="rowStyle"
              dataSource={transactions}
              loading={isLoading}
              columns={[
                ...Columns(t, accounts, invoices, expenses, postReconciliation)
              ]}
            />
          </ContentCustom>
        </>
      )}
    </>
  );
};

export default BankReconciliation;
