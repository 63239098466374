/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { FormButton } from '../../../utils/constants/customButton';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const PatchForm = ({ revenuId }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [revenu, setRevenu] = useState({});
  const customers = [];
  const cat = [];
  const [contacts, setContacts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/incomes/${revenuId}` });
    setRevenu(data);
  }, []);

  const fetchDataContacts = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: '/contacts' });
    setContacts(data.data);
  }, []);

  const fetchDataCategories = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: '/categories' });
    setCategories(data.data);
  }, []);

  const patchRevenu = async (data) => {
    await dispatchAPI('PATCH', {
      url: `/incomes/${revenuId}`,
      body: data
    });
  };

  const renderCustommers = () => {
    contacts.map((e) => {
      if (e.type === 'CUSTOMERS') customers.push(e);
      return e;
    });
  };

  const renderCategories = () => {
    categories.map((e) => {
      if (e.in_incomes === true) cat.push(e);
      return e;
    });
  };

  const onFinish = (e) => {
    patchRevenu(e);
    history.goBack();
  };

  useEffect(() => {
    fetchData();
    fetchDataContacts();
    fetchDataCategories();
  }, [fetchData, fetchDataContacts, fetchDataCategories]);

  return (
    <>
      {typeof customers !== 'undefined' && renderCustommers()}
      {typeof categories !== 'undefined' && renderCategories()}
      {typeof revenu.data !== 'undefined' && (
        <Form
          {...formItemLayout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            name="wording"
            label={t('incomes.form.wording')}
            initialValue={revenu.data.wording}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="id_customer"
            label={t('incomes.form.customer')}
            initialValue={revenu.data.id_customer}
            rules={[{ required: true }]}
          >
            <Select>
              {customers.map((e) => (
                <Option value={e._id}>
                  {`${e.last_name} ${e.first_name || ''}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="amount_ht"
            label={t('incomes.form.amount')}
            initialValue={revenu.data.amount_ht}
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="desc"
            label={t('incomes.form.desc')}
            initialValue={revenu.data.desc}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="date"
            label={t('incomes.form.date')}
            initialValue={moment(revenu.data.date)}
            rules={[{ required: true }]}
          >
            <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="category"
            label={t('incomes.form.category')}
            initialValue={revenu.data.category}
          >
            <Select>
              {categories &&
                categories.map((c) => <Option value={c._id}>{c.label}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="tva"
            label={t('incomes.form.tva')}
            initialValue={revenu.data.tva}
          >
            <Select allowClear>
              <Option value="normal"> taux normal : 20 %</Option>
              <Option value="intermediare">taux intermédiaire : 10 %</Option>
              <Option value="reduit">taux réduit : 5,5 %</Option>
              <Option value="particulier">taux particulier : 2,1 %</Option>
            </Select>
          </Form.Item>
          <FormButton cancelButton={history.goBack} />
        </Form>
      )}
    </>
  );
};

PatchForm.propTypes = {
  revenuId: PropTypes.string.isRequired
};

export default PatchForm;
