import React, { useState } from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListSupplies = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);

  const headers = [
    {
      label: 'label',
      key: 'label'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'my_company',
      key: 'my_company.name'
    },
    {
      label: 'email',
      key: 'email'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="supplies"
      withSubRoutes
      populate="catalogue"
      headers={headers}
      extraQuery={extraQuery}
      resourceModelName="Supplies"
    >
      {/* <Col><OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} /></Col> */}
    </SimpleResourceLandingLayout>
  );
};

export default ListSupplies;
