const useSiteListContent = (data = {}) => {
  const buy_site = data?.buy_site ?? {};
  const location_on_site = data?.location_on_site ?? '';
  const location = data?.location ?? '';
  return [
    {
      label: 'stocks.show.site.buy_site',
      content: buy_site?.label,
      span: 1
    },
    {
      label: 'stocks.show.site.location',
      content: location,
      span: 1
    },
    {
      label: 'stocks.show.site.location_on_site',
      content: location_on_site,
      span: 1
    }
  ];
};

export default useSiteListContent;
