/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Table,
  Tag,
  InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { profesionalsTypes } from '../../utils/constants/tagColors';

const EditableCell = ({
  matters,
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  record,
  index,
  children,
  ...restProps
}) => {
  // let inputNode = inputType === 'select' ? select : <Input />;

  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = <Input />;
      break;
    case 'select':
      inputNode = (
        <Select
          mode="multiple"
          placeholder="Sélectionnez les matières"
          style={{ width: '100%' }}
          options={(matters || [])?.map((el) => ({
            value: el._id,
            label: el.label
          }))}
        >
          {/* {(matters || [])?.map(el => <option  value={el._id}>{el.label}</option>)} */}
        </Select>
      );
      break;
    case 'number':
      inputNode = <InputNumber />;

      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  children: PropTypes.element.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  matters: PropTypes.arrayOf(PropTypes.object).isRequired,
  record: PropTypes.shape({}).isRequired,
  selectType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const PromotionsTable = ({
  promotion,
  editPromotion,
  matters,
  deletePromotion
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      matters: record.matters.map(({ _id }) => _id)
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    const row = await form.validateFields();
    editPromotion(record._id, row);
    setEditingKey('');
  };

  const columns = [
    {
      title: t('settings.promotion.label'),
      dataIndex: 'label',
      type: 'text',
      width: '40%',
      editable: true
    },
    {
      title: t('settings.promotion.matters'),
      dataIndex: 'matters',
      type: 'select',
      render: (renderedMatters, record) =>
        renderedMatters?.map((m) => (
          <Tag color={profesionalsTypes[m?.label]}>{m?.label}</Tag>
        )),
      editable: true,
      width: '40%'
    },
    {
      title: t('settings.promotion.amount'),
      dataIndex: 'amount',
      type: 'number',
      width: '40%',
      editable: true
    },
    {
      title: t('settings.promotion.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)}>
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {t('buttons.edit')}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deletePromotion(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {t('buttons.delete')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        matters
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={promotion}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

PromotionsTable.propTypes = {
  deletePromotion: PropTypes.func.isRequired,
  editPromotion: PropTypes.func.isRequired,
  matters: PropTypes.shape({
    label: PropTypes.any,
    map: PropTypes.func
  }).isRequired,
  promotion: PropTypes.shape({}).isRequired
};

export default PromotionsTable;
