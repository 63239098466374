import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSalesAccountingCodeColumns } from './Columns/SalesAccountingCodeColumns';
import { usePurchaseAccountingCodeColumns } from './Columns/PurchaseAccountingCodeColumns';
import { useCusertomerCodeColumns } from './Columns/CustomerCodeColumns';
import { Configuration } from './Configuration';
import KanbanSettings from './kanbanSettings';
import PermissionsSettings from './PermissionSettings';
import CommentariesSettings from './commentarySettings';
import PromotionsSettings from './promotionsSettings';
import CoefSettings from './coefSettings';
import TvaSettings from './tvaSettings';
import TrelloSettings from './trelloSettings';
import TrelloCardLabelsSettings from './trelloCardLabelsSettings';
import ProcessSettings from './processSettings';
import OperatorSettings from './operatorSettings';
import MatterfinitSettings from './matterfinitSettings';
import MachineSettings from './machineSettings';
import WorktaskSettings from './worktaskSettings';
import FinitionsSettings from './finitionsSettings';
import AccessorySettings from './accessorySettings';
import MaterialSettings from './materialSettings';
import SettlementConditionSettings from './settlementConditionSettings';

export const usePanels = () => {
  const { t } = useTranslation();
  const salesAccountingCodeColumns = useSalesAccountingCodeColumns();
  const purchaseAccountingCodeColumns = usePurchaseAccountingCodeColumns();
  const customerCodeColumns = useCusertomerCodeColumns();

  return [
    {
      title: t('settings-sales-accounting-codes.header'),
      permission: 'SETTINGS.ONLY_CODES',
      element: (
        <Configuration
          resourceName="settings-sales-accounting-codes"
          resourceColumns={salesAccountingCodeColumns}
        />
      )
    },
    {
      title: t('settings-purchase-accounting-codes.header'),
      permission: 'SETTINGS.ONLY_CODES',
      element: (
        <Configuration
          resourceName="settings-purchase-accounting-codes"
          resourceColumns={purchaseAccountingCodeColumns}
        />
      )
    },
    {
      title: t('settings-customers-codes.header'),
      permission: 'SETTINGS.ONLY_CODES',
      element: (
        <Configuration
          resourceName="settings-customers-codes"
          resourceColumns={customerCodeColumns}
        />
      )
    },
    {
      title: t('settings.tva.title'),
      permission: 'SETTINGS.TVA',
      element: <TvaSettings />
    },
    {
      title: t('settings.matterfinits.title'),
      permission: 'SETTINGS.MATTER_FINITS',
      element: <MatterfinitSettings />
    },
    {
      title: t('settings.Processs.title'),
      permission: 'SETTINGS.PROCESS',
      element: <ProcessSettings />
    },
    {
      title: t('settings.Worktasks.title'),
      permission: 'SETTINGS.WORKTASKS',
      element: <WorktaskSettings />
    },
    {
      title: t('settings.machines.title'),
      permission: 'SETTINGS.MACHINES',
      element: <MachineSettings />
    },
    {
      title: t('settings.coef.title'),
      permission: 'SETTINGS.COEF',
      element: <CoefSettings />
    },
    {
      title: t('settings.promotion.title'),
      permission: 'SETTINGS.PROMOTION',
      element: <PromotionsSettings />
    },
    {
      title: t('settings.commentary.title'),
      permission: 'SETTINGS.COMMENTARY',
      element: <CommentariesSettings />
    },
    {
      title: t('settings.kanbantable.title'),
      permission: 'SETTINGS.KANBAN_TABLE',
      element: <KanbanSettings />
    },
    {
      title: t('settings.permissions.title'),
      permission: 'SETTINGS.PERMISSIONS',
      element: <PermissionsSettings />
    },
    {
      title: t('settings.trello.title'),
      permission: 'SETTINGS.TRELLO',
      element: <TrelloSettings />
    },
    {
      title: t('settings.trello.cardLabel'),
      permission: 'SETTINGS.TRELLO_CARD_LABELS',
      element: <TrelloCardLabelsSettings />
    },
    {
      title: t('settings.operators.title'),
      permission: 'SETTINGS.OPERATORS',
      element: <OperatorSettings />
    },
    {
      title: t('settings.finitions.title'),
      permission: 'SETTINGS.FINITIONS',
      element: <FinitionsSettings />
    },
    {
      title: t('settings.Accessories.title'),
      permission: 'SETTINGS.ACCESSORIES',
      element: <AccessorySettings />
    },
    {
      title: t('settings.Materials.title'),
      permission: 'SETTINGS.MATERIALS',
      element: <MaterialSettings />
    },
    {
      title: t('settings.Settlement-condition.title'),
      permission: 'SETTINGS.SETTLEMENT_CONDITION',
      element: <SettlementConditionSettings />
    }
  ];
};
