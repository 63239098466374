import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useGenerateFormItem from '../../utils/GenerateFormItem';

const CreateUpdateSupplier = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();

  const advancedOptions = (
    <Row>
      <Col offset={8} span={24}>
        {advancedFields.map((field) => generateFormItem('labors', field))}
      </Col>
    </Row>
  );

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      populate="site"
      baseUrl="suppliers"
      resource="suppliers"
      formExtra={advancedOptions}
      withSubRoutes
    />
  );
};

CreateUpdateSupplier.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateSupplier;
