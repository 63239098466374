import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Divider, Row, Steps, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import DownloadGeneratedFile from './DownloadGeneratedFile';
import Customer from './Components/Customer';
import Profesionals from './Components/Profesionals';
import DescQuotForm from '../Quotations/descQuotForm';
import PaymentsTable from './PaymentsTable';
import InvoicesModal from './InvoicesModal';
import ImportFiles from '../../../components/importFiles';

const { Step } = Steps;
const Line = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 10px;
`;
const ExtraContainer = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 10px;
`;

const ShowOrder = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  // const { template, downloadFile } = useAccountingContext();
  const [isLoading, setLoading] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [toPayed, setToPayed] = useState(0);
  const [template, setTemplate] = useState();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=INVOICE`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (
    fileId,
    name,
    callback,
    _,
    outputType = 'pdf'
  ) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/invoice/${fileId}/${template._id}?outputType=${outputType}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=order,customer,profesional`
      });
      const quotation = await dispatchAPI('GET', {
        url: `/quotations/${data.quotation}?populate=customer,site,coef,quotlines.elements.element,`
      });
      setResource({ ...data, quotation: quotation.data });
      let payments = 0;
      if (data.payed) {
        const amounts = data.payed?.map(({ amount }) => amount);
        payments = amounts.length
          ? amounts.reduce((acc, value) => acc + value)
          : 0;
      }
      const rac =
        Number.parseFloat(data.order.prices.totalNet).toFixed(2) - payments;
      setToPayed(rac);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCurrentStep = (currentRessource) => {
    let step;
    switch (currentRessource.status) {
      case 'PENDING':
        step = 0;
        break;
      case 'PAYED':
        step = 1;
        break;
      default:
        break;
    }
    return step;
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    await getTemplate();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id, forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: displayedResource.number
        })}
        extra={
          <>
            <DownloadGeneratedFile
              element={displayedResource}
              template={template}
              downloadFile={downloadFile}
            />
            {!isLoading && displayedResource.status !== 'PAYED' && (
              <InvoicesModal
                setForceRefresh={setForceRefresh}
                forceRefresh={forceRefresh}
                toPayed={toPayed}
              />
            )}
          </>
        }
      />

      <ContentCustom>
        {resource === 'invoices' && (
          <Row>
            <Col span={6} offset={8}>
              <Steps current={getCurrentStep(displayedResource)}>
                <Step title={t('invoices.enums.PENDING')} />
                <Step title={t('invoices.enums.PAYED')} />
              </Steps>
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
          <Col span={6} style={{ paddingBottom: '10px' }}>
            {displayedResource.profesional && (
              <Profesionals
                display={displayedResource.profesional}
                isLoading={isLoading}
              />
            )}
          </Col>
          <Col span={6} offset={12} style={{ paddingBottom: '10px' }}>
            <Customer
              customer={displayedResource.customer}
              isLoading={isLoading}
            />
          </Col>
        </Row>

        {displayedResource.quotation?.quotlines.map((line) => (
          <DescQuotForm line={line} />
        ))}

        {displayedResource.quotation?.extra_fields?.length > 0 ? (
          <ExtraContainer>
            {displayedResource.quotation?.extra_fields?.map((extra) => (
              <Descriptions title={t('quotation.extra.title')} size="small">
                <Descriptions.Item label={t('quotation.extra.label')}>
                  {`${extra.label}`}
                </Descriptions.Item>
                <Descriptions.Item label={t('quotation.extra.comment')}>
                  {`${extra.comment}`}
                </Descriptions.Item>
                <Descriptions.Item label={t('quotation.extra.price')}>
                  {`${extra.price}`}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </ExtraContainer>
        ) : null}

        <Row style={{ marginTop: 50 }}>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.totalht')}
              <div style={{ float: 'right' }}>
                {`${numeral(
                  displayedResource.quotation?.prices.totalBrut
                ).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.discount')}
              <div style={{ float: 'right' }}>
                {`${numeral(
                  displayedResource.quotation?.prices.discount || 0
                ).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            <Line>
              {t('quotations.show.vat')}
              <div style={{ float: 'right' }}>
                {`${numeral(
                  displayedResource.quotation?.prices.amountTva
                ).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            {/* Here it's not that bad */}
            <Line>
              {t('quotations.show.totalttc')}
              <div style={{ float: 'right' }}>
                {`${numeral(
                  displayedResource.quotation?.prices.totalNet
                ).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={18}>
            {/* Here it's not that bad */}
            <Line>
              {t('quotations.show.amountDue')}
              <div style={{ float: 'right' }}>
                {`${numeral(toPayed).format('0,0.00')}€`}
              </div>
            </Line>
          </Col>
        </Row>

        <Divider />
        <PaymentsTable payments={displayedResource?.payed} />
      </ContentCustom>
      <ImportFiles
        id={id}
        files={displayedResource.files}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        resource="invoices"
      />
    </>
  );
};

ShowOrder.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ShowOrder;
