import React from 'react';
import { Typography } from 'antd';
import numeral from 'numeral';
import styled from 'styled-components';

const { Title } = Typography;

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 10px;
`;

const Line = styled.div`
  display: flex;
  justify-content: start;
`;

const Item = styled.div`
  margin-right: 20px;
`;

const DescQuotForm = ({ line, setIsModalVisible, isModalVisible, key }) => {
  const desc = (
    <Container>
      <Title level={4}>
        {`${line.family} - ${line.section ? line.section : ''}`}
      </Title>
      {line.elements.map(
        ({ element, price, thikness, surface, sizes, freelabel, comment }) => (
          <Line>
            {element && (
              <Item>{`${element.label} - ${element.color || ''}`}</Item>
            )}
            {thikness && (
              <Item>
                {`${
                  element.pricing.find(({ _id }) => _id === thikness).size
                } cm`}
              </Item>
            )}
            {surface && <Item>{`${surface} m2`}</Item>}
            {freelabel && <Item>{`${freelabel}`}</Item>}
            {comment && <Item>{`${comment}`}</Item>}
            {price && <Item>{`${numeral(price).format('0,0.00')} €/HT`}</Item>}
          </Line>
        )
      )}
    </Container>
  );

  return desc;
};

export default DescQuotForm;
