import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { categoryProcutions } from '../../../utils/constants/tagColors';

const useListContent = ({ ref, label, category, sold_price }) => {
  const { t } = useTranslation();

  return [
    {
      label: t('catalogues.column.ref'),
      content: ref,
      span: 3
    },
    {
      label: t('catalogues.column.label'),
      content: label,
      span: 3
    },
    {
      label: t('catalogues.column.category'),
      content: category && (
        <Tag color={categoryProcutions[category.toUpperCase()]}>
          {category.toUpperCase()}
        </Tag>
      ),
      span: 3
    },
    {
      label: t('catalogues.column.sold_price'),
      content: `${numeral(sold_price).format('0,0.00')} €`,
      span: 3
    }
  ];
};

export default useListContent;
