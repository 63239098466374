import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import MatterfinitTable from './matterfinitTable';

const MatterfinitSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [matterfinits, setMatterfinit] = useState([]);

  const fetchMatterfinits = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-matterfinits`
      });
      setMatterfinit(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addMatterfinits = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-matterfinits', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchMatterfinits();
  };

  const deleteMatterfinits = async (matterfinit) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-matterfinits/${matterfinit._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchMatterfinits();
  };

  const editMatterfinits = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-matterfinits/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchMatterfinits();
  };

  useEffect(() => {
    fetchMatterfinits();
  }, []);

  return (
    <>
      {matterfinits.length > 0 && (
        <MatterfinitTable
          matterfinits={matterfinits}
          editMatterfinits={editMatterfinits}
          deleteMatterfinits={deleteMatterfinits}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addMatterfinits}
      >
        {t('settings.matterfinits.addMatterfinits')}
      </Button>
    </>
  );
};

export default MatterfinitSettings;
