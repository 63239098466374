import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag, Switch } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import { mattersLabels } from '../../../utils/constants/tagColors';
import FormPrice from '../formPrice';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedsupplier, setSelectedSupplier] = useState('');
  const [coef, setCoef] = useState([]);
  const [choosenCoef, setChossenCoef] = useState(0);
  const [existentSupplier, setExistentSupplier] = useState(false);

  const handleCoef = (e) => {
    setChossenCoef(e);
  };

  const basicFields = [
    {
      name: ['ref'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {enums.map((el) => (
            <Option key={el} value={el}>
              <Tag color={mattersLabels[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['color'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      name: ['completions'],
      input: <Input />
    },
    {
      name: ['lostCost'],
      input: <Input type="number" />
    },
    {
      name: ['deliveryCost'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      label: ['existentSupplier'],
      input: <Switch onChange={(change) => setExistentSupplier(change)} />
    },
    !existentSupplier
      ? { name: ['nonExistentSupplier'], input: <Input /> }
      : {
          name: ['supplier'],
          input: (
            <Select
              onChange={(change) => {
                setSelectedSupplier(change);
              }}
              value={selectedsupplier}
            >
              {suppliers.map((el) => (
                <Option key={el._id} value={el._id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          )
        },
    {
      name: ['coef'],
      input: (
        <Select onChange={handleCoef} value={choosenCoef}>
          {coef &&
            coef.map((el) => (
              <Option key={el._id} value={el._id}>
                {el.coef}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['pricing'],
      input: <FormPrice />
    }
  ];

  const advancedFields = [];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/matters/enums' });
      setEnums(data.labels);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  const getCoefs = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/settings-coefs' });
      setCoef(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCoefs();
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
