import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const CreateUpdateArticle = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { dispatchAPI, user } = useAuthContext();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();

  const getResource = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `matters/${id}` });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  const comparePrincingArray = (arr1, arr2) => {
    let result = '';

    const modifiedObjects = arr2.filter((obj2) => {
      const obj1 = arr1.find((objArr1) => objArr1._id === obj2._id);

      if (!obj1) return false; // skip objects that don't exist in arr1

      return Object.keys(obj2).some((key) => obj2[key] !== obj1[key]);
    });
    result = modifiedObjects
      .map(
        (item) =>
          `${t('matters.timeline.edit')} ${t('matters.timeline.thickness')} ${
            item.size
          }cm ${t('matters.timeline.for')} ${item.buy_price.toFixed(2)}€. `
      )
      .join('');

    return result;
  };

  const updateMatter = async (values) => {
    try {
      const data = await getResource();
      await dispatchAPI('PATCH', {
        url: `matters/${id}`,
        body: values
      });

      const arr1 = data.pricing;
      const arr2 = values.pricing;

      const timeline = {
        status: t('matters.timeline.default'),
        date: moment(),
        by: user._id
      };

      if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
        timeline.status = comparePrincingArray(arr1, arr2);
      }

      await dispatchAPI('POST', {
        url: `/matters/one-timeline/${id}`,
        body: timeline
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="matters"
      resource="matters"
      loadingFields={isFieldsLoading}
      withSubRoutes
      customSubmit={
        purpose === 'edit'
          ? (values) => {
              updateMatter(values);
            }
          : null
      }
    />
  );
};

CreateUpdateArticle.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateArticle;
