import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Tabs, Tag, Space } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import Infos from './Infos';
import Sections from './Sections';
import Products from './Products';
import { Placed } from '../../Quotes/Details/Placed';
import Totals from './Totals';
import useButtons from './Buttons';
import MarbleBackground from '../../../assets/images/white-stone_Scott-Webb.jpg';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import WorkSheets from './Worksheet';
import LaySheets from './Laysheet';
import {
  enumsStatus,
  orderStatus,
  iconOrderStatus
} from '../../../utils/constants/tagColors';
import useOrderContext from '../OrderContext';
import Payments from './Payments';
import ImportFiles from '../../../components/importFiles';
// import RemovalOrders from './RemovalOrders';
import RemovalOrder from './RemovalOrder';
import DeliveryNotes from './DeliveryNotes';
import AcceptanceReports from './AcceptanceReport';
import Validation from '../Validation';
import Accessories from './Accessories';

const { TabPane } = Tabs;

const Details = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { type } = useOrderContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [validationVisisble, setValidationVisible] = useState(false);
  const forceRefresh = () => setRefresh(!refresh);

  const onInvoice = async (key) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/ordersnew/${key._id}`,
        body: { status: 'INVOICED' }
      });
      const newBody = { ...key, status: 'INVOICED' };
      await dispatchAPI('POST', {
        url: `/invoices`,
        body: newBody
      });
      forceRefresh();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onValidate = () => {
    setValidationVisible(true);
  };

  const extraButton = useButtons(datas, onInvoice, onValidate);

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew/${id}`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getData();
  }, [id, refresh]);

  const renderStatus = () => {
    if (type === 'invoices') {
      return (
        <Tag color={datas?.payed ? 'green' : 'red'}>
          {datas?.payed ? 'PAYÉ' : 'EN ATTENTE DE PAIEMENT'}
        </Tag>
      );
    }
    return (
      <Tag
        color={orderStatus[datas?.status]}
        icon={iconOrderStatus[datas?.status]}
      >
        {enumsStatus[datas?.status]}
      </Tag>
    );
  };

  const renderAdvancePayment = () => {
    const advancePayment = datas?.payments?.find(
      (payment) => payment.advance_payment === true
    );
    if (advancePayment) {
      return (
        <Tag color="green">
          <CheckCircleOutlined type="check" style={{ paddingRight: '8px' }} />
          {t('orders.show.advance_payment')}
        </Tag>
      );
    }
    return null;
  };

  return (
    <>
      <Validation
        isVisible={validationVisisble}
        setIsVisible={setValidationVisible}
        forceRefresh={forceRefresh}
      />
      <PageHeaderCustom
        title={
          <Space size="large">
            <div>
              {t(`${type}.show.number`, {
                number:
                  type === 'invoices' ? datas?.invoice_number : datas?.number
              })}
            </div>
            {renderStatus()}
            {renderAdvancePayment()}
          </Space>
        }
        extra={extraButton}
      />
      <ContentCustom>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('orders.show.tab.detail')} key="1">
            <div
              style={{
                backgroundImage: `url(${MarbleBackground})`,
                backgroundSize: 'cover',
                minHeight: 'calc(100vh - 200px)'
              }}
            >
              <Row
                justify="space-between"
                align="stretch"
                style={{
                  padding: '24px',
                  flexWrap: 'nowrap',
                  height: 'fit-content'
                }}
                gutter={24}
              >
                <Infos datas={datas?.infos} />
                <Totals datas={datas?.totals} />
              </Row>
              <Sections datas={datas} />
              <Row
                justify="space-between"
                align="stretch"
                style={{ padding: '0 24px 24px 24px', height: 'fit-content' }}
                gutter={24}
              >
                <Products datas={datas} />
                <Accessories datas={datas} />
                <Placed datas={datas?.placed} />
              </Row>
            </div>
          </TabPane>
          <TabPane tab={t('orders.show.tab.worksheet')} key="2">
            <WorkSheets order={datas} />
          </TabPane>
          <TabPane tab={t('orders.show.tab.laysheet')} key="3">
            <LaySheets order={datas} />
          </TabPane>
          <TabPane tab={t('orders.show.tab.files')} key="4">
            <ImportFiles
              id={datas._id}
              files={datas.files}
              forceRefresh={refresh}
              setForceRefresh={setRefresh}
              resource="ordersnew"
              download={false}
            />
          </TabPane>
          <TabPane tab={t('orders.show.tab.payments')} key="5">
            <Payments datas={datas} forceRefresh={forceRefresh} />
          </TabPane>
          {/* {datas.placed && datas.placed.without_lay ? (
            <TabPane tab={t('orders.show.tab.delivery')} key="6">
              <RemovalOrders order={datas} />
            </TabPane>
          ) : ( */}
          <TabPane tab={t('orders.show.tab.acceptance-report')} key="7">
            <AcceptanceReports order={datas} />
          </TabPane>
          {/* )} */}
          <TabPane tab={t('orders.show.tab.removal-orders')} key="8">
            <RemovalOrder order={datas} />
          </TabPane>
          <TabPane tab={t('orders.show.tab.delivery-notes')} key="9">
            <DeliveryNotes order={datas} />
          </TabPane>
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default Details;
