import PropTypes from 'prop-types';
import React from 'react';
import { Typography, Col, Card, Row } from 'antd';

const TotalConditions = ({ datas }) => {
  const { Title } = Typography;
  const formatPercentage = (value) => `${value} %`;

  if (datas) {
    return (
      <Col span={17} style={{ height: 'fit-content' }}>
        <Card
          style={{
            padding: 0,
            background: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          <div>
            <Title level={4}>Conditions tarifiaires</Title>
            <div style={{ width: '110px' }}>
              <hr
                style={{
                  backgroundColor: 'black',
                  height: '3px',
                  border: 'none',
                  marginBottom: '18px'
                }}
              />
            </div>
            <Row>
              <Col span={8}>
                <div>
                  type de facturation :&nbsp;
                  <strong>{datas?.billingType}</strong>
                </div>
                <div>
                  Taux de commissions:&nbsp;
                  <strong>
                    {datas?.comRate ? formatPercentage(datas?.comRate) : ''}
                  </strong>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    );
  }

  return <div> Totals loading ...</div>;
};

TotalConditions.propTypes = {
  datas: PropTypes.shape({
    comRate: PropTypes.number,
    billingType: PropTypes.string
  }).isRequired
};

export default TotalConditions;
