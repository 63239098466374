/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Spin, Upload, Space, Col, Card } from 'antd';
import {
  InboxOutlined,
  FileOutlined,
  LoadingOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from '../contexts/AuthContext';
import filesLogos from '../utils/constants/filesLogos';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  color: var(--textColor);
  &:hover {
    color: var(--primaryColor);
  }
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Dragger } = Upload;

const ImportFiles = ({
  id,
  files,
  forceRefresh,
  setForceRefresh,
  resource,
  disabled,
  download
}) => {
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState({});
  const [isDownloading, setIsDownloading] = useState({});

  const props = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/${resource}/files/${id}`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setForceRefresh(!forceRefresh);
      }
      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  const downloadFile = async (fileId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${fileId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      if (download) {
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.target = '_blank';
        a.click();
      } else {
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [fileId]: false });
  };

  const deleteFile = async (_id) => {
    setIsLoading({ ...isDownloading, [_id]: true });
    const body = {
      _id
    };
    try {
      await dispatchAPI('PATCH', { url: `${resource}/files/${id}`, body });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Card
      style={{
        padding: 0,
        background: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: disabled ? 'white' : 'rgba(219, 219, 219)'
      }}
    >
      <Row>
        <Space
          style={{
            marginTop: 20,
            width: '100%',
            flexWrap: 'nowrap',
            overflow: 'auto'
          }}
          size={50}
        >
          <Col style={{ display: 'flex', overflowX: 'auto' }}>
            {files &&
              files.map(({ _id, filename, metadata, type }) => (
                <div style={{ padding: 20 }}>
                  {canEditResource(path) && (
                    <button
                      type="button"
                      style={{
                        float: 'right',
                        color: 'red',
                        cursor: 'pointer'
                      }}
                      onClick={() => deleteFile(_id)}
                      onKeyDown={() => deleteFile(_id)}
                    >
                      {isLoading[_id] ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <DeleteOutlined />
                      )}
                    </button>
                  )}
                  <Content
                    onClick={() => {
                      downloadFile(_id, metadata?.originalName || filename);
                    }}
                  >
                    {filesLogos[type] || (
                      <FileOutlined style={{ fontSize: 40 }} />
                    )}
                    {metadata?.originalName.slice(0, 15) || filename}
                  </Content>
                </div>
              ))}
          </Col>
        </Space>
      </Row>
      {canEditResource(path) && (
        <Row style={{ marginTop: 20, height: 150 }}>
          <div style={{ width: '100%' }}>
            <Dragger {...props} disabled={disabled}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('dragndrop')}</p>
            </Dragger>
          </div>
        </Row>
      )}
    </Card>
  );
};

ImportFiles.propTypes = {
  callback: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  resource: PropTypes.shape({}).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  typeSelector: PropTypes.bool,
  disabled: PropTypes.bool,
  download: PropTypes.bool
};

ImportFiles.defaultProps = {
  typeSelector: false,
  callback: (history) => history.push('/documents/templates'),
  disabled: false,
  download: true
};

export default ImportFiles;
