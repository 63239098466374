import React from 'react';
import { useBarcode } from '@createnextapp/react-barcode';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

function Barcode(data, ide) {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  // FOR THE QR CODE
  const urlQrcode = `http://localhost:3000/suppliers_accounting/stocks/show/${data.ide}`;
  const refInternQrcode = `${data.data}`;

  const { inputRef } = useBarcode({
    value: `/suppliers_accounting/stocks/show/${data.data}`,
    options: {
      width: 1,
      height: 80,
      background: '#ffffff'
    }
  });

  const [value, setValue] = React.useState('');
  const wrapper_ref = React.useRef();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onClick = (e) => {
    const opt = {
      scale: 4
    };
    const elem = wrapper_ref.current;
    html2canvas(elem, opt).then((canvas) => {
      const iframe = document.createElement('iframe');
      iframe.name = 'printf';
      iframe.id = 'printf';
      iframe.height = 0;
      iframe.width = 0;
      document.body.appendChild(iframe);

      const imgUrl = canvas.toDataURL({
        format: 'jpeg',
        quality: '1.0'
      });

      const style = `
              height:30vh;
              width:50vw;
              position:absolute;
              left:0:
              top:0;
          `;

      const url = `<img style="${style}" src="${imgUrl}"/>`;
      const newWin = window.frames.printf;
      newWin.document.write(`<body onload="window.print()">${url}</body>`);
      newWin.document.close();
    });
  };
  const style = {
    display: 'flow-root'
  };
  return (
    <div className="App" style={style}>
      {/** This wrapper is important */}
      <button
        type="button"
        ref={wrapper_ref}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        {/* remove the hidden property if you want to use a barcode */}
        <img ref={inputRef} alt="barcode" hidden />
        <QRCode value={refInternQrcode} size="160" />
      </button>
    </div>
  );
}

export default Barcode;
