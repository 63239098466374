import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { ExportSheet } from 'react-xlsx-sheet';
import * as XLSX from 'xlsx';
import { DownloadOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

export const ExportDatas = ({ exportDatas }) => {
  const { t } = useTranslation();

  const ExportXLSXButton = ({ exportsheet }) => (
    <Button type="link" block icon={<DownloadOutlined />} onClick={exportsheet}>
      {t('buttons.action.export-xlsx')}
    </Button>
  );

  ExportXLSXButton.propTypes = {
    exportsheet: PropTypes.func
  };

  ExportXLSXButton.defaultProps = {
    exportsheet: () => {}
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <CSVLink
          style={{ color: 'inherit' }}
          filename="invoices"
          data={exportDatas}
        >
          <Button type="link" block icon={<DownloadOutlined />}>
            {t('buttons.action.export-csv')}
          </Button>
        </CSVLink>
      </Menu.Item>
      <Menu.Item>
        <ExportSheet
          dataType="Array-of-Arrays"
          fileName="invoices"
          dataSource={exportDatas}
          isDOMElement={false}
          xlsx={XLSX}
        >
          <ExportXLSXButton />
        </ExportSheet>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button
        type="link"
        icon={
          <MenuOutlined style={{ fontSize: 16, color: 'var(--textColor)' }} />
        }
      >
        {t('buttons.action.export-modal')}
      </Button>
    </Dropdown>
  );
};

ExportDatas.propTypes = {
  exportDatas: PropTypes.shape([]).isRequired
};
