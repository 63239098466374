import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Row } from 'antd';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Text } = Typography;

const PlanningContext = createContext({});

export const PlanningContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage;
  const { dispatchAPI } = useAuthContext();
  const dataSource = [];
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [dataSourceOrder, setDataSourceOrder] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [sites, setSites] = useState([]);
  const [workSheets, setWorkSheets] = useState([]);
  const [laySheets, setLaySheets] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  const getPlannings = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url:
          '/plannings?populate=order,workSheet,workSheet.production_sites,laySheet,laySheet.production_sites,order.infos.customer'
      });
      const subData = data.reduce((obj, element) => {
        const result = obj;
        if (
          element.order &&
          !element.order.payed &&
          element.order.status !== 'INVOICED'
        ) {
          const orderId = element.order._id;
          result[orderId] = result[orderId]
            ? [...result[orderId], element]
            : [element];
        }
        return result;
      }, {});
      Object.keys(subData).forEach((key) => {
        const listeChantiers = subData[key];
        const fullName = `${
          listeChantiers[0].order.infos.customer.last_name
        } ${listeChantiers[0].order.infos.customer.first_name || ''}`;
        const orderNumber = t('planning.order-header', {
          number: listeChantiers[0].number
        });

        dataSource.push({
          key: {
            key,
            customer: subData[key][0].order.infos.customer._id,
            sites: listeChantiers.map((chantier) => {
              return chantier.workSheet
                ? chantier.workSheet?.production_sites
                : chantier.laySheet?.production_sites;
            })
          },
          orderHeader: (
            <>
              <Row justify="center">
                <Text strong>{fullName}</Text>
              </Row>
              <Link
                to={{
                  pathname: `/customers_accounting/orders_sales/show/${key}`,
                  state: { key }
                }}
              >
                {orderNumber}
              </Link>
            </>
          ),
          listeChantiers
        });
      });
      setDataSources(dataSource);
      setDataSourceOrder(dataSource);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const deletePlanning = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/plannings/${id}`
      });
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const getWorkSheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?validated=true&populate=order,commercial_site,customer,production_sites`
      });

      const orderedWS = {};
      const options = [];

      await data.forEach((workSheet) => {
        const { order } = workSheet;
        if (order && !order.deleted) {
          if (orderedWS[order._id]) orderedWS[order._id].push(workSheet);
          else orderedWS[order._id] = [workSheet];
        }
      });

      Object.keys(orderedWS).forEach((el) => {
        orderedWS[el].forEach((ws, index) => {
          options.push({
            label: `${ws?.customer.first_name || ''} ${ws?.customer.last_name ||
              ''} - ${ws?.order.number}-FT${index + 1}`,
            value: ws._id,
            key: ws._id
          });
        });
      });

      setAllOptions(options);
      setWorkSheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLaySheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/LaySheets?isDefinitively=true&populate=order,customer'
      });
      const orderedLS = {};
      const options = [];

      await data.forEach((laySheet) => {
        const { order } = laySheet;
        if (order && !order.deleted) {
          if (orderedLS[order._id]) orderedLS[order._id].push(laySheet);
          else orderedLS[order._id] = [laySheet];
        }
      });

      Object.keys(orderedLS).forEach((el) => {
        orderedLS[el].forEach((ls, index) => {
          options.push({
            label: `${ls?.customer.first_name || ''} ${ls?.customer.last_name ||
              ''} - ${ls?.order.number}-FP${index + 1}`,
            value: ls._id,
            key: ls._id
          });
        });
      });

      setAllOptions(options);
      setLaySheets(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getPlannings();
    getSites();
  }, [refresh]);

  return (
    <PlanningContext.Provider
      value={{
        sites,
        refresh,
        setRefresh,
        isLoading,
        setIsLoading,
        dataSource,
        dataSourceOrder,
        setDataSourceOrder,
        dataSources,
        setDataSources,
        workSheets,
        setWorkSheets,
        getWorkSheets,
        laySheets,
        setLaySheets,
        getLaySheets,
        allOptions,
        setAllOptions,
        deletePlanning
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
};

PlanningContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(PlanningContext);
