import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import { routes, subRoutes } from '../../utils/constants/routes';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../utils/ErrorMessage';
import ImportFiles from '../../components/importFiles';
import TablesTab from './TablesTab';

const ShowBuildingSites = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const onDelete = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/building_sites/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBuildingSite = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/building-sites/${id}?populate=site,customer,profesional`
      });

      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getBuildingSite();
    })();
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={datas.label}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.BUILDING_SITES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={onDelete}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(datas)} translate />
        </Skeleton>
        <TablesTab datas={datas} />
        <ImportFiles
          id={id}
          files={datas.files}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          resource="building-sites"
        />
      </ContentCustom>
    </>
  );
};

export default ShowBuildingSites;
