import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './Fields';

const CreateUpdateExpense = ({ purpose }) => {
  const { fields, areFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && moment(data.date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      resource="expenses"
      fields={fields}
      loadingFields={areFieldsLoading}
      purpose={purpose}
      baseUrl="expenses"
      withSubRoutes
      config={config}
    />
  );
};

CreateUpdateExpense.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateExpense;
