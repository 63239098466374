import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Col, Collapse, Divider, Row, Spin, Tabs, Card } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import useAuthContext from '../../../../contexts/AuthContext';
import { AddActivityForm } from './AddActivityForm';
import { SelectDealStatus } from '../SelectDealStatus';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import ListDealActivity from './ListDealActivity';
import useErrorMessage from '../../../../utils/ErrorMessage';
import ContactsTable from '../ContactsTable';
import InformationRow from './InformationRow';
import ClientInformationRow from './ClientInformationRow';
import QuoteInformationRow from './QuoteInformationRow';
import EditDrawer from '../EditDrawer';
import ChangeQuotesStatusModal from './ChangeQuotesStatusModal';
import useDealContext, { DealsContextProvider } from '../DealsContext';
import FileCard from './FileCard';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ShowDealWithContext = () => {
  return (
    <DealsContextProvider>
      <ShowDeal />
    </DealsContextProvider>
  );
};

const ShowDeal = () => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [deal, setCurrentDeal] = useState({});
  const [formActivity, setFormActivity] = useState(false);
  const [, setListActivity] = useState(true);
  const [itemSelected, setItemSelected] = useState('activity');
  const [kanbanColumns, setKanbanCols] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const { setVisible, setDeal, kanbanCols, dataRefresh } = useDealContext();
  const { message } = useErrorMessage();
  const [showQuoteAcceptModal, setShowQuoteAcceptModal] = useState(false);
  const [showQuoteDenyModal, setShowQuoteDenyModal] = useState(false);
  const [showQuoteDropModal, setShowQuoteDropModal] = useState(false);

  const menuItems = [
    { name: t('deals.tabs.activities'), key: 'activity' },
    { name: t('deals.tabs.notes'), key: 'note' },
    { name: t('deals.tabs.calls'), key: 'calls' },
    { name: t('deals.tabs.meetings'), key: 'meeting' }
  ];
  const titleDivider = (
    <div style={{ width: '110px' }}>
      <hr
        style={{
          backgroundColor: 'black',
          height: '3px',
          border: 'none',
          marginBottom: '18px'
        }}
      />
    </div>
  );
  const getProjectTypes = () => {
    let projectTypes = '';
    if (deal.quotes) {
      deal.quotes.forEach((quote) => {
        if (quote.section_1) {
          projectTypes += `${quote.section_1.project_type} `;
        }
        if (quote.section_2) {
          projectTypes += `${quote.section_2.project_type} `;
        }
        if (quote.section_3) {
          projectTypes += `${quote.section_3.project_type} `;
        }
        if (quote.section_4) {
          projectTypes += `${quote.section_4.project_type} `;
        }
        if (quote.section_5) {
          projectTypes += `${quote.section_5.project_type} `;
        }
      });
    }
    return projectTypes;
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${id}?populate=lead,status,companies,activities.author,site,customers,professionals,prescribers,quotes.infos.commercial_site, quotes.quote.infos.production_site,files`
      });
      setCurrentDeal(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onChangeQuoteStatusModalCancel = () => {
    setShowQuoteAcceptModal(false);
    setShowQuoteDenyModal(false);
    setShowQuoteDropModal(false);
  };

  // function that takes a list of quote ids and change their status to the one given in parameters
  const changeQuoteStatus = async (quoteIds, status) => {
    try {
      // for each quote id, we change its status using patch on /quotes/:id
      for (let i = 0; i < quoteIds.length; i += 1) {
        await dispatchAPI('PATCH', {
          url: `/quotes/${quoteIds[i]}`,
          body: {
            status
          }
        });
      }

      onChangeQuoteStatusModalCancel();

      await fetchData();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onArchive = async () => {
    // get the id of the status which label is 'Archives'
    const status = kanbanCols.filter((sta) => sta.label === 'Archives')[0];

    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: { is_archived: true, status: status._id }
      });
      await fetchData();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const removeFile = async (file) => {
    const body = {
      ...deal,
      files: deal.files.filter((el) => el._id !== file._id)
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body
      });
      await fetchData();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getButtons = () => {
    const extraRowStyle = {
      padding: '5px'
    };
    if (!deal.status) return <></>;
    const acceptDenyQuoteButtons = (
      <Row>
        <Col style={extraRowStyle}>
          <ChangeQuotesStatusModal
            deal={deal}
            onOk={changeQuoteStatus}
            onCancel={onChangeQuoteStatusModalCancel}
            status="DROPPED"
            visible={showQuoteDropModal}
            quotes={deal.quotes}
            t={t}
          />
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            onClick={() => {
              setShowQuoteDropModal(true);
            }}
            disabled={deal.is_archived}
          >
            {t('deals.dropQuote')}
          </Button>
        </Col>
        <Col style={extraRowStyle}>
          <ChangeQuotesStatusModal
            deal={deal}
            onOk={changeQuoteStatus}
            onCancel={onChangeQuoteStatusModalCancel}
            status="REJECTED"
            visible={showQuoteDenyModal}
            quotes={deal.quotes}
            t={t}
          />
          <Button
            icon={<CloseOutlined />}
            type="primary"
            onClick={() => {
              setShowQuoteDenyModal(true);
            }}
            disabled={deal.is_archived}
          >
            {t('deals.denyQuote')}
          </Button>
        </Col>
        <Col style={extraRowStyle}>
          <ChangeQuotesStatusModal
            deal={deal}
            onOk={changeQuoteStatus}
            onCancel={onChangeQuoteStatusModalCancel}
            status="VALIDATE"
            visible={showQuoteAcceptModal}
            quotes={deal.quotes}
            t={t}
          />
          <Button
            icon={<CheckOutlined />}
            type="primary"
            onClick={() => {
              setShowQuoteAcceptModal(true);
            }}
            disabled={deal.is_archived}
          >
            {t('deals.acceptQuote')}
          </Button>
        </Col>
      </Row>
    );

    const addQuote = async (quoteId) => {
      try {
        await dispatchAPI('POST', {
          url: `/deals/${id}/quotes`,
          body: {
            quotes: [...deal.quotes.map((quote) => quote._id), quoteId]
          }
        });
        await fetchData();
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    };

    const addQuoteButton = (
      <Link
        to={{
          pathname: '/customers_accounting/quotes/create',
          state: {
            fromCRM: true,
            dealId: id
          }
        }}
      >
        <Row>
          <Col style={extraRowStyle}>
            <Button icon={<PlusOutlined />} type="primary">
              {t('deals.addQuote')}
            </Button>
          </Col>
        </Row>
      </Link>
    );

    const addQuoteStatuses = ['Prospect', 'Demande de devis effectuée'];

    const quoteButtonsStatuses = [
      'En attente de décision client',
      'Devis refusés'
    ];

    return (
      <Row>
        {quoteButtonsStatuses.includes(deal.status.label)
          ? acceptDenyQuoteButtons
          : null}
        {addQuoteStatuses.includes(deal.status.label) ? addQuoteButton : null}
        <Col style={extraRowStyle}>
          <Button
            type="primary"
            onClick={onArchive}
            disabled={deal.is_archived}
          >
            {t('deals.archive')}
          </Button>
        </Col>
        <Col style={extraRowStyle}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => {
              setDeal(deal._id);
              setVisible(true);
            }}
            disabled={deal.is_archived}
          >
            {t('deals.edit')}
          </Button>
        </Col>
      </Row>
    );
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchData();
    await getKanbans();
    await getEmployees();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions, dataRefresh]);

  const addActivity = async (values) => {
    const newActivityValues = values;

    // Adding unique ID for deal activity
    // Use for activity deletion
    newActivityValues._id = uuid();

    await dispatchAPI('PATCH', {
      url: `/deals/${id}`,
      body: {
        activities: [...deal.activities, values]
      }
    });
    setFormActivity(false);
    setListActivity(true);
    await fetchData();
  };

  const editStatus = async (value) => {
    const newStatusId = kanbanColumns[value];
    const body = {
      status: newStatusId._id,
      is_archived: newStatusId.label === 'Archives'
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    await fetchData();
  };

  const delActivity = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          activities: deal.activities.filter(
            (activity) => activity._id !== values._id
          )
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    await fetchData();
  };

  const getEmployees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setEmployees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const populate = 'site,profesional,customer,invoice,order';
  const pathQuotation = `/customers_accounting/quotes`;
  const pathBuildingSite = `/commercial/building_sites`;

  const actualStatus = deal.status;
  const goodStep = kanbanColumns.findIndex(
    (elem) => elem._id === actualStatus?._id
  );

  return (
    <>
      <PageHeaderCustom title={deal.name} withSubRoutes extra={getButtons()} />
      <EditDrawer visible={isVisible} dealId={deal._id} />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Row>
            <Col span={24}>
              {deal && (
                <SelectDealStatus
                  editStatus={editStatus}
                  dealStatusIndex={goodStep && goodStep}
                  kanbanCols={kanbanColumns}
                />
              )}
            </Col>
          </Row>
          <Divider />

          <InformationRow deal={deal} />

          <Row align="end" justify="space-evenly">
            <Col span={12} />
            <Col span={12}>
              <Row align="middle" justify="space-between">
                <Tabs
                  className="blacktab"
                  onChange={(key) => {
                    setItemSelected(key);
                  }}
                >
                  {menuItems.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))}
                </Tabs>
                <Button
                  type="link"
                  onClick={() => {
                    setListActivity(false);
                    setFormActivity(true);
                  }}
                  icon={<PlusOutlined />}
                >
                  {t('deals.show.add_activity').toUpperCase()}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ paddingRight: '16px' }}>
              <Collapse defaultActiveKey={['']} className="deal-collapse">
                <Panel key="0" header="Informations sur le(s) devis">
                  {deal.quotes &&
                    deal.quotes.map((quote) => (
                      <QuoteInformationRow
                        quote={{
                          id: quote.quote._id,
                          infos: quote.quote.infos,
                          number: quote.quote.number,
                          projectTypes: getProjectTypes(quote.quote),
                          totals: quote.quote.totals,
                          status: quote.quote.status
                        }}
                      />
                    ))}
                </Panel>
                <Panel key="1" header="Informations sur le prospect / client">
                  {deal.customers &&
                    deal.customers.map((customer) => (
                      <ClientInformationRow
                        client={{
                          status: 'Client',
                          name: `${customer.first_name || ''} ${
                            customer.last_name
                          }`,
                          address: `${customer.address.number || ''} ${customer
                            .address?.street || ''} ${
                            customer.address.postal_code
                          } ${customer.address.city}`,
                          phoneNumber: customer.phone_number_1,
                          email: customer.email
                        }}
                      />
                    ))}
                  {deal.professionals &&
                    deal.professionals.map((pro) => (
                      <ClientInformationRow
                        client={{
                          status: 'Professionnel',
                          name: pro.name,
                          address: `${pro.address.number || ''} ${
                            pro.address.street
                          } ${pro.address.postal_code} ${pro.address.city}`,
                          phoneNumber: pro.phone_number,
                          email: pro.email
                        }}
                      />
                    ))}
                  {deal.prescribers &&
                    deal.prescribers.map((prescriber) => (
                      <ClientInformationRow
                        client={{
                          status: 'Prescripteur',
                          name: `${prescriber?.first_name ||
                            prescriber?.name} ${prescriber?.last_name || ''}`,
                          address: `${prescriber.address.number} ${prescriber.address.street} ${prescriber.address.postal_code} ${prescriber.address.city}`,
                          phoneNumber: prescriber.phone_number,
                          email: prescriber.email
                        }}
                      />
                    ))}
                </Panel>
                <Panel key="2" header="Contacts">
                  <ContactsTable id={deal._id} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={12} style={{ border: '1px solid #000' }}>
              {formActivity && (
                <AddActivityForm
                  addActivity={addActivity}
                  setFormActivity={setFormActivity}
                  setListActivity={setListActivity}
                  employeesList={employees}
                />
              )}
              {!formActivity && (
                <ListDealActivity
                  deal={deal}
                  selectedTab={itemSelected}
                  delActivity={delActivity}
                />
              )}
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Card
                style={{
                  backgroundColor: 'rgba(245,245,245,1)',
                  minHeight: '100%'
                }}
                bordered={false}
              >
                {t('deals.show.files_title')}
                {titleDivider}
                {deal.files?.map((file) => (
                  <FileCard file={file} onDeleteFile={removeFile} />
                ))}
              </Card>
            </Col>
          </Row>
        </ContentCustom>
      </Spin>
    </>
  );
};

export default ShowDealWithContext;
