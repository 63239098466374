import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

const DartyPriceList = ({ pricing }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('catalogues.column.granit'),
      dataIndex: 'granit',
      key: 'granit'
    },
    {
      title: t('catalogues.column.silestone'),
      dataIndex: 'silestone',
      key: 'silestone'
    },
    {
      title: t('catalogues.column.dekton'),
      dataIndex: 'dekton',
      key: 'dekton'
    }
  ];
  return (
    <div style={{ width: '50%' }}>
      {pricing !== null && pricing.length > 0 && (
        <Table
          columns={columns}
          dataSource={pricing}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
};

DartyPriceList.propTypes = {
  pricing: PropTypes.string
};

DartyPriceList.defaultProps = {
  pricing: null
};

export default DartyPriceList;
