import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const CreateUpdateProduction = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();
  const { t } = useTranslation();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="productions"
      resource="productions"
      loadingFields={isFieldsLoading}
      withSubRoutes
    />
  );
};

CreateUpdateProduction.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateProduction;
