import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useGenerateFormItem from '../../utils/GenerateFormItem';

const CreateUpdatePrescriber = ({
  purpose,
  customSubmit,
  customCancel,
  isModal
}) => {
  const generateFormItem = useGenerateFormItem();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();
  const advancedOptions = (
    <Row>
      <Col offset={8} span={24}>
        {advancedFields.map((field) => generateFormItem('labors', field))}
      </Col>
    </Row>
  );
  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="prescribers"
      resource="prescribers"
      formExtra={advancedOptions}
      withSubRoutes
      customSubmit={customSubmit}
      customCancel={customCancel}
      isModal={isModal}
    />
  );
};

CreateUpdatePrescriber.propTypes = {
  purpose: PropTypes.string.isRequired,
  customSubmit: PropTypes.func,
  customCancel: PropTypes.func,
  isModal: PropTypes.bool
};

CreateUpdatePrescriber.defaultProps = {
  customSubmit: null,
  customCancel: null,
  isModal: false
};

export default CreateUpdatePrescriber;
