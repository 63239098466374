import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [catalogue, setCatalogue] = useState([]);

  const fields = [
    {
      name: ['label'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['brand']
    },
    {
      name: ['supplier'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['ref_internal'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['ref_supplier'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['catalogue'],
      input: (
        <Select loading={isFieldsLoading}>
          {catalogue.map(({ label, _id }) => (
            <Option key={label} value={_id}>
              {label.toUpperCase()}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['quantity'],
      input: <InputNumber />
    },
    {
      name: ['quantity_min'],
      input: <InputNumber />
    },
    {
      name: ['buying_price'],
      input: <InputNumber />
    },
    {
      name: ['selling_price'],
      input: <InputNumber />
    },
    {
      name: ['description'],
      input: <TextArea />
    }
  ];

  const getSupplies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/products' });
      setCatalogue(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getSupplies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
