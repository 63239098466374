import React, { useEffect, useState } from 'react';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import KanbanColumn from './KanbanColumn';
import EditDrawer from './EditDrawer';
import useBoxContext from './DealsContext';
import MarbleBackground from '../../../assets/images/marble-background-trello.jpg';
import ArchivedColumn from './ArchiveColumn';

const { Search } = Input;
const { Option } = Select;

const KanbanBoard = () => {
  const { t } = useTranslation();
  const {
    deal,
    searchResource,
    searchValue,
    visible,
    setVisible,
    fetchKanbanColumns,
    kanbanCols,
    fetchData,
    handleFilter,
    sites,
    customers,
    projectTypes
  } = useBoxContext();
  const [showArchive, setShowArchive] = useState(false);

  const [filters, setFilters] = useState({});

  const sortedKanbanCols = kanbanCols.sort((a, b) => {
    return b.index - a.index;
  });

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    handleFilter(filters);
  }, [filters]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchKanbanColumns();
    })();
  }, []);

  const extraRowStyle = {
    padding: '5px'
  };

  // function that returns an option row that contains a filter on the customer, on the site, and on the project type
  const getOptions = () => {
    return (
      <Row>
        <Col style={extraRowStyle}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder={t('crm.filter.status')}
            optionFilterProp="children"
            onChange={(value) => {
              setFilters({ ...filters, customers: value });
            }}
          >
            {customers.map((customer) => (
              <Option key={customer._id} value={customer._id}>
                {customer.last_name + ' ' + customer.first_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col style={extraRowStyle}>
          <Select
            allowClear
            showSearch
            style={{ width: '200px' }}
            placeholder={t('crm.filter.site')}
            optionFilterProp="children"
            onChange={(value) => {
              setFilters({ ...filters, site: value });
            }}
          >
            {sites.map((site) => (
              <Option key={site._id} value={site._id}>
                {site.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col style={extraRowStyle}>
          <Select
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder={t('crm.filter.project')}
            optionFilterProp="children"
            onChange={(value) => {
              setFilters({ ...filters, projectType: value });
            }}
          >
            {projectTypes &&
              projectTypes.map((projectType) => (
                <Option key={projectType} value={projectType}>
                  {projectType}
                </Option>
              ))}
          </Select>
        </Col>
        <Col style={extraRowStyle}>
          <Button
            type="primary"
            icon={showArchive ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => {
              setShowArchive(!showArchive);
            }}
          >
            {t('buttons.showArchive')}
          </Button>
        </Col>

        <Col style={extraRowStyle}>
          <Button
            type="primary"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
          >
            {t('buttons.create')}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Col>{getOptions()}</Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          backgroundImage: `url(${MarbleBackground})`,
          backgroundSize: 'cover',
          height: '100%',
          marginTop: 20
        }}
      >
        <div
          style={{
            display: 'flex',
            overflowX: 'scroll'
          }}
        >
          {sortedKanbanCols
            .filter((col) => col.show)
            .map((col) => (
              <KanbanColumn column={col} key={col.label} />
            ))}
        </div>

        <div>{showArchive && <ArchivedColumn />}</div>
      </div>

      <EditDrawer visible={visible} dealId={deal} close={onClose} />
    </>
  );
};

export default KanbanBoard;
