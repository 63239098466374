import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Descriptions, Typography, PageHeader } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import { DetailsButton } from '../../../utils/constants/customButton';

const Container = styled.div`
  padding: 24px;
  background-color: white;
  margin-bottom: 0.05%;
`;

const { Text } = Typography;

const Details = ({ match: { params: id } }) => {
  const { dispatchAPI } = useAuthContext();
  const revenuId = id;
  const [revenu, setRevenu] = useState({});
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/incomes',
      breadcrumbName: t('basiclayout.menu.accounting')
    },
    {
      path: '/incomes',
      breadcrumbName: t('incomes.breadcrumb.income')
    },
    {
      breadcrumbName: t('incomes.breadcrumb.show')
    }
  ];

  const renderCustomers = (value) => {
    let affiche = null;
    customers.map((e) => {
      if (e._id === value) {
        affiche = `${e.last_name} ${e.first_name || ''}`;
      }
      return e;
    });
    return affiche;
  };

  const renderCategories = (value) => {
    let affiche = null;
    categories.map((e) => {
      if (e._id === value) {
        affiche = `${e.accounting_code} - ${e.label}`;
      }
      return e;
    });
    return affiche;
  };

  const fetchDataContacts = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/contacts` });
    setCustomers(data.data);
  }, []);

  const fetchDataCategories = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: '/categories' });
    setCategories(data.data);
  }, []);

  const fetchData = useCallback(async () => {
    const data = await dispatchAPI('GET', { url: `/incomes/${revenuId}` });
    setRevenu(data);
  }, []);

  const deleteRessource = (deletedRessourceId) => {
    dispatchAPI('DELETE', { url: `/incomes/${deletedRessourceId}` });
    history.goBack();
  };

  useEffect(() => {
    fetchData();
    fetchDataContacts();
    fetchDataCategories();
  }, [fetchData, fetchDataContacts, fetchDataCategories]);
  return (
    <>
      {typeof revenu.data !== 'undefined' && (
        <PageHeader
          className="site-page-header"
          title={`${t('incomes.details.title')} ${revenu.data.wording}`}
          style={{ backgroundColor: 'white', marginBottom: 1 }}
          breadcrumb={{
            routes,
            // eslint-disable-next-line react/prop-types
            itemRender: ({ path, breadcrumbName }) => (
              <Link to={path}>{breadcrumbName}</Link>
            )
          }}
          onBack={history.goBack}
          extra={[
            <DetailsButton
              path="/incomes/edit/"
              id={revenuId}
              deleteRessource={deleteRessource}
            />
          ]}
        />
      )}
      <Container>
        {typeof revenu.data !== 'undefined' && (
          <Descriptions style={{ paddingLeft: 25 }}>
            <Descriptions.Item
              label={t('incomes.form.wording')}
              style={{ display: 'block' }}
            >
              <Text strong> {revenu.data.wording} </Text>
            </Descriptions.Item>
            <DescriptionsItem
              label={t('incomes.form.customer')}
              style={{ display: 'block' }}
            >
              <Text strong>{renderCustomers(revenu.data.id_customer)}</Text>
            </DescriptionsItem>
            <Descriptions.Item
              label={t('incomes.form.date')}
              style={{ display: 'block' }}
            >
              <Text strong>
                {new Date(revenu.data.date).toLocaleDateString('fr-FR')}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={t('incomes.form.amount')}
              style={{ display: 'block' }}
            >
              <Text strong>{`${revenu.data.amount_ht} €`}</Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={t('incomes.form.desc')}
              style={{ display: 'block' }}
            >
              <Text strong>{revenu.data.desc}</Text>
            </Descriptions.Item>
            <DescriptionsItem
              label={t('incomes.details.category')}
              style={{ display: 'block' }}
            >
              <Text strong>{renderCategories(revenu.data.category)}</Text>
            </DescriptionsItem>
            <DescriptionsItem
              label={t('incomes.form.tva')}
              style={{ display: 'block' }}
            >
              <Text strong>{revenu.data.tva}</Text>
            </DescriptionsItem>
            <DescriptionsItem
              label={t('incomes.details.reconcilation')}
              style={{ display: 'block' }}
            >
              <Text strong>{revenu.data.id_payment}</Text>
            </DescriptionsItem>
          </Descriptions>
        )}
      </Container>
    </>
  );
};

Details.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.any }) })
    .isRequired
};

export default Details;
