import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ContactsDrawer from './ContactsDrawer';

const ContactsTable = (id) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const { message } = useErrorMessage();
  const columns = [
    {
      title: 'Nom',
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Téléphone',
      key: 'phone_number_1',
      dataIndex: 'phone_number_1',
      sorter: true
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: true
    }
  ];
  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts`
      });
      const filteredContacts = data.filter((contact) => contact.deal === id.id);
      setContacts(filteredContacts);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchData();
    setIsLoading(false);
  }, []);
  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);
  return (
    <>
      <ContactsDrawer id={id.id} />
      <Table
        dataSource={contacts.map((contact) => ({
          gender: contact.gender,
          name: `${contact.first_name} ${contact.last_name}`,
          last_name: contact.last_name,
          first_name: contact.first_name,
          email: contact.email,
          phone_number_1: contact.phone_number_1
        }))}
        columns={columns}
        pagination={false}
      />
    </>
  );
};
export default ContactsTable;
