/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/charts';

const PieQuotationTypeProject = ({ quotes }) => {
  const [datas, setDatas] = useState([]);
  const statistics = [];
  const AllprojectTypes = [];
  useEffect(() => {
    if (quotes.length) {
      quotes.forEach((quote) => {
        if (quote.section_1 && quote.section_1.project_type) {
          AllprojectTypes.push(quote.section_1.project_type);
        }
        if (quote.section_2 && quote.section_2.project_type) {
          AllprojectTypes.push(quote.section_2.project_type);
        }
        if (quote.section_3 && quote.section_3.project_type) {
          AllprojectTypes.push(quote.section_3.project_type);
        }
        if (quote.section_4 && quote.section_4.project_type) {
          AllprojectTypes.push(quote.section_4.project_type);
        }
        if (quote.section_5 && quote.section_5.project_type) {
          AllprojectTypes.push(quote.section_5.project_type);
        }
      });
      AllprojectTypes.sort();
      AllprojectTypes.map((el) => {
        if (statistics[el]) {
          statistics[el] += 1;
        } else {
          statistics[el] = 1;
        }
      });
      let total = 0;
      Object.keys(statistics).map((key) => {
        total += statistics[key];
      });
      if (total) {
        const arrayData = Object.keys(statistics).map((key) => {
          return {
            type: key,
            value: parseInt((statistics[key] / total) * 100, 0)
          };
        });
        const elements = [];
        arrayData.map((el) => {
          elements.push({ type: el.type, value: el.value });
        });
        setDatas([...elements]);
      }
    } else {
      setDatas([]);
    }
  }, [quotes]);

  const config = {
    width: 280,
    height: 280,
    appendPadding: 10,
    data: datas,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.75,
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      content: '{value}%',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [{ type: 'element-selected' }],
    statistic: {
      title: false,
      content: false
    }
  };
  return <Pie {...config} />;
};

PieQuotationTypeProject.propTypes = {
  quotes: PropTypes.shape().isRequired
};

export default PieQuotationTypeProject;
