import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { formatData } from './utils';

const ExportButton = ({
  fileName,
  dataName,
  url,
  headers,
  formatter,
  populate,
  extraQuery
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const needsFetch = useRef(true);
  const csvLinkRef = useRef();

  let allWorkSheets;

  const getWorksheets = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?populate=matter,customer,order`
      });
      allWorkSheets = data;
    } catch (e) {
      if (e) message(e);
    }
  };
  useEffect(() => {
    getWorksheets();
  }, []);

  const fetchData = useCallback(async () => {
    if (needsFetch.current) {
      setVisible(true);
      try {
        const fetchUrl = `${url}?${extraQuery ? `${extraQuery}&` : ''}${
          populate ? `populate=${populate}` : ''
        }`;

        const { data } = await dispatchAPI('GET', {
          url: fetchUrl
        });

        let newData;

        if (formatter) {
          newData = formatter(data);
        } else if (allWorkSheets) {
          newData = formatData(data, allWorkSheets);
        } else {
          newData = data;
        }

        if (data.length) setDataCSV(newData);
        else setDataCSV([{}]);
      } catch (e) {
        message(e);
      } finally {
        setVisible(false);
        needsFetch.current = false; // Mark that data has been fetched
        // Trigger the CSV download after the data is set
        setTimeout(() => {
          csvLinkRef.current.link.click();
        }, 0);
      }
    }
  }, [url, formatter, dataName, headers, populate]);

  useEffect(() => {
    setDataCSV([]);
    needsFetch.current = true; // Reset fetch flag when dependencies change
  }, [dataName, url, headers]);

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          Nous préparons votre fichier. Merci de patienter.
        </Typography.Text>
      </Modal>
      <CSVLink
        ref={csvLinkRef}
        style={{ color: 'inherit' }}
        asyncOnClick
        onClick={async (e, done) => {
          if (needsFetch.current) {
            e.preventDefault();
            await fetchData();
            done(false);
          } else {
            done();
          }
        }}
        filename={fileName}
        data={dataCSV}
        headers={(headers || []).map(({ label, ...header }) => ({
          label: t(`${label}`),
          ...header
        }))}
      >
        <DownloadOutlined style={{ fontSize: '14px' }} />
        {` ${t(`buttons.export`)}`}
      </CSVLink>
    </>
  );
};

ExportButton.propTypes = {
  dataName: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  populate: PropTypes.string,
  url: PropTypes.string.isRequired
};

ExportButton.defaultProps = {
  formatter: null,
  extraQuery: null,
  populate: null
};

export default ExportButton;
