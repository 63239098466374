/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Typography
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';

const { Title } = Typography;

const NewCompanyDrawer = ({
  isVisible,
  setVisibilityCompanyDrawer,
  getCompanies
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldLoading, setIsFieldLoading] = useState(true);

  const cancelButton = () => {
    setVisibilityCompanyDrawer();
  };

  const onFinish = async (values) => {
    const newCompanyValues = values;
    try {
      // Add the type to the JSON body (companies or suppliers)
      // values.type = type ? type : 'customers';
      newCompanyValues.type = 'CUSTOMERS';

      await dispatchAPI('POST', {
        url: '/companies',
        body: values
      });
      message.success(t('companies.message.success.new'));
      await getCompanies();
      setVisibilityCompanyDrawer();
    } catch (e) {
      message.error(t('companies.message.error.'));
    }
  };
  const onFinishFailed = (error) => {
    message.error(t('companies.message.error'));
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldLoading(true);
      await getEnums();
      setIsFieldLoading(false);
    })();
  }, []);

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityCompanyDrawer();
      }}
      width="30%"
    >
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`companies.form.title.create`)}</Title>
      </Col>
      <Form
        {...formItemLayout}
        name="test"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%' }}
      >
        <Form.Item
          label={t('companies.form.name')}
          name="name"
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Veuillez remplir ce champ'
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('companies.form.legal_form')}
          name="legal_form"
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Veuillez remplir ce champ'
            }
          ]}
        >
          <Select loading={isFieldLoading} style={{ width: '100%' }}>
            {(enums.legalForms || []).map((form) => (
              <Select.Option key={form} value={form}>
                {t(`companies.legalForms.${form}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('companies.form.type')}>
          <Select loading={isFieldLoading}>
            {(enums.types || []).map((type) => (
              <Select.Option key={type} value={type}>
                {t(`companies.tags.${type}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('companies.form.group')} name="group">
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('companies.form.channel_number')}
          name="channel_number"
          rules={[
            {
              required: true,
              message: 'Veuillez remplir ce champ'
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('companies.form.address_details')}
          name="address_details"
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('companies.form.postal_code')}
          name="postal_code"
          rules={[
            {
              required: true,
              message: 'Veuillez remplir ce champ'
            }
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={t('companies.form.city')} name="city">
          <Input style={{ width: '100%' }} type="text" />
        </Form.Item>
        <Form.Item
          label={t('companies.form.siren')}
          name="siren_number"
          rules={[
            {
              required: true,
              message: 'Veuillez remplir ce champ'
            }
          ]}
        >
          <InputNumber style={{ width: '100%' }} minLength={9} maxLength={9} />
        </Form.Item>
        <Form.Item label={t('companies.form.ape')} name="ape_number">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={t('companies.form.share_capital')}
          name="share_capital"
        >
          <InputNumber style={{ width: '100%' }} type="number" min={0} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            background: 'rgb(71, 210, 103)',
            color: '#fff',
            border: 'rgb(71, 210, 103)',
            borderRadius: 3,
            marginLeft: 10,
            float: 'right'
          }}
        >
          {t('buttons.save')} <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          style={{
            background: '#fff',
            color: 'red',
            borderColor: 'red',
            borderRadius: 3,
            float: 'right'
          }}
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

NewCompanyDrawer.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setVisibilityCompanyDrawer: PropTypes.func.isRequired
};

export default NewCompanyDrawer;
