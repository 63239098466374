import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ContextProvider } from './context';
import { ConfiguratorContextProvider } from './Configurator/ConfiguratorContext';
import QuotesList from './List/index';
import Configurator from './Configurator';
import QuoteDetails from './Details/index';

const Router = () => {
  const { path } = useRouteMatch();

  return (
    <ContextProvider collection="quotes">
      <ConfiguratorContextProvider>
        <Switch>
          <Route path={`${path}/create`} component={Configurator} />
          <Route path={`${path}/edit/:id`} component={Configurator} />
          <Route path={`${path}/show/:id`} component={QuoteDetails} />
          <Route path={path} component={QuotesList} />
        </Switch>
      </ConfiguratorContextProvider>
    </ContextProvider>
  );
};

export default Router;
