import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const OrderContext = createContext({});

export const OrderContextProvider = ({ children }) => {
  const [range, setRange] = useState([]);

  return (
    <OrderContext.Provider value={{ range, setRange }}>
      {children}
    </OrderContext.Provider>
  );
};

OrderContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(OrderContext);
