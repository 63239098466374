import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const OptionsRow = ({ setFilter }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { Option } = Select;

  const [isLoading, setIsLoading] = useState(false);
  const [quotesOptions, setQuotesOptions] = useState([]);
  const [profesionals, setProfesionals] = useState([]);

  const getQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotes/enums'
      });

      setQuotesOptions(data);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPro = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/profesionals'
      });

      const newProfesionals = data.map((pro) => pro.name);

      setProfesionals(Array.from(new Set(newProfesionals)));
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getQuotes();
    getPro();
  }, []);

  return (
    <>
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 200 }}
        placeholder="Statut"
        onChange={(value) => setFilter('status', value)}
        isLoading={isLoading}
        showSearch
      >
        {quotesOptions?.status?.map((quote) => (
          <Option value={quote} key={quote}>
            {t(`commission.${quote}`)}
          </Option>
        ))}
      </Select>
      <Select
        allowClear
        style={{ margin: '3px 6px 3px 0', width: 200 }}
        placeholder="Professionnels"
        onChange={(value) => setFilter('infos', value)}
        options={profesionals.map((pro) => ({
          key: pro,
          value: pro
        }))}
        isLoading={isLoading}
        showSearch
      />
    </>
  );
};

OptionsRow.propTypes = {
  setFilter: PropTypes.func.isRequired
};

export default OptionsRow;
