/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Col,
  InputNumber,
  Form,
  Row,
  Select,
  Space,
  message
} from 'antd';
import styled from 'styled-components';
import {
  MinusOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { enumsCaterogy } from '../../../../utils/constants/tagColors';

const { TextArea } = Input;

const Section = styled.div`
  background-color: #fff;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

const SectionForm = ({
  totalPrice,
  form,
  categories,
  parentKey,
  comments,
  t,
  dispatchAPI,
  initialElements,
  name,
  coefs
}) => {
  const [type, setType] = useState(null);
  const [elements, setElements] = useState(initialElements);
  const [elementId, setElementId] = useState(null);
  const [element, setElement] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [saveElements, setSaveElements] = useState(initialElements);

  const fetchElements = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${type}?populate=coef`
      });
      const datas = data.filter(({ target }) => target !== 'DARTY');
      setElements(datas);
      setSaveElements([...saveElements, ...data]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (type && type !== 'others') fetchElements();
  }, [type]);

  useEffect(() => {
    if (elementId) setElement(elements.find(({ _id }) => _id === elementId));
  }, [elementId]);

  const calcSurface = (key) => {
    const values = form.getFieldsValue();
    const quotline = values.quotlines[parentKey];
    const elementQuot = quotline.elements[key];
    const surfaces = elementQuot.sizes.map(
      ({ width, height }) => ((width / 1000) * height) / 1000
    );
    const surface = surfaces.reduce((acc, value) => acc + value);
    const quotlines = form.getFieldValue('quotlines');
    quotlines[parentKey].elements[key].surface = surface;
    form.setFieldsValue({
      quotlines
    });
    calcPrice(surface, key);
  };

  const calcPrice = (value, key) => {
    const values = form.getFieldsValue();
    const quotline = values.quotlines[parentKey];
    const elemId = quotline.elements[key].element;
    let thikId = quotline.elements[key].thikness;
    if (thikId === '2') {
      const el = initialElements.find(({ _id }) => _id === elemId);
      const pricing = el.pricing.find(({ size }) => size === '2');
      thikId = pricing._id;
    } else if (thikId === '3') {
      const el = initialElements.find(({ _id }) => _id === elemId);
      const pricing = el.pricing.find(({ size }) => size === '3');
      thikId = pricing._id;
    }
    const elem = saveElements.find(({ _id }) => _id === elemId);
    if (elem) {
      const coef =
        elem.coef.coef || coefs.find(({ _id }) => _id === elem.coef).coef;
      const basePrice = elem.pricing.find(({ _id }) => _id === thikId);
      const price = Number(basePrice.buy_price)
        ? Number(basePrice.buy_price) * Number(coef) * Number(value)
        : 0;
      const quotlines = form.getFieldValue('quotlines');
      quotlines[parentKey].elements[key].price = price;
      form.setFieldsValue({
        quotlines
      });
      totalPrice();
    }
  };

  const onSelectElement = (value, key) => {
    setElementId(value);
    const el = saveElements.find(({ _id }) => _id === value);
    if (el.sold_price) {
      const quotlines = form.getFieldValue('quotlines');
      quotlines[parentKey].elements[key].price = el.sold_price;
      form.setFieldsValue({
        quotlines
      });
      totalPrice();
    }
  };

  const onSelectType = (value, key) => {
    if (value !== 'matters') {
      setIsHidden({ [key]: true });
    } else {
      setIsHidden(false);
    }
    setType(value);
    const quotlines = form.getFieldValue('quotlines');
    const models = value.charAt(0).toUpperCase() + value.slice(1);
    const model = models.slice(0, -1);
    quotlines[parentKey].elements[key].refpath = model;
    form.setFieldsValue({
      quotlines
    });
  };

  const selectComment = (value, key) => {
    const quotlines = form.getFieldValue('quotlines');
    quotlines[parentKey].elements[key].comment = value;
    form.setFieldsValue({
      quotlines
    });
  };

  return (
    <Form.List name={[name, 'elements']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, fieldName, fieldKey }) => (
            <Section key={key}>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    name={[fieldName, 'refpath']}
                    fieldKey={[fieldKey, 'refpath']}
                    hidden
                  />
                  <Form.Item
                    name={[fieldName, 'type']}
                    fieldKey={[fieldKey, 'type']}
                  >
                    <Select
                      allowClear
                      options={categories.map((el) => ({
                        label: enumsCaterogy[el],
                        value: el.toLowerCase()
                      }))}
                      style={{ width: '300%', marginTop: 20 }}
                      placeholder="Choix du catalogue"
                      onSelect={(value) => onSelectType(value, fieldKey)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    name={[fieldName, 'element']}
                    fieldKey={[fieldKey, 'element']}
                  >
                    <Select
                      allowClear
                      showSearch
                      options={elements.map(({ label, color, _id }) => ({
                        label: `${label} ${color || ''}`,
                        value: _id
                      }))}
                      style={{ width: '300%' }}
                      optionFilterProp="children"
                      placeholder="Chosir un élément du catalogue"
                      onSelect={(value) => onSelectElement(value, fieldKey)}
                      filterOption={(input, option) =>
                        option.label.includes(input)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    name={[fieldName, 'freelabel']}
                    fieldKey={[fieldKey, 'freelabel']}
                  >
                    <Input
                      placeholder="Saisir un élément hors catalogue"
                      style={{ width: '300%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    visibility={isHidden[key] === true ? 'hidden' : false}
                    hidden={isHidden[key] === true}
                    name={[fieldName, 'thikness']}
                    fieldKey={[fieldKey, 'thikness']}
                  >
                    <Select
                      options={element?.pricing?.map(({ size, _id }) => ({
                        label: `${size} cm`,
                        value: _id
                      }))}
                      placeholder="Epaisseur de la matière"
                      style={{ width: '300%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={8} offset={8}>
                <Form.List
                  visibility={isHidden[key] === true ? 'hidden' : false}
                  hidden={isHidden[key] === true}
                  name={[fieldName, 'sizes']}
                  fieldKey={[fieldKey, 'sizes']}
                >
                  {(listFields, { addField, removeField }) => (
                    <>
                      {listFields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                          size={20}
                        >
                          <Form.Item
                            visibility={
                              isHidden[key] === true ? 'hidden' : false
                            }
                            hidden={isHidden[key] === true}
                            {...field}
                            name={[field.name, 'width']}
                            fieldKey={[field.fieldKey, 'width']}
                            rules={[
                              { required: true, message: 'Missing width' }
                            ]}
                          >
                            <InputNumber
                              placeholder={t('quotations.surface.width')}
                              style={{ width: '300%' }}
                            />
                          </Form.Item>
                          <Form.Item
                            visibility={
                              isHidden[key] === true ? 'hidden' : false
                            }
                            hidden={isHidden[key] === true}
                            {...field}
                            name={[field.name, 'height']}
                            fieldKey={[field.fieldKey, 'height']}
                            rules={[
                              { required: true, message: 'Missing height' }
                            ]}
                          >
                            <InputNumber
                              placeholder={t('quotations.surface.height')}
                              style={{ width: '300%' }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => removeField(field.name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          visibility={isHidden[key] === true ? 'hidden' : false}
                          hidden={isHidden[key] === true}
                          type="dashed"
                          onClick={() => addField()}
                          block
                          icon={<PlusOutlined />}
                          style={{ width: '300%' }}
                        >
                          Nouvelle dimension
                        </Button>
                      </Form.Item>
                      {fields.length ? (
                        <Form.Item>
                          <Button
                            onClick={() => calcSurface(fieldKey)}
                            type="dashed"
                            style={{
                              marginBottom: 10,
                              width: '300%'
                            }}
                          >
                            Calculer la surface
                          </Button>
                        </Form.Item>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </Col>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    visibility={isHidden[key] === true ? 'hidden' : false}
                    hidden={isHidden[key] === true}
                    name={[name, 'surface']}
                    fieldKey={[fieldKey, 'surface']}
                  >
                    <InputNumber
                      style={{ width: '300%' }}
                      placeholder="Surface (m2)"
                      onChange={(value) => calcPrice(value, fieldKey)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item>
                    <Select
                      style={{ width: '300%' }}
                      options={comments.map(({ label, comment }) => ({
                        label,
                        value: comment
                      }))}
                      placeholder="Modèles de commentaires"
                      onSelect={(value) => selectComment(value, fieldKey)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} offset={8}>
                  <Form.Item
                    name={[name, 'comment']}
                    fieldKey={[fieldKey, 'comment']}
                  >
                    <TextArea placeholder="Commentaire ..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} offset={8}>
                  <Form.Item
                    name={[name, 'price']}
                    fieldKey={[fieldKey, 'price']}
                  >
                    <InputNumber
                      precision={2}
                      placeholder="Montant €/HT"
                      style={{ width: '300%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: -20 }}>
                <Col
                  span={4}
                  offset={20}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    type="link"
                    onClick={() => remove(name)}
                    block
                    icon={<MinusOutlined />}
                  >
                    Supprimer élément
                  </Button>
                </Col>
              </Row>
            </Section>
          ))}
          <Row>
            <Col span={24} offset={8}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Nouvel élément
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  );
};

SectionForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  coefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  parentKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  totalPrice: PropTypes.func.isRequired
};

export default SectionForm;
