import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const Revenu = () => {
  const { t } = useTranslation();

  const column = [
    {
      title: t('incomes.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: (date) => {
        return new Date(date).toLocaleDateString('fr-FR');
      }
    },
    {
      title: t('incomes.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('incomes.form.category'),
      key: 'category',
      dataIndex: 'category',
      sorter: true,
      render: (categories) =>
        categories && (
          <Tag color={categories.color}>{categories.label.toUpperCase()}</Tag>
        )
    },
    {
      title: t('incomes.form.amount'),
      key: 'amount_ht',
      dataIndex: 'amount_ht',
      sorter: true,
      render: (value) => {
        return `${value} €`;
      }
    },
    {
      title: t('incomes.form.customer'),
      key: 'id_customer',
      dataIndex: 'id_customer',
      sorter: true,
      render: (contact) =>
        contact && `${contact.last_name} ${contact.first_name}`
    }
  ];

  const headers = [
    {
      label: 'Due_date',
      key: 'date'
    },
    {
      label: 'wording',
      key: 'wording'
    },
    {
      label: 'category',
      key: 'category.label'
    },
    {
      label: 'Amount_HT',
      key: 'amount_ht'
    },
    {
      label: 'Customers first name',
      key: 'id_customer.first_name' || ''
    },
    {
      label: 'Customers last name',
      key: 'id_customer.last_name' || ''
    }
  ];

  return (
    <>
      <SimpleResourceLandingLayout
        resourceName="incomes"
        columns={column}
        withSubRoutes
        populate="category,id_customer"
        headers={headers}
      />
    </>
  );
};

export default Revenu;
