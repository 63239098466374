import { useTranslation } from 'react-i18next';

export const useCusertomerCodeColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('settings-customers-codes.form.title'),
      dataIndex: 'title',
      editable: true,
      width: '25%'
    },
    {
      title: t('settings-customers-codes.form.nomenclature'),
      dataIndex: 'nomenclature',
      editable: true,
      width: '25%'
    }
  ];
};
