import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Row, Collapse, Table, Button, Form } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import { useConsumableColumns } from '../Columns/ConsumableColumns';
import useErrorMessage from '../../../utils/ErrorMessage';
import { ConsumableModal } from './ConsumableModal';

const { Panel } = Collapse;

export const ConsumableCard = ({
  isValidDefinitevly,
  form,
  dataSource,
  setDataSource
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isModalConsumableVisible, setIsModalConsumVisible] = useState(false);
  const [consumables, setConsumables] = useState([]);
  const consumableColumns = useConsumableColumns(
    isValidDefinitevly,
    setDataSource
  );

  const getConsumables = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'supplies'
      });
      setConsumables(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getConsumables();
    })();
  }, []);

  const showModalConsum = () => {
    setIsModalConsumVisible(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      consumables: dataSource
    });
  }, [dataSource]);

  return (
    <Row
      justify="space-between"
      align="stretch"
      style={{
        padding: '0px 24px 24px 24px',
        flexWrap: 'nowrap',
        height: 'fit-content'
      }}
      gutter={24}
    >
      <Col span={24}>
        <Collapse>
          <Panel header={t('laysheet.form.titles.consumable')} key="consumable">
            <Form.Item name={['consumables']}>
              <Table
                dataSource={dataSource}
                columns={consumableColumns}
                pagination={false}
                scroll={{ x: 800 }}
              />
            </Form.Item>
            <Button
              disabled={isValidDefinitevly}
              type="dashed"
              style={{ width: '100%', height: 50 }}
              onClick={showModalConsum}
            >
              {t('laysheet.form.buttons.add.consumable')}
            </Button>
            <ConsumableModal
              consumables={consumables}
              setDataSource={setDataSource}
              isModalConsumableVisible={isModalConsumableVisible}
              setIsModalConsumVisible={setIsModalConsumVisible}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

ConsumableCard.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  isValidDefinitevly: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setDataSource: PropTypes.func
};

ConsumableCard.defaultProps = {
  isValidDefinitevly: undefined,
  dataSource: null,
  setDataSource: null
};
