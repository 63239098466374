import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderTable = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('ordersPurchases.matter'),
      dataIndex: 'matter'
    },
    {
      title: t('ordersPurchases.completion'),
      dataIndex: 'completion'
    },
    {
      title: t('ordersPurchases.color'),
      dataIndex: 'color'
    },
    {
      title: t('ordersPurchases.thickness'),
      dataIndex: 'thickness'
    },
    {
      title: t('ordersPurchases.quantity'),
      dataIndex: 'quantity'
    }
  ];

  const datas = [];
  if (data.supplies) {
    data.supplies.map((el, i) => {
      const result = {
        matter: el.matter.label,
        completion: el.matter.completions,
        color: el.matter.color,
        thickness: el.thickness,
        quantity: el.quantity
      };
      datas.push(result);
      return el;
    });
  }

  return <Table columns={columns} dataSource={datas} />;
};

OrderTable.propTypes = {
  data: PropTypes.shape({
    supplies: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired
};

export default OrderTable;
