import React from 'react';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DartyPrice = () => {
  const { t } = useTranslation();

  return (
    <Form.List name="darty_price">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'baseline'
              }}
            >
              <Form.Item
                name={[field.name, 'granit']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
              >
                <Input placeholder="Granit" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'silestone']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
              >
                <Input placeholder="Silestone" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'dekton']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
              >
                <Input placeholder="Dekton" />
              </Form.Item>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
            >
              {t('catalogues.addpriceDarty')}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
export default DartyPrice;
