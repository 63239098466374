import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckOutlined
} from '@ant-design/icons';

const ConfiguratorButtons = ({
  currentStep,
  setCurrentStep,
  handleSubmit,
  status
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="primary"
        style={{
          marginTop: 20,
          float: 'left',
          display: currentStep === 0 ? 'none' : ''
        }}
        onClick={() => setCurrentStep(currentStep - 1)}
        icon={<ArrowLeftOutlined />}
      >
        {t(`configurator.prev`)}
      </Button>
      <Button
        type="primary"
        style={{
          marginTop: 20,
          float: 'right',
          display: currentStep === 4 ? 'none' : ''
        }}
        onClick={() => setCurrentStep(currentStep + 1)}
      >
        {t(`configurator.next`)}
        <ArrowRightOutlined />
      </Button>

      <Button
        type="primary"
        style={{
          marginTop: 20,
          float: 'right',
          display: currentStep === 4 ? '' : 'none'
        }}
        onClick={() => handleSubmit('PENDING')}
      >
        {t(`configurator.validate`)}
        <CheckOutlined />
      </Button>
      {status !== 'PENDING' && (
        <Button
          style={{
            marginTop: 20,
            float: 'right',
            display: currentStep === 4 ? '' : 'none'
          }}
          onClick={() => handleSubmit('DRAFT')}
        >
          {t(`configurator.draft`)}
          <CheckOutlined />
        </Button>
      )}
    </>
  );
};

export default ConfiguratorButtons;

ConfiguratorButtons.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};
