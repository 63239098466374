/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Tag, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  const popoverContent = (
    <ChromePicker
      color={colorPicker || '#fff'}
      onChangeComplete={(color) => {
        setColorPicker(color.hex);
      }}
    />
  );
  let inputNode;
  if (inputType === 'color') {
    inputNode = (
      <Popover content={popoverContent} title="Title">
        <Button
          style={{
            backgroundColor: colorPicker || record.color,
            border: 'none',
            color: '#fff'
          }}
          value={colorPicker}
        >
          {colorPicker || record.color}
        </Button>
      </Popover>
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  children: PropTypes.element.isRequired,
  colorPicker: PropTypes.string.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  inputType: PropTypes.string.isRequired,
  record: PropTypes.shape({
    color: PropTypes.any
  }).isRequired,
  selectType: PropTypes.string.isRequired,
  setColorPicker: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const TrelloCardLabelsTable = ({
  cardLabel,
  editCardLabel,
  deleteCardLabel
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      color: record.color,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const setColor = async ({ color }) => {
    await form.setFieldsValue({
      color: colorPicker || color
    });
  };
  const save = async (record) => {
    await setColor(record);

    const row = await form.validateFields();
    editCardLabel(record._id, row);
    setEditingKey('');
  };

  const columns = [
    {
      title: t('settings.tva.label'),
      dataIndex: 'label',
      width: '40%',
      editable: true
    },
    {
      title: t('settings.kanbantable.color'),
      dataIndex: 'color',
      width: '40%',
      editable: true,
      render: (color) => <Tag color={color}>{color}</Tag>
    },
    {
      title: t('settings.kanbantable.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button onClick={() => save(record)} style={{ borderRadius: 0 }}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteCardLabel(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,

      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker,
        setColorPicker
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={cardLabel}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

TrelloCardLabelsTable.propTypes = {
  cardLabel: PropTypes.string.isRequired,
  deleteCardLabel: PropTypes.func.isRequired,
  editCardLabel: PropTypes.func.isRequired
};

export default TrelloCardLabelsTable;
