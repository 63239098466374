import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountingContextProvider } from '../AccountingContext';
import ListInvoices from './ListInvoices';
import Details from './Details';
import AccountingCode from './AccountingCode/AccountingCode';
import { AccountingCodesContextProvider } from './AccountingCode/AccountingCodeContext';

const InvoiceRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AccountingContextProvider collection="invoices">
      <AccountingCodesContextProvider>
        <Switch>
          <Route path={`${path}/show/:id`} component={Details} />
          <Route
            path={`${path}/accounting-codes/:id`}
            component={AccountingCode}
          />
          <Route path={path} component={ListInvoices} />
        </Switch>
      </AccountingCodesContextProvider>
    </AccountingContextProvider>
  );
};

export default InvoiceRouter;
