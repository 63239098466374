import PropTypes from 'prop-types';
import React, { useState, useEffect, createContext, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { message, Form } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

export const WorkSheetsContext = createContext({});

export const WorkSheetsContextProvider = ({ children }) => {
  const { state } = useLocation();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [template, setTemplate] = useState();
  const [finitions, setFinitions] = useState([]);
  const [machines, setMachines] = useState([]);
  const [operators, setOperators] = useState([]);
  const [process, setProcess] = useState([]);
  const [sites, setSites] = useState([]);
  const [matterfinits, setMatterfinits] = useState([]);
  const [, setWorktasks] = useState([]);

  const fetchFinitions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-finitions`
      });
      const dataSource = data.map(({ _id, label }) => ({
        _id,
        label,
        machine: '',
        operator: '',
        status: '',
        end_date: ''
      }));
      setFinitions(dataSource);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchProcess = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Processs`
      });
      const dataSource = data.map(({ _id, label }) => ({
        _id,
        label,
        machine: '',
        quantity: '',
        operator: '',
        status: '',
        end_date: ''
      }));

      setProcess(dataSource);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchOperators = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-operators`
      });
      setOperators(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchMachines = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-machines`
      });
      setMachines(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites`
      });
      setSites(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchMatterfinits = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-matterfinits`
      });
      const dataSource = data.map(({ _id, label }) => ({
        _id,
        label,
        worktasks: '',
        disponibility: '',
        operator: '',
        status: '',
        end_date: ''
      }));

      setMatterfinits(dataSource);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchWorktasks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-worktasks`
      });
      setWorktasks(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=WORKSHEET`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/worksheet/${id}/${template._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });
      const nameFile =
        name === 'undefined.pdf' ? `${state.datas.number}-FT.pdf` : name;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = nameFile;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  useEffect(() => {
    fetchOperators();
    fetchMachines();
    fetchProcess();
    fetchSites();
    fetchWorktasks();
    fetchMatterfinits();
    fetchFinitions();
    getTemplate();
  }, []);

  const updateLayDate = async () => {
    const method = 'PATCH';
    const data = form.getFieldsValue();
    const body = {
      placed: {
        lay_date: data?.lay_date?._d,
        measurement_date: data?.measurement_date?._d
      }
    };
    try {
      const url = `/ordersnew/${state.datas._id}`;
      await dispatchAPI(method, {
        url,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const createAcceptanceReport = async () => {
    try {
      let body = {
        worksheet: state.worksheet?._id,
        order: state.datas?._id,
        date: form.getFieldValue().date || state.worksheet?.date,
        orderNumber: state.datas?.number
      };
      let url = '/acceptance-report';
      if (state.datas?.placed.without_lay) {
        url = '/removal-order';
        body = {
          worksheet: state.worksheet?._id,
          order: state.datas?._id,
          is_delivery: false,
          date: form.getFieldValue().date || state.worksheet?.date,
          orderNumber: state.datas?.number
        };
      }
      await dispatchAPI('POST', {
        url,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const createWorkSheet = async () => {
    const url = '/worksheets';
    const body = {
      ...form.getFieldsValue(),
      order: state.datas._id,
      date: form.getFieldValue().lay_date
        ? form.getFieldValue().lay_date
        : moment(),
      measurement_date: form.getFieldValue().measurement_date
        ? form.getFieldValue().measurement_date
        : moment(),
      matter: form.getFieldValue().matter,
      validated: form.getFieldValue().validated
    };
    const method = 'POST';

    try {
      const result = await dispatchAPI(method, {
        url,
        body
      });
      state.worksheet = { ...body, _id: result.data.description.id };
      updateLayDate();
      if (body?.validated) await createAcceptanceReport();
      history.push(
        `/customers_accounting/orders_sales/show/${state.datas._id}`
      );
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  return (
    <WorkSheetsContext.Provider
      value={{
        form,
        state,
        template,
        setTemplate,
        getTemplate,
        finitions,
        setFinitions,
        machines,
        operators,
        process,
        sites,
        matterfinits,
        downloadFile,
        updateLayDate,
        createWorkSheet
      }}
    >
      {children}
    </WorkSheetsContext.Provider>
  );
};

WorkSheetsContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(WorkSheetsContext);
