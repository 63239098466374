import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import invoicesColumns from '../../Invoices/columns';
import expensesColumns from '../../Expenses/ConstantColumns';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const PartialReconciliation = ({
  type,
  elements,
  onSelectionChange,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const { t } = useTranslation();
  const [parsedElements, setParsedElements] = useState([]);
  const correspondingColumns =
    type === 'invoices' ? [...invoicesColumns(t)] : [...expensesColumns()];

  useEffect(() => {
    if (elements) {
      const tmpElements = [...elements];

      if (type === 'invoices') {
        tmpElements.forEach((element) => {
          const currElement = element;
          currElement.key = element._id;
          currElement.total -= element.total_paid ? element.total_paid : 0;
        });
      }

      if (type === 'expenses') {
        tmpElements.forEach((element) => {
          const currElement = element;
          currElement.key = element._id;
          currElement.amount_ht -= element.total_reconciled
            ? element.total_reconciled
            : 0;
        });
      }

      setParsedElements(tmpElements);
    }
  }, [elements]);

  useEffect(() => {
    if (selectedRowKeys) {
      onSelectionChange(selectedRowKeys.length !== 1);
    }
  }, [selectedRowKeys]);

  return (
    <StyledTable
      as={Table}
      rowClassName="rowStyle"
      dataSource={parsedElements}
      rowSelection={{
        onChange: setSelectedRowKeys
      }}
      columns={correspondingColumns}
      pagination={false}
    />
  );
};

PartialReconciliation.defaultProps = {
  type: 'invoices'
};

PartialReconciliation.propTypes = {
  type: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired
};

export default PartialReconciliation;
