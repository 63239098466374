import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Tag, Col, Row } from 'antd';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { colorsType } from '../../../utils/constants/tagColors';
import useDealContext from './DealsContext';
import DealCardTags from './DealCardTags';
import PriorityTag from './PriorityTag';

const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: relative;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(255, 0, 0);
  font-size: 20px;
  cursor: pointer;
`;

const DealCard = ({ deal }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { _id, name, lead, priority, expected_date } = deal;
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && setIsLoading({ [deal._id]: true })
    }),
    canDrag: () => !deal.is_archived
  });
  const {
    fetchData,
    delDeal,
    setVisible,
    setDeal,
    isLoading,
    setIsLoading
  } = useDealContext();
  const compareExpectedDate = (expectedDate, object) => {
    const expectedD = moment(expectedDate).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    if (expectedDate) {
      if (moment(expectedD).isSame(currentDate, 'day'))
        return colorsType.ORANGE;
      if (moment(expectedDate).isBefore(currentDate)) return colorsType.RED;

      return object === 'title' ? colorsType.BLACK : colorsType.GREEN;
    }
    return object === 'title' ? colorsType.BLACK : colorsType.GREEN;
  };

  const getProjectTypes = () => {
    let projectTypes = '';
    if (deal.quotes) {
      deal.quotes.forEach((quote) => {
        if (quote.quote) {
          if (quote.quote.section_1 && quote.quote.section_1.project_type) {
            projectTypes += `${quote.quote.section_1.project_type}, `;
          }
          if (quote.quote.section_2 && quote.quote.section_2.project_type) {
            projectTypes += `${quote.quote.section_2.project_type}, `;
          }
          if (quote.quote.section_3 && quote.quote.section_3.project_type) {
            projectTypes += `${quote.quote.section_3.project_type}, `;
          }
          if (quote.quote.section_4 && quote.quote.section_4.project_type) {
            projectTypes += `${quote.quote.section_4.project_type}, `;
          }
          if (quote.quote.section_5 && quote.quote.section_5.project_type) {
            projectTypes += `${quote.quote.section_5.project_type}, `;
          }
        }
      });
    }
    // Remove duplicate projects
    projectTypes = projectTypes
      .split(',')
      .filter((v, i, a) => a.indexOf(v) === i);
    return projectTypes;
  };
  return (
    <>
      {!isLoading[deal._id] && (
        <Card ref={drag} style={{ opacity: isDragging ? '0.5' : '1' }}>
          <DealCardTags deal={deal} />
          <div
            style={{
              fontSize: 16,
              marginBottom: 12,
              fontWeight: 'bold',
              color: `${compareExpectedDate(expected_date, 'title')}`
            }}
          >
            {name}
          </div>
          <div>
            <span>
              {deal.professionals && deal.professionals.length > 0 && (
                <span>
                  <span>{t('crm.card.professional')} </span>
                  <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                    {deal.professionals.map((pro) => pro.name).join(', ')}
                  </span>
                </span>
              )}

              {deal.customers && deal.customers.length > 0 && (
                <div>
                  <span>{t('crm.card.customer')} </span>
                  <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                    {deal.customers
                      .map(
                        (customer) =>
                          `${customer.first_name || ''} ${customer.last_name}`
                      )
                      .join(', ')}
                  </span>
                </div>
              )}
              {deal.prescribers && deal.prescribers.length > 0 && (
                <div>
                  <span>{t('crm.card.prescribers')}</span>
                  <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                    {deal.prescribers
                      .map(
                        (prescriber) =>
                          `${prescriber?.first_name ||
                            prescriber?.name} ${prescriber?.last_name || ''}`
                      )
                      .join(', ')}
                  </span>
                </div>
              )}
            </span>
          </div>
          <div>
            <span>{t('crm.card.project_type')}</span>
            {}
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
              {getProjectTypes()}
            </span>
          </div>
          {!deal?.quotes[0]?.quote ? (
            <div>
              <span>{t(`crm.card.due_date`)}</span>
              <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                <Tag color={compareExpectedDate(expected_date, 'date')}>
                  {expected_date
                    ? moment(expected_date).format('DD/MM/YYYY')
                    : 'N/R'}
                </Tag>
              </span>
            </div>
          ) : (
            ''
          )}
          <Row
            style={{ fontSize: '20px', marginTop: 8 }}
            justify="space-between"
            align="middle"
          >
            <Col>
              <PriorityTag priority={priority} />
            </Col>
            <Col>
              <Link
                to={{
                  pathname: `${pathname}/show/${_id}`
                }}
              >
                <EyeOutlined />
              </Link>
              <Divider type="vertical" />
              {!deal.is_archived && (
                <EditOutlined
                  style={{ color: 'var(--textColorSecondary)' }}
                  onClick={() => {
                    setDeal(_id);
                    setVisible(true);
                  }}
                />
              )}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

DealCard.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.string,
    customers: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }),
    expected_date: PropTypes.func,
    is_archived: PropTypes.any,
    lead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    name: PropTypes.string,
    prescribers: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }),
    priority: PropTypes.string,
    professionals: PropTypes.array,
    quotes: PropTypes.shape({
      forEach: PropTypes.func
    })
  }).isRequired
};

export default DealCard;
