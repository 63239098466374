import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Card, Typography } from 'antd';

const Accessories = ({ datas }) => {
  const { Title } = Typography;
  if (datas) {
    return (
      <Col span={8} style={{ minHeight: '100%' }}>
        <Card
          style={{ background: 'rgba(255, 255, 255, 0.8)', height: '100%' }}
          bordered={false}
        >
          <Row>
            <Col>
              <Title level={4}>Autres</Title>
              <div style={{ width: '110px' }}>
                <hr
                  style={{
                    backgroundColor: 'black',
                    height: '3px',
                    border: 'none',
                    marginBottom: '18px'
                  }}
                />
              </div>
              <Row style={{ minHeight: '100px' }}>
                {datas.accessories &&
                  datas.accessories.map((accessory, index) => {
                    return (
                      <Col style={{ padding: '6px 6px 6px 0' }}>
                        <Card style={{ width: '90%' }} bordered={false}>
                          <Row>
                            {`Nom :`}&nbsp;
                            <b>{`${accessory.name}`}</b>
                          </Row>
                          <Row>
                            {`Prix :`}&nbsp;
                            <b>{`${accessory.price}`}</b>
                          </Row>
                          <Row>
                            {`Quantité :`}&nbsp;
                            <b>{`${accessory.quantity}`}</b>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }

  return <div>Loading</div>;
};

Accessories.propTypes = {
  datas: PropTypes.shape({
    accessories: PropTypes.shape({
      map: PropTypes.func
    }),
    products: PropTypes.shape({
      map: PropTypes.func
    })
  }).isRequired
};

export default Accessories;
