/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';

const EditableCell = ({
  enums,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  categories,
  ...restProps
}) => {
  const select = (
    <Select
      options={categories?.map((cat) => ({
        label: cat,
        value: cat
      }))}
    />
  );

  const inputNode = inputType === 'select' ? select : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  categories: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  children: PropTypes.element.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  enums: PropTypes.arrayOf(PropTypes.enums).isRequired,
  index: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  record: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired
};

const CommentariesTable = ({
  comments,
  categories,
  editComment,
  deleteComment
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    const row = await form.validateFields();
    editComment(record._id, row);
    setEditingKey('');
  };

  const columns = [
    {
      title: t('settings.commentary.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('settings.commentary.type'),
      dataIndex: 'type',
      width: '20%',
      editable: true
    },
    {
      title: t('settings.commentary.comment'),
      dataIndex: 'comment',
      width: '50%',
      editable: true
    },
    {
      title: t('settings.commentary.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)}>
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {t('buttons.edit')}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteComment(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {t('buttons.delete')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'type' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        categories
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={comments}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

CommentariesTable.propTypes = {
  categories: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  comments: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    comment: PropTypes.string
  }).isRequired,
  deleteComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired
};

export default CommentariesTable;
