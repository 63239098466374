import React, { useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { sitesTypes } from '../../utils/constants/tagColors';
import ContactsForm from './contactsForm';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const basicFields = [
    {
      name: ['type'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select>
          <Option key="rawMaterial" value="rawMaterial">
            <Tag color="blue">{t('suppliers.form.rawMaterial')}</Tag>
          </Option>
          <Option key="consumable" value="consumable">
            <Tag color="green">{t('suppliers.form.consumable')}</Tag>
          </Option>
          <Option key="Machine-Maintenance" value="Machine-Maintenance">
            <Tag color="orange">{t('suppliers.form.machine')}</Tag>
          </Option>
        </Select>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'channel_number',
      name: ['address', 'number']
    },
    {
      label: 'street',
      name: ['address', 'street'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: <Input type="number" />
    },
    {
      label: 'city',
      name: ['address', 'city'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'country',
      name: ['country'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      name: ['siren_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['ape_number'],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      label: 'email',
      name: ['email'],
      input: <Input type="email" />
    },
    {
      name: ['phone_number']
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <ContactsForm />
    }
  ];

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
