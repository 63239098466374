import moment from 'moment';
import React from 'react';
import { Tag } from 'antd';
import { customerTypes } from '../../utils/constants/tagColors';

const useColumns = (t, types, genders) => {
  return [
    {
      title: t('quotations.list.column.title.fileNumber'),
      dataIndex: 'fileNumber',
      key: 'fileNumber',
      render: (number) => (
        <p style={{ fontWeight: '500', marginTop: '0.8rem' }}>{number}</p>
      ),
      sorter: true
    },

    // {
    //   title: t('quotations.list.column.title.fixType'),
    //   dataIndex: 'joineries',
    //   key: 'fixType',
    //   render: item =>item.map((el) => <Tag color={fixTypes[el.fixType]}>{el.fixType.toUpperCase()}</Tag>),
    //   sorter: true,
    // },

    {
      title: t('quotations.list.column.title.customer'),
      dataIndex: 'type',
      key: 'type',
      render: (item) => <Tag color={customerTypes[item]}>{item}</Tag>,
      sorter: true,
      filters:
        types &&
        types.map((el) => ({
          text: el,
          value: el
        }))
    },
    {
      title: t('quotations.list.column.title.societyName'),
      dataIndex: 'societyName',
      key: 'societyName',
      render: (society) => (
        <p style={{ fontWeight: '500', marginTop: '0.8rem' }}>{society}</p>
      )
    },
    {
      title: t('quotations.list.column.title.last_name'),
      dataIndex: 'last_name',
      key: 'last_name',
      render: (lastname) => (
        <p style={{ fontWeight: '500', marginTop: '0.8rem' }}>{lastname}</p>
      )
    },

    {
      title: t('quotations.list.column.title.first_name'),
      dataIndex: 'first_name',
      key: 'first_name'
      // render: customer => `${customer.first_name} ${customer.last_name}`,
    },

    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    }
  ];
};

export default useColumns;
