import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, message, Modal, Select, Popconfirm } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { userRoles } from '../../utils/constants/tagColors';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;
const { Option } = Select;

const ListUsers = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [profesionals, setProfesional] = useState(false);
  const [pro, setPro] = useState('');
  const [id, setId] = useState('');

  const showModal = (_id) => {
    setId(_id);
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleOk = async () => {
    const body = {
      check: true,
      profesional: pro
    };
    try {
      await dispatchAPI('PATCH', { url: `extrausers/${id}`, body });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsVisible(false);
  };

  const deleteUser = async (_id) => {
    try {
      await dispatchAPI('DELETE', { url: `extrausers/${_id}` });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getProfesional = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/profesionals` });
      setProfesional(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const proSelected = (e) => {
    setPro(e);
  };

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'Address street number',
      key: 'address.number'
    },
    {
      label: 'Address street name',
      key: 'address.street'
    },
    {
      label: 'Address additional information',
      key: 'address.additional'
    },
    {
      label: 'Address postal code',
      key: 'address.postal_code'
    },
    {
      label: 'Address city',
      key: 'address.city'
    },
    {
      label: 'Address country',
      key: 'address.country'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, profesional }) =>
        profesional ? (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ danger: true }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteUser(key)}
          >
            <CloseOutlined style={{ fontSize: iconSize, color: 'red' }} />
          </Popconfirm>
        ) : (
          <>
            <CheckOutlined
              style={{ fontSize: iconSize, color: '#47D267' }}
              onClick={() => showModal(key)}
            />
            <Modal
              title="Basic Modal"
              visible={isVisible}
              onOk={handleOk}
              onCancel={() => handleCancel(key)}
            >
              <Select style={{ width: '100%' }} onChange={proSelected}>
                {profesionals &&
                  profesionals.map((item) => (
                    <Option value={item._id}>{item.name}</Option>
                  ))}
              </Select>
            </Modal>
          </>
        )
    }
  ];

  useEffect(() => {
    getProfesional();
  }, []);

  return (
    <SimpleResourceLandingLayout
      resourceName="extrausers"
      customActionColumn
      columns={columns}
      withSubRoutes
      headers={headers}
      forceRefresh={forceRefresh}
      resourceModelName="ExtraUser"
    />
  );
};

export default ListUsers;
