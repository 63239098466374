import React from 'react';
import { Link } from 'react-router-dom';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Profesionals = ({ isLoading, display }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {display && (
          <>
            <Title level={5}>
              <Link to={`/commercial/profesionals/show/${display._id}`}>
                <span style={{ marginRight: 5 }}>
                  {display.name.toUpperCase()}
                </span>
              </Link>
            </Title>
            <Title level={5}>
              {display.email}
              <br />
              {display.phone_number}
            </Title>
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Profesionals.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  display: PropTypes.bool.isRequired
};

export default Profesionals;
