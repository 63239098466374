import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import useConfiguratorContext from './ConfiguratorContext';

const ShapingsForm = ({ name, elementId, sectionId, choice }) => {
  const { datas, t, form } = useConfiguratorContext();
  const { shapings } = datas;

  const [filters, setFilters] = useState([]);
  const [isDecimal, setIsDecimal] = useState();
  const [catalogue, setCatalogue] = useState([]);

  useEffect(() => {
    const values = form.getFieldsValue();

    const newFilters = [];
    if (values[sectionId].elements[elementId].sizes[name[0]]?.shapings) {
      values[sectionId].elements[elementId].sizes[name[0]].shapings.forEach(
        (shaping, shapingIndex) => {
          newFilters[shapingIndex] = { category: shaping.shaping };
        }
      );
    }

    setFilters(newFilters);
  }, []);

  useEffect(() => {
    const copy = [];
    if (choice === 'DY') {
      (shapings || []).forEach((el) => {
        if (el.ref.slice(-2) === 'DY') {
          copy.push(el);
        }
      });
    } else {
      (shapings || []).forEach((el) => {
        if (el.ref.slice(-2) !== 'DY') {
          copy.push(el);
        }
      });
    }
    setCatalogue(copy);
  }, [choice]);

  const onSelectShapingType = (value, index) => {
    const values = form.getFieldsValue();
    values[sectionId].elements[elementId].sizes[name[0]].shapings[
      index
    ].shaping_name = null;
    values[sectionId].elements[elementId].sizes[name[0]].shapings[
      index
    ].nb = null;
    form.setFieldsValue(values);

    const newFilters = [...filters];
    newFilters[index] = { category: value };
    setFilters(newFilters);
  };

  const onSelectShaping = (value, index) => {
    const values = form.getFieldsValue();

    const shapingPrice = shapings
      .filter((shaping) => shaping._id === value)
      .shift();
    values[sectionId].elements[elementId].sizes[name[0]].shapings[index] = {
      ...values[sectionId].elements[elementId].sizes[name[0]].shapings[index],
      unit_price: shapingPrice.sold_price
    };
    if (shapingPrice?.unit?.toLowerCase() === 'unité') {
      setIsDecimal(false);
    } else setIsDecimal(true);
    form.setFieldsValue(values);
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((el) => (
            <div className="quotation-shaping-field-wrapper">
              <Form.Item
                label={t('configurator.shaping_type')}
                name={[el.name, 'shaping']}
                fieldKey={[el.fieldKey, 'shaping']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  options={[
                    ...new Set(catalogue.map(({ category }) => category))
                  ].map((label) => ({ label, value: label }))}
                  onChange={(value) => {
                    onSelectShapingType(value, el.name);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('configurator.shaping_name')}
                name={[el.name, 'shaping_name']}
                fieldKey={[el.fieldKey, 'shaping_name']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  options={catalogue
                    .filter(({ category }) => {
                      return !filters[el.name]
                        ? false
                        : category === filters[el.name].category;
                    })
                    .map(({ label, _id }) => ({ label, value: _id }))}
                  onChange={(value) => onSelectShaping(value, el.name)}
                />
              </Form.Item>
              <Form.Item
                hidden
                name={[el.name, 'unit_price']}
                fieldKey={[el.fieldKey, 'unit_price']}
                rules={[{ required: true }]}
              />
              <Form.Item
                label={t('configurator.quantity')}
                name={[el.name, 'nb']}
                fieldKey={[el.fieldKey, 'nb']}
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <InputNumber
                  min={0}
                  step={isDecimal ? 0.01 : 1}
                  formatter={(value) => {
                    if (value) {
                      const values = form.getFieldsValue();
                      const id =
                        values[sectionId].elements[elementId].sizes[name[0]]
                          .shapings[el.name].shaping_name;
                      const isInteger = shapings
                        .filter((shaping) => shaping._id === id)
                        .map((val) => val)
                        .shift();
                      if (isInteger?.unit?.toLowerCase() === 'unité') {
                        setIsDecimal(false);
                        return Math.trunc(value);
                      }
                      setIsDecimal(true);
                      return value;
                    }
                  }}
                  parser={(value) => (isDecimal ? value : Math.trunc(value))}
                  onChange={(value) => {
                    if (isDecimal) {
                      return value;
                    }
                    return Math.trunc(value);
                  }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                <DeleteOutlined
                  onClick={() => {
                    remove(el.name);
                    const values = form.getFieldsValue();

                    const newFilters = [];
                    if (
                      values[sectionId].elements[elementId].sizes[name[0]]
                        .shapings
                    ) {
                      values[sectionId].elements[elementId].sizes[
                        name[0]
                      ].shapings.forEach((shaping, shapingIndex) => {
                        if (shaping) {
                          newFilters[shapingIndex] = {
                            category: shaping.shaping
                          };
                        }
                      });
                    }
                    setFilters(newFilters);
                  }}
                />
              </Form.Item>
            </div>
          ))}
          <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
            <Button
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              style={{ marginBottom: 20 }}
              className="add-shaping-btn"
            >
              façonnage
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

ShapingsForm.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  sectionId: PropTypes.number.isRequired,
  elementId: PropTypes.number.isRequired,
  choice: PropTypes.string
};

ShapingsForm.defaultProps = {
  choice: ''
};

export default ShapingsForm;
