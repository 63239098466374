import PropTypes from 'prop-types';
import { InputNumber, Row, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const PieceNumbers = ({ form, number, disabled }) => {
  const [singleNumber, setSingleNumber] = useState(1);
  const [ranges, setRanges] = useState([{ min: 1, max: 1 }]);
  const [rangeCount, setRangeCount] = useState(1);
  const [currentPieceNumber, setCurrentPieceNumber] = useState(1);
  const addRange = () => {
    if (rangeCount < number) {
      setRangeCount(rangeCount + 1);
      setRanges([...ranges, { min: 1, max: 1 }]);
    }
  };
  const removeRange = () => {
    if (rangeCount > 1) {
      setRangeCount(rangeCount - 1);
      setRanges(ranges.filter((el, index) => index < rangeCount));
    }
  };

  useEffect(() => {
    setFormsValue();
  }, [ranges, singleNumber]);

  const setFormsValue = () => {
    let allNumbers = [];
    const values = form.getFieldsValue();
    if (number === 1) {
      allNumbers = [singleNumber];
    } else {
      const allNumbersArrays = ranges.map((r) =>
        Array.from({ length: r.max - r.min + 1 }, (_, i) => r.min + i)
      );

      allNumbersArrays.forEach((arr) => {
        allNumbers = [...allNumbers, ...arr];
      });

      allNumbers = Array.from(new Set(allNumbers));
    }
    setCurrentPieceNumber(allNumbers.length);
    if (allNumbers.length === number) {
      form.setFieldsValue({ ...values, pieces_numbers: allNumbers });
    }
  };

  const rangeLine = (rangeIndex) => {
    return (
      <Row align="middle" style={{ paddingBottom: '8px' }}>
        &nbsp;du&nbsp;
        <InputNumber
          value={ranges[rangeIndex]?.min}
          disabled={disabled}
          onChange={(value) => {
            const newRanges = [...ranges];
            if (!ranges[rangeIndex].max || ranges[rangeIndex].max < value) {
              newRanges[rangeIndex] = { min: value, max: value };
            } else {
              newRanges[rangeIndex] = { ...newRanges[rangeIndex], min: value };
            }
            setRanges(newRanges);
          }}
        />
        &nbsp; au&nbsp;
        <InputNumber
          value={ranges[rangeIndex]?.max}
          disabled={disabled}
          onChange={(value) => {
            const newRanges = [...ranges];
            newRanges[rangeIndex] = { ...newRanges[rangeIndex], max: value };
            setRanges(newRanges);
          }}
        />
        &nbsp;
        <div>
          {ranges[rangeIndex].min > ranges[rangeIndex].max
            ? 'Le maximum ne peut pas être inférieur au minimum'
            : `${ranges[rangeIndex].max - ranges[rangeIndex].min + 1} tranches`}
        </div>
      </Row>
    );
  };
  const addButton = (
    <Button icon={<PlusOutlined />} onClick={addRange}>
      AJOUTER UNE SERIE
    </Button>
  );
  const removeButton = (
    <Button icon={<MinusOutlined />} onClick={removeRange}>
      SUPPRIMER UNE SERIE
    </Button>
  );

  const rows = [];
  for (let i = 0; i < rangeCount; i += 1) {
    rows.push(rangeLine(i));
  }

  return (
    <>
      {number === 1 && (
        <InputNumber
          value={singleNumber}
          disabled={disabled}
          onChange={(newValue) => {
            if (newValue) setSingleNumber(newValue);
          }}
        />
      )}

      {number > 1 && (
        <div>
          {rows}
          {addButton}
          {removeButton}
        </div>
      )}
      <div style={{ color: 'red' }}>
        {currentPieceNumber !== number && 'Le nombre de tranches est incorrect'}
      </div>
    </>
  );
};

PieceNumbers.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  number: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

PieceNumbers.defaultProps = {
  disabled: null
};

export default PieceNumbers;
