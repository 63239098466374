import React, { useEffect, useState } from 'react';
import { Col, Table } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import OptionsRow from './OptionsRow';

const ListMovements = () => {
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [pieces, setPieces] = useState([]);
  const [isLoading] = useState(false);
  const history = useHistory();
  const [resources, setResources] = useState([]); // TODO : voir DataTable
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});

  const setFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const searchFilter = (value) => {
    if (value) {
      setExtraQuery(`filter={"$text": {"$search":"${value}"}}`);
    } else if (filters) {
      setFilters({ ...filters });
    }
  };

  const StyledTable = styled.div`
    .rowStyle {
      cursor: pointer;
    }
  `;

  const refresh = () => {
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    let query;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        if (query) {
          query += `&${key}=${filters[key]}`;
        } else {
          query = `${key}=${filters[key]}`;
        }
      }
    });
    setExtraQuery(query);
  }, [filters]);

  const columns = useColumns(refresh);

  const fetchAllPieces = async () => {
    try {
      const fetchedData = await dispatchAPI('GET', {
        url: `/pieces?populate=matter,buy_site,supplier,sizes.order&${extraQuery ||
          ''}`
      });
      setPieces(fetchedData);

      const data = {};

      fetchedData.data.forEach((piece) => {
        const sizesWithFields = piece.sizes.map((size) => {
          return {
            ...size,
            piece,
            matter: piece.matter,
            ref_supplier: piece.ref_supplier,
            size: { length: size.length, width: size.width }
          };
        });

        if (data[piece._id]) {
          data[piece._id] = [...data[piece._id], ...sizesWithFields];
        } else {
          data[piece._id] = sizesWithFields;
        }
      });

      let flattenedData = [];

      Object.keys(data).forEach((key) => {
        flattenedData = [...flattenedData, ...data[key]];
      }); // TODO : ajouter les informations matter, ref fournisseur, personne in charge

      flattenedData.map((fields, index) => {
        return { ...fields, key: index };
      });

      setResources(flattenedData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    fetchAllPieces();
  }, [forceRefresh, extraQuery]);

  return (
    <>
      <PageHeaderCustom title={t(`stocks.movement.title`)} withSubRoutes />

      <ContentCustom>
        <div>
          <Col style={{ paddingBottom: '16px' }}>
            <OptionsRow
              setFilter={setFilter}
              searchFilter={searchFilter}
              style={{ float: 'right' }}
              dataSource={pieces?.data}
            />
          </Col>
          <StyledTable
            as={Table}
            style={{ overflowX: 'auto' }}
            rowClassName="rowStyle"
            onRow={({ piece }) => ({
              onDoubleClick: () => history.push(`stocks/show/${piece._id}`)
            })}
            dataSource={resources}
            loading={isLoading}
            columns={columns}
          />
        </div>
      </ContentCustom>
    </>
  );
};

export default ListMovements;
