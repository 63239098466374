/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Button,
  Col,
  Form,
  Row,
  Select,
  Spin,
  Typography,
  Divider,
  InputNumber,
  Popover,
  message
} from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import {
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import SectionForm from './sectionForm';

const { Title } = Typography;

const familyOptions = [
  'Cuisine',
  'Salle de bain',
  'Toilette',
  'Mobilier',
  'Décoration',
  'Autres'
];

const Section = styled.div`
  background-color: var(--bodyBackground);
  margin-bottom: 20px;
`;

const QuotationForm = ({
  tvaCategories,
  resource,
  sites,
  profesionals,
  handleSubmit,
  categories,
  customers,
  quotation,
  isLoading,
  comments,
  location,
  dispatchAPI,
  initialElements,
  coefs
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [totalMode, setMode] = useState('HT');
  const [total, setTotal] = useState(0);
  const [popOver, setPopOver] = useState([]);
  const [popOverMatter, setPopOverMatter] = useState([]);
  const [tvaTx, setTvaTx] = useState(quotation?.tvaTx);
  const [ecoTaxe, setEcoTaxe] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState(1);

  const handleTopBackground = () => {
    setSelectedBackground(1);
  };

  const handleMidBackground = () => {
    setSelectedBackground(2);
  };

  const handleBotBackground = () => {
    setSelectedBackground(3);
  };

  const contentPop = (
    <>
      <div style={{ fontWeight: 'bold' }}>
        Montant de le promotion : {popOver.amount}%
      </div>
      <div style={{ fontWeight: 'bold' }}>Matieres concerné :</div>
      <div>
        {popOverMatter.map((el) => (
          <p>
            {el.label} {el.color} - {el.completions}
          </p>
        ))}
      </div>
    </>
  );

  const handlePopOver = async (e) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `profesionals/${e}?populate=promotion,promotion.matters`
      });
      setPopOver(data.promotion);
      setPopOverMatter(data.promotion.matters);
    } catch (err) {
      if (err.response) message(err.response.status);
    }
  };

  const handleTva = (id) => {
    const cat = tvaCategories.find(({ _id }) => _id === id);
    const tx = 1 + cat?.amount / 100 || 1;
    setTvaTx(tx);
  };

  const handleEcoTaxe = (amount) => {
    setEcoTaxe(amount);
  };

  const totalPrice = () => {
    const values = form.getFieldsValue();
    const discount = values.discount?.amount || 0;
    const quotlines = values.quotlines || [];
    const allElements = quotlines.map(({ elements }) => elements);
    const prices = allElements.flat().map(({ price }) => price);
    const price = prices.length && prices.reduce((acc, value) => acc + value);
    if (prices.length) {
      setTotal(price - discount);
    } else {
      setTotal(0);
    }
  };

  useEffect(() => {
    if (quotation.prices) {
      setTotal(quotation.prices.totalBrut - quotation.prices.discount);
    }
  }, [quotation]);

  return (
    <Spin spinning={isLoading}>
      <Form
        name="quotationForm"
        {...formItemLayout}
        onFinish={(values) => handleSubmit(values, '', total)}
        form={form}
        initialValues={{
          quotlines: quotation?.quotlines
        }}
      >
        <Row
          onFocus={handleTopBackground}
          style={selectedBackground === 1 ? { background: '#F0F2F5' } : ''}
        >
          <Col span={24}>
            <Row hidden>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div>
                  <span style={{ color: 'red' }}>*</span>
                  &nbsp;
                  {t(`quotation.crm`)}
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['crm']}
                  required
                  initialValue={location?.state?.crm}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name={['buildingSite']}
                  initialValue={location?.state?.buildingSite}
                >
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>

            <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
              Informations client
            </h2>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div>
                  <span style={{ color: 'red' }}>*</span>
                  &nbsp;
                  {t(`quotation.site`)}
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['site']}
                  required
                  initialValue={quotation.site || location?.state?.site?._id}
                >
                  <Select
                    allowClear
                    options={sites.map(({ label, _id }) => ({
                      label,
                      value: _id
                    }))}
                    loading={isLoading}
                    style={{ width: '300%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div>
                  <span style={{ color: 'red' }}>*</span>
                  &nbsp;
                  {t(`quotation.customer`)}
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['customer']}
                  required
                  initialValue={
                    quotation.customer || location.state?.customer._id
                  }
                >
                  <Select
                    allowClear
                    options={customers.map(
                      ({ name, first_name, last_name, _id }) => ({
                        label: name || `${first_name || ''} ${last_name}`,
                        value: _id
                      })
                    )}
                    loading={isLoading}
                    style={{ width: '300%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ textAlign: 'right' }}>
                <div>
                  &nbsp;
                  {t(`quotation.profesional`)}
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                </div>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['profesional']}
                  initialValue={
                    quotation.profesional || location.state?.profesional._id
                  }
                >
                  <Select
                    onChange={handlePopOver}
                    allowClear
                    options={profesionals.map((el) => ({
                      label: el.name,
                      value: el._id
                    }))}
                    loading={isLoading}
                    style={{ width: '300%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Popover content={contentPop} title="Promotions">
            <InfoCircleOutlined
              style={{
                float: 'right',
                marginRight: '3em',
                fontSize: '20px',
                marginLeft: '70%',
                paddingBottom: '1%'
              }}
            />
          </Popover>
        </Row>
        <Divider />
        <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Ajout Matériaux/Main d&#39oeuvre
        </h2>
        <Form.List name="quotlines">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Section onFocus={handleMidBackground}>
                  <Row>
                    <Col span={8} offset={8}>
                      <Form.Item name={[name, 'family']}>
                        <Select
                          options={familyOptions.map((el) => ({
                            labe: el,
                            value: el
                          }))}
                          style={{ width: '300%', marginTop: 20 }}
                          placeholder="Type de section ..."
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={8}>
                      <Form.Item name={[name, 'section']}>
                        <Input
                          placeholder="Libellé de section"
                          style={{ width: '300%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <SectionForm
                    totalPrice={totalPrice}
                    form={form}
                    comments={comments}
                    categories={categories}
                    resource={resource}
                    parentKey={key}
                    name={name}
                    t={t}
                    dispatchAPI={dispatchAPI}
                    initialElements={initialElements}
                    coefs={coefs}
                  />
                  <Row style={{ marginTop: -20 }}>
                    <Col
                      span={4}
                      offset={20}
                      style={{ display: 'flex', justifyContent: 'end' }}
                    >
                      <Button
                        type="link"
                        danger
                        onClick={() => remove(name)}
                        block
                        icon={<MinusOutlined />}
                      >
                        Supprimer section
                      </Button>
                    </Col>
                  </Row>
                </Section>
              ))}
              <Row>
                <Col span={24} offset={8}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Nouvelle section
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Col
          span={24}
          style={selectedBackground === 3 ? { background: '#F0F2F5' } : ''}
        >
          <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Remise et Taxes
          </h2>
          <Row onFocus={handleBotBackground}>
            <Col span={8} style={{ textAlign: 'right' }}>
              <div>Montant €/HT remise &nbsp;&nbsp;:&nbsp;&nbsp;</div>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['discount', 'amount']}
                initialValue={quotation?.discount?.amount}
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  onChange={totalPrice}
                  style={{ width: '300%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              <div>Commentaire remise &nbsp;&nbsp;:&nbsp;&nbsp;</div>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['discount', 'comment']}
                initialValue={quotation?.discount?.comment}
              >
                <div style={{ width: '300%' }}>
                  <TextArea />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              <div>
                &nbsp;
                {t(`quotation.TVA`)}
                &nbsp;&nbsp;:&nbsp;&nbsp;
              </div>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['tva']}
                rules={[{ required: true }]}
                initialValue={quotation?.tva}
              >
                <Select
                  onChange={handleTva}
                  allowClear
                  options={
                    tvaCategories &&
                    tvaCategories.map(({ label, _id }) => ({
                      label,
                      value: _id
                    }))
                  }
                  loading={isLoading}
                  style={{ width: '300%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              <div>Taxe Écologique &nbsp;&nbsp;:&nbsp;&nbsp;</div>
            </Col>
            <Col span={8}>
              <Form.Item name={['ecoTaxe']} initialValue={quotation.ecoTaxe}>
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  onChange={handleEcoTaxe}
                  style={{ width: '300%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        <Row style={{ color: 'white', background: 'black' }}>
          <Col
            span={4}
            offset={10}
            onClick={() => {
              setMode(totalMode === 'HT' ? 'TTC' : 'HT');
            }}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <strong style={{ color: 'white' }}>
              {t(`${resource}.form.total`)}
            </strong>
            <Title level={3} style={{ margin: 0, color: 'white' }}>
              {totalMode === 'HT'
                ? `${numeral(total).format('0,0.00')}€`
                : `${numeral(total * tvaTx + ecoTaxe).format('0,0.00')}€`}
            </Title>
            <Button style={{ color: 'white' }} type="link">
              {totalMode}
            </Button>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={4} offset={10}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{
                  float: 'right',
                  margin: '1em',
                  color: 'white',
                  background: 'black',
                  border: 'black'
                }}
                type="add"
                htmlType="submit"
              >
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
              <Button
                style={{ float: 'right', margin: '1em' }}
                type="link"
                danger
                onClick={() => history.goBack()}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

QuotationForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  coefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      buildingSite: PropTypes.any,
      crm: PropTypes.any,
      customer: PropTypes.shape({
        _id: PropTypes.any
      }),
      profesional: PropTypes.shape({
        _id: PropTypes.any
      }),
      site: PropTypes.shape({
        _id: PropTypes.any
      })
    })
  }).isRequired,
  profesionals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  quotation: PropTypes.shape({
    customer: PropTypes.any,
    discount: PropTypes.shape({
      amount: PropTypes.any,
      comment: PropTypes.any
    }),
    ecoTaxe: PropTypes.any,
    prices: PropTypes.shape({
      discount: PropTypes.any,
      totalBrut: PropTypes.any
    }),
    profesional: PropTypes.any,
    quotlines: PropTypes.any,
    site: PropTypes.any,
    tva: PropTypes.any,
    tvaTx: PropTypes.any
  }).isRequired,
  resource: PropTypes.shape({}).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tvaCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default QuotationForm;
