import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, InputNumber, Select, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [areFieldsLoading, setAreFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);

  const fields = [
    {
      name: ['wording'],
      label: 'wording',
      rules: [{ required: true }]
    },
    {
      name: ['id_supplier'],
      label: 'supplier',
      rules: [{ required: true }],
      input: (
        <Select loading={areFieldsLoading}>
          {companies.map((c) => {
            if (c.type === 'SUPPLIERS') {
              return (
                <Option key={c._id} value={c._id}>
                  {c.name}
                </Option>
              );
            }
            return <></>;
          })}
        </Select>
      )
    },
    {
      name: ['amount_ht'],
      label: 'amount',
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['desc'],
      input: <TextArea />
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
    },
    {
      name: ['category'],
      input: (
        <Select loading={areFieldsLoading}>
          {categories.map((c) => (
            <Option key={c._id} value={c._id}>
              <Tag color={c.color}>{c.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['tva'],
      input: (
        <Select allowClear>
          <Option value="normal"> taux normal : 20 %</Option>
          <Option value="intermediaire">taux intermédiaire : 10 %</Option>
          <Option value="reduit">taux réduit : 5,5 %</Option>
          <Option value="particulier">taux particulier : 2,1 %</Option>
        </Select>
      )
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/categories' });
      setCategories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setAreFieldsLoading(true);
    await getEnums();
    await getCompanies();
    await getCategories();
    setAreFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    areFieldsLoading
  };
};

export default useFields;
