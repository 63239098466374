import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { enumsStatus, colorTag } from '../../../utils/constants/tagColors';
import ListInvoicesGenerateFile from './ListInvoicesGenerateFile';
import AccountingTag from './AccountingCode/AccountingTag';

const findInvoice = (element) => {
  const foundObject = element?.payments.find(
    (obj) => obj.is_basic_invoice === true
  );
  return foundObject;
};

const useColumns = (enums) => {
  const { t } = useTranslation();
  let foundObject = {};

  return [
    {
      title: t('invoices.column.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('invoices.column.type_of_invoices'),
      dataIndex: 'is_advance_payment',
      key: 'is_advance_payment',
      sorter: true,
      render: (_, data) => {
        switch (true) {
          case data.is_credit_note:
            return t('invoices.column.type.creditNote');
          case data.is_advance_payment:
            return t('invoices.column.type.advance');

          default:
            return t('invoices.column.type.basic');
        }
      }
    },
    {
      title: t('invoices.column.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={colorTag[status]}>{enumsStatus[status]}</Tag>
      ),
      sorter: true,
      filters:
        enums &&
        enums.orderStatus2?.map((label) => ({
          text: <Tag color={colorTag[label]}>{enumsStatus[label]}</Tag>,
          value: label
        }))
    },
    {
      title: t('invoices.column.customer'),
      dataIndex: 'infos',
      key: 'infos',
      render: (infos) =>
        infos?.customer
          ? `${infos.customer?.last_name || ''} ${infos.customer?.first_name ||
              ''}`
          : '',
      sorter: true
    },
    {
      title: t('invoices.column.total'),
      dataIndex: 'totals',
      key: 'totals',
      render: (_, data) => {
        switch (true) {
          case data.is_credit_note:
            return ` ${numeral(data?.totals?.totalsTTC).format(
              '0,0.00'
            )} € TTC`;
          case data.is_advance_payment:
            return `${numeral(data?.totals?.totalsTTC).format('0,0.00')} € TTC`;

          default:
            foundObject = findInvoice(data);

            if (foundObject === undefined) {
              const paymentAmount = data.payments[0]?.amount;

              return data.payments[0]?.amount
                ? `${numeral(data?.totals?.totalsTTC - paymentAmount).format(
                    '0,0.00'
                  )} € TTC`
                : `${numeral(data?.totals?.totalsTTC).format('0,0.00')} € TTC`;
            }
            return `${numeral(foundObject.amount).format('0,0.00')} € TTC`;
        }
      },
      sorter: true
    },
    {
      title: t('invoices.column.emission_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.column.is_accounting_code.title'),
      dataIndex: 'is_accounting_code',
      key: 'is_accounting_code',
      sorter: true,
      render: (_, data) => <AccountingTag invoice={data} />
    },
    {
      key: 'action',
      render: (data) => <ListInvoicesGenerateFile data={data} />
    }
  ];
};

export default useColumns;
