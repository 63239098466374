import React from 'react';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './columns';

const ListArticles = () => {
  const columns = useColumns();

  const headers = [];

  return (
    <SimpleResourceLandingLayout
      resourceName="sites"
      columns={columns}
      withSubRoutes
      headers={headers}
      resourceModelName="Site"
    />
  );
};

export default ListArticles;
