import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { enumsType } from '../../utils/constants/tagColors';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sites, setSites] = useState([]);
  const types = ['Matière Première', 'Consommable', 'Machine-Maintenance'];
  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
    })();
  }, []);

  return [
    {
      title: t('commercial.profesionals.column.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('commercial.profesionals.column.type'),
      dataIndex: 'type',
      render: (type) => enumsType[type],
      sorter: true,
      filters:
        types &&
        types?.map((type) => ({
          text: type,
          value: type
        }))
    },
    {
      title: t('commercial.profesionals.column.address'),
      dataIndex: 'address',
      render: (ad) => ad && ad.city,
      sorter: true
    },
    {
      title: t('commercial.profesionals.column.email'),
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('commercial.profesionals.column.contacts'),
      dataIndex: 'contacts',
      render: (contact) => contact && contact.length,
      sorter: true
    }
  ];
};

export default useColumns;
