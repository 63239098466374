import React from 'react';
import { Modal, Form, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

export const ConsumableModal = ({
  consumables,
  setDataSource,
  isModalConsumableVisible,
  setIsModalConsumVisible
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleCancel = () => {
    setIsModalConsumVisible(false);
  };

  const onFinish = (values) => {
    const selectedConsumable = consumables.find(
      (consumable) => consumable._id === values.consumable
    );

    setDataSource((prev) => {
      const updatedState = [...prev];
      updatedState.push({
        ...selectedConsumable,
        tint: '',
        expect: true
      });
      return updatedState;
    });

    form.resetFields();
    handleCancel();
  };

  const options = (consumables || []).map((consumable) => ({
    label: consumable.label,
    value: consumable._id
  }));

  return (
    <Modal
      title={t('laysheet.form.buttons.add.consumable')}
      visible={isModalConsumableVisible}
      onOk={() => {}}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={['consumable']}
          label={t('laysheet.columns.consumable.consumable')}
        >
          <Select options={options} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button danger style={{ marginRight: 20 }} onClick={handleCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.add')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ConsumableModal.propTypes = {
  consumables: PropTypes.arrayOf(PropTypes.shape({})),
  setDataSource: PropTypes.func,
  isModalConsumableVisible: PropTypes.bool,
  setIsModalConsumVisible: PropTypes.func
};

ConsumableModal.defaultProps = {
  consumables: null,
  setDataSource: null,
  isModalConsumableVisible: undefined,
  setIsModalConsumVisible: null
};
