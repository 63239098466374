import React from 'react';
import { Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { TagFilled } from '@ant-design/icons';

const PriorityTag = ({ priority, includeLabel = false }) => {
  const priorityTag = () => {
    let color;
    let label;
    switch (priority) {
      case 'MEDIUM':
        color = 'blue';
        label = 'Normale';
        break;
      case 'HIGH':
        color = 'orange';
        label = 'Haute';
        break;
      case 'URGENT':
        color = 'red';
        label = 'Urgente';
        break;
      default:
        color = 'lightBlue';
        label = 'Faible';
        break;
    }

    return (
      <Tooltip title={label}>
        <Row align="middle">
          <TagFilled style={{ color: `${color}` }} />
          &nbsp;
          <b>{includeLabel && label}</b>
        </Row>
      </Tooltip>
    );
  };

  return priorityTag();
};

PriorityTag.propTypes = {
  priority: PropTypes.string.isRequired
};

export default PriorityTag;
