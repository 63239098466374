import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('stocks.matter'),
      dataIndex: 'matter',
      render: (matter) => <Tag>{matter?.label}</Tag>,
      sorter: true
    },
    {
      title: t('stocks.supplier'),
      dataIndex: 'supplier',
      render: (supplier) => supplier?.name || ''
    },
    {
      title: t('catalogues.column.label'),
      dataIndex: 'matter',
      sorter: true,
      render: (matter) => matter?.color || ''
    },
    {
      title: t('stocks.batch_number'),
      dataIndex: 'batch_number',
      sorter: true
    },
    {
      title: t('stocks.finition'),
      dataIndex: 'matter',
      sorter: true,
      render: (matter) => matter?.completions || ''
    },
    {
      title: t('stocks.dimensions'),
      dataIndex: 'sizes',
      render: (sizes) => `${sizes[0]?.length} x ${sizes[0]?.width}`
    },
    {
      title: t('stocks.thickness'),
      dataIndex: 'thickness',
      sorter: true
    }
  ];
};

export default useColumns;
