import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;
const useFields = (visible) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [deals, setDeals] = useState([]);
  const [companies, setCompanies] = useState([]);
  const fields = [
    {
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.genders || []).map((el) => (
            <option value={el}>{el}</option>
          ))}
        </Select>
      )
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.types || []).map((el) => (
            <option value={el}>{el}</option>
          ))}
        </Select>
      )
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_1'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_2']
    },
    {
      name: ['contact_details'],
      rules: [{ required: false }],
      input: <TextArea rows={4} />
    }
  ];

  const getDeals = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deals' });
      setDeals(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getDeals();
    setIsFieldsLoading(false);
  }, [visible]);
  useEffect(() => {
    if (visible)
      (async () => {
        await getSelectOptions();
      })();
  }, [getSelectOptions]);
  return { fields, isFieldsLoading };
};
export default useFields;
