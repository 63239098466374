import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);

  const fields = [
    {
      label: 'gender',
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'company',
      name: ['my_company'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {companies.map((company) => (
            <Option key={company._id} value={company._id}>
              {company.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['position'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_1'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_2']
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
