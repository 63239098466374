import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { WorksheetsColumns } from './Columns/WorksheetsColumns';

const WorkSheet = ({ order }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [datas, setDatas] = useState({});
  const [refresh, setrefresh] = useState(false);

  const getDatas = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksheets?order=${order._id}&populate=matter,customer`
      });
      setDatas(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getDatas();
  }, [order, refresh]);

  const onDelete = async (key) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/worksheets/${key}`
      });
      setrefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = WorksheetsColumns(order, onDelete);

  if (Object.keys(datas).length) {
    return <Table dataSource={datas} columns={columns} pagination={false} />;
  }
  return null;
};

WorkSheet.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.any,
    number: PropTypes.any
  }).isRequired
};

export default WorkSheet;
