import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import FinitionsTable from './finitionsTable';

const FinitionsSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [finitions, setFinitions] = useState([]);

  const fetchFinitions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Finitions`
      });
      setFinitions(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addFinition = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Finitions', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchFinitions();
  };

  const deleteFinition = async (finition) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-Finitions/${finition._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.finitions_delete'));
    }
    fetchFinitions();
  };

  const editFinition = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-Finitions/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchFinitions();
  };

  useEffect(() => {
    fetchFinitions();
  }, []);

  return (
    <>
      {finitions.length > 0 && (
        <FinitionsTable
          finitions={finitions}
          editFinition={editFinition}
          deleteFinition={deleteFinition}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addFinition}
      >
        {t('settings.finitions.addFinition')}
      </Button>
    </>
  );
};

export default FinitionsSettings;
