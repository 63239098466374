import React from 'react';
import { Layout, PageHeader, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { ConfigurationContextProvider } from './ConfigurationContext';
import { usePanels } from './Panels';

const { Content } = Layout;
const { Panel } = Collapse;

const Settings = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const panels = usePanels();
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/parameters',
      breadcrumbName: 'Paramètres'
    }
  ];

  return (
    <ConfigurationContextProvider>
      <PageHeader
        breadcrumb={{
          routes,
          // eslint-disable-next-line react/prop-types
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        title={t('settings.title')}
        onBack={() => window.history.back()}
        ghost={false}
      />
      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Collapse accordion>
          {panels.map(
            (panel) =>
              user &&
              (user.permission.grant.includes('SETTINGS.*') ||
                user.permission.grant.includes(panel.permission)) && (
                <Panel key={panel.title} header={panel.title}>
                  {panel.element}
                </Panel>
              )
          )}
        </Collapse>
      </Content>
    </ConfigurationContextProvider>
  );
};

export default Settings;
