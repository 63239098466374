import React from 'react';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const invoiceTypes = {
  PAY: 'blue',
  DEPOSIT: 'green',
  CREDIT: 'magenta'
};

export const ticketStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const dailycardColor = {
  EXPERTISE: '#5cdbd3',
  DEMONSTRATION: '#faad14',
  INTERVENTION: '#1890ff',
  'LEVER DE RESERVE': '#722ed1',
  SAV: '#f5222d',
  'ENTRETIEN CAMION': '#fa8c16'
};

export const ticketsPriorities = ['NORMAL', 'HIGH', 'URGENT'];

export const ticketsPrioritiesColor = {
  LOW: 'blue',
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsTypes = ['PICKUP', 'THEFT', 'LOSS', 'DAMAGED'];

export const ticketsTypesColor = {
  PICKUP: 'green',
  THEFT: 'volcano',
  LOSS: 'cyan',
  DAMAGED: 'red'
};

export const tasksStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  CLOSED: 'red'
};

export const tasksPriorities = {
  URGENT: 'red',
  HIGH: 'orange',
  NORMAL: 'blue',
  LOW: 'lightblue'
};

export const projectsStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const companiesTypes = {
  CUSTOMERS: 'blue',
  SUPPLIERS: 'green'
};

export const userPermissions = {
  devTeam: 'yellow'
};

export const dealsPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const mattersLabels = {
  ARDOISE: 'red',
  BASALTE: 'yellow',
  CÉRAMIQUE: 'cyan',
  DEKTON: 'purple',
  SILESTONE: 'green',
  QUARTZITE: 'blue',
  GRANIT: 'orange',
  MARBRE: 'volcano',
  ONYX: 'lime'
};

export const typeCatalogues = {
  DARTY: 'red',
  ATELIER_DU_MARBRE: 'blue',
  GLOBAL: 'cyan'
};
export const categoryLabors = {
  measure: 'red',
  lay: 'blue',
  other: 'cyan',
  ecotax: 'purple',
  displacement: 'gold'
};
export const categoryShaping = {
  'Finition Bords': 'default',
  'Mise en forme': 'gold',
  Finitions: 'pink',
  Décaissé: 'purple',
  Défoncement: 'green',
  Découpe: 'orange',
  Perçage: 'volcano'
};
export const categoryProcutions = {
  USINAGES: 'red'
};
export const categoryProducts = {
  PROTECTION: 'green',
  NETTOYAGE: 'purple',
  ENTRETIEN: 'gold'
};
export const profesionalsTypes = {
  TERRASON: 'blue',
  PESSAC: 'cyan',
  LIMOGES: 'purple'
};
export const customersTypes = {
  PARTICULIER: 'blue',
  ENTREPRISE: 'green'
};

export const customerTypes = {
  PARTICULIER: 'blue',
  ENTREPRISE: 'green',
  HORS_ASSURANTIEL: 'purple'
};

export const profesionalTypes = {
  EXPERT: 'blue',
  ASSURANCE: 'cyan',
  PLATEFORME_ASSURANCE: 'purple'
};

export const sitesTypes = {
  Terrason: 'red',
  Pessac: 'green'
};

export const enumsCaterogy = {
  MATTERS: 'Matières',
  LABORS: "Main-d'oeuvre",
  PRODUCTIONS: 'Productions',
  PRODUCTS: 'Produits',
  SHAPINGS: 'Façonnage',
  OTHERS: 'Divers'
};

export const ordersPurchase = {
  NATURAL: 'NATUREL',
  SYNTHETIC: 'SYNTHETIQUE'
};

export const enumsStatus = {
  VALIDATE: 'VALIDÉ',
  WAITING_FOR_SIGNATURE: 'EN ATTENTE DE SIGNATURE',
  REJECTED: 'REFUSÉ',
  PENDING: 'EN ATTENTE',
  DROPPED: 'ABANDONNÉ',
  DELETED: 'SUPPRIMÉ',
  WAITING: 'EN ATTENTE',
  INVOICED: 'FACTURÉ',
  'WAYTING FOR PAYMENT': 'EN ATTENTE DE PAIEMENT',
  'PAYE PROFESIONAL': 'PROFESSIONNEL PAYER',
  PAYED: 'PAYÉ',
  DRAFT: 'BROUILLON'
};

export const colorTag = {
  'WAYTING FOR PAYMENT': 'red',
  PAYED: 'green'
};

export const enumsType = {
  'Raw Material': 'Matière Première',
  consumable: 'Consommable',
  'Machine-Maintenance': 'Machine-Maintenance'
};

export const quoteStatus = {
  VALIDATE: 'green',
  WAITING_FOR_SIGNATURE: 'orange',
  DRAFT: 'blue',
  DROPPED: 'red',
  REJECTED: 'red',
  PENDING: 'red'
};

export const orderStatus = {
  WAITING: 'default',
  INVOICED: 'orange',
  VALIDATE: 'green'
};

export const iconOrderStatus = {
  WAITING: <ClockCircleOutlined />,
  VALIDATE: <CheckCircleOutlined />
};

export const colorsType = {
  GREEN: 'green',
  RED: 'red',
  BLUE: 'blue',
  YELLOW: 'yellow',
  PURPLE: 'purple',
  BROWN: 'brown',
  ORANGE: 'orange',
  BLACK: 'black'
};

export const statusPieces = {
  DISPONIBLE: 'green',
  IMMOBILISÉ: 'blue',
  REBUT: 'red'
};
