import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Tag, Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import {
  enumsStatus,
  quoteStatus,
  orderStatus
} from '../../utils/constants/tagColors';

const iconStyle = { color: 'var(--primaryColor)', fontSize: 18 };

const useColumns = (type) => {
  const { t } = useTranslation();
  const [enumsQuotes, setEnumsQuotes] = useState([]);
  const [enumsOrders, setEnumsOrders] = useState([]);

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnumsQuotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotes/enums`
      });
      setEnumsQuotes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getEnumsOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ordersnew/enums`
      });
      setEnumsOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getEnumsQuotes();
    getEnumsOrders();
  }, []);

  const quotesCols = [
    {
      title: t('quotes.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('quotes.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={quoteStatus[status]}>{enumsStatus[status]}</Tag>
      ),
      sorter: true,
      filters:
        enumsQuotes.status &&
        enumsQuotes.status
          .filter((it) => it !== 'DELETED')
          .map((el) => ({
            text: enumsStatus[el],
            value: el
          }))
    },
    {
      title: t('quotes.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('quotes.total'),
      dataIndex: 'totals',
      render: ({ totalClient }) =>
        `${numeral(totalClient).format('0,0.00')} € HT`,
      sorter: true
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/customers_accounting/quotes/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>
            <Divider type="vertical" />
          </span>
        );
      }
    }
  ];

  const renderStatus = (value) => {
    if (type === 'invoices') {
      return (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'PAYÉ' : 'EN ATTENTE DE PAIEMENT'}
        </Tag>
      );
    }
    return <Tag color={orderStatus[value]}>{enumsStatus[value]}</Tag>;
  };

  const filterStatus = () => {
    if (type === 'invoices') {
      return ['PAYÉ', 'EN ATTENTE DE PAIEMENT'].map((el, i) => ({
        text: el,
        value: i === 0
      }));
    }
    if (enumsOrders.status) {
      return enumsOrders.status
        .filter((it) => it !== 'DELETED')
        .map((el) => ({
          text: enumsStatus[el],
          value: el
        }));
    }
  };

  const ordersCols = [
    {
      title: t('quotes.number'),
      dataIndex: type === 'invoices' ? 'invoice_number' : 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('quotes.status'),
      dataIndex: type === 'invoices' ? 'payed' : 'status',
      key: 'status',
      render: (value) => renderStatus(value),
      sorter: true,
      filters: filterStatus()
    },
    {
      title: t('quotes.created_at'),
      dataIndex: type === 'invoices' ? 'invoice_date' : 'created_at',
      key: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('quotes.total'),
      dataIndex: 'totals',
      render: ({ totalClient }) =>
        `${numeral(totalClient).format('0,0.00')} € HT`,
      sorter: true
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => {
        return (
          <span style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `/customers_accounting/${type}/show/${key}`,
                state: { quoteId: key }
              }}
            >
              <EyeOutlined style={iconStyle} />
            </Link>
          </span>
        );
      }
    }
  ];
  return { ordersCols, quotesCols };
};

export default useColumns;
