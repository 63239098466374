import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Table, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const PieceTable = ({ data, refresh }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteResource = async (piece, key) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/pieces/${piece._id}`,
        body: {
          sizes: piece.sizes.filter((size) => size._id !== key._id)
        }
      });
      refresh();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t('stocks.number'),
      dataIndex: 'number'
    },
    {
      title: t('stocks.type'),
      dataIndex: 'type'
    },
    {
      title: t('stocks.entry_date'),
      dataIndex: 'entry_date',
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('stocks.exit_date'),
      dataIndex: 'exit_date',
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : '')
    },
    {
      title: t('stocks.person_in_charge'),
      dataIndex: 'person_in_charge'
    },
    {
      title: t('stocks.order'),
      dataIndex: 'order',
      render: (order) => order?.number
    },
    {
      title: t('stocks.is_rebut'),
      dataIndex: 'is_rebut',
      render: (isRebut) => (isRebut ? 'Oui' : 'Non')
    },
    {
      title: t('stocks.shape'),
      dataIndex: 'shape'
    },
    {
      title: t('stocks.length'),
      dataIndex: 'length'
    },
    {
      title: t('stocks.width'),
      dataIndex: 'width'
    }
  ];

  const actionColumn = [
    {
      key: 'action',
      dataIndex: 'piece',
      render: (piece, key) => (
        <div style={{ float: 'right' }}>
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(piece, key)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined style={{ color: 'red' }} type="delete" />
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Table
      pagination={false}
      columns={canEditResource(path) ? [...columns, ...actionColumn] : columns}
      scroll={{ x: 800 }}
      dataSource={(data?.sizes || [])?.map((size) => {
        return { ...size, piece: data };
      })}
    />
  );
};

PieceTable.propTypes = {
  data: PropTypes.shape({
    sizes: PropTypes.shape({
      map: PropTypes.func
    }),
    status: PropTypes.any
  }).isRequired,
  refresh: PropTypes.func.isRequired
};

export default PieceTable;
