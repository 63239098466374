import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import MarbleBackground from '../../assets/images/white-stone_Scott-Webb.jpg';

import useLaysheetContext from './LaysheetContext';
import { LaysheetButtons } from './Buttons/LaysheetButtons';
import { SelectionCard } from './LaysheetCard/SelectionCard';
import { LaysheetForm } from './LaysheetForm';

const LaySheet = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { getWorksheet, getOrder, state } = useLaysheetContext();

  const [isValide, setIsValide] = useState(false);
  const [isValidDefinitevly, setIsValidDefinitevly] = useState(false);
  const [formAssociate] = Form.useForm();

  useEffect(() => {
    if (state?.laysheet && state.laysheet.associateWorkSheet) {
      getWorksheet(state.laysheet.associateWorkSheet);
      getOrder(state.laysheet.order);
    }
  }, [state]);

  const updateLayDate = async (data) => {
    const method = 'PATCH';
    const body = {
      placed: {
        lay_date: data?.delivery_date?._d,
        measurement_date: data?.measurement_date?._d
      }
    };
    try {
      const url = `/ordersnew/${state.laysheet.order}`;
      await dispatchAPI(method, {
        url,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
    try {
      const url = `/worksheets/${
        formAssociate.getFieldValue()?.associateWorkSheet?.value
      }`;
      delete body.placed;
      body.date = data?.delivery_date?._d;
      await dispatchAPI(method, {
        url,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.message);
    }
  };

  const handleValideDefini = async (isValidate) => {
    if (isValide) {
      const values = formAssociate.getFieldsValue();

      const filteredAccessories = (values.accessories || []).filter((obj) => {
        // Obtenir toutes les clés de l'objet où la valeur n'est pas 'undefined', à l'exception de '_id'
        const keysWithDefinedValues = Object.keys(obj).filter(
          (key) => obj[key] && key !== '_id'
        );

        // Conserver l'objet s'il y a plus d'une clé avec une valeur définie (en excluant '_id')
        return keysWithDefinedValues.length > 1;
      });

      const body = {
        ...values,
        accessories: filteredAccessories,
        associateWorkSheet: values.associateWorkSheet.value,
        numberWorkSheet: values.associateWorkSheet.label.toString(),
        isDefinitively: isValidate,
        utils: values.utils || []
      };
      let key;
      if (state.datas) {
        body.order = state.datas?._id;
        key = state.datas._id;
      } else key = state.laysheet.order;

      const method = state.laysheet ? 'PATCH' : 'POST';
      const url = state.laysheet
        ? `/laysheets/${state.laysheet._id}`
        : '/laysheets';
      try {
        await dispatchAPI(method, {
          url,
          body
        });
        history.push(`/customers_accounting/orders_sales/show/${key}`);
      } catch (e) {
        if (e.response) message.error(e.response.message);
      }
      updateLayDate(body);
      setIsValidDefinitevly(isValidate);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('laysheet.order.number', {
          number: state?.datas ? state.datas.number : state?.laysheet.number
        })}
        extra={
          <LaysheetButtons
            isValidDefinitevly={isValidDefinitevly}
            isValide={isValide}
            save={() => handleValideDefini(false)}
            onValidate={() => handleValideDefini(true)}
          />
        }
      />
      <div
        style={{
          backgroundImage: `url(${MarbleBackground})`,
          backgroundSize: 'cover',
          minHeight: 'calc(100vh - 200px)'
        }}
      >
        <Form
          form={formAssociate}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            hidden
            initialValue={
              state?.datas ? state.datas.number : state?.laysheet.number
            }
            name={['number']}
          />
          <SelectionCard
            form={formAssociate}
            isValidDefinitevly={isValidDefinitevly}
            isValide={isValide}
            setIsValide={setIsValide}
          />
          <LaysheetForm
            isValide={isValide}
            setIsValide={setIsValide}
            form={formAssociate}
            isValidDefinitevly={isValidDefinitevly}
            setIsValidDefinitevly={setIsValidDefinitevly}
            handleValideDefini={handleValideDefini}
          />
        </Form>
      </div>
    </>
  );
};

export default LaySheet;
