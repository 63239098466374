import React from 'react';
import useOrderContext from '../OrderContext';
import useColumns from './columns';
import headers from './headers';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const List = () => {
  const { collection, refresh, type, sites } = useOrderContext();
  const columns = useColumns();
  let extraQuery = '';
  let tradKey = 'Commandes Clients';
  if (type === 'invoices') {
    extraQuery = 'status=INVOICED';
    tradKey = 'Factures Clients';
  }

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName={collection}
      extraQuery={extraQuery}
      headers={headers}
      withUploadButton={extraQuery === ''}
      customActionColumn
      forceRefresh={refresh}
      withCreateButton={false}
      tradKey={tradKey}
      sites={sites}
      isOrder={tradKey === 'Commandes Clients'}
    />
  );
};

export default List;
