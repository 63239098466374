import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useAuthContext from '../../contexts/AuthContext';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  return [
    {
      title: t('sav.label'),
      dataIndex: 'label'
    },
    {
      title: t('sav.order'),
      dataIndex: 'order',
      render: (order) => order?.number
    },
    {
      title: t('sav.buildingSite'),
      dataIndex: 'buildingSite',
      render: (buildingSite) => buildingSite?.label
    },
    {
      title: t('sav.site'),
      dataIndex: 'site',
      render: (site) => site?.label
    }
  ];
};

export default useColumns;
