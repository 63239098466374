import numeral from 'numeral';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Row, Select, InputNumber, Radio } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const ListShapings = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const onOk = async () => {
    try {
      const body = form.getFieldsValue();
      const category = body.shaping;
      await dispatchAPI('PATCH', { url: `/shapings`, body });
      await dispatchAPI('POST', {
        url: `/shapings/timeline/${category}`,
        body: {
          status:
            body.direction === 'DEA'
              ? `Baisse de prix de ${body.price}%`
              : `Augmentation de prix de ${body.price}%`,
          date: moment(),
          by: user._id
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatchAPI('GET', {
          url: `/shapings`
        });
        setData(response.data);
      } catch (e) {
        if (e) message(e);
      }
    })();
  }, []);

  const extraButtons = (
    <>
      <Form form={form}>
        <Modal
          title="Changer le prix"
          visible={isVisible}
          onOk={onOk}
          onCancel={() => setIsVisible(false)}
        >
          <Row align="middle">
            <Form.Item
              name="shaping"
              label="Façonnages"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '100%', minWidth: '200px' }}
                options={[
                  ...new Set(data?.map(({ category }) => category))
                ].map((category) => ({ category, value: category }))}
              />
            </Form.Item>
          </Row>
          <Row align="middle">
            <Form.Item
              name="direction"
              label="Prix"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="INC">Augmentation du prix</Radio>
                <Radio value="DEA">Baisse du prix</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row align="middle">
            <Form.Item
              name="price"
              label="Changement du prix"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) => (value ? `${value} %` : '')}
                style={{ width: '100%', minWidth: '100px' }}
                min={0}
                max={100}
              />
            </Form.Item>
          </Row>
        </Modal>
      </Form>
      <Button
        style={{
          background: 'black',
          border: 'black',
          color: 'white',
          marginRight: 10
        }}
        onClick={() => setIsVisible(true)}
      >
        {`${t('buttons.price')}`}
        <EditOutlined />
      </Button>
    </>
  );

  const headers = [
    {
      label: t('catalogues.column.ref'),
      key: 'ref'
    },
    {
      label: t('catalogues.column.label'),
      key: 'label'
    },
    {
      label: t('catalogues.column.category'),
      key: 'category'
    },
    {
      label: t('catalogues.column.sold_price'),
      key: 'sold_price',
      render: (sold_price) => `${numeral(sold_price).format('0,0.00')} €`
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="shapings"
      columns={[...columns()]}
      withSubRoutes
      withImportButton
      headers={headers}
      resourceModelName="Shaping"
      extraButtons={extraButtons}
    />
  );
};

export default ListShapings;
