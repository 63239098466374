import PropTypes from 'prop-types';
import React from 'react';
import { Popconfirm, Button } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DangerPopconfirm = ({ deleteRessource, id }) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={t('datatable.column.action.delete.title')}
      onConfirm={() => deleteRessource(id)}
      okText={t('datatable.column.action.delete.ok')}
      cancelText={t('datatable.column.action.delete.cancel')}
      okType="danger"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <Button danger>
        <span>{t('buttons.delete')}</span> <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
};

DangerPopconfirm.propTypes = {
  deleteRessource: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default DangerPopconfirm;
