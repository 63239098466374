import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DrowpdownDownloadButton } from '../../../components/DropdownDonwloadButton/DropdownDownloadButtonQuotation';

const DownloadGeneratedFile = ({
  element,
  template,
  downloadFile,
  details,
  type
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const text = () => {
    switch (type) {
      case 'quote':
        return `Générer le devis ${details ? 'détaillé' : 'sans détails'}`;
      default:
        return 'Générer';
    }
  };

  return (
    <>
      {template && (
        <DrowpdownDownloadButton
          recordKey={element._id}
          number={element.number}
          isDownloading={isDownloading}
          setIsDownloading={setIsDownloading}
          downloadFile={downloadFile}
          details={details}
          buttonText={text()}
        />
      )}
    </>
  );
};

DownloadGeneratedFile.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.string,
    section_1: PropTypes.shape({})
  }).isRequired,
  template: PropTypes.shape({}).isRequired,
  downloadFile: PropTypes.func.isRequired,
  details: PropTypes.bool,
  type: PropTypes.string.isRequired
};

DownloadGeneratedFile.defaultProps = {
  details: true
};

export default DownloadGeneratedFile;
