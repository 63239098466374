import React from 'react';
import { Row, Col, Form, InputNumber, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useConfiguratorContext from './ConfiguratorContext';

const EcoTaxForm = () => {
  const { t } = useTranslation();
  const { currentStep } = useConfiguratorContext();

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ display: currentStep === 4 ? '' : 'none' }}
      >
        {t('configurator.ecotax')}
      </Typography.Title>
      <div className="quotation-form-fields-wrapper">
        <Row>
          <Col span={10} offset={2}>
            <Form.Item
              label={t('configurator.cook_supp_120')}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              name={['ecotax', 'cook_supp_120']}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('configurator.cook_inf_120')}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              name={['ecotax', 'cook_inf_120']}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('configurator.table_plate')}
              name={['ecotax', 'table_plate']}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('configurator.furniture')}
              name={['ecotax', 'furniture']}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('configurator.credence')}
              name={['ecotax', 'credence']}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('configurator.bathroom')}
              name={['ecotax', 'bathroom']}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EcoTaxForm;
