import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import {
  typeCatalogues,
  categoryLabors
} from '../../../utils/constants/tagColors';
import DartyPrice from '../dartyPrice';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const basicFields = [
    {
      name: ['ref'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['target'],
      input: (
        <Select loading={isFieldsLoading}>
          {enums?.targets?.map((el) => (
            <Option key={el} value={el}>
              <Tag color={typeCatalogues[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['category'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {enums?.categories?.map((el) => (
            <Option key={el} value={el}>
              <Tag color={categoryLabors[el]}>{t(`labors.enums.${el}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['sold_price'],
      input: <InputNumber />
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <DartyPrice />
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/labors/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
