/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/charts';

const PieQuotationTypeCustomer = ({ quotes }) => {
  let professionnels = 0;
  let particuliers = 0;
  const [professionnel, setProfessionnel] = useState(0);
  const [particulier, setParticulier] = useState(0);

  useEffect(() => {
    if (quotes.length) {
      quotes.map((quote) => {
        if (quote.infos.profesional) professionnels += 1;
        else particuliers += 1;
      });
      if (quotes.length) {
        setProfessionnel(parseInt((professionnels / quotes.length) * 100, 0));
        setParticulier(parseInt((particuliers / quotes.length) * 100, 0));
      }
    } else {
      setProfessionnel(0);
      setParticulier(0);
    }
  }, [quotes]);
  const datas = [
    {
      type: 'PROFESSIONNELS',
      value: professionnel
    },
    {
      type: 'PARTICULIERS',
      value: particulier
    }
  ];
  const config = {
    width: 300,
    height: 300,
    appendPadding: 10,
    data: datas,
    angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'PROFESSIONNELS') {
        return 'gray';
      }
      return 'black';
    },
    radius: 1,
    innerRadius: 0.75,
    label: {
      type: 'inner',
      offset: '-50%',
      style: { textAlign: 'center' },
      autoRotate: false,
      content: '{value} %'
    },
    interactions: [{ type: 'element-selected' }],
    statistic: {
      title: false,
      content: false
    }
  };
  return <Pie {...config} />;
};

PieQuotationTypeCustomer.propTypes = {
  quotes: PropTypes.shape().isRequired
};

export default PieQuotationTypeCustomer;
