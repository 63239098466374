import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import numeral from 'numeral';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { enumsStatus } from '../../utils/constants/tagColors';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [, setEnums] = useState([]);

  const format = (value) => numeral(value).format('0,0[.]00 $');

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/commissions/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const resultComPro = (data) => {
    const {
      totalMatters,
      totalShapings,
      totalsAccessories,
      totalPro,
      comPro
    } = data;
    let result = 0;
    if (data && data.comWithLay) {
      result = totalPro * (comPro / 100);
    } else {
      const fixComPro = totalMatters + totalShapings + totalsAccessories;
      result = fixComPro - fixComPro / ((100 + comPro) / 100);
    }
    return result;
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('commission.profesional'),
      dataIndex: 'infos',
      render: (infos) => {
        return (infos && infos?.profesional && infos?.profesional?.name) || '-';
      }
    },
    {
      title: t('commission.status'),
      dataIndex: 'status',
      key: 'status',
      render: (renderedEnums) => enumsStatus[renderedEnums],
      sorter: true
    },
    {
      title: t('commission.order'),
      dataIndex: 'order',
      render: (order) => {
        return (order && order[0]?.number) || '-';
      }
    },
    {
      title: t('commission.amountV'),
      dataIndex: 'totals',
      render: (totals) => totals && format(totals.totalPro)
    },
    {
      title: t('commission.amountT'),
      dataIndex: 'totals',
      render: (totals) => totals && format(resultComPro(totals))
    }
  ];
};

export default useColumns;
