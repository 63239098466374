import React, { useState } from 'react';
import { Col } from 'antd';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import OptionsRow from './OptionsRow';

const ListProfesionals = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);

  const headers = [
    {
      label: 'Fournisseur',
      key: 'supplier'
    },
    {
      label: 'Site',
      key: 'site.label'
    },
    {
      label: 'Prénom Client',
      key: 'customer.first_name'
    },
    {
      label: 'Nom Client',
      key: 'customer.last_name'
    },
    {
      label: 'Pro',
      key: 'profesional.name'
    },
    {
      label: 'Date',
      key: 'created_at'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="orders-supplies"
      resourceModelName="OrderSupply"
      withSubRoutes
      extraQuery={extraQuery}
      populate="site,customer,profesional,supplies.product"
      headers={headers}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListProfesionals;
