/* eslint-disable operator-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/charts';
import { IdcardFilled } from '@ant-design/icons';

const ColumnChartTop = ({ dataStocks }) => {
  const [datas, setDatas] = useState([]);

  const calculateArea = () => {
    const aireOfMatter = {};

    dataStocks.forEach((piece) => {
      aireOfMatter[piece.matter.color] = 0;
    });

    dataStocks.forEach((piece) => {
      piece.sizes.forEach((size) => {
        if (size.exit_date) {
          aireOfMatter[piece.matter.color] =
            aireOfMatter[piece.matter.color] +
            (size.length / 1000) * (size.width / 1000);
        }
      });
    });
    if (Object.keys(aireOfMatter).length > 0) {
      let arrayOfData = Object.keys(aireOfMatter).map((key) => {
        return {
          type: key,
          value: aireOfMatter[key]
        };
      });
      const top10Values = arrayOfData
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);
      arrayOfData = top10Values.filter((el) => {
        return el.value !== 0;
      });
      setDatas(arrayOfData);
    }
  };

  useEffect(() => {
    if (dataStocks.length) {
      calculateArea();
    } else setDatas([]);
  }, [dataStocks]);
  const config = {
    maxColumnWidth: 20,
    width: 200,
    height: 350,
    data: datas,
    xField: 'type',
    yField: 'value',
    seriesField: 'type',
    xAxis: {
      label: false
    },
    legend: {
      layout: 'vertical',
      position: 'bottom',
      maxRow: 4,
      flipPage: false,
      itemHeight: 4,
      itemWidth: 140,
      itemSpacing: 60
    }
  };
  return <Column {...config} />;
};

ColumnChartTop.propTypes = {
  dataStocks: PropTypes.shape().isRequired
};

export default ColumnChartTop;
