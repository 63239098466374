import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Tabs, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import SectionForm from './SectionForm';
import useConfiguratorContext from './ConfiguratorContext';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { TabPane } = Tabs;

const sectionTitle = {
  '1': 'Première section',
  '2': 'Deuxième section',
  '3': 'Troisième section',
  '4': 'Quatrième section',
  '5': 'Cinquième section'
};

const TabsPieces = ({
  initialSectionCount,
  value,
  activeSection,
  setActiveSection
}) => {
  const { currentStep, t, form, setActiveKey } = useConfiguratorContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const initialSections = [
    {
      content: <SectionForm name="section_1" choice={value} />,
      key: '1',
      closable: false
    }
  ];
  const [sections, setSections] = useState(initialSections);
  const { dispatchAPI } = useAuthContext();

  useEffect(() => {
    if (sections.length === 0) setSections(initialSections);
    if (activeSection === '0') setActiveSection('1');
  }, [sections, activeSection]);

  useEffect(() => {
    const newSections = [];
    for (let i = 0; i < initialSectionCount; i += 1) {
      newSections.push({
        content: (
          <SectionForm
            name={`section_${String(i + 1)}`}
            valeur={false}
            choice={value}
          />
        ),
        key: String(i + 1)
      });
    }
    setSections(newSections);
  }, [initialSectionCount, value]);

  const addSection = () => {
    form.setFieldsValue({
      [`section_${String(sections.length + 1)}`]: {
        description: null,
        elements: null,
        project_type: null
      }
    });
    setSections([
      ...sections,
      {
        content: (
          <SectionForm
            name={`section_${String(sections.length + 1)}`}
            valeur
            choice={value}
          />
        ),
        key: String(sections.length + 1)
      }
    ]);
  };
  const removeSectionFromForm = async (sectionId) => {
    try {
      const values = form.getFieldsValue();
      const nameSection = `section_${sectionId}`;
      await dispatchAPI('PATCH', {
        url: `/quotes/${id}`,
        body: { [nameSection]: null }
      });
      delete values[nameSection];
      form.setFieldsValue(values);
    } catch (e) {
      if (e.response) message.error(e);
    }
  };
  const onEditSection = (targetkey, action) => {
    switch (action) {
      case 'add':
        if (sections.length + 1 <= 5) {
          addSection();
          setActiveSection(String(sections.length + 1));
        }
        break;
      case 'remove':
        // eslint-disable-next-line no-case-declarations
        const newSections = sections
          .filter(({ key }) => key !== targetkey)
          .map((section, index) => {
            return {
              content: {
                ...section.content,
                props: {
                  ...section.content.props,
                  name: `section_${String(index + 1)}`
                }
              },
              key: String(index + 1)
            };
          });
        // eslint-disable-next-line no-case-declarations
        const values = form.getFieldsValue();
        if (id) removeSectionFromForm(targetkey);
        else {
          for (let i = parseInt(targetkey, 10); i <= 5; i += 1) {
            const nextSection = `section_${i + 1}`;
            if (values[nextSection]) {
              const newSection = `section_${i}`;
              values[newSection] = values[nextSection];
            } else {
              delete values[`section_${i}`];
            }
          }
          form.resetFields();
          form.setFieldsValue(values);
        }
        setSections([]);
        setSections(newSections);
        setActiveSection(String(Number(targetkey) - 1));
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="quotation-form-wrapper"
      style={{ display: currentStep === 1 ? '' : 'none' }}
    >
      <Typography.Title
        level={4}
        style={{ display: currentStep === 1 ? '' : 'none' }}
      >
        {t('configurator.pieces')}
      </Typography.Title>
      <Tabs
        type="editable-card"
        onChange={(key) => {
          setActiveKey('1');
          setActiveSection(key);
        }}
        activeKey={activeSection}
        onEdit={onEditSection}
        style={{ display: currentStep === 1 ? '' : 'none' }}
      >
        {sections.map(({ key, closable, content }) => {
          return (
            <TabPane
              tab={sectionTitle[key]}
              key={key}
              closable={closable || sections.length > 1}
              forceRender
            >
              {content}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

TabsPieces.propTypes = {
  initialSectionCount: PropTypes.number.isRequired,
  value: PropTypes.string,
  activeSection: PropTypes.string,
  setActiveSection: PropTypes.func
};

TabsPieces.defaultProps = {
  value: '',
  activeSection: '1',
  setActiveSection: () => {}
};

export default TabsPieces;
