import { useTranslation } from 'react-i18next';

const useListContent = ({ order, site, label, note, buildingSite }) => {
  const { t } = useTranslation();

  return [
    {
      label: 'sites.name',
      content: label
    },
    {
      label: 'sav.order',
      content: order ? order?.number : '-'
    },
    {
      label: 'sav.site',
      content: site ? site?.label : '-'
    },
    {
      label: 'sav.buildingSite',
      content: buildingSite ? buildingSite?.label : '-'
    },
    {
      label: 'sav.note',
      content: note
    }
  ];
};

export default useListContent;
