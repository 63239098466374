import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import Repository from './DealCard';
import useBoxContext from './DealsContext';
import { kanbanIcon } from '../Settings/utilsKanban';
import useAuthContext from '../../contexts/AuthContext';

const Col = styled.div`
  height: 62vh;
  position: relative;
  min-width: 250px;
  padding: 7px;
  overflow: hidden;
`;

const Title = styled.div`
  background: #929292;
  position: absolute;
  display: flex;
  justify-content: left;
  top: 7px;
  width: calc(100% - 14px);
  text-align: left;
  height: 40px;
  align-items: center;
  padding: 0 14px;
  #icon {
    color: #fff;
  }
  #label {
    color: #fff;
    margin-left: 12px;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 57px;
  width: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 57px);
  overflow-y: auto;
`;

const KanbanColumn = ({ column: { _id, label, icon } }) => {
  const {
    updateDeal,
    deals,
    forceRefresh,
    setForceRefresh,
    searchValue
  } = useBoxContext();
  const { user } = useAuthContext();
  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      if (deals.filter((el) => el._id === item.id)[0].archived) {
        return setForceRefresh(!forceRefresh);
      }
      const itemHistory = deals.filter((el) => el._id === item.id)[0].history;
      await updateDeal(item.id, {
        status: _id,
        history: [
          ...(itemHistory || []),
          {
            change_type: 'STATUS_CHANGE',
            new_value: label,
            editor: user,
            date: Date.now()
          }
        ]
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const filteredSearch = (deal) => {
    const { first_name, last_name } = deal.orders.infos.customer;
    const value = searchValue?.toLowerCase();
    if (value)
      return (
        first_name?.toLowerCase().startsWith(value) ||
        last_name?.toLowerCase().startsWith(value) ||
        `${first_name} ${last_name}`.toLowerCase().startsWith(value)
      );
    return deal;
  };

  return (
    <div>
      <Col
        ref={drop}
        style={{
          'border-right': '1px solid rgba(255, 255, 255, 0.4)'
        }}
      >
        <Title>
          <div id="icon">
            {kanbanIcon.filter((ic) => ic.label === icon)[0]?.value}
          </div>
          <div id="label" style={{ lineHeight: 1 }}>
            {label}
          </div>
        </Title>
        <CardContainer>
          {deals
            .filter((el) => filteredSearch(el))
            .filter((deal) => deal.status === _id && !deal.archived)
            .map((deal) => (
              <Repository deal={deal} key={deal._id} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

KanbanColumn.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string
  }).isRequired
};

export default KanbanColumn;
