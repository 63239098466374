import {
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useContext from '../context';

const Buttons = ({ handleValidate, isEditable, handleSave, element }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { downloadFile } = useContext();
  return (
    <>
      <Button
        type="primary"
        icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
        style={{ margin: '5px' }}
        onClick={async () => {
          if (!isDownloading) {
            setIsDownloading(true);
            await downloadFile(element._id, `${element.order.number}.pdf`, () =>
              setIsDownloading(false)
            );
          }
        }}
      >
        Générer
      </Button>
      <Tooltip
        title={
          isEditable
            ? ''
            : "Le bon d'enlèvement / livraison a été déjà valider!"
        }
      >
        <Button
          type="primary"
          style={{ margin: '5px' }}
          icon={<CheckOutlined />}
          onClick={handleValidate}
          disabled={!isEditable}
        >
          Valider définitivement
        </Button>
      </Tooltip>
      <Tooltip
        title={
          isEditable
            ? ''
            : "Le bon d'enlèvement / livraison a été déjà valider!"
        }
      >
        <Button
          type="primary"
          style={{ margin: '5px 22px 5px 5px' }}
          onClick={handleSave}
          disabled={!isEditable}
        >
          Enregistrer
        </Button>
      </Tooltip>
    </>
  );
};

Buttons.propTypes = {
  handleValidate: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  element: PropTypes.shape({
    _id: PropTypes.string,
    order: PropTypes.shape({ number: PropTypes.string })
  }).isRequired
};

export default Buttons;
