import PropTypes from 'prop-types';
import React from 'react';
import { Typography, Row, Col, Card } from 'antd';

const Infos = ({ datas }) => {
  const { Title } = Typography;
  if (datas) {
    return (
      <Col span={7}>
        <Card
          style={{
            padding: 0,
            background: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          <Title level={4}>Informations générales</Title>
          <div style={{ width: '110px' }}>
            <hr
              style={{
                backgroundColor: 'black',
                height: '3px',
                border: 'none',
                marginBottom: '18px'
              }}
            />
          </div>
          <Row>
            <Col span={24}>
              <div>
                {datas.commercial_site && (
                  <div>
                    Site de demande :{' '}
                    <strong>{datas.commercial_site?.label}</strong>
                  </div>
                )}
                <div>
                  {datas.production_site && (
                    <div>
                      Site de fabrication :{' '}
                      <strong>{datas.production_site?.label}</strong>
                    </div>
                  )}
                </div>
                {datas.customer && (
                  <div>
                    Client :{' '}
                    <strong>
                      {datas.customer?.last_name}{' '}
                      {datas?.customer.first_name || ''}
                    </strong>
                  </div>
                )}
                {
                  <div>
                    Professionnel :{' '}
                    <strong>
                      {datas.profesional ? datas.profesional.name : 'N/R'}
                    </strong>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }

  return <>No infos available </>;
};

Infos.propTypes = {
  datas: PropTypes.shape({
    commercial_site: PropTypes.shape({
      label: PropTypes.string
    }),
    customer: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    production_site: PropTypes.shape({
      label: PropTypes.string
    }),
    profesional: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired
};

export default Infos;
