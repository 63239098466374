/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Popconfirm, Row, Steps, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CheckOutlined,
  CopyOutlined
} from '@ant-design/icons/lib';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import DownloadGeneratedFile from '../Invoices/DownloadGeneratedFile';
import Customer from '../Invoices/Components/Customer';
import Profesionals from '../Invoices/Components/Profesionals';
import DescQuotForm from './descQuotForm';
import ImportFiles from '../../../components/importFiles';
import BlocPrices from './prices';
import MetreModal from './MetreModal';
import ModalStat from './FormsComponents/modalStat';

const { Step } = Steps;

const ShowQuotation = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [template, setTemplate] = useState();
  const [metre, setMetre] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const onDuplicate = async () => {
    setLoading2(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/duplicate/${id}`
      });
      setLoading2(false);
      history.push(`/customers_accounting/quotations/show/${data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=QUOTATION`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (fileId, name, callback) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/quotation/${fileId}/${template._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    callback();
  };

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,site,profesional,coef,quotlines.elements.element`
      });
      setResource(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onDelete = async () => {
    const body = {
      deleted: true
    };
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
      history.push('/customers_accounting/quotations');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createOrder = async (orderId) => {
    const body = {
      quotation: orderId
    };
    try {
      await dispatchAPI('POST', { url: '/orders', body });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  // const createNotification = async id => {
  //   const date = new Date()
  //   // date.setDate(date.getDate() + 7);
  //   console.log(body)
  //   const body = {
  //     element: id,
  //     date
  //   };
  //   try {
  //     await dispatchAPI('POST', { url: '/notifications', body });
  //     setForceRefresh(true);
  //   } catch (e) {
  //     if (e.response) message(e.response.status);
  //   }
  // };

  const showModalMetre = () => {
    setMetre(false);
    setIsModalVisible(true);
  };

  const handleOkMetre = () => {
    setIsModalVisible(false);
  };

  const handleCancelMetre = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    const body = {
      status: 'VALIDATE',
      metre: values.metre
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${displayedResource._id}`,
        body
      });
      await createOrder(displayedResource._id);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onValidate = () => {
    showModalMetre();
    // if(metre == true){
    //   const body = {
    //     status: 'VALIDATE'
    //   };
    //   try {
    //     await dispatchAPI('PATCH', {
    //       url: `/quotations/${displayedResource._id}`,
    //       body
    //     });
    //     await createOrder(displayedResource._id);
    //     setForceRefresh(!forceRefresh);
    //   } catch (e) {
    //     if (e.response) message(e.response.status);
    //   }
    // }
  };

  const getCurrentStep = (currentRessource) => {
    let step;
    switch (currentRessource.status) {
      case 'PENDING':
        step = 1;
        break;
      case 'VALIDATE':
        step = 2;
        break;
      default:
        break;
    }
    return step;
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    await getTemplate();
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id, forceRefresh]);
  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: displayedResource.number
        })}
        extra={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DownloadGeneratedFile
              element={displayedResource}
              template={template}
              downloadFile={downloadFile}
              type="quote"
            />
            {!isLoading && displayedResource.status !== 'VALIDATE' && (
              <>
                <Popconfirm
                  title={t(`${resource}.action.delete.title`)}
                  okText={t(`${resource}.action.delete.ok`)}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t(`${resource}.action.delete.cancel`)}
                  onConfirm={onDelete}
                  icon={<WarningOutlined />}
                >
                  <Button
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                    style={{ float: 'right', margin: '0 4px' }}
                  >
                    {t('buttons.delete')}
                  </Button>
                </Popconfirm>
                <Link
                  to={{
                    pathname: `/customers_accounting/${resource}/edit/${id}`
                  }}
                  style={{ float: 'right' }}
                >
                  <Button
                    type="primary"
                    style={{ float: 'right', margin: '0 4px' }}
                    icon={<EditOutlined />}
                  >
                    {t('buttons.edit')}
                  </Button>
                </Link>
                <Button
                  type="add"
                  style={{ float: 'right', margin: '0 4px' }}
                  icon={<CheckOutlined />}
                  onClick={onValidate}
                >
                  {t('buttons.validate')}
                </Button>
                <MetreModal
                  onFinish={onFinish}
                  handleOkMetre={handleOkMetre}
                  handleCancelMetre={handleCancelMetre}
                  isModalVisible={isModalVisible}
                />
                <Popconfirm
                  title={t(`${resource}.action.duplicate.warning`)}
                  okText={t(`${resource}.action.duplicate.ok`)}
                  okButtonProps={{ type: 'add' }}
                  cancelText={t(`${resource}.action.delete.cancel`)}
                  onConfirm={onDuplicate}
                  icon={<WarningOutlined />}
                >
                  <Button
                    type="add"
                    add
                    icon={isLoading2 ? <Spin /> : <CopyOutlined />}
                    style={{ float: 'right', margin: '4 4px' }}
                  >
                    {t(`${resource}.action.duplicate.title`)}
                  </Button>
                </Popconfirm>
              </>
            )}{' '}
          </div>
        }
      />

      <ContentCustom>
        {resource === 'quotations' && (
          <Row>
            <Col span={14} offset={4}>
              <Steps current={getCurrentStep(displayedResource)}>
                <Step
                  title={`${t('quotations.form.enums.CREATE')} ${moment(
                    resource.emission_date
                  ).format('DD-MM-YY')}`}
                />
                <Step title={t('quotations.form.enums.PENDING')} />
                <Step title={t('quotations.form.enums.VALIDATE')} />
              </Steps>
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
          <Col span={6} style={{ paddingBottom: '10px' }}>
            {displayedResource.profesional && (
              <Profesionals
                display={displayedResource.profesional}
                isLoading={isLoading}
                dispatchAPI={dispatchAPI}
              />
            )}
          </Col>
          <Col span={6} offset={12} style={{ paddingBottom: '10px' }}>
            <Customer
              customer={displayedResource.customer}
              isLoading={isLoading}
            />
          </Col>
        </Row>
        <ModalStat
          surface={displayedResource && displayedResource.quotlines}
          setIsModalVisible={setIsModalVisible2}
          isModalVisible={isModalVisible2}
        />
        {displayedResource.quotlines?.map((line) => (
          <DescQuotForm line={line} />
        ))}

        {displayedResource?.prices && (
          <BlocPrices prices={displayedResource.prices} />
        )}
      </ContentCustom>
      <ImportFiles
        id={id}
        files={displayedResource.files}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        resource="quotations"
      />
    </>
  );
};

ShowQuotation.propTypes = {
  resource: PropTypes.shape({
    emission_date: PropTypes.any
  }).isRequired
};

export default ShowQuotation;
