import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  Form,
  Select,
  Modal,
  Button,
  Tag,
  Input,
  Col,
  Row,
  Card
} from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { CheckOutlined, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import useDealContext from './DealsContext';
import FileCard from './FileCard';
import AddFile from './AddFile';
import HistoryColumn from './HistoryColumn';
import StockTagForMatter from '../Stocks/StockTagForMatter';

const { TextArea } = Input;

const ModalCard = ({ deal, isVisible, setIsVisible, mattersArray }) => {
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user, canEditResource } = useAuthContext();
  const { path } = useRouteMatch();
  const { forceRefresh, setForceRefresh, labels, status } = useDealContext();
  const [actionsToSave, setActionsToSave] = useState([]);
  const [filesReady, setFilesReady] = useState(false);

  useEffect(() => {
    setActionsToSave([]);
  }, [deal]);

  const saveTrelloCard = async (values) => {
    const body = values;
    try {
      await dispatchAPI('PATCH', { url: `/trellos-cards/${deal._id}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setForceRefresh(!forceRefresh);
  };

  const handleOk = async () => {
    await saveTrelloCard({
      ...form.getFieldsValue(),
      history: [...form.getFieldsValue().history, ...actionsToSave]
    });

    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const addFile = async (file, filename) => {
    const files = [...form.getFieldsValue().files, file];
    const history = [
      ...form.getFieldsValue().history,
      {
        new_value: filename,
        editor: user,
        change_type: 'FILE_ADD',
        date: Date.now()
      }
    ];

    const body = { files: [file], history };
    await saveTrelloCard(body);
  };

  const removeFile = async (file) => {
    const files = form
      .getFieldsValue()
      .files.filter((el) => el._id !== file._id);
    const history = [
      ...form.getFieldsValue().history,
      {
        new_value: file.metadata.originalName,
        editor: user,
        change_type: 'FILE_REMOVE',
        date: Date.now()
      }
    ];
    form.setFieldsValue({ ...form.getFieldsValue(), files, history });

    const body = { files, history };

    await saveTrelloCard(body);
  };

  useEffect(() => {
    if (Object.keys(deal).length > 0) {
      form.setFieldsValue({
        ...deal,
        color: deal?.color?.map((cardLabel) => cardLabel._id),
        estimated_date_measure: moment(deal.estimated_date_measure),
        estimated_date_lay: moment(deal.estimated_date_lay),
        manufacturing_site: deal.manufacturing_site?._id,
        lay_site: deal.lay_site?._id,
        files: deal.files ? deal.files : [],
        history: deal.history ? deal.history : []
      });
    }
    setFilesReady(true);
  }, [deal]);

  return (
    <Form form={form}>
      <Modal
        zIndex={10}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: 24
            }}
          >
            <Row align="middle">
              <div
                style={{ fontWeight: 600, fontSize: 18, paddingRight: '40px' }}
              >
                {`${deal?.orders?.infos?.customer?.first_name || ''} ${
                  deal?.orders?.infos?.customer?.last_name
                }`}
              </div>
              <Tag
                color={
                  deal.commercial_site?.request_color &&
                  deal.commercial_site?.request_color?.hex
                }
              >
                <span>{`DEM.${deal.commercial_site?.label.toUpperCase()}`}</span>
              </Tag>
              {deal.production_sites.map((site) => (
                <Tag color={site.production_color && site.production_color.hex}>
                  <span>{`FAB.${site.label.toUpperCase()}`}</span>
                </Tag>
              ))}
              <Form.Item name="color" style={{ marginBottom: 0 }}>
                <Select
                  mode="multiple"
                  options={(labels || []).map((item) => ({
                    label: <Tag color={item.color}>{item.label}</Tag>,
                    value: item._id
                  }))}
                  style={{ width: '100%', minWidth: '200px' }}
                  disabled={deal.archived}
                />
              </Form.Item>
            </Row>

            <Row>
              {deal.worksheet && (
                <Link
                  to={{
                    pathname: `/customers_accounting/workSheets`,
                    state: {
                      worksheet: deal.worksheet,
                      datas: deal.orders
                    }
                  }}
                  style={{
                    float: 'right',
                    marginRight: '20px'
                  }}
                >
                  <Button type="primary" icon={<ExportOutlined />}>
                    FICHE DE TRAVAIL
                  </Button>
                </Link>
              )}
              <Link
                to={{
                  pathname: `/customers_accounting/orders_sales/show/${deal?.orders?._id}`
                }}
                style={{
                  float: 'right',
                  marginRight: '30px'
                }}
              >
                <Button type="primary" icon={<ExportOutlined />}>
                  COMMANDE
                </Button>
              </Link>
            </Row>
          </div>
        }
        style={{
          borderRadius: '5px',
          color: 'black'
        }}
        bodyStyle={{
          paddingTop: '0px',
          paddingBottom: '0px'
        }}
        width="75%"
        visible={isVisible}
        onCancel={handleCancel}
        centered
        footer={
          canEditResource(path) ? (
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              icon={<CheckOutlined />}
            >
              {t('buttons.validate')}
            </Button>
          ) : (
            []
          )
        }
      >
        <Row>
          <Col
            style={{
              height: '100%',
              width: '60%',
              borderRight: '2px solid #f0f0f0',
              paddingTop: '24px',
              paddingRight: '40px'
            }}
          >
            <Row align="middle">
              <Col span={8}>
                <p style={{ marginBottom: 10 }}>
                  {`Commande n°${deal.orders?.number}`}
                </p>
              </Col>
              <Col span={12}>
                <Form.Item name={['status']} label="Statut">
                  <Select
                    options={(status || []).map((item) => ({
                      label: item.label,
                      value: item._id,
                      key: item._id
                    }))}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const oldLabel = status.filter(
                        (item) => item._id === deal.status
                      )[0].label;
                      const label = status.filter(
                        (item) => item._id === value
                      )[0].label;
                      setActionsToSave([
                        ...actionsToSave,
                        {
                          change_type: 'STATUS_CHANGE',
                          new_value: label,
                          editor: user,
                          date: Date.now()
                        }
                      ]);
                    }}
                    disabled={deal.archived || !canEditResource(path)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              span={24}
              justify="center"
              align="middle"
              style={{ marginBottom: 10 }}
            >
              <Col span={8}>
                {`Date commande : ${moment(deal.orders?.created_at).format(
                  'DD/MM/YYYY'
                )}`}
              </Col>
              <Col span={8}>
                {deal.worksheet?.date
                  ? `Date pose : ${moment(deal.worksheet?.date).format(
                      'DD/MM/YYYY'
                    )}`
                  : 'Pas de date de pose'}
              </Col>
              <Col span={8}>
                <Form.Item
                  name="estimated_date_measure"
                  label="Date du metré"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    defaultValue={moment(deal?.worksheet?.measurement_date)}
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    disabled={!canEditResource(path)}
                    onSelect={(value) => {
                      setActionsToSave([
                        ...actionsToSave,
                        {
                          change_type: 'DATE_CHANGE',
                          new_value: value,
                          editor: user,
                          date: Date.now()
                        }
                      ]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {mattersArray.length > 0 &&
              mattersArray.map((el, index) => (
                <Row>
                  <Card
                    style={{
                      height: '100%',
                      width: '100%',
                      margin: '10px 0 5px 0',
                      border: '1px solid'
                    }}
                    bodyStyle={{ padding: '12px 16px' }}
                  >
                    <Row>
                      <Col
                        style={{
                          width: '15%'
                        }}
                      >
                        <Row>
                          Matière
                          {index + 1} &nbsp;
                        </Row>
                        <StockTagForMatter
                          matter={el}
                          sizesNeeded={el?.sizes || []}
                        />
                      </Col>
                      <Col
                        style={{
                          width: '85%'
                        }}
                      >
                        <Row style={{ width: '100%' }} justify="center">
                          <div style={{ marginBottom: 8 }}>
                            <b>{el?.matter?.toUpperCase()}</b>
                          </div>
                        </Row>
                        <Row style={{ width: '100%' }}>
                          <Col
                            span={12}
                            style={{
                              textAlign: 'right',
                              paddingRight: '5px',
                              color: 'var(--textColorSecondary)'
                            }}
                          >
                            <div>Couleur : </div>
                            <div>Finition : </div>
                            <div>
                              {`Épaisseur ${
                                el.is_credence ? 'crédences' : 'plans'
                              } (cm) : `}
                            </div>
                          </Col>
                          <Col
                            span={12}
                            style={{ textAlign: 'left', paddingLeft: '5px' }}
                          >
                            <div>{el?.color?.color}</div>
                            <div>{el?.color?.completions}</div>
                            <div>{el?.thikness}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              ))}

            <Form.Item
              style={{
                height: '100%',
                margin: '10px 0 5px 0'
              }}
              label="Commentaire"
              name={['note']}
            >
              <TextArea
                rows={4}
                cols={200}
                placeholder="Ecrire le commentaire..."
                disabled={deal.archived}
              />
            </Form.Item>

            <Row
              span={24}
              style={{
                height: '100%',
                width: '100%',
                margin: '10px 0 10px 0'
              }}
              justify="middle"
            >
              <Form.Item name={['files']}>
                <Col>
                  <p style={{ marginBottom: 4 }}>Pièces jointes :</p>
                  <Row
                    style={{
                      width: '100%'
                    }}
                    align="middle"
                  >
                    {filesReady &&
                      Array.isArray(form.getFieldsValue().files) &&
                      form
                        .getFieldsValue()
                        .files?.map((el) => (
                          <FileCard
                            span={8}
                            file={el}
                            onDeleteFile={removeFile}
                          />
                        ))}
                    {canEditResource(path) && !deal.archived && (
                      <AddFile onFileUploaded={addFile} />
                    )}
                  </Row>
                </Col>
              </Form.Item>
            </Row>
          </Col>
          <Col
            style={{
              height: '100%',
              width: '40%',
              paddingTop: '24px',
              paddingLeft: '40px'
            }}
          >
            <Form.Item name={['history']}>
              <HistoryColumn history={form.getFieldValue().history} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

ModalCard.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.any,
    archived: PropTypes.bool,
    color: PropTypes.array,
    commercial_site: PropTypes.shape({
      commercial_color: PropTypes.shape({
        hex: PropTypes.any
      }),
      label: PropTypes.shape({
        toUpperCase: PropTypes.func
      }),
      request_color: PropTypes.shape({
        hex: PropTypes.any
      })
    }),
    date: PropTypes.shape({}),
    estimated_date_lay: PropTypes.any,
    estimated_date_measure: PropTypes.any,
    files: PropTypes.array,
    history: PropTypes.any,
    lay_site: PropTypes.shape({
      _id: PropTypes.any
    }),
    manufacturing_site: PropTypes.shape({
      _id: PropTypes.any
    }),
    order: PropTypes.any,
    orders: PropTypes.shape({
      _id: PropTypes.any,
      created_at: PropTypes.any,
      customer: PropTypes.shape({
        first_name: PropTypes.any,
        last_name: PropTypes.any
      }),
      estimated_date_lay: PropTypes.any,
      infos: PropTypes.shape({
        customer: PropTypes.shape({
          first_name: PropTypes.any,
          last_name: PropTypes.any
        })
      }),
      number: PropTypes.any
    }),
    production_sites: PropTypes.shape({
      map: PropTypes.func
    }),
    status: PropTypes.string,
    worksheet: PropTypes.any
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  mattersArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsVisible: PropTypes.func.isRequired
};

export default ModalCard;
