import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, message } from 'antd';
import { LoadingOutlined, FilePdfOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import useOrderContext from '../../OrderContext';

export const DownloadGeneratedFileAdv = ({ values }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { templateAdv } = useOrderContext();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async () => {
    try {
      const goodTemplate = templateAdv.find(
        (el) => el.metadata.originalName === 'Exemple Facture.docx'
      );
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?number=${values.invoice_number}`
      });

      const response = await dispatchAPI('GET', {
        url: `/files/generate/invoice/${data[0]._id}/${goodTemplate._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });

      const url = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location = url;
      return null;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return null;
  };

  return (
    <Button
      type="primary"
      style={{ margin: 0 }}
      icon={isDownloading ? <LoadingOutlined /> : <FilePdfOutlined />}
      onClick={async () => {
        if (!isDownloading) {
          setIsDownloading(true);
          await downloadFile();
        }
        setIsDownloading(false);
      }}
    >
      {t('invoices.column.type.number')}
    </Button>
  );
};

DownloadGeneratedFileAdv.propTypes = {
  values: PropTypes.shape({
    invoice_number: PropTypes.string
  }).isRequired
};
