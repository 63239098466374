import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Card, Row, Button } from 'antd';
import {
  LoadingOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const FileCard = ({ file, onDeleteFile }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { path } = useRouteMatch();
  const { dispatchAPI, canEditResource } = useAuthContext();
  const { message } = useErrorMessage();

  const downloadFile = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: file?.contentType || response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      if (file?.contentType !== 'application/pdf') {
        const a = document.createElement('a');
        a.href = url;
        a.download = file?.metadata?.originalName;
        a.click();
      } else {
        const pdfWindow = window.open();
        pdfWindow.location.href = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading(false);
  };

  const deleteFile = async () => {
    try {
      await onDeleteFile(file);
      await dispatchAPI('DELETE', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const visualizeFile = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${file._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const url = window.URL.createObjectURL(blob);
      const pdfWindow = window.open();
      pdfWindow.location.href = url;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Card
      style={{
        background: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid #000',
        marginRight: '8px'
      }}
      bodyStyle={{ padding: '0 8px' }}
      bordered
    >
      <Row justify="middle">
        <Col>{file.metadata && file.metadata.originalName}</Col>
        <Col>
          <Row>
            <Button
              type="link"
              onClick={async () => {
                await visualizeFile();
              }}
            >
              {<EyeOutlined />}
            </Button>
            <Button
              type="link"
              onClick={async () => {
                if (!isDownloading) {
                  setIsDownloading(true);
                  await downloadFile();
                }
              }}
            >
              {isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>
            {canEditResource(path) && (
              <Button
                type="link"
                onClick={async () => {
                  await deleteFile();
                }}
              >
                {<DeleteOutlined style={{ color: 'red' }} />}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

FileCard.propTypes = {
  file: PropTypes.shape({
    _id: PropTypes.any,
    filename: PropTypes.string,
    contentType: PropTypes.string,
    metadata: PropTypes.shape({
      originalName: PropTypes.string
    })
  }).isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

export default FileCard;
