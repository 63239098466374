import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Customer = ({ isLoading, customer }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {customer && (
          <>
            <Title level={3} style={{ marginTop: '0' }}>
              <Link to={`/commercial/customers/show/${customer._id}`}>
                <span style={{ marginRight: 5 }}>
                  {customer.gender === 'MISTER' ? 'Mr' : 'Mme'}
                </span>
                <span style={{ marginRight: 5 }}>
                  {customer.last_name?.toUpperCase()}
                </span>
                <span style={{ marginRight: 5 }}>
                  {customer.first_name
                    ? `${customer.first_name.slice(0, 1).toUpperCase()}
                  ${customer.first_name.slice(1)}`
                    : ''}
                </span>
              </Link>
            </Title>
            {customer.address.number || ''} {customer.address.street}
            <br />
            {customer.address.postal_code} {customer.address.city}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Customer.propTypes = {
  customer: PropTypes.shape({
    _id: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.any,
      number: PropTypes.any,
      postal_code: PropTypes.any,
      street: PropTypes.any
    }),
    address_details: PropTypes.string,
    channel_number: PropTypes.number,
    city: PropTypes.string,
    first_name: PropTypes.shape({
      slice: PropTypes.func
    }),
    gender: PropTypes.string,
    last_name: PropTypes.shape({
      toUpperCase: PropTypes.func
    }),
    name: PropTypes.string,
    postal_code: PropTypes.number
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Customer;
