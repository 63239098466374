import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  message,
  Popconfirm,
  Skeleton,
  Card,
  Typography,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../utils/constants/routes';
import { customersTypes } from '../../utils/constants/tagColors';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';

const { Title } = Typography;

const Content = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
  margin-bottom: 20px;
`;

const Insurance = styled.div`
  background-color: var(--contrastBackground);
  padding: 4px;
  minheight: 150px;
`;

const ShowExtraquotation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [extraquotation, setExtraquotation] = useState();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/extraquotations/${id}?populate=supplies.matter`
      });
      setExtraquotation(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const deleteCustomer = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/extraquotations/${id}` });
      history.push(
        `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.EXTRAQUOTATIONS}`
      );
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`extraquotations.show.title`)}
        extra={
          <>
            <Link
              to={{
                pathname: '/customers_accounting/quotations/create',
                state: {
                  crm: id && id,
                  site: '',
                  customer: '',
                  profesional: ''
                }
              }}
            >
              <Button>Ajouter un devis</Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCustomer}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      {extraquotation !== undefined ? (
        <>
          <ContentCustom>
            <Row style={{ minHeight: '14rem' }}>
              <Col span={6}>
                <Card style={{ height: '100%' }}>
                  <Row>
                    <Col
                      style={{
                        height: '11rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                      span={20}
                      offset={2}
                    >
                      <Title level={3}>
                        Dossier n°
                        {extraquotation.fileNumber}
                      </Title>
                      <p>
                        Date d&#39;émission :{' '}
                        {moment(extraquotation.created_at).format('DD/MM/YYYY')}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={17} offset={1}>
                <Card style={{ textAlign: 'center', height: '100%' }}>
                  <Row>
                    <Col
                      span={7}
                      style={{
                        height: '9.1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around'
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          borderBottom: '1px solid grey',
                          width: '11rem',
                          paddingBottom: '0.3rem'
                        }}
                      >
                        Informations client
                      </Title>
                      <p>
                        Catégorie :
                        <Tag
                          style={{ marginLeft: '1rem' }}
                          color={customersTypes[extraquotation.type]}
                        >
                          {extraquotation.type}
                        </Tag>
                      </p>
                      {extraquotation.type === 'ENTREPRISE' ? (
                        <p>
                          Nom de la société :{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {extraquotation.societyName}
                          </span>
                        </p>
                      ) : null}
                    </Col>
                    <Col style={{ paddingTop: '1rem' }} span={7} offset={1}>
                      <br />
                      <br />
                      <p>
                        {extraquotation.gender === 'MADAME' ? 'Mme' : 'M'}{' '}
                        <span
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          {`${extraquotation.last_name.toUpperCase()} ${
                            extraquotation.first_name
                          }`}
                        </span>
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        {extraquotation.adress}
                      </p>
                    </Col>
                    <Col style={{ paddingTop: '1rem' }} span={7} offset={1}>
                      <br />
                      <br />
                      <p
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        {`${extraquotation.phone_number} ${
                          extraquotation.mobile_phone
                            ? `/ ${extraquotation.mobile_phone}`
                            : ''
                        }`}
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        {extraquotation.email}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </ContentCustom>
          <ContentCustom>
            <Title level={3}>Liste des matériaux</Title>
            <br />
            {extraquotation.supplies.map((el) => (
              <h3>
                {el.matter.label} -{el.matter.color} {el.matter.completions}{' '}
                {el.thickness}
                cm - Nombres de tranche : {el.quantity}
              </h3>
            ))}
          </ContentCustom>
        </>
      ) : (
        <>
          <br />
          <Skeleton active />
          <br />
          <Skeleton active />
          <br />
          <Skeleton active />
        </>
      )}
    </>
  );
};

export default ShowExtraquotation;
