import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = ({ amount, setmodalOpen }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const stripe = useStripe();
  const elements = useElements();

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  const value = amount || 0;

  const resultAPI = async () => {
    const response = await dispatchAPI('POST', {
      url: '/payment-system/stripe',
      body: { amount: value }
    });
    if (response && response.status === 200) {
      setClientSecret(response.data.clientSecret);
    }
  };

  useEffect(() => {
    (() => resultAPI())();
  }, []);

  const handleChange = (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    });
    if (payload.error) {
      setError(
        `${t('payment.stripe.payment-failed')} : ${payload.error.message}`
      );
      setProcessing(false);
      message.error(t('payment.stripe.payment-failed'));
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      message.success(t('payment.stripe.payment-succeed'));
      setmodalOpen(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <button
        type="button"
        id="submit"
        disabled={processing || disabled || succeeded}
      >
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner" /> : 'Pay'}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}

      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        {t('payment.stripe.payment-succeed')}
      </p>
    </form>
  );
};

CheckoutForm.propTypes = {
  amount: PropTypes.number,
  setmodalOpen: PropTypes.func.isRequired
};

CheckoutForm.defaultProps = {
  amount: null
};

export default CheckoutForm;
