import { useTranslation } from 'react-i18next';

const ColumnsViews = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('accounts.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('accounts.form.num'),
      key: 'num',
      dataIndex: 'num',
      sorter: true
    }
  ];
};

const Columns = () => {
  return [
    {
      title: 'Libellé',
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: 'Montant TTC',
      key: 'ammount',
      dataIndex: 'ammount',
      sorter: true,
      render: (value) => {
        return `${value}€`;
      }
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: (date) => {
        return new Date(date).toLocaleDateString('fr-FR');
      }
    }
  ];
};

export { ColumnsViews, Columns };
