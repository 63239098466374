import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import OptionsRow from './OptionsRow';
import { formatter } from './formatter';
import { headers } from './headers';

const ListPieces = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState();
  const [filters, setFilters] = useState({});
  const { state } = useLocation();

  const setFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  useEffect(() => {
    let query;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        if (query) {
          query += `&${key}=${filters[key]}`;
        } else {
          query = `${key}=${filters[key]}`;
        }
      }
    });
    setExtraQuery(query);
  }, [filters]);

  useEffect(() => {
    if (state && state.filterMatter) {
      setExtraQuery(`?matter=${state.filterMatter._id}`);
    } else {
      setExtraQuery(null);
    }
  }, []);

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      formatter={formatter}
      resourceName="pieces"
      withSubRoutes
      extraQuery={extraQuery}
      populate="supplier,site,catalogue,localisation,matter,customer"
      headers={headers}
      pagination
      resourceModelName="Piece"
      extraButtons={
        <OptionsRow setExtraQuery={setExtraQuery} setFilter={setFilter} />
      }
    />
  );
};

export default ListPieces;
