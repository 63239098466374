import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Select
} from 'antd';
import { MenuOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ExportButton from '../ExportButton/ExportButton';
import ImportButton from '../importButton';
import Datatable from '../DataTable/Datatable';
import PageHeaderCustom from '../PageHeader/PageHeader';
import ContentCustom from '../ContentCustom/ContentCustom';
import useAuthContext from '../../contexts/AuthContext';

const { Search } = Input;

const SimpleResourceLandingLayout = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  formatter,
  withImportButton,
  withCreateButton,
  withUploadButton,
  forceRefresh,
  withSubRoutes,
  resourceModelName,
  withSearchPlaceholder,
  sites,
  csv_url,
  isOrder,
  isQuote
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { canEditResource } = useAuthContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [filters, setFilters] = useState({
    commercial_site: null,
    production_site: null,
    date_lowest: null,
    date_highest: null
  });

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  const readActionColumn = [
    {
      key: 'action',
      render: (_, { key }) => (
        <Link
          to={{
            pathname: `${path}/show/${key}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item>
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
            extraQuery={extraQuery}
            formatter={formatter}
          />
        </Menu.Item>
      )}
      {withImportButton ? (
        <Menu.Item>
          <ImportButton resourceName={resourceModelName} url={csv_url} />
        </Menu.Item>
      ) : (
        ''
      )}
    </Menu>
  );

  return (
    <div>
      <PageHeaderCustom
        title={tradKey || t(`${resourceName}.title`)}
        withSubRoutes={withSubRoutes}
        extra={extraHeader}
      />
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          {withSearchPlaceholder && (
            <Col>
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onChange={(e) => searchResource(e.target.value)}
              />
            </Col>
          )}
          {(isOrder || isQuote) && (
            <>
              <Col>
                {t(`${resourceName}.filters.title.name`)}
                <Select
                  allowClear
                  style={{ marginLeft: 5, width: 150 }}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      commercial_site: value
                    })
                  }
                  placeholder={t(
                    `${resourceName}.filters.placeholder.commercial_site`
                  )}
                  options={
                    sites &&
                    sites.map(({ _id, label }) => ({ label, value: _id }))
                  }
                />
                <Select
                  allowClear
                  style={{ marginLeft: 5, width: 160 }}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      production_site: value
                    })
                  }
                  placeholder={t(
                    `${resourceName}.filters.placeholder.production_site`
                  )}
                  options={
                    sites &&
                    sites.map(({ _id, label }) => ({ label, value: _id }))
                  }
                />
              </Col>
              <Col>
                {t(
                  `${resourceName}.filters.dates.${isOrder ? 'lay' : 'default'}`
                )}
                <DatePicker
                  style={{ marginLeft: 4, marginRight: 4, width: 120 }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      date_lowest: value
                    });
                  }}
                />
                au
                <DatePicker
                  style={{ marginLeft: 4, marginRight: 4, width: 120 }}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      date_highest: value
                    })
                  }
                />
              </Col>
            </>
          )}
          <Row align="middle">
            {withCreateButton && canEditResource(path) && (
              <Col>
                {extraButtons}
                <Link to={`${pathname}/create`}>
                  <Button
                    type="add"
                    style={{ background: 'black', border: 'black' }}
                  >
                    {`${t('buttons.create')}`}
                    &nbsp;
                    <PlusOutlined />
                  </Button>
                </Link>
              </Col>
            )}
            {withUploadButton && (
              <Col>
                <Dropdown overlay={menu}>
                  <Button type="link">
                    <MenuOutlined
                      style={{ fontSize: 16, color: 'var(--textColor)' }}
                    />
                  </Button>
                </Dropdown>
              </Col>
            )}
          </Row>
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          searchValue={searchValue}
          columns={
            canEditResource(path) || customActionColumn
              ? [...columns]
              : [...columns, ...readActionColumn]
          }
          customActionColumn={!canEditResource(path) || customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          isOrder={isOrder}
          filter={filters}
        />
      </ContentCustom>
    </div>
  );
};

SimpleResourceLandingLayout.propTypes = {
  children: PropTypes.element,
  formatter: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  dataToFetch: PropTypes.string,
  exportUrl: PropTypes.string,
  csv_url: PropTypes.string,
  extraButtons: PropTypes.element,
  extraHeader: PropTypes.element,
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  populate: PropTypes.string,
  resourceModelName: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withSearchPlaceholder: PropTypes.bool,
  withSubRoutes: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  sites: PropTypes.arrayOf(PropTypes.shape({})),
  isOrder: PropTypes.bool,
  isQuote: PropTypes.bool,
  withImportButton: PropTypes.bool
};

SimpleResourceLandingLayout.defaultProps = {
  children: null,
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  formatter: null,
  populate: null,
  csv_url: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  dataToFetch: null,
  forceRefresh: null,
  withSubRoutes: false,
  resourceModelName: null,
  withSearchPlaceholder: true,
  sites: [],
  isOrder: false,
  isQuote: false,
  withImportButton: false
};

export default SimpleResourceLandingLayout;
