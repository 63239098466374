import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Col } from 'antd';
import moment from 'moment';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import OptionsRow from './OptionsRow';
import { DrowpdownDownloadButton } from '../../../components/DropdownDonwloadButton/DropdownDownloadButtonQuotation';

const iconSize = 18;

const ListQuotations = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const history = useHistory();
  const { range } = useAccountingContext();
  const { dispatchAPI } = useAuthContext();
  const { pathname } = location;
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [template, setTemplate] = useState();
  const [companies, setCompanies] = useState();
  const [status, setStatus] = useState();

  const onDelete = async (id) => {
    const body = {
      deleted: true
    };
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
      history.push({ path: '/quotations' });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createOrder = async (id) => {
    const body = {
      quotation: id
    };
    try {
      await dispatchAPI('POST', { url: '/orders', body });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const onValidate = async (id) => {
    const body = {
      status: 'VALIDATE'
    };
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
      await createOrder(id);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?label=template_quotations'
      });
      setTemplate(data[0].value);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/quotations/enums' });
      setStatus(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name, _, __, outputType = 'pdf') => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/quotation/${id}/${template}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type:
          outputType === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/pdf'
      });

      if (outputType === 'docx') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${outputType}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location = url;
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplateSetting();
    await getCompanies();
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, renderedStatus, number, ...rest }) => {
        return (
          <span>
            <Link
              to={{
                pathname: `${pathname}/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            {template && (
              <>
                <Divider type="vertical" />
                <DrowpdownDownloadButton
                  recordKey={key}
                  number={number}
                  isDownloading={isDownloading}
                  setIsDownloading={setIsDownloading}
                  downloadFile={downloadFile}
                />
              </>
            )}
            {renderedStatus !== 'VALIDATE' && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `${pathname}/edit/${key}`
                  }}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.validate.title')}
                  okText={t('datatable.column.action.validate.ok')}
                  okButtonProps={{ type: 'primary' }}
                  cancelText={t('datatable.column.action.validate.cancel')}
                  onConfirm={() => onValidate(key)}
                  icon={<WarningOutlined />}
                >
                  <CheckOutlined
                    style={{ color: 'green', fontSize: iconSize }}
                    type="validate"
                  />
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => onDelete(key)}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: iconSize }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
          </span>
        );
      }
    }
  ];

  const headers = [
    {
      label: 'Quotation number',
      key: 'number'
    },
    {
      label: 'emission date',
      key: 'emission_date'
    },
    {
      label: 'deadline date',
      key: 'deadline_date'
    },
    {
      label: 'Subject',
      key: 'subject'
    },
    {
      label: 'Comments',
      key: 'comment'
    },
    {
      label: 'Subtotal',
      key: 'subTotal'
    },
    {
      label: 'Total VAT',
      key: 'total_VAT'
    },
    {
      label: 'Total discount',
      key: 'total_discount'
    },
    {
      label: 'Final total',
      key: 'total'
    },
    {
      label: 'Customers',
      key: 'customer.name'
    },
    {
      label: 'Contact first name',
      key: 'contact.first_name'
    },
    {
      label: 'Contact last name',
      key: 'contact.last_name'
    },
    {
      label: 'Contact email',
      key: 'contact.email'
    }
  ];

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `emission_date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&emission_date<=${moment(range[1])
          .endOf('day')
          .toISOString()}`
      );
    } else setExtraQuery(null);
  }, [range]);

  return (
    <SimpleResourceLandingLayout
      resourceName="quotations"
      columns={[...columns(t, companies, status), ...actionColumn]}
      extraQuery={extraQuery}
      populate="customer"
      customActionColumn
      withUploadButton={false}
      headers={headers}
      forceRefresh={forceRefresh}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListQuotations;
