import React, { useState } from 'react';
import { Form, Input, Button, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FormPrice = () => {
  const { t } = useTranslation();
  const [price, setPrice] = useState([]);

  const handlePrice = (e) => {
    setPrice(parseInt(e, 10));
  };

  return (
    <Form.List name="pricing">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'baseline'
              }}
            >
              <Form.Item
                name={[field.name, 'size']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
                rules={[{ required: true }]}
              >
                <Input placeholder="Épaisseur (cm)" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'buy_price']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Prix d'achat HT"
                  onChange={handlePrice}
                />
              </Form.Item>

              {/* coef */}
              {/* <Form.Item
                name={[field.name, 'coef']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}
              >
                <Select onChange={handleCoef} value={choosenCoef}>
                  {coef && coef.map(el => (
                    <Option key={el._id} value={el._id}>
                      {el.coef}
                    </Option>
                   ))}
                </Select>
              </Form.Item> */}

              {/* <Form.Item
                name={[field.name, 'sold_price']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginRight: 5 }}

              >
                <Input  defaultValue={sell}  />
              </Form.Item> */}
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
            >
              {t('catalogues.addprice')}
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
export default FormPrice;
