import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateContact = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="contacts"
      resource="contacts"
      withSubRoutes
    />
  );
};

CreateUpdateContact.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateContact;
