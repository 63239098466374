import { Form, Col, Select, Tag, Input, InputNumber, Row, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import PieceNumbers from './PieceNumbers';

const useLotFields = ({
  form,
  purpose,
  showClientSelect,
  setShowClientSelect
}) => {
  const { Option } = Select;
  const [suppliers, setSuppliers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [pieceCount, setPieceCount] = useState(1); // TODO : if edit, initialize to correct value

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSuppliers(data);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateValue = () => {
    const {
      buying_price,
      devaluation,
      length,
      width,
      pieces_count
    } = form.getFieldsValue();
    let result = null;
    let newDevaluation = 1;

    if (devaluation) newDevaluation = 1 - devaluation / 100;
    if (buying_price) result = buying_price * newDevaluation;
    if (length) result *= length / 1000;
    if (width) result *= width / 1000;
    if (pieces_count) result *= pieces_count;

    if (result)
      form.setFieldsValue({
        value: Number(result.toFixed(2))
      });
  };

  useEffect(() => {
    getSuppliers();
    getCustomers();
  }, [form]);

  return (
    <>
      <Col>
        <Form.Item
          name={['supplier']}
          label={t('pieces.form.supplier')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: purpose !== 'edit' }]}
        >
          <Select loading={isFieldsLoading} disabled={purpose === 'edit'}>
            {suppliers.map((el) => (
              <Option key={el._id} value={el._id}>
                <Tag>{el.name}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['ref_supplier']}
          label={t('pieces.form.ref_supplier')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Input disabled={purpose === 'edit'} />
        </Form.Item>
        <Form.Item
          name={['batch_number']}
          label={t('pieces.form.batch_number')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <InputNumber disabled={purpose === 'edit'} />
        </Form.Item>
        <Form.Item
          name={['pieces_count']}
          label={t('pieces.form.pieces_count')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          initialValue={pieceCount}
          rules={[{ required: true }]}
        >
          <Select
            onSelect={(newValue) => {
              setPieceCount(newValue);
              updateValue();
            }}
            disabled={purpose === 'edit'}
          >
            {Array.from({ length: 100 }, (_, i) => i + 1).map((el) => (
              <Option key={el} value={el}>
                {el}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['pieces_numbers']}
          label={t('pieces.form.pieces_numbers')}
          hidden
        />
        <Form.Item
          label={t('pieces.form.pieces_numbers')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
        >
          <PieceNumbers
            form={form}
            number={pieceCount}
            disabled={purpose === 'edit'}
          />
        </Form.Item>
        <Form.Item
          label={t('pieces.form.buying_price')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          rules={[{ required: true }]}
        >
          <Row>
            <Form.Item
              name={['buying_price']}
              onChange={() => updateValue()}
              rules={[{ required: true }]}
            >
              <InputNumber disabled={purpose === 'edit'} />
            </Form.Item>
            &nbsp;
            <Form.Item
              name={['devaluation']}
              label={t('pieces.form.devaluation')}
              onChange={() => updateValue()}
              rules={[{ required: true }]}
            >
              <InputNumber disabled={purpose === 'edit'} />
            </Form.Item>
            &nbsp;
            <Form.Item name={['value']} label={t('pieces.form.value')}>
              <InputNumber readOnly />
            </Form.Item>
          </Row>
        </Form.Item>

        <Form.Item
          label={t('pieces.form.is_affected')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <Row>
            &nbsp;&nbsp;
            <Radio.Group
              onChange={(value) => {
                setShowClientSelect(value.target.value);
              }}
              defaultValue={showClientSelect}
            >
              <Radio value>oui</Radio>
              <Radio value={false}>non</Radio>
            </Radio.Group>
            {showClientSelect && (
              <Form.Item
                name={['customer']}
                label={t('pieces.form.affected_to')}
              >
                <Select
                  loading={isFieldsLoading}
                  style={{ minWidth: '200px' }}
                  showSearch
                  optionFilterProp="children"
                >
                  {customers.map((el) => (
                    <Option key={el._id} value={el._id}>
                      {`${el.first_name || ''} ${el.last_name}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Row>
        </Form.Item>
      </Col>
    </>
  );
};

useLotFields.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired,
  purpose: PropTypes.string.isRequired,
  showClientSelect: PropTypes.bool,
  setShowClientSelect: PropTypes.func
};

useLotFields.defaultProps = {
  showClientSelect: undefined,
  setShowClientSelect: null
};

export default useLotFields;
