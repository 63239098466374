import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateCustomer = ({
  purpose,
  customSubmit,
  customCancel,
  isModal
}) => {
  const [form] = Form.useForm();
  const { basicFields, isFieldsLoading } = useFields(form);

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="customers"
      resource="customers"
      withSubRoutes
      customForm={form}
      customSubmit={customSubmit}
      customCancel={customCancel}
      isModal={isModal}
    />
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: PropTypes.string.isRequired,
  customSubmit: PropTypes.func,
  customCancel: PropTypes.func,
  isModal: PropTypes.bool
};

CreateUpdateCustomer.defaultProps = {
  customSubmit: null,
  customCancel: null,
  isModal: false
};

export default CreateUpdateCustomer;
