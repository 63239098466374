import React, { useEffect, useState } from 'react';
import { Select, Tag, Button, Input, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { sitesTypes } from '../../../utils/constants/tagColors';
import ContactsForm from './contactsForm';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [enums, setEnums] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [orders, setOrders] = useState([]);
  const [test, setTest] = useState();
  const [enumsStatus, setEnumsStatus] = useState([]);

  const handletest = (e) => {
    setTest(e);
  };
  const basicFields = [
    {
      label: 'category',
      name: ['category'],
      input: (
        <Select onChange={handletest}>
          {(enums || []).map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['site'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select mode="multiple" allowClear>
          {(sites || []).map((item) => (
            <Option key={item._id} value={item._id}>
              <Tag color={sitesTypes[item.label]}>{item.label}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['supplier'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      input: (
        <Select>
          {(supplier || []).map((item) => (
            <Option key={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['order_date'],
      rules: [{ required: true, message: t('errors.message.missing_field') }],
      initialValue: moment(new Date()),
      input: <DatePicker format="DD-MM-YYYY" />
    },
    {
      name: ['removal_date'],
      input: <DatePicker format="DD-MM-YYYY" />
    },
    test === 'SYNTHETIC'
      ? {
          label: 'order',
          name: ['order'],
          rules: [
            { required: true, message: t('errors.message.missing_field') }
          ],
          input: (
            <Select>
              {(orders || []).map((item) => (
                <Option key={item._id} value={item._id}>
                  {`${item.infos.customer.first_name || ''}
                  ${item.infos.customer.last_name} ${item.number}`}
                </Option>
              ))}
            </Select>
          )
        }
      : {
          label: 'order',
          name: ['order'],
          input: (
            <Select>
              {(orders || []).map((item) => (
                <Option key={item._id} value={item._id}>
                  {`${item.infos.customer.first_name || ''}
                  ${item.infos.customer.last_name} Commande N°: ${item.number}`}
                </Option>
              ))}
            </Select>
          )
        }
    // {
    //   label: 'price',
    //   name: ['price'],
    //   input: (<Input type="number"/>)
    // },
    // {
    //   label: 'status',
    //   name: ['status'],
    //   input: (
    //     <Select onChange={handletest} >
    //       {(enumsStatus || []).map(item => (
    //         <Option key={item} value={item}>
    //           {t(`orders-buyings.form.status-values.${item}`)}
    //         </Option>
    //       ))}
    //     </Select>
    //   )
    // }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: (
        <>
          {' '}
          <ContactsForm />
          <Link to="/catalogues/matters/create">
            <Button type="add" onClick="">
              Créer une matière
            </Button>
          </Link>{' '}
        </>
      )
    }
  ];

  const getOrders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/ordersnew?populate=infos.customer'
      });
      setOrders(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders-buyings/enums'
      });
      setEnums(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnumsStatus = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/orders-buyings/enums_status'
      });
      setEnumsStatus(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSupplier = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSupplier(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getOrders();
      await getEnums();
      await getSites();
      await getSupplier();
      await getEnumsStatus();
    })();
  }, []);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
