import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import PromotionsTable from './promotionsTable';

const PromotionsSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [promotions, setpromotions] = useState([]);
  const [matters, setMatters] = useState([]);

  const fetchPromotions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-promotions?populate=matters`
      });
      setpromotions(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const fetchMatters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/matters`
      });
      setMatters(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addPromotion = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-promotions', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchPromotions();
  };

  const deletePromotion = async (promotion) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-promotions/${promotion._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Promotion_delete'));
    }
    fetchPromotions();
  };

  const editPromotion = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-promotions/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchPromotions();
  };

  useEffect(() => {
    fetchPromotions();
    fetchMatters();
  }, []);

  return (
    <>
      {promotions.length > 0 && (
        <PromotionsTable
          matters={matters}
          promotion={promotions}
          editPromotion={editPromotion}
          deletePromotion={deletePromotion}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addPromotion}
      >
        {t('settings.promotion.addPromotion')}
      </Button>
    </>
  );
};

export default PromotionsSettings;
