import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Button } from 'antd';
import { Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

import { getRedirectionFlow, completeRedirectionFlow, pay } from './GCUtils';

const PaymentGocardless = ({ type, amount, reference, btnStyle }) => {
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();
  const params = useLocation().search;
  const isGCCallback = useLocation().search !== '';
  const [redirectURI, setRedirectURI] = useState(null);

  const chooseGCAction = async () => {
    if (user.gcMandate === undefined) {
      const redirection = await getRedirectionFlow(dispatchAPI, user, t);
      setRedirectURI(redirection);
    } else {
      await pay(type, dispatchAPI, user, amount, t, reference);
    }
  };

  useEffect(() => {
    if (isGCCallback) {
      const redirect_flow_id = params.replace('?redirect_flow_id=', '');
      (async () => {
        if (user.gcMandate === undefined) {
          const newUser = await completeRedirectionFlow(
            dispatchAPI,
            user,
            t,
            redirect_flow_id,
            setUser
          );
          await pay(type, dispatchAPI, newUser, amount, t, reference);
        }
      })();
    }
  }, []);

  return redirectURI === null ? (
    <>
      <Row justify="center">
        <Button
          type="primary"
          style={btnStyle}
          onClick={() => chooseGCAction(dispatchAPI)}
        >
          {t('payment.buttons.gocardless')} <CheckOutlined />
        </Button>
      </Row>
    </>
  ) : (
    <Route
      path="/payment-system"
      component={() => {
        window.location.href = redirectURI;
        return null;
      }}
    />
  );
};

PaymentGocardless.propTypes = {
  amount: PropTypes.number.isRequired,
  btnStyle: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default PaymentGocardless;
