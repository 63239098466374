import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import useColumns from './columns';

import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListSuppliers = ({ type }) => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);
  const { t } = useTranslation();
  const headers = [
    {
      label: t('commercial.profesionals.column.name'),
      key: 'name'
    },
    {
      label: t('commercial.profesionals.column.type'),
      key: 'type'
    },
    {
      label: t('commercial.profesionals.column.address'),
      key: 'address.city'
    },
    {
      label: t('commercial.profesionals.column.email'),
      key: 'email'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="suppliers"
      resourceModelName="Supplier"
      withSubRoutes
      populate="site"
      extraQuery={extraQuery}
      headers={headers}
    />
  );
};

ListSuppliers.propTypes = {
  type: PropTypes.string.isRequired
};

export default ListSuppliers;
