import React from 'react';
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const filesLogo = {
  'image/png': <FileImageOutlined style={{ fontSize: 40, color: '#9254de' }} />,
  'application/pdf': (
    <FilePdfOutlined style={{ fontSize: 40, color: '#ff4d4f' }} />
  ),
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
    <FileExcelOutlined style={{ fontSize: 40, color: '#73d13d' }} />
  ),
  'text/plain': <FileTextOutlined style={{ fontSize: 40, color: '#40a9ff' }} />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
    <FileTextOutlined style={{ fontSize: 40, color: '#40a9ff' }} />
  )
};

export default filesLogo;
