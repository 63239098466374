import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListProductions = () => {
  const { t } = useTranslation();
  const headers = [
    {
      label: t('catalogues.column.label'),
      key: 'label'
    },
    {
      label: t('catalogues.column.ref'),
      key: 'ref'
    },

    {
      label: t('catalogues.column.category'),
      key: 'category'
    },
    {
      label: t('catalogues.column.sold_price'),
      key: 'sold_price'
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="productions"
      columns={[...columns()]}
      withSubRoutes
      headers={headers}
      resourceModelName="Production"
    />
  );
};

export default ListProductions;
