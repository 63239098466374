import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import useColumns from './columns';
import OptionsRow from './OptionsRow';

import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

const ListProfesionals = () => {
  const columns = useColumns();
  const [extraQuery, setExtraQuery] = useState(null);
  const { t } = useTranslation();

  const headers = [
    {
      label: t('commercial.profesionals.column.name'),
      key: 'name'
    },
    {
      label: t('commercial.profesionals.column.sites'),
      key: 'site.label'
    },
    {
      label: t('profesionals.column.type'),
      key: 'type'
    },
    {
      label: t('commercial.profesionals.column.address'),
      key: 'address.city'
    },
    {
      label: t('commercial.profesionals.column.email'),
      key: 'email'
    },
    {
      label: t('commercial.profesionals.column.contacts'),
      key: 'contacts.length'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="profesionals"
      resourceModelName="profesionals"
      withSubRoutes
      extraQuery={extraQuery}
      populate="site,settings-commissions,settings-promotions"
      headers={headers}
    >
      <Col>
        <OptionsRow setExtraQuery={setExtraQuery} style={{ float: 'right' }} />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListProfesionals;
