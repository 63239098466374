import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Button, InputNumber } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import ShapingsForm from './ShapingsForm';

const Content = styled.div`
  border: 2px solid black;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 12px;
  margin-left: 50px;
  margin-right: 50px;
`;

const SizesForm = ({ name, sectionId, value }) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Content>
              <div className="quotation-size-field-wrapper">
                <Form.Item
                  label={t('configurator.height')}
                  name={[field.name, 'height']}
                  fieldKey={[field.fieldKey, 'height']}
                  rules={[{ required: true, message: 'Missing height' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  label={t('configurator.width')}
                  name={[field.name, 'width']}
                  fieldKey={[field.fieldKey, 'width']}
                  rules={[{ required: true, message: 'Missing width' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <CloseOutlined onClick={() => remove(field.name)} />
              </div>
              <ShapingsForm
                name={[field.name, 'shapings']}
                sectionId={sectionId}
                elementId={name[0]}
                choice={value}
              />
            </Content>
          ))}
          <Form.Item wrapperCol={{ span: 8, offset: 8 }}>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              className="quotation-add-btn"
            >
              {t('configurator.add_piece')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

SizesForm.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  sectionId: PropTypes.number.isRequired,
  value: PropTypes.string
};

SizesForm.defaultProps = {
  value: ''
};

export default SizesForm;
