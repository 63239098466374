import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, Spin } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import marbleBlack from '../../assets/images/marbleBlack.jpg';

const BarcodeScan = ({ state }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [input, setInput] = useState();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  const home = location.pathname;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleScan = async (e) => {
    setInput(e.target.value);
    if (e.target.value.length === 11) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/pieces?ref_internal=${e.target.value} `
        });
        history.push(`/suppliers_accounting/stocks/show/${data[0]._id}`, [
          home
        ]);
      } catch (err) {
        if (err.response) message(err.response.status);
      }
    }
  };

  const mystyleDiv = {
    padding: '10px',
    fontFamily: 'Arial',
    zIndex: 10,
    height: '100%',
    width: '100%',
    margin: ' 0 auto'
  };
  const mystyleInput = {
    opacity: '0'
  };
  const mystyleButton = {
    borderRadius: ' 2px',
    float: 'center',
    height: 130,
    fontSize: 13,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    letterSpacing: 2,
    backgroundImage: `url(${marbleBlack})`
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          height: '10em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundImage: `url(${marbleBlack})`
        }}
        className="customShortcut"
      >
        <QrcodeOutlined style={{ fontSize: '25px' }} />
        <span
          style={{
            whiteSpace: 'pre-line',
            width: '100%',
            textAlign: 'center',
            marginLeft: 0
          }}
        >
          {state === 'exit'
            ? 'SCANNER UNE ENTRÉE DE STOCK'
            : 'SCANNER UNE SORTIE DE STOCK'}
        </span>
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onfocus={handleScan}
        autoFocus={false}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <Spin tip="Scanner votre article" size="large">
          <div style={mystyleDiv}>
            <Input autoFocus onChange={handleScan} style={mystyleInput} />
          </div>
        </Spin>
      </Modal>
    </>
  );
};
BarcodeScan.propTypes = {
  state: PropTypes.string.isRequired
};

export default BarcodeScan;
