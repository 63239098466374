import moment from 'moment';
import numeral from 'numeral';
import { enumsStatus } from '../../../utils/constants/tagColors';

const columns = (t, enums) => [
  {
    title: t('invoices.column.number'),
    dataIndex: 'number',
    key: 'number',
    sorter: true
  },

  {
    title: t('invoices.column.status'),
    dataIndex: 'status',
    key: 'status',
    render: (renderedEnums) => enumsStatus[renderedEnums],
    sorter: true,
    filters:
      enums &&
      enums.status?.map((label) => ({ text: enumsStatus[label], value: label }))
  },
  {
    title: t('invoices.column.customer'),
    dataIndex: 'customer',
    key: 'customer',
    render: (customer) => `${customer.last_name} ${customer.first_name || ''}`,
    sorter: true
  },
  {
    title: t('invoices.column.total'),
    dataIndex: 'order',
    key: 'total',
    render: (element) =>
      `${numeral(element?.prices?.totalNet).format('0,0.00')} € TTC`,
    sorter: true
  },
  {
    title: t('invoices.column.emission_date'),
    dataIndex: 'emission_date',
    key: 'emission_date',
    sorter: true,
    render: (date) => date && moment(date).format('DD/MM/YYYY')
  }
];

export default columns;
