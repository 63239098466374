import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useColumns = () => {
  const { t } = useTranslation();
  const [sites, setSites] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSites();
    })();
  }, []);

  return [
    {
      title: t('building-sites.label'),
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('building-sites.sites'),
      dataIndex: 'site',
      render: (site) => site.label,
      sorter: true
      // filters: sites && sites?.map(c => ({
      //       text: c.label, value: c._id
      //     }))
    },
    // {
    //   title: t('building-sites.customers'),
    //   dataIndex: 'customer',
    //   render: (customer) => customer?.last_name
    // },
    {
      title: t('building-sites.profesional'),
      dataIndex: 'profesional',
      render: (profesional) => profesional?.name,
      sorter: true
    },
    {
      title: t('building-sites.date'),
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
      sorter: true
    }
  ];
};

export default useColumns;
