import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import {
  typeCatalogues,
  categoryShaping
} from '../../../utils/constants/tagColors';
import DartyPrice from '../dartyPrice';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [targets, setTargets] = useState([]);
  const [categories, setCategories] = useState([]);

  const units = ['ml', 'm²', 'U'];

  const basicFields = [
    {
      name: ['ref'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['category'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {categories.map((el) => (
            <Option key={el} value={el}>
              <Tag color={categoryShaping[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['unit'],
      input: (
        <Select
          options={units.map((el) => ({
            label: el,
            value: el
          }))}
        />
      )
    },
    {
      name: ['target'],
      input: (
        <Select loading={isFieldsLoading}>
          {targets.map((el) => (
            <Option key={el} value={el}>
              <Tag color={typeCatalogues[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['sold_price'],
      input: <InputNumber />
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <DartyPrice />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/shapings/enums' });
      setTargets(data.targets);
      setCategories(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
