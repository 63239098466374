import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, message, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../../utils/constants/routes';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import TablesTab from './TablesTab';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
// import ContactsTable from './contactsTable';
import ImportFiles from '../../../components/importFiles';
// import DownloadGeneratedFile from '../../Accounting/Invoices/DownloadGeneratedFile';

const ShowProfesional = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [profesional, setProfesional] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [template, setTemplate] = useState();

  const getTemplate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?type=QUOTATION`
      });
      setTemplate(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  // const downloadFile = async (fileId, name, callback) => {
  //   try {
  //     const response = await dispatchAPI('GET', {
  //       url: `/files/generate/orders_purchases
  //       $/${fileId}/${template._id}`,
  //       responseType: 'blob'
  //     });
  //     const blob = new Blob([response.data], {
  //       type: 'application/pdf'
  //     });

  //     const url = URL.createObjectURL(blob);
  //     const pdfWindow = window.open();
  //     pdfWindow.location = url;
  //   } catch (e) {
  //     if (e.response) message(e.response.status);
  //   }
  //   callback();
  // };

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/orders-supplies/${id}?populate=site,supplies.product,customer,profesional`
      });
      setProfesional(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
      await getTemplate();
    })();
  }, [fetchData, forceRefresh]);

  const deleteProfesional = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/orders-supplies/${id}` });
      history.push(`${routes.BUYING}${subRoutes.BUYING.ORDERS_SUPPLIES}`);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  return (
    <>
      <PageHeaderCustom title={t(`orders-supplies.show.title`)} withSubRoutes />

      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(profesional)} translate />
        </Skeleton>
      </ContentCustom>
      <ContentCustom>
        {Object.values(profesional).length && (
          <TablesTab profesional={profesional} />
        )}
      </ContentCustom>
      <ImportFiles
        id={id}
        files={profesional.files}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        resource="orders-supplies"
        disabled={profesional.files?.length !== 0}
      />
    </>
  );
};

export default ShowProfesional;
