import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  TreeSelect,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  treeState,
  treeLoading,
  children,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'tree':
      inputNode = (
        <TreeSelect
          style={{
            width: '40%'
          }}
          loading={treeLoading}
          treeDataSimpleMode
          treeData={treeState}
          treeCheckable
          multiple
          showArrow
          showCheckedStrategy="SHOW_PARENT"
        />
      );
      break;
    default:
      inputNode = <Input />;
      break;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PermissionsTable = ({
  permissions,
  editPermission,
  deletePermission
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [treeState, setTreeState] = useState([]);
  const [enums, setEnums] = useState({});

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      permission_label: record.permission_label,
      grant: record.grant,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editPermission(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      message('Validate Failed:', errInfo);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/permissions/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTreetOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  const usersByTitle = [...new Set(enums.functionalities)]?.reduce(
    (acc, value) => {
      if (value.split('.') && !acc[value.split('.')[0]]) {
        acc[value.split('.')[0]] = [];
      }
      acc[value.split('.')[0]].push(value);
      return acc;
    },
    {}
  );

  const setTree = () => {
    const parent = [];

    Object.values(usersByTitle).forEach((arryOfGrantedPermissions) => {
      let index = 0;
      while (index < arryOfGrantedPermissions.length) {
        const element = arryOfGrantedPermissions[index];
        const numberOfLayer = element.split('.').length;
        if (numberOfLayer === 1) {
          parent.push({
            title: element,
            value: element,
            id: element,
            pid: 0
          });
        }

        if (numberOfLayer === 2) {
          parent.push({
            title: element.split('.')[1],
            value: element,
            id: element,
            pId: element.split('.')[0]
          });
        }

        if (numberOfLayer === 3) {
          parent.push({
            title: element.split('.')[2],
            value: element,
            id: element,
            pId: `${element.split('.')[0]}.${element.split('.')[1]}`
          });
        }
        index += 1;
      }
    });
    setTreeState(parent);
  };
  useEffect(() => {
    getTreetOptions();
  }, [getTreetOptions]);
  useEffect(() => {
    if (enums?.functionalities) {
      setTree();
    }
  }, [enums]);
  const columns = [
    {
      title: t('settings.permissionstable.permission_label'),
      dataIndex: 'permission_label',
      width: '16%',
      editable: true
    },
    {
      title: t('settings.permissionstable.grant'),
      dataIndex: 'grant',
      width: '40%',
      editable: true,
      ellipsis: true,
      render: (grant) =>
        grant && (
          <Typography.Paragraph ellipsis>
            {grant.join(' ')}
          </Typography.Paragraph>
        )
    },
    {
      title: t('settings.permissionstable.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)}>
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button>
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {t('buttons.edit')}
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deletePermission(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {t('buttons.delete')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'grant' ? 'tree' : 'text',
        dataIndex: col.dataIndex,
        treeState,
        treeLoading: isLoading,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={permissions}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.defaultProps = {
  children: null
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  treeState: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      pid: PropTypes.number.isRequired
    })
  ).isRequired,
  treeLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

PermissionsTable.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      permission_label: PropTypes.string.isRequired,
      grant: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  editPermission: PropTypes.func.isRequired,
  deletePermission: PropTypes.func.isRequired
};
export default PermissionsTable;
