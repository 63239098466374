import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Space } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const WorksheetsActions = ({ worksheet, order, index, onDelete }) => (
  <Space>
    <Link
      to={{
        pathname: `/customers_accounting/workSheets`,
        state: {
          worksheet,
          datas: order,
          numberWorkSheet: `${order.number}-FT${index + 1}`
        }
      }}
    >
      <EditOutlined style={{ color: 'black', fontSize: 18 }} />
    </Link>
    <Divider type="vertical" />
    {worksheet?._id && (
      <DeleteOutlined
        style={{ color: 'red', fontSize: 18 }}
        onClick={() => {
          onDelete(worksheet?._id);
        }}
      />
    )}
  </Space>
);

WorksheetsActions.propTypes = {
  worksheet: PropTypes.shape({
    _id: PropTypes.string,
    validated: PropTypes.bool
  }).isRequired,
  order: PropTypes.shape({ number: PropTypes.string }).isRequired,
  index: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};
WorksheetsActions.defaultProps = {
  onDelete: () => {}
};
