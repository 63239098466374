import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListProducts from './ListProducts';
import CreateUpdateProduct from './CreateUpdateProduct';
import ShowProduct from './ShowProduct';

const ProductRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateProduct purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateProduct purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowProduct} />
      <Route path={path} component={ListProducts} />
    </Switch>
  );
};

export default ProductRouter;
