import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from './fields';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import OrderForm from './OrderForm';

const CreateUpdateOrder = ({ purpose }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const { fields } = useFields(purpose);

  const updateOrder = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/orders/${id}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrder = useCallback(async () => {
    setIsLoading(true);
    if (purpose === 'edit' && id) {
      try {
        const { data } = await dispatchAPI('GET', { url: `/orders/${id}` });
        setOrder({
          ...data,
          reception_date: data.reception_date && moment(data.reception_date)
        });
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    getOrder();
  }, [getOrder, purpose, id]);

  const handleSubmit = async (values) => {
    const formattedValues = { ...values, quotations: [values.quotation] };
    if (purpose === 'edit') await updateOrder(formattedValues);
  };

  return (
    <>
      <PageHeaderCustom title={t(`orders.form.title.${purpose}`)} />
      <ContentCustom>
        {isLoading ? (
          <Space direction="vertical" style={{ width: '100%' }} align="center">
            {[...fields].map((f) => (
              <Skeleton.Input key={`${f.name}`} style={{ width: 300 }} active />
            ))}
          </Space>
        ) : (
          <OrderForm
            resource="orders"
            isLoading={isLoading}
            initialValues={order}
            handleSubmit={handleSubmit}
            fields={fields}
          />
        )}
      </ContentCustom>
    </>
  );
};

CreateUpdateOrder.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateOrder;
