import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ListMovements from './index';

const MovementRouter = () => {
  const { path } = useRouteMatch();

  return <ListMovements />;
};

export default MovementRouter;
