import { Form, Col, Select, Tag, Input, InputNumber, Row, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { profesionalsTypes } from '../../../utils/constants/tagColors';

const useSiteFields = () => {
  const { Option } = Select;
  const [sites, setSites] = useState([]);
  const [finitions, setFinitions] = useState([]);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sites' });
      setSites(data);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getSites();
  }, []);

  return (
    <>
      <Col>
        <Form.Item
          name={['buy_site']}
          label={t('pieces.form.buy_site')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          rules={[{ required: true }]}
        >
          <Select loading={isFieldsLoading}>
            {sites.map((el) => (
              <Option key={el._id} value={el._id}>
                <Tag>{el.label}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['location']}
          label={t('pieces.form.location')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['on_site_location']}
          label={t('pieces.form.on_site_location')}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
        >
          <Input />
        </Form.Item>
      </Col>
    </>
  );
};

export default useSiteFields;
