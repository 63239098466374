import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Tag, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import {
  typeCatalogues,
  categoryProducts
} from '../../../utils/constants/tagColors';
import DartyPrice from '../dartyPrice';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [targets, setTargets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [existentSupplier, setExistentSupplier] = useState(false);
  const [selectedsupplier, setSelectedSupplier] = useState('');
  const [suppliers, setSuppliers] = useState([]);

  const basicFields = [
    {
      name: ['ref'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['category'],
      input: (
        <Select loading={isFieldsLoading}>
          {categories.map((el) => (
            <Option key={el} value={el}>
              <Tag color={categoryProducts[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['target'],
      input: (
        <Select loading={isFieldsLoading}>
          {targets.map((el) => (
            <Option key={el} value={el}>
              <Tag color={typeCatalogues[el]}>{el.toUpperCase()}</Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['points'],
      input: <InputNumber />
    },
    {
      label: ['existentSupplier'],
      input: <Switch onChange={(change) => setExistentSupplier(change)} />
    },
    !existentSupplier
      ? { name: ['nonExistentSupplier'], input: <Input /> }
      : {
          name: ['supplier'],
          input: (
            <Select
              onChange={(change) => {
                setSelectedSupplier(change);
              }}
              value={selectedsupplier}
            >
              {suppliers.map((el) => (
                <Option key={el._id} value={el._id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          )
        },
    {
      name: ['sold_price'],
      input: <InputNumber />
    }
  ];

  const advancedFields = [
    {
      noLabel: true,
      input: <DartyPrice />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/products/enums' });
      setTargets(data.targets);
      setCategories(data.categories);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/suppliers' });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, advancedFields, isFieldsLoading };
};

export default useFields;
