import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import MaterialsTable from './materialsTable';

const MaterialSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [materials, setMaterial] = useState([]);

  const fetchMaterials = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/settings-Materials`
      });
      setMaterial(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addMaterials = async () => {
    const body = {
      label: 'Nouveau',
      amount: 0
    };
    try {
      await dispatchAPI('POST', { url: '/settings-Materials', body });
    } catch (e) {
      message.error(e.message);
    }
    fetchMaterials();
  };

  const deleteMaterials = async (process) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/settings-Materials/${process._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Tva'));
    }
    fetchMaterials();
  };

  const editMaterials = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/settings-Materials/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchMaterials();
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <>
      {materials.length > 0 && (
        <MaterialsTable
          material={materials}
          editMaterial={editMaterials}
          deleteMaterial={deleteMaterials}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addMaterials}
      >
        {t('settings.Materials.addMaterial')}
      </Button>
    </>
  );
};

export default MaterialSettings;
