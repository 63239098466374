import React from 'react';
import {
  ContactsOutlined,
  EuroOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HomeOutlined,
  ProjectOutlined,
  SettingOutlined,
  StockOutlined,
  TagsOutlined,
  UserOutlined,
  CarOutlined,
  AccountBookOutlined,
  FolderOpenOutlined,
  CalendarOutlined,
  BookOutlined,
  EnvironmentOutlined,
  GoldOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  SITES: <EnvironmentOutlined />,
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  STOCK: <GoldOutlined />,
  ACCOUNTING: <EuroOutlined />,
  COMMERCIAL: <ContactsOutlined />,
  CONTRACTS: <FileDoneOutlined />,
  QUOTES: <FileTextOutlined />,
  CUSTOMERS: <ContactsOutlined />,
  HUMANRESOURCE: <UserOutlined />,
  DELIVERIES: <CarOutlined />,
  PROJECTS: <ProjectOutlined />,
  THEME: <SettingOutlined />,
  'TIMES-AND-ATTENDANCES': <FieldTimeOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  CUSTOMERS_ACCOUNTING: <AccountBookOutlined />,
  BUYING: <EuroOutlined />,
  TRUCKS_FLEET: <CarOutlined />,
  PLANNING: <CalendarOutlined />,
  SETTINGS: <SettingOutlined />,
  DOCUMENTS: <FolderOpenOutlined />,
  CATALOGUES: <BookOutlined />,
  CUSTOM_ACCOUNTING: <ContactsOutlined />
};

export default navMenuLogos;
