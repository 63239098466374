/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Card, Row, Button, Upload, Modal } from 'antd';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const AddFile = ({ onFileUploaded }) => {
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const { Dragger } = Upload;

  const props = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/files/upload`,
    data: {
      type: 'TRELLO_FILE'
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;

      if (
        info.file.response &&
        info.file.response.description &&
        info.file.response.description.id
      ) {
        onFileUploaded(info.file.response.description.id, info.file.name);
        setVisible(false);
      }

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      hidden: true
    }
  };

  const fileDragger = (
    <Col span={24}>
      <Dragger {...props} style={{ margin: '5px' }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('files.create.action')}</p>
        <p className="ant-upload-hint">{t('files.create.formats')}</p>
      </Dragger>
    </Col>
  );

  const modal = (
    <Modal
      zIndex={11}
      visible={visible}
      okButtonProps={{ hidden: true }}
      onCancel={() => setVisible(false)}
    >
      {fileDragger}
    </Modal>
  );

  return (
    <Col span={8} style={{ height: 'fit-content' }}>
      {modal}
      <Card bordered={false} bodyStyle={{ padding: '0 8px' }}>
        <Row>
          <Col>
            <Row>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusOutlined />
                Ajouter une pièce jointe
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

AddFile.propTypes = {
  onFileUploaded: PropTypes.func.isRequired
};

export default AddFile;
