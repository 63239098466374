import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListProfesionals from './Profesionals';
import ShowProfesional from './ShowProfesional';
import CreateUpdateProfesional from './CreateUpdateProfesional';

const OrdersPurchases = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateProfesional purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateProfesional purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowProfesional} />
      <Route path={path} component={ListProfesionals} />
    </Switch>
  );
};

export default OrdersPurchases;
