import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateBuildingSites from './CreateUpdateBuildingSites';
import ShowBuildingSites from './ShowBuildingSites';
import ListBuildingSites from './ListBuildingSites';

const BuildingSitesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateBuildingSites purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateBuildingSites purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowBuildingSites} />

      <Route path={`${path}`} component={ListBuildingSites} />
    </Switch>
  );
};

export default BuildingSitesRouter;
