import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

export const PlanningButtons = ({ setStart, start }) => {
  const goLeft = () => {
    const date = moment(start).subtract(5, 'days');
    if (date.day() === 0) setStart(moment(start).subtract(3, 'days'));
    else setStart(date);
  };

  const goRight = () => {
    const date = moment(start).add(5, 'days');
    if (date.day() === 6) setStart(moment(start).add(3, 'days'));
    else setStart(date);
  };

  return (
    <Row justify="space-between">
      <Button
        shape="circle"
        type="primary"
        icon={<LeftOutlined style={{ fontSize: 18 }} />}
        onClick={goLeft}
      />
      <Button
        shape="circle"
        type="primary"
        icon={<RightOutlined style={{ fontSize: 18 }} />}
        onClick={goRight}
      />
    </Row>
  );
};

PlanningButtons.propTypes = {
  setStart: PropTypes.func.isRequired,
  start: PropTypes.string.isRequired
};
